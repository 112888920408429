import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    // Disable scroll restoration by the browser
    window.history.scrollRestoration = 'manual';
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  }, [location.pathname]); // Only runs on route changes

  return null; // This component doesn't render anything
};

export default ScrollToTop;
