import React from 'react';
import style from './../Css/Policies.module.css';

const ShippingAndDelivery = () => {
  const currentDate = new Date().toLocaleDateString();

  return (
    <div className='container px-1'>
    <div className={style.policy_container}>
      <h1 className={style.title}>Shipping and Delivery Policy</h1>
      <p className={style.date}>Last updated: 17-11-2024</p>

      <section>
        <h2 className={style.section_title}>1. Digital Course Delivery</h2>
        <p className={style.text}>
          Our courses, teaching materials, and live online classes are delivered digitally. Once your payment is confirmed, you will receive an email with access details for the course materials and live class links. Please ensure you provide a valid email address during registration.
        </p>
      </section>

      <section>
        <h2 className={style.section_title}>2. Access Timeline</h2>
        <p className={style.text}>
          You will receive access to your purchased course content within 24 hours of payment confirmation. If you do not receive your access details within this timeframe, please contact our support team for assistance.
        </p>
      </section>

      <section>
        <h2 className={style.section_title}>3. Physical Materials (If Applicable)</h2>
        <p className={style.text}>
          In some cases, we may offer additional physical study materials or merchandise. If you purchase any physical items, they will be shipped within 5-7 business days after payment. You will receive a tracking number once your order is dispatched.
        </p>
      </section>

      <section>
        <h2 className={style.section_title}>4. Shipping Fees</h2>
        <p className={style.text}>
          Shipping fees for any physical materials will be calculated at checkout. For international orders, additional customs fees or taxes may apply based on your country’s regulations. These fees are the responsibility of the customer.
        </p>
      </section>

      <section>
        <h2 className={style.section_title}>5. Delivery Issues</h2>
        <p className={style.text}>
          If you experience any issues with the delivery of your digital content (e.g., missing access details) or physical materials (e.g., damaged or lost items), please contact our support team at <strong>_ support@iitscode.com _</strong>. We will resolve the issue as quickly as possible.
        </p>
      </section>

      <section>
        <h2 className={style.section_title}>6. Refunds for Delivery Issues</h2>
        <p className={style.text}>
          If you do not receive your digital course access or if physical items are lost or damaged during shipping, you may be eligible for a refund or replacement. Please refer to our <strong>Cancellation and Refund Policy</strong> for more details.
        </p>
      </section>

      <section>
        <h2 className={style.section_title}>7. Contact Support</h2>
        <p className={style.text}>
          If you have any questions or concerns regarding our shipping and delivery policy, please reach out to us at <strong>_ support@iitscode.com _</strong>. We are here to help.
        </p>
      </section>
    </div>    </div>
  );
};

export default ShippingAndDelivery;
