import React, { useState, useEffect } from 'react';
import style from './../Css/Signup.module.css';
import API_BASE_URL from '../apiConfig';
import axios from 'axios';

const Signup = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    mobileNumber: '',
    verificationCode: '',
    password: '',
    rePassword: '',
    acceptPrivacyPolicy: false,
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [verificationMessage, setVerificationMessage] = useState('');
  const [verificationSent, setVerificationSent] = useState(false);



  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const toggleRePasswordVisibility = () => setShowRePassword(!showRePassword);

  // Handle input changes
  const handleChange = async (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });

    // Automatically send verification code when email is filled
    // if (name === 'email' && value) {
    //   await sendVerificationCode(value);
    // }
  };
// Function to send verification code
// const sendVerificationCode = async (email) => {
//   try {
//     const response = await axios.post(
//       `${API_BASE_URL}/sendVerificationCode.php`, 
//       { email },
   
//     );

//     console.log(response.data);
   

//     if (response.status === 200) {
//       if (response.data.status === 'success') {
//         setVerificationMessage('Verification code sent to your email.');
//         setVerificationSent(true);
//       } else {
//         setVerificationMessage('ERROR');
//       }
//     } else {
//       setVerificationMessage('Failed to send verification code. Try again.');
//     }
//   } catch (error) {
//     console.error('Error sending verification code:', error);
//     setVerificationMessage('Error sending verification code. Please try again later.');
//   }
// };



// Validate form fields
const validateForm = () => {
  const errors = {};
  const { fullName, email, mobileNumber, verificationCode, password, rePassword, acceptPrivacyPolicy } = formData;

  if (!fullName) errors.fullName = 'Full Name is required';
  if (!email) errors.email = 'Email is required';
  if (!mobileNumber) errors.mobileNumber = 'Mobile Number is required';
  if (!verificationCode) errors.verificationCode = 'Verification Code is required';
  if (!password) errors.password = 'Password is required';
  if (!rePassword) errors.rePassword = 'Re-entering password is required';
  if (!acceptPrivacyPolicy) errors.acceptPrivacyPolicy = 'You must accept the privacy policy';

  const passwordRegex = /^(?=.*[A-Z])(?=.*[@]).{8,}$/;
  if (password && !passwordRegex.test(password)) {
    errors.password = 'Password must be at least 8 characters long, contain an uppercase letter and @';
  }

  if (password !== rePassword) {
    errors.rePassword = 'Passwords do not match';
  }

  return errors;
};

const handleSubmit = async (e) => {
  e.preventDefault();

  // Validate the form before submitting
  const errors = validateForm();
  if (Object.keys(errors).length > 0) {
    setFormErrors(errors);
    setSuccessMessage('');
    return;
  }

  try {
    const response = await axios.post(
      `${API_BASE_URL}/signup.php`, 
      formData,
      { credentials: 'include' }  // Include session cookies in the request
    );
  
   
    console.log(response.data);

    if (response.status === 200) {
      if (response.data.status === 'success') {
        setSuccessMessage('Signup successful!');
        // Clear form data after successful signup
        setFormData({
          fullName: '',
          email: '',
          mobileNumber: '',
          verificationCode: '',
          password: '',
          rePassword: '',
          acceptPrivacyPolicy: false,
        });
        setVerificationMessage('');
      } else {

        setVerificationMessage(response.data.message || 'success.');
      }
    } else {
      setVerificationMessage('Failed to complete signup. Please try again later.');
    }
  } catch (error) {
    console.error('Signup error:', error);
    setVerificationMessage('Error during signup. Please try again later.');
  }
};


  return (
    <>
      <div className="modal fade" id="signupModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className={`${style.signupbox} modal-dialog`}>
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className={`${style.signupmodelbox} modal-body`}>
              <h1 className={`${style.signupheading} top`}>Create an Account</h1>

              {successMessage && <div className={style.successMessage}>{successMessage}</div>}
              {verificationMessage && <div className={style.infoMessage}>{verificationMessage}</div>}

              <form onSubmit={handleSubmit}>
                <label className={style.signupcontent} htmlFor="fullName">
                  Full Name
                  <div className={style.signupicons}>
                    <i className={`${style.signupIcons} fa-solid fa-user`}></i>
                    <input
                      className={style.signupmodalinputtxt}
                      type="text"
                      name="fullName"
                      value={formData.fullName}
                      onChange={handleChange}
                      placeholder="Your full name"
                    />
                  </div>
                  {formErrors.fullName && <span className={style.error}>{formErrors.fullName}</span>}
                </label><br />

                <label className={style.signupcontent} htmlFor="email">
                  Email Address
                  <div className={style.signupicons}>
                    <i className={`${style.signupIcons} fa-solid fa-at`}></i>
                    <input
                      className={style.signupmodalinputtxt}
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Your email address"
                    />
                  </div>
                  {formErrors.email && <span className={style.error}>{formErrors.email}</span>}
                </label><br />

                <label className={style.signupcontent} htmlFor="mobileNumber">
                  Mobile Number
                  <div className={style.signupicons}>
                    <i className={`${style.signupIcons} fa-solid fa-phone`}></i>
                    <input
                      className={style.signupmodalinputtxt}
                      type="tel"
                      name="mobileNumber"
                      value={formData.mobileNumber}
                      onChange={handleChange}
                      placeholder="Contact number"
                    />
                  </div>
                  {formErrors.mobileNumber && <span className={style.error}>{formErrors.mobileNumber}</span>}
                </label><br />

                {/* <label className={style.signupcontent} htmlFor="verificationCode">
                  Verification Code
                  <div className={style.signupicons}>
                    <i className={`${style.signupIcons} fa-solid fa-check`}></i>
                    <input
                      className={style.signupmodalinputtxt}
                      type="text"
                      name="verificationCode"
                      value={formData.verificationCode}
                      onChange={handleChange}
                      placeholder="Enter one-time password"
                    />
                  </div>
                  {formErrors.verificationCode && <span className={style.error}>{formErrors.verificationCode}</span>}
                </label><br />
  */}

                <label className={style.signupcontent} htmlFor="password">
                  Password
                  <div className={style.signupicons}>
                    <i className={`${style.signupIcons} fa-solid fa-lock`}></i>
                    <input
                      className={style.signupmodalinputpass}
                      type={showPassword ? 'text' : 'password'}
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      placeholder="Your password"
                    />
                    <button
                      type="button"
                      onClick={togglePasswordVisibility}
                      className={style.showPasswordBtnsignup}
                    >
                      {showPassword ? <i className="fa-regular fa-eye-slash"></i> : <i className="fa-regular fa-eye"></i>}
                    </button>
                  </div>
                  {formErrors.password && <span className={style.error}>{formErrors.password}</span>}
                </label><br />

                <label className={style.signupcontent} htmlFor="rePassword">
                  Re-enter Password
                  <div className={style.signupicons}>
                    <i className={`${style.signupIcons} fa-solid fa-lock`}></i>
                    <input
                      className={style.signupmodalinputpass}
                      type={showRePassword ? 'text' : 'password'}
                      name="rePassword"
                      value={formData.rePassword}
                      onChange={handleChange}
                      placeholder="Re-enter password"
                    />
                    <button
                      type="button"
                      onClick={toggleRePasswordVisibility}
                      className={style.showPasswordBtnsignup}
                    >
                      {showRePassword ? <i className="fa-regular fa-eye-slash"></i> : <i className="fa-regular fa-eye"></i>}
                    </button>
                  </div>
                  {formErrors.rePassword && <span className={style.error}>{formErrors.rePassword}</span>}
                </label><br />

                <label className={style.signupcontent}>
                  <input
                    type="checkbox"
                    name="acceptPrivacyPolicy"
                    checked={formData.acceptPrivacyPolicy}
                    onChange={handleChange}
                  />
                  I accept the Privacy Policy
                </label>
                <br />
                {formErrors.acceptPrivacyPolicy && <span className={style.error}>{formErrors.acceptPrivacyPolicy}</span>}

                <button type="submit"       className={`${style.signupmodalbtn} btn`}>Sign Up</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
