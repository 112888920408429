import React, { useState } from 'react';
import style from './../../Css/Courses.module.css'

function Csscoursedata() {
  // State to keep track of the selected chapter
  const [selectedChapter, setSelectedChapter] = useState('chapter1')

  // Function to handle chapter selection
  const handleChapterClick = (chapter) => {
    setSelectedChapter(chapter)
  }

  // State to track the active chapter
  const [activeChapter, setActiveChapter] = useState('chapter1');

  // Function to handle chapter click
  const handleChapterActive = (chapter) => {
    setActiveChapter(chapter);
  };
  const handleClick = (chapter) => {
    handleChapterActive(chapter);
    handleChapterClick(chapter);
    window.scrollTo(0,0);
  };


  return (
    <div className={`${style.firstcontainer} container-fluid`}>
    
        <div className={`${style.therow} row`}>



        <div className={`${style.droupdownbtnbar} dropdown`}>
                    <button className={`${style.droupdownbtn} btn dropdown-toggle`} type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  CSS
                    </button>
                    
                    <ul className={`${style.dropdownmenu} dropdown-menu`}>


                        <li onClick={() => handleClick('chapter1')}
                            className={`${style.chapter1} ${activeChapter === 'chapter1' ? style.active : ''} text-decoration-none dropdown-item`} >
                       
Introduction
                        </li>



                        <li
                            onClick={() => handleClick('chapter2')}
                            className={`${style.chapter2} ${activeChapter === 'chapter2' ? style.active : ''} dropdown-item `}>
                            
How To
                        </li>


                        <li onClick={() => handleClick('chapter3')}
              className={`${style.chapter3} ${activeChapter === 'chapter3' ? style.active : ''} dropdown-item `}>
           Syntax 
              </li>

              <li onClick={() => handleClick('chapter4')} 
                className={`${style.chapter4} ${activeChapter === 'chapter4' ? style.active : ''} dropdown-item `}>
          Commments 
              </li>


              <li onClick={() => handleClick('chapter5')} 
              className={`${style.chapter5} ${activeChapter === 'chapter5' ? style.active : ''} dropdown-item `}>
               Examples
              </li>

              <li onClick={() => handleClick('chapter6')}
              className={`${style.chapter6} ${activeChapter === 'chapter6' ? style.active : ''} dropdown-item `}>
                Snippets
              </li>

              <li onClick={() => handleClick('chapter7')} 
              className={`${style.chapter7} ${activeChapter === 'chapter7' ? style.active : ''} dropdown-item `}>
           Templates
              </li>

              <li onClick={() => handleClick('chapter8')}
               className={`${style.chapter8} ${activeChapter === 'chapter8' ? style.active : ''} dropdown-item `}>
            Editors
              </li>

              <li onClick={() => handleClick('chapter9')}
                className={`${style.chapter9} ${activeChapter === 'chapter9' ? style.active : ''} dropdown-item `}>
              Reference
              </li>


<h5>Selectors and Specificity</h5>
              <li onClick={() => handleClick('chapter10')}
               className={`${style.chapter10} ${activeChapter === 'chapter10' ? style.active : ''} dropdown-item `}>
             Selectors
              </li>

              <li onClick={() => handleClick('chapter135')} 
               className={`${style.chapter135} ${activeChapter === 'chapter135' ? style.active : ''} dropdown-item `}>
              Attribute Selectors
              </li>

              <li onClick={() => handleClick('chapter11')} 
              className={`${style.chapter11} ${activeChapter === 'chapter11' ? style.active : ''} dropdown-item `}>
            Combinators
              </li>

              <li onClick={() => handleClick('chapter12')}
               className={`${style.chapter12} ${activeChapter === 'chapter12' ? style.active : ''} dropdown-item `}>
              Pseudo-class
              </li>

              <li onClick={() => handleClick('chapter13')} 
              className={`${style.chapter13} ${activeChapter === 'chapter13' ? style.active : ''} dropdown-item `}>
            Pseudo-elements
              </li>
              <li onClick={() => handleClick('chapter14')} 
               className={`${style.chapter14} ${activeChapter === 'chapter14' ? style.active : ''} dropdown-item `}>
              Specificity
              </li>

<h5>Box Model and Layout</h5>
              <li onClick={() => handleClick('chapter15')} 
                className={`${style.chapter15} ${activeChapter === 'chapter15' ? style.active : ''} dropdown-item `} >
                  Box Model 
              </li>

              <li onClick={() => handleClick('chapter16')
              } 
              className={`${style.chapter16} ${activeChapter === 'chapter16' ? style.active : ''} dropdown-item `}>
             Height/Width

              </li>

              <li onClick={() => handleClick('chapter17')}
                className={`${style.chapter17} ${activeChapter === 'chapter17' ? style.active : ''} dropdown-item `}>
             Margins
              </li>



              <li onClick={() => handleClick('chapter18')} 
               className={`${style.chapter18} ${activeChapter === 'chapter18' ? style.active : ''} dropdown-item `}>
             Padding
              </li>


              <li onClick={() => handleClick('chapter19')}
                className={`${style.chapter19} ${activeChapter === 'chapter19' ? style.active : ''} dropdown-item `}>
           Borders
              </li>


              <li onClick={() => handleClick('chapter20')} 
               className={`${style.chapter20} ${activeChapter === 'chapter20' ? style.active : ''} dropdown-item `}>
          Outline
              </li>

              <li onClick={() => handleClick('chapter21')} 
               className={`${style.chapter21} ${activeChapter === 'chapter21' ? style.active : ''} dropdown-item `}>
              Outline Width
              </li>


              <li onClick={() => handleClick('chapter22')} 
               className={`${style.chapter22} ${activeChapter === 'chapter22' ? style.active : ''} dropdown-item `}>
                Outline Colour
              </li>



              <li onClick={() =>handleClick('chapter23')} 
               className={`${style.chapter23} ${activeChapter === 'chapter23' ? style.active : ''} dropdown-item `}>
         Outline Shorthand
              </li>



              <li onClick={() =>handleClick('chapter24')}
               className={`${style.chapter24} ${activeChapter === 'chapter24' ? style.active : ''} dropdown-item `}>
          Outline Offset
              </li>



              <li onClick={() =>handleClick('chapter25')} 
                className={`${style.chapter25} ${activeChapter === 'chapter25' ? style.active : ''} dropdown-item `}>
                  Display
              </li>


              <li onClick={() =>handleClick('chapter26')}
                className={`${style.chapter26} ${activeChapter === 'chapter26' ? style.active : ''} dropdown-item `}>
              Max-Width
              </li>



              <li onClick={() =>handleClick('chapter27')} 
               className={`${style.chapter27} ${activeChapter === 'chapter27' ? style.active : ''} dropdown-item `}>
            Position
              </li>



              <li onClick={() =>handleClick('chapter28')} 
               className={`${style.chapter28} ${activeChapter === 'chapter28' ? style.active : ''} dropdown-item `}>
              Z-index
              </li>



              <li onClick={() => handleClick('chapter29')} 
              className={`${style.chapter29} ${activeChapter === 'chapter29' ? style.active : ''} dropdown-item `}>
             Overflow
              </li>



              <li onClick={() => handleClick('chapter30')} 
              className={`${style.chapter30} ${activeChapter === 'chapter30' ? style.active : ''} dropdown-item `}>
               Float
              </li>



              <li onClick={() => handleClick('chapter31')} 
              className={`${style.chapter31} ${activeChapter === 'chapter31' ? style.active : ''} dropdown-item `}>
             Inline-block
              </li>



              <li onClick={() => handleClick('chapter32')} 
              className={`${style.chapter32} ${activeChapter === 'chapter32' ? style.active : ''} dropdown-item `}>
          Align
              </li>



              <li onClick={() => handleClick('chapter33')} 
              className={`${style.chapter33} ${activeChapter === 'chapter33' ? style.active : ''} dropdown-item `}>
          User Interface
              </li>



              <li onClick={() => handleClick('chapter34')} 
              className={`${style.chapter34} ${activeChapter === 'chapter34' ? style.active : ''} dropdown-item `}>
          Box Sizing              </li>


<h5>Colors and Background</h5>
              <li onClick={() => handleClick('chapter35')} 
              className={`${style.chapter35} ${activeChapter === 'chapter35' ? style.active : ''} dropdown-item `}>
        Colors
              </li>


              <li onClick={() => handleClick('chapter36')} 
              className={`${style.chapter36} ${activeChapter === 'chapter36' ? style.active : ''} dropdown-item `}>
             Backgrounds
              </li>


              <li onClick={() => handleClick('chapter37')} 
              className={`${style.chapter37} ${activeChapter === 'chapter37' ? style.active : ''} dropdown-item `}>
             Color Keywords
              </li>


              <li onClick={() => handleClick('chapter38')} 
              className={`${style.chapter38} ${activeChapter === 'chapter38' ? style.active : ''} dropdown-item `}>
            Gradient 
              </li>


              <li onClick={() => handleClick('chapter39')} 
              className={`${style.chapter39} ${activeChapter === 'chapter39' ? style.active : ''} dropdown-item `}>
              Color Values
              </li>


              <li onClick={() => handleClick('chapter40')} 
              className={`${style.chapter40} ${activeChapter === 'chapter40' ? style.active : ''} dropdown-item `}>
   Web Safe-Fonts
              </li>

<h5>Text and Font Styles</h5>
              <li onClick={() => handleClick('chapter41')} 
              className={`${style.chapter41} ${activeChapter === 'chapter41' ? style.active : ''} dropdown-item `}>
              
               Text
               
              </li>


              <li onClick={() => handleClick('chapter42')} 
              className={`${style.chapter42} ${activeChapter === 'chapter42' ? style.active : ''} dropdown-item `}>
        Text Color
              </li>


              <li onClick={() => handleClick('chapter43')} 
              className={`${style.chapter43} ${activeChapter === 'chapter43' ? style.active : ''} dropdown-item `}>
                   Text Alignment
              </li>


              <li onClick={() => handleClick('chapter44')} 
              className={`${style.chapter44} ${activeChapter === 'chapter44' ? style.active : ''} dropdown-item `}>
               Text Decoration 
              </li>


              <li onClick={() => handleClick('chapter45')} 
              className={`${style.chapter45} ${activeChapter === 'chapter45' ? style.active : ''} dropdown-item `}>
            Text Transformastion
              </li>

              <li onClick={() => handleClick('chapter46')} 
              className={`${style.chapter46} ${activeChapter === 'chapter46' ? style.active : ''} dropdown-item `}>
              Text Spacing
              </li>

              <li onClick={() => handleClick('chapter47')} 
              className={`${style.chapter47} ${activeChapter === 'chapter47' ? style.active : ''} dropdown-item `}>
               Text Shadow
              </li>

              <li onClick={() => handleClick('chapter48')} 
              className={`${style.chapter48} ${activeChapter === 'chapter48' ? style.active : ''} dropdown-item `}>
            Font Family
              </li>

              <li onClick={() => handleClick('chapter49')} 
              className={`${style.chapter49} ${activeChapter === 'chapter49' ? style.active : ''} dropdown-item `}>
                Font Web Safe
              </li>

              <li onClick={() => handleClick('chapter50')} 
              className={`${style.chapter50} ${activeChapter === 'chapter50' ? style.active : ''} dropdown-item `}>
             Font Fallbacks
              </li>

              <li onClick={() => handleClick('chapter51')} 
              className={`${style.chapter51} ${activeChapter === 'chapter51' ? style.active : ''} dropdown-item `}>
             Font Style
              </li>

              <li onClick={() => handleClick('chapter52')} 
              className={`${style.chapter52} ${activeChapter === 'chapter52' ? style.active : ''} dropdown-item `}>
              Font Size
              </li>

              <li onClick={() => handleClick('chapter53')} 
              className={`${style.chapter53} ${activeChapter === 'chapter53' ? style.active : ''} dropdown-item `}>
           Font Google
              </li>

              <li onClick={() => handleClick('chapter54')} 
              className={`${style.chapter54} ${activeChapter === 'chapter54' ? style.active : ''} dropdown-item `}>
           Font Pairing
              </li>

              <li onClick={() => handleClick('chapter55')} 
              className={`${style.chapter55} ${activeChapter === 'chapter55' ? style.active : ''} dropdown-item `}>
        Font Shorthand
              </li>

              <li onClick={() => handleClick('chapter56')} 
              className={`${style.chapter56} ${activeChapter === 'chapter56' ? style.active : ''} dropdown-item `}>
            Text Effect
              </li>

              <li onClick={() => handleClick('chapter136')} 
              className={`${style.chapter136} ${activeChapter === 'chapter136' ? style.active : ''} dropdown-item `}>
              Web Fonts
              </li>


<h5>Icons, Lists, and Tables</h5>
              <li onClick={() => handleClick('chapter57')} 
              className={`${style.chapter57} ${activeChapter === 'chapter57' ? style.active : ''} dropdown-item `}>
          Icons
              </li>



              <li onClick={() => handleClick('chapter58')} 
              className={`${style.chapter58} ${activeChapter === 'chapter58' ? style.active : ''} dropdown-item `}>
            Links
              </li>

              <li onClick={() => handleClick('chapter59')} 
              className={`${style.chapter59} ${activeChapter === 'chapter59' ? style.active : ''} dropdown-item `}>
              Lists
              </li>

              <li onClick={() => handleClick('chapter60')} 
              className={`${style.chapter60} ${activeChapter === 'chapter60' ? style.active : ''} dropdown-item `}>
          Tables
              </li>



<h5>Navigation and Interactivity</h5>
              <li onClick={() => handleClick('chapter61')} 
              className={`${style.chapter61} ${activeChapter === 'chapter61' ? style.active : ''} dropdown-item `}>
            Navigation Bar
              </li>


              <li onClick={() => handleClick('chapter62')} 
              className={`${style.chapter62} ${activeChapter === 'chapter62' ? style.active : ''} dropdown-item `}>
                 Dropdowns
              </li>


              <li onClick={() => handleClick('chapter63')} 
              className={`${style.chapter63} ${activeChapter === 'chapter63' ? style.active : ''} dropdown-item `}>
             Tooltips
              </li>
 
              <li onClick={() => handleClick('chapter64')} 
              className={`${style.chapter64} ${activeChapter === 'chapter64' ? style.active : ''} dropdown-item `}>
            Button
              </li>

              <li onClick={() => handleClick('chapter65')} 
              className={`${style.chapter65} ${activeChapter === 'chapter65' ? style.active : ''} dropdown-item `}>
             Pagination
              </li>

              <li onClick={() => handleClick('chapter66')} 
              className={`${style.chapter66} ${activeChapter === 'chapter66' ? style.active : ''} dropdown-item `}>
              Forms
              </li>


<h5>Advanced Techniques</h5>
              <li onClick={() => handleClick('chapter67')} 
              className={`${style.chapter67} ${activeChapter === 'chapter67' ? style.active : ''} dropdown-item `}>
             Image Gallery
              </li>

              <li onClick={() => handleClick('chapter68')} 
              className={`${style.chapter68} ${activeChapter === 'chapter68' ? style.active : ''} dropdown-item `}>
              Image Sprites
              </li>

              <li onClick={() => handleClick('chapter69')} 
              className={`${style.chapter69} ${activeChapter === 'chapter69' ? style.active : ''} dropdown-item `}>
             Counters
              </li>

              <li onClick={() => handleClick('chapter70')} 
              className={`${style.chapter70} ${activeChapter === 'chapter70' ? style.active : ''} dropdown-item `}>
             Websites Layout
              </li>

              <li onClick={() => handleClick('chapter71')} 
              className={`${style.chapter71} ${activeChapter === 'chapter71' ? style.active : ''} dropdown-item `}>
            Units
              </li>

              <li onClick={() => handleClick('chapter72')} 
              className={`${style.chapter72} ${activeChapter === 'chapter72' ? style.active : ''} dropdown-item `}>
             !important
              </li>

              <li onClick={() => handleClick('chapter73')} 
              className={`${style.chapter73} ${activeChapter === 'chapter73' ? style.active : ''} dropdown-item `}>
            Math Function
              </li>

              <li onClick={() => handleClick('chapter74')} 
              className={`${style.chapter74} ${activeChapter === 'chapter74' ? style.active : ''} dropdown-item `}>
              Rounded Corners
              </li>

              <li onClick={() => handleClick('chapter75')} 
              className={`${style.chapter75} ${activeChapter === 'chapter75' ? style.active : ''} dropdown-item `}>
              Border Images
              </li>

              <li onClick={() => handleClick('chapter76')} 
              className={`${style.chapter76} ${activeChapter === 'chapter76' ? style.active : ''} dropdown-item `}>
             Shadows
              </li>

              <li onClick={() => handleClick('chapter77')} 
              className={`${style.chapter77} ${activeChapter === 'chapter77' ? style.active : ''} dropdown-item `}>
              2D Transforms
              </li>

              <li onClick={() => handleClick('chapter78')} 
              className={`${style.chapter78} ${activeChapter === 'chapter78' ? style.active : ''} dropdown-item `}>
           3D Transforms
              </li>

              <li onClick={() => handleClick('chapter79')} 
              className={`${style.chapter79} ${activeChapter === 'chapter79' ? style.active : ''} dropdown-item `}>
               Transition
              </li>

              <li onClick={() => handleClick('chapter80')} 
              className={`${style.chapter80} ${activeChapter === 'chapter80' ? style.active : ''} dropdown-item `}>
              Animation
              </li>

              <li onClick={() => handleClick('chapter81')} 
              className={`${style.chapter81} ${activeChapter === 'chapter81' ? style.active : ''} dropdown-item `}>
              Style Images
              </li>

              <li onClick={() => handleClick('chapter82')} 
              className={`${style.chapter82} ${activeChapter === 'chapter82' ? style.active : ''} dropdown-item `}>
              Image Reflection
              </li>

              <li onClick={() => handleClick('chapter83')} 
              className={`${style.chapter83} ${activeChapter === 'chapter83' ? style.active : ''} dropdown-item `}>
              Object-fit
              </li>

              <li onClick={() => handleClick('chapter84')} 
              className={`${style.chapter84} ${activeChapter === 'chapter84' ? style.active : ''} dropdown-item `}>
            Object-Position
              </li>

              <li onClick={() => handleClick('chapter85')} 
              className={`${style.chapter85} ${activeChapter === 'chapter85' ? style.active : ''} dropdown-item `}>
             Masking 
              </li>

              <li onClick={() => handleClick('chapter86')} 
              className={`${style.chapter86} ${activeChapter === 'chapter86' ? style.active : ''} dropdown-item `}>
             Multiple Columns
              </li>

              <li onClick={() => handleClick('chapter134')} 
              className={`${style.chapter134} ${activeChapter === 'chapter134' ? style.active : ''} dropdown-item `}>
              Opacity
              </li>


              <li onClick={() => handleClick('chapter137')} 
              className={`${style.chapter137} ${activeChapter === 'chapter137' ? style.active : ''} dropdown-item `}>
              Variables 
              </li>




 <h5>Media Queries</h5>
              <li onClick={() => handleClick('chapter128')} 
              className={`${style.chapter128} ${activeChapter === 'chapter128' ? style.active : ''} dropdown-item `}>
               Media Queries
              </li>
      
              <li onClick={() => handleClick('chapter129')} 
              className={`${style.chapter129} ${activeChapter === 'chapter129' ? style.active : ''} dropdown-item `}>
                   MQ Examples 
              </li>


  <h5>Responsive Web Design(RWD)</h5>

              <li onClick={() => handleClick('chapter87')} 
              className={`${style.chapter87} ${activeChapter === 'chapter87' ? style.active : ''} dropdown-item `}>
                RWD Intro
              </li>

              <li onClick={() => handleClick('chapter88')} 
              className={`${style.chapter88} ${activeChapter === 'chapter88' ? style.active : ''} dropdown-item `}>
              RWD Media Queries
              </li>

              <li onClick={() => handleClick('chapter89')} 
              className={`${style.chapter89} ${activeChapter === 'chapter89' ? style.active : ''} dropdown-item `}>
        RWD Image
              </li>

              <li onClick={() => handleClick('chapter90')} 
              className={`${style.chapter90} ${activeChapter === 'chapter90' ? style.active : ''} dropdown-item `}>
                RWD Videos
              </li>

              <li onClick={() => handleClick('chapter91')} 
              className={`${style.chapter91} ${activeChapter === 'chapter91' ? style.active : ''} dropdown-item `}>
    RWD Framworks
              </li>

              <li onClick={() => handleClick('chapter92')} 
              className={`${style.chapter92} ${activeChapter === 'chapter92' ? style.active : ''} dropdown-item `}>
            RWD Templates
              </li>


<h5>Gride </h5>
              <li onClick={() => handleClick('chapter93')} 
              className={`${style.chapter93} ${activeChapter === 'chapter93' ? style.active : ''} dropdown-item `}>
              Gride Intro
              </li>


              <li onClick={() => handleClick('chapter94')} 
              className={`${style.chapter94} ${activeChapter === 'chapter94' ? style.active : ''} dropdown-item `}>
              Gride Container
              </li>

              <li onClick={() => handleClick('chapter95')} 
              className={`${style.chapter95} ${activeChapter === 'chapter95' ? style.active : ''} dropdown-item `}>
               Gride Item
              </li>

              <li onClick={() => handleClick('chapter96')} 
              className={`${style.chapter96} ${activeChapter === 'chapter96' ? style.active : ''} dropdown-item `}>
               Gride View
              </li>

<h5>Flex</h5>

              <li onClick={() => handleClick('chapter97')} 
              className={`${style.chapter97} ${activeChapter === 'chapter97' ? style.active : ''} dropdown-item `}>
              Flexbox
              </li>

              <li onClick={() => handleClick('chapter98')} 
              className={`${style.chapter98} ${activeChapter === 'chapter98' ? style.active : ''} dropdown-item `}>
              Flex Responsive
              </li>


<h5>Sass Topics</h5>

        <li onClick={() => handleClick('chapter101')} 
              className={`${style.chapter101} ${activeChapter === 'chapter101' ? style.active : ''} dropdown-item `}>
            Sass Home
              </li>

              <li onClick={() => handleClick('chapter102')} 
              className={`${style.chapter102} ${activeChapter === 'chapter102' ? style.active : ''}  dropdown-item `}>
              Sass Intro
              </li>

              <li onClick={() => handleClick('chapter103')} 
              className={`${style.chapter103} ${activeChapter === 'chapter103' ? style.active : ''} dropdown-item `}>
              Sass Installation
              </li>

              <li onClick={() => handleClick('chapter104')} 
              className={`${style.chapter104} ${activeChapter === 'chapter104' ? style.active : ''} dropdown-item `}>
                Sass Variables
              </li>

              <li onClick={() => handleClick('chapter105')} 
              className={`${style.chapter105} ${activeChapter === 'chapter105' ? style.active : ''} dropdown-item `}>
                    Sass Nesting 
              </li>

              <li onClick={() => handleClick('chapter106')} 
              className={`${style.chapter106} ${activeChapter === 'chapter106' ? style.active : ''} dropdown-item `}>
              Sass @import 
              </li>

              <li onClick={() => handleClick('chapter107')} 
              className={`${style.chapter107} ${activeChapter === 'chapter107' ? style.active : ''} dropdown-item `}>
               Sass @mixin
              </li>

              <li onClick={() => handleClick('chapter108')} 
              className={`${style.chapter108} ${activeChapter === 'chapter108' ? style.active : ''} dropdown-item `}>
              Sass @extend 
              </li>

              <li onClick={() => handleClick('chapter109')} 
              className={`${style.chapter109} ${activeChapter === 'chapter109' ? style.active : ''} dropdown-item `}>
                      Sass String
              </li>

              <li onClick={() => handleClick('chapter110')} 
              className={`${style.chapter110} ${activeChapter === 'chapter110' ? style.active : ''} dropdown-item `}>
         Sass Numeric
              </li>

              <li onClick={() => handleClick('chapter111')} 
              className={`${style.chapter111} ${activeChapter === 'chapter111' ? style.active : ''} dropdown-item `}>
           Sass List
              </li>

              <li onClick={() => handleClick('chapter112')} 
              className={`${style.chapter112} ${activeChapter === 'chapter112' ? style.active : ''} dropdown-item `}>
             Sass Map
              </li>

           
              <li onClick={() => handleClick('chapter113')} 
              className={`${style.chapter113} ${activeChapter === 'chapter113' ? style.active : ''} dropdown-item `}>
               Sass Selector 
              </li>

             
              <li onClick={() => handleClick('chapter114')} 
              className={`${style.chapter114} ${activeChapter === 'chapter114' ? style.active : ''} dropdown-item `}>
          Sass Introspection 
              </li>

        
              <li onClick={() => handleClick('chapter115')} 
              className={`${style.chapter115} ${activeChapter === 'chapter115' ? style.active : ''} dropdown-item `}>
          Sass Color
              </li>

              
   <h5>Resources and  Learning</h5>
              
              <li onClick={() => handleClick('chapter116')} 
              className={`${style.chapter116} ${activeChapter === 'chapter116' ? style.active : ''} dropdown-item `}>
                    Quiz 
              </li>

            
              <li onClick={() => handleClick('chapter117')} 
              className={`${style.chapter117} ${activeChapter === 'chapter117' ? style.active : ''} dropdown-item `}>
                  Exercises
              </li>

              <li onClick={() => handleClick('chapter118')} 
              className={`${style.chapter118} ${activeChapter === 'chapter118' ? style.active : ''} dropdown-item `}>
        Interview Prep
              </li>

              <li onClick={() => handleClick('chapter119')} 
              className={`${style.chapter119} ${activeChapter === 'chapter119' ? style.active : ''} dropdown-item `}>
               Bootcamp
              </li>


             
              <li onClick={() => handleClick('chapter120')} 
              className={`${style.chapter120} ${activeChapter === 'chapter120' ? style.active : ''} dropdown-item `}>
           Animatable 
              </li>

            
              <li onClick={() => handleClick('chapter121')} 
              className={`${style.chapter121} ${activeChapter === 'chapter121' ? style.active : ''} dropdown-item `}>
                   Browser Support 
              </li>

             
              <li onClick={() => handleClick('chapter122')} 
              className={`${style.chapter122} ${activeChapter === 'chapter122' ? style.active : ''} dropdown-item `}>
           Default Values 
              </li>

          
              <li onClick={() => handleClick('chapter123')} 
              className={`${style.chapter123} ${activeChapter === 'chapter123' ? style.active : ''} dropdown-item `}>
                  Functions 
              </li>

            
              <li onClick={() => handleClick('chapter124')} 
              className={`${style.chapter124} ${activeChapter === 'chapter124' ? style.active : ''} dropdown-item `}>
         Reference Aural 
              </li>

             
              <li onClick={() => handleClick('chapter125')} 
              className={`${style.chapter125} ${activeChapter === 'chapter125' ? style.active : ''} dropdown-item `}>
           CSS PX-EM Convert
              
              </li>

          
             
              





              </ul>
                </div>






          <div className={`${style.leftcolumn} col-2`}>
            {/* Chapter List */}
            <ul className={style.chapters}>

              <h5 className={style.stickyheading}>CSS</h5>
              <li
                onClick={() => handleClick('chapter1')} 
               
                className={`${style.chapter1} ${style.chapters} ${activeChapter === 'chapter1' ? style.active : ''} text-decoration-none`}
              >
              introduction 
              </li>
              <li
                onClick={() => handleClick('chapter2')}
                className={`${style.chapter2} ${activeChapter === 'chapter2' ? style.active : ''}`}
              >
                 How To
              </li>
              
              <li onClick={() => handleClick('chapter3')}
              className={`${style.chapter3} ${activeChapter === 'chapter3' ? style.active : ''}`}>
           Syntax 
              </li>

              <li onClick={() => handleClick('chapter4')} 
                className={`${style.chapter4} ${activeChapter === 'chapter4' ? style.active : ''}`}>
          Commments 
              </li>


              <li onClick={() => handleClick('chapter5')} 
              className={`${style.chapter5} ${activeChapter === 'chapter5' ? style.active : ''}`}>
               Examples
              </li>

              <li onClick={() => handleClick('chapter6')}
              className={`${style.chapter6} ${activeChapter === 'chapter6' ? style.active : ''}`}>
                Snippets
              </li>

              <li onClick={() => handleClick('chapter7')} 
              className={`${style.chapter7} ${activeChapter === 'chapter7' ? style.active : ''}`}>
           Templates
              </li>

              <li onClick={() => handleClick('chapter8')}
               className={`${style.chapter8} ${activeChapter === 'chapter8' ? style.active : ''}`}>
            Editors
              </li>

              <li onClick={() => handleClick('chapter9')}
                className={`${style.chapter9} ${activeChapter === 'chapter9' ? style.active : ''}`}>
              Reference
              </li>


<h5>Selectors and Specificity</h5>
              <li onClick={() => handleClick('chapter10')}
               className={`${style.chapter10} ${activeChapter === 'chapter10' ? style.active : ''}`}>
             Selectors
              </li>

              <li onClick={() => handleClick('chapter135')} 
               className={`${style.chapter135} ${activeChapter === 'chapter135' ? style.active : ''}`}>
              Attribute Selectors
              </li>

              <li onClick={() => handleClick('chapter11')} 
              className={`${style.chapter11} ${activeChapter === 'chapter11' ? style.active : ''}`}>
            Combinators
              </li>

              <li onClick={() => handleClick('chapter12')}
               className={`${style.chapter12} ${activeChapter === 'chapter12' ? style.active : ''}`}>
              Pseudo-class
              </li>

              <li onClick={() => handleClick('chapter13')} 
              className={`${style.chapter13} ${activeChapter === 'chapter13' ? style.active : ''}`}>
            Pseudo-elements
              </li>
              <li onClick={() => handleClick('chapter14')} 
               className={`${style.chapter14} ${activeChapter === 'chapter14' ? style.active : ''}`}>
              Specificity
              </li>

<h5>Box Model and Layout</h5>
              <li onClick={() => handleClick('chapter15')} 
                className={`${style.chapter15} ${activeChapter === 'chapter15' ? style.active : ''}`} >
                  Box Model 
              </li>

              <li onClick={() => handleClick('chapter16')
              } 
              className={`${style.chapter16} ${activeChapter === 'chapter16' ? style.active : ''}`}>
             Height/Width

              </li>

              <li onClick={() => handleClick('chapter17')}
                className={`${style.chapter17} ${activeChapter === 'chapter17' ? style.active : ''}`}>
             Margins
              </li>



              <li onClick={() => handleClick('chapter18')} 
               className={`${style.chapter18} ${activeChapter === 'chapter18' ? style.active : ''}`}>
             Padding
              </li>


              <li onClick={() => handleClick('chapter19')}
                className={`${style.chapter19} ${activeChapter === 'chapter19' ? style.active : ''}`}>
           Borders
              </li>


              <li onClick={() => handleClick('chapter20')} 
               className={`${style.chapter20} ${activeChapter === 'chapter20' ? style.active : ''}`}>
          Outline
              </li>

              <li onClick={() => handleClick('chapter21')} 
               className={`${style.chapter21} ${activeChapter === 'chapter21' ? style.active : ''}`}>
              Outline Width
              </li>


              <li onClick={() => handleClick('chapter22')} 
               className={`${style.chapter22} ${activeChapter === 'chapter22' ? style.active : ''}`}>
                Outline Colour
              </li>



              <li onClick={() =>handleClick('chapter23')} 
               className={`${style.chapter23} ${activeChapter === 'chapter23' ? style.active : ''}`}>
         Outline Shorthand
              </li>



              <li onClick={() =>handleClick('chapter24')}
               className={`${style.chapter24} ${activeChapter === 'chapter24' ? style.active : ''}`}>
          Outline Offset
              </li>



              <li onClick={() =>handleClick('chapter25')} 
                className={`${style.chapter25} ${activeChapter === 'chapter25' ? style.active : ''}`}>
                  Display
              </li>


              <li onClick={() =>handleClick('chapter26')}
                className={`${style.chapter26} ${activeChapter === 'chapter26' ? style.active : ''}`}>
              Max-Width
              </li>



              <li onClick={() =>handleClick('chapter27')} 
               className={`${style.chapter27} ${activeChapter === 'chapter27' ? style.active : ''}`}>
            Position
              </li>



              <li onClick={() =>handleClick('chapter28')} 
               className={`${style.chapter28} ${activeChapter === 'chapter28' ? style.active : ''}`}>
              Z-index
              </li>



              <li onClick={() => handleClick('chapter29')} 
              className={`${style.chapter29} ${activeChapter === 'chapter29' ? style.active : ''}`}>
             Overflow
              </li>



              <li onClick={() => handleClick('chapter30')} 
              className={`${style.chapter30} ${activeChapter === 'chapter30' ? style.active : ''}`}>
               Float
              </li>



              <li onClick={() => handleClick('chapter31')} 
              className={`${style.chapter31} ${activeChapter === 'chapter31' ? style.active : ''}`}>
             Inline-block
              </li>



              <li onClick={() => handleClick('chapter32')} 
              className={`${style.chapter32} ${activeChapter === 'chapter32' ? style.active : ''}`}>
          Align
              </li>



              <li onClick={() => handleClick('chapter33')} 
              className={`${style.chapter33} ${activeChapter === 'chapter33' ? style.active : ''}`}>
          User Interface
              </li>



              <li onClick={() => handleClick('chapter34')} 
              className={`${style.chapter34} ${activeChapter === 'chapter34' ? style.active : ''}`}>
          Box Sizing              </li>


<h5>Colors and Background</h5>
              <li onClick={() => handleClick('chapter35')} 
              className={`${style.chapter35} ${activeChapter === 'chapter35' ? style.active : ''}`}>
        Colors
              </li>


              <li onClick={() => handleClick('chapter36')} 
              className={`${style.chapter36} ${activeChapter === 'chapter36' ? style.active : ''}`}>
             Backgrounds
              </li>


              <li onClick={() => handleClick('chapter37')} 
              className={`${style.chapter37} ${activeChapter === 'chapter37' ? style.active : ''}`}>
             Color Keywords
              </li>


              <li onClick={() => handleClick('chapter38')} 
              className={`${style.chapter38} ${activeChapter === 'chapter38' ? style.active : ''}`}>
            Gradient 
              </li>


              <li onClick={() => handleClick('chapter39')} 
              className={`${style.chapter39} ${activeChapter === 'chapter39' ? style.active : ''}`}>
              Color Values
              </li>


              <li onClick={() => handleClick('chapter40')} 
              className={`${style.chapter40} ${activeChapter === 'chapter40' ? style.active : ''}`}>
   Web Safe-Fonts
              </li>

<h5>Text and Font Styles</h5>
              <li onClick={() => handleClick('chapter41')} 
              className={`${style.chapter41} ${activeChapter === 'chapter41' ? style.active : ''}`}>
              
               Text
               
              </li>


              <li onClick={() => handleClick('chapter42')} 
              className={`${style.chapter42} ${activeChapter === 'chapter42' ? style.active : ''}`}>
        Text Color
              </li>


              <li onClick={() => handleClick('chapter43')} 
              className={`${style.chapter43} ${activeChapter === 'chapter43' ? style.active : ''}`}>
                   Text Alignment
              </li>


              <li onClick={() => handleClick('chapter44')} 
              className={`${style.chapter44} ${activeChapter === 'chapter44' ? style.active : ''}`}>
               Text Decoration 
              </li>


              <li onClick={() => handleClick('chapter45')} 
              className={`${style.chapter45} ${activeChapter === 'chapter45' ? style.active : ''}`}>
            Text Transformastion
              </li>

              <li onClick={() => handleClick('chapter46')} 
              className={`${style.chapter46} ${activeChapter === 'chapter46' ? style.active : ''}`}>
              Text Spacing
              </li>

              <li onClick={() => handleClick('chapter47')} 
              className={`${style.chapter47} ${activeChapter === 'chapter47' ? style.active : ''}`}>
               Text Shadow
              </li>

              <li onClick={() => handleClick('chapter48')} 
              className={`${style.chapter48} ${activeChapter === 'chapter48' ? style.active : ''}`}>
            Font Family
              </li>

              <li onClick={() => handleClick('chapter49')} 
              className={`${style.chapter49} ${activeChapter === 'chapter49' ? style.active : ''}`}>
                Font Web Safe
              </li>

              <li onClick={() => handleClick('chapter50')} 
              className={`${style.chapter50} ${activeChapter === 'chapter50' ? style.active : ''}`}>
             Font Fallbacks
              </li>

              <li onClick={() => handleClick('chapter51')} 
              className={`${style.chapter51} ${activeChapter === 'chapter51' ? style.active : ''}`}>
             Font Style
              </li>

              <li onClick={() => handleClick('chapter52')} 
              className={`${style.chapter52} ${activeChapter === 'chapter52' ? style.active : ''}`}>
              Font Size
              </li>

              <li onClick={() => handleClick('chapter53')} 
              className={`${style.chapter53} ${activeChapter === 'chapter53' ? style.active : ''}`}>
           Font Google
              </li>

              <li onClick={() => handleClick('chapter54')} 
              className={`${style.chapter54} ${activeChapter === 'chapter54' ? style.active : ''}`}>
           Font Pairing
              </li>

              <li onClick={() => handleClick('chapter55')} 
              className={`${style.chapter55} ${activeChapter === 'chapter55' ? style.active : ''}`}>
        Font Shorthand
              </li>

              <li onClick={() => handleClick('chapter56')} 
              className={`${style.chapter56} ${activeChapter === 'chapter56' ? style.active : ''}`}>
            Text Effect
              </li>

              <li onClick={() => handleClick('chapter136')} 
              className={`${style.chapter136} ${activeChapter === 'chapter136' ? style.active : ''}`}>
              Web Fonts
              </li>


<h5>Icons, Lists, and Tables</h5>
              <li onClick={() => handleClick('chapter57')} 
              className={`${style.chapter57} ${activeChapter === 'chapter57' ? style.active : ''}`}>
          Icons
              </li>



              <li onClick={() => handleClick('chapter58')} 
              className={`${style.chapter58} ${activeChapter === 'chapter58' ? style.active : ''}`}>
            Links
              </li>

              <li onClick={() => handleClick('chapter59')} 
              className={`${style.chapter59} ${activeChapter === 'chapter59' ? style.active : ''}`}>
              Lists
              </li>

              <li onClick={() => handleClick('chapter60')} 
              className={`${style.chapter60} ${activeChapter === 'chapter60' ? style.active : ''}`}>
          Tables
              </li>



<h5>Navigation and Interactivity</h5>
              <li onClick={() => handleClick('chapter61')} 
              className={`${style.chapter61} ${activeChapter === 'chapter61' ? style.active : ''}`}>
            Navigation Bar
              </li>


              <li onClick={() => handleClick('chapter62')} 
              className={`${style.chapter62} ${activeChapter === 'chapter62' ? style.active : ''}`}>
                 Dropdowns
              </li>


              <li onClick={() => handleClick('chapter63')} 
              className={`${style.chapter63} ${activeChapter === 'chapter63' ? style.active : ''}`}>
             Tooltips
              </li>
 
              <li onClick={() => handleClick('chapter64')} 
              className={`${style.chapter64} ${activeChapter === 'chapter64' ? style.active : ''}`}>
            Button
              </li>

              <li onClick={() => handleClick('chapter65')} 
              className={`${style.chapter65} ${activeChapter === 'chapter65' ? style.active : ''}`}>
             Pagination
              </li>

              <li onClick={() => handleClick('chapter66')} 
              className={`${style.chapter66} ${activeChapter === 'chapter66' ? style.active : ''}`}>
              Forms
              </li>


<h5>Advanced Techniques</h5>
              <li onClick={() => handleClick('chapter67')} 
              className={`${style.chapter67} ${activeChapter === 'chapter67' ? style.active : ''}`}>
             Image Gallery
              </li>

              <li onClick={() => handleClick('chapter68')} 
              className={`${style.chapter68} ${activeChapter === 'chapter68' ? style.active : ''}`}>
              Image Sprites
              </li>

              <li onClick={() => handleClick('chapter69')} 
              className={`${style.chapter69} ${activeChapter === 'chapter69' ? style.active : ''}`}>
             Counters
              </li>

              <li onClick={() => handleClick('chapter70')} 
              className={`${style.chapter70} ${activeChapter === 'chapter70' ? style.active : ''}`}>
             Websites Layout
              </li>

              <li onClick={() => handleClick('chapter71')} 
              className={`${style.chapter71} ${activeChapter === 'chapter71' ? style.active : ''}`}>
            Units
              </li>

              <li onClick={() => handleClick('chapter72')} 
              className={`${style.chapter72} ${activeChapter === 'chapter72' ? style.active : ''}`}>
             !important
              </li>

              <li onClick={() => handleClick('chapter73')} 
              className={`${style.chapter73} ${activeChapter === 'chapter73' ? style.active : ''}`}>
            Math Function
              </li>

              <li onClick={() => handleClick('chapter74')} 
              className={`${style.chapter74} ${activeChapter === 'chapter74' ? style.active : ''}`}>
              Rounded Corners
              </li>

              <li onClick={() => handleClick('chapter75')} 
              className={`${style.chapter75} ${activeChapter === 'chapter75' ? style.active : ''}`}>
              Border Images
              </li>

              <li onClick={() => handleClick('chapter76')} 
              className={`${style.chapter76} ${activeChapter === 'chapter76' ? style.active : ''}`}>
             Shadows
              </li>

              <li onClick={() => handleClick('chapter77')} 
              className={`${style.chapter77} ${activeChapter === 'chapter77' ? style.active : ''}`}>
              2D Transforms
              </li>

              <li onClick={() => handleClick('chapter78')} 
              className={`${style.chapter78} ${activeChapter === 'chapter78' ? style.active : ''}`}>
           3D Transforms
              </li>

              <li onClick={() => handleClick('chapter79')} 
              className={`${style.chapter79} ${activeChapter === 'chapter79' ? style.active : ''}`}>
               Transition
              </li>

              <li onClick={() => handleClick('chapter80')} 
              className={`${style.chapter80} ${activeChapter === 'chapter80' ? style.active : ''}`}>
              Animation
              </li>

              <li onClick={() => handleClick('chapter81')} 
              className={`${style.chapter81} ${activeChapter === 'chapter81' ? style.active : ''}`}>
              Style Images
              </li>

              <li onClick={() => handleClick('chapter82')} 
              className={`${style.chapter82} ${activeChapter === 'chapter82' ? style.active : ''}`}>
              Image Reflection
              </li>

              <li onClick={() => handleClick('chapter83')} 
              className={`${style.chapter83} ${activeChapter === 'chapter83' ? style.active : ''}`}>
              Object-fit
              </li>

              <li onClick={() => handleClick('chapter84')} 
              className={`${style.chapter84} ${activeChapter === 'chapter84' ? style.active : ''}`}>
            Object-Position
              </li>

              <li onClick={() => handleClick('chapter85')} 
              className={`${style.chapter85} ${activeChapter === 'chapter85' ? style.active : ''}`}>
             Masking 
              </li>

              <li onClick={() => handleClick('chapter86')} 
              className={`${style.chapter86} ${activeChapter === 'chapter86' ? style.active : ''}`}>
             Multiple Columns
              </li>

              <li onClick={() => handleClick('chapter134')} 
              className={`${style.chapter134} ${activeChapter === 'chapter134' ? style.active : ''}`}>
              Opacity
              </li>


              <li onClick={() => handleClick('chapter137')} 
              className={`${style.chapter137} ${activeChapter === 'chapter137' ? style.active : ''}`}>
              Variables 
              </li>




 <h5>Media Queries</h5>
              <li onClick={() => handleClick('chapter128')} 
              className={`${style.chapter128} ${activeChapter === 'chapter128' ? style.active : ''}`}>
               Media Queries
              </li>
      
              <li onClick={() => handleClick('chapter129')} 
              className={`${style.chapter129} ${activeChapter === 'chapter129' ? style.active : ''}`}>
                   MQ Examples 
              </li>


  <h5>Responsive Web Design(RWD)</h5>

              <li onClick={() => handleClick('chapter87')} 
              className={`${style.chapter87} ${activeChapter === 'chapter87' ? style.active : ''}`}>
                RWD Intro
              </li>

              <li onClick={() => handleClick('chapter88')} 
              className={`${style.chapter88} ${activeChapter === 'chapter88' ? style.active : ''}`}>
              RWD Media Queries
              </li>

              <li onClick={() => handleClick('chapter89')} 
              className={`${style.chapter89} ${activeChapter === 'chapter89' ? style.active : ''}`}>
        RWD Image
              </li>

              <li onClick={() => handleClick('chapter90')} 
              className={`${style.chapter90} ${activeChapter === 'chapter90' ? style.active : ''}`}>
                RWD Videos
              </li>

              <li onClick={() => handleClick('chapter91')} 
              className={`${style.chapter91} ${activeChapter === 'chapter91' ? style.active : ''}`}>
    RWD Framworks
              </li>

              <li onClick={() => handleClick('chapter92')} 
              className={`${style.chapter92} ${activeChapter === 'chapter92' ? style.active : ''}`}>
            RWD Templates
              </li>


<h5>Gride </h5>
              <li onClick={() => handleClick('chapter93')} 
              className={`${style.chapter93} ${activeChapter === 'chapter93' ? style.active : ''}`}>
              Gride Intro
              </li>


              <li onClick={() => handleClick('chapter94')} 
              className={`${style.chapter94} ${activeChapter === 'chapter94' ? style.active : ''}`}>
              Gride Container
              </li>

              <li onClick={() => handleClick('chapter95')} 
              className={`${style.chapter95} ${activeChapter === 'chapter95' ? style.active : ''}`}>
               Gride Item
              </li>

              <li onClick={() => handleClick('chapter96')} 
              className={`${style.chapter96} ${activeChapter === 'chapter96' ? style.active : ''}`}>
               Gride View
              </li>

<h5>Flex</h5>

              <li onClick={() => handleClick('chapter97')} 
              className={`${style.chapter97} ${activeChapter === 'chapter97' ? style.active : ''}`}>
              Flexbox
              </li>

              <li onClick={() => handleClick('chapter98')} 
              className={`${style.chapter98} ${activeChapter === 'chapter98' ? style.active : ''}`}>
              Flex Responsive
              </li>


<h5>Sass Topics</h5>

        <li onClick={() => handleClick('chapter101')} 
              className={`${style.chapter101} ${activeChapter === 'chapter101' ? style.active : ''}`}>
            Sass Home
              </li>

              <li onClick={() => handleClick('chapter102')} 
              className={`${style.chapter102} ${activeChapter === 'chapter102' ? style.active : ''}`}>
              Sass Intro
              </li>

              <li onClick={() => handleClick('chapter103')} 
              className={`${style.chapter103} ${activeChapter === 'chapter103' ? style.active : ''}`}>
              Sass Installation
              </li>

              <li onClick={() => handleClick('chapter104')} 
              className={`${style.chapter104} ${activeChapter === 'chapter104' ? style.active : ''}`}>
                Sass Variables
              </li>

              <li onClick={() => handleClick('chapter105')} 
              className={`${style.chapter105} ${activeChapter === 'chapter105' ? style.active : ''}`}>
                    Sass Nesting 
              </li>

              <li onClick={() => handleClick('chapter106')} 
              className={`${style.chapter106} ${activeChapter === 'chapter106' ? style.active : ''}`}>
              Sass @import 
              </li>

              <li onClick={() => handleClick('chapter107')} 
              className={`${style.chapter107} ${activeChapter === 'chapter107' ? style.active : ''}`}>
               Sass @mixin
              </li>

              <li onClick={() => handleClick('chapter108')} 
              className={`${style.chapter108} ${activeChapter === 'chapter108' ? style.active : ''}`}>
              Sass @extend 
              </li>

              <li onClick={() => handleClick('chapter109')} 
              className={`${style.chapter109} ${activeChapter === 'chapter109' ? style.active : ''}`}>
                      Sass String
              </li>

              <li onClick={() => handleClick('chapter110')} 
              className={`${style.chapter110} ${activeChapter === 'chapter110' ? style.active : ''}`}>
         Sass Numeric
              </li>

              <li onClick={() => handleClick('chapter111')} 
              className={`${style.chapter111} ${activeChapter === 'chapter111' ? style.active : ''}`}>
           Sass List
              </li>

              <li onClick={() => handleClick('chapter112')} 
              className={`${style.chapter112} ${activeChapter === 'chapter112' ? style.active : ''}`}>
             Sass Map
              </li>

           
              <li onClick={() => handleClick('chapter113')} 
              className={`${style.chapter113} ${activeChapter === 'chapter113' ? style.active : ''}`}>
               Sass Selector 
              </li>

             
              <li onClick={() => handleClick('chapter114')} 
              className={`${style.chapter114} ${activeChapter === 'chapter114' ? style.active : ''}`}>
          Sass Introspection 
              </li>

        
              <li onClick={() => handleClick('chapter115')} 
              className={`${style.chapter115} ${activeChapter === 'chapter115' ? style.active : ''}`}>
          Sass Color
              </li>

              
   <h5>Resources and  Learning</h5>
              
              <li onClick={() => handleClick('chapter116')} 
              className={`${style.chapter116} ${activeChapter === 'chapter116' ? style.active : ''}`}>
                    Quiz 
              </li>

            
              <li onClick={() => handleClick('chapter117')} 
              className={`${style.chapter117} ${activeChapter === 'chapter117' ? style.active : ''}`}>
                  Exercises
              </li>

              <li onClick={() => handleClick('chapter118')} 
              className={`${style.chapter118} ${activeChapter === 'chapter118' ? style.active : ''}`}>
        Interview Prep
              </li>

              <li onClick={() => handleClick('chapter119')} 
              className={`${style.chapter119} ${activeChapter === 'chapter119' ? style.active : ''}`}>
               Bootcamp
              </li>


             
              <li onClick={() => handleClick('chapter120')} 
              className={`${style.chapter120} ${activeChapter === 'chapter120' ? style.active : ''}`}>
           Animatable 
              </li>

            
              <li onClick={() => handleClick('chapter121')} 
              className={`${style.chapter121} ${activeChapter === 'chapter121' ? style.active : ''}`}>
                   Browser Support 
              </li>

             
              <li onClick={() => handleClick('chapter122')} 
              className={`${style.chapter122} ${activeChapter === 'chapter122' ? style.active : ''}`}>
           Default Values 
              </li>

          
              <li onClick={() => handleClick('chapter123')} 
              className={`${style.chapter123} ${activeChapter === 'chapter123' ? style.active : ''}`}>
                  Functions 
              </li>

            
              <li onClick={() => handleClick('chapter124')} 
              className={`${style.chapter124} ${activeChapter === 'chapter124' ? style.active : ''}`}>
         Reference Aural 
              </li>

             
              <li onClick={() => handleClick('chapter125')} 
              className={`${style.chapter125} ${activeChapter === 'chapter125' ? style.active : ''}`}>
           CSS PX-EM Convert
              
              </li>

          
             
              

           

            </ul>
          </div>















          <div className={`${style.rightcolumn} col`}>
            {/* Chapter Content */}






{selectedChapter === 'chapter1' && (
    <div className={style.chaptercontent}>
      <h1 className={style.heading}>Introduction</h1>

    

      <p>
        CSS, or Cascading Style Sheets, is a styling language used to describe the presentation of a document written in HTML or XML. It allows web developers to control how web pages are displayed, making them look attractive and functional across different devices and screen sizes. Here’s a brief overview:
      </p><br />

      <h2>What is CSS?</h2>
      <p>
        <strong>CSS</strong> stands for Cascading Style Sheets. It’s a language used to style and layout web pages, including aspects like colors, fonts, spacing, and positioning.
      </p><br />

      <h2 style={{paddingBottom:"6px"}}>Why Use CSS?</h2>
      <ul>
        <li><strong>Separation of Content and Style:</strong> CSS separates the content (HTML) from the design (CSS), making it easier to manage and update the look of a website without changing its underlying structure.</li><br />
        <li><strong>Consistency:</strong> By using CSS, you can ensure a consistent look and feel across multiple pages of a website with a single stylesheet.</li><br />
        <li><strong>Responsive Design:</strong> CSS enables the creation of designs that adapt to different screen sizes and devices, enhancing usability and accessibility.</li><br />
        <li><strong>Enhanced Design Capabilities:</strong> CSS offers advanced design features like animations, transitions, and grid layouts, allowing for more dynamic and interactive web pages.</li>
      </ul><br />

      <h2>Basic Concepts of CSS:</h2>
      <h3>Selectors:</h3>
      <p>These are patterns used to target HTML elements. For example, <code>p</code> selects all <code>&lt;p&gt;</code> elements, and <code>.class-name</code> selects all elements with the specified class.</p>
      <pre><code>{`p {
  color: blue;
}`}</code></pre><br />

      <h3>Properties and Values:</h3>
      <p>CSS properties define the style (such as color, font-size, margin) and values specify the settings for these properties.</p>
      <pre><code>{`body {
  background-color: lightgrey;
}`}</code></pre><br />

      <h3>Box Model:</h3>
      <p>Every element on a web page is a box. CSS allows you to control the content, padding, border, and margin of these boxes.</p>
      <pre><code>{`.box {
  padding: 10px;
  border: 1px solid black;
  margin: 20px;
}`}</code></pre><br />

      <h3>Responsive Design:</h3>
      <p>Media queries in CSS adjust styles based on device characteristics, like screen width, ensuring your website looks good on both desktops and mobile devices.</p>
      <pre><code>{`@media (max-width: 600px) {
  .container {
    width: 100%;
  }
}`}</code></pre><br />

      <h2>How CSS is Applied:</h2>
      <h3>Inline CSS:</h3>
      <p>Styles are applied directly within HTML tags using the <code>style</code> attribute, which is not ideal for large projects.</p>
      <pre><code>{`<p style="color: red;">This text is red.</p>`}</code></pre>

      <h3>Internal CSS:</h3>
      <p>Styles are placed within the <code>&lt;style&gt;</code> tag in the <code>&lt;head&gt;</code> section of an HTML document, affecting only that document.</p>
      <pre><code>{`<style>
body {
  font-family: Arial, sans-serif;
}
</style>`}</code></pre><br />

      <h3>External CSS:</h3>
      <p>Styles are defined in separate <code>.css</code> files and linked to HTML documents. This method is preferred for maintaining styles across multiple pages.</p>
      <pre><code>{`<link rel="stylesheet" href="styles.css">`}</code></pre>
      <pre><code>{`/* styles.css */
h1 {
  color: navy;
}`}</code></pre><br />

      <p>
        CSS is a powerful tool that enables web developers to create visually engaging and user-friendly websites by controlling the design and layout effectively.
      </p>
    

    </div>
  )}


   



            {selectedChapter === 'chapter3' && (
              <div className={style.chaptercontent}>


      <h1 className={style.heading}>Syntax</h1>

      <p>
        CSS (Cascading Style Sheets) is used to style and layout web pages. Its syntax is relatively straightforward, consisting of selectors and declarations. Here’s a comprehensive guide to CSS syntax:
      </p><br/>

      <h2>Basic Syntax</h2>
      <pre><code>{`selector {
  property: value;
}`}</code></pre><br />

      <h2 style={{paddingBottom:"6px"}}>Components</h2>
      <ul>
        <li><strong>Selector</strong>: Targets the HTML element(s) you want to style. Examples include element selectors, class selectors, and ID selectors.</li><br />
        <li><strong>Property</strong>: Specifies the style feature you want to modify. Examples include <code>color</code>, <code>font-size</code>, <code>margin</code>, and <code>padding</code>.</li><br />
        <li><strong>Value</strong>: Defines the setting for the property. Examples include <code>red</code>, <code>16px</code>, <code>10px</code>, and <code>center</code>.</li>
      </ul><br/>

      <h4 style={{paddingBottom:"6px"}}>Examples</h4>

      <h3>1. Element Selector</h3>
      <pre><code>{`p {
  color: blue;
  font-size: 16px;
}`}</code></pre>
      <p><strong>Selector</strong>: <code>p</code> (targets all <code>&lt;p&gt;</code> elements)</p>
      <p><strong>Properties</strong>: <code>color</code>, <code>font-size</code></p>
      <p><strong>Values</strong>: <code>blue</code>, <code>16px</code></p><br/>

      <h3>2. Class Selector</h3>
      <pre><code>{`.my-class {
  background-color: lightgrey;
  padding: 10px;
}`}</code></pre>
      <p><strong>Selector</strong>: <code>.my-class</code> (targets elements with <code>class="my-class"</code>)</p>
      <p><strong>Properties</strong>: <code>background-color</code>, <code>padding</code></p>
      <p><strong>Values</strong>: <code>lightgrey</code>, <code>10px</code></p><br/>

      <h3>3. ID Selector</h3>
      <pre><code>{`#my-id {
  border: 1px solid black;
  margin: 20px;
}`}</code></pre>
      <p><strong>Selector</strong>: <code>#my-id</code> (targets the element with <code>id="my-id"</code>)</p>
      <p><strong>Properties</strong>: <code>border</code>, <code>margin</code></p>
      <p><strong>Values</strong>: <code>1px solid black</code>, <code>20px</code></p><br/>

      <h2 style={{paddingBottom:"6px"}}>Combining Selectors</h2>

      <h3>Group Selector</h3>
      <pre><code>{`h1, h2, h3 {
  font-family: Arial, sans-serif;
}`}</code></pre>
      <p><strong>Selectors</strong>: <code>h1</code>, <code>h2</code>, <code>h3</code> (targets all <code>&lt;h1&gt;</code>, <code>&lt;h2&gt;</code>, and <code>&lt;h3&gt;</code> elements)</p>
      <p><strong>Property</strong>: <code>font-family</code></p>
      <p><strong>Value</strong>: <code>Arial, sans-serif</code></p><br/>

      <h3>Descendant Selector</h3>
      <pre><code>{`div p {
  color: green;
}`}</code></pre>
      <p><strong>Selector</strong>: <code>div p</code> (targets all <code>&lt;p&gt;</code> elements inside <code>&lt;div&gt;</code>)</p>
      <p><strong>Property</strong>: <code>color</code></p>
      <p><strong>Value</strong>: <code>green</code></p><br/>

      <h3>Child Selector</h3>
      <pre><code>{`ul > li {
  list-style-type: none;
}`}</code></pre>
      <p><strong>Selector</strong>: <code>ul &gt; li</code> (targets <code>&lt;li&gt;</code> elements that are direct children of <code>&lt;ul&gt;</code>)</p>
      <p><strong>Property</strong>: <code>list-style-type</code></p>
      <p><strong>Value</strong>: <code>none</code></p><br/>

      <h2>Pseudo-Classes</h2>
      <pre><code>{`a:hover {
  color: red;
}`}</code></pre>
      <p><strong>Selector</strong>: <code>a:hover</code> (targets <code>&lt;a&gt;</code> elements when hovered over)</p>
      <p><strong>Property</strong>: <code>color</code></p>
      <p><strong>Value</strong>: <code>red</code></p><br/>

      <h2>Pseudo-Elements</h2>
      <pre><code>{`p::first-line {
  font-weight: bold;
}`}</code></pre>
      <p><strong>Selector</strong>: <code>p::first-line</code> (targets the first line of <code>&lt;p&gt;</code> elements)</p>
      <p><strong>Property</strong>: <code>font-weight</code></p>
      <p><strong>Value</strong>: <code>bold</code></p><br />

      <h2>Media Queries</h2>
      <pre><code>{`@media (max-width: 600px) {
  .container {
    width: 100%;
  }
}`}</code></pre>
      <p><strong>Media Query</strong>: <code>@media (max-width: 600px)</code> (applies styles when the viewport width is 600px or less)</p>
      <p><strong>Selector</strong>: <code>.container</code></p>
      <p><strong>Property</strong>: <code>width</code></p>
      <p><strong>Value</strong>: <code>100%</code></p><br />

      <h2>CSS Variables</h2>
      <pre><code>{`:root {
  --main-color: blue;
}

p {
  color: var(--main-color);
}`}</code></pre>
      <p><strong>Variable</strong>: <code>--main-color</code> (defined in the <code>:root</code> selector)</p>
      <p><strong>Property</strong>: <code>color</code></p>
      <p><strong>Value</strong>: <code>var(--main-color)</code> (uses the value of <code>--main-color</code>)</p>

      <h4>Full Example</h4>
      <pre><code>{`/* Global Styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

/* Header Styles */
header {
  background-color: #333;
  color: #fff;
  padding: 10px 0;
}

/* Navigation Menu */
nav ul {
  list-style-type: none;
  padding: 0;
}

nav ul li {
  display: inline;
  margin-right: 15px;
}

nav ul li a {
  color: #fff;
  text-decoration: none;
}

/* Responsive Design */
@media (max-width: 768px) {
  nav ul li {
    display: block;
    margin-bottom: 10px;
  }
}`}</code></pre><br />

      <p>
        This guide covers the fundamental aspects of CSS syntax. CSS is quite flexible and allows for a wide range of styling options, making it an essential tool for web development.
      </p>


      
      </div>
  )}




{selectedChapter === 'chapter2' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>How to Use CSS</h1>


      <p>If you're looking to provide a "How To" guide for CSS in a React component, here’s how you can structure it:</p><br/>

        <h2>1. Provide a Brief Introduction</h2>
        <p>Explain what CSS is and its purpose.</p>
     <br/>

    
        <h2>2. Explain Basic Concepts</h2>
        <p>Cover key CSS concepts such as selectors, properties, and values.</p>
     <br/>

   
        <h2>3. Offer Practical Examples</h2>
        <p>Show how to use CSS with concrete examples.</p>
     <br/>

      
        <h2>4. Include Advanced Topics</h2>
        <p>Introduce concepts like responsive design and CSS variables.</p>
     <br/>

     
        <h2>5. Add Styling</h2>
        <p>Make sure the component itself is well-styled to improve readability.</p>
      <br/>

     
        <h2 style={{paddingBottom:"6px"}}>Examples</h2>
        <h3>Basic Syntax</h3>
        <pre><code>{`selector {
  property: value;
}`}</code></pre>
        <p>
          - <strong>Selector:</strong> Targets HTML elements (e.g., <code>p</code>, <code>.my-class</code>, <code>#my-id</code>).
        </p>
        <p>
          - <strong>Property:</strong> Defines the style feature (e.g., <code>color</code>, <code>font-size</code>).
        </p>
        <p>
          - <strong>Value:</strong> Sets the value of the property (e.g., <code>red</code>, <code>16px</code>).
        </p><br/>

        <h3>Element Selector</h3>
        <pre><code>{`p {
  color: blue;
  font-size: 16px;
}`}</code></pre>
        <p><strong>Selector:</strong> <code>p</code> (targets all <code>&lt;p&gt;</code> elements)</p>
        <p><strong>Properties:</strong> <code>color</code>, <code>font-size</code></p>
        <p><strong>Values:</strong> <code>blue</code>, <code>16px</code></p><br />

        <h3>Class Selector</h3>
        <pre><code>{`.my-class {
  background-color: lightgrey;
  padding: 10px;
}`}</code></pre>
        <p><strong>Selector:</strong> <code>.my-class</code> (targets elements with <code>class="my-class"</code>)</p>
        <p><strong>Properties:</strong> <code>background-color</code>, <code>padding</code></p>
        <p><strong>Values:</strong> <code>lightgrey</code>, <code>10px</code></p><br/>

        <h3>ID Selector</h3>
        <pre><code>{`#my-id {
  border: 1px solid black;
  margin: 20px;
}`}</code></pre>
        <p><strong>Selector:</strong> <code>#my-id</code> (targets the element with <code>id="my-id"</code>)</p>
        <p><strong>Properties:</strong> <code>border</code>, <code>margin</code></p>
        <p><strong>Values:</strong> <code>1px solid black</code>, <code>20px</code></p>
     <br />

    
        <h2 style={{paddingBottom:"6px"}}>Advanced Topics</h2>
        <h3>Responsive Design</h3>
        <pre><code>{`@media (max-width: 600px) {
  .container {
    width: 100%;
  }
}`}</code></pre>
        <p>Media queries allow you to apply different styles for different screen sizes.</p><br />

        <h3>CSS Variables</h3>
        <pre><code>{`:root {
  --main-color: blue;
}

p {
  color: var(--main-color);
}`}</code></pre>
        <p>CSS variables enable you to reuse values throughout your stylesheets.</p>
   <br />

        <h2>Full Example</h2>
        <pre><code>{`/* Global Styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

/* Header Styles */
header {
  background-color: #333;
  color: #fff;
  padding: 10px 0;
}

/* Navigation Menu */
nav ul {
  list-style-type: none;
  padding: 0;
}

nav ul li {
  display: inline;
  margin-right: 15px;
}

nav ul li a {
  color: #fff;
  text-decoration: none;
}

/* Responsive Design */
@media (max-width: 768px) {
  nav ul li {
    display: block;
    margin-bottom: 10px;
  }
}`}</code></pre>
        <p>This example covers global styles, header styles, navigation menu styles, and responsive design.</p>
    



              </div>
            )}










            {selectedChapter === 'chapter4' && (
              <div className={style.chaptercontent}>
               <h1 className={style.heading} >Comments</h1>
    
      <p>In CSS, comments are used to add notes or explanations within the stylesheet. Comments are helpful for documenting your code, making it easier for you and others to understand it. They are ignored by the browser and do not affect the rendering of the page.</p><br/>

      <h2>Basic CSS Comment Syntax</h2>
      <pre><code>{`/* This is a single-line comment */

/* 
   This is a multi-line comment
   It can span multiple lines
*/`}</code></pre><br/>

      <h2 style={{paddingBottom:"6px"}}>Examples of CSS Comments</h2>

      <h3>1. Single-Line Comment</h3>
      <pre><code>{`/* This rule sets the background color */
body {
  background-color: #f0f0f0;
}`}</code></pre><br/>

      <h3>2. Multi-Line Comment</h3>
      <pre><code>{`/* 
   This section contains styles for the header
   It includes background color, text color, and padding
*/
header {
  background-color: #333;
  color: #fff;
  padding: 20px;
}`}</code></pre><br/>

      <h3>3. Commenting Out Code</h3>
      <pre><code>{`/* 
.old-style {
  color: red;
}
*/
.new-style {
  color: blue;
}`}</code></pre><br/>

      <h3>4. Section Headers</h3>
      <pre><code>{`/* ================================
   Global Styles
   ================================ */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

/* ================================
   Header Styles
   ================================ */
header {
  background-color: #333;
  color: #fff;
  padding: 10px;
}`}</code></pre><br/>

      <h3>5. Explaining Complex Rules</h3>
      <pre><code>{`/* 
   The following rule centers the content inside a flex container
   Align items vertically and horizontally in the center
*/
.container {
  display: flex;
  justify-content: center; /* Horizontal center */
  align-items: center; /* Vertical center */
  height: 100vh;
}`}</code></pre><br/>

      <h2 style={{paddingBottom:"6px"}}>Best Practices</h2>
      <ul>
        <li><strong>Be Descriptive:</strong> Write clear and descriptive comments to explain the purpose and function of the code.</li><br />
        <li><strong>Keep Comments Updated:</strong> Ensure comments are updated if the associated code changes. Outdated comments can be misleading.</li><br />
        <li><strong>Use Comments Sparingly:</strong> Avoid over-commenting or using comments to explain trivial code. Comments should provide value and clarity.</li>
      </ul><br/>

      <h2>Example of CSS with Comments</h2>
      <pre><code>{`/* ================================
   Resetting Default Styles
   ================================ */
body, h1, h2, h3, p {
  margin: 0;
  padding: 0;
}

/* ================================
   Header Styles
   ================================ */
header {
  background-color: #333;
  color: #fff;
  padding: 15px;
}

header h1 {
  font-size: 24px;
}

/* ================================
   Navigation Menu
   ================================ */
nav ul {
  list-style: none;
  padding: 0;
}

nav ul li {
  display: inline;
  margin-right: 20px;
}

nav ul li a {
  color: #fff;
  text-decoration: none;
}

/* ================================
   Footer Styles
   ================================ */
footer {
  background-color: #222;
  color: #aaa;
  padding: 10px;
  text-align: center;
}

/* Responsive Design
   ================================ */
@media (max-width: 768px) {
  nav ul li {
    display: block;
    margin-bottom: 10px;
  }
}`}</code></pre>

      <p>Using comments effectively in your CSS can greatly improve the maintainability and readability of your stylesheets.</p>


              </div>
            )

            }






            {selectedChapter === 'chapter35' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Colors</h1>

      <p>
        CSS colors are a fundamental aspect of web design that define the colors of text, backgrounds, borders, and other elements on a web page. CSS provides several ways to specify colors, making it flexible and versatile for designing visually appealing websites. Here’s a comprehensive overview:
      </p><br/>
      
      <h2 style={{paddingBottom:"6px"}}>Ways to Specify Colors in CSS</h2>

      <h3>1. Named Colors</h3>
      <p>
        CSS supports a set of named colors that can be used directly. Some examples include <code>red</code>, <code>blue</code>, <code>green</code>, <code>black</code>, and <code>white</code>.
      </p>
      <pre><code>{`p {
  color: blue;
}`}</code></pre><br/>

      <h3>2. Hexadecimal Colors</h3>
      <p>
        Hexadecimal colors are specified using a hash (<code>#</code>) followed by six hexadecimal digits. The first two digits represent the red component, the next two represent green, and the last two represent blue.
      </p>
      <p>
        <strong>Short Form:</strong> <code>#rgb</code> (e.g., <code>#f00</code> for red)
      </p>
      <p>
        <strong>Full Form:</strong> <code>#rrggbb</code> (e.g., <code>#ff0000</code> for red)
      </p>
      <pre><code>{`div {
  background-color: #ffcc00; /* Bright yellow */
}`}</code></pre><br/>

      <h3>3. RGB Colors</h3>
      <p>
        RGB stands for Red, Green, Blue. Colors are defined using the <code>rgb()</code> function with values from <code>0</code> to <code>255</code> for each color component.
      </p>
      <pre><code>{`h1 {
  color: rgb(255, 0, 0); /* Red */
}`}</code></pre><br/>

      <h3>4. RGBA Colors</h3>
      <p>
        RGBA is an extension of RGB that includes an alpha (opacity) value. The <code>rgba()</code> function allows you to specify colors with transparency.
      </p>
      <pre><code>{`button {
  background-color: rgba(0, 255, 0, 0.5); /* Semi-transparent green */
}`}</code></pre><br/>

      <h3>5. HSL Colors</h3>
      <p>
        HSL stands for Hue, Saturation, Lightness. The <code>hsl()</code> function is used to define colors based on these three components.
      </p>
      <p>
        <strong>Hue:</strong> The type of color, represented as an angle from <code>0</code> to <code>360</code> degrees.
      </p>
      <p>
        <strong>Saturation:</strong> The intensity of the color, from <code>0%</code> (gray) to <code>100%</code> (full color).
      </p>
      <p>
        <strong>Lightness:</strong> The brightness of the color, from <code>0%</code> (black) to <code>100%</code> (white).
      </p>
      <pre><code>{`p {
  color: hsl(120, 100%, 50%); /* Pure green */
}`}</code></pre><br/>

      <h3>6. HSLA Colors</h3>
      <p>
        HSLA extends HSL by adding an alpha (opacity) component. The <code>hsla()</code> function specifies colors with transparency.
      </p>
      <pre><code>{`header {
  background-color: hsla(210, 50%, 50%, 0.8); /* Semi-transparent blue */
}`}</code></pre><br/>

      <h3>7. CSS Color Functions</h3>
      <p>
        <strong><code>color()</code>:</strong> The <code>color()</code> function can be used for specifying colors in various color spaces like <code>srgb</code>, <code>display-p3</code>, <code>a98-rgb</code>, etc.
      </p>
      <pre><code>{`.box {
  color: color(display-p3 1 0 0 / 0.5); /* Semi-transparent red in display P3 color space */
}`}</code></pre>
      <p>
        <strong><code>currentColor</code>:</strong> A keyword that uses the current color value of an element for its property.
      </p>
      <pre><code>{`.bordered {
  border: 1px solid currentColor; /* Uses the color of the text */
}`}</code></pre><br/>

      <h4>Examples of Using Colors in CSS</h4>
      <pre><code>{`/* Named Color */
body {
  background-color: lightgray;
}

/* Hexadecimal Color */
h2 {
  color: #3498db; /* Blue */
}

/* RGB Color */
a {
  color: rgb(255, 87, 34); /* Orange */
}

/* RGBA Color */
nav {
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent black */
}

/* HSL Color */
footer {
  color: hsl(0, 100%, 50%); /* Red */
}

/* HSLA Color */
button {
  background-color: hsla(120, 100%, 50%, 0.3); /* Semi-transparent green */
}`}</code></pre><br/>

      <h2 style={{paddingBottom:"6px"}}>Best Practices for Using Colors in CSS</h2>
      <ul>
        <li><strong>Contrast:</strong> Ensure sufficient contrast between text and background colors to improve readability.</li><br />
        <li><strong>Accessibility:</strong> Use color combinations that are friendly for colorblind users. Tools like <a href="https://colorsafe.co/" target="_blank" rel="noopener noreferrer">Color Safe</a> and <a href="https://webaim.org/resources/contrastchecker/" target="_blank" rel="noopener noreferrer">WebAIM Contrast Checker</a> can help.</li><br />
        <li><strong>Consistency:</strong> Use a consistent color palette across your website to maintain a cohesive design.</li><br />
        <li><strong>Use Variables:</strong> For easier maintenance, define color values as CSS variables.</li><br />
      </ul>
      <pre><code>{`:root {
  --primary-color: #3498db;
  --secondary-color: #2ecc71;
}

.header {
  color: var(--primary-color);
}

.button {
  background-color: var(--secondary-color);
}`}</code></pre>

      <p>By understanding and effectively utilizing these CSS color options, you can enhance the visual appeal and user experience of your web pages.</p>
   


   </div>
            )}



{selectedChapter === 'chapter36' && (
              <div className={style.chaptercontent}>

         <h1 className={style.heading} >Backgrounds</h1>
        

    
      <p>
        CSS colors are a fundamental aspect of web design that define the colors of text, backgrounds, borders, and other elements on a web page. CSS provides several ways to specify colors, making it flexible and versatile for designing visually appealing websites. Here’s a comprehensive overview:
      </p><br />
      
      <section>
        <h2 style={{paddingBottom:"6px"}}>Ways to Specify Colors in CSS</h2>

        <h3>1. Named Colors</h3>
        <div className={style.codeExample}>
          <code>
            {`p {\n  color: blue;\n}`}
          </code>
        </div>
        <p>CSS supports a set of named colors that can be used directly. Some examples include <code>red</code>, <code>blue</code>, <code>green</code>, <code>black</code>, and <code>white</code>.</p><br />

        <h3>2. Hexadecimal Colors</h3>
       
          <code>
            {`div {\n  background-color: #ffcc00; /* Bright yellow */\n}`}
          </code>
    
        <p>Hexadecimal colors are specified using a hash (<code>#</code>) followed by six hexadecimal digits. The first two digits represent the red component, the next two represent green, and the last two represent blue.</p><br/>

        <h3>3. RGB Colors</h3>
 
          <code>
            {`h1 {\n  color: rgb(255, 0, 0); /* Red */\n}`}
          </code>
       <br/>
        <p>RGB stands for Red, Green, Blue. Colors are defined using the <code>rgb()</code> function with values from <code>0</code> to <code>255</code> for each color component.</p><br/>

        <h3>4. RGBA Colors</h3>
      
          <code>
            {`button {\n  background-color: rgba(0, 255, 0, 0.5); /* Semi-transparent green */\n}`}
          </code>
    
        <p>RGBA is an extension of RGB that includes an alpha (opacity) value. The <code>rgba()</code> function allows you to specify colors with transparency.</p><br/>

        <h3>5. HSL Colors</h3>
       
          <code>
            {`p {\n  color: hsl(120, 100%, 50%); /* Pure green */\n}`}
          </code>
  
        <p>HSL stands for Hue, Saturation, Lightness. The <code>hsl()</code> function is used to define colors based on these three components.</p><br/>

        <h3>6. HSLA Colors</h3>

          <code>
            {`header {\n  background-color: hsla(210, 50%, 50%, 0.8); /* Semi-transparent blue */\n}`}
          </code>
   
        <p>HSLA extends HSL by adding an alpha (opacity) component. The <code>hsla()</code> function specifies colors with transparency.</p><br/>

        <h3 style={{paddingBottom:"6px"}}>7. CSS Color Functions</h3>

        <h4>Color Function</h4>
       
          <code>
            {`.box {\n  color: color(display-p3 1 0 0 / 0.5); /* Semi-transparent red in display P3 color space */\n}`}
          </code>
  
        <p>The <code>color()</code> function can be used for specifying colors in various color spaces like <code>srgb</code>, <code>display-p3</code>, <code>a98-rgb</code>, etc.</p><br />

        <h4>Current Color</h4>
       
          <code>
            {`.bordered {\n  border: 1px solid currentColor; /* Uses the color of the text */\n}`}
          </code>
       
        <p><code>currentColor</code> is a keyword that uses the current color value of an element for its property.</p><br />

        <h4>Examples of Using Colors in CSS</h4>
       
          <code>
            {`/* Named Color */\nbody {\n  background-color: lightgray;\n}\n\n/* Hexadecimal Color */\nh2 {\n  color: #3498db; /* Blue */\n}\n\n/* RGB Color */\na {\n  color: rgb(255, 87, 34); /* Orange */\n}\n\n/* RGBA Color */\nnav {\n  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent black */\n}\n\n/* HSL Color */\nfooter {\n  color: hsl(0, 100%, 50%); /* Red */\n}\n\n/* HSLA Color */\nbutton {\n  background-color: hsla(120, 100%, 50%, 0.3); /* Semi-transparent green */\n}`}
          </code><br /><br />


        <h2 style={{paddingBottom:"6px"}}>Best Practices for Using Colors in CSS</h2>
        <ul>
          <li><strong>Contrast:</strong> Ensure sufficient contrast between text and background colors to improve readability.</li><br />
          <li><strong>Accessibility:</strong> Use color combinations that are friendly for colorblind users. Tools like <a href="https://colorsafe.co/">Color Safe</a> and <a href="https://webaim.org/resources/contrastchecker/">WebAIM Contrast Checker</a> can help.</li><br />
          <li><strong>Consistency:</strong> Use a consistent color palette across your website to maintain a cohesive design.</li><br />
          <li><strong>Use Variables:</strong> For easier maintenance, define color values as CSS variables.</li>
        </ul><br />
     
          <code>
            {`:root {\n  --primary-color: #3498db;\n  --secondary-color: #2ecc71;\n}\n\n.header {\n  color: var(--primary-color);\n}\n\n.button {\n  background-color: var(--secondary-color);\n}`}
          </code>
     
      </section>
 
                </div>
            )}


           


            {selectedChapter === 'chapter19' && (
              <div className={style.chaptercontent}>
 <h1 className={style.heading} >Borders</h1>


        <p>
          In CSS, <strong>borders</strong> are used to create outlines around elements, helping to define their boundaries and enhance the visual layout of a webpage. Borders can be customized in several ways, including their width, style, and color.
        </p><br />

        <h2 style={{paddingBottom:"6px"}}>Key Aspects of Borders in CSS</h2>

        <h3>1. Border Properties:</h3>
        <ul>
          <li>
            <strong>`border`:</strong> A shorthand property to set the width, style, and color all at once.
            <pre>
              <code>border: 2px solid black; {/* 2px wide, solid line, black color */}</code>
            </pre>
          </li><br />

          <li>
            <strong>`border-width`:</strong> Specifies the thickness of the border. Can use specific values (e.g., `px`, `em`) or keywords (`thin`, `medium`, `thick`).
            <pre>
              <code>border-width: 3px;</code>
            </pre>
          </li><br />

          <li>
            <strong>`border-style`:</strong> Defines the style of the border. Common styles include:
            <ul>
              <li><code>none</code>: No border.</li><br />
              <li><code>solid</code>: A solid line.</li><br />
              <li><code>dashed</code>: A dashed line.</li><br />
              <li><code>dotted</code>: A dotted line.</li><br />
              <li><code>double</code>: A double line.</li>
            </ul>
            <pre>
              <code>border-style: dashed;</code>
            </pre>
          </li><br />

          <li>
            <strong>`border-color`:</strong> Sets the color of the border.
            <pre>
              <code>border-color: red;</code>
            </pre>
          </li>
        </ul><br />

        <h3>2. Individual Border Sides:</h3>
        <p>
          You can specify borders for each side of an element individually:
          <ul>
            <li><code>border-top</code></li><br />
            <li><code>border-right</code></li><br />
            <li><code>border-bottom</code></li><br />
            <li><code>border-left</code></li>
          </ul>
          <pre>
            <code>border-top: 1px solid blue; {/* Blue border at the top */}</code>
          </pre>
        </p><br />

        <h3>3. Example Usage:</h3>
        <p>
          Here’s an example of how to apply borders to an element:
        </p>
        <pre>
          <code>
            {`.box {`}
            <br />
            {`  border: 2px solid green;`}
            <br />
            {`  padding: 10px;`}
            <br />
            {`  margin: 20px;`}
            <br />
            {`}`}
          </code>
        </pre>
        <pre>
          <code>
            {`<div class="box">This is a bordered box.</div>`}
          </code>
        </pre><br />

        <h2>Conclusion</h2>
        <p>
          Borders are a fundamental aspect of CSS that enhance the design and usability of web elements. By adjusting their properties, you can create visually appealing layouts and effectively separate content.
        </p>
      

              </div>
            )

            }




            {selectedChapter === 'chapter17' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading} >Margins</h1>
           


        <h2 style={{paddingBottom:"6px"}}>1. Definition</h2>
        <ul>
          <li>The <code>margin</code> property in CSS creates space around an element, outside its border.</li><br />
          <li>It separates the element from other elements and the edge of its container.</li>
        </ul><br/>

        <h2 style={{paddingBottom:"6px"}}>2. Syntax</h2>

        <ul>
          <li><strong>Single Value</strong>: Sets the same margin on all four sides.
            <pre><code>margin: 10px; /* 10px on top, right, bottom, and left */</code></pre>
          </li><br />
          <li><strong>Two Values</strong>: First value is for the vertical margins (top and bottom), second for the horizontal margins (left and right).
            <pre><code>margin: 10px 20px; /* 10px top & bottom, 20px left & right */</code></pre>
          </li><br />
          <li><strong>Three Values</strong>: First value is for the top margin, second for the horizontal margins, and third for the bottom margin.
            <pre><code>margin: 10px 20px 30px; /* 10px top, 20px left & right, 30px bottom */</code></pre>
          </li><br />
          <li><strong>Four Values</strong>: Applies to top, right, bottom, and left margins respectively.
            <pre><code>margin: 10px 20px 30px 40px; /* Top: 10px, Right: 20px, Bottom: 30px, Left: 40px */</code></pre>
          </li>
        </ul><br/>

        <h2 style={{paddingBottom:"6px"}}>3. Special Values</h2>
        <ul>
          <li><code>auto</code>: Used for automatic margin calculation, commonly for centering elements horizontally.
            <pre><code>margin: 0 auto; /* Horizontally centers an element within its container */</code></pre>
          </li><br />
          <li><code>inherit</code>: Inherits the margin value from the parent element.
            <pre><code>margin: inherit; /* Inherits margin from parent */</code></pre>
          </li><br />
          <li><code>initial</code>: Sets the property to its default value.
            <pre><code>margin: initial; /* Resets to default margin value */</code></pre>
          </li><br />
          <li><code>unset</code>: Resets the property to its natural value, based on the element's context.
            <pre><code>margin: unset; /* Resets margin to natural value based on context */</code></pre>
          </li>
        </ul><br/>

        <h2 style={{paddingBottom:"6px"}}>4. Margin Collapse</h2>
        <ul>
          <li><strong>Vertical Margin Collapse</strong>: When two vertical margins meet, the larger margin value is used and the smaller margin is discarded.
            <pre><code>
.element1 &#123;
  margin-bottom: 20px;
&#125;

.element2 &#123;
  margin-top: 30px;
&#125;

/* The margin between .element1 and .element2 will be 30px */
            </code></pre>
          </li>
        </ul><br/>

        <h2 style={{paddingBottom:"6px"}}>5. Examples</h2>
        <ul>
          <li><strong>Basic Margin Application</strong>
            <pre><code>.box &#123;
  margin: 15px; /* 15px margin on all sides */
&#125;</code></pre>
          </li><br />
          <li><strong>Centering an Element</strong>
            <pre><code>.container &#123;
  width: 50%;
  margin: 0 auto; /* Centers the container horizontally */
&#125;</code></pre>
          </li><br />
          <li><strong>Different Margins for Each Side</strong>
            <pre><code>.box &#123;
  margin: 10px 20px 30px 40px; /* Top: 10px, Right: 20px, Bottom: 30px, Left: 40px */
&#125;</code></pre>
          </li>
        </ul><br/>

        <h2 style={{paddingBottom:"6px"}}>6. Best Practices</h2>
        <ul>
          <li><strong>Consistency</strong>: Maintain consistent margins for uniform spacing.</li><br />
          <li><strong>Responsive Design</strong>: Adjust margins for different screen sizes using media queries.</li><br />
          <li><strong>Avoid Overuse</strong>: Use margins judiciously to avoid complex layouts.</li>
        </ul><br/>

        <h2 style={{paddingBottom:"6px"}}>7. Additional Tips</h2>
        <ul>
          <li>Use <strong>CSS variables</strong> to manage margin values for a consistent design.
            <pre><code>:root &#123;
  --margin-large: 20px;
&#125;

.element &#123;
  margin: var(--margin-large); /* Uses the CSS variable */
&#125;</code></pre>
          </li>
        </ul><br/>

        <p>By understanding and using the <code>margin</code> property effectively, you can better manage the spacing and layout of elements on your web pages, resulting in a more organized and visually appealing design.</p>
     
  



              </div>
            )

            }





{selectedChapter === 'chapter18' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading} >Padding</h1>
           
             

      
      <p>
        Padding in CSS is the space between the content of an element and its border. It is used to create space within an element, enhancing the visual layout and improving readability.
      </p><br/>

     <h2 style={{paddingBottom:"6px"}}> Key Points:</h2>

      <ol>
        <li>
          <strong >Basic Concept:</strong>
          <ul>
            <li><strong>Padding</strong> is the area inside the border, between the border and the content.</li><br />
            <li>It pushes the content away from the edges of the element.</li>
          </ul>
        </li><br/>

        <li>
          <strong>Syntax:</strong>
          <pre>
            <code>{`padding: [top] [right] [bottom] [left];`}</code>
          </pre><br/>
          <ul>
            <li>
              <strong>Single Value:</strong> Applies the same padding to all four sides.
              <pre><code>{`padding: 10px;`}</code></pre>
            </li><br/>
            <li>
              <strong>Two Values:</strong> The first value applies to the top and bottom; the second to the right and left.
              <pre><code>{`padding: 10px 20px;`}</code></pre>
            </li><br/>
            <li>
              <strong>Three Values:</strong> The first value is for the top, the second for the right and left, and the third for the bottom.
              <pre><code>{`padding: 10px 20px 30px;`}</code></pre>
            </li><br/>
            <li>
              <strong>Four Values:</strong> The values apply to the top, right, bottom, and left in that order.
              <pre><code>{`padding: 10px 20px 30px 40px;`}</code></pre>
            </li>
          </ul>
        </li><br/>

        <li>
          <strong>Individual Sides:</strong>
          <pre>
            <code>{`padding-top: 10px;\npadding-right: 20px;\npadding-bottom: 30px;\npadding-left: 40px;`}</code>
          </pre>
        </li><br/>

        <li>
          <strong>Units:</strong>
          <ul>
            <li>
              Padding can be specified in various units such as pixels (px), ems (em), percentages (%), etc.
              <pre><code>{`padding: 1em;  /* Relative to the font-size of the element */\npadding: 5%;   /* Percentage of the element’s width */`}</code></pre>
            </li>
          </ul>
        </li><br/>

        <li>
          <strong>Box Model Context:</strong>
          <p>
            Padding is part of the CSS box model, which includes margins, borders, padding, and the content area. The total width of an element is calculated as:
          </p>
          <pre><code>{`Total Width = Content Width + Padding (left + right) + Border (left + right) + Margin (left + right)`}</code></pre>
        </li><br/>

        <li>
          <strong>Impact on Layout:</strong>
          <ul>
            <li>
              Adding padding increases the space within an element, which can affect the layout of surrounding elements.
            </li>
            <li>
              It does not affect the element’s width or height if `box-sizing: border-box;` is used, which includes padding and border in the element's total width and height.
            </li>
          </ul>
        </li>
      </ol><br/>

      <p><strong style={{paddingBottom:"6px"}}>Examples:</strong></p>

      <ul>
        <li>
          <strong>Uniform Padding:</strong>
          <pre><code>{`.box {\n  padding: 20px;\n  border: 1px solid black;\n}`}</code></pre>
        </li>
        <li>
          <strong>Different Padding for Each Side:</strong>
          <pre><code>{`.box {\n  padding: 10px 20px 30px 40px;\n  border: 1px solid black;\n}`}</code></pre>
        </li>
        <li>
          <strong>Responsive Padding:</strong>
          <pre><code>{`.box {\n  padding: 2vw;  /* Padding based on viewport width */\n  border: 1px solid black;\n}`}</code></pre>
        </li>
      </ul><br />

      <p><strong style={{paddingBottom:"6px"}}>Best Practices:</strong></p>
      <ul>
        <li>Use padding to create space inside elements for better readability and design.</li><br />
        <li>Avoid excessive padding that could disrupt the layout or make the design look inconsistent.</li><br />
        <li>Consider responsive design principles when setting padding values to ensure they work well on different screen sizes.</li>
      </ul>


              </div>
            )

            }






            {selectedChapter === 'chapter16' && (
              <div className={style.chaptercontent}>
              
      <h1 className={style.heading}>Height/Width</h1>

      <p>In CSS, <code>height</code> and <code>width</code> properties control the dimensions of elements. Understanding how to use these properties is fundamental for designing layouts and controlling the size of elements on a web page. Here’s a comprehensive overview:</p><br/>

      <h2>Height</h2>
      <p><strong>Definition:</strong> The <code>height</code> property sets the height of an element. It controls how tall the element will be.</p><br/>

      <h3>Syntax</h3>
      <pre>
        <code>height: [value];</code>
      </pre><br/>

      <h3 style={{paddingBottom:"6px"}}>Values</h3>
      <ul>
        <li><strong>Length Units:</strong> Fixed sizes like <code>px</code>, <code>em</code>, <code>rem</code>, <code>vh</code>, etc.
          <pre>
            <code>{`height: 100px;  /* Fixed height of 100 pixels */\nheight: 10em;   /* Height relative to the font size of the element */\nheight: 50vh;   /* Height as a percentage of the viewport height */`}</code>
          </pre>
        </li><br />
        <li><strong>Percentage:</strong> Relative to the height of the parent element.
          <pre>
            <code>height: 50%;    /* 50% of the height of the parent element */</code>
          </pre>
        </li><br />
        <li><strong>Auto:</strong> The height is determined by the content or other constraints.
          <pre>
            <code>height: auto;   /* Height is determined by the content */</code>
          </pre>
        </li>
      </ul><br />

      <p><strong>Usage:</strong> Often used to define fixed heights for elements, control layouts, and create responsive designs.</p><br/>

      <h2>Width</h2>
      <p><strong>Definition:</strong> The <code>width</code> property sets the width of an element. It controls how wide the element will be.</p><br />

      <h3>Syntax</h3>
      <pre>
        <code>width: [value];</code>
      </pre><br />

      <h3 style={{paddingBottom:"6px"}}>Values</h3>
      <ul>
        <li><strong>Length Units:</strong> Fixed sizes like <code>px</code>, <code>em</code>, <code>rem</code>, <code>vw</code>, etc.
          <pre>
            <code>{`width: 200px;   /* Fixed width of 200 pixels */\nwidth: 15em;    /* Width relative to the font size of the element */\nwidth: 50vw;    /* Width as a percentage of the viewport width */`}</code>
          </pre>
        </li><br />
        <li><strong>Percentage:</strong> Relative to the width of the parent element.
          <pre>
            <code>width: 75%;     /* 75% of the width of the parent element */</code>
          </pre>
        </li><br />
        <li><strong>Auto:</strong> The width is determined by the content or other constraints.
          <pre>
            <code>width: auto;    /* Width is determined by the content */</code>
          </pre>
        </li>
      </ul><br />

      <p><strong>Usage:</strong> Commonly used for setting fixed widths, creating flexible layouts, and designing responsive elements.</p><br/>

      <h2>Box Model Context</h2>
      <p style={{paddingBottom:"6px"}}>In CSS, the width and height of an element are part of the box model, which includes the following components:</p>
      <ul>
        <li><strong>Content:</strong> The actual content of the element (text, images, etc.).</li><br />
        <li><strong>Padding:</strong> Space between the content and the border.</li><br />
        <li><strong>Border:</strong> The border around the padding (if any).</li><br />
        <li><strong>Margin:</strong> Space outside the border, separating the element from others.</li>
      </ul><br />
      <p>The total dimensions of an element can be calculated with these properties as follows:</p>
      <pre>
        <code>{`Total Width = Width + Padding (left + right) + Border (left + right) + Margin (left + right)\nTotal Height = Height + Padding (top + bottom) + Border (top + bottom) + Margin (top + bottom)`}</code>
      </pre><br/>

      <h2>Box-Sizing Property</h2>
      <p><strong>Definition:</strong> The <code>box-sizing</code> property controls how the width and height of an element are calculated.</p><br />

      <h3>Values</h3>
      <ul>
        <li><strong><code>content-box</code> (default):</strong> The width and height apply only to the content box. Padding, borders, and margins are added outside.
          <pre>
            <code>box-sizing: content-box;</code>
          </pre>
        </li><br />
        <li><strong><code>border-box</code>:</strong> The width and height include padding and border. This makes sizing elements easier.
          <pre>
            <code>box-sizing: border-box;</code>
          </pre>
        </li>
      </ul><br />

      <h2>Examples</h2>
      <ol>
        <li><strong>Fixed Dimensions:</strong>
          <pre>
            <code>{`.box {\n  width: 300px;\n  height: 150px;\n  border: 2px solid black;\n}`}</code>
          </pre>
        </li><br />
        <li><strong>Percentage Dimensions:</strong>
          <pre>
            <code>{`.container {\n  width: 80%;  /* 80% of the width of the parent element */\n  height: 50vh; /* 50% of the viewport height */\n}`}</code>
          </pre>
        </li><br />
        <li><strong>Responsive Design:</strong>
          <pre>
            <code>{`.responsive {\n  width: 100%; /* Full width of the parent element */\n  height: auto; /* Height adjusts automatically based on content */\n}`}</code>
          </pre>
        </li><br />
        <li><strong>Using Box-Sizing:</strong>
          <pre>
            <code>{`.box {\n  box-sizing: border-box;\n  width: 300px;\n  height: 150px;\n  padding: 20px;\n  border: 2px solid black;\n}`}</code>
          </pre>
        </li>
      </ol><br />

      <p>Understanding and using the <code>height</code> and <code>width</code> properties effectively helps in creating well-structured layouts and responsive designs.</p>
   

              </div>
            )}





            {selectedChapter === 'chapter15' && (
             <div className={style.chaptercontent}>
           <h1 className={style.heading} >Box Model</h1>



     
      <p>
        The CSS box model is a fundamental concept in web design that describes how the elements on a web page are structured and how their sizes are calculated. It helps you understand how margins, borders, padding, and content fit together to define the layout of a page. Here’s a breakdown of the components:
      </p><br/>

      <ol>
        <li>
          <strong>Content</strong>: This is the innermost part of the box, where the actual content of the element (text, images, etc.) is displayed. The size of the content area can be controlled with properties like <code>width</code> and <code>height</code>.
        </li><br/>
        <li>
          <strong>Padding</strong>: Padding is the space between the content and the border. It adds extra space inside the element, pushing the content inward. Padding can be controlled with properties like <code>padding-top</code>, <code>padding-right</code>, <code>padding-bottom</code>, and <code>padding-left</code>.
        </li><br/>
        <li>
          <strong>Border</strong>: The border wraps around the padding (if any) and the content. You can set the border’s width, style, and color using properties like <code>border-width</code>, <code>border-style</code>, and <code>border-color</code>. The border creates a visual line around the element.
        </li><br/>
        <li>
          <strong>Margin</strong>: Margin is the outermost layer, creating space between the element's border and the surrounding elements. It pushes other elements away from the current element. You can control margins with properties like <code>margin-top</code>, <code>margin-right</code>, <code>margin-bottom</code>, and <code>margin-left</code>.
        </li>
      </ol><br/>

      <p>Here’s a visual representation:</p>

      <pre>
{`+-----------------------+
|       Margin          |
|  +-----------------+  |
|  |    Border       |  |
|  |  +-----------+  |  |
|  |  |  Padding   |  |  |
|  |  | +-------+ |  |  |
|  |  | |Content| |  |  |
|  |  | +-------+ |  |  |
|  |  +-----------+  |  |
|  +-----------------+  |
+-----------------------+`}
      </pre><br/>

      <h3 style={{paddingBottom:"6px"}}>Box Model Properties in CSS:</h3>
      <ul>
        <li><strong>Content</strong>: Controlled with <code>width</code> and <code>height</code>.</li><br/>
        <li><strong>Padding</strong>: Controlled with <code>padding</code>, <code>padding-top</code>, <code>padding-right</code>, <code>padding-bottom</code>, <code>padding-left</code>.</li><br/>
        <li><strong>Border</strong>: Controlled with <code>border</code>, <code>border-width</code>, <code>border-style</code>, <code>border-color</code>.</li><br/>
        <li><strong>Margin</strong>: Controlled with <code>margin</code>, <code>margin-top</code>, <code>margin-right</code>, <code>margin-bottom</code>, <code>margin-left</code>.</li>
      </ul><br/>

      <h3>Box-Sizing Property</h3>
      <p>By default, the <code>width</code> and <code>height</code> properties only apply to the content area. However, you can change this behavior using the <code>box-sizing</code> property:</p>
      <ul>
        <li><code>box-sizing: content-box;</code> (default): Width and height only include the content area. Padding, border, and margin are added outside of this.</li><br/>
        <li><code>box-sizing: border-box;</code>: Width and height include padding and border, making it easier to control the total size of the element.</li><br/>
      </ul><br/>

      <p>Understanding the box model is crucial for creating well-structured, visually appealing layouts and ensuring that your designs behave as expected across different browsers and devices.</p>
 


              </div>

            )}


{selectedChapter === 'chapter20' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Outline</h1>
                
      <p>
        In CSS, the <code>outline</code> property is used to create a line that appears around elements, similar to the border but with some distinct differences. It’s often used for focus states, accessibility purposes, and debugging layout issues. Here’s a detailed overview:
      </p><br/>

      <h2 style={{paddingBottom:"6px"}}>Key Characteristics of CSS Outline</h2>
      <ol>
        <li>
          <strong>Outlines vs. Borders</strong>:
          <ul>
            <li><strong>Outline</strong>: Unlike borders, outlines do not take up space in the layout; they do not affect the element’s size or position. They are drawn outside the element’s border edge.</li><br/>
            <li><strong>Border</strong>: Borders are part of the element's box model and occupy space, affecting the layout and size of the element.</li><br/>
          </ul>
        </li><br/>
        <li>
          <strong>Properties</strong>:
          <ul>
            <li><strong><code>outline-width</code></strong>: Defines the width of the outline. Can be specified in pixels, ems, or other length units.</li><br/>
            <li><strong><code>outline-style</code></strong>: Specifies the style of the outline. Common values include <code>solid</code>, <code>dashed</code>, <code>dotted</code>, <code>double</code>, and <code>none</code>.</li><br/>
            <li><strong><code>outline-color</code></strong>: Defines the color of the outline. You can use color names, hex codes, RGB, RGBA, HSL, or HSLA values.</li><br/>
            <li><strong><code>outline</code></strong>: A shorthand property that combines <code>outline-width</code>, <code>outline-style</code>, and <code>outline-color</code> into one declaration.</li><br/>
          </ul>
        </li>
      </ol>

      <h2>Example Usage</h2>
      <p>Here’s a basic example of how to use the <code>outline</code> property in CSS:</p>
      <pre>
{`/* Apply a solid red outline */
.example {
  outline: 2px solid red;
}`}
      </pre><br/>

      <h2>Outline Properties in Detail</h2>
      <ul>
        <li><strong><code>outline-width</code></strong>: Sets the width of the outline. Defaults to <code>medium</code> if not specified.
          <pre>
{`.example {
  outline-width: 3px; /* Can be px, em, etc. */
}`}
          </pre>
        </li><br />
        <li><strong><code>outline-style</code></strong>: Sets the style of the outline. It must be specified; otherwise, the outline will not be visible.
          <pre>
{`.example {
  outline-style: dashed; /* Options: solid, dashed, dotted, double, groove, ridge, inset, outset */
}`}
          </pre>
        </li><br />
        <li><strong><code>outline-color</code></strong>: Sets the color of the outline. If not specified, the outline inherits the color from the <code>color</code> property.
          <pre>
{`.example {
  outline-color: blue; /* Can be named colors, hex, rgb, rgba, hsl, hsla */
}`}
          </pre>
        </li><br />
        <li><strong><code>outline</code></strong>: A shorthand for setting all the outline properties at once.
          <pre>
{`.example {
  outline: 4px dotted green; /* width, style, color */
}`}
          </pre>
        </li>
      </ul><br/>

      <h2>Common Uses</h2>
      <ul>
        <li><strong>Accessibility</strong>: Outlines are often used to highlight elements that are focused, making it easier for keyboard and screen reader users to navigate a webpage. For instance, browsers automatically apply outlines to focused elements (e.g., form fields, buttons) to enhance accessibility.
          <pre>
{`/* Custom focus outline */
input:focus {
  outline: 2px solid #007BFF; /* Example: Blue outline on focus */
}`}
          </pre>
        </li><br />
        <li><strong>Debugging</strong>: Outlines are useful for debugging layout issues as they can help visualize the boundaries of elements without altering the layout. This helps in checking how elements are spaced and aligned.
          <pre>
{`/* Debugging layout */
.debug {
  outline: 1px solid red;
}`}
          </pre>
        </li><br />
        <li><strong>Design</strong>: Outlines can be used for decorative purposes or to create a visual effect, especially in interactive elements.
          <pre>
{`/* Decorative outline */
.button {
  outline: 3px dashed #ff5722;
}`}
          </pre>
        </li>
      </ul><br/>

      <h2>Browser Compatibility</h2>
      <p>The <code>outline</code> property is well-supported across all modern browsers. It’s also supported in older versions of major browsers, making it a reliable choice for styling and accessibility purposes.</p>

      <p>By understanding and using the <code>outline</code> property effectively, you can enhance the accessibility, design, and debugging of your web applications.</p>



              </div>
            )}






            {selectedChapter === 'chapter21' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}> Outline Width</h1>
                
                

              
      
      <p>
        The <code>outline-width</code> property in CSS is used to set the thickness of an outline around an element. It specifies how thick the outline should be. Unlike borders, outlines do not affect the element's layout or size—they are drawn outside the element's border edge and do not take up space in the document flow.
      </p><br/>

      <h2>Syntax</h2>
      <p>
        The <code>outline-width</code> property can be specified using length values or predefined keyword values:
      </p>
      
      <pre>
        `/* Using length values */
outline-width: 2px; /* Specifies a 2-pixel wide outline */<br/><br/>

outline-width: 0.5em; /* Specifies a 0.5em wide outline */<br/><br/><br/>

/* Using predefined keywords */<br/><br/>

outline-width: thin; /* Specifies a thin outline */<br/><br/>
outline-width: medium; /* Specifies a medium outline */<br/><br/>
outline-width: thick; /* Specifies a thick outline */ <br/><br/>
      </pre><br/>

      <h2>Values</h2>
      <ul>
        <li><strong>Length Values</strong>:
          <ul>
            <li><strong>Pixels (`px`)</strong>: For example, <code>outline-width: 2px;</code> sets the outline to be 2 pixels thick.</li><br/>
            <li><strong>Ems (`em`)</strong>: Relative to the font-size of the element. For example, <code>outline-width: 0.5em;</code> sets the outline thickness to half the font-size of the element.</li>
          </ul>
        </li><br/>
        <li><strong>Keyword Values</strong>:
          <ul>
            <li><strong><code>thin</code></strong>: Represents a small thickness, often around 1-2 pixels (browser-dependent).</li><br/>
            <li><strong><code>medium</code></strong>: Represents a medium thickness, usually around 3-4 pixels (default value).</li><br/>
            <li><strong><code>thick</code></strong>: Represents a larger thickness, generally around 5-6 pixels.</li><br/>
          </ul>
        </li>
      </ul><br/>

      <h2>Example Usage</h2>
      <p>Here's how you can use the <code>outline-width</code> property in your CSS:</p>
      
      <pre>
        {`/* Apply a 3-pixel wide solid outline */
.example {
  outline-width: 3px; /* 3 pixels wide */
  outline-style: solid; /* Outline style */
  outline-color: blue; /* Outline color */
}

/* Using keyword values for outlines */
.thin-outline {
  outline-width: thin; /* Thin outline */
  outline-style: solid;
  outline-color: green;
}

.medium-outline {
  outline-width: medium; /* Medium outline (default) */
  outline-style: solid;
  outline-color: red;
}

.thick-outline {
  outline-width: thick; /* Thick outline */
  outline-style: solid;
  outline-color: purple;
}`}
      </pre><br/>

      <h2>Key Points</h2>
      <ul>
        <li><strong>Non-Layout Affecting</strong>: Outlines do not affect the layout of the document. They are drawn outside the border edge and do not change the dimensions or positioning of elements.</li><br/>
        <li><strong>Default Value</strong>: If not specified, the default <code>outline-width</code> is <code>medium</code>, which is usually around 3-4 pixels but can vary by browser.</li><br/>
        <li><strong>Shorthand Property</strong>: The <code>outline</code> shorthand property can set <code>outline-width</code>, <code>outline-style</code>, and <code>outline-color</code> in a single declaration:
          <pre>
            {`.example {
  outline: 2px solid #000; /* 2px wide, solid black outline */
}`}
          </pre>
        </li>
      </ul><br/>

      <p>
        The <code>outline-width</code> property is useful for creating visual emphasis or focus indicators on elements, especially for accessibility purposes, without affecting the layout or spacing of your content.
      </p>


              </div>
            )}



           




           {selectedChapter === 'chapter22' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Outline Color</h1>
            <p>
        The <code>outline-color</code> property in CSS is used to set the color of an element's outline. An outline is a line that is drawn around elements, similar to a border, but it does not take up space or affect the layout of the element. It is often used to enhance focus states for accessibility or for debugging purposes.
      </p><br/>

      <h2>Syntax</h2>
      <p>
        The <code>outline-color</code> property can be specified using various color values:
      </p>

      <pre>
        `/* Using color names */<br/>
outline-color: red;<br/><br/>

/* Using hexadecimal color codes */<br/>
outline-color: #ff0000;<br/><br/>

/* Using RGB values */<br/>
outline-color: rgb(255, 0, 0);<br/><br/>

/* Using RGBA values (for opacity) */<br/>
outline-color: rgba(255, 0, 0, 0.5);<br/><br/>

/* Using HSL values */<br/>
outline-color: hsl(0, 100%, 50%);<br/><br/>

/* Using HSLA values (for opacity) */<br/>
outline-color: hsla(0, 100%, 50%, 0.5);`<br/>
      </pre><br/>

      <h2>Example Usage</h2>
      <p>Here are some examples of how to use the <code>outline-color</code> property in CSS:</p>

      <pre>
        {`/* Solid red outline */
.element-red {
  outline-width: 2px;
  outline-style: solid;
  outline-color: red;
}

/* Blue dashed outline */
.element-blue {
  outline-width: 3px;
  outline-style: dashed;
  outline-color: blue;
}

/* Semi-transparent green outline */
.element-green {
  outline-width: 4px;
  outline-style: solid;
  outline-color: rgba(0, 255, 0, 0.3); /* Green with 30% opacity */
}

/* Custom color using HSL */
.element-hsl {
  outline-width: 5px;
  outline-style: solid;
  outline-color: hsl(120, 100%, 50%); /* Bright green */
}`}
      </pre><br/>

      <h2>Key Points</h2>
      <ul>
        <li><strong>Outline vs. Border</strong>: Unlike borders, outlines do not affect the element's layout or size. They are drawn outside the element's border edge and do not take up space in the document flow.</li><br/>
        <li><strong>Default Behavior</strong>: If <code>outline-color</code> is not specified, it defaults to the current color of the element (<code>color</code> property), if <code>outline-style</code> is set.</li><br/>
        <li><strong>Shorthand Property</strong>: You can use the <code>outline</code> shorthand property to set <code>outline-width</code>, <code>outline-style</code>, and <code>outline-color</code> all at once:
          <pre>
            {`.example {
  outline: 2px solid blue; /* 2px wide, solid blue outline */
}`}
          </pre>
        </li>
      </ul>

      <p>
        Using <code>outline-color</code> effectively allows you to control the appearance of outlines to improve visual emphasis and accessibility, without impacting the layout of your elements.
      </p>
    
              </div>
            )}




            {selectedChapter === 'chapter23' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Outline Shorthand</h1>
                
    <p>
      The CSS <code>outline</code> shorthand property allows you to set the <code>outline-width</code>, <code>outline-style</code>, and <code>outline-color</code> properties in a single declaration. This shorthand simplifies your CSS by combining these three properties into one line of code.
    </p><br/>

    <h2>Syntax</h2>
    <pre>
      {`outline: [outline-width] [outline-style] [outline-color];`}
    </pre><br/>

    <h2>Parameters</h2>
    <ul>
      <li><strong><code>outline-width</code></strong>: Specifies the thickness of the outline. It can be a length value (e.g., <code>2px</code>, <code>0.5em</code>) or a keyword (<code>thin</code>, <code>medium</code>, <code>thick</code>).</li><br/>
      <li><strong><code>outline-style</code></strong>: Specifies the style of the outline. Common values include <code>solid</code>, <code>dashed</code>, <code>dotted</code>, <code>double</code>, <code>groove</code>, <code>ridge</code>, <code>inset</code>, and <code>outset</code>. The <code>outline-style</code> is required; if omitted, the outline will not be visible.</li><br/>
      <li><strong><code>outline-color</code></strong>: Specifies the color of the outline. It can be a color name, a hexadecimal color code, RGB values, RGBA values, HSL values, or HSLA values.</li><br/>
    </ul>

    <h2>Examples</h2>
    <p>Here are a few examples to illustrate how to use the <code>outline</code> shorthand property:</p>

    <h3>1. Basic Usage</h3>
    <pre>
      {`/* 2px wide solid red outline */
.example {
  outline: 2px solid red;
}`}
    </pre>

    <h3>2. Using Different Widths and Styles</h3>
    <pre>
      {`/* 3px wide dashed blue outline */
.example-dashed {
  outline: 3px dashed blue;
}`}
    </pre>

    <h3>3. Using RGBA for Transparency</h3>
    <pre>
      {`/* 4px wide solid semi-transparent green outline */
.example-transparent {
  outline: 4px solid rgba(0, 255, 0, 0.5); /* 50% opacity */
}`}
    </pre>

    <h3>4. Combining HSL Colors</h3>
    <pre>
      {`/* 5px wide solid outline with a custom HSL color */
.example-hsl {
  outline: 5px solid hsl(120, 100%, 50%); /* Bright green */
}`}
    </pre><br/>

    <h2>Key Points</h2>
    <ul>
      <li><strong>Order Matters</strong>: The <code>outline</code> shorthand property values should be provided in the order of <code>outline-width</code>, <code>outline-style</code>, and <code>outline-color</code>. If any property is omitted, it defaults to its initial value. For example, if you only specify <code>outline-width</code> and <code>outline-style</code>, the <code>outline-color</code> will default to the current color of the element.</li><br/>
      <li><strong>Defaults</strong>: If only one value is provided, it sets <code>outline-width</code> and defaults <code>outline-style</code> to <code>solid</code>, and <code>outline-color</code> to the current color of the element. For example, <code>outline: 2px;</code> will be interpreted as <code>outline-width: 2px; outline-style: solid; outline-color: currentColor;</code>.</li><br/>
    </ul><br/>

    <h2>Browser Support</h2>
    <p>The <code>outline</code> shorthand property is widely supported across all modern browsers, making it a reliable choice for styling outlines.</p>

    <p>By using the <code>outline</code> shorthand property, you can streamline your CSS code and apply outlines more efficiently, which can be particularly useful for focus states, debugging, or enhancing visual design.</p>
  

              </div>
            )}





{selectedChapter === 'chapter24' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Outline Offset</h1>



      <p>
        The <code>outline-offset</code> property in CSS is used to adjust the position of an outline relative to an element's border edge. An outline is a line that is drawn around elements, outside of the border edge, and it does not take up space or affect the layout.
      </p><br/>
      <p>Here’s how the <code>outline-offset</code> property works:</p>
      <ul>
        <li><strong>Positive Values:</strong> When you set a positive value for <code>outline-offset</code>, the outline is pushed away from the border edge, creating more space between the element’s border and the outline.</li><br/>
        <li><strong>Negative Values:</strong> When you set a negative value for <code>outline-offset</code>, the outline is drawn closer to the border edge, and it may even overlap the border.</li><br/>
        <li><strong>Default Value:</strong> The default value for <code>outline-offset</code> is <code>0</code>, meaning the outline is positioned directly on the border edge.</li><br/>
      </ul>
      <h2>Example Usage</h2>
      <pre>
        <code>
{`.element {
  outline: 2px solid blue;
  outline-offset: 5px; /* Pushes the outline 5px away from the element's border */
}`}
        </code>
      </pre>
      <p>
        In this example, the outline is 2 pixels thick and blue, and it is offset 5 pixels away from the element's border.
      </p>
      <p>
        This property is particularly useful for improving the visibility of focus indicators or creating visual effects that require precise control over the positioning of outlines.
      </p>



                </div>
            )}





{selectedChapter === 'chapter41' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Text </h1>
           
 
      <p>
        In CSS, the <code>text</code> properties are used to control the appearance and formatting of text within an element. These properties include settings for color, alignment, decoration, spacing, and more. Here's a breakdown of some of the commonly used CSS text properties:
      </p><br />

      <h2>1. <code>color</code></h2>
      <p>Sets the color of the text.</p>
      <pre>
        <code>
{`p {
  color: blue;
}`}
        </code>
      </pre><br/>

      <h2>2. <code>font-family</code></h2>
      <p>Specifies the font of the text.</p>
      <pre>
        <code>
{`p {
  font-family: Arial, sans-serif;
}`}
        </code>
      </pre><br/>

      <h2>3. <code>font-size</code></h2>
      <p>Defines the size of the text.</p>
      <pre>
        <code>
{`p {
  font-size: 16px;
}`}
        </code>
      </pre><br/>

      <h2>4. <code>font-weight</code></h2>
      <p>Controls the thickness of the text.</p>
      <pre>
        <code>
{`p {
  font-weight: bold;
}`}
        </code>
      </pre><br/>

      <h2>5. <code>font-style</code></h2>
      <p>Sets the style of the text, such as normal, italic, or oblique.</p>
      <pre>
        <code>
{`p {
  font-style: italic;
}`}
        </code>
      </pre><br/>

      <h2>6. <code>text-align</code></h2>
      <p>Aligns the text horizontally within its container (left, right, center, justify).</p>
      <pre>
        <code>
{`p {
  text-align: center;
}`}
        </code>
      </pre><br/>

      <h2>7. <code>text-decoration</code></h2>
      <p>Adds decorations like underline, overline, line-through, or none.</p>
      <pre>
        <code>
{`p {
  text-decoration: underline;
}`}
        </code>
      </pre><br/>

      <h2>8. <code>text-transform</code></h2>
      <p>Controls text capitalization, such as uppercase, lowercase, or capitalize.</p>
      <pre>
        <code>
{`p {
  text-transform: uppercase;
}`}
        </code>
      </pre><br/>

      <h2>9. <code>letter-spacing</code></h2>
      <p>Adjusts the spacing between characters.</p>
      <pre>
        <code>
{`p {
  letter-spacing: 2px;
}`}
        </code>
      </pre><br/>

      <h2>10. <code>line-height</code></h2>
      <p>Sets the distance between lines of text.</p>
      <pre>
        <code>
{`p {
  line-height: 1.5;
}`}
        </code>
      </pre><br/>

      <h2>11. <code>word-spacing</code></h2>
      <p>Adjusts the spacing between words.</p>
      <pre>
        <code>
{`p {
  word-spacing: 4px;
}`}
        </code>
      </pre><br/>

      <h2>12. <code>text-indent</code></h2>
      <p>Specifies the indentation of the first line of a text block.</p>
      <pre>
        <code>
{`p {
  text-indent: 20px;
}`}
        </code>
      </pre><br/>

      <h2>13. <code>text-shadow</code></h2>
      <p>Adds shadow to the text.</p>
      <pre>
        <code>
{`p {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}`}
        </code>
      </pre><br/>

      <h2>14. <code>text-overflow</code></h2>
      <p>Specifies how overflowed text is displayed (e.g., ellipsis).</p>
      <pre>
        <code>
{`p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}`}
        </code>
      </pre><br/>

      <h2>15. <code>white-space</code></h2>
      <p>Controls how white space inside an element is handled (e.g., normal, nowrap).</p>
      <pre>
        <code>
{`p {
  white-space: nowrap;
}`}
        </code>
      </pre><br/>

      <h2>16. <code>text-align-last</code></h2>
      <p>Specifies the alignment of the last line of a text block (only for multiline text).</p>
      <pre>
        <code>
{`p {
  text-align-last: right;
}`}
        </code>
      </pre><br/>

      <p>
        These properties allow for extensive customization of how text appears on a webpage, providing control over visual aspects such as color, size, spacing, and more. They are essential for ensuring that text is both readable and aesthetically pleasing.
      </p>
   
          
   
              </div>
            )}



{selectedChapter === 'chapter42' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Text Color</h1>
           
             
      <p>
        In CSS, the <code>color</code> property is used to set the color of the text within an HTML element. This property can accept various types of color values, including color names, hex codes, RGB values, RGBA values, HSL values, and HSLA values.
      </p><br />

      <h2>Basic Syntax</h2>
      <pre>
        <code>
{`selector {
  color: value;
}`}
        </code>
      </pre><br/>

      <h2 style={{paddingBottom:"6px"}}>Examples</h2>

      <h3>1. <code>Named Colors</code></h3>
      <p>You can use predefined color names.</p>
      <pre>
        <code>
{`p {
  color: red; /* Sets the text color to red */
}`}
        </code>
      </pre><br/>

      <h3>2. <code>Hexadecimal Color Codes</code></h3>
      <p>You can specify colors using hex codes.</p>
      <pre>
        <code>
{`p {
  color: #3498db; /* Sets the text color to a shade of blue */
}`}
        </code>
      </pre><br/>

      <h3>3. <code>RGB Color Values</code></h3>
      <p>You can use RGB (Red, Green, Blue) values.</p>
      <pre>
        <code>
{`p {
  color: rgb(34, 193, 195); /* Sets the text color to a teal shade */
}`}
        </code>
      </pre><br/>

      <h3>4. <code>RGBA Color Values</code></h3>
      <p>RGBA (Red, Green, Blue, Alpha) allows you to specify opacity with the alpha value.</p>
      <pre>
        <code>
{`p {
  color: rgba(34, 193, 195, 0.8); /* Sets the text color to teal with 80% opacity */
}`}
        </code>
      </pre><br/>

      <h3>5. <code>HSL Color Values</code></h3>
      <p>HSL (Hue, Saturation, Lightness) values can also be used.</p>
      <pre>
        <code>
{`p {
  color: hsl(180, 100%, 50%); /* Sets the text color to cyan */
}`}
        </code>
      </pre><br/>

      <h3>6. <code>HSLA Color Values</code></h3>
      <p>HSLA (Hue, Saturation, Lightness, Alpha) is similar to HSL but includes opacity.</p>
      <pre>
        <code>
{`p {
  color: hsla(180, 100%, 50%, 0.5); /* Sets the text color to cyan with 50% opacity */
}`}
        </code>
      </pre><br/>

      <h2>Additional Notes</h2>
      <ul>
        <li><strong>Inheritance:</strong> The <code>color</code> property is inherited by child elements. This means that if you set a color on a parent element, all of its text children will inherit that color unless overridden.</li><br/>
        <li><strong>Contrast and Accessibility:</strong> When choosing text colors, ensure sufficient contrast against the background to maintain readability and accessibility. Tools like the WebAIM Contrast Checker can help evaluate color contrast ratios.</li><br/>
        <li><strong>Browser Compatibility:</strong> The <code>color</code> property is widely supported across all modern browsers.</li>
      </ul>br

      <h2>Example in Context</h2>
      <p>Here's an example of using different color values in a React component:</p>
      <pre>
        <code>
{`import React from 'react';
import styles from './YourStyles.module.css'; // Ensure this path matches your actual CSS module file

const TextColorExample = () => {
  return (
    <div>
      <p className={styles.namedColor}>This is red text.</p>
      <p className={styles.hexColor}>This is blue text using hex code.</p>
      <p className={styles.rgbColor}>This is teal text using RGB.</p>
      <p className={styles.rgbaColor}>This is teal text with 80% opacity using RGBA.</p>
      <p className={styles.hslColor}>This is cyan text using HSL.</p>
      <p className={styles.hslaColor}>This is cyan text with 50% opacity using HSLA.</p>
    </div>
  );
};

export default TextColorExample;`}
        </code>
      </pre>

      <p>And in your CSS module (YourStyles.module.css):</p>
      <pre>
        <code>
{`.namedColor {
  color: red;
}

.hexColor {
  color: #3498db;
}

.rgbColor {
  color: rgb(34, 193, 195);
}

.rgbaColor {
  color: rgba(34, 193, 195, 0.8);
}

.hslColor {
  color: hsl(180, 100%, 50%);
}

.hslaColor {
  color: hsla(180, 100%, 50%, 0.5);
}`}
        </code>
      </pre>

      <p>This setup demonstrates different ways to set text colors using CSS in a React component.</p>
 

              </div>
            )}




{selectedChapter === 'chapter43' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Text Alignment</h1>
    
    <p>In CSS, <strong>text alignment</strong> refers to the horizontal positioning of text within its container. This is controlled by the <code>text-align</code> property, which can align the text to the left, right, center, or justify it so that the text is spread evenly across the width of the container.</p><br />
    
    <p>Here are the common values for <code>text-align</code>:</p>
    
    <ol>
      <li>
        <strong>left</strong>: Aligns the text to the left edge of the container.
        <pre><code>p &#123; text-align: left; &#125;</code></pre>
      </li><br />
      <li>
        <strong>right</strong>: Aligns the text to the right edge of the container.
        <pre><code>p &#123; text-align: right; &#125;</code></pre>
      </li><br />
      <li>
        <strong>center</strong>: Centers the text within the container.
        <pre><code>p &#123; text-align: center; &#125;</code></pre>
      </li><br />
      <li>
        <strong>justify</strong>: Stretches the text so that it is evenly spaced across the container's width, aligning both the left and right edges.
        <pre><code>p &#123; text-align: justify; &#125;</code></pre>
      </li>
    </ol>
    
    <p>By using the <code>text-align</code> property, you can control how the text appears visually in relation to its container's boundaries.</p>
    
  </div>
)}


            
{selectedChapter === 'chapter44' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Text Decoration</h1>
                
    <p>
      In CSS, the <code>text-decoration</code> property is used to apply various text decorations to text elements. This property can control the appearance of text decorations such as underlines, overlines, line-throughs (strikethroughs), and more. It is commonly used to style text in ways that enhance readability or match design specifications.
    </p><br />

    <h2>Basic Syntax</h2>
    <pre><code>{`selector {\n  text-decoration: value;\n}`}</code></pre><br/>

    <h2>Possible Values</h2>
    
    <h3>1. <code>none</code></h3>
    <p>Removes any text decoration applied to the text.</p>
    <pre><code>{`p {\n  text-decoration: none;\n}`}</code></pre><br />

    <h3>2. <code>underline</code></h3>
    <p>Adds a line underneath the text.</p>
    <pre><code>{`p {\n  text-decoration: underline;\n}`}</code></pre><br />

    <h3>3. <code>overline</code></h3>
    <p>Adds a line above the text.</p>
    <pre><code>{`p {\n  text-decoration: overline;\n}`}</code></pre><br />

    <h3>4. <code>line-through</code></h3>
    <p>Adds a line through the middle of the text, also known as a strikethrough.</p>
    <pre><code>{`p {\n  text-decoration: line-through;\n}`}</code></pre><br />

    <h3>5. <code>underline overline</code></h3>
    <p>Combines both underline and overline decorations.</p>
    <pre><code>{`p {\n  text-decoration: underline overline;\n}`}</code></pre><br />

    <h3>6. <code>line-through underline</code></h3>
    <p>Combines both line-through and underline decorations.</p>
    <pre><code>{`p {\n  text-decoration: line-through underline;\n}`}</code></pre><br />

    <h2 style={{paddingBottom:"6px"}}>CSS Text Decoration Level 4 (Enhanced Control)</h2>

    <h3>1. <code>text-decoration-color</code></h3>
    <p>Specifies the color of the text decoration.</p>
    <pre><code>{`p {\n  text-decoration: underline;\n  text-decoration-color: red;\n}`}</code></pre><br />

    <h3>2. <code>text-decoration-style</code></h3>
    <p>Specifies the style of the text decoration, such as solid, double, dotted, dashed, or wavy.</p>
    <pre><code>{`p {\n  text-decoration: underline;\n  text-decoration-style: dotted;\n}`}</code></pre><br />

    <h3>3. <code>text-decoration-thickness</code></h3>
    <p>Specifies the thickness of the text decoration line.</p>
    <pre><code>{`p {\n  text-decoration: underline;\n  text-decoration-thickness: 2px;\n}`}</code></pre><br />

    <h2>Example in Context</h2>
    <p>Here’s how you can use the <code>text-decoration</code> property in a React component with some CSS styling:</p><br />

    <pre><code>{`import React from 'react';\nimport styles from './YourStyles.module.css'; // Adjust the path to your CSS module file\n\nconst TextDecorationExample = () => {\n  return (\n    <div className={styles.container}>\n      <p className={styles.noDecoration}>This text has no decoration.</p>\n      <p className={styles.underline}>This text is underlined.</p>\n      <p className={styles.overline}>This text has an overline.</p>\n      <p className={styles.lineThrough}>This text has a line through it.</p>\n      <p className={styles.doubleDecoration}>This text has both an underline and an overline.</p>\n      <p className={styles.customDecoration}>This text has a custom decoration style and color.</p>\n    </div>\n  );\n};\n\nexport default TextDecorationExample;\n`}</code></pre>
    
    <p>And in your CSS module (`YourStyles.module.css`):</p>

    <pre><code>{`.container {\n  width: 80%;\n  margin: 0 auto;\n}\n\n.noDecoration {\n  text-decoration: none;\n}\n\n.underline {\n  text-decoration: underline;\n}\n\n.overline {\n  text-decoration: overline;\n}\n\n.lineThrough {\n  text-decoration: line-through;\n}\n\n.doubleDecoration {\n  text-decoration: underline overline;\n}\n\n.customDecoration {\n  text-decoration: underline;\n  text-decoration-color: blue;\n  text-decoration-style: wavy;\n  text-decoration-thickness: 2px;\n}\n`}</code></pre><br />

    <h2 style={{paddingBottom:"6px"}}>Explanation</h2>
    <ul>
      <li><code>text-decoration: none;</code>: Removes any decoration from the text.</li><br />
      <li><code>text-decoration: underline;</code>: Adds an underline to the text.</li><br />
      <li><code>text-decoration: overline;</code>: Adds a line above the text.</li><br />
      <li><code>text-decoration: line-through;</code>: Adds a line through the text.</li><br />
      <li><code>text-decoration: underline overline;</code>: Adds both an underline and an overline.</li><br />
      <li><code>text-decoration-color</code>, <code>text-decoration-style</code>, <code>text-decoration-thickness</code>: Allow more customization of text decoration.</li>
    </ul><br />

    <p>The <code>text-decoration</code> property and its associated properties are essential for creating visually distinct text elements, improving readability, and enhancing the user experience on web pages.</p>
  

              </div>
            )}




            
{selectedChapter === 'chapter45' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Text Transformation</h1>
           
  
      <p>
        CSS text transformation refers to the use of CSS properties that allow you to control the capitalization and formatting of text in HTML elements. This is primarily done using the <code>text-transform</code> property. Here are the main values you can use with <code>text-transform</code>:
      </p><br />
      <ul>
        <li>
          <strong><code>uppercase</code></strong>: Transforms all the text to uppercase letters.
          <pre>
            <code>
              {`p {
  text-transform: uppercase;
}`}
            </code>
          </pre>
        </li><br />
        <li>
          <strong><code>lowercase</code></strong>: Transforms all the text to lowercase letters.
          <pre>
            <code>
              {`p {
  text-transform: lowercase;
}`}
            </code>
          </pre>
        </li><br />
        <li>
          <strong><code>capitalize</code></strong>: Capitalizes the first letter of each word.
          <pre>
            <code>
              {`p {
  text-transform: capitalize;
}`}
            </code>
          </pre>
        </li><br />
        <li>
          <strong><code>none</code></strong>: No transformation is applied; the text is displayed as is.
          <pre>
            <code>
              {`p {
  text-transform: none;
}`}
            </code>
          </pre>
        </li>
      </ul><br />
      <p>
        These transformations are purely cosmetic and don't actually alter the content of the text; they just change how it's displayed on the page. For example, if you use <code>text-transform: uppercase</code>, the text will appear in uppercase, but the actual HTML content remains unchanged.
      </p>
   
              </div>
            )}


{selectedChapter === 'chapter46' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Text Spacing</h1>
    
    <p>
      Text spacing in CSS refers to the control of the space between letters, words, and lines of text within an element. There are several CSS properties that allow you to manipulate text spacing:
    </p><br />

    <h3>1. <strong>Letter Spacing</strong></h3>
    <p>- Adjusts the spacing between characters in a text.</p>
    <pre>
      <code>{`letter-spacing: 2px; /* Adds 2 pixels between each letter */`}</code>
    </pre><br />

    <h3>2. <strong>Word Spacing</strong></h3>
    <p>- Adjusts the spacing between words in a text.</p>
    <pre>
      <code>{`word-spacing: 10px; /* Adds 10 pixels between each word */`}</code>
    </pre><br />

    <h3>3. <strong>Line Height</strong></h3>
    <p>- Controls the vertical spacing between lines of text.</p>
    <pre>
      <code>{`line-height: 1.5; /* Sets the space between lines to 1.5 times the font size */`}</code>
    </pre><br />

    <h3>4. <strong>Text Indent</strong></h3>
    <p>- Indents the first line of text in a block.</p>
    <pre>
      <code>{`text-indent: 30px; /* Indents the first line by 30 pixels */`}</code>
    </pre><br />

    <h3>5. <strong>Text Align</strong></h3>
    <p>- Controls the horizontal alignment of text.</p>
    <pre>
      <code>{`text-align: justify; /* Aligns the text so that both edges are flush with the left and right */`}</code>
    </pre>

    <h3>Example:</h3>
    <pre>
      <code>{`p {
    letter-spacing: 1px;
    word-spacing: 5px;
    line-height: 1.6;
    text-indent: 20px;
    text-align: justify;
}`}</code>
    </pre>

    <p>In this example, the text inside a <code>&lt;p&gt;</code> element will have extra spacing between letters and words, larger line spacing, an indent for the first line, and justified alignment.</p>
  </div>
)}



{selectedChapter === 'chapter47' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Text Shadow</h1>
           
               
    <p>
      CSS <code>text-shadow</code> is a property used to apply shadow effects to text. This can enhance the visual appeal of text by creating a sense of depth or adding a stylistic touch. The <code>text-shadow</code> property allows you to define the shadow's offset, blur radius, and color.
    </p><br/>

    <h2>Syntax</h2>
    <p>The <code>text-shadow</code> property is written with the following syntax:</p>
    <pre>
      <code>
        {`text-shadow: <offset-x> <offset-y> <blur-radius> <color>;`}
      </code>
    </pre>

    <ul>
      <li><strong><code>&lt;offset-x&gt;</code></strong>: The horizontal distance of the shadow from the text. Positive values move the shadow to the right, while negative values move it to the left.</li><br />
      <li><strong><code>&lt;offset-y&gt;</code></strong>: The vertical distance of the shadow from the text. Positive values move the shadow down, while negative values move it up.</li><br />
      <li><strong><code>&lt;blur-radius&gt;</code></strong>: The blur radius of the shadow. A higher value makes the shadow more blurred, while a value of <code>0</code> results in a sharp shadow.</li><br />
      <li><strong><code>&lt;color&gt;</code></strong>: The color of the shadow. This can be specified using named colors, HEX, RGB, RGBA, HSL, or HSLA values.</li>
    </ul><br/>

    <h2 style={{paddingBottom:"6px"}}>Examples</h2>

    <h3>1. Basic Shadow</h3>
    <pre>
      <code>
        {`h1 {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}`}
      </code>
    </pre>
    <p>This creates a shadow that is 2 pixels to the right and 2 pixels down from the text, with a 4-pixel blur radius and a semi-transparent black color.</p><br/>

    <h3>2. Multiple Shadows</h3>
    <pre>
      <code>
        {`h1 {
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5),
               -1px -1px 2px rgba(255, 255, 255, 0.5);
}`}
      </code>
    </pre>
    <p>This applies two shadows: one with a black color and one with a white color, creating a more complex effect.</p><br/>

    <h3>3. No Blur</h3>
    <pre>
      <code>
        {`p {
  text-shadow: 3px 3px 0 rgba(0, 0, 0, 0.7);
}`}
      </code>
    </pre>
    <p>This shadow has no blur radius (blur radius is set to <code>0</code>), making it appear sharp.</p><br/>

    <h3>4. Colored Shadow</h3>
    <pre>
      <code>
        {`h2 {
  text-shadow: 2px 2px 5px red;
}`}
      </code>
    </pre>
    <p>This creates a red shadow that is 2 pixels to the right and 2 pixels down from the text, with a 5-pixel blur radius.</p><br/>

    <h2 style={{paddingBottom:"6px"}}>Use Cases</h2>
    <ul>
      <li><strong><code>text-shadow</code></strong>: Adding a shadow can make text more readable against a complex or similarly-colored background.</li><br />
      <li><strong><code>text-shadow</code></strong>: Creates a 3D effect, making text appear as though it is floating above the page.</li><br />
      <li><strong><code>text-shadow</code></strong>: Can be used for decorative effects in headers, buttons, or other UI elements.</li>
    </ul><br/>

    <h2>Example Usage in a React Component</h2>
    <pre>
      <code>
        {`import React from 'react';
import style from './YourStyles.module.css'; // Update this path to your actual CSS module

const TextShadowExample = () => {
  return (
    <div className={style.container}>
      <h1 className={style.shadowedText}>CSS Text Shadow Example</h1>
      <p className={style.shadowedText}>
        Adding text shadows can enhance readability and add visual interest to your text.
      </p>
    </div>
  );
};

export default TextShadowExample;`}
      </code>
    </pre>

    <p>In your CSS module (<code>YourStyles.module.css</code>):</p>
    <pre>
      <code>
        {`.shadowedText {
  text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.7); /* Example shadow */
}`}
      </code>
    </pre>
    <p>This approach demonstrates how to apply text shadows using CSS modules in a React component. Adjust the values to achieve the desired visual effect.</p>
 
              </div>
            )}



{selectedChapter === 'chapter48' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Font Family</h1>
           
        <p>
          In CSS, the <code>font-family</code> property is used to specify the typeface for text. You can use a list of fonts, separated by commas, to provide multiple options. The browser will use the first font that is available on the user’s system.
        </p><br/>

        <h2>Here’s a basic example:</h2>
        <pre>
          <code>
            {`body {
  font-family: Arial, sans-serif;
}`}
          </code>
        </pre>

        <p>
          In this example:
          <ul>
            <li><strong>Arial</strong> is the primary font. If Arial is not available, the browser will use the next option.</li><br/>
            <li><strong>sans-serif</strong> is a generic font family that acts as a fallback if Arial is not available. It ensures that the text remains readable by using a sans-serif typeface.</li>
          </ul>
        </p><br/>

        <h2 style={{paddingBottom:"6px"}}>Common Font Families</h2>
        <ul>
          <li><strong>Serif Fonts</strong>: Fonts with small lines or decorations at the end of their strokes.
            <pre><code>Times New Roman, Times, serif</code></pre>
          </li><br />
          <li><strong>Sans-Serif Fonts</strong>: Fonts without the small lines or decorations at the end of their strokes.
            <pre><code>Arial, Helvetica, sans-serif</code></pre>
          </li><br />
          <li><strong>Monospace Fonts</strong>: Fonts where each character takes up the same amount of horizontal space.
            <pre><code>Courier New, Courier, monospace</code></pre>
          </li><br />
          <li><strong>Cursive Fonts</strong>: Fonts that mimic handwriting.
            <pre><code>Comic Sans MS, Comic Sans, cursive</code></pre>
          </li><br />
          <li><strong>Fantasy Fonts</strong>: Fonts with decorative, stylized characters.
            <pre><code>Impact, fantasy</code></pre>
          </li>
        </ul><br/>

        <h2>Using Web Fonts</h2>
        <p>
          You can also use web fonts to include fonts that are not commonly available on all devices. Services like Google Fonts and Adobe Fonts allow you to include custom fonts by adding a link to their stylesheet in your HTML or importing them directly into your CSS.
        </p><br/>

        <h3>Google Fonts Example:</h3>
        <ol>
          <li><strong>Add the link to your HTML:</strong>
            <pre>
              <code>
                {`<link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap" rel="stylesheet">`}
              </code>
            </pre>
          </li>
          <li><strong>Use the font in your CSS:</strong>
            <pre>
              <code>
                {`body {
  font-family: 'Roboto', sans-serif;
}`}
              </code>
            </pre>
          </li>
        </ol><br/>

        <h3>Importing from Google Fonts in CSS:</h3>
        <pre>
          <code>
            {`@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body {
  font-family: 'Roboto', sans-serif;
}`}
          </code>
        </pre><br/>

        <h2>Font Stack Example</h2>
        <p>
          A font stack is a list of fonts in a particular order of preference:
        </p>
        <pre>
          <code>
            {`body {
  font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
}`}
          </code>
        </pre>
        <p>
          In this stack:
          <ul>
            <li>The browser will first try to use <strong>Open Sans</strong>.</li><br />
            <li>If that isn’t available, it will try <strong>Helvetica Neue</strong>.</li><br />
            <li>If neither of those is available, it will use <strong>Arial</strong>.</li><br />
            <li>If none of the specified fonts are available, the browser will fall back to any available <strong>sans-serif</strong> font.</li>
          </ul><br/>
        </p>
        <p>
          This method helps ensure your text remains readable and visually consistent across different devices and browsers.
        </p>
  

              </div>
            )}




{selectedChapter === 'chapter49' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Font web Safe</h1>
              


      <p>
        <strong>CSS web-safe fonts</strong> are fonts that are generally available on most operating systems and web browsers, ensuring consistent text appearance across different devices without needing additional font files.
      </p><br />

      <h2>Example:</h2>
      <p>
        To use web-safe fonts in your CSS, you specify a list of fonts in the <code>font-family</code> property. The browser will use the first available font from the list.
      </p><b></b>
      
      <h3>CSS Example:</h3>
      <pre>
        <code>
{`body {
  font-family: Arial, Helvetica, sans-serif;
}`}
        </code>
      </pre>
      <p style={{paddingBottom:"6px"}}>In this example:</p>
      <ul>
        <li><strong>Arial</strong>: The browser will try to use this font first.</li><br />
        <li><strong>Helvetica</strong>: If Arial is not available, it will try Helvetica.</li><br />
        <li><strong>sans-serif</strong>: If neither Arial nor Helvetica is available, it will use any available sans-serif font.</li>
      </ul><br/>

      <h2 style={{paddingBottom:"6px"}}>Common Web-Safe Fonts:</h2>
      <ul>
        <li><strong>Serif</strong>: Times New Roman, Georgia</li><br />
        <li><strong>Sans-Serif</strong>: Arial, Helvetica, Verdana</li><br />
        <li><strong>Monospace</strong>: Courier New, Courier</li><br />
        <li><strong>Cursive</strong>: Comic Sans MS</li><br />
        <li><strong>Fantasy</strong>: Impact</li>
      </ul>
  


              </div>
            )}




            
{selectedChapter === 'chapter50' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Font Fallbacks</h1>
           
           
      
      <p>
        <strong>CSS font fallbacks</strong> are a mechanism in CSS that allows you to specify a list of fonts in the <code>font-family</code> property. If the browser cannot load the first font in the list, it will attempt to use the next one. This ensures that text remains readable and consistent even if the preferred font is unavailable.
      </p><br/>

      <h2 style={{paddingBottom:"6px"}}>How Font Fallbacks Work</h2>
      <ol>
        <li><strong>Primary Font</strong>: This is the preferred font you want to use. It will be applied if available.</li><br />
        <li><strong>Secondary Fonts</strong>: If the primary font is unavailable, the browser will try the next font in the list.</li><br />
        <li><strong>Generic Font Family</strong>: This is a catch-all option to ensure that a similar type of font is used if none of the specified fonts are available.</li>
      </ol><br/>

      <h2>Example of Font Fallbacks in CSS</h2>
      <pre>
        <code>
{`body {
  font-family: 'Roboto', 'Arial', 'Helvetica', sans-serif;
}`}
        </code>
      </pre>
      <p style={{paddingBottom:"6px"}}>In this example:</p>
      <ul>
        <li><strong>'Roboto'</strong>: The browser will first attempt to use the 'Roboto' font.</li><br/>
        <li><strong>'Arial'</strong>: If 'Roboto' is not available, the browser will try 'Arial'.</li><br/>
        <li><strong>'Helvetica'</strong>: If neither 'Roboto' nor 'Arial' is available, the browser will try 'Helvetica'.</li><br/>
        <li><strong>sans-serif</strong>: If none of the specified fonts are available, the browser will use any available sans-serif font.</li>
      </ul><br/>

      <h2 style={{paddingBottom:"6px"}}>Benefits of Font Fallbacks</h2>
      <ul>
        <li><strong>Consistency</strong>: Ensures that text is displayed in a readable font, even if the preferred font isn't available.</li><br/>
        <li><strong>User Experience</strong>: Prevents text from being displayed in an unexpected or undesirable font, which can affect the design and readability of a webpage.</li><br/>
        <li><strong>Performance</strong>: Helps avoid issues with font loading, such as when custom fonts take too long to load or fail to load.</li>
      </ul><br/>

      <h2 style={{paddingBottom:"6px"}}>Key Points</h2>
      <ul>
        <li><strong>Order Matters</strong>: The order in which fonts are listed determines their priority. The browser will use the first available font from the list.</li><br/>
        <li><strong>Generic Font Families</strong>: Including a generic font family (like <code>sans-serif</code>, <code>serif</code>, or <code>monospace</code>) ensures that a reasonable font is used if none of the specified fonts are available.</li><br/>
        <li><strong>Custom Fonts</strong>: When using custom fonts (e.g., from Google Fonts), fallback options are still important in case the custom font fails to load.</li>
      </ul><br/>

      <p>
        Using font fallbacks ensures that your typography remains functional and aesthetically pleasing across different environments and devices.
      </p>
 


              </div>
            )}





            
{selectedChapter === 'chapter51' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Font Style</h1>
     
      
      <p>
        <strong>CSS font style</strong> refers to the way you can control the appearance of text on your web pages using various CSS properties. The <code>font-style</code> property is specifically used to apply styles such as italic or oblique to text. This property is part of the broader <code>font</code> shorthand property, which allows you to set multiple font-related properties in one line.
      </p><br />

      <h2 style={{paddingBottom:"6px"}}>Key CSS Font Properties</h2>

      <ol>
        <li>
          <strong><code>font-style</code></strong>: Specifies the style of the font. Common values include:
          <ul>
            <li><strong><code>normal</code></strong>: The default style. Text is rendered in the normal upright style.</li><br />
            <li><strong><code>italic</code></strong>: Text is rendered in an italic style, which usually slants to the right.</li><br />
            <li><strong><code>oblique</code></strong>: Text is rendered in an oblique style, which is similar to italic but may not be as slanted and may look different depending on the font.</li>
          </ul>
          <pre>
            <code>
{`p {
  font-style: italic; /* Makes text italic */
}

span {
  font-style: oblique; /* Makes text oblique */
}`}
            </code>
          </pre>
        </li><br />

        <li>
          <strong><code>font-weight</code></strong>: Specifies the thickness of the font. Common values include:
          <ul>
            <li><strong><code>normal</code></strong>: Regular font weight.</li><br />
            <li><strong><code>bold</code></strong>: Bold font weight.</li><br />
            <li><strong><code>bolder</code></strong>: Bolder than the parent element.</li><br />
            <li><strong><code>lighter</code></strong>: Lighter than the parent element.</li><br />
            <li><strong>Numeric values</strong>: Ranges from 100 to 900 (e.g., <code>400</code> for normal, <code>700</code> for bold).</li>
          </ul>
          <pre>
            <code>
{`h1 {
  font-weight: bold; /* Makes text bold */
}

h2 {
  font-weight: 300; /* Lighter than normal weight */
}`}
            </code>
          </pre>
        </li><br />

        <li>
          <strong><code>font-family</code></strong>: Specifies the font to use. You can list multiple fonts as fallbacks, separated by commas.
          <pre>
            <code>
{`body {
  font-family: 'Arial', 'Helvetica', sans-serif; /* Fallbacks from left to right */
}`}
            </code>
          </pre>
        </li><br />

        <li>
          <strong><code>font-size</code></strong>: Specifies the size of the font.
          <pre>
            <code>
{`p {
  font-size: 16px; /* Sets font size to 16 pixels */
}`}
            </code>
          </pre>
        </li><br />

        <li>
          <strong><code>line-height</code></strong>: Sets the space between lines of text.
          <pre>
            <code>
{`p {
  line-height: 1.5; /* 1.5 times the font size */
}`}
            </code>
          </pre>
        </li><br />

        <li>
          <strong><code>font-variant</code></strong>: Specifies variations in font, such as small caps.
          <pre>
            <code>
{`h2 {
  font-variant: small-caps; /* Renders text in small caps */
}`}
            </code>
          </pre>
        </li>
      </ol><br />

      <h2>Example Usage</h2>
      <p>Here’s how you might use these properties together in CSS:</p>
      <pre>
        <code>
{`p {
  font-family: 'Georgia', serif;
  font-style: italic;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.6;
}`}
        </code>
      </pre><br />
      <p style={{paddingBottom:"6px"}}>In this example:</p>
      <ul>
        <li><strong><code>font-family</code></strong>: Uses Georgia as the primary font, with serif as a fallback.</li><br />
        <li><strong><code>font-style</code></strong>: Applies an italic style to the text.</li><br />
        <li><strong><code>font-weight</code></strong>: Sets the font weight to normal (400).</li><br />
        <li><strong><code>font-size</code></strong>: Sets the font size to 18 pixels.</li><br />
        <li><strong><code>line-height</code></strong>: Sets the line spacing to 1.6 times the font size.</li>
      </ul><br />

      <p>
        <strong>CSS font style</strong> allows you to control the appearance and presentation of text in a variety of ways, enhancing both readability and visual appeal in your web design.
      </p>


              </div>
            )}





            
{selectedChapter === 'chapter52' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Font Size</h1>
               

      <p>
        In CSS, the <code>font-size</code> property is used to set the size of the font for text. It determines how large or small the text appears on the screen. The <code>font-size</code> property can accept several types of values, which allows for flexible and responsive design.
      </p><br />

      <h2>Syntax</h2>
      <pre><br/>
        <code>
{`selector {
  font-size: value;
}`}
        </code>
      </pre><br/><br />

      <h2 style={{paddingBottom:"6px"}}>Types of Values</h2>

      <h3>1. Absolute Units:</h3>
      <ul>
        <li>
          <strong>px (pixels)</strong>: Fixed size units that are not affected by the user's settings or the parent container's font size.
          <pre>
            <code>
{`p {
  font-size: 16px; /* Sets font size to 16 pixels */
}`}
            </code>
          </pre>
        </li>
        <li>
          <strong>pt (points)</strong>: Used mainly in print styles. 1 point is equal to 1/72 of an inch.
          <pre>
            <code>
{`p {
  font-size: 12pt; /* Sets font size to 12 points */
}`}
            </code>
          </pre>
        </li>
      </ul><br/>

      <h3>2. Relative Units:</h3>
      <ul>
        <li>
          <strong>em</strong>: Relative to the font size of the parent element. If the parent’s font size is 16px, then <code>1em</code> equals 16px.
          <pre>
            <code>
{`div {
  font-size: 1.5em; /* Sets font size to 1.5 times the parent's font size */
}`}
            </code>
          </pre>
        </li>
        <li>
          <strong>rem</strong>: Relative to the font size of the root element (&lt;html&gt;). If the root’s font size is 16px, then <code>1rem</code> equals 16px.
          <pre>
            <code>
{`p {
  font-size: 1rem; /* Sets font size to 16px if the root font size is 16px */
}`}
            </code>
          </pre>
        </li>
        <li>
          <strong>%</strong>: Relative to the font size of the parent element. For example, <code>150%</code> is 1.5 times the parent’s font size.
          <pre>
            <code>
{`h1 {
  font-size: 120%; /* Sets font size to 120% of the parent’s font size */
}`}
            </code>
          </pre>
        </li>
      </ul><br/>

      <h3>3. Viewport Units:</h3>
      <ul>
        <li>
          <strong>vw (viewport width)</strong>: Relative to 1% of the width of the viewport (the browser window).
          <pre>
            <code>
{`h1 {
  font-size: 5vw; /* Sets font size to 5% of the viewport width */
}`}
            </code>
          </pre>
        </li>
        <li>
          <strong>vh (viewport height)</strong>: Relative to 1% of the height of the viewport.
          <pre>
            <code>
{`h1 {
  font-size: 5vh; /* Sets font size to 5% of the viewport height */
}`}
            </code>
          </pre>
        </li>
      </ul><br/>

      <h3>4. Keywords:</h3>
      <ul>
        <li>
          <strong>xx-small, x-small, small, medium, large, x-large, xx-large</strong>: These are predefined sizes that scale with the user's browser settings.
          <pre>
            <code>
{`p {
  font-size: large; /* Sets font size to a predefined large size */
}`}
            </code>
          </pre>
        </li>
      </ul><br/>

      <h2>Example Usage</h2>
      <pre>
        <code>
{`body {
  font-size: 16px; /* Base font size */
}

h1 {
  font-size: 2rem; /* 2 times the root element's font size (32px if root is 16px) */
}

p {
  font-size: 1em; /* Same as the parent element’s font size (16px) */
}

small {
  font-size: 0.75em; /* 75% of the parent’s font size (12px if parent is 16px) */
}

@media (min-width: 768px) {
  h1 {
    font-size: 4vw; /* Responsive font size based on viewport width */
  }
}`}
        </code>
      </pre><br/>

      <h2 style={{paddingBottom:"6px"}}>Key Points</h2>
      <ul>
        <li><strong>Absolute Units</strong> provide fixed sizes, useful for print or specific design requirements.</li><br />
        <li><strong>Relative Units</strong> are flexible and adapt to different contexts, making them suitable for responsive design.</li><br />
        <li><strong>Viewport Units</strong> are useful for creating responsive typography that scales with the viewport size.</li><br />
        <li><strong>Keywords</strong> provide predefined sizes for quick styling, but they are less flexible compared to numerical values.</li>
      </ul><br />

      <p>
        Using the <code>font-size</code> property effectively helps ensure that text is readable and appropriately sized for different devices and contexts.
      </p>


              </div>
            )}


            
{selectedChapter === 'chapter53' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Font Google</h1>
           
                
          
          <p>
            <strong>Google Fonts</strong> is a service provided by Google that offers a vast collection of free and open-source fonts that you can use on your website. By integrating Google Fonts into your CSS, you can easily apply these fonts to your web pages without needing to host the font files yourself.
          </p><br/>
          
          <h2 style={{paddingBottom:"6px"}}>How to Use Google Fonts in CSS</h2>

          <h3>1. Choose a Font</h3>
          <p>
            Visit the <a href="https://fonts.google.com/" target="_blank" rel="noopener noreferrer">Google Fonts website</a> and select the font(s) you want to use. You can browse through categories or search for specific fonts.
          </p><br/>

          <h3>2. Embed the Font</h3>
          <p>
            Once you’ve selected a font, Google Fonts provides an embed code that you can include in the <code>&lt;head&gt;</code> section of your HTML. This embed code typically looks like this:
          </p>
          <pre>
            <code>{`<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap">`}</code>
          </pre>
          <ul>
            <li><strong>href</strong> contains the URL to the CSS file for the font.</li><br />
            <li><strong>family=Roboto:wght@400;700</strong> specifies the font family and weights you want to use.</li>
          </ul><br/>

          <h3>3. Apply the Font in Your CSS</h3>
          <p>
            After including the Google Fonts link in your HTML, you can apply the font in your CSS using the <code>font-family</code> property:
          </p>
          <pre>
            <code>{`body {\n  font-family: 'Roboto', sans-serif;\n}\n\nh1 {\n  font-family: 'Roboto', sans-serif;\n  font-weight: 700; /* Use bold weight for headers */\n}`}</code>
          </pre><br/>

          <h3>Example Code</h3>
          <p>Here’s an example of how you might use Google Fonts in a React component:</p>
          
          <pre>
            <code>{`import React from 'react';\nimport './App.css'; // Your CSS file\n\nconst App = () => {\n  return (\n    <div className="App">\n      <h1 className="heading">Welcome to My Website</h1>\n      <p className="text">This is a paragraph using Google Fonts.</p>\n    </div>\n  );\n};\n\nexport default App;`}</code>
          </pre><br/>

          <h4>App.css</h4>
          <pre>
            <code>{`/* Import Google Fonts in your CSS file (optional: can also be in HTML head) */\n@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');\n\nbody {\n  font-family: 'Roboto', sans-serif;\n}\n\n.heading {\n  font-family: 'Roboto', sans-serif;\n  font-weight: 700; /* Bold weight for headers */\n}\n\n.text {\n  font-family: 'Roboto', sans-serif;\n  font-weight: 400; /* Normal weight for paragraphs */\n}`}</code>
          </pre><br/>

          <h3 style={{paddingBottom:"6px"}}>Key Points</h3>
          <ul>
            <li><strong>Performance</strong>: Google Fonts are served from Google's CDN, which is optimized for fast delivery.</li><br/>
            <li><strong>Customization</strong>: You can choose specific weights and styles of fonts to minimize load times.</li><br/>
            <li><strong>Consistency</strong>: Google Fonts ensures that text appears consistently across different browsers and devices.</li>
          </ul><br/>

          <p>
            By following these steps, you can easily incorporate a wide variety of high-quality fonts into your web design, enhancing the visual appeal and readability of your site.
          </p>
     

                   

              </div>
            )}





            
{selectedChapter === 'chapter54' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Font Pairings</h1>
           
                

      <p>
        Font pairings in CSS refer to the practice of selecting and combining different fonts for use in a web design to create a visually appealing and harmonious typographic style. Effective font pairing can enhance readability and create a strong visual hierarchy on a webpage.
      </p><br/>

      <h2>1. Selecting Fonts:</h2>
      <ul>
        <li><strong>Serif Fonts:</strong> Often used for headings or body text to add a touch of elegance and readability. Examples: <code>Times New Roman</code>, <code>Georgia</code>.</li><br/>
        <li><strong>Sans-Serif Fonts:</strong> Modern and clean, often used for body text or headings. Examples: <code>Arial</code>, <code>Helvetica</code>.</li><br/>
        <li><strong>Monospace Fonts:</strong> Ideal for code or technical content. Examples: <code>Courier New</code>, <code>Roboto Mono</code>.</li><br/>
        <li><strong>Display Fonts:</strong> Decorative fonts used for headlines or special elements. Examples: <code>Lobster</code>, <code>Pacifico</code>.</li>
      </ul><br/>

      <h2>2. Combining Fonts:</h2>
      <ul>
        <li><strong>Contrast and Complement:</strong> Pair a serif font with a sans-serif font for contrast. For example, you might use <code>Georgia</code> for headings and <code>Arial</code> for body text.</li><br/>
        <li><strong>Hierarchy:</strong> Use different fonts to establish a visual hierarchy. For instance, a bold display font for headings and a more neutral font for body text.</li><br/>
        <li><strong>Consistency:</strong> Maintain consistency by limiting the number of different fonts. Typically, two to three fonts (including variations) are used.</li>
      </ul><br/>

      <h2>3. Implementing Font Pairings in CSS:</h2>
      <pre>
        <code>
{`/* Importing fonts from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&family=Playfair+Display:wght@400');

/* Applying font pairs */
body {
  font-family: 'Roboto', sans-serif;
}

h1, h2, h3 {
  font-family: 'Playfair Display', serif;
}`}
        </code>
      </pre>
      <p>In this example:</p>
      <ul>
        <li><code>Roboto</code> is used for the body text because it is clean and highly readable.</li><br />
        <li><code>Playfair Display</code> is used for headings to add a touch of elegance and contrast.</li>
      </ul><br/>

      <h2>4. Responsive Typography:</h2>
      <pre>
        <code>
{`body {
  font-size: 1rem; /* Adjust size as needed */
}

h1 {
  font-size: 2rem;
}`}
        </code>
      </pre>
      <p>Ensure your font pairings work well on various screen sizes. Use relative units like <code>em</code> or <code>rem</code> for font sizes to maintain scalability and readability across devices.</p><br/>

      <h2>5. Testing and Refinement:</h2>
      <p>
        Always test font pairings on different devices and browsers to ensure they look good and function well in various contexts. Adjust font weights, sizes, and line heights as necessary.
      </p><br/>

      <p>
        Font pairings are an essential aspect of web design that can significantly impact the overall user experience. By carefully selecting and combining fonts, you can create a visually appealing and effective design.
      </p>
   


              </div>
            )}





            
{selectedChapter === 'chapter55' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Font Shorthand</h1>
           
      
      <p>
        Font shorthand in CSS is a way to specify multiple font-related properties in a single line. This shorthand notation allows you to set values for several font properties simultaneously, which helps to simplify your CSS and ensure consistency.
      </p><br/>

      <h2>Font Shorthand Syntax</h2>

      <p>
        The <code>font</code> shorthand property can combine the following properties:
      </p>

      <ol>
        <li><strong><code>font-style</code></strong>: Specifies the style of the font. Possible values include <code>normal</code>, <code>italic</code>, <code>oblique</code>.</li><br/>
        <li><strong><code>font-variant</code></strong>: Specifies the variant of the font. Common values are <code>normal</code> and <code>small-caps</code>.</li><br/>
        <li><strong><code>font-weight</code></strong>: Specifies the weight (thickness) of the font. Values can be keywords like <code>normal</code>, <code>bold</code>, or numeric values ranging from <code>100</code> to <code>900</code>.</li><br/>
        <li><strong><code>font-size</code></strong>: Specifies the size of the font. Values can be absolute units (e.g., <code>px</code>, <code>em</code>) or relative units (e.g., <code>rem</code>).</li><br/>
        <li><strong><code>line-height</code></strong>: Specifies the line height of the text. It can be a unitless number or a specific unit like <code>px</code> or <code>em</code>. When specified in the shorthand, it appears immediately after the <code>font-size</code> and is separated by a space.</li><br/>
        <li><strong><code>font-family</code></strong>: Specifies the typeface to be used. You can list multiple font families as a fallback system.</li>
      </ol><br/>

      <h2>Example Usage</h2>
      <pre>
        <code>
{`/* Full property definition */
p {
  font-style: italic;
  font-variant: small-caps;
  font-weight: bold;
  font-size: 16px;
  line-height: 1.5;
  font-family: 'Arial', sans-serif;
}

/* Shorthand property */
p {
  font: italic small-caps bold 16px/1.5 'Arial', sans-serif;
}`}
        </code>
      </pre><br/>

      <h2 style={{paddingBottom:"6px"}}>Explanation</h2>
      <ul>
        <li><code>italic</code>: Font style.</li><br/>
        <li><code>small-caps</code>: Font variant.</li><br/>
        <li><code>bold</code>: Font weight.</li><br/>
        <li><code>16px</code>: Font size.</li><br/>
        <li><code>1.5</code>: Line height (unitless).</li><br/>
        <li><code>'Arial', sans-serif</code>: Font family.</li>
      </ul><br/>

      <h2 style={{paddingBottom:"6px"}}>Important Points</h2>
      <ul>
        <li>The <code>font</code> shorthand property must include at least <code>font-size</code> and <code>font-family</code>. If either is omitted, the shorthand property is invalid.</li><br/>
        <li>If <code>font-size</code> is specified without <code>font-family</code>, the shorthand will not work correctly.</li><br/>
        <li><strong>Order Matters</strong>: The order of the properties in the shorthand is important. If you use an invalid value or omit a required value, the shorthand may not apply correctly.</li>
      </ul><br/>

      <p>
        By using the <code>font</code> shorthand property, you can streamline your CSS and make your font declarations more efficient.
      </p>
 
 

              </div>
            )}





{selectedChapter === 'chapter57' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Icons</h1>
           

          <p>
            In CSS, an "icon" typically refers to a small graphical element used to represent an action, status, or object within a user interface. Icons are 
             often used to improve the visual appeal of a website or application and to make navigation more intuitive.
          </p><br />
          <p>Here are a few common ways to include and style icons in CSS:</p>

          <ol>
            <li>
              <strong>Using Font Icons</strong>: One of the most popular methods for adding icons is through icon fonts like Font Awesome, Material Icons, or  
              Bootstrap Icons. These fonts provide a wide range of icons that can be styled using CSS.
              <pre>
                <code>{`<!-- Include Font Awesome CSS -->
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css">

<!-- HTML -->
<i class="fas fa-camera"></i>`}</code>
              </pre>
              <pre>
                <code>{`/* CSS */
.fas {
  font-size: 24px;
  color: #333;
}`}</code>
              </pre>
            </li><br />

            <li>
              <strong>Using SVG Icons</strong>: Scalable Vector Graphics (SVG) can be used directly in your HTML or CSS. SVG icons are resolution-independent and 
               can be styled with CSS.
              <pre>
                <code>{`<!-- Inline SVG -->
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3
.59-8 8-8 8 3.59 8 8-8 8zm-1-13h2v6h-2zm0 8h2v2h-2z" fill="#000"/>
</svg>`}</code>
              </pre>
              <pre>
                <code>{`/* CSS */
svg {
  width: 24px;
  height: 24px;
  fill: #333;
}`}</code>
              </pre>
            </li><br />

            <li>
              <strong>Using Image Icons</strong>: Icons can also be implemented using image files (e.g., PNG, GIF). This method involves using the <code>&lt;img&gt;</code>
               tag and applying CSS styles to adjust the size and positioning.
              <pre>
                <code>{`<!-- HTML -->
<img src="icon.png" alt="Icon" class="icon">`}</code>
              </pre>
              <pre>
                <code>{`/* CSS */
.icon {
  width: 24px;
  height: 24px;
}`}</code>
              </pre>
            </li><br />

            <li>
              <strong>CSS Background Images</strong>: Another way to include icons is to use background images in CSS. This method is often used for simple icons and can be 
               combined with pseudo-elements.
              <pre>
                <code>{`<!-- HTML -->
<div class="icon"></div>`}</code>
              </pre>
              <pre>
                <code>{`/* CSS */
.icon {
  width: 24px;
  height: 24px;
  background-image: url('icon.png');
  background-size: cover;
}`}</code>
              </pre>
            </li>
          </ol><br />

          <p>
            Each method has its use cases depending on factors like scalability, ease of customization, and performance. Font icons and SVGs are especially favored for their 
             scalability and ease of styling with CSS.
          </p>
             

              </div>
            )}




{selectedChapter === 'chapter58' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Links</h1>
           
        
          <p>
            In CSS, the term "link" usually refers to a couple of different things depending on the context:
          </p><br />

          <h2>1. Linking External Stylesheets</h2>
          <p>
            When using CSS in a web project, you'll often link to an external stylesheet. This is done using the <code>&lt;link&gt;</code> element in HTML. This <code>&lt;link&gt;</code> element is placed inside the <code>&lt;head&gt;</code> section of your HTML document and specifies the location of your CSS file.
          </p>

          <pre>
            <code>
{`<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Document</title>
  <!-- Link to an external CSS file -->
  <link rel="stylesheet" href="styles.css">
</head>
<body>
  <h1>Hello World!</h1>
</body>
</html>`}
            </code>
          </pre>

          <p>
            In this example:
            <ul>
              <li><code>rel="stylesheet"</code> indicates that the linked file is a stylesheet.</li><br />
              <li><code>href="styles.css"</code> specifies the path to the CSS file.</li>
            </ul>
          </p><br/>

          <h2>2. Linking with CSS</h2>
          <p>
            In CSS, you might use styles to specifically target <code>&lt;a&gt;</code> elements, which are typically used for hyperlinks. You can style these elements in various states, such as normal, hovered, visited, and active. This is done using pseudo-classes.
          </p>

          <pre>
            <code>
{`/* Normal link */
a {
  color: blue;
  text-decoration: none;
}

/* Visited link */
a:visited {
  color: purple;
}

/* Hovered link */
a:hover {
  color: red;
  text-decoration: underline;
}

/* Active link */
a:active {
  color: orange;
}`}
            </code>
          </pre>

          <p>
            In this example:
            <ul>
              <li><code>a</code> targets all <code>&lt;a&gt;</code> elements and sets their color to blue and removes the underline.</li><br />
              <li><code>a:visited</code> styles links that have been visited by the user, setting their color to purple.</li><br />
              <li><code>a:hover</code> styles links when the mouse pointer hovers over them, changing their color to red and adding an underline.</li><br />
              <li><code>a:active</code> styles links when they are clicked, changing their color to orange.</li>
            </ul>
          </p><br/>

          <h2>3. Using CSS <code>@import</code></h2>
          <p>
            You can also link CSS files within a CSS file itself using the <code>@import</code> rule. This allows you to include one CSS file within another.
          </p>

          <pre>
            <code>
{`/* main.css */
@import url('reset.css');
@import url('typography.css');

/* Styles in main.css */
body {
  font-family: Arial, sans-serif;
}`}
            </code>
          </pre>

          <p>
            In this example:
            <ul>
              <li><code>@import url('reset.css');</code> imports styles from <code>reset.css</code>.</li><br />
              <li><code>@import url('typography.css');</code> imports styles from <code>typography.css</code>.</li>
            </ul>
          </p>
          <p>
            Using <code>@import</code> is less common nowadays due to performance considerations, as it can increase the time it takes for stylesheets to load. It's generally recommended to use <code>&lt;link&gt;</code> elements in HTML for better performance.
          </p>
          <p>
            In summary:
            <ul>
              <li><strong>Linking External Stylesheets</strong>: Achieved with the <code>&lt;link&gt;</code> element in HTML.</li><br />
              <li><strong>Styling Links</strong>: Using CSS pseudo-classes (<code>:link</code>, <code>:visited</code>, <code>:hover</code>, <code>:active</code>).</li><br />
              <li><strong>Importing Stylesheets</strong>: Achieved with the <code>@import</code> rule in CSS.</li>
            </ul>
          </p>
   


              </div>
            )}






{selectedChapter === 'chapter59' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Lists</h1>
           
              

          <p>
            In CSS, the term "list" typically refers to the styling of HTML list elements. There are several types of lists in HTML, and CSS provides various ways to style them. Here’s a breakdown of how you can work with lists in CSS:
          </p><br />

          <h2 style={{paddingBottom:"6px"}}>1. Types of Lists</h2>
          <ul>
            <li><strong>Unordered Lists (`&lt;ul&gt;`)</strong>: These lists use bullet points by default.</li><br/>
            <li><strong>Ordered Lists (`&lt;ol&gt;`)</strong>: These lists use numbers or letters by default.</li><br/>
            <li><strong>Definition Lists (`&lt;dl&gt;`)</strong>: These lists are used for terms and their descriptions.</li>
          </ul><br/>

          <h2 style={{paddingBottom:"6px"}}>2. Basic List Styling</h2>

          <h3>Unordered Lists (`&lt;ul&gt;`)</h3>
          <p>
            By default, &lt;ul&gt; elements display items with bullets. You can change the style of these bullets using the <code>list-style-type</code> property.
          </p>

          <pre>
            <code>
{`<ul class="unordered-list">
  <li>Item 1</li>
  <li>Item 2</li>
  <li>Item 3</li>
</ul>`}
            </code>
          </pre>

          <pre>
            <code>
{`/* Basic Unordered List Styling */
.unordered-list {
  list-style-type: square; /* Other values include disc, circle, none */
  padding-left: 20px; /* Adjust the left padding */
}

.unordered-list li {
  margin-bottom: 10px; /* Space between list items */
}`}
            </code>
          </pre><br/>

          <h3>Ordered Lists (`&lt;ol&gt;`)</h3>
          <p>
            By default, &lt;ol&gt; elements display items with numbers. You can change the numbering style using the <code>list-style-type</code> property.
          </p>

          <pre>
            <code>
{`<ol class="ordered-list">
  <li>First item</li>
  <li>Second item</li>
  <li>Third item</li>
</ol>`}
            </code>
          </pre>

          <pre>
            <code>
{`/* Basic Ordered List Styling */
.ordered-list {
  list-style-type: lower-alpha; /* Other values include decimal, lower-roman, upper-alpha, etc. */
  padding-left: 20px; /* Adjust the left padding */
}

.ordered-list li {
  margin-bottom: 10px; /* Space between list items */
}`}
            </code>
          </pre><br/>

          <h3>Definition Lists (`&lt;dl&gt;`)</h3>
          <p>
            Definition lists consist of &lt;dt&gt; (definition term) and &lt;dd&gt; (definition description).
          </p>

          <pre>
            <code>
{`<dl class="definition-list">
  <dt>HTML</dt>
  <dd>HyperText Markup Language</dd>
  <dt>CSS</dt>
  <dd>Cascading Style Sheets</dd>
</dl>`}
            </code>
          </pre>

          <pre>
            <code>
{`/* Basic Definition List Styling */
.definition-list {
  padding-left: 20px; /* Adjust the left padding */
}

.definition-list dt {
  font-weight: bold; /* Style for terms */
}

.definition-list dd {
  margin-bottom: 10px; /* Space between definitions */
}`}
            </code>
          </pre><br/>

          <h2>3. Advanced List Styling</h2><br/>

          <h3>Custom List Markers</h3>
          <p>
            You can use custom images or shapes for list markers.
          </p>

          <pre>
            <code>
{`<ul class="custom-list">
  <li>Custom marker 1</li>
  <li>Custom marker 2</li>
  <li>Custom marker 3</li>
</ul>`}
            </code>
          </pre>

          <pre>
            <code>
{`/* Custom List Marker */
.custom-list {
  list-style: none; /* Remove default bullets */
  padding-left: 0; /* Remove default padding */
}

.custom-list li {
  position: relative; /* Positioning for the custom marker */
  padding-left: 30px; /* Space for the custom marker */
}

.custom-list li::before {
  content: url('path/to/your-icon.png'); /* Use an image as a marker */
  position: absolute;
  left: 0; /* Position the marker on the left */
  top: 50%; /* Center vertically */
  transform: translateY(-50%); /* Center vertically */
}`}
            </code>
          </pre><br />

          <h3>Nested Lists</h3>
          <p>
            You can style nested lists differently from their parent lists.
          </p>

          <pre>
            <code>
{`<ul class="parent-list">
  <li>Parent Item 1
    <ul class="nested-list">
      <li>Child Item 1</li>
      <li>Child Item 2</li>
    </ul>
  </li>
  <li>Parent Item 2</li>
</ul>`}
            </code>
          </pre>

          <pre>
            <code>
{`/* Styling for Nested Lists */
.parent-list {
  list-style-type: disc;
}

.nested-list {
  list-style-type: circle;
  padding-left: 20px;
}`}
            </code>
          </pre><br/>

          <h2>4. CSS Properties for Lists</h2>

          <ul>
            <li><code>list-style</code>: A shorthand property for <code>list-style-type</code>, <code>list-style-position</code>, and <code>list-style-image</code>.</li><br/>
            <li><code>list-style-type</code>: Specifies the type of list item marker.</li><br/>
            <li><code>list-style-position</code>: Determines whether the marker should be inside or outside the list item box.</li><br/>
            <li><code>list-style-image</code>: Allows you to specify an image as the list item marker.</li>
          </ul><br />

          <pre>
            <code>
{`/* List Style Shorthand */
ul.custom-style {
  list-style: square inside url('path/to/marker.png');
}`}
            </code>
          </pre><br />

          <p>
            In summary, CSS provides various ways to style lists, from changing the default markers to using custom images or adjusting the padding and margins for a more tailored appearance.
          </p>
       


              </div>
            )}






{selectedChapter === 'chapter60' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Tabels</h1>
           
      <p>
        In CSS, the term "table" refers to a set of properties that control the styling and layout of HTML tables. Tables are used to display data in a structured format, and CSS provides various properties to manage their appearance and behavior.
      </p><br />

      <h2>1. <code>display</code></h2>
      <p>
        <code>display: table;</code>: Makes an element behave like an HTML <code>&lt;table&gt;</code> element. This is useful for creating table-like layouts with non-table elements, such as <code>&lt;div&gt;</code>.
      </p><br />

      <h2>2. <code>table-layout</code></h2>
      <p>
        <code>table-layout: auto;</code>: The default value. Columns are sized according to their content and the table’s width.
      </p><br />
      <p>
        <code>table-layout: fixed;</code>: Columns are sized based on the table’s width and the first row of cells. This can improve performance and consistency.
      </p><br />

      <h2>3. <code>border-collapse</code></h2>
      <p>
        <code>border-collapse: collapse;</code>: Collapses table borders into a single border. This reduces the spacing between borders of adjacent cells.
      </p><br />
      <p>
        <code>border-collapse: separate;</code>: Keeps borders separate, allowing you to control spacing between borders.
      </p><br />

      <h2>4. <code>border-spacing</code></h2>
      <p>
        <code>border-spacing: &lt;length&gt;;</code>: Sets the distance between borders of adjacent cells when <code>border-collapse</code> is <code>separate</code>.
      </p><br />

      <h2>5. <code>caption-side</code></h2>
      <p>
        <code>caption-side: top;</code>: Places the table caption above the table.
      </p><br />
      <p>
        <code>caption-side: bottom;</code>: Places the table caption below the table.
      </p><br />
      <p>
        <code>caption-side: left;</code> or <code>right;</code>: Less common, but can place the caption on the sides.
      </p><br />

      <h2>6. <code>empty-cells</code></h2>
      <p>
        <code>empty-cells: show;</code>: Displays borders and background of empty cells.
      </p><br />
      <p>
        <code>empty-cells: hide;</code>: Hides borders and background of empty cells.
      </p><br />

      <h2>7. <code>vertical-align</code></h2>
      <p>
        <code>vertical-align: &lt;value&gt;;</code>: Aligns content vertically within table cells. Common values include <code>top</code>, <code>middle</code>, <code>bottom</code>, <code>baseline</code>.
      </p><br />

      <h2>Example Usage</h2>
      <p>Here is an example CSS styling for an HTML table:</p>

      <pre>
        <code>
          {`
table {
  width: 100%;
  border-collapse: collapse; /* Merge cell borders into one */
}

th, td {
  border: 1px solid #ddd; /* Border around table cells */
  padding: 8px;           /* Space inside cells */
  text-align: left;       /* Align text to the left */
}

caption {
  caption-side: top;      /* Position caption at the top */
  font-weight: bold;      /* Bold caption text */
}

table {
  border-spacing: 10px;   /* Space between borders if not collapsed */
}
          `}
        </code>
      </pre><br />

      <h2>Summary</h2>
      <p>
        In CSS, the <code>table</code> properties allow you to format and style tables, controlling aspects like border appearance, cell spacing, and table layout. These properties help you present data in a visually appealing and organized manner.
      </p>
    


              </div>
            )}




{selectedChapter === 'chapter25' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Display</h1>
           
            
      <p>
        In CSS, the <code>display</code> property is a fundamental property that determines how an element is displayed on the page. It controls the layout behavior of elements, including their positioning and how they interact with other elements. Here's a detailed overview:
      </p><br/>

      <h2 style={{paddingBottom:"6px"}}>Key Values of the <code>display</code> Property</h2>

      <h3><code>block</code></h3>
      <p>
        <strong>Description:</strong> Elements with <code>display: block;</code> are displayed as block-level elements. They occupy the full width available and start on a new line. Block-level elements stack vertically.
      </p>
      <p>
        <strong>Examples:</strong> <code>&lt;div&gt;</code>, <code>&lt;p&gt;</code>, <code>&lt;h1&gt;</code>, <code>&lt;ul&gt;</code>
      </p>
      <p>
        <strong>Usage:</strong>
        <pre><code>{`
div {
  display: block;
}
`}</code></pre>
      </p><br/>

      <h3><code>inline</code></h3>
      <p>
        <strong>Description:</strong> Elements with <code>display: inline;</code> are displayed as inline elements. They do not start on a new line and only take up as much width as necessary. Inline elements flow horizontally with other inline elements.
      </p>
      <p>
        <strong>Examples:</strong> <code>&lt;span&gt;</code>, <code>&lt;a&gt;</code>, <code>&lt;strong&gt;</code>, <code>&lt;em&gt;</code>
      </p>
      <p>
        <strong>Usage:</strong>
        <pre><code>{`
span {
  display: inline;
}
`}</code></pre>
      </p><br/>

      <h3><code>inline-block</code></h3>
      <p>
        <strong>Description:</strong> Elements with <code>display: inline-block;</code> are formatted as inline elements but can have width and height set like block elements. They do not start on a new line but respect the width and height properties.
      </p>
      <p>
        <strong>Examples:</strong> <code>&lt;img&gt;</code>, <code>&lt;button&gt;</code>, <code>&lt;input&gt;</code>
      </p>
      <p>
        <strong>Usage:</strong>
        <pre><code>{`
img {
  display: inline-block;
}
`}</code></pre>
      </p><br/>

      <h3><code>none</code></h3>
      <p>
        <strong>Description:</strong> Elements with <code>display: none;</code> are not displayed at all and do not take up any space on the page. This is different from <code>visibility: hidden;</code>, which hides the element but still occupies space.
      </p>
      <p>
        <strong>Usage:</strong>
        <pre><code>{`
.hidden {
  display: none;
}
`}</code></pre>
      </p><br/>

      <h3><code>flex</code></h3>
      <p>
        <strong>Description:</strong> Elements with <code>display: flex;</code> use the Flexbox layout model, which makes it easier to align and distribute space among items in a container, even when their size is unknown.
      </p>
      <p>
        <strong>Examples:</strong> Flex containers and items in a layout.
      </p>
      <p>
        <strong>Usage:</strong>
        <pre><code>{`
.container {
  display: flex;
}
`}</code></pre>
      </p><br/>

      <h3><code>grid</code></h3>
      <p>
        <strong>Description:</strong> Elements with <code>display: grid;</code> use the Grid layout model, which allows you to create complex, responsive grid-based layouts with rows and columns.
      </p>
      <p>
        <strong>Examples:</strong> Grid containers and grid items in a layout.
      </p>
      <p>
        <strong>Usage:</strong>
        <pre><code>{`
.grid-container {
  display: grid;
}
`}</code></pre>
      </p><br/>

      <h3><code>table</code></h3>
      <p>
        <strong>Description:</strong> Elements with <code>display: table;</code> make an element behave like an HTML <code>&lt;table&gt;</code> element. This allows you to create table-like layouts with non-table elements.
      </p>
      <p>
        <strong>Examples:</strong> Custom table layouts.
      </p>
      <p>
        <strong>Usage:</strong>
        <pre><code>{`
.custom-table {
  display: table;
}
`}</code></pre>
      </p><br/>

      <h3><code>table-row</code></h3>
      <p>
        <strong>Description:</strong> Elements with <code>display: table-row;</code> behave like HTML <code>&lt;tr&gt;</code> elements.
      </p>
      <p>
        <strong>Usage:</strong>
        <pre><code>{`
.table-row {
  display: table-row;
}
`}</code></pre>
      </p><br/>

      <h3><code>table-cell</code></h3>
      <p>
        <strong>Description:</strong> Elements with <code>display: table-cell;</code> behave like HTML <code>&lt;td&gt;</code> elements.
      </p>
      <p>
        <strong>Usage:</strong>
        <pre><code>{`
.table-cell {
  display: table-cell;
}
`}</code></pre>
      </p><br/>

      <h3><code>list-item</code></h3>
      <p>
        <strong>Description:</strong> Elements with <code>display: list-item;</code> are formatted like list items, similar to HTML <code>&lt;li&gt;</code>.
      </p>
      <p>
        <strong>Examples:</strong> List items in custom lists.
      </p>
      <p>
        <strong>Usage:</strong>
        <pre><code>{`
.list-item {
  display: list-item;
}
`}</code></pre>
      </p><br/>

      <h2>Example Usage</h2>
      <p>Here's an example of how the <code>display</code> property might be used in a CSS file:</p>

      <pre>
        <code>{`
.container {
  display: flex; /* Makes the container a flex container */
}

.item {
  display: inline-block; /* Allows items to be inline but with block properties */
}

.hidden {
  display: none; /* Hides elements completely */
}

.table {
  display: table; /* Makes the element behave like a table */
}

.table-cell {
  display: table-cell; /* Makes the element behave like a table cell */
}
        `}</code>
      </pre><br/>

      <h2>Summary</h2>
      <p>
        The <code>display</code> property is crucial for controlling how elements are rendered and how they interact with their surrounding elements. It supports a variety of layout models, including block, inline, flex, grid, and table, providing powerful tools for designing web layouts.
      </p>
  

              </div>
            )}




{selectedChapter === 'chapter26' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Max-Width</h1>
           

      <p>
        In CSS, the <code>max-width</code> property is used to set the maximum width of an element. It defines the upper limit on how wide an element can grow, even if the content inside it or the surrounding layout would normally make it wider. This property is particularly useful for responsive design, as it helps prevent elements from becoming too wide on large screens or when viewed in a flexible layout.
      </p><br />

      <h2 style={{paddingBottom:"6px"}}>Key Points about <code>max-width</code>:</h2>

      <ul>
        <li><strong>Function:</strong> It restricts the width of an element to a maximum value, allowing the element to be responsive while maintaining control over its maximum size.</li><br/>
        <li><strong>Syntax:</strong>
          <pre><code>{`element {
  max-width: value;
}`}</code></pre>
        </li><br/>
        <li><strong>Common Values:</strong>
          <ul>
            <li><code>Length Units:</code> <pre><code>{`max-width: 600px;`}</code></pre> - Sets the maximum width to 600 pixels.</li><br />
            <li><code>Percentage:</code> <pre><code>{`max-width: 80%;`}</code></pre> - Sets the maximum width to 80% of the width of the containing block (parent element).</li><br />
            <li><code>Auto:</code> <pre><code>{`max-width: auto;`}</code></pre> - This is the default value. The element’s width will be determined by its content and other layout rules, with no maximum width restriction.</li>
          </ul>
        </li><br/>
        <li><strong>Behavior with <code>width</code>:</strong> If <code>width</code> is set to a value and <code>max-width</code> is also set, the element will not exceed the width specified by <code>max-width</code>, even if <code>width</code> would otherwise make it larger.</li><br/>
        <li><strong>Responsive Design:</strong> <code>max-width</code> is often used in responsive web design to ensure that elements do not become excessively wide on large screens, improving readability and maintaining the layout’s integrity.</li>
      </ul><br/>

      <h2>Example Usage:</h2>
      <pre>
        <code>{`
.container {
  max-width: 1200px; /* The container will not exceed 1200px in width */
  margin: 0 auto;    /* Centers the container horizontally */
  padding: 20px;     /* Adds padding inside the container */
}

.image {
  max-width: 100%;   /* Ensures the image does not exceed the width of its container */
  height: auto;      /* Maintains the aspect ratio of the image */
}
        `}</code>
      </pre><br/>

      <h2>Example Explained:</h2>
      <ul>
        <li><strong>.container:</strong> The <code>.container</code> class will not grow wider than 1200 pixels, no matter how large the viewport is. This helps maintain a consistent layout and readability on very large screens.</li><br />
        <li><strong>.image:</strong> The <code>.image</code> class ensures that images inside this container will scale responsively. The image width will be a maximum of 100% of its container's width, ensuring it doesn’t overflow the container. The height is set to <code>auto</code> to maintain the image’s aspect ratio.</li>
      </ul><br/>

      <h2>Summary:</h2>
      <p>
        The <code>max-width</code> property is essential for controlling the maximum width of elements in CSS. It is particularly useful for creating flexible layouts that adapt to different screen sizes while preventing elements from becoming too wide and affecting the layout negatively.
      </p>
  
  

              </div>
            )}





            
{selectedChapter === 'chapter27' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Position</h1>
           
                   
               

      <p>
        In CSS, the <code>position</code> property specifies the positioning method for an element. It determines how an element is positioned in relation to its containing block or other elements. The <code>position</code> property plays a crucial role in controlling the layout and positioning of elements on a web page.
      </p><br/>

      <h2 style={{paddingBottom:"6px"}}>Key Values of the <code>position</code> Property:</h2>

      <ol>
        <li>
          <strong><code>static</code></strong>
          <p>
            <strong>Description:</strong> This is the default value. Elements with <code>position: static;</code> are positioned according to the normal flow of the document. Top, right, bottom, and left properties have no effect on statically positioned elements.
          </p>
          <pre>
            <code>{`.element {\n  position: static;\n}`}</code>
          </pre>
        </li><br/>

        <li>
          <strong><code>relative</code></strong>
          <p>
            <strong>Description:</strong> An element with <code>position: relative;</code> is positioned relative to its normal position in the document flow. You can then use the <code>top</code>, <code>right</code>, <code>bottom</code>, and <code>left</code> properties to offset the element from its original position.
          </p>
          <pre>
            <code>{`.element {\n  position: relative;\n  top: 10px; /* Moves the element 10px down from its normal position */\n  left: 20px; /* Moves the element 20px to the right from its normal position */\n}`}</code>
          </pre>
        </li><br/>

        <li>
          <strong><code>absolute</code></strong>
          <p>
            <strong>Description:</strong> An element with <code>position: absolute;</code> is positioned relative to the nearest positioned ancestor (i.e., an ancestor element with a <code>position</code> value other than <code>static</code>). If no such ancestor exists, it is positioned relative to the initial containing block (usually the <code>&lt;html&gt;</code> element). The <code>top</code>, <code>right</code>, <code>bottom</code>, and <code>left</code> properties are used to precisely position the element.
          </p>
          <pre>
            <code>{`.element {\n  position: absolute;\n  top: 50px; /* Position 50px from the top of the nearest positioned ancestor */\n  left: 100px; /* Position 100px from the left of the nearest positioned ancestor */\n}`}</code>
          </pre>
        </li><br/>

        <li>
          <strong><code>fixed</code></strong>
          <p>
            <strong>Description:</strong> An element with <code>position: fixed;</code> is positioned relative to the viewport, which means it stays in the same place even when the page is scrolled. The <code>top</code>, <code>right</code>, <code>bottom</code>, and <code>left</code> properties determine its position within the viewport.
          </p>
          <pre>
            <code>{`.element {\n  position: fixed;\n  bottom: 10px; /* Positioned 10px from the bottom of the viewport */\n  right: 20px; /* Positioned 20px from the right of the viewport */\n}`}</code>
          </pre>
        </li><br/>

        <li>
          <strong><code>sticky</code></strong>
          <p>
            <strong>Description:</strong> An element with <code>position: sticky;</code> toggles between <code>relative</code> and <code>fixed</code> positioning depending on the user's scroll position. It is positioned relative to its containing block until a specified scroll position is reached, after which it becomes fixed in place.
          </p>
          <pre>
            <code>{`.element {\n  position: sticky;\n  top: 0; /* Sticks the element to the top of the viewport when scrolling */\n}`}</code>
          </pre>
        </li>
      </ol><br/>

      <h2>Example Usage:</h2>
      <pre>
        <code>{`
.static-element {
  position: static;
}

.relative-element {
  position: relative;
  top: 20px;
  left: 30px;
}

.absolute-element {
  position: absolute;
  top: 50px;
  left: 100px;
}

.fixed-element {
  position: fixed;
  bottom: 10px;
  right: 10px;
}

.sticky-element {
  position: sticky;
  top: 0;
}
        `}</code>
      </pre><br/>

      <h2 style={{paddingBottom:"6px"}}>Example Explained:</h2>
      <ul>
        <li><strong>.static-element:</strong> This element follows the normal document flow.</li><br />
        <li><strong>.relative-element:</strong> This element is moved 20px down and 30px to the right from where it would normally be.</li><br />
        <li><strong>.absolute-element:</strong> This element is positioned 50px from the top and 100px from the left of its nearest positioned ancestor.</li><br />
        <li><strong>.fixed-element:</strong> This element remains fixed in the viewport, 10px from the bottom and right.</li><br />
        <li><strong>.sticky-element:</strong> This element sticks to the top of the viewport when scrolled past.</li>
      </ul><br/>

      <h2>Summary:</h2>
      <p>
        The <code>position</code> property in CSS is vital for controlling how elements are placed and moved within a web page. By using different positioning values (<code>static</code>, <code>relative</code>, <code>absolute</code>, <code>fixed</code>, <code>sticky</code>), you can achieve a wide variety of layouts and designs, making it an essential tool in web development and design.
      </p>
  

              </div>
            )}




{selectedChapter === 'chapter28' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Z-Index</h1>
           
               
      
      <p>
        The <code>z-index</code> property in CSS controls the stacking order of positioned elements (i.e., elements with <code>position</code> values of <code>relative</code>, <code>absolute</code>, <code>fixed</code>, or <code>sticky</code>). It determines which elements appear in front of or behind other elements when they overlap.
      </p><br/>
      
      <h3>Key Points about <code>z-index</code>:</h3>
      <ol>
        <li>
          <strong>Stacking Context:</strong>
          <ul>
            <li><code>z-index</code> only affects elements within the same stacking context. A stacking context is formed by any element with a position value other than <code>static</code> and a <code>z-index</code> value other than <code>auto</code>, among other criteria.</li><br />
            <li>Elements with higher <code>z-index</code> values are stacked on top of those with lower <code>z-index</code> values.</li>
          </ul><br/>
        </li>
        <li>
          <strong>Default Value:</strong>
          <ul>
            <li>The default value of <code>z-index</code> is <code>auto</code>, which means the element will be stacked in the order it appears in the document.</li>
          </ul>
        </li><br/>
        <li>
          <strong>Numeric Values:</strong>
          <ul>
            <li>Positive values will stack the element in front of elements with lower or negative <code>z-index</code> values.</li><br />
            <li>Negative values will stack the element behind elements with higher <code>z-index</code> values.</li><br />
            <li>The higher the value, the closer the element is to the front.</li>
          </ul>
        </li><br/>
        <li>
          <strong>Stacking Order:</strong>
          <ul>
            <li><code>z-index</code> works in conjunction with the <code>position</code> property. For <code>z-index</code> to have an effect, the element must be positioned.</li>
          </ul>
        </li>
      </ol><br/>
      
      <h3>Example Usage:</h3>
      <pre>
        <code>
          {`
<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>z-index Example</title>
  <style>
    .container {
      position: relative;
      width: 300px;
      height: 300px;
      border: 1px solid #000;
    }
    .box {
      position: absolute;
      width: 100px;
      height: 100px;
    }
    .box1 {
      background-color: red;
      z-index: 1;
      top: 50px;
      left: 50px;
    }
    .box2 {
      background-color: blue;
      z-index: 2;
      top: 80px;
      left: 80px;
    }
    .box3 {
      background-color: green;
      z-index: 0;
      top: 110px;
      left: 110px;
    }
  </style>
</head>
<body>
  <div class="container">
    <div class="box box1"></div>
    <div class="box box2"></div>
    <div class="box box3"></div>
  </div>
</body>
</html>
          `}
        </code>
      </pre><br/>
      
      <h3>Explanation:</h3>
      <ul>
        <li><strong>HTML Structure:</strong></li>
        <ul>
          <li>The <code>.container</code> class sets up a relatively positioned container.</li><br />
          <li>The <code>.box</code> classes represent the individual boxes with absolute positioning.</li>
        </ul><br />
        <li><strong>CSS Styles:</strong></li>
        <ul>
          <li><code>.box1</code> (red) has a <code>z-index</code> of <code>1</code>.</li><br />
          <li><code>.box2</code> (blue) has a <code>z-index</code> of <code>2</code>.</li><br />
          <li><code>.box3</code> (green) has a <code>z-index</code> of <code>0</code>.</li><br />
          <li>Because <code>.box2</code> has the highest <code>z-index</code> value, it will appear on top of <code>.box1</code> and <code>.box3</code>. The <code>.box1</code> will appear above <code>.box3</code> due to its higher <code>z-index</code> value.</li>
        </ul>
      </ul><br/>

      <h3>Summary:</h3>
      <p>
        The <code>z-index</code> property is essential for managing overlapping elements and their stacking order. By using <code>z-index</code>, you can control which elements appear on top of others, making it crucial for creating layered layouts and user interfaces in web design.
      </p>



              </div>
            )}





{selectedChapter === 'chapter29' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Overflow</h1>
           
                 
      <p>
        In CSS, the <code>overflow</code> property controls how content that
        overflows the boundaries of an element's box is handled. It's useful
        for managing content that is larger than the container, especially in
        scenarios where you don't want the content to break the layout.
      </p><br/>
      <p>Here are the main values for the <code>overflow</code> property:</p>
      <ul>
        <li><strong><code>visible</code></strong>: The default value. Content
          that overflows the element's box is visible outside the element. It
          doesn't clip the content or add scrollbars.
        </li><br/>
        <li><strong><code>hidden</code></strong>: Content that overflows the
          element's box is clipped and not visible. No scrollbars are provided
          to access the hidden content.
        </li><br/>
        <li><strong><code>scroll</code></strong>: Scrollbars are always
          provided (both horizontal and vertical, if needed) to view the
          overflowing content, regardless of whether the content actually
          overflows.
        </li><br/>
        <li><strong><code>auto</code></strong>: Scrollbars are added only if
          necessary. If the content fits within the element, no scrollbars are
          displayed. If the content overflows, scrollbars appear as needed.
        </li>
      </ul><br/>
      <p>Additionally, there are properties that work in tandem with
        <code>overflow</code>:</p>
      <ul>
        <li><strong><code>overflow-x</code></strong>: Controls the overflow on
          the horizontal axis.
        </li><br/>
        <li><strong><code>overflow-y</code></strong>: Controls the overflow on
          the vertical axis.
        </li>
      </ul><br/>
      <p>
        You can set these properties independently of each other, which allows
        for more granular control over how overflow is handled in different
        directions.
      </p>
      <p>Example usage:</p>
      <pre>
        <code>
          {`.container {
  width: 200px;
  height: 100px;
  overflow: auto; /* Adds scrollbars if the content overflows */
}

.container-hidden {
  overflow: hidden; /* Hides any overflowing content */
}`}
        </code>
      </pre>
      <p>In the first example, scrollbars will appear if the content inside
        <code>.container</code> exceeds its width or height. In the second
        example, any content that overflows <code>.container-hidden</code> will
        be hidden from view.
      </p>
 



              </div>
            )}






{selectedChapter === 'chapter30' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Float</h1>
           
                   

      <p>
        The <code>float</code> property in CSS is used to control the positioning of an element within its container.
        It allows elements to be taken out of the normal document flow and positioned to the left or right of their
        containing block, allowing other content to wrap around them. Although <code>float</code> was originally designed
        for text wrapping around images, it has been used for various layout purposes.
      </p><br />

      <h2>Basic Usage</h2>
      <p>Here's a simple example:</p>
      <pre>
        <code>
{`<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <style>
    .container {
      width: 500px;
      border: 1px solid #000;
    }

    .float-left {
      float: left;
      width: 150px;
      height: 100px;
      background-color: lightblue;
      margin-right: 10px;
    }

    .float-right {
      float: right;
      width: 150px;
      height: 100px;
      background-color: lightcoral;
      margin-left: 10px;
    }

    .content {
      background-color: lightgreen;
      padding: 10px;
    }
  </style>
  <title>Float Example</title>
</head>
<body>
  <div class="container">
    <div class="float-left">Float Left</div>
    <div class="float-right">Float Right</div>
    <div class="content">
      This is the content area. Notice how the text wraps around the floated elements.
    </div>
  </div>
</body>
</html>`}
        </code>
      </pre><br/>

      <h2 style={{paddingBottom:"6px"}}>Key Points</h2>
      <ul>
        <li>
          <strong>Values:</strong>
          <ul>
            <li><code>left</code>: The element floats to the left of its containing block.</li><br/>
            <li><code>right</code>: The element floats to the right of its containing block.</li><br/>
            <li><code>none</code>: The default value. The element does not float and remains in the normal document flow.</li><br/>
            <li><code>inherit</code>: The element inherits the <code>float</code> value from its parent.</li>
          </ul>
        </li><br/>
        <li>
          <strong>Clearing Floats:</strong>
          <p>To prevent elements from wrapping around floated elements, you can use the <code>clear</code> property. This property
            can take values like <code>left</code>, <code>right</code>, <code>both</code>, or <code>none</code>, and ensures that the
            element will not be positioned next to floated elements.
          </p>
          <pre>
            <code>
{`.clear-both {
  clear: both;
}`}
            </code>
          </pre>
        </li><br/>
        <li>
          <strong>Common Issues:</strong>
          <ul>
            <li><strong>Clearing Issues:</strong> When you use <code>float</code>, the parent container might collapse if it only
              contains floated children. To fix this, you might need to use clearfix techniques to ensure the parent container
              expands to contain the floated elements.
            </li><br/>
            <li><strong>Overlapping Content:</strong> Floated elements can overlap with non-floated content if not properly managed.</li>
          </ul>
        </li><br/>
        <li>
          <strong>Modern Alternatives:</strong>
          <p>For complex layouts, modern CSS layout techniques like Flexbox and Grid are recommended over <code>float</code>, as they
            offer more control and are better suited for responsive designs.
          </p>
          <ul>
            <li><strong>Flexbox:</strong> Provides more flexible and intuitive layout arrangements for both horizontal and vertical alignment.</li><br/>
            <li><strong>Grid:</strong> Offers a two-dimensional layout system that helps in creating complex and responsive grid-based designs.</li>
          </ul>
        </li>
      </ul><br/>

      <h2>Example of Clearfix</h2>
      <p>To handle issues with floated elements collapsing their containers, you can use a clearfix hack:</p>
      <pre>
        <code>
{`/* Clearfix */
.clearfix::after {
  content: "";
  display: table;
  clear: both;
}`}
        </code>
      </pre>
      <p>Apply the <code>.clearfix</code> class to any container element that contains floated children to ensure it properly encompasses its content.</p>
  


              </div>
            )}







{selectedChapter === 'chapter31' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Inline-block</h1>
           

      <p>
        The <code>inline-block</code> value for the CSS <code>display</code> property is a combination of <code>inline</code> and <code>block</code> characteristics. It allows an element to flow inline with other content, like inline elements, but also allows it to have block-level properties, such as width and height.
      </p><br/>

      <h2 style={{paddingBottom:"6px"}}>Characteristics of <code>inline-block</code></h2>
      <ul>
        <li><strong>Inline Flow</strong>: An element with <code>display: inline-block</code> will sit alongside other inline elements and text in the same line, like <code>inline</code> elements. This means it will not start on a new line.</li><br/>
        <li><strong>Block-Level Properties</strong>: Unlike <code>inline</code> elements, <code>inline-block</code> elements respect width and height properties. You can set their width and height explicitly, which is not possible with <code>inline</code> elements.</li><br/>
        <li><strong>Margin and Padding</strong>: Margins and padding are respected, and you can control the spacing around <code>inline-block</code> elements.</li><br/>
        <li><strong>Vertical Alignment</strong>: <code>inline-block</code> elements align with the baseline of the line of text by default, but you can adjust their vertical alignment using the <code>vertical-align</code> property.</li>
      </ul><br/>

      <h2 style={{paddingBottom:"6px"}}>Common Uses</h2>
      <ul>
        <li><strong>Aligning Elements</strong>: <code>inline-block</code> is useful for aligning elements horizontally while still being able to set their size and spacing.</li><br/>
        <li><strong>Navigation Menus</strong>: It's often used in navigation bars where each menu item needs to be aligned horizontally and have consistent sizing.</li><br/>
        <li><strong>Layout Adjustments</strong>: It allows more control over layout compared to purely <code>inline</code> elements while avoiding the complexities of <code>float</code> or <code>flexbox</code> for simpler scenarios.</li>
      </ul><br />

      <h2>Example</h2>
      <p>Here’s a simple example to illustrate how <code>inline-block</code> works:</p>
      <pre>
        <code>
{`<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <style>
    .container {
      border: 1px solid #000;
      padding: 10px;
    }

    .box {
      display: inline-block;
      width: 100px;
      height: 100px;
      background-color: lightblue;
      margin: 10px;
      vertical-align: top;
    }

    .box2 {
      background-color: lightcoral;
    }
  </style>
  <title>Inline-Block Example</title>
</head>
<body>
  <div class="container">
    <div class="box">Box 1</div>
    <div class="box box2">Box 2</div>
    <div class="box">Box 3</div>
  </div>
</body>
</html>`}
        </code>
      </pre><br/>

      <h2 style={{paddingBottom:"6px"}}>Key Points in the Example</h2>
      <ul>
        <li><strong>Element Flow</strong>: The <code>.box</code> elements are displayed inline, but each has block-level characteristics like a set width and height.</li><br/>
        <li><strong>Spacing</strong>: The <code>margin</code> property affects spacing between <code>.box</code> elements, which is controlled just like block elements.</li><br/>
        <li><strong>Vertical Alignment</strong>: The <code>vertical-align</code> property adjusts how <code>.box</code> elements align with each other. In this case, they are aligned to the top.</li>
      </ul><br/>

      <h2 style={{paddingBottom:"6px"}}>Practical Considerations</h2>
      <ul>
        <li><strong>Whitespace</strong>: Inline-block elements respect whitespace in the HTML, which can sometimes result in unexpected gaps. This is due to the way browsers handle whitespace between inline-block elements.</li>
        <pre>
          <code>
{`<div class="box">Box 1</div> <!-- Note the space after this element -->
<div class="box">Box 2</div>`}
          </code>
        </pre>
        <li>To avoid gaps, you can either remove the space between elements in the HTML or use CSS techniques to control spacing.</li><br/>
        <li><strong>Modern Alternatives</strong>: For more complex layouts, modern CSS techniques like Flexbox and Grid can be more powerful and flexible, but <code>inline-block</code> remains useful for simpler, specific use cases.</li><br/>
      </ul>

      <p>Overall, <code>inline-block</code> is a versatile value that combines the layout control of block-level elements with the flow of inline elements, making it useful in various design scenarios.</p>
    

              </div>
            )}




{selectedChapter === 'chapter32' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}> Align</h1>
           
                   

      <p>
        In CSS, `align` is a term used to control the alignment of elements. There are several CSS properties related to alignment, each serving different purposes. Here's an overview of the main alignment properties:
      </p>

      <h2>1. Text Alignment</h2>
      <p className={style.textAlignExample}>
        <span className={style.textAlignLeft}>Text aligned to the left.</span>
        <span className={style.textAlignCenter}>Text aligned to the center.</span>
        <span className={style.textAlignRight}>Text aligned to the right.</span>
      </p><br /><br />

      <h2>2. Vertical Alignment</h2>
      <p className={style.verticalAlignExample}>
        <span className={style.alignMiddle}>Middle</span>
      </p><br /><br />

      <h2>3. Flexbox Alignment</h2>
      <div className={style.flexboxExample}>
        <div className={style.flexContainer}>
          <div className={style.flexItem}>Item 1</div>
          <div className={style.flexItem}>Item 2</div>
          <div className={style.flexItem}>Item 3</div>
        </div>
      </div><br /><br />

      <h2>4. Grid Alignment</h2>
      <div className={style.gridExample}>
        <div className={style.gridContainer}>
          <div className={style.gridItem}>Grid Item 1</div>
          <div className={style.gridItem}>Grid Item 2</div>
          <div className={style.gridItem}>Grid Item 3</div>
        </div>
      </div><br /><br />

      <h2>5. Text Alignment in Inline-Level Flex Containers</h2>
      <p>Inline-level flex containers can use the same alignment properties as block-level containers.</p>
    



              </div>
            )}



{selectedChapter === 'chapter11' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Combinators</h1>
      

        <p>
          In CSS, <strong>combinators</strong> are used to define relationships between selectors, allowing you to target elements based on their position relative to other elements. They help you create more complex and specific rules for styling your HTML documents. Here’s a breakdown of the different types of combinators and their uses:
        </p><br />

        <h2>1. Descendant Combinator (` `)</h2>
        <p>
          The descendant combinator selects elements that are nested within another element. It is represented by a space between two selectors.
        </p>
        <pre>
          <code>
            {`/* Selects all <p> elements inside <div> elements */\ndiv p {\n  color: blue;\n}`}
          </code>
        </pre><br />

        <h2>2. Child Combinator {"(`>`)"}</h2>
        <p>
          The child combinator selects elements that are direct children of a specified element. It is represented by {"a `>`"}between two selectors.
        </p>
        <pre>
          <code>
            {`/* Selects <p> elements that are direct children of <div> elements */\ndiv > p {\n  color: green;\n}`}
          </code>
        </pre><br />

        <h2>3. Adjacent Sibling Combinator (`+`)</h2>
        <p>
          The adjacent sibling combinator selects an element that is immediately preceded by a specified element. It is represented by a `+` between two selectors.
        </p>
        <pre>
          <code>
            {`/* Selects the <p> element that is immediately after any <h1> element */\nh1 + p {\n  color: red;\n}`}
          </code>
        </pre><br />

        <h2>4. General Sibling Combinator (`~`)</h2>
        <p>
          The general sibling combinator selects all elements that are siblings of a specified element and come after it. It is represented by a `~` between two selectors.
        </p>
        <pre>
          <code>
            {`/* Selects all <p> elements that are siblings of any <h1> element and come after it */\nh1 ~ p {\n  color: purple;\n}`}
          </code>
        </pre><br />

        <h3 style={{paddingBottom:"6px"}}>Examples and Use Cases</h3>

        <h4>Descendant Combinator</h4>
        <pre>
          <code>
            {`<style>\n  /* Selects all <span> elements inside <div> elements */\ndiv span {\n    color: blue;\n  }\n</style>\n\n<div>\n  <span>This text will be blue.</span>\n</div>`}
          </code>
        </pre><br />

        <h4>Child Combinator</h4>
        <pre>
          <code>
            {`<style>\n  /* Selects only the direct child <p> elements of <div> */\ndiv > p {\n    color: green;\n  }\n</style>\n\n<div>\n  <p>This text will be green.</p>\n  <div>\n    <p>This text will not be green, as it is not a direct child.</p>\n  </div>\n</div>`}
          </code>
        </pre><br />

        <h4>Adjacent Sibling Combinator</h4>
        <pre>
          <code>
            {`<style>\n  /* Selects the <p> that immediately follows an <h1> */\nh1 + p {\n    color: red;\n  }\n</style>\n\n<h1>This heading is followed by a red paragraph.</h1>\n<p>This text will be red.</p>\n<p>This text will not be red, as it does not immediately follow the <h1>.</p>`}
          </code>
        </pre><br />

        <h4>General Sibling Combinator</h4>
        <pre>
          <code>
            {`<style>\n  /* Selects all <p> elements that follow an <h1> */\nh1 ~ p {\n    color: purple;\n  }\n</style>\n\n<h1>This heading is followed by purple paragraphs.</h1>\n<p>This text will be purple.</p>\n<p>This text will also be purple.</p>\n<p>This text will also be purple.</p>`}
          </code>
        </pre><br />

        <h3 style={{paddingBottom:"6px"}}>Summary</h3>
        <ul>
          <li><strong>Descendant Combinator</strong> (`div p`): Selects all elements that are descendants of a specified element.</li><br />
          <li><strong>Child Combinator</strong> {"(`div > p`)"}: Selects only direct child elements of a specified element.</li><br />
          <li><strong>Adjacent Sibling Combinator</strong> (`h1 + p`): Selects the first element immediately following a specified element.</li><br />
          <li><strong>General Sibling Combinator</strong> (`h1 ~ p`): Selects all elements that follow a specified element and are siblings.</li>
        </ul><br />

        <p>
          Understanding and using combinators effectively can help you create more precise and efficient CSS rules, allowing for flexible and powerful styling of your web pages.
        </p>
  

      
              </div>
            )}




{selectedChapter === 'chapter12' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Pseudo-class</h1>
           
      

      <p>
        In CSS, a <strong>pseudo-class</strong> is used to define a special state or condition of an element. Pseudo-classes allow you to apply styles to elements based on their state, position, or other attributes without needing to add extra classes or modify HTML directly. They are prefixed with a colon (<code>:</code>) and can be used to select elements dynamically.
      </p><br />

      <h2 style={{paddingBottom:"6px"}}>Common Pseudo-Classes</h2>

      <ol>
        <li>
          <strong>:hover</strong>
          <p><strong>Description</strong>: Applies styles to an element when the mouse pointer hovers over it.</p>
          <pre>
            <code>
              {`a:hover {\n  color: red;\n}`}
            </code>
          </pre>
          <p>In this example, the link color changes to red when the mouse hovers over it.</p>
        </li><br />

        <li>
          <strong>:active</strong>
          <p><strong>Description</strong>: Applies styles to an element at the moment it is being activated, such as when a button is clicked.</p>
          <pre>
            <code>
              {`button:active {\n  background-color: blue;\n}`}
            </code>
          </pre>
          <p>Here, the button's background color changes to blue while it is being clicked.</p>
        </li><br />

        <li>
          <strong>:focus</strong>
          <p><strong>Description</strong>: Applies styles to an element that currently has focus, such as an input field or a link.</p>
          <pre>
            <code>
              {`input:focus {\n  border-color: green;\n}`}
            </code>
          </pre>
          <p>The input field’s border color turns green when it is focused.</p>
        </li><br />

        <li>
          <strong>:visited</strong>
          <p><strong>Description</strong>: Applies styles to links that have been visited by the user.</p>
          <pre>
            <code>
              {`a:visited {\n  color: purple;\n}`}
            </code>
          </pre>
          <p>Links that the user has previously visited will appear purple.</p>
        </li><br />

        <li>
          <strong>:nth-child(n)</strong>
          <p><strong>Description</strong>: Selects elements based on their position in a parent element. The <code>n</code> can be a number, keyword, or formula.</p>
          <pre>
            <code>
              {`li:nth-child(odd) {\n  background-color: lightgrey;\n}`}
            </code>
          </pre>
          <p>This example styles odd-numbered list items with a light grey background.</p>
        </li><br />

        <li>
          <strong>:first-child</strong>
          <p><strong>Description</strong>: Selects the first child element of its parent.</p>
          <pre>
            <code>
              {`p:first-child {\n  font-weight: bold;\n}`}
            </code>
          </pre>
          <p>The first <code>p</code> element within its parent will be bold.</p>
        </li><br />

        <li>
          <strong>:last-child</strong>
          <p><strong>Description</strong>: Selects the last child element of its parent.</p>
          <pre>
            <code>
              {`p:last-child {\n  margin-bottom: 0;\n}`}
            </code>
          </pre>
          <p>The last <code>p</code> element within its parent will have no bottom margin.</p>
        </li><br />

        <li>
          <strong>:not(selector)</strong>
          <p><strong>Description</strong>: Selects elements that do not match the specified selector.</p>
          <pre>
            <code>
              {`div:not(.special) {\n  background-color: lightblue;\n}`}
            </code>
          </pre>
          <p>This applies a light blue background to all <code>div</code> elements that do not have the class <code>special</code>.</p>
        </li><br />

        <li>
          <strong>:nth-of-type(n)</strong>
          <p><strong>Description</strong>: Selects elements of a specific type based on their position among siblings of the same type.</p>
          <pre>
            <code>
              {`p:nth-of-type(2) {\n  color: red;\n}`}
            </code>
          </pre>
          <p>The second <code>p</code> element among its siblings will be red.</p>
        </li><br />

        <li>
          <strong>:empty</strong>
          <p><strong>Description</strong>: Selects elements that have no children (including text nodes).</p>
          <pre>
            <code>
              {`div:empty {\n  border: 1px solid grey;\n}`}
            </code>
          </pre>
          <p>Any empty <code>div</code> element will have a grey border.</p>
        </li>
      </ol><br />

      <h2>Example of Usage</h2>
      <p>Here’s a practical example that combines several pseudo-classes:</p>

      <pre>
        <code>
          {`<!DOCTYPE html>\n<html lang="en">\n<head>\n  <meta charset="UTF-8">\n  <meta name="viewport" content="width=device-width, initial-scale=1.0">\n  <title>CSS Pseudo-Classes Example</title>\n  <style>\n    /* Hover effect */\n    a:hover {\n      color: red;\n    }\n    \n    /* Focus effect */\n    input:focus {\n      border-color: green;\n    }\n    \n    /* First child */\n    ul li:first-child {\n      font-weight: bold;\n    }\n    \n    /* Odd children */\n    ul li:nth-child(odd) {\n      background-color: lightgrey;\n    }\n    \n    /* Empty element */\n    div:empty {\n      border: 1px solid grey;\n    }\n  </style>\n</head>\n<body>\n  <ul>\n    <li>Item 1</li>\n    <li>Item 2</li>\n    <li>Item 3</li>\n  </ul>\n  <a href="#">Hover over me</a>\n  <input type="text" placeholder="Focus on me">\n  <div></div>\n</body>\n</html>`}
        </code>
      </pre><br />

      <h2 style={{paddingBottom:"6px"}}>Summary</h2>
      <ul>
        <li><strong>Pseudo-classes</strong> are used to apply styles based on the state or position of elements.</li><br />
        <li>They provide dynamic styling capabilities without changing the HTML structure.</li><br />
        <li>Common pseudo-classes include <code>:hover</code>, <code>:focus</code>, <code>:nth-child(n)</code>, and more.</li>
      </ul><br />
      <p>Understanding and using pseudo-classes effectively can enhance your ability to create interactive and well-styled web pages.</p>
    
                   

              </div>
            )}


{selectedChapter === 'chapter13' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Pseudo-element</h1>
           
       

      <p>
        In CSS, a <strong>pseudo-element</strong> is a keyword added to a selector that allows you to style a specific part of an element or create content dynamically. Unlike pseudo-classes, which style elements based on their state or position, pseudo-elements target specific parts of an element or create new elements within an existing one.
      </p><br />

      <h2 style={{paddingBottom:"6px"}}>Common Pseudo-Elements</h2>

      <ol>
        <li>
          <strong>::before</strong>
          <p><strong>Description</strong>: Inserts content before the content of an element.</p>
          <pre>
            <code>
              {`.example::before {\n  content: "Prefix - ";\n  color: red;\n}`}
            </code>
          </pre>
          <p>In this example, "Prefix - " will be added before the content of elements with the class <code>example</code>.</p>
        </li><br />

        <li>
          <strong>::after</strong>
          <p><strong>Description</strong>: Inserts content after the content of an element.</p>
          <pre>
            <code>
              {`.example::after {\n  content: " - Suffix";\n  color: blue;\n}`}
            </code>
          </pre>
          <p>Here, " - Suffix" will be appended after the content of elements with the class <code>example</code>.</p>
        </li><br />

        <li>
          <strong>::first-line</strong>
          <p><strong>Description</strong>: Targets the first line of text in an element.</p>
          <pre>
            <code>
              {`p::first-line {\n  font-weight: bold;\n  color: green;\n}`}
            </code>
          </pre>
          <p>This styles the first line of all <code>&lt;p&gt;</code> elements to be bold and green.</p>
        </li><br />

        <li>
          <strong>::first-letter</strong>
          <p><strong>Description</strong>: Targets the first letter of a block-level element.</p>
          <pre>
            <code>
              {`p::first-letter {\n  font-size: 2em;\n  color: orange;\n}`}
            </code>
          </pre>
          <p>This enlarges and colors the first letter of <code>&lt;p&gt;</code> elements.</p>
        </li><br />

        <li>
          <strong>::selection</strong>
          <p><strong>Description</strong>: Styles the portion of an element that is selected by the user.</p>
          <pre>
            <code>
              {`::selection {\n  background: yellow;\n  color: black;\n}`}
            </code>
          </pre>
          <p>This changes the background and text color of the selected text.</p>
        </li>
      </ol><br />

      <h2>Differences Between Pseudo-Elements and Pseudo-Classes</h2>
      <ul>
        <li><strong>Pseudo-Elements</strong> (<code>::before</code>, <code>::after</code>, <code>::first-line</code>, <code>::first-letter</code>, <code>::selection</code>) are used to style or insert content within an element without altering the HTML structure.</li><br />
        <li><strong>Pseudo-Classes</strong> (<code>:hover</code>, <code>:focus</code>, <code>:nth-child()</code>, <code>:last-child</code>, etc.) are used to apply styles based on the state of an element or its relationship with other elements.</li>
      </ul><br />

      <h2>Example Usage</h2>
      <p>Here’s a practical example that demonstrates some of the pseudo-elements:</p>

      <pre>
        <code>
          {`<!DOCTYPE html>\n<html lang="en">\n<head>\n  <meta charset="UTF-8">\n  <meta name="viewport" content="width=device-width, initial-scale=1.0">\n  <title>CSS Pseudo-Elements</title>\n  <style>\n    .box::before {\n      content: "[Start]";\n      color: red;\n    }\n    \n    .box::after {\n      content: "[End]";\n      color: blue;\n    }\n    \n    p::first-line {\n      font-weight: bold;\n    }\n    \n    p::first-letter {\n      font-size: 2em;\n      color: green;\n    }\n    \n    ::selection {\n      background: yellow;\n      color: black;\n    }\n  </style>\n</head>\n<body>\n  <div class="box">This is a box.</div>\n  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum.</p>\n  <p>Another paragraph with more content to demonstrate the first-letter styling.</p>\n</body>\n</html>`}
        </code>
      </pre><br />

      <h2>Summary</h2>
      <ul>
        <li><strong>Pseudo-elements</strong> are used to style specific parts of an element or add content before or after an element's content.</li><br />
        <li>They help you manipulate the content and appearance of elements more flexibly without changing the underlying HTML.</li><br />
        <li>They are useful for adding decorative content or applying styles to particular parts of an element.</li>
      </ul>
   
                   

              </div>
            )}




{selectedChapter === 'chapter134' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Opacity</h1>
           
  
      <p>
        In CSS, the <code>opacity</code> property controls the transparency level of an element and its contents. This property allows you to make an element partially or fully transparent, which can be useful for creating various visual effects and design purposes.
      </p><br />

      <h2>How It Works</h2>
      <p>
        <strong>Range of Values:</strong> The <code>opacity</code> property accepts a numeric value between <code>0</code> and <code>1</code>:
      </p>
      <ul>
        <li><code>0</code> means the element is fully transparent (completely invisible).</li><br />
        <li><code>1</code> means the element is fully opaque (not transparent at all).</li><br />
        <li>Values between <code>0</code> and <code>1</code> make the element partially transparent. For example, <code>0.5</code> makes the element 50% transparent.</li>
      </ul><br />

      <h2>Syntax</h2>
      <pre><code>opacity: &lt;value&gt;;</code></pre>
      <p><code>&lt;value&gt;</code> is a number between <code>0</code> (fully transparent) and <code>1</code> (fully opaque).</p><br />

      <h2>Example</h2>
      <p>Here's a simple example demonstrating the use of the <code>opacity</code> property:</p><br />
      
      <h3>HTML:</h3>
      <pre><code>{`<div class="transparent-box">This is a transparent box</div>`}</code></pre><br />

      <h3>CSS:</h3>
      <pre><code>{`.transparent-box {
  width: 100px;
  height: 100px;
  background-color: red;
  opacity: 0.5; /* 50% transparency */
}`}</code></pre>

      <p>In this example, the <code>.transparent-box</code> class creates a red box with 50% opacity, meaning it will be partially transparent.</p><br />

      <h2 style={{paddingBottom:"6px"}}>Key Points</h2>
      <ol>
        <li><strong>Affects Entire Element:</strong> The <code>opacity</code> property affects the entire element, including its content and child elements. If you set an element to be 50% transparent, all text, images, and other child elements inside it will also be 50% transparent.</li><br />
        <li><strong>Layering Effects:</strong> You can use <code>opacity</code> to create layering effects, such as overlaying text on an image where the text is semi-transparent.</li><br />
        <li><strong>Interaction with Background:</strong> If the element has a background color or image, <code>opacity</code> will also make the background transparent, which can affect the overall appearance and readability.</li><br />
        <li><strong>Alternative for Background Transparency:</strong> If you only want to make the background color of an element transparent while keeping the text or other content fully opaque, consider using the <code>rgba</code> color model for the background color. For example:</li>
      </ol><br />

      <pre><code>{`.semi-transparent-background {
  background-color: rgba(255, 0, 0, 0.5); /* Red background with 50% transparency */
}`}</code></pre><br />

      <h2 style={{paddingBottom:"6px"}}>Use Cases</h2>
      <ul>
        <li><strong>Visual Effects:</strong> To create fading effects or blend elements with backgrounds.</li><br />
        <li><strong>Overlays:</strong> To make overlay elements semi-transparent, allowing the background content to be partially visible.</li><br />
        <li><strong>Hover Effects:</strong> To change opacity on hover for interactive visual feedback.</li>
      </ul><br />

      <p>Understanding how <code>opacity</code> works helps in creating visually appealing and functional designs by controlling how elements blend with their backgrounds and other elements on the page.</p>
 
           
              </div>
            )}




{selectedChapter === 'chapter61' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Navigation Bar</h1>
          
          <p>
            A navigation bar (or navbar) in CSS is a user interface component 
            that provides links to different sections of a website. It's typically 
            horizontal, appearing at the top of a webpage, but it can also be 
            vertical or styled in various ways.
          </p><br />

          <h2 style={{paddingBottom:"6px"}}>Key Features of a Navigation Bar:</h2>
          <ul>
            <li><strong>Links</strong>: Contains links (usually <code>&lt;a&gt;</code> tags) to different pages or sections of the site.</li><br />
            <li><strong>Styling</strong>: Styled with CSS for appearance, including background colors, font styles, spacing, and hover effects.</li><br />
            <li><strong>Responsive Design</strong>: Often made responsive, adapting to different screen sizes using media queries.</li><br />
            <li><strong>Interactivity</strong>: May include dropdown menus or other interactive elements.</li>
          </ul><br />

          <h2>Basic Example:</h2>
          <p>Here’s a simple HTML and CSS example of a navigation bar:</p>
          
          <pre>
            <code>
              {`<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Navigation Bar Example</title>
    <style>
        body {
            font-family: Arial, sans-serif;
        }
        .navbar {
            background-color: #333;
            overflow: hidden;
        }
        .navbar a {
            float: left;
            display: block;
            color: white;
            text-align: center;
            padding: 14px 20px;
            text-decoration: none;
        }
        .navbar a:hover {
            background-color: #ddd;
            color: black;
        }
    </style>
</head>
<body>

<div class="navbar">
    <a href="#home">Home</a>
    <a href="#about">About</a>
    <a href="#services">Services</a>
    <a href="#contact">Contact</a>
</div>

</body>
</html>`}
            </code>
          </pre><br />

          <h2>Explanation:</h2>
          <p>
            <strong>HTML Structure:</strong> The navigation links are wrapped in a 
            <code>&lt;div&gt;</code> with a class of <code>.navbar</code>.
          </p><br />
          <p>
            <strong>CSS Styles:</strong> The navbar has a dark background and links 
            that float to the left. Links change color on hover for better interactivity.
          </p><br />
          <p>
            This is a basic example, but you can expand on it with more complex styles, 
            dropdowns, and JavaScript for additional functionality!
          </p><br />
    

              </div>
            )}





{selectedChapter === 'chapter62' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Dropdown</h1>
           


          <p>
            A dropdown in CSS refers to a user interface element that displays a list of options when a user interacts with it (typically by hovering over or clicking on a trigger element, like a button or a link). Dropdowns are commonly used in navigation bars, forms, and menus.
          </p><br />

          <h2 style={{paddingBottom:"6px"}}>Key Components of a Dropdown:</h2>
          <ul>
            <li><strong>Trigger Element</strong>: Usually a button or link that, when interacted with, shows the dropdown menu.</li><br />
            <li><strong>Dropdown Menu</strong>: A hidden element that appears when the trigger is activated, containing the options or links.</li>
          </ul><br />

          <h2>Basic Example:</h2>
          <p>Here’s a simple example of a CSS dropdown menu using HTML and CSS:</p>

          <pre>
            <code>
              {`<div className="navbar">
    <a href="#home">Home</a>
    <a href="#about">About</a>
    <div className="dropdown">
        <button className="dropbtn">Services</button>
        <div className="dropdown-content">
            <a href="#webdesign">Web Design</a>
            <a href="#seo">SEO</a>
            <a href="#marketing">Marketing</a>
        </div>
    </div>
    <a href="#contact">Contact</a>
</div>`}
            </code>
          </pre><br />


CSS For Navigationbar 
          <pre>
          <code>{`

          body {
    font-family: Arial, sans-serif;
}

.chaptercontent {
    padding: 20px;
}

.heading {
    margin-bottom: 20px;
}

.navbar {
    background-color: #333;
    overflow: hidden;
}

.navbar a {
    float: left;
    display: block;
    color: white;
    text-align: center;
    padding: 14px 20px;
    text-decoration: none;
}

.navbar a:hover {
    background-color: #ddd;
    color: black;
}

.dropdown {
    float: left;
    overflow: hidden;
}

.dropbtn {
    font-size: 16px;    
    border: none; 
    outline: none;
    color: white;    
    padding: 14px 20px;    
    background-color: inherit;
    font-family: inherit; 
    margin: 0; 
}

.dropdownContent {
    display: none; 
    position: absolute; 
    background-color: #f9f9f9; 
    min-width: 160px; 
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); 
    z-index: 1; 
}

.dropdownContent a {
    float: none; 
    color: black; 
    padding: 12px 16px; 
    text-decoration: none; 
    text-align: left; 
}

.dropdownContent a:hover {
    background-color: #ddd; 
}

.dropdown:hover .dropdownContent {
    display: block; 
}


`}</code>
          </pre><br />



          <h2>Explanation:</h2>
          <p>
            <strong>HTML Structure:</strong> The dropdown is contained within a <code>&lt;div className="dropdown"&gt;</code>, which includes a button (<code>&lt;button className="dropbtn"&gt;</code>) that triggers the dropdown content. The dropdown options are in another <code>&lt;div className="dropdown-content"&gt;</code>, which holds links.<br/>
          </p><br />
          <p>
            <strong>CSS Styles:</strong> Basic styling for the navbar and links. The <code>.dropdown-content</code> is initially hidden (<code>display: none</code>) and becomes visible when the parent <code>.dropdown</code> is hovered over.<br/>
          </p><br />
          <p>
            This basic structure can be customized with different styles, animations, and behaviors using CSS and JavaScript.<br/>
          </p>

          <div className={style.navbar}>
            <a href="#home">Home</a>
            <a href="#about">About</a>
            <div className={style.dropdown}>
              <button className={style.dropbtn}>Services</button>
              <div className={style.dropdownContent}>
                <a href="#webdesign">Web Design</a>
                <a href="#seo">SEO</a>
                <a href="#marketing">Marketing</a>
              </div>
            </div>
            <a href="#contact">Contact</a>
          </div><br/><br/>



              </div>
            )}



{selectedChapter === 'chapter67' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Image Gallery</h1>
           

          <p>
            An image gallery in CSS is a visual layout that displays a collection of images, often in a grid or masonry style. Galleries can be interactive, allowing users to click on images for larger views, slideshows, or additional information.
          </p><br />

          <h2>Key Features of an Image Gallery:</h2>
          <ul>
            <li><strong>Grid Layout</strong>: Images are typically arranged in a grid format, making them easy to browse.</li><br />
            <li><strong>Responsive Design</strong>: Galleries adapt to different screen sizes, maintaining a good layout on mobile and desktop.</li><br />
            <li><strong>Hover Effects</strong>: Visual effects that trigger when a user hovers over an image, like scaling or adding a border.</li><br />
            <li><strong>Lightbox Functionality</strong>: Often includes a lightbox feature to view images in larger formats without navigating away from the gallery.</li>
          </ul><br />

          <h2>Basic Example:</h2>
          <p>Here’s a simple example of an image gallery using HTML and CSS:</p>

          <pre>
            <code>
              {`<div className="gallery">
    <img src="image1.jpg" alt="Image 1" />
    <img src="image2.jpg" alt="Image 2" />
    <img src="image3.jpg" alt="Image 3" />
    <img src="image4.jpg" alt="Image 4" />
    <img src="image5.jpg" alt="Image 5" />
    <img src="image6.jpg" alt="Image 6" />
</div>`}
            </code>
          </pre><br />

         
              </div>
            )}



{selectedChapter === 'chapter68' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Image Sprites</h1>
           
      
          <p>
            Image sprites are a technique in web design used to combine multiple images into a single image file. This approach reduces the number of HTTP requests a browser has to make when loading a webpage, improving loading speed and overall performance.
          </p><br />
          <h2 style={{paddingBottom:"6px"}}>How It Works:</h2>
          <ol>
            <li>
              <strong>Single Image File:</strong> Instead of using multiple image files, you create a single image that contains all the images you want to use (like icons or buttons).
            </li><br />
            <li>
              <strong>CSS Positioning:</strong> You then use CSS to display only the part of the image that you want. This is done by adjusting the <code>background-image</code>, <code>background-position</code>, and <code>width</code>/<code>height</code> properties.
            </li>
          </ol><br />
          <h2>Example:</h2>
          <p>
            Suppose you have a sprite image containing three icons (home, search, and settings). You could use the following CSS:
          </p>
          <pre>
            <code>
              {`.sprite {
  background-image: url('sprite.png');
  display: inline-block;
}

.icon-home {
  width: 32px; /* Width of the icon */
  height: 32px; /* Height of the icon */
  background-position: 0 0; /* Position of the home icon */
}

.icon-search {
  width: 32px;
  height: 32px;
  background-position: -32px 0; /* Position of the search icon */
}

.icon-settings {
  width: 32px;
  height: 32px;
  background-position: -64px 0; /* Position of the settings icon */
}`}
            </code>
          </pre><br />
          <h2 style={{paddingBottom:"6px"}}>Benefits:</h2>
          <ul>
            <li><strong>Reduced HTTP Requests:</strong> Fewer image files mean less loading time.</li><br />
            <li><strong>Performance Improvement:</strong> Faster rendering of pages due to fewer server requests.</li><br />
            <li><strong>Better Organization:</strong> All related images in one file can simplify management.</li>
          </ul><br />
          <h2 style={{paddingBottom:"6px"}}>Considerations:</h2>
          <ul>
            <li><strong>Initial Setup:</strong> Creating a sprite image and managing its positions can be more complex.</li><br />
            <li><strong>Size Limitations:</strong> Large sprite images can become unwieldy and affect performance negatively if not managed properly.</li>
          </ul><br />
          <p>
            Using image sprites effectively can significantly enhance your website’s performance, especially if it relies on many small images!
          </p>
  

              </div>
            )}


{selectedChapter === 'chapter135' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}> Attribute Selectors</h1>
           
             
          <p>
            Attribute selectors in CSS allow you to select elements based on the presence or value of their attributes. This provides a way to apply styles to elements without relying solely on class or ID selectors.
          </p><br />

          <h2>Syntax</h2>
          <p>The basic syntax for attribute selectors is:</p>
          <pre>
            <code>
              {`[element] {
  /* styles */
}`}
            </code>
          </pre><br />

          <h2>Attribute Value Variants</h2>
          <ol>
            <li>
              <strong>Exists:</strong> Select elements with a specific attribute.
              <pre>
                <code>
                  {`a[target] {
  color: blue; /* Styles all <a> elements with a target attribute */
}`}
                </code>
              </pre>
            </li><br />
            <li>
              <strong>Exact Match:</strong> Select elements with a specific attribute value.
              <pre>
                <code>
                  {`input[type="text"] {
  border: 1px solid black; /* Styles input elements of type text */
}`}
                </code>
              </pre>
            </li><br />
            <li>
              <strong>Starts With:</strong> Select elements whose attribute value starts with a specified string.
              <pre>
                <code>
                  {`a[href^="https"] {
  color: green; /* Styles links that start with https */
}`}
                </code>
              </pre>
            </li><br />
            <li>
              <strong>Ends With:</strong> Select elements whose attribute value ends with a specified string.
              <pre>
                <code>
                  {`img[src$=".png"] {
  border: 2px solid red; /* Styles images with a .png extension */
}`}
                </code>
              </pre>
            </li><br />
            <li>
              <strong>Contains:</strong> Select elements whose attribute value contains a specified substring.
              <pre>
                <code>
                  {`a[href*="example"] {
  font-weight: bold; /* Styles links that contain 'example' in their href */
}`}
                </code>
              </pre>
            </li>
          </ol><br />

          <h2>Examples</h2>
          <pre>
            <code>
              {`/* Select all links with a target attribute */
a[target] {
  color: blue;
}

/* Select input fields of type email */
input[type="email"] {
  background-color: lightyellow;
}

/* Select images that are thumbnails (src ends with '-thumb.jpg') */
img[src$="-thumb.jpg"] {
  width: 100px;
  height: auto;
}

/* Select buttons with a specific data attribute */
button[data-status="active"] {
  background-color: green;
  color: white;
}`}
            </code>
          </pre><br />

          <h2 style={{paddingBottom:"6px"}}>Benefits</h2>
          <ul>
            <li><strong>Specificity:</strong> You can target elements more specifically without needing to add classes or IDs.</li><br />
            <li><strong>Flexibility:</strong> It allows for dynamic styling based on attribute values, useful in many scenarios.</li>
          </ul><br />

          <h2 style={{paddingBottom:"6px"}}>Use Cases</h2>
          <ul>
            <li>Styling form inputs based on type (e.g., text, password, checkbox).</li><br />
            <li>Custom styling for links based on their `href` attributes.</li><br />
            <li>Selecting elements based on custom data attributes (e.g., `data-*`).</li>
          </ul><br />

          <p>
            Attribute selectors can greatly enhance the flexibility and maintainability of your CSS!
          </p>
   

                   

              </div>
            )}



{selectedChapter === 'chapter66' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Forms</h1>
           
          <p>
            In CSS, the term "form" generally refers to the styling and layout of HTML forms. Forms are essential for user interaction on web pages, allowing users to input data, submit information, and interact with applications. CSS can be used to enhance the appearance of forms, making them more user-friendly and visually appealing.
          </p><br />

          <h2 style={{paddingBottom:"6px"}}>Key Components of Forms in CSS</h2>
          <ol>
            <li>
              <strong>Form Elements:</strong> These include input fields, text areas, select boxes, buttons, checkboxes, and radio buttons. Each element can be styled individually.
            </li><br/>
            <li>
              <strong>Layout:</strong> You can use CSS to control the layout of form elements, including positioning, spacing (margins and paddings), and alignment (using flexbox or grid).
            </li><br/>
            <li>
              <strong>Styling States:</strong> Forms can have different states (like focus, hover, and active), and CSS can be used to style these states to provide visual feedback to users.
            </li><br/>
          </ol>

          <h2 style={{paddingBottom:"6px"}}>Common CSS Properties for Form Styling</h2>
          <ul>
            <li><strong>Width and Height:</strong> To define the size of form elements.</li><br/>
            <li><strong>Margin and Padding:</strong> To control spacing around and inside form elements.</li><br/>
            <li><strong>Borders:</strong> To add borders around inputs and other elements, enhancing visual separation.</li><br/>
            <li><strong>Background Color:</strong> To change the background color of inputs and buttons.</li><br/>
            <li><strong>Font Styles:</strong> To set font size, weight, and color for text in form elements.</li><br/>
            <li><strong>Cursor:</strong> To change the cursor when hovering over buttons or inputs (e.g., <code>cursor: pointer;</code>).</li>
          </ul><br/>

          <h2>Example of Styled Form</h2>
          <p>Here’s a simple example of how to style a form using CSS:</p>
          <pre>
            <code>
              {`<form class="contact-form">
  <label for="name">Name:</label>
  <input type="text" id="name" name="name" required />

  <label for="email">Email:</label>
  <input type="email" id="email" name="email" required />

  <label for="message">Message:</label>
  <textarea id="message" name="message" rows="4" required></textarea>

  <button type="submit">Submit</button>
</form>`}
            </code>
          </pre><br/>

          <h2>CSS Styling Example</h2>
          <pre>
            <code>
              {`.contact-form {
  max-width: 400px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.contact-form label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.contact-form button {
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  background-color: #28a745;
  color: white;
  cursor: pointer;
}

.contact-form button:hover {
  background-color: #218838;
}`}
            </code>
          </pre><br/>

          <h2>Benefits of Styling Forms</h2>
          <ul>
            <li><strong>Improved Usability:</strong> Clear, well-styled forms are easier for users to understand and navigate.</li><br/>
            <li><strong>Visual Appeal:</strong> Aesthetically pleasing forms can enhance the overall design of a website.</li><br/>
            <li><strong>Feedback and Interaction:</strong> Styling hover and focus states provides immediate feedback, improving user experience.</li>
          </ul><br/>

          <h2>Conclusion</h2>
          <p>
            CSS plays a crucial role in the appearance and functionality of forms on websites. By using various CSS properties, you can create forms that are not only functional but also visually appealing and user-friendly.
          </p>
      


              </div>
            )}




{selectedChapter === 'chapter69' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Counters</h1>
           
            
          <p>
            In CSS, counters are a way to keep track of how many times an element has been generated and can be used for automatic numbering. This is particularly useful for things like ordered lists, sections, and other elements where you want to display a count dynamically without having to manually set numbers.
          </p><br />

          <h2 style={{paddingBottom:"6px"}}>Key Features of CSS Counters</h2>
          <ol>
            <li>
              <strong>Definition:</strong> You define a counter using the <code>counter-reset</code> property and increment it with the <code>counter-increment</code> property.
            </li><br/>
            <li>
              <strong>Usage:</strong> Counters can be displayed using the <code>content</code> property, typically in conjunction with pseudo-elements like <code>::before</code> or <code>::after</code>.
            </li>
          </ol><br />

          <h2 style={{paddingBottom:"6px"}}>Basic Syntax</h2>
          <h3>Creating a Counter</h3>
          <pre>
            <code>{`.container {
  counter-reset: myCounter; /* Initialize the counter */
}`}</code>
          </pre><br/>

          <h3>Incrementing a Counter</h3>
          <pre>
            <code>{`.item {
  counter-increment: myCounter; /* Increment the counter for each item */
}`}</code>
          </pre><br/>

          <h3>Displaying a Counter</h3>
          <pre>
            <code>{`.item::before {
  content: counter(myCounter) ". "; /* Display the counter value */
}`}</code>
          </pre><br/>

          <h2>Example</h2>
          <p>Here’s a simple example of how to use counters in CSS:</p>
          <pre>
            <code>{`<div class="container">
  <div class="item">First item</div>
  <div class="item">Second item</div>
  <div class="item">Third item</div>
</div>`}</code>
          </pre><br/>

          <h2>CSS Styling Example</h2>
          <pre>
            <code>{`.container {
  counter-reset: myCounter; /* Initialize the counter */
}

.item {
  counter-increment: myCounter; /* Increment the counter */
  margin: 10px 0; /* Add some spacing */
}

.item::before {
  content: counter(myCounter) ". "; /* Display the counter before each item */
  font-weight: bold; /* Make it bold */
}`}</code>
          </pre><br/>

          <h2>Result</h2>
          <p>The output will look like:</p>
          <pre>
            <code>{`1. First item
2. Second item
3. Third item`}</code>
          </pre><br/>

          <h2 style={{paddingBottom:"6px"}}>Use Cases</h2>
          <ul>
            <li><strong>Numbered Lists:</strong> Automatically number items in a list without using <code>&lt;ol&gt;</code>.</li><br/>
            <li><strong>Section Headings:</strong> Number sections of a document or article.</li><br/>
            <li><strong>Dynamic Content:</strong> Useful in dynamically generated content where the number of items may vary.</li>
          </ul><br/>

          <h2>Advantages of Using Counters</h2>
          <ul>
            <li><strong>Dynamic Updating:</strong> Counters automatically adjust when items are added or removed.</li><br/>
            <li><strong>Clean Markup:</strong> Reduces the need for extra HTML elements just for numbering.</li><br/>
            <li><strong>Styling Flexibility:</strong> Counters can be styled like any other CSS property.</li>
          </ul><br/>

          <h2>Conclusion</h2>
          <p>
            CSS counters are a powerful feature for managing and displaying numerical content dynamically. They simplify the process of numbering elements and enhance the semantic quality of your HTML.
          </p>
       


              </div>
            )}




{selectedChapter === 'chapter70' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Website Layout</h1>
 
          <p>
            Website layout using CSS (Cascading Style Sheets) involves using various CSS properties to structure and design the visual presentation of a web page. Here are some key concepts and techniques commonly used in CSS for layout:
          </p><br />

          <h2 style={{paddingBottom:"6px"}}>1. Box Model</h2>
          <ul>
            <li><strong>Content</strong>: The actual content of the box (text, images).</li><br />
            <li><strong>Padding</strong>: Space between the content and the border.</li><br />
            <li><strong>Border</strong>: The line surrounding the padding (if any).</li><br />
            <li><strong>Margin</strong>: Space outside the border, separating the element from others.</li>
          </ul><br />

          <h2 style={{paddingBottom:"6px"}} >2. Display Property</h2>
          <ul>
            <li><strong>block</strong>: Elements take the full width available.</li><br />
            <li><strong>inline</strong>: Elements take only the width of their content.</li><br />
            <li><strong>inline-block</strong>: Elements take the width of their content but can have padding and margins.</li><br />
            <li><strong>flex</strong>: Creates a flexible layout structure.</li><br />
            <li><strong>grid</strong>: Enables a two-dimensional layout system.</li>
          </ul><br />

          <h2>3. Flexbox</h2>
          <p>
            A layout model that allows for the arrangement of items in a one-dimensional space (either row or column). Useful for aligning and distributing space among items in a container.
          </p>
          <pre>
            <code>{`.container {
  display: flex;
  justify-content: space-between; /* Aligns items */
  align-items: center; /* Vertically centers items */
}`}</code>
          </pre><br />

          <h2>4. CSS Grid</h2>
          <p>
            A powerful layout system for creating two-dimensional layouts. Allows for precise control over rows and columns.
          </p>
          <pre>
            <code>{`.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three equal columns */
  gap: 10px; /* Space between items */
}`}</code>
          </pre><br />

          <h2 style={{paddingBottom:"6px"}} >5. Positioning</h2>
          <ul>
            <li><strong>static</strong>: Default position; elements are positioned according to the normal flow.</li><br />
            <li><strong>relative</strong>: Positioned relative to its normal position.</li><br />
            <li><strong>absolute</strong>: Positioned relative to its nearest positioned ancestor.</li><br />
            <li><strong>fixed</strong>: Positioned relative to the viewport, stays in place when scrolling.</li><br />
            <li><strong>sticky</strong>: Toggles between relative and fixed, based on scroll position.</li>
          </ul><br />

          <h2>6. Media Queries</h2>
          <p>
            Used for responsive design, allowing styles to change based on screen size.
          </p>
          <pre>
            <code>{`@media (max-width: 600px) {
  .container {
    flex-direction: column; /* Stack items on smaller screens */
  }
}`}</code>
          </pre><br />

          <h2>7. Responsive Units</h2>
          <p>
            Using percentages, <code>em</code>, <code>rem</code>, <code>vw</code>, and <code>vh</code> for sizing elements responsively.
          </p><br />

          <h2>Example Layout</h2>
          <p>Here’s a simple example combining these concepts:</p>
          <pre>
            <code>{`<div class="header">Header</div>
<div class="container">
    <div class="sidebar">Sidebar</div>
    <div class="main-content">Main Content</div>
    <div class="sidebar">Sidebar</div>
</div>
<div class="footer">Footer</div>`}</code>
          </pre><br />

          <h2>CSS Example</h2>
          <pre>
            <code>{`body {
    display: flex;
    flex-direction: column;
    margin: 0;
}

.header, .footer {
    background-color: #333;
    color: white;
    text-align: center;
    padding: 1rem;
}

.container {
    display: flex;
    flex: 1;
}

.sidebar {
    background-color: #f4f4f4;
    width: 200px;
    padding: 1rem;
}

.main-content {
    flex: 1; /* Takes remaining space */
    padding: 1rem;
}`}</code>
          </pre><br />

          <p>
            This CSS creates a basic layout with a header, footer, sidebars, and main content area. You can expand and customize it based on your design needs!
          </p>



              </div>
            )}




{selectedChapter === 'chapter71' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Units</h1>
    
          <p>
            In CSS, units are used to define lengths, sizes, and dimensions. Understanding the different units available can help you create responsive and adaptable layouts. Here’s an overview of the main types of units in CSS:
          </p><br />

          <h2>1. Absolute Units</h2>
          <p>Absolute units are fixed and do not change based on other elements. Common absolute units include:</p>
          <ul>
            <li><strong>px (pixels)</strong>: The most common unit, representing a single dot on the screen. It’s absolute and does not scale with the user's settings.</li><br />
            <li><strong>pt (points)</strong>: Mainly used in print styles; 1 point is 1/72 of an inch.</li><br />
            <li><strong>in (inches)</strong>: Represents physical inches. Not commonly used in web design.</li><br />
            <li><strong>cm (centimeters)</strong>: Represents physical centimeters. Also not common in web design.</li><br />
            <li><strong>mm (millimeters)</strong>: Represents physical millimeters. Rarely used.</li>
          </ul><br />

          <h2>2. Relative Units</h2>
          <p>Relative units depend on other elements and can provide more flexibility, especially for responsive design. Common relative units include:</p>
          <ul>
            <li><strong>em</strong>: Relative to the font-size of the element. For example, if an element has a font-size of 16px, <code>2em</code> would be 32px.</li><br />
            <li><strong>rem (root em)</strong>: Relative to the font-size of the root element (<code>&lt;html&gt;</code>). This unit is more consistent across the site compared to <code>em</code>.</li><br />
            <li><strong>% (percentages)</strong>: Often used to specify a size relative to the parent element's size. For example, <code>50%</code> width means half of the parent element's width.</li><br />
            <li><strong>vw (viewport width)</strong>: Represents a percentage of the viewport's width. <code>1vw</code> is equal to 1% of the viewport width.</li><br />
            <li><strong>vh (viewport height)</strong>: Represents a percentage of the viewport's height. <code>1vh</code> is equal to 1% of the viewport height.</li><br />
            <li><strong>vmin</strong>: The smaller value of either <code>vw</code> or <code>vh</code>. This helps maintain responsiveness regardless of the orientation (landscape or portrait).</li><br />
            <li><strong>vmax</strong>: The larger value of either <code>vw</code> or <code>vh</code>. Useful for making elements responsive in one direction.</li><br />
          </ul><br />

          <h2>3. CSS Functions</h2>
          <p>CSS also provides functions that can dynamically calculate lengths:</p>
          <ul>
            <li><strong>calc()</strong>: Allows you to perform calculations. For example, <code>width: calc(100% - 50px);</code> calculates the width by subtracting 50px from 100%.</li>
          </ul><br />

          <h2 style={{paddingBottom:"6px"}}>Choosing the Right Unit</h2>
          <ul>
            <li><strong>Pixels (px)</strong>: Good for precise layouts, but not responsive.</li><br />
            <li><strong>Relative units (em, rem)</strong>: Ideal for responsive typography and layouts.</li><br />
            <li><strong>Viewport units (vw, vh)</strong>: Great for full-screen elements and responsive designs.</li>
          </ul><br />

          <h2>Example</h2>
          <pre>
            <code>{`.container {
  width: 80%; /* 80% of the parent width */
  padding: 1em; /* 1 times the font-size of the element */
  margin: 0 auto; /* Centered */
}

.text {
  font-size: 2rem; /* 2 times the font-size of the root element */
}

.full-screen {
  height: 100vh; /* Full height of the viewport */
  width: 100vw; /* Full width of the viewport */
}`}</code>
          </pre><br />

          <p>
            By choosing the appropriate units, you can create flexible, accessible, and responsive web designs.
          </p>
        


              </div>
            )}





{selectedChapter === 'chapter14' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Specificity</h1>
           
            
          <p>
            In CSS, specificity determines which styles are applied to an element when multiple rules could apply. It's a way of resolving conflicts in styles by assigning a weight to selectors based on their types.
          </p><br />
          <p style={{paddingBottom:"6px"}}>Specificity is calculated based on the following rules:</p>
          <ol>
            <li>
              <strong>Inline styles</strong> (e.g., <code>style="..."</code>) have the highest specificity. They score 1000.
            </li><br/>
            <li>
              <strong>IDs</strong> (e.g., <code>#example</code>) have a score of 100.
            </li><br/>
            <li>
              <strong>Classes</strong>, <strong>attributes</strong>, and <strong>pseudo-classes</strong> (e.g., <code>.example</code>, <code>[type="text"]</code>, <code>:hover</code>) score 10.
            </li><br/>
            <li>
              <strong>Elements</strong> and <strong>pseudo-elements</strong> (e.g., <code>div</code>, <code>p</code>, <code>::before</code>) score 1.
            </li><br/>
          </ol>
          <h2>Example:</h2>
          <ul>
            <li><code>#header</code> has a specificity of 100.</li><br/>
            <li><code>.nav</code> has a specificity of 10.</li><br/>
            <li><code>h1</code> has a specificity of 1.</li><br/>
            <li><code>style="color: red;"</code> has a specificity of 1000.</li>
          </ul><br/>
          <p>
            If you have the following CSS:
          </p>
          <pre>
            {`h1 {
  color: blue;
}

.nav {
  color: green;
}

#header {
  color: red;
}

<style>
  color: purple; /* Inline style */
}
`}
          </pre>
          <p>
            The final color of the <code>&lt;h1&gt;</code> element would be <strong>red</strong> because the ID selector (<code>#header</code>) has a higher specificity than the class and element selectors.
          </p><br/>
          <h2>Calculating Specificity:</h2>
          <p>
            You can think of specificity as a four-part number, where each part represents the counts of each selector type:
          </p>
          <ul>
            <li>Inline styles: <strong>(1)</strong></li>
            <li>IDs: <strong>(0, 1, 0, 0)</strong></li>
            <li>Classes: <strong>(0, 0, 1, 0)</strong></li>
            <li>Elements: <strong>(0, 0, 0, 1)</strong></li>
          </ul>
          <p>
            For example, for the selector <code>.nav #header h1</code>, the specificity would be calculated as:
          </p>
          <ul>
            <li>0 (inline styles)</li>
            <li>1 (1 ID)</li>
            <li>1 (1 class)</li>
            <li>3 (3 elements)</li>
          </ul>
          <p>
            So it scores <strong>(0, 1, 1, 3)</strong>.
          </p>
          <p>
            Understanding specificity helps in managing styles effectively, especially in larger projects where multiple CSS rules may apply to the same elements.
          </p>





              </div>
            )}




{selectedChapter === 'chapter72' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>!important</h1>
           
          <p>
            In CSS, <code>!important</code> is a special declaration that can be added to a style rule to give it higher priority over other conflicting rules. When a style is marked as <code>!important</code>, it overrides any other styles that may apply to the same element, regardless of their specificity.
          </p><br />
          <h2>Usage</h2>
          <p>You can use <code>!important</code> like this:</p>
          <pre>
            {`p {
  color: blue !important;
}

p {
  color: red; /* This will be ignored because of the !important in the previous rule */
}`}
          </pre>
          <p>
            In this example, all <code>&lt;p&gt;</code> elements will be blue, even if there are other rules trying to set the color to red.
          </p><br />
          <h2 style={{paddingBottom:"6px"}}>When to Use <code>!important</code></h2>
          <ul>
            <li><strong>Quick Fixes</strong>: It can be useful for overriding styles quickly during debugging or when you're working with third-party styles.</li><br />
            <li><strong>Legacy Code</strong>: Sometimes necessary in legacy systems where you can't modify the original CSS.</li>
          </ul><br />
          <h2>Caution</h2>
          <p>
            While <code>!important</code> can be useful, it should be used sparingly because:
          </p>
          <ol>
            <li><strong>Maintenance Issues</strong>: It can make your CSS harder to read and maintain, as it breaks the natural cascading rules.</li><br />
            <li><strong>Debugging Challenges</strong>: Overusing <code>!important</code> can lead to confusion when trying to figure out why a style is not being applied.</li><br />
            <li><strong>Specificity Wars</strong>: Relying on <code>!important</code> can lead to a cycle of adding more <code>!important</code> declarations, making the CSS increasingly complex.</li>
          </ol><br />
          <h2 style={{paddingBottom:"6px"}}>Best Practices</h2>
          <ul>
            <li>Try to rely on CSS specificity first.</li><br />
            <li>Use <code>!important</code> only when absolutely necessary.</li><br />
            <li>Consider refactoring your styles if you find yourself using it frequently.</li>
          </ul><br />
          <p>
            In summary, while <code>!important</code> can be a powerful tool, it should be used judiciously to maintain clean and manageable CSS.
          </p>
     

              </div>
            )}



{selectedChapter === 'chapter73' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Math Function</h1>
       
          <p>
            CSS provides several mathematical functions that allow you to perform calculations directly within your styles. These functions can be particularly useful for responsive design, layout adjustments, and more. Here are the primary math functions in CSS:
          </p><br />

          <h2>1. <code>calc()</code></h2>
          <p>
            The <code>calc()</code> function lets you perform calculations to set CSS property values. You can combine different units (e.g., percentages and pixels).
          </p>
          <pre>
            {`width: calc(100% - 50px);`}
          </pre><br />

          <h2>2. <code>min()</code></h2>
          <p>
            The <code>min()</code> function returns the smallest value from a list of values. It can be used to ensure that a property doesn’t exceed a certain limit.
          </p>
          <pre>
            {`width: min(50vw, 400px);`}
          </pre><br />

          <h2>3. <code>max()</code></h2>
          <p>
            The <code>max()</code> function returns the largest value from a list. It can be useful for setting a property to not go below a certain threshold.
          </p>
          <pre>
            {`width: max(200px, 30%);`}
          </pre><br />

          <h2>4. <code>clamp()</code></h2>
          <p>
            The <code>clamp()</code> function combines the effects of <code>min()</code> and <code>max()</code>, allowing you to set a value that scales between a defined minimum and maximum.
          </p>
          <pre>
            {`font-size: clamp(1rem, 2vw, 2rem);`}
          </pre><br />

          <h2 style={{paddingBottom:"6px"}}>Usage Scenarios</h2>
          <ul>
            <li><strong>Responsive Design</strong>: Adjust sizes based on viewport dimensions.</li><br />
            <li><strong>Flexible Layouts</strong>: Create layouts that adapt to different screen sizes and resolutions.</li><br />
            <li><strong>Dynamic Spacing</strong>: Calculate margins, paddings, or gaps that depend on other elements.</li>
          </ul><br />

          <h2>Combining Functions</h2>
          <p>
            You can nest these functions for more complex calculations. For example:
          </p>
          <pre>
            {`margin: calc(min(20px, 5%) + 10px);`}
          </pre><br />

          <h2>Conclusion</h2>
          <p>
            Math functions in CSS greatly enhance your ability to create flexible and responsive designs, making your styles more adaptable to different contexts and screen sizes. They provide powerful tools for calculating dimensions, spacing, and more, directly in your stylesheets.
          </p>
       



              </div>
            )}



            

{selectedChapter === 'chapter74' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Rounded corners</h1>
           

          <p>
            Rounded corners in CSS are achieved using the <code>border-radius</code> property, which allows you to create corners with a specified radius, giving elements a softer and more visually appealing appearance. This property can be applied to all HTML elements that have a border, including <code>div</code>, <code>img</code>, <code>input</code>, and others.
          </p><br />

          <h2>Basic Usage</h2>
          <p>Here’s how to use <code>border-radius</code>:</p>
          <pre>
            {`.box {
  width: 200px;
  height: 100px;
  background-color: lightblue;
  border-radius: 10px; /* Creates rounded corners with a radius of 10px */
}`}
          </pre><br />

          <h2>Values</h2>
          <ol>
            <li>
              <strong>Single Value</strong>: Applies the same radius to all four corners.
              <pre>
                {`border-radius: 15px; /* All corners rounded */`}
              </pre>
            </li><br />
            <li>
              <strong>Two Values</strong>: The first value applies to the top-left and bottom-right corners, and the second applies to the top-right and bottom-left corners.
              <pre>
                {`border-radius: 10px 20px; /* TL & BR: 10px, TR & BL: 20px */`}
              </pre>
            </li><br />
            <li>
              <strong>Four Values</strong>: Specifies the radius for each corner in the order: top-left, top-right, bottom-right, bottom-left.
              <pre>
                {`border-radius: 10px 20px 30px 40px; /* TL, TR, BR, BL */`}
              </pre>
            </li>
          </ol><br />

          <h2>Elliptical Corners</h2>
          <p>
            You can also create elliptical corners by providing two values for each corner:
          </p>
          <pre>
            {`border-radius: 50px 25px; /* Horizontal radius: 50px, Vertical radius: 25px */`}
          </pre><br />

          <h2>Percentage Values</h2>
          <p>
            Using percentages allows for responsive and flexible designs:
          </p>
          <pre>
            {`border-radius: 50%; /* Creates a circle if the element is square */`}
          </pre><br />

          <h2 style={{paddingBottom:"6px"}}>Examples</h2>
          <h3>1. Simple Box with Rounded Corners</h3>
          <pre>
            {`<div class="box"></div>`}
          </pre><br />
          <h3>2. Circle</h3>
          <pre>
            {`.circle {
  width: 100px;
  height: 100px;
  background-color: lightcoral;
  border-radius: 50%; /* Perfect circle */
}`}
          </pre><br />
          <h3>3. Responsive Rounded Corners</h3>
          <pre>
            {`.responsive {
  width: 100%;
  height: 200px;
  background-color: lightgreen;
  border-radius: 20%; /* Rounded corners with percentage */
}`}
          </pre><br />

          <h2>Conclusion</h2>
          <p>
            Rounded corners enhance the aesthetics of elements in web design, making them appear softer and more modern. By using the <code>border-radius</code> property creatively, you can significantly improve the visual appeal of your user interface.
          </p>
      


              </div>
            )}



{selectedChapter === 'chapter75' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Border Images</h1>
        
          <p>
            Border images in CSS allow you to use an image as a border for an element instead of a solid color or a gradient. This can create visually interesting effects and can be particularly useful for decorative purposes. The <code>border-image</code> property is a shorthand property that combines several other properties for defining how an image is used to create the border.
          </p><br />

          <h2 style={{paddingBottom:"6px"}} >Key Properties</h2>
          <ol>
            <li><strong><code>border-image-source</code></strong>: Specifies the image to be used as the border.</li><br />
            <li><strong><code>border-image-slice</code></strong>: Defines how to slice the image into sections to use as the border. It can take values in pixels or percentages.</li><br />
            <li><strong><code>border-image-width</code></strong>: Specifies the width of the border image. It can be set in pixels, percentages, or using the keyword <code>auto</code>.</li><br />
            <li><strong><code>border-image-outset</code></strong>: Defines the space between the border image and the border box. It can also be specified in pixels or percentages.</li><br />
            <li><strong><code>border-image-repeat</code></strong>: Determines how the border image is repeated. It can take values like <code>stretch</code>, <code>repeat</code>, or <code>round</code>.</li>
          </ol><br />

          <h2>Basic Syntax</h2>
          <pre>
            {`.element {
  border-width: 20px; /* Must specify a border width */
  border-image-source: url('path/to/image.png');
  border-image-slice: 30; /* Slice the image */
  border-image-width: 20px; /* Width of the border image */
  border-image-outset: 5px; /* Outset of the border image */
  border-image-repeat: stretch; /* How the image is repeated */
}`}
          </pre><br />

          <h2>Example</h2>
          <p>Here’s a simple example to demonstrate how to use border images:</p>
          <pre>
            {`<div class="border-image-example">Hello, World!</div>`}
          </pre>
          <pre>
            {`.border-image-example {
  border-width: 20px;
  border-style: solid; /* Necessary for border-image to work */
  border-image-source: url('border-image.png');
  border-image-slice: 30; /* Slices the image */
  border-image-width: 20px; /* Sets the width of the image border */
  border-image-repeat: stretch; /* Stretches the image */
  padding: 20px; /* Adds space inside the border */
  background-color: white; /* Background color for better visibility */
}`}
          </pre><br />

          <h2 style={{paddingBottom:"6px"}}>Usage Tips</h2>
          <ul>
            <li><strong>Image Preparation</strong>: Ensure that the image is designed to work as a border. It should have a seamless design, especially if you’re using it with <code>repeat</code>.</li><br />
            <li><strong>Border Width</strong>: Always specify a <code>border-width</code> for the <code>border-image</code> to take effect.</li><br />
            <li><strong>Browser Support</strong>: While widely supported in modern browsers, it's always good to check compatibility for specific use cases.</li>
          </ul><br />

          <h2>Conclusion</h2>
          <p>
            Border images can add a creative touch to your designs, allowing you to create unique borders that enhance the overall aesthetic of your web pages. By combining images with the CSS border properties, you can achieve visually striking effects that go beyond traditional solid borders.
          </p>
 


              </div>
            )}


{selectedChapter === 'chapter37' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Color Keywords</h1>
           

          <p>
            Color keywords in CSS are predefined names that represent specific colors. They offer a simple way to set colors without needing to use hex codes, RGB, or other color formats. Here’s a quick overview of how they work:
          </p><br />

          <h2 style={{paddingBottom:"6px"}}>Common Color Keywords</h2>
          <h3>Basic Colors</h3>
          <ul>
            <li><code>red</code></li>
            <li><code>green</code></li>
            <li><code>blue</code></li>
            <li><code>yellow</code></li>
            <li><code>black</code></li>
            <li><code>white</code></li>
            <li><code>gray</code></li>
          </ul><br />

          <h3>Extended Colors</h3>
          <ul>
            <li><code>lightblue</code></li>
            <li><code>darkgreen</code></li>
            <li><code>orange</code></li>
            <li><code>purple</code></li>
            <li><code>pink</code></li>
          </ul><br />

          <h3>Others</h3>
          <ul>
            <li><code>transparent</code></li>
            <li><code>navy</code></li>
            <li><code>teal</code></li>
            <li><code>silver</code></li>
          </ul><br />

          <h2>Example Usage</h2>
          <p>You can use color keywords in various CSS properties, such as <code>color</code>, <code>background-color</code>, <code>border-color</code>, etc. Here’s how you might use them:</p>
          <pre>
            {`h1 {
  color: blue; /* Sets the text color to blue */
}

.button {
  background-color: green; /* Sets the background color to green */
  border: 1px solid orange; /* Sets the border color to orange */
}`}
          </pre><br />

          <h2 style={{paddingBottom:"6px"}} >Advantages</h2>
          <ul>
            <li><strong>Simplicity</strong>: Using color keywords is often simpler and more readable than using hex or RGB values.</li><br />
            <li><strong>Human-Readable</strong>: They are easier to understand and remember, especially for common colors.</li>
          </ul><br />

          <h2 style={{paddingBottom:"6px"}}>Limitations</h2>
          <ul>
            <li><strong>Limited Palette</strong>: The number of color keywords is limited compared to the vast range of colors you can define using other methods (like hex, RGB, or HSL).</li><br />
            <li><strong>Consistency</strong>: Different screens may render colors slightly differently, so relying on specific keywords may not always yield consistent results.</li>
          </ul><br />

          <h2>Conclusion</h2>
          <p>
            Color keywords provide an easy and readable way to specify colors in CSS. While they are great for common colors, for more specific or nuanced colors, it may be better to use hex codes or RGB values.
          </p>
      
      

              </div>
            )}


{selectedChapter === 'chapter38' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Gradient</h1>
           
              
            

          <p>
            In CSS, a gradient is a smooth transition between two or more colors. Gradients can be used as backgrounds for elements, creating visually appealing designs. There are several types of gradients you can use in CSS:
          </p><br />

          <h2>1. Linear Gradients</h2>
          <p>A linear gradient transitions colors along a straight line. You can control the direction of the gradient (e.g., top to bottom, left to right).</p>
          <p><strong>Syntax:</strong></p>
          <pre>
            {`background: linear-gradient(direction, color-stop1, color-stop2, ...);`}
          </pre>
          <p><strong>Example:</strong></p>
          <pre>
            {`background: linear-gradient(to right, red, yellow);`}
          </pre><br />

          <h2>2. Radial Gradients</h2>
          <p>A radial gradient transitions colors in a circular pattern, starting from a central point and expanding outward.</p>
          <p><strong>Syntax:</strong></p>
          <pre>
            {`background: radial-gradient(shape size at position, color-stop1, color-stop2, ...);`}
          </pre>
          <p><strong>Example:</strong></p>
          <pre>
            {`background: radial-gradient(circle, red, yellow);`}
          </pre><br />

          <h2>3. Conic Gradients</h2>
          <p>Conic gradients create a circular gradient that rotates around a center point. This type is often used for pie charts or similar designs.</p>
          <p><strong>Syntax:</strong></p>
          <pre>
            {`background: conic-gradient(from angle, color-stop1, color-stop2, ...);`}
          </pre>
          <p><strong>Example:</strong></p>
          <pre>
            {`background: conic-gradient(red, yellow, green, blue);`}
          </pre><br />

          <h2>4. Multi-Color Gradients</h2>
          <p>You can create gradients that transition through multiple colors by adding more color stops.</p>
          <p><strong>Example:</strong></p>
          <pre>
            {`background: linear-gradient(to right, red, orange, yellow, green, blue);`}
          </pre><br />

          <h2 style={{paddingBottom:"6px"}}>Usage Tips</h2>
          <ul>
            <li><strong>Browser Compatibility:</strong> Gradients are well-supported in modern browsers, but always check compatibility for specific properties.</li><br />
            <li><strong>Fallbacks:</strong> If you want to ensure older browsers display a solid color, provide a fallback before the gradient.</li>
          </ul>
          <p><strong>Example:</strong></p>
          <pre>
            {`background: red; /* Fallback */
background: linear-gradient(to right, red, yellow);`}
          </pre><br />

          <h2>Conclusion</h2>
          <p>
            CSS gradients are a powerful tool for creating visually appealing backgrounds and designs. They allow for a wide range of creative possibilities, enhancing the aesthetics of web pages.
          </p>
      


              </div>
            )}



{selectedChapter === 'chapter39' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Color Values</h1>

    <p>
      In CSS, <strong>Color Values</strong> are used to specify the color of text, backgrounds, borders, 
      and other elements. CSS offers various ways to define color, each suitable for different needs. 
      The main types of color values are:
    </p><br />

    <h3>1. Keyword Colors</h3>
    <p>
      CSS provides predefined color names, like <code>red</code>, <code>blue</code>, <code>green</code>, 
      etc. These are simple to use but offer limited flexibility.
    </p>
    <pre>
      <code>{`
color: red;
background-color: blue;
      `}</code>
    </pre><br />

    <h3>2. Hexadecimal Colors</h3>
    <p>
      Hex colors use a six-digit code representing the red, green, and blue (RGB) values. Each pair of 
      digits is a hexadecimal number ranging from <code>00</code> to <code>FF</code>.
    </p>
    <pre>
      <code>{`
color: #ff0000; /* Red */
background-color: #00ff00; /* Green */
      `}</code>
    </pre>
    <p>
      <strong>Shorthand Hex</strong>: If each pair of digits is the same, you can use the shorthand version with just three digits.
    </p>
    <pre>
      <code>{`
color: #f00; /* Red */
      `}</code>
    </pre><br />

    <h3>3. RGB Colors</h3>
    <p>
      The <code>rgb()</code> function defines colors using the Red, Green, and Blue components. 
      Each value is between 0 and 255.
    </p>
    <pre>
      <code>{`
color: rgb(255, 0, 0); /* Red */
background-color: rgb(0, 255, 0); /* Green */
      `}</code>
    </pre><br />

    <h3>4. RGBA Colors</h3>
    <p>
      The <code>rgba()</code> function adds an alpha (opacity) value to the RGB values. 
      The alpha ranges from 0 (fully transparent) to 1 (fully opaque).
    </p>
    <pre>
      <code>{`
color: rgba(255, 0, 0, 0.5); /* Semi-transparent red */
      `}</code>
    </pre><br />

    <h3>5. HSL Colors</h3>
    <p>
      The <code>hsl()</code> function defines colors based on <strong>Hue</strong>, <strong>Saturation</strong>, 
      and <strong>Lightness</strong>. Hue is an angle on the color wheel (0-360), saturation is a percentage 
      (0% means grayscale), and lightness is also a percentage (0% means black, 100% means white).
    </p>
    <pre>
      <code>{`
color: hsl(0, 100%, 50%); /* Red */
background-color: hsl(120, 100%, 50%); /* Green */
      `}</code>
    </pre><br />

    <h3>6. HSLA Colors</h3>
    <p>
      The <code>hsla()</code> function adds an alpha value for transparency, just like <code>rgba()</code>.
    </p>
    <pre>
      <code>{`
color: hsla(240, 100%, 50%, 0.5); /* Semi-transparent blue */
      `}</code>
    </pre><br />

    <h3>7. CSS Custom Properties (Variables)</h3>
    <p>
      You can also define colors using CSS variables for reusability across your styles.
    </p>
    <pre>
      <code>{`
:root {
  --primary-color: #ff6347;
}
color: var(--primary-color); /* Tomato color */
      `}</code>
    </pre><br />

    <h3>Example:</h3>
    <pre>
      <code>{`
.element {
  color: rgb(255, 255, 255); /* White text */
  background-color: #0000ff; /* Blue background */
  border-color: rgba(255, 0, 0, 0.8); /* Semi-transparent red border */
}
      `}</code>
    </pre><br />

    <p>
      In summary, CSS color values offer flexible ways to define colors, whether using keywords, 
      hex codes, or more precise control with RGB, HSL, and opacity options.
    </p>
  </div>
)}



{selectedChapter === 'chapter76' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Shadows</h1>
           
           

          <p>
            In CSS, shadows are visual effects that create a sense of depth by simulating the presence of light and shadow around elements. The primary properties used to create shadows are <code>box-shadow</code> for rectangular elements and <code>text-shadow</code> for text. Here’s a breakdown:
          </p><br />

          <h2>1. Box Shadow</h2>
          <p>The <code>box-shadow</code> property adds a shadow around an element’s box (e.g., a div).</p><br />

          <p><strong>Syntax:</strong></p>
          <pre>
            {`box-shadow: offset-x offset-y blur-radius spread-radius color;`}
          </pre><br />

          <ul>
            <li><strong>offset-x:</strong> Horizontal distance of the shadow (positive values move the shadow to the right, negative to the left).</li><br />
            <li><strong>offset-y:</strong> Vertical distance of the shadow (positive values move it down, negative up).</li><br />
            <li><strong>blur-radius:</strong> How blurred the shadow is. A higher value means more blur.</li><br />
            <li><strong>spread-radius:</strong> How much the shadow expands. Positive values make it larger, negative values make it smaller.</li><br />
            <li><strong>color:</strong> The color of the shadow (can use rgba for transparency).</li>
          </ul><br />

          <p><strong>Example:</strong></p>
          <pre>
            {`.box {\n  width: 200px;\n  height: 100px;\n  background-color: lightblue;\n  box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.5);\n}`}
          </pre><br />

          <h2>2. Text Shadow</h2>
          <p>The <code>text-shadow</code> property adds a shadow effect to text.</p>

          <p><strong>Syntax:</strong></p>
          <pre>
            {`text-shadow: offset-x offset-y blur-radius color;`}
          </pre><br />

          <p><strong>Example:</strong></p>
          <pre>
            {`h1 {\n  color: white;\n  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);\n}`}
          </pre><br />

          <h2 style={{paddingBottom:"6px"}}>Usage Tips</h2>
          <ul>
            <li><strong>Subtlety:</strong> Shadows are often used subtly to enhance depth without overwhelming the design.</li><br />
            <li><strong>Layering:</strong> You can create multiple shadows by separating them with commas.</li>
          </ul><br />

          <p><strong>Example:</strong></p>
          <pre>
            {`.box {\n  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5),\n              -5px -5px 10px rgba(255, 255, 255, 0.5);\n}`}
          </pre><br />

          <h2>Conclusion</h2>
          <p>
            Shadows are a powerful design tool in CSS, providing depth and dimension to elements. By adjusting the properties of <code>box-shadow</code> and <code>text-shadow</code>, you can create various visual effects to enhance your web design.
          </p>



              </div>
            )}


{selectedChapter === 'chapter56' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Text Effect </h1>


    <p>
      In CSS, text effects refer to various styles and transformations that can be applied to text to enhance its appearance and visual impact. These effects can include changes in color, size, spacing, shadows, and other properties. Here are some common text effects you can achieve with CSS:
    </p><br />

    <h2>1. Text Shadow</h2>
    <p>
      The <code>text-shadow</code> property adds a shadow effect to text, creating depth and making the text stand out.
    </p>
    <pre>
      <code>{`h1 {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}`}</code>
    </pre><br />

    <h2>2. Text Stroke</h2>
    <p>
      The <code>-webkit-text-stroke</code> property allows you to add an outline to the text, which can create a bold effect.
    </p>
    <pre>
      <code>{`h2 {
  color: white;
  -webkit-text-stroke: 1px black;
}`}</code>
    </pre><br />

    <h2>3. Text Transform</h2>
    <p>
      The <code>text-transform</code> property can change the casing of text (uppercase, lowercase, capitalize).
    </p>
    <pre>
      <code>{`p {
  text-transform: uppercase;
}`}</code>
    </pre><br />

    <h2>4. Letter Spacing</h2>
    <p>
      The <code>letter-spacing</code> property adjusts the space between characters in text.
    </p>
    <pre>
      <code>{`h3 {
  letter-spacing: 2px;
}`}</code>
    </pre><br />

    <h2>5. Word Spacing</h2>
    <p>
      The <code>word-spacing</code> property controls the space between words in a text.
    </p>
    <pre>
      <code>{`p {
  word-spacing: 5px;
}`}</code>
    </pre><br />

    <h2>6. Line Height</h2>
    <p>
      The <code>line-height</code> property adjusts the spacing between lines of text, enhancing readability.
    </p>
    <pre>
      <code>{`p {
  line-height: 1.5;
}`}</code>
    </pre><br />

    <h2>7. Gradient Text</h2>
    <p>
      Using a background gradient with <code>background-clip</code> and <code>text-fill-color</code> can create a gradient effect on text.
    </p>
    <pre>
      <code>{`h1 {
  background: linear-gradient(to right, red, yellow);
  -webkit-background-clip: text;
  color: transparent;
}`}</code>
    </pre><br />

    <h2>8. Animated Text</h2>
    <p>
      CSS animations can be applied to text to create dynamic effects, such as fading in or sliding.
    </p>
    <pre>
      <code>{`@keyframes slide {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

h1 {
  animation: slide 0.5s ease-in-out;
}`}</code>
    </pre><br />

    <h2>Conclusion</h2>
    <p>
      Text effects in CSS enhance the visual presentation of text on web pages, improving aesthetics and user engagement. By creatively using properties like <code>text-shadow</code>, <code>text-transform</code>, and animations, you can create unique and appealing typography that aligns with your design vision.
    </p>

              </div>
            )}




{selectedChapter === 'chapter136' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Web Fonts</h1>
           

    <p>
      Web fonts in CSS refer to fonts that are not installed on the user's device but can be loaded and used in a web page. They allow web designers and developers to use a wider variety of typography without relying solely on the fonts that are pre-installed on users' systems. This is essential for creating unique and visually appealing designs.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Key Concepts of Web Fonts</h2>

    <ol>
      <li>
        <strong>@font-face Rule</strong>
        <p>
          This is the CSS rule used to define a web font. It allows you to specify the font file to be used on the webpage.
        </p>
        <pre>
          <code>
{`@font-face {
  font-family: 'MyCustomFont';
  src: url('fonts/mycustomfont.woff2') format('woff2'),
       url('fonts/mycustomfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}`}
          </code>
        </pre>
      </li><br />

      <li>
        <strong>Using Web Fonts</strong>
        <p>
          Once a font is defined with <code>@font-face</code>, you can use it in your styles like any other font.
        </p>
        <pre>
          <code>
{`body {
  font-family: 'MyCustomFont', sans-serif;
}`}
          </code>
        </pre>
      </li><br />

      <li>
        <strong>Font Formats</strong>
        <p>
          It's important to provide different font formats for better browser compatibility. Common formats include:
        </p>
        <ul>
          <li>TrueType (.ttf)</li><br />
          <li>OpenType (.otf)</li><br />
          <li>Web Open Font Format (.woff and .woff2)</li><br />
          <li>Embedded OpenType (.eot)</li>
        </ul>
      </li><br />

      <li>
        <strong>Google Fonts</strong>
        <p>
          A popular resource for free web fonts. You can easily integrate Google Fonts into your project by linking to the font stylesheet in your HTML or using <code>@import</code> in your CSS.
        </p>
        <pre>
          <code>
{`<link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap" rel="stylesheet">`}
          </code>
        </pre>
        <pre>
          <code>
{`body {
  font-family: 'Roboto', sans-serif;
}`}
          </code>
        </pre>
      </li><br />

      <li>
        <strong>Performance Considerations</strong>
        <p>
          Using too many web fonts or large font files can impact page load times. It's a good practice to only load the font weights and styles you need.
        </p>
      </li>
    </ol><br />

    <h2>Conclusion</h2>
    <p>
      Web fonts enhance the typographic flexibility of websites, allowing designers to create more engaging and unique user experiences. By using <code>@font-face</code> or integrating web font services like Google Fonts, developers can ensure their designs are consistent across different devices and browsers.
    </p>
 

              </div>
            )}


{selectedChapter === 'chapter77'&& (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>2D Transforms </h1>
        

    <p>
      2D transforms in CSS allow you to manipulate elements in two-dimensional space by changing their position, size, rotation, and skew. This is done using the <code>transform</code> property, which can apply various transformation functions. Here's a breakdown of the main types of 2D transforms:
    </p><br />

    <h2>1. Translate</h2>
    <p>The <code>translate</code> function moves an element from its current position.</p>
    <p>
      <strong>Syntax:</strong> 
      <code>transform: translate(x, y);</code>
    </p>
    <p>
      <strong>Example:</strong> 
      <code>transform: translate(50px, 100px);</code> {/* Moves 50px to the right and 100px down */}
    </p><br />

    <h2>2. Scale</h2>
    <p>The <code>scale</code> function resizes an element. Values greater than 1 enlarge the element, while values less than 1 shrink it.</p>
    <p>
      <strong>Syntax:</strong> 
      <code>transform: scale(sx, sy);</code>
    </p>
    <p>
      <strong>Example:</strong> 
      <code>transform: scale(1.5, 2);</code> {/* 1.5 times wider and 2 times taller */}
    </p><br />

    <h2>3. Rotate</h2>
    <p>The <code>rotate</code> function rotates an element around a specified point (the origin) by a given angle.</p>
    <p>
      <strong>Syntax:</strong> 
      <code>transform: rotate(angle);</code>
    </p>
    <p>
      <strong>Example:</strong> 
      <code>transform: rotate(45deg);</code> {/* Rotates 45 degrees clockwise */}
    </p><br />

    <h2>4. Skew</h2>
    <p>The <code>skew</code> function skews an element along the X and Y axes by specified angles.</p>
    <p>
      <strong>Syntax:</strong> 
      <code>transform: skew(x-angle, y-angle);</code>
    </p>
    <p>
      <strong>Example:</strong> 
      <code>transform: skew(20deg, 10deg);</code> {/* Skews 20 degrees along the X-axis and 10 degrees along the Y-axis */}
    </p><br />

    <h2>5. Transform Origin</h2>
    <p>The <code>transform-origin</code> property allows you to change the point of origin for transformations. By default, this is set to the center of the element.</p>
    <p>
      <strong>Syntax:</strong> 
      <code>transform-origin: x y;</code> {/* Can use values in pixels, percentages, etc. */}
    </p>
    <p>
      <strong>Example:</strong> 
      <code>transform-origin: top left;</code> {/* Changes the origin to the top-left corner */}
    </p><br />

    <h2>Combining Transforms</h2>
    <p>You can combine multiple transformations in a single <code>transform</code> property by separating them with spaces.</p>
    <p>
      <strong>Example:</strong> 
      <code>transform: translate(30px, 50px) rotate(30deg) scale(1.2);</code>
    </p><br />

    <h2>Conclusion</h2>
    <p>
      2D transforms are powerful tools for creating dynamic and interactive web designs. By manipulating elements' positions, sizes, and orientations, you can achieve a variety of effects that enhance user experience.
    </p>


              </div>
            )}


{selectedChapter === 'chapter78' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>3D Transforms</h1>
              
    <p>
      3D transforms in CSS allow you to manipulate elements in a three-dimensional space, providing depth and perspective to your web designs. This is done using the <code>transform</code> property, which can apply various transformation functions, such as translating, rotating, and scaling in three dimensions.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Key Concepts of 3D Transforms</h2>

    <h4>1. Perspective</h4>
    <p>
      The <code>perspective</code> property defines how far the viewer is from the z=0 plane. A smaller value creates a more pronounced perspective effect, while a larger value makes it appear flatter.
    </p>
    <pre>
      <code>
{`.container {
  perspective: 1000px;
}`}
      </code>
    </pre><br />

    <h4>2. Translate3D</h4>
    <p>
      The <code>translate3d</code> function moves an element in 3D space along the x, y, and z axes.
    </p>
    <pre>
      <code>
{`transform: translate3d(50px, 100px, 200px);`}
      </code>
    </pre><br />

    <h4>3. Rotate3D</h4>
    <p>
      The <code>rotate3d</code> function rotates an element around a specified axis (x, y, z) by a given angle.
    </p>
    <pre>
      <code>
{`transform: rotate3d(1, 1, 0, 45deg); /* Rotate around an axis defined by (1,1,0) */`}
      </code>
    </pre><br />

    <h4>4. Scale3D</h4>
    <p>
      The <code>scale3d</code> function scales an element in 3D space. You can specify different scaling factors for the x, y, and z dimensions.
    </p>
    <pre>
      <code>
{`transform: scale3d(1.5, 1.5, 1.5); /* Scale uniformly */`}
      </code>
    </pre><br />

    <h4>5. Transform Origin</h4>
    <p>
      The <code>transform-origin</code> property can also be set in 3D space, allowing you to control the pivot point for transformations.
    </p>
    <pre>
      <code>
{`transform-origin: 50% 50% 50%; /* Center of the element */`}
      </code>
    </pre><br />

    <h4>Combining 3D Transforms</h4>
    <p>
      You can combine multiple 3D transformations in a single <code>transform</code> property.
    </p>
    <pre>
      <code>
{`transform: translate3d(30px, 40px, 50px) rotate3d(0, 1, 0, 60deg) scale3d(1.2, 1.2, 1.2);`}
      </code>
    </pre><br />

    <h2>Conclusion</h2>
    <p>
      3D transforms are a powerful feature in CSS that enables designers to create dynamic and engaging visual effects by manipulating elements in three-dimensional space. By using perspective and combining different transform functions, you can achieve stunning visual depth and interactivity on web pages.
    </p>

              </div>
            )}


{selectedChapter === 'chapter79' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Transitions </h1>
           

    <p>
      In CSS, transitions allow you to change property values smoothly (over a specified duration) when an element's state changes, such as when hovering or focusing. This feature enhances user experience by providing a visually appealing way to animate changes in style.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Key Concepts of CSS Transitions</h2>

    <h4 style={{paddingBottom:"6px"}}>1. Transition Properties</h4>
    <ul>
      <li><strong>`transition-property`:</strong> Specifies the CSS property to which the transition is applied (e.g., `width`, `opacity`).</li><br />
      <li><strong>`transition-duration`:</strong> Defines how long the transition will take (e.g., `0.5s`, `200ms`).</li><br />
      <li><strong>`transition-timing-function`:</strong> Describes how the intermediate property values are calculated over the duration. Common values include:
        <ul><br />
          <li>`linear`: Constant speed.</li><br />
          <li>`ease`: Starts slow, speeds up, and then slows down.</li><br />
          <li>`ease-in`: Starts slow and speeds up.</li><br />
          <li>`ease-out`: Starts fast and slows down.</li><br />
          <li>`ease-in-out`: Starts and ends slow, with a faster middle.</li>
        </ul>
      </li><br />
      <li><strong>`transition-delay`:</strong> Specifies a delay before the transition starts.</li>
    </ul><br />

    <h4>2. Basic Syntax</h4>
    <p>You can set transitions in a CSS rule like this:</p>
    <pre>
      <code>
        {`.element {\n  transition: property duration timing-function delay;\n}`}
      </code>
    </pre><br />

    <h4>3. Example</h4>
    <p>Here’s a simple example that changes the background color of a button when hovered:</p>
    <pre>
      <code>
        {`.button {\n  background-color: blue;\n  transition: background-color 0.3s ease;\n}\n\n.button:hover {\n  background-color: green;\n}`}
      </code>
    </pre><br />

    <h4>4. Shorthand Property</h4>
    <p>You can also use the shorthand `transition` property to define multiple aspects in one line:</p>
    <pre>
      <code>
        {`.element {\n  transition: all 0.5s ease-in-out; /* Applies to all properties */\n}`}
      </code>
    </pre><br />


    <h4>5. Combining Transitions</h4>
    <p>You can combine transitions for multiple properties:</p>
    <pre>
      <code>
        {`.box {\n  width: 100px;\n  height: 100px;\n  transition: width 0.5s, height 0.5s;\n}\n\n.box:hover {\n  width: 200px;\n  height: 200px;\n}`}
      </code>
    </pre><br />

    <h2>Conclusion</h2>
    <p>
      CSS transitions are a powerful tool for enhancing the visual dynamics of a web page. By smoothly animating property changes, you can create engaging interactions that improve user experience.
    </p>
 

              </div>
            )}


{selectedChapter === 'chapter80' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Animation </h1>
           

    <p>
      CSS animations allow you to create smooth transitions between different states of an element. They enable you to animate various CSS properties over a specified duration, adding visual flair and enhancing user interactions on a webpage.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Key Concepts of CSS Animations</h2>

    <h4>1. @keyframes Rule</h4>
    <p>
      This rule defines the animation by specifying the changes to be made at various stages of the animation.
    </p>
    <pre>
      <code>
        {`@keyframes myAnimation {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(100px);
    opacity: 0;
  }
}`}
      </code>
    </pre><br />

    <h4>2. Animation Properties</h4>
    <ul>
      <li><strong>animation-name</strong>: Specifies the name of the <code>@keyframes</code> animation.</li><br />
      <li><strong>animation-duration</strong>: Defines how long the animation takes to complete (e.g., <code>2s</code>, <code>500ms</code>).</li><br />
      <li><strong>animation-timing-function</strong>: Describes the speed curve of the animation. Common values include:
        <ul><br />
          <li><code>linear</code>: Constant speed.</li><br />
          <li><code>ease</code>: Starts slow, speeds up, and then slows down.</li><br />
          <li><code>ease-in</code>: Starts slow and speeds up.</li><br />
          <li><code>ease-out</code>: Starts fast and slows down.</li><br />
          <li><code>ease-in-out</code>: Starts and ends slow, with a faster middle.</li>
        </ul>
      </li><br />
      <li><strong>animation-delay</strong>: Specifies a delay before the animation starts.</li><br />
      <li><strong>animation-iteration-count</strong>: Defines how many times the animation should repeat (e.g., <code>infinite</code>).</li><br />
      <li><strong>animation-direction</strong>: Specifies whether the animation should alternate direction on each iteration.</li>
    </ul><br />

    <h4>3. Basic Syntax</h4>
    <p>
      You can apply an animation to an element like this:
    </p>
    <pre>
      <code>
        {`.element {
  animation: myAnimation 2s ease-in-out infinite;
}`}
      </code>
    </pre><br />

    <h3>4. Example</h3>
    <p>
      Here's an example of a simple animation that moves an element from left to right:
    </p>
    <pre>
      <code>
        {`@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100px);
  }
}

.box {
  width: 100px;
  height: 100px;
  background-color: red;
  animation: slide 2s ease-in-out infinite;
}`}
      </code>
    </pre><br />

    <h2>Conclusion</h2>
    <p>
      CSS animations enhance web design by allowing you to create engaging and interactive experiences. By leveraging keyframes and animation properties, you can animate elements smoothly and effectively, improving the overall aesthetic and functionality of your web pages.
    </p>



              </div>
            )}


{selectedChapter === 'chapter63' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Tooltips </h1>
           


    <p>
      Tooltips in CSS are small informational boxes that appear when a user hovers over an element, providing additional context or details about that element. They are commonly used to enhance user experience by offering hints, explanations, or extra information without cluttering the interface.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Key Features of Tooltips</h2>
    <ol>
      <li><strong>Trigger Events:</strong> Tooltips are typically activated by hover events but can also be triggered by focus or click events.</li><br />
      <li><strong>Positioning:</strong> Tooltips are usually positioned relative to the element they describe. They can appear above, below, to the left, or to the right of the element.</li><br />
      <li><strong>Visibility:</strong> Tooltips should be easy to read and only visible when needed. They typically fade in and out for a smooth user experience.</li><br />
    </ol><br />

    <h2>Basic Implementation</h2>
    <h3>HTML</h3>
    <pre>
      <code>
{`<div className="tooltip">
  Hover over me
  <span className="tooltiptext">Tooltip text</span>
</div>`}
      </code>
    </pre><br />

    <h3>CSS</h3>
    <pre>
      <code>
{`.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position above the text */
  left: 50%;
  margin-left: -60px; /* Center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}`} 
      </code>
    </pre><br />

    <h2>Explanation</h2>
    <ul>
      <li><strong>Structure:</strong> The tooltip consists of a parent element (<code>.tooltip</code>) and a child element (<code>.tooltiptext</code>) that contains the tooltip text.</li><br />
      <li><strong>Positioning:</strong> The tooltip text is absolutely positioned relative to the parent element. The <code>bottom</code> and <code>left</code> properties control where it appears.</li><br />
      <li><strong>Visibility:</strong> The tooltip text is initially hidden. It becomes visible on hover with a transition effect for a smoother appearance.</li><br />
    </ul><br />

    <h2>Conclusion</h2>
    <p>
      Tooltips are an effective way to provide supplementary information to users without taking up additional space in the UI. By using simple HTML and CSS, you can create custom tooltips that enhance the usability of your web applications.
    </p>



              </div>
            )}


{selectedChapter === 'chapter81' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Style Images</h1>
      

    <p>
      In CSS, styling images involves applying various properties to control their appearance, position, and behavior within a webpage. Here are some key aspects of styling images in CSS:
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Key Concepts of Styling Images</h2>

    <ol>
      <li>
        <strong>Width and Height</strong>: 
        You can set the dimensions of an image using the <code>width</code> and <code>height</code> properties to ensure they fit well within the layout.
        <pre>
          <code>
{`img {
  width: 100%;  /* Makes the image responsive */
  height: auto; /* Maintains aspect ratio */
}`}
          </code>
        </pre>
      </li><br />

      <li>
        <strong>Borders</strong>: 
        Adding borders to images can enhance their appearance.
        <pre>
          <code>
{`img {
  border: 2px solid #000; /* Black border */
  border-radius: 10px;    /* Rounded corners */
}`}
          </code>
        </pre>
      </li><br />

      <li>
        <strong>Box Shadow</strong>: 
        Applying shadows can give images a sense of depth.
        <pre>
          <code>
{`img {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5); /* Subtle shadow */
}`}
          </code>
        </pre>
      </li><br />

      <li>
        <strong>Opacity</strong>: 
        You can adjust the transparency of an image using the <code>opacity</code> property.
        <pre>
          <code>
{`img {
  opacity: 0.8; /* Slightly transparent */
}`}
          </code>
        </pre>
      </li><br />

      <li>
        <strong>Filter Effects</strong>: 
        CSS filters allow for effects like blur, grayscale, and brightness adjustments.
        <pre>
          <code>
{`img {
  filter: grayscale(100%); /* Converts image to grayscale */
}`}
          </code>
        </pre>
      </li><br />

      <li>
        <strong>Object Fit</strong>: 
        This property defines how an image should be resized to fit its container.
        <pre>
          <code>
{`img {
  object-fit: cover; /* Ensures the image covers the container */
}`}
          </code>
        </pre>
      </li><br />

      <li>
        <strong>Responsive Images</strong>: 
        Use media queries to adjust image styles for different screen sizes.
        <pre>
          <code>
{`@media (max-width: 600px) {
  img {
    width: 100%; /* Full width on small screens */
  }
}`}
          </code>
        </pre>
      </li><br />

      <li>
        <strong>Image Alignment</strong>: 
        Use margin and text alignment to control image placement.
        <pre>
          <code>
{`img {
  display: block; /* Ensures margins work as expected */
  margin: 0 auto; /* Centers the image */
}`}
          </code>
        </pre>
      </li>
    </ol><br />

    <h2>Example</h2>

    <p>Here’s an example of a simple CSS style for an image:</p>

    <pre>
      <code>
{`.image-container {
  text-align: center; /* Centers the image */
}

.image-container img {
  width: 80%; /* Responsive */
  height: auto;
  border: 2px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s; /* Smooth scaling effect */
}

.image-container img:hover {
  transform: scale(1.05); /* Slightly enlarges on hover */
}`}
      </code>
    </pre><br />

    <h2>Conclusion</h2>
    <p>
      Styling images in CSS enhances their visual impact and allows for better integration into the overall design of a webpage. By leveraging various CSS properties, you can control their appearance, responsiveness, and interactivity, improving user experience.
    </p>

              </div>
            )}


{selectedChapter === 'chapter82' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Image Reflection</h1>
           
    
    <p>
      Reflection in CSS refers to creating a mirrored effect of an element, similar to how a surface might reflect an image. Although CSS doesn’t have a built-in property for reflections, you can achieve this effect using techniques like gradients and transforms.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Example of Creating a Reflection Effect</h2>
    
    <h3>HTML</h3>
    <pre>
      <code>
        {`<div class="reflection-container">
  <img src="image.jpg" alt="Sample" class="reflected-image" />
</div>`}
      </code>
    </pre><br />

    <h3>CSS</h3>
    <pre>
      <code>
        {`.reflection-container {
  position: relative;
  overflow: hidden; /* Hide the overflow of the reflection */
}

.reflected-image {
  display: block; /* Remove the bottom space */
  width: 100%; /* Make it responsive */
}

.reflected-image::after {
  content: '';
  display: block;
  background: inherit; /* Inherit the image background */
  transform: scaleY(-1); /* Flip the image vertically */
  opacity: 0.5; /* Adjust opacity for a reflection effect */
  height: 100%; /* Match the height */
  position: absolute;
  top: 100%; /* Position below the original image */
  left: 0;
  width: 100%; /* Match the width */
  pointer-events: none; /* Ensure it's not clickable */
}`}
      </code>
    </pre><br />

    <h2 style={{paddingBottom:"6px"}}>Explanation</h2>
    <ul>
      <li>
        <strong>Container:</strong> The <code>.reflection-container</code> uses <code>overflow: hidden</code> to ensure that the reflection is contained within its boundaries.
      </li><br />
      <li>
        <strong>Image:</strong> The original image is displayed normally.
      </li><br />
      <li>
        <strong>Reflection:</strong> The <code>::after</code> pseudo-element creates a mirrored version of the image, flipped vertically and placed below the original image.
      </li>
    </ul><br />

    <h2>Conclusion</h2>
    <p>
      While CSS doesn't have a specific reflection property, you can create visually appealing effects using pseudo-elements and transformations.
    </p>
  

              </div>
            )}


{selectedChapter === 'chapter83' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Object-fit </h1>
           
          

    <p>
      The <code>object-fit</code> property in CSS is used to control how an image or video is resized to fit its container. It is particularly useful for responsive designs where maintaining the aspect ratio of media elements is important. This property helps manage how the content is displayed within its box, especially when the aspect ratios of the box and the content do not match.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Key Values of <code>object-fit</code></h2>

    <ol>
      <li>
        <strong><code>fill</code>:</strong> 
        The default value. The content is resized to fill the container, potentially distorting the aspect ratio.
        <pre>
          <code>{`img {
  object-fit: fill;
}`}</code>
        </pre>
      </li><br />

      <li>
        <strong><code>contain</code>:</strong> 
        The content is scaled to maintain its aspect ratio while fitting within the container. This may leave empty space (letterboxing) on one side.
        <pre>
          <code>{`img {
  object-fit: contain;
}`}</code>
        </pre>
      </li><br />

      <li>
        <strong><code>cover</code>:</strong> 
        The content is scaled to maintain its aspect ratio while filling the container. This may cause the content to be clipped if its aspect ratio does not match the container.
        <pre>
          <code>{`img {
  object-fit: cover;
}`}</code>
        </pre>
      </li><br />

      <li>
        <strong><code>none</code>:</strong> 
        The content retains its original size. If the original size is larger than the container, it will overflow.
        <pre>
          <code>{`img {
  object-fit: none;
}`}</code>
        </pre>
      </li><br />

      <li>
        <strong><code>scale-down</code>:</strong> 
        The content is scaled down to the smallest size that fits within the container while maintaining its aspect ratio. It behaves like <code>none</code> if the content is already smaller than the container.
        <pre>
          <code>{`img {
  object-fit: scale-down;
}`}</code>
        </pre>
      </li>
    </ol><br />

    <h2>Example Usage</h2>

    <p>Here’s an example demonstrating the use of <code>object-fit</code>:</p>

    <pre>
      <code>{`<div class="image-container">
  <img src="example.jpg" alt="Example" class="image" />
</div>`}</code>
    </pre>

    <pre>
      <code>{`.image-container {
  width: 300px;
  height: 200px;
  overflow: hidden; /* To hide overflow */
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Use cover for a stylish effect */
}`}</code>
    </pre><br />

    <h2>Conclusion</h2>

    <p>
      The <code>object-fit</code> property provides flexibility in handling images and videos within their containers, making it easier to create visually appealing layouts without distortion. It is especially valuable in responsive design, ensuring that media elements adapt well to varying screen sizes and resolutions.
    </p>

              </div>
            )}


{selectedChapter === 'chapter84' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Object-position </h1>
           
      

    <p>
      The <code>object-position</code> property in CSS is used to specify the alignment of a replaced element (like an image or video) within its box when the <code>object-fit</code> property is applied. This is particularly useful when the aspect ratio of the content does not match the aspect ratio of its container.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Key Concepts</h2>
    <p>Usage: The <code>object-position</code> property determines how the content is positioned within the bounds set by <code>object-fit</code>.</p><br />

    <h2>Syntax</h2>
    <pre>
      <code>
        {`object-position: x-position y-position;`}
      </code>
    </pre><br />
    <ul>
      <li><strong>x-position</strong>: Can be a percentage or a length value (like pixels) indicating the horizontal alignment.</li><br />
      <li><strong>y-position</strong>: Can be a percentage or a length value indicating the vertical alignment.</li>
    </ul><br />

    <h2>Example</h2>
    <p>Here’s how you might use <code>object-position</code> with <code>object-fit</code>:</p>
    <pre>
      <code>
        {`.image {
  width: 300px;
  height: 200px;
  object-fit: cover; /* Ensures the image covers the entire container */
  object-position: top left; /* Positions the image at the top-left corner */
}`}
      </code>
    </pre><br />

    <h2 style={{paddingBottom:"6px"}}>Key Points</h2>
    <ul>
      <li><strong>Default Position</strong>: If not specified, the default value for <code>object-position</code> is <code>50% 50%</code>, which centers the content within its box.</li><br />
      <li><strong>Combining with <code>object-fit</code></strong>: The <code>object-position</code> property works in conjunction with <code>object-fit</code>. For example, if you set <code>object-fit: cover</code>, you can use <code>object-position</code> to control which part of the image is visible.</li><br />
      <li><strong>Responsive Design</strong>: This property is useful in responsive design where media elements need to adapt and be positioned correctly within their containers.</li>
    </ul><br />

    <h2>Conclusion</h2>
    <p>
      The <code>object-position</code> property provides a way to fine-tune how images and videos are displayed within their containers, allowing for better control over their alignment and presentation.
    </p>
 
              </div>
            )}


{selectedChapter === 'chapter85' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Masking</h1>
               
    <p>
      Masking in CSS refers to the technique of controlling the visibility of an element by using a mask. A mask can define which parts of an element should be visible and which parts should be hidden. This is useful for creating complex visual effects, such as revealing images, creating cut-out shapes, or applying gradients.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Key Concepts of Masking in CSS</h2>

    <ol>
      <li>
        <strong>Masking Properties:</strong>
        <ul>
          <li><code>mask</code>: This property defines the mask for an element, accepting an image, a gradient, or a CSS shape.</li><br />
          <li><code>mask-image</code>: Specifies the image or gradient to be used as a mask.</li><br />
          <li><code>mask-mode</code>: Defines how the mask image is applied (e.g., <code>alpha</code> for transparency).</li><br />
          <li><code>mask-position</code>: Controls the position of the mask image.</li><br />
          <li><code>mask-size</code>: Specifies the size of the mask image.</li><br />
          <li><code>mask-repeat</code>: Defines how the mask image is repeated.</li>
        </ul>
      </li><br />
      <li>
        <strong>Types of Masks:</strong>
        <ul>
          <li><strong>Alpha Masks</strong>: Uses the alpha channel of an image to determine visibility.</li><br />
          <li><strong>Luminance Masks</strong>: Uses the brightness of a grayscale image to define the visible areas.</li>
        </ul>
      </li>
    </ol><br />

    <h2>Basic Syntax</h2>
    <pre>
      <code>
{`.masked-element {
  mask-image: url('mask.png');
  mask-size: cover;
  mask-repeat: no-repeat;
}`}
      </code>
    </pre><br /><br/>

    <h2>Example of Masking</h2><br />
    <h3>HTML</h3>
    <pre>
      <code>
{`<div class="masked-container">
  <img src="image.jpg" alt="Masked Image" class="masked-image" />
</div>`}
      </code>
    </pre>

    <h3>CSS</h3>
    <pre>
      <code>
{`.masked-container {
  position: relative;
  width: 300px;
  height: 200px;
  overflow: hidden; /* Hide overflow */
}

.masked-image {
  width: 100%;
  height: 100%;
  mask-image: url('mask.svg'); /* Mask applied here */
  mask-size: cover;
  mask-repeat: no-repeat;
}`}
      </code>
    </pre><br />

    <h2>Conclusion</h2>
    <p>
      Masking in CSS is a powerful tool for creating visually appealing designs by controlling how elements are displayed. By using various masking techniques, you can achieve intricate visual effects that enhance user experience and engagement on web pages.
    </p>

                   

              </div>
            )}


{selectedChapter === 'chapter64' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Button</h1>
           
            
    
    <p>
      In CSS, a button is a user interface element that allows users to trigger an action when clicked. 
      Buttons can be styled using CSS to enhance their appearance, improve usability, and provide visual 
      feedback on interaction.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Key Concepts of Buttons in CSS</h2>

    <ol>
      <li>
        <strong>Button Types:</strong>
        <ul>
          <li><strong>Default Button:</strong> A standard button created using HTML <code>&lt;button&gt;</code> or <code>&lt;input type="button"&gt;</code>.</li><br />
          <li><strong>Anchor Button:</strong> A link styled to look like a button using <code>&lt;a&gt;</code>.</li>
        </ul>
      </li><br />

      <li>
        <strong>Styling Buttons:</strong>
        <p>You can style buttons using CSS properties such as:</p>
        <ul>
          <li><strong>Background Color:</strong> <code>background-color</code></li><br />
          <li><strong>Text Color:</strong> <code>color</code></li><br />
          <li><strong>Borders:</strong> <code>border</code></li><br />
          <li><strong>Padding and Margin:</strong> <code>padding</code>, <code>margin</code></li><br />
          <li><strong>Font Size and Weight:</strong> <code>font-size</code>, <code>font-weight</code></li><br />
          <li><strong>Border Radius:</strong> <code>border-radius</code> (for rounded corners)</li><br />
          <li><strong>Box Shadow:</strong> <code>box-shadow</code> (for depth)</li><br />
          <li><strong>Hover Effects:</strong> Using <code>:hover</code> pseudo-class to change styles on hover.</li>
        </ul>
      </li><br />

      <li>
        <strong>Example of a Button Style:</strong>
        <pre>
          <code>
{`
.button {
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 8px; /* Rounded corners */
  transition: background-color 0.3s; /* Smooth transition */
}

.button:hover {
  background-color: #45a049; /* Darker green on hover */
}`}
          </code>
        </pre>
      </li><br />

      <li>
        <strong>Accessibility:</strong>
        <p>Ensure buttons are accessible by:</p>
        <ul>
          <li>Providing clear labels.</li>
          <li>Using semantic HTML (<code>&lt;button&gt;</code> or <code>&lt;a&gt;</code> with role attributes).</li>
          <li>Ensuring they are keyboard navigable.</li>
        </ul>
      </li>
    </ol><br />

    <h2>Conclusion</h2>
    <p>
      Buttons are crucial interactive elements in web design. By applying CSS styles, you can create visually 
      appealing and functional buttons that enhance user experience on your website.
    </p>


              </div>
            )}


                {selectedChapter === 'chapter65' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Pagination</h1>
           

    <p>
      Pagination in CSS refers to the design and layout of pagination controls, which allow users to navigate through multiple pages of content. This is particularly useful in applications that display large amounts of data, such as blogs, product listings, or search results. Pagination helps improve user experience by providing a way to access content in a structured manner.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Key Concepts of Pagination in CSS</h2>

    <ol>
      <li>
        <strong>Structure:</strong>
        <ul>
          <li>Pagination typically consists of a series of links or buttons that represent different pages (e.g., "1", "2", "3", "Next", "Previous").</li><br />
          <li>It can also include indicators for the current page.</li>
        </ul>
      </li><br />
      <li>
        <strong>Styling Pagination:</strong>
        <p>
          Use CSS to style pagination controls for better visibility and usability. Common properties include:
        </p>
        <ul>
          <li><strong>Background Color:</strong> For button backgrounds.</li><br />
          <li><strong>Borders:</strong> To define button shapes.</li><br />
          <li><strong>Padding and Margin:</strong> To space out elements.</li><br />
          <li><strong>Hover Effects:</strong> To provide visual feedback when users interact with pagination items.</li>
        </ul>
      </li><br />
      <li>
        <strong>Responsive Design:</strong>
        <p>Ensure pagination controls adapt to different screen sizes. Use media queries to adjust layout and size.</p>
      </li><br />
      <li>
        <strong>Accessibility:</strong>
        <p>Make pagination accessible by using semantic HTML elements and ensuring keyboard navigability.</p>
      </li>
    </ol><br />

    <h2>Example of CSS Pagination</h2><br />

    <div className={style.pagination}>
      <a href="#">&laquo; Previous</a>
      <a href="#">1</a>
      <a href="#" className={style.active}>2</a>
      <a href="#">3</a>
      <a href="#">Next &raquo;</a>
    </div>

    <style jsx>{`
      .${style.pagination} {
        display: flex;
        justify-content: center;
        padding: 10px;
      }

      .${style.pagination} a {
        color: #007bff; /* Link color */
        padding: 8px 16px;
        text-decoration: none;
        border: 1px solid #007bff; /* Border color */
        margin: 0 4px;
        border-radius: 4px; /* Rounded corners */
        transition: background-color 0.3s;
      }

      .${style.pagination} a:hover {
        background-color: #007bff; /* Background color on hover */
        color: white; /* Text color on hover */
      }

      .${style.pagination} .${style.active} {
        background-color: #007bff; /* Active page background */
        color: white; /* Active page text color */
        border: none; /* No border for active page */
      }
    `}</style><br />

    <h2>Conclusion</h2>
    <p>
      Pagination is essential for managing content across multiple pages in a user-friendly way. By applying CSS styles effectively, you can create visually appealing pagination controls that enhance navigation and improve the overall user experience on your website.
    </p>


              </div>
            )}


{selectedChapter === 'chapter86' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Multiple Colums</h1>
  

    <p>
      Multiple columns in CSS refers to the ability to arrange content into multiple vertical columns, allowing for more efficient use of space and a layout that resembles newspaper or magazine styles. This feature is particularly useful for long text blocks, enhancing readability and aesthetics.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Key Properties for Multiple Columns</h2>

    <ol>
      <li>
        <strong>column-count</strong>: Specifies the number of columns an element should be divided into.
        <br />
        <code>{`column-count: 3;`}</code>
      </li><br />
      <li>
        <strong>column-width</strong>: Defines the ideal width of the columns.
        <br />
        <code>{`column-width: 200px;`}</code>
      </li><br />
      <li>
        <strong>column-gap</strong>: Sets the space between the columns.
        <br />
        <code>{`column-gap: 20px;`}</code>
      </li><br />
      <li>
        <strong>column-rule</strong>: Adds a rule (line) between columns.
        <br /><br />
        <code>{`column-rule: 1px solid #ccc;`}</code>
      </li>
    </ol><br />

    <h2>Example Usage</h2><br />

    <h3>HTML</h3>
    <pre>
      {`<div class="multi-column">
  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum.</p>
  <p>Curabitur tristique, nisi vitae facilisis feugiat, ipsum lacus interdum nisi, non posuere sapien quam in eros.</p>
  <p>Phasellus scelerisque eros at magna lacinia, nec suscipit elit scelerisque. Proin at enim ac nisl malesuada dignissim.</p>
  <p>Donec at ligula euismod, gravida libero vitae, viverra elit. Sed eget vehicula lorem.</p>
</div>`}
    </pre><br />

    <h3>CSS</h3>
    <pre>
      {`.multi-column {
  column-count: 3;
  column-gap: 20px;
  column-rule: 1px solid #ccc;
}`}
    </pre><br />

    <h2>Conclusion</h2>
    <p>
      Using multiple columns in CSS allows for dynamic, responsive layouts that enhance the presentation of text content. By applying the relevant properties, you can create visually appealing designs that improve readability and user experience.
    </p>
 

              </div>
            )}


{selectedChapter === 'chapter33' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>User Interface</h1>
          

    <h2>User Interface in CSS</h2>
    <p>
      User Interface (UI) in CSS refers to the design and styling of elements that users interact with on a webpage or application. CSS plays a crucial role in enhancing the visual presentation, usability, and overall experience of the user interface. It allows developers to create aesthetically pleasing layouts, control the appearance of buttons, forms, menus, and other interactive elements.
    </p><br />

    <h3 style={{paddingBottom:"6px"}}>Key Concepts of UI in CSS</h3>

    <ol>
      <li>
        <strong>Layout and Structure</strong>: CSS helps define the layout of UI components using properties like <code>flexbox</code> and <code>grid</code>. These techniques allow for responsive designs that adapt to various screen sizes.
      </li><br />
      <li>
        <strong>Styling Elements</strong>: CSS can be used to style buttons, input fields, navigation menus, and more. Common properties include:
        <ul>
          <li><strong>Colors</strong>: <code>color</code>, <code>background-color</code></li><br />
          <li><strong>Borders</strong>: <code>border</code>, <code>border-radius</code></li><br />
          <li><strong>Fonts</strong>: <code>font-size</code>, <code>font-family</code>, <code>font-weight</code></li><br />
          <li><strong>Spacing</strong>: <code>margin</code>, <code>padding</code></li>
        </ul>
      </li><br />
      <li>
        <strong>Hover and Focus Effects</strong>: CSS enables visual feedback through pseudo-classes like <code>:hover</code> and <code>:focus</code>, enhancing the user experience by indicating which elements are interactive.
      </li><br />
      <li>
        <strong>Transitions and Animations</strong>: CSS transitions and animations can create smooth effects when UI elements change state (e.g., button clicks, menu reveals), making the interface more engaging.
      </li><br />
      <li>
        <strong>Accessibility</strong>: CSS also supports accessibility practices, such as ensuring sufficient color contrast, clear focus states, and responsive designs that accommodate various devices and user needs.
      </li><br />
      <li>
        <strong>Responsive Design</strong>: Media queries in CSS allow for adjustments in styles based on the viewport size, ensuring that the UI remains functional and attractive across devices.
      </li>
    </ol><br />

    <h3>Example of UI Elements Styled with CSS</h3>

    <div className={style.example}>
      <div className={style.buttonContainer}>
        <button className={style.btn}>Click Me</button>
        <input type="text" className={style.input} placeholder="Enter text" />
      </div>
    </div><br />

    <h3>Conclusion</h3>
    <p>
      CSS is fundamental in shaping the user interface of web applications. It not only enhances visual appeal but also plays a vital role in user experience, ensuring that the interface is intuitive, accessible, and responsive. By leveraging CSS effectively, developers can create engaging and functional UIs that meet user needs.
    </p>

              </div>
            )}


{selectedChapter === 'chapter137' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Variables </h1>
      

    <p>
      CSS variables, also known as custom properties, allow you to store values that can be reused throughout your stylesheet. They make it easier to manage and maintain styles, as you can define a variable once and use it in multiple places. This is especially useful for themes, color schemes, and any values that might need to change frequently.
    </p><br />

    <h2 style={{paddingBottom:"6px"}} >Key Concepts of CSS Variables</h2>

    <ol>
      <li>
        <strong>Declaration:</strong>
        <p>
          CSS variables are declared using the <code>--</code> prefix within a selector.
        </p>
        <pre>
          <code>
            {`:root {
  --main-color: #3498db;  /* A blue color */
  --font-size: 16px;      /* Default font size */
}`}
          </code>
        </pre>
      </li><br />

      <li>
        <strong>Usage:</strong>
        <p>
          To use a CSS variable, you use the <code>var()</code> function.
        </p>
        <pre>
          <code>
            {`body {
  background-color: var(--main-color);
  font-size: var(--font-size);
}`}
          </code>
        </pre>
      </li><br />

      <li>
        <strong>Scope:</strong>
        <p>
          CSS variables are scoped to the element where they are defined. If you define a variable inside a specific class, it can only be used within that class and its children.
        </p>
        <pre>
          <code>
            {`.container {
  --padding: 20px;
}

.container .child {
  padding: var(--padding);  /* This will work */
}

.child-outside {
  padding: var(--padding);   /* This will NOT work */
}`}
          </code>
        </pre>
      </li><br />

      <li>
        <strong>Dynamic Changes:</strong>
        <p>
          CSS variables can be changed dynamically with JavaScript, allowing for real-time style updates.
        </p>
        <pre>
          <code>
            {`document.documentElement.style.setProperty('--main-color', '#e74c3c');  // Change the color`}
          </code>
        </pre>
      </li><br />

      <li>
        <strong>Fallback Values:</strong>
        <p>
          You can provide a fallback value if the variable is not defined.
        </p>
        <pre>
          <code>
            {`color: var(--text-color, black);  /* Uses black if --text-color is not defined */}
`}
          </code>
        </pre>
      </li>
    </ol><br />

    <h2>Example</h2>

    <p>Here’s a simple example demonstrating the use of CSS variables:</p>
    <pre>
      <code>
        {`:root {
  --primary-color: #4CAF50; /* Green */
  --text-color: white;
  --padding: 10px;
}

.button {
  background-color: var(--primary-color);
  color: var(--text-color);
  padding: var(--padding);
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.button:hover {
  background-color: darkgreen; /* Direct color for hover effect */
}`}
      </code>
    </pre><br />

    <h2>Conclusion</h2>
    <p>
      CSS variables provide a powerful way to manage styles consistently and efficiently. They improve maintainability, make it easier to implement themes, and allow for more dynamic styling. With their flexible scope and ability to be modified at runtime, CSS variables are a valuable tool in modern web development.
    </p>
 

              </div>
            )}


{selectedChapter === 'chapter34' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Box Sizing </h1>
           

    <p>
      In CSS, the <code>box-sizing</code> property defines how the total width and height of an element are calculated. It helps control the box model used for elements, allowing you to manage layout more easily.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Key Values of <code>box-sizing</code></h2>

    <ol>
      <li>
        <strong><code>content-box</code> (default):</strong>
        <p>
          The width and height properties only include the content. Padding and borders are added outside of the specified width and height.
        </p>
        <p><strong>Calculation:</strong> <code>width = content width + padding + border</code></p>
      </li><br />

      <li>
        <strong><code>border-box</code>:</strong>
        <p>
          The width and height properties include content, padding, and border. This makes it easier to size elements because you don't need to account for padding and borders separately.
        </p>
        <p><strong>Calculation:</strong> <code>width = content width (including padding and border)</code></p>
      </li>
    </ol><br />

    <h2>Example Usage</h2>
    <pre>
      <code>
        {`/* Default behavior (content-box) */\n.box1 {\n  width: 200px;\n  padding: 20px;\n  border: 5px solid black;\n}\n\n/* Border-box behavior */\n.box2 {\n  box-sizing: border-box;\n  width: 200px;\n  padding: 20px;\n  border: 5px solid black;\n}`}
      </code>
    </pre><br />

    <h2>Example Visual Difference</h2>
    <ul>
      <li>
        <strong>With <code>content-box</code>:</strong> The total width of <code>.box1</code> will be 200px (width) + 20px (padding left) + 20px (padding right) + 5px (border left) + 5px (border right) = 250px.
      </li>
      <li>
        <strong>With <code>border-box</code>:</strong> The total width of <code>.box2</code> will remain 200px, including padding and border.
      </li>
    </ul><br />

    <h2>Global Application</h2>
    <p>
      To apply <code>border-box</code> globally, you can use the following CSS reset:
    </p>
    <pre>
      <code>{`* {\n  box-sizing: border-box;\n}`}</code>
    </pre><br />

    <h2>Conclusion</h2>
    <p>
      Using <code>box-sizing: border-box</code> is generally recommended for better layout control, as it simplifies width and height calculations, especially in responsive designs.
    </p>
 

              </div>
            )}




{selectedChapter === 'chapter128' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Media Queries </h1>
         
    
    <p>
      Media queries in CSS are a powerful feature used to apply styles based on the characteristics of the device or viewport, such as its width, height, orientation, and resolution. They enable responsive web design, allowing websites to adapt their layout and appearance to different screen sizes and devices.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Key Concepts of Media Queries</h2>

    <ol>
      <li>
        <strong>Basic Syntax:</strong>
        <p>
          Media queries consist of a media type (like <code>screen</code>, <code>print</code>, etc.) and conditions that define when the styles should be applied. The basic syntax is:
        </p>
        <pre>
          <code>
            {`@media media-type and (condition) {\n  /* CSS rules here */\n}`}
          </code>
        </pre>
      </li><br />

      <li>
        <strong>Common Conditions:</strong>
        <ul>
          <li><code>min-width</code>: Minimum width of the viewport.</li><br />
          <li><code>max-width</code>: Maximum width of the viewport.</li><br />
          <li><strong>Orientation:</strong>
            <ul><br />
              <li><code>portrait</code>: Portrait orientation (height greater than width).</li><br />
              <li><code>landscape</code>: Landscape orientation (width greater than height).</li>
            </ul>
          </li>
          <li><code>min-resolution</code>: Minimum resolution in DPI or DPCM.</li>
        </ul>
      </li><br />

      <li>
        <strong>Example Usage:</strong>
        <pre>
          <code>
            {`/* Default styles */\nbody {\n  background-color: white;\n  font-size: 16px;\n}\n\n/* Styles for devices with a max width of 600px */\n@media screen and (max-width: 600px) {\n  body {\n    background-color: lightblue;\n    font-size: 14px;\n  }\n}\n\n/* Styles for devices in landscape orientation */\n@media screen and (orientation: landscape) {\n  body {\n    background-color: lightgreen;\n  }\n}`}
          </code>
        </pre>
      </li><br />

      <li>
        <strong>Mobile-First Approach:</strong>
        <p>
          It's common to use a mobile-first approach, where you define styles for small screens first and then use media queries to add styles for larger screens. This ensures a smoother experience for users on mobile devices.
        </p>
      </li><br />

      <li>
        <strong>Using Multiple Queries:</strong>
        <p>
          You can combine multiple media queries using commas, which will apply styles if any of the conditions are met:
        </p>
        <pre>
          <code>
            {`@media screen and (max-width: 600px), (orientation: landscape) {\n  /* Styles for small screens or landscape orientation */\n}`}
          </code>
        </pre>
      </li>
    </ol><br />

    <h2>Conclusion</h2>
    <p>
      Media queries are essential for creating responsive designs that work well across a variety of devices and screen sizes. By using them effectively, you can ensure that your website provides a great user experience, regardless of how it's accessed.
    </p>


              </div>
            )}


{selectedChapter === 'chapter129' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>MQ Examples  </h1>

    <p>
      Media Query (MQ) examples in CSS demonstrate how to apply styles based on the characteristics of the device or viewport. Here are a few common examples:
    </p><br />

    <h2>1. Basic Media Query</h2>
    <p>
      This example changes the background color of the body when the viewport width is less than 600px.
    </p>
    <pre>
      <code>
        {`@media screen and (max-width: 600px) {
  body {
    background-color: lightblue;
  }
}`}
      </code>
    </pre><br />

    <h2>2. Targeting Portrait and Landscape</h2>
    <p>
      You can use media queries to style elements differently based on the device's orientation.
    </p>
    <pre>
      <code>
        {`@media screen and (orientation: portrait) {
  body {
    background-color: lightgreen;
  }
}

@media screen and (orientation: landscape) {
  body {
    background-color: lightcoral;
  }
}`}
      </code>
    </pre><br />

    <h2>3. Multiple Conditions</h2>
    <p>
      This example applies styles when either condition is met—if the viewport is less than 600px wide or the orientation is landscape.
    </p>
    <pre>
      <code>
        {`@media screen and (max-width: 600px), (orientation: landscape) {
  .container {
    padding: 10px;
  }
}`}
      </code>
    </pre><br />

    <h2>4. Min-Width for a Mobile-First Approach</h2>
    <p>
      Start with styles for mobile and then add styles for larger screens.
    </p>
    <pre>
      <code>
        {`/* Mobile styles */
body {
  font-size: 14px;
}

/* Styles for larger screens */
@media screen and (min-width: 600px) {
  body {
    font-size: 16px;
  }
}

@media screen and (min-width: 900px) {
  body {
    font-size: 18px;
  }
}`}
      </code>
    </pre><br />

    <h2>5. Print Media Query</h2>
    <p>
      Change styles specifically for printing.
    </p>
    <pre>
      <code>
        {`@media print {
  body {
    background-color: white;
    color: black;
  }

  .no-print {
    display: none;
  }
}`}
      </code>
    </pre><br />

    <h2>Conclusion</h2>
    <p>
      These examples showcase how media queries can enhance responsive design by adapting styles based on screen size, orientation, and other conditions. By leveraging media queries, you can ensure a better user experience across various devices.
    </p>


              </div>
            )}


{selectedChapter === 'chapter97' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Flexbox</h1>
      
    
    <p>
      Flexbox, or the Flexible Box Layout, is a CSS layout model designed to make it easier to create responsive layouts. It allows items within a container to be aligned and distributed efficiently, even when their sizes are unknown or dynamic.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Key Concepts of Flexbox</h2>

    <h3>1. Flex Container</h3>
    <p>
      The parent element that contains flex items. To create a flex container, you apply the <code>display: flex;</code> property.
    </p><br />

    <h3>2. Flex Items</h3>
    <p>
      The direct children of a flex container. They are automatically made flexible, allowing for various alignment and sizing options.
    </p><br />

    <h3 style={{paddingBottom:"6px"}}>3. Main Axis and Cross Axis</h3>
    <p>
      <strong>Main Axis:</strong> The primary axis along which flex items are laid out (default is horizontal).<br />
      <strong>Cross Axis:</strong> The perpendicular axis (default is vertical).
    </p><br />

    <h3 style={{paddingBottom:"6px"}}>4. Flex Properties</h3>
    <ul>
      <li><code>flex-direction</code>: Defines the direction of flex items (e.g., <code>row</code>, <code>column</code>).</li><br />
      <li><code>justify-content</code>: Aligns items along the main axis (e.g., <code>center</code>, <code>space-between</code>).</li><br />
      <li><code>align-items</code>: Aligns items along the cross axis (e.g., <code>flex-start</code>, <code>stretch</code>).</li><br />
      <li><code>flex-wrap</code>: Controls wrapping of items (e.g., <code>nowrap</code>, <code>wrap</code>).</li>
    </ul><br />

    <h3 style={{paddingBottom:"6px"}}>5. Flex Item Properties</h3>
    <ul>
      <li><code>flex-grow</code>: Defines how much a flex item should grow relative to others.</li><br />
      <li><code>flex-shrink</code>: Defines how much a flex item should shrink relative to others.</li><br />
      <li><code>flex-basis</code>: Specifies the initial size of a flex item before space is distributed.</li>
    </ul>

    <h2>Example</h2>
    <p>Here’s a simple example of using Flexbox:</p>

    <pre>
      <code>
{`/* CSS */\n.container {\n  display: flex;\n  flex-direction: row; /* Align items in a row */\n  justify-content: space-between; /* Space out items */\n  align-items: center; /* Center items vertically */\n}\n\n.item {\n  flex: 1; /* Each item will grow equally */\n  padding: 10px;\n  border: 1px solid #ccc;\n}`}
      </code>
    </pre><br />

    <h3>HTML Structure</h3>
    <pre>
      <code>
{`<div class="container">\n  <div class="item">Item 1</div>\n  <div class="item">Item 2</div>\n  <div class="item">Item 3</div>\n</div>`}
      </code>
    </pre><br />

    <h2>Conclusion</h2>
    <p>
      Flexbox simplifies the process of creating complex layouts without needing to use floats or positioning. Its responsive capabilities make it a powerful tool for modern web design.
    </p>
 
              </div>
            )}






{selectedChapter === 'chapter98' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Flex Responsive</h1>
           
                   
   
          <p>
            Flexbox (Flexible Box Layout) is designed to create responsive layouts that adapt to different screen sizes. 
            By using flex properties, you can align and distribute space among items easily.
          </p><br />

          <h2 style={{paddingBottom:"6px"}}>Key Concepts of Flex Responsive Design</h2>
          <ul>
            <li><strong>Flexible Sizing:</strong> Use properties like <code>flex-grow</code>, <code>flex-shrink</code>, and <code>flex-basis</code>.</li><br />
            <li><strong>Direction and Wrapping:</strong> Control item direction with <code>flex-direction</code> and allow wrapping with <code>flex-wrap</code>.</li><br />
            <li><strong>Alignment:</strong> Use <code>justify-content</code> and <code>align-items</code> for layout adjustments.</li><br />
            <li><strong>Media Queries:</strong> Combine Flexbox with media queries for dynamic layout changes.</li>
          </ul><br />

          <h2>Example of Responsive Flexbox</h2>
          <div className={style.container}>
            <div className={style.item}>Item 1</div>
            <div className={style.item}>Item 2</div>
            <div className={style.item}>Item 3</div>
            <div className={style.item}>Item 4</div>
          </div>
   

              </div>
            )}






{selectedChapter === 'chapter87' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>RWD Intro</h1>
           
                   

                <p>
      Responsive Web Design (RWD) in CSS refers to the approach of designing web pages that adapt to different screen sizes and devices. The goal is to provide an optimal viewing experience, ensuring that users can easily read and navigate a site across various devices, from desktops to tablets and smartphones.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Key Concepts of Responsive Web Design</h2>

    <ol>
      <li>
        <strong>Fluid Grids:</strong>
        <p>
          Use relative units like percentages instead of fixed units (like pixels) for layout elements. This allows elements to resize fluidly based on the viewport.
        </p>
        <pre>
          <code>
{`.container {
  width: 100%; /* Full width */
  max-width: 1200px; /* Max width for larger screens */
}`}
          </code>
        </pre>
      </li><br />

      <li>
        <strong>Flexible Images:</strong>
        <p>
          Ensure images scale appropriately by setting their max width to 100%. This keeps them responsive and prevents overflow.
        </p>
        <pre>
          <code>
{`img {
  max-width: 100%; /* Image scales with the parent */
  height: auto; /* Maintain aspect ratio */
}`}
          </code>
        </pre>
      </li><br />

      <li>
        <strong>Media Queries:</strong>
        <p>
          Media queries are a cornerstone of RWD, allowing you to apply different styles based on the viewport's size or device characteristics.
        </p>
        <pre>
          <code>
{`@media (max-width: 600px) {
  .container {
    flex-direction: column; /* Stack elements on small screens */
  }
}`}
          </code>
        </pre>
      </li><br />

      <li>
        <strong>Viewport Meta Tag:</strong>
        <p>
          Include the viewport meta tag in your HTML to control layout on mobile browsers. This ensures your layout scales correctly.
        </p>
        <pre>
          <code>
{`<meta name="viewport" content="width=device-width, initial-scale=1.0">`}
          </code>
        </pre>
      </li><br />

      <li>
        <strong>Responsive Typography:</strong>
        <p>
          Use relative units (like <code>em</code> or <code>rem</code>) for font sizes, ensuring text scales with the user’s preferences and the overall layout.
        </p>
        <pre>
          <code>
{`body {
  font-size: 16px; /* Base size */
}

h1 {
  font-size: 2rem; /* Responsive size */
}`}
          </code>
        </pre>
      </li>
    </ol><br />

    <h2>Conclusion</h2>
    <p>
      Responsive Web Design is essential for modern web development, ensuring that websites are usable and visually appealing across a wide range of devices. By employing fluid grids, flexible images, media queries, and proper meta tags, you can create a seamless experience for users, enhancing engagement and accessibility.
    </p>
 

              </div>
            )}


{selectedChapter === 'chapter96' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Gride View</h1>
           
      
    
    <p>
      A grid view in CSS refers to a layout technique that uses CSS Grid Layout, which allows developers to create complex, responsive web layouts easily. Here are the key features of grid view:
    </p><br />
    
    <h2 style={{paddingBottom:"6px"}}>Key Features of CSS Grid</h2>
    <ol>
      <li><strong>Two-Dimensional Layout:</strong> CSS Grid enables you to design both rows and columns simultaneously, making it ideal for creating complex layouts.</li><br />
      <li><strong>Grid Container:</strong> You define a grid container using <code>display: grid;</code>, which sets the context for grid items.</li><br />
      <li><strong>Defining Rows and Columns:</strong> You can specify the number and size of rows and columns using properties like <code>grid-template-rows</code> and <code>grid-template-columns</code>.</li>
    </ol>
    
    <pre>
      <code>
{`.grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Three equal columns */
    grid-template-rows: auto; /* Rows adjust to content */
}`}
      </code>
    </pre><br />

    <ol start={4}>
      <li><strong>Grid Items:</strong> Elements within the grid container become grid items and can be positioned precisely using grid lines.</li><br />
      <li><strong>Responsive Design:</strong> You can create responsive layouts easily using media queries or by utilizing <code>fr</code> units, percentages, and auto-sizing.</li><br />
      <li><strong>Alignment and Spacing:</strong> CSS Grid provides properties for aligning items within the grid, such as <code>align-items</code>, <code>justify-items</code>, and <code>gap</code> for spacing between items.</li>
    </ol><br />
    
    <h2>Example of a Simple Grid Layout</h2>
    
    <p>Here's a basic example to illustrate a grid view:</p>
    
    <pre>
      <code>
{`<div class="grid-container">
    <div class="item1">Item 1</div>
    <div class="item2">Item 2</div>
    <div class="item3">Item 3</div>
    <div class="item4">Item 4</div>
</div>`}
      </code>
    </pre>

    <pre>
      <code>
{`.grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two equal columns */
    gap: 10px; /* Space between items */
}

.item1, .item2, .item3, .item4 {
    background-color: lightblue;
    padding: 20px;
    text-align: center;
}`}
      </code>
    </pre><br />

    <h2>Conclusion</h2>
    
    <p>
      Grid view in CSS provides a powerful and flexible way to create layouts that adapt to different screen sizes. It simplifies the process of building complex designs while maintaining a clean and organized code structure.
    </p>


              </div>
            )}


{selectedChapter === 'chapter88' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>RWD Media Queries</h1>
           
            

    <p>
      RWD Media Queries are a key feature of Responsive Web Design (RWD) that allow developers to apply different CSS styles based on the characteristics of the user's device, particularly the viewport size. Media queries help create flexible layouts that adapt to various screen sizes, ensuring an optimal viewing experience across devices.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Key Concepts of Media Queries</h2>
    <ol>
      <li>
        <strong>Viewport Width:</strong> Media queries can target specific widths to adjust styles for mobile, tablet, and desktop screens.
      </li><br />
      <li>
        <strong>Syntax:</strong> A media query consists of a media type and one or more expressions that check for conditions like width, height, resolution, etc. The basic syntax looks like this:
        <pre>
          <code>
{`@media (condition) {
    /* CSS rules */
}`}
          </code>
        </pre>
      </li><br />
      <li>
        <strong style={{paddingBottom:"6px"}}>Common Features:</strong>
        <ul>
          <li><strong>Width and Height:</strong> Target specific widths or heights, such as <code>max-width</code>, <code>min-width</code>, <code>max-height</code>, or <code>min-height</code>.</li><br />
          <li><strong>Orientation:</strong> Apply styles based on whether the device is in portrait or landscape mode.</li><br />
          <li><strong>Resolution:</strong> Target high-resolution screens using <code>min-resolution</code> or <code>max-resolution</code>.</li>
        </ul>
      </li>
    </ol><br />

    <h2>Example of Media Queries</h2>
    <p>Here’s a simple example:</p>
    
    <pre>
      <code>
{`/* Default styles for all devices */
body {
    font-size: 16px;
}

/* Styles for devices with a max width of 600px (mobile devices) */
@media (max-width: 600px) {
    body {
        font-size: 14px; /* Smaller font for mobile */
    }
}

/* Styles for devices with a min width of 601px and max width of 1200px (tablets) */
@media (min-width: 601px) and (max-width: 1200px) {
    body {
        font-size: 15px; /* Medium font for tablets */
    }
}

/* Styles for devices with a min width of 1201px (desktops) */
@media (min-width: 1201px) {
    body {
        font-size: 18px; /* Larger font for desktops */
    }
}`}
      </code>
    </pre><br />

    <h2 style={{paddingBottom:"6px"}} >Benefits of Using Media Queries</h2>
    <ul>
      <li><strong>Improved User Experience:</strong> Ensures that content is easily readable and navigable on all devices.</li><br />
      <li><strong>Performance:</strong> Reduces the need for separate mobile and desktop versions of a website, streamlining development and maintenance.</li><br />
      <li><strong>Flexibility:</strong> Allows developers to create layouts that can easily adapt to new devices and screen sizes.</li>
    </ul><br />

    <h2>Conclusion</h2>
    <p>
      RWD Media Queries are essential for creating responsive websites that provide a tailored experience across different devices. By leveraging media queries, developers can optimize layouts, typography, and other design elements based on the specific characteristics of the user's device.
    </p>

              </div>
            )}




{selectedChapter === 'chapter89' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>RWD Image </h1>
           
               
          <p>
            RWD stands for Responsive Web Design, and RWD images refer to images that adapt to different screen sizes and resolutions to provide an optimal viewing experience. In CSS, there are several techniques to achieve this:
          </p><br />

          <h2>1. Fluid Images</h2>
          <p>
            By setting the image width to a percentage, it can resize relative to its container.
          </p>
          <pre>
            <code>
              {`img {
  max-width: 100%;
  height: auto;
}`}
            </code>
          </pre><br />

          <h2>2. Media Queries</h2>
          <p>
            You can use media queries to change the source of the image based on the viewport size.
          </p>
          <pre>
            <code>
              {`@media (max-width: 600px) {
  .responsive-img {
    background-image: url('small-image.jpg');
  }
}
@media (min-width: 601px) {
  .responsive-img {
    background-image: url('large-image.jpg');
  }
}`}
            </code>
          </pre><br />

          <h2>3. srcset Attribute</h2>
          <p>
            In HTML, the srcset attribute allows you to specify different images for different screen sizes or resolutions.
          </p>
          <pre>
            <code>
              {`<img src="image-small.jpg" 
     srcset="image-medium.jpg 600w, 
             image-large.jpg 1200w" 
     sizes="(max-width: 600px) 100vw, 50vw" 
     alt="Description of the image">`}
            </code>
          </pre><br />

          <h2>4. picture Element</h2>
          <p>
            This allows for more control over image sources based on screen conditions.
          </p>
          <pre>
            <code>
              {`<picture>
  <source media="(max-width: 600px)" srcset="image-small.jpg">
  <source media="(min-width: 601px)" srcset="image-large.jpg">
  <img src="image-default.jpg" alt="Description">
</picture>`}
            </code>
          </pre><br />

          <p>
            Using these methods helps ensure that images load efficiently and look good on a variety of devices, enhancing both performance and user experience.
          </p>



              </div>
            )}

{selectedChapter === 'chapter90' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>RWD Videos</h1>
         
          <p>
            RWD videos, or Responsive Web Design videos, refer to videos that adjust their size and layout according to the screen size and resolution of the device being used. This ensures that videos are displayed correctly on a variety of devices, providing a better user experience.
          </p><br />

          <h2 style={{paddingBottom:"6px"}}>Here are some common techniques to make videos responsive in CSS:</h2>

          <h3>1. Fluid Width with CSS</h3>
          <p>
            You can make a video responsive by setting its width to 100% and the height to auto. This way, it will scale proportionally with its container.
          </p>
          <pre>
            <code>
              {`video {
    width: 100%;
    height: auto;
}`}
            </code>
          </pre><br />

          <h3>2. Using a Wrapper with Aspect Ratio</h3>
          <p>
            To maintain a specific aspect ratio, you can use a wrapper around the video. This approach uses padding to create a responsive container.
          </p>
          <pre>
            <code>
              {`.video-wrapper {
    position: relative;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
}

.video-wrapper iframe,
.video-wrapper video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}`}
            </code>
          </pre><br />

          <h3>3. Using `object-fit`</h3>
          <p>
            For {"`<video>`"} tags, you can use `object-fit` to control how the video is resized to fit its container.
          </p>
          <pre>
            <code>
              {`video {
    width: 100%;
    height: 100%;
    object-fit: cover; /* or contain */
}`}
            </code>
          </pre><br />

          <h3>4. Using HTML5 {"`<video>`"}  Tag</h3>
          <p>
            When embedding videos, make sure to use the HTML5 {"`<video>`"}  tag for better responsiveness. Here’s an example:
          </p>
          <pre>
            <code>
              {`<div className="video-wrapper">
    <video controls>
        <source src="your-video.mp4" type="video/mp4" />
        Your browser does not support the video tag.
    </video>
</div>`}
            </code>
          </pre><br />

          <h3>5. Using `iframe` for Embedded Videos</h3>
          <p>
            If you’re embedding videos from platforms like YouTube, you can use a similar wrapper method to ensure responsiveness:
          </p>
          <pre>
            <code>
              {`<div className="video-wrapper">
    <iframe 
        src="https://www.youtube.com/embed/VIDEO_ID" 
        frameborder="0" 
        allowfullscreen>
    </iframe>
</div>`}
            </code>
          </pre><br />

          <h2>Summary</h2>
          <p>
            Using these techniques allows videos to adapt to different screen sizes, providing a better viewing experience. Combining CSS properties like width, height, and positioning techniques helps ensure that videos look great on any device.
          </p>
        


              </div>
            )}



{selectedChapter === 'chapter91' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>RWD Frameworks</h1>
           
   
          <p>
            A <strong>Responsive Web Design (RWD) framework</strong> is a collection of CSS, JavaScript, and sometimes HTML tools that help developers create responsive websites. These frameworks provide a set of pre-designed components and layout systems that adapt to different screen sizes and orientations, ensuring that a website looks good and functions well on a variety of devices, from desktops to smartphones.
          </p><br />

          <h2 style={{paddingBottom:"6px"}}>Key Features of RWD Frameworks</h2>
          <ol>
            <li>
              <strong>Grid Systems</strong>: Most RWD frameworks include a flexible grid system that allows you to create layouts that can adjust to various screen sizes.
            </li><br />
            <li>
              <strong>Media Queries</strong>: They often come with built-in media queries that allow you to apply different styles based on the device's characteristics, such as width, height, and orientation.
            </li><br />
            <li>
              <strong>Responsive Components</strong>: Common UI elements like buttons, navigation menus, forms, and modals are designed to be responsive out of the box.
            </li><br />
            <li>
              <strong>Cross-Browser Compatibility</strong>: RWD frameworks are usually tested across multiple browsers to ensure consistent behavior.
            </li><br />
            <li>
              <strong>Mobile First</strong>: Many frameworks encourage a mobile-first approach, meaning you design for smaller screens first and then scale up for larger devices.
            </li>
          </ol><br />

          <h2 style={{paddingBottom:"6px"}}>Popular RWD Frameworks</h2>
          <ol>
            <li>
              <strong>Bootstrap</strong>: One of the most popular front-end frameworks, Bootstrap provides a comprehensive set of components, a responsive grid system, and extensive documentation.
            </li><br />
            <li>
              <strong>Foundation</strong>: Developed by ZURB, Foundation is another robust framework that offers advanced features for building responsive websites and applications.
            </li><br />
            <li>
              <strong>Tailwind CSS</strong>: Unlike traditional frameworks that come with pre-designed components, Tailwind CSS is a utility-first CSS framework that allows developers to build custom designs quickly.
            </li><br />
            <li>
              <strong>Bulma</strong>: A modern CSS framework based on Flexbox, Bulma is lightweight and provides a simple syntax for creating responsive layouts.
            </li><br />
            <li>
              <strong>Materialize</strong>: Based on Google's Material Design principles, Materialize offers components that are designed to look good on both mobile and desktop devices.
            </li>
          </ol><br />

          <h2 style={{paddingBottom:"6px"}}>Benefits of Using RWD Frameworks</h2>
          <ul>
            <li><strong>Efficiency</strong>: Frameworks speed up the development process by providing pre-built components and styles.</li><br />
            <li><strong>Consistency</strong>: They help maintain design consistency across different pages and devices.</li><br />
            <li><strong>Responsiveness</strong>: Ensure that websites adapt seamlessly to various screen sizes and resolutions.</li><br />
            <li><strong>Community Support</strong>: Popular frameworks often have large communities, which can provide support, tutorials, and plugins.</li>
          </ul><br />

          <p>
            Using an RWD framework can significantly enhance your ability to create responsive and user-friendly websites efficiently.
          </p>
 


              </div>
            )}

{selectedChapter === 'chapter92' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>RWD Templates</h1>
           
          <p>
            <strong>RWD (Responsive Web Design) templates</strong> are pre-designed website layouts that are built using responsive web design principles. These templates are structured to adapt seamlessly to various screen sizes and resolutions, ensuring a consistent user experience across devices, from desktops to tablets and smartphones.
          </p><br />

          <h2 style={{paddingBottom:"6px"}}>Key Features of RWD Templates</h2>
          <ol>
            <li>
              <strong>Fluid Grids</strong>: RWD templates use fluid grids that allow layout elements to resize proportionally based on the screen size. This is often achieved using percentage-based widths instead of fixed pixel values.
            </li><br />
            <li>
              <strong>Media Queries</strong>: Templates utilize CSS media queries to apply different styles depending on the viewport dimensions, allowing for tailored presentations on different devices.
            </li><br />
            <li>
              <strong>Flexible Images and Media</strong>: Images and other media elements are set to scale within their containers, ensuring they don’t overflow or distort on smaller screens.
            </li><br />
            <li>
              <strong>Mobile-First Design</strong>: Many RWD templates adopt a mobile-first approach, designing for smaller screens initially and progressively enhancing the design for larger screens.
            </li><br />
            <li>
              <strong>Pre-Styled Components</strong>: They typically come with a variety of pre-styled components (like buttons, forms, navigation menus) that are responsive out of the box.
            </li>
          </ol><br />

          <h2 style={{paddingBottom:"6px"}} >Benefits of Using RWD Templates</h2>
          <ul>
            <li><strong>Time-Saving</strong>: Using a template can significantly reduce development time since much of the design work is already done.</li><br />
            <li><strong>Consistency</strong>: RWD templates help maintain a consistent look and feel across different pages and devices.</li><br />
            <li><strong>User Experience</strong>: They improve usability by ensuring that content is accessible and visually appealing, regardless of the device used.</li><br />
            <li><strong>Cross-Browser Compatibility</strong>: Most templates are tested across various browsers to ensure functionality and appearance remain consistent.</li>
          </ul><br />

          <h2 style={{paddingBottom:"6px"}}>Popular Sources for RWD Templates</h2>
          <ol>
            <li>
              <strong>Bootstrap</strong>: Offers a wide range of templates and components that follow responsive design principles.
            </li><br />
            <li>
              <strong>Foundation</strong>: Another robust framework providing RWD templates.
            </li><br />
            <li>
              <strong>ThemeForest</strong>: A marketplace for premium website templates, many of which are responsive.
            </li><br />
            <li>
              <strong>HTML5 UP</strong>: Provides free responsive HTML5 templates that are easy to customize.
            </li><br />
            <li>
              <strong>W3Layouts</strong>: Offers a variety of free and premium responsive templates.
            </li>
          </ol><br />

          <h2>Example Usage</h2>
          <p>
            When using a responsive template, developers can customize the existing styles and components to fit their needs while ensuring the layout adapts to different devices. Here’s a simple example of a responsive container in CSS:
          </p>
          <pre>
            <code>
              {`.container {
    width: 100%;
    max-width: 1200px; /* Maximum width */
    margin: 0 auto; /* Centering */
    padding: 15px;
}

@media (max-width: 768px) {
    .container {
        padding: 10px; /* Adjust padding for smaller screens */
    }
}`}
            </code>
          </pre><br />

          <h2>Summary</h2>
          <p>
            RWD templates are valuable tools for developers looking to create responsive websites efficiently. They leverage modern CSS techniques to ensure a great user experience across devices, saving time and effort in the development process.
          </p>
      


              </div>
            )}

{selectedChapter === 'chapter93' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Gride Intro </h1>
           
              

          <p>
            <strong>Grid Intro in CSS</strong> refers to the foundational concepts and features of the CSS Grid Layout, a powerful layout system in CSS that allows for the creation of complex web layouts in a straightforward manner. CSS Grid enables developers to design responsive and flexible grid-based layouts, making it easier to align items within a grid structure.
          </p><br />

          <h2 style={{paddingBottom:"6px"}}>Key Features of CSS Grid</h2>
          <ol>
            <li>
              <strong>Two-Dimensional Layout</strong>: Unlike Flexbox, which is primarily one-dimensional (either a row or a column), CSS Grid is designed for two-dimensional layouts, allowing for both rows and columns.
            </li><br />
            <li>
              <strong>Grid Container</strong>: A grid layout is created by defining a container as a grid using the <code>display: grid;</code> property. This container can hold multiple grid items.
            </li><br />
            <li>
              <strong>Grid Items</strong>: The direct children of the grid container become grid items and can be positioned within the grid using various properties.
            </li><br />
            <li>
              <strong>Grid Tracks</strong>: Rows and columns in a grid layout are referred to as grid tracks. You can define their sizes using fixed units (like pixels or ems) or flexible units (like fractions).
            </li><br />
            <li>
              <strong>Template Areas</strong>: CSS Grid allows you to define named grid areas, making it easy to position items by name instead of by grid lines.
            </li><br />
            <li>
              <strong>Responsive Design</strong>: Grid layouts can adapt easily to different screen sizes and orientations, making it an excellent choice for responsive web design.
            </li><br />
          </ol>

          
          <h2>Here’s a simple example of how to create a grid layout:</h2>

          <h3>CSS</h3>
          <pre>
            <code>
              {`.container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Three equal columns */
    grid-template-rows: auto; /* Rows adjust to content height */
    gap: 10px; /* Space between grid items */
}

.item {
    background-color: #ccc; /* Item background */
    padding: 20px; /* Item padding */
    text-align: center; /* Center text */
}`}
            </code>
          </pre><br />

          <h3> HTML Structure</h3>
          <pre>
            <code>
              {`<div class="container">
    <div class="item">1</div>
    <div class="item">2</div>
    <div class="item">3</div>
    <div class="item">4</div>
    <div class="item">5</div>
    <div class="item">6</div>
</div>`}
            </code>
          </pre><br />

          <h2 style={{paddingBottom:"6px"}}>Benefits of Using CSS Grid</h2>
          <ul>
            <li><strong>Flexibility</strong>: Easily create complex layouts without relying on floats or positioning.</li><br />
            <li><strong>Alignment</strong>: Precise control over alignment and spacing between grid items.</li><br />
            <li><strong>Simplified Markup</strong>: Reduced need for nested elements, leading to cleaner HTML.</li><br />
            <li><strong>Responsive Design</strong>: Easily adjust layouts for different screen sizes using media queries.</li> 
          </ul><br />

          <h2>Summary</h2>
          <p>
            CSS Grid is a robust layout system that empowers developers to create responsive and complex web designs with ease. Understanding the basics of CSS Grid is essential for modern web development, enabling you to build visually appealing and adaptable layouts.
          </p>
       


              </div>
            )}


{selectedChapter === 'chapter94' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Gride Container</h1>
          

          <p>
            A <strong>Grid Container</strong> in CSS refers to the element that establishes a grid layout for its child elements, known as grid items. By setting the <code>display</code> property to <code>grid</code>, you create a context in which the grid items can be positioned according to the defined grid structure.
          </p><br />

          <h2 style={{paddingBottom:"6px"}}>Key Concepts of Grid Container</h2>
          <ol>
            <li>
              <strong>Creating a Grid Container</strong>: To make an element a grid container, you apply the following CSS property:
              <pre>
                <code>{`.container {
    display: grid;
}`}</code>
              </pre>
            </li><br />
            <li>
              <strong>Defining Rows and Columns</strong>: You can define the structure of the grid using properties like <code>grid-template-columns</code> and <code>grid-template-rows</code>:
              <pre>
                <code>{`.container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Three equal columns */
    grid-template-rows: auto; /* Rows adjust to content height */
}`}</code>
              </pre>
            </li><br />
            <li>
              <strong>Grid Gap</strong>: The space between the rows and columns can be controlled using the <code>gap</code> property:
              <pre>
                <code>{`.container {
    display: grid;
    gap: 10px; /* Space between grid items */
}`}</code>
              </pre>
            </li><br />
            <li>
              <strong>Justification and Alignment</strong>: You can control how the grid items are aligned within the grid container using properties like <code>justify-items</code>, <code>align-items</code>, and <code>place-items</code>:
              <pre>
                <code>{`.container {
    display: grid;
    justify-items: center; /* Center items horizontally */
    align-items: center; /* Center items vertically */
}`}</code>
              </pre>
            </li><br />
            <li>
              <strong>Implicit vs. Explicit Grid</strong>:
              <ul>
                <li>
                  <strong>Explicit Grid</strong>: Defined by <code>grid-template-columns</code> and <code>grid-template-rows</code>, where you specify how many rows and columns there are.
                </li><br />
                <li>
                  <strong>Implicit Grid</strong>: Automatically created by the grid container when more items are added than defined in the explicit grid.
                </li>
              </ul>
            </li>
          </ol><br />

          <h2>Example of a Grid Container</h2>
          <p>Here's a simple example demonstrating a grid container:</p>
          <pre>
            <code>{`<div class="grid-container">
    <div class="item">1</div>
    <div class="item">2</div>
    <div class="item">3</div>
    <div class="item">4</div>
    <div class="item">5</div>
    <div class="item">6</div>
</div>`}</code>
          </pre><br />

          <h2>CSS Example</h2>
          <pre>
            <code>{`.grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Three equal columns */
    gap: 10px; /* Space between items */
}

.item {
    background-color: #ccc;
    padding: 20px;
    text-align: center;
}`}</code>
          </pre><br />

          <h2>Summary</h2>
          <p>
            The grid container is the foundational element of the CSS Grid Layout system. It allows you to create responsive, two-dimensional layouts that can adjust seamlessly to various screen sizes. Understanding how to set up and manage grid containers is essential for building modern web interfaces.
          </p>
      


              </div>
            )}
{selectedChapter === 'chapter95' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Grid Item</h1>
    <p>
      In CSS, a <strong>Grid Item</strong> is any direct child of a <strong>Grid Container</strong>, 
      which is an element with the <code>display: grid</code> or <code>display: inline-grid</code> 
      property applied. Grid items are automatically placed into the grid's rows and columns based 
      on the grid structure defined within the container.
    </p><br />
    
    <h3 style={{paddingBottom:"6px"}}>Key Points:</h3>
    <ol>
      <li>
        <strong>Direct children of a grid container</strong>: Only the immediate children of an 
        element with <code>display: grid</code> become grid items. Nested elements inside grid 
        items are not considered grid items themselves.
      </li><br />
      <li>
        <strong>Grid Placement</strong>: Grid items can be placed in specific rows and columns using 
        properties like <code>grid-row</code>, <code>grid-column</code>, or more advanced methods such 
        as <code>grid-area</code> for custom layout placement.
      </li><br />
      <li>
        <strong>Grid Item Properties</strong>: Grid items can be controlled individually by setting 
        properties like:
        <ul>
          <li><code>grid-column-start</code>, <code>grid-column-end</code>: Define where the item should start and end on the column axis.</li><br />
          <li><code>grid-row-start</code>, <code>grid-row-end</code>: Define where the item should start and end on the row axis.</li><br />
          <li><code>justify-self</code>: Controls horizontal alignment within the item’s grid area.</li><br />
          <li><code>align-self</code>: Controls vertical alignment within the item’s grid area.</li>
        </ul>
      </li><br />
      <li>
        <strong>Flexible Layout</strong>: Grid items can automatically adapt to the available grid 
        tracks or be placed explicitly based on the rules you define.
      </li>
    </ol>

    <h3>Example:</h3>
    <pre>
      <code>{`
.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 equal columns */
  grid-template-rows: 200px 200px;      /* 2 rows of fixed height */
}

.grid-item {
  background-color: lightblue;
  padding: 20px;
  border: 1px solid black;
}
      `}</code>
    </pre>
    <pre>
      <code>{`
<div className="grid-container">
  <div className="grid-item">Item 1</div>
  <div className="grid-item">Item 2</div>
  <div className="grid-item">Item 3</div>
  <div className="grid-item">Item 4</div>
  <div className="grid-item">Item 5</div>
  <div className="grid-item">Item 6</div>
</div>
      `}</code>
    </pre>
    <p>
      In this example, the <code>.grid-container</code> defines a grid with 3 columns and 2 rows, 
      and each <code>.grid-item</code> is automatically placed in the grid cells.
    </p>
  </div>
)}

{selectedChapter === 'chapter101' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Sass Home</h1>
           
                   

               
          <p>
            Sass (Syntactically Awesome Style Sheets) is a CSS preprocessor that adds advanced features to CSS, making it more powerful and easier to manage. It allows developers to write styles in a more structured and efficient way. Some key features of Sass include:
          </p><br />
          
          <h2>1. Variables</h2>
          <p>
            You can store values like colors, fonts, or any CSS value in variables, making it easy to reuse them throughout your stylesheet.
          </p>
          <pre>
            <code>
              {`$primary-color: #3498db;

body {
  background-color: $primary-color;
}`}
            </code>
          </pre><br />

          <h2>2. Nesting</h2>
          <p>
            Sass lets you nest CSS selectors in a way that follows the HTML structure, improving readability.
          </p>
          <pre>
            <code>
              {`.nav {
  ul {
    list-style: none;
  }
  li {
    display: inline-block;
  }
}`}
            </code>
          </pre><br />

          <h2>3. Mixins</h2>
          <p>
            You can create reusable chunks of styles, making it easy to apply the same styles in different places.
          </p>
          <pre>
            <code>
              {`@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

.box {
  @include border-radius(10px);
}`}
            </code>
          </pre><br />

          <h2>4. Inheritance</h2>
          <p>
            You can use the <code>@extend</code> directive to share styles between selectors, reducing duplication.
          </p>
          <pre>
            <code>
              {`.button {
  padding: 10px;
  color: white;
}

.success-button {
  @extend .button;
  background-color: green;
}`}
            </code>
          </pre><br />

          <h2>5. Partials and Imports</h2>
          <p>
            Sass allows you to break your styles into smaller, manageable files and import them into a main stylesheet.
          </p>

          <p>
            Sass files typically have a <code>.scss</code> extension. When compiled, they generate standard CSS files that can be used in web projects. Overall, Sass enhances CSS by making it more maintainable and scalable, especially for larger projects.
          </p>
      


              </div>
            )}

{selectedChapter === 'chapter102' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Sass Intro</h1>
           
            
          
          <p>
            In CSS, an "intro" usually refers to introductory content or styles that set the stage for a webpage or a specific section. This can include elements like:
          </p><br />

          <ol>
            <li>
              <strong>Introduction Section:</strong> A dedicated section at the top of a webpage that briefly describes the content or purpose of the page. It often contains headings, subheadings, and introductory text.
            </li><br />
            <li>
              <strong>Styling:</strong> CSS styles applied to the introductory elements to make them visually appealing. This may involve:
              <ul><br />
                <li><strong>Typography:</strong> Setting font sizes, weights, and colors to emphasize headings and introductory text.</li><br />
                <li><strong>Backgrounds:</strong> Using background colors or images to create a visually distinct intro section.</li><br />
                <li><strong>Spacing:</strong> Adding margins and padding to ensure that the intro stands out from other content.</li>
              </ul>
            </li><br />
            <li>
              <strong>Animations or Effects:</strong> CSS transitions or animations that enhance the presentation of the intro section, such as fade-ins or slide-ins when the page loads.
            </li>
          </ol><br />

          <h2>Example CSS for an Intro Section</h2>
          <pre>
            <code>
              {`.intro {
  background-color: #f9f9f9;
  padding: 20px;
  text-align: center;
  border-bottom: 2px solid #ccc;
}

.intro h1 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 10px;
}

.intro p {
  font-size: 1.2rem;
  color: #666;
  max-width: 600px;
  margin: 0 auto;
}`}
            </code>
          </pre><br />

          <h2>Example HTML</h2>
          <pre>
            <code>
              {`<div class="intro">
  <h1>Welcome to Our Website</h1>
  <p>This site provides you with all the information you need about our services and products.</p>
</div>`}
            </code>
          </pre><br />

          <p>
            This combination of CSS and HTML creates a visually appealing introduction that can draw users in and provide context for the rest of the content on the page.
          </p>



              </div>
            )}

{selectedChapter === 'chapter103' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Sass Installation </h1>
           
              
          
          <p>
            To install and use Sass in your project, you can follow these steps. Depending on your setup, you may use various methods for installation, such as using npm, yarn, or a pre-compiled version.
          </p><br />

          <h2 style={{paddingBottom:"6px"}}>1. Using npm or yarn</h2>

          <h3>Step 1: Install Node.js</h3>
          <p>
            Make sure you have Node.js installed on your system. You can download it from <a href="https://nodejs.org/" target="_blank" rel="noopener noreferrer">nodejs.org</a>.
          </p><br />

          <h3>Step 2: Initialize Your Project</h3>
          <p>
            If you don’t already have a project, create a new directory and initialize it:
          </p>
          <pre>
            <code>
              {`mkdir my-project
cd my-project
npm init -y  # or yarn init -y`}
            </code>
          </pre><br />

          <h3>Step 3: Install Sass</h3>
          <p>
            Run one of the following commands in your terminal to install Sass:
          </p>
          <pre>
            <code>
              {`npm install sass --save-dev`}
            </code>
          </pre>
          <p>or</p>
          <pre>
            <code>
              {`yarn add sass --dev`}
            </code>
          </pre><br />

          <h2 style={{paddingBottom:"6px"}}>2. Using the Command Line Interface (CLI)</h2>

          <h3>Step 1: Install Sass Globally (Optional)</h3>
          <p>
            You can install Sass globally to use it in any project. This step is optional, especially if you want to use it only in specific projects.
          </p>
          <pre>
            <code>
              {`npm install -g sass`}
            </code>
          </pre><br />

          <h3>Step 2: Compile Sass</h3>
          <p>
            After installation, you can compile Sass files to CSS. Use the following command:
          </p>
          <pre>
            <code>
              {`sass input.scss output.css`}
            </code>
          </pre>
          <p>You can also watch a directory for changes:</p>
          <pre>
            <code>
              {`sass --watch input.scss:output.css`}
            </code>
          </pre><br />

          <h2>3. Using Pre-compiled Versions</h2>
          <p>
            If you prefer not to use npm or yarn, you can download a pre-compiled version of Sass from the <a href="https://sass-lang.com/install" target="_blank" rel="noopener noreferrer">Sass website</a>. Follow the instructions for your specific operating system.
          </p><br />

          <h2>4. Integrating with Build Tools</h2>
          <p>
            If you're using a build tool like Webpack, Gulp, or Grunt, you can integrate Sass with those tools:
          </p>
          <ul>
            <li><strong>Webpack:</strong> Use <code>sass-loader</code>, <code>css-loader</code>, and <code>style-loader</code>.</li><br />
            <li><strong>Gulp:</strong> Use <code>gulp-sass</code>.</li><br />
            <li><strong>Grunt:</strong> Use <code>grunt-sass</code>.</li>
          </ul><br />

          <h2>Example Usage</h2>
          <p>
            After installation, create a <code>.scss</code> file and write some Sass code:
          </p>
          <pre>
            <code>
              {`// styles.scss
$primary-color: #3498db;

body {
  background-color: $primary-color;
  font-family: Arial, sans-serif;
}`}
            </code>
          </pre>
          <p>Compile it to CSS:</p>
          <pre>
            <code>
              {`sass styles.scss styles.css`}
            </code>
          </pre><br />

          <h2>Summary</h2>
          <p>
            Now you have Sass installed and can start using it in your projects! You can create <code>.scss</code> files, and when compiled, they will generate standard CSS files that you can link to your HTML. If you need further assistance or specific configurations, feel free to ask!
          </p>



              </div>
            )}




{selectedChapter === 'chapter104' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Sass Variables </h1>
           
           
          
          <p>
            Sass variables are a powerful feature of Sass (Syntactically Awesome Style Sheets) that allow you to store values such as colors, font sizes, and other CSS values for reuse throughout your stylesheets. This helps maintain consistency and makes it easier to manage your styles, especially in larger projects.
          </p><br />

          <h2 style={{paddingBottom:"6px"}}>How to Use Sass Variables</h2>

          <h3>1. Declaring Variables:</h3>
          <p>
            You can declare a variable by using the <code>$</code> symbol followed by the variable name. Here’s an example:
          </p>
          <pre>
            <code>
              {`$primary-color: #3498db;
$font-stack: 'Helvetica, Arial, sans-serif';
$font-size: 16px;`}
            </code>
          </pre><br />

          <h3>2. Using Variables:</h3>
          <p>
            Once declared, you can use these variables anywhere in your stylesheets:
          </p>
          <pre>
            <code>
              {`body {
  font-family: $font-stack;
  font-size: $font-size;
  color: $primary-color;
}

.button {
  background-color: $primary-color;
  padding: 10px;
  border-radius: 5px;
}`}
            </code>
          </pre><br />

          <h3>3. Updating Variables:</h3>
          <p>
            If you need to change a value, you only need to update the variable declaration, and it will reflect everywhere the variable is used:
          </p>
          <pre>
            <code>
              {`$primary-color: #e74c3c; // Update color`}
            </code>
          </pre><br />

          <h3>4. Variable Scoping:</h3>
          <p>
            Variables can be scoped within a selector or a block, meaning they are only available within that specific context. For example:
          </p>
          <pre>
            <code>
              {`.container {
  $local-color: #2ecc71; // This variable is local to .container

  h1 {
    color: $local-color; // This works
  }
}

p {
  // color: $local-color; // This would throw an error
}`}
            </code>
          </pre><br />

          <h2 style={{paddingBottom:"6px"}}>Benefits of Using Sass Variables</h2>
          <ul>
            <li><strong>Consistency:</strong> Ensures consistent use of colors, sizes, and other values across your styles.</li><br />
            <li><strong>Maintainability:</strong> Makes it easier to update styles; change a value in one place rather than throughout your code.</li><br />
            <li><strong>Clarity:</strong> Improves the readability of your code by using descriptive variable names instead of raw values.</li>
          </ul><br />

          <h2>Example</h2>
          <p>
            Here’s a complete example demonstrating Sass variables:
          </p>
          <pre>
            <code>
              {`// Variables
$primary-color: #3498db;
$secondary-color: #2ecc71;
$font-stack: 'Helvetica, Arial, sans-serif';
$base-font-size: 16px;

// Styles
body {
  font-family: $font-stack;
  font-size: $base-font-size;
  color: $primary-color;
}

button {
  background-color: $secondary-color;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;

  &:hover {
    background-color: darken($secondary-color, 10%);
  }
}`}
            </code>
          </pre><br />

          <p>
            In this example, the use of variables enhances readability and makes it easy to manage the styling of the entire project. If you decide to change the <code>$primary-color</code> or <code>$font-stack</code>, you only have to do it in one place!
          </p>
        


              </div>
            )}

{selectedChapter === 'chapter105' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Sass Nesting</h1>
           
          
          <p>
            Sass nesting is a feature of Sass (Syntactically Awesome Style Sheets) that allows you to nest your CSS selectors in a way that follows the HTML structure. This helps improve the readability of your styles and makes it easier to manage related styles together.
          </p><br />

          <h2 style={{paddingBottom:"6px"}}>Benefits of Nesting</h2>
          <ul>
            <li><strong>Improved Readability:</strong> By nesting styles, you can clearly see the relationship between selectors, making it easier to understand the structure of your CSS.</li><br />
            <li><strong>Logical Grouping:</strong> Nesting helps group styles that are related to a specific component or section of your website, reducing the need for repetitive code.</li><br />
            <li><strong>Cleaner Code:</strong> It reduces the amount of code needed, as you don’t have to repeat the parent selector.</li>
          </ul><br />

          <h2 style={{paddingBottom:"6px"}}>How to Use Nesting</h2>

          <h3>Basic Example</h3>
          <p>Here’s a basic example of Sass nesting:</p>
          <pre>
            <code>
              {`.nav {
  ul {
    list-style: none;
    padding: 0;
  }

  li {
    display: inline-block;
    margin-right: 20px;

    &:hover {
      text-decoration: underline;
    }
  }
}`}
            </code>
          </pre>
          <p>
            In this example, the <code>ul</code> and <code>li</code> selectors are nested within the <code>.nav</code> class, showing that these styles are related to the navigation component. The <code>&:hover</code> selector applies styles to the <code>li</code> elements when hovered over.
          </p><br />

          <h3>Deep Nesting</h3>
          <p>You can also nest selectors multiple levels deep:</p>
          <pre>
            <code>
              {`.card {
  border: 1px solid #ccc;
  padding: 20px;

  .title {
    font-size: 1.5rem;
    color: $primary-color;
  }

  .content {
    margin-top: 10px;

    p {
      line-height: 1.6;
    }
  }
}`}
            </code>
          </pre>
          <p>
            Here, <code>.title</code> and <code>.content</code> are nested within <code>.card</code>, and <code>p</code> is nested within <code>.content</code>. This clearly indicates that <code>p</code> styles apply only to paragraphs inside the <code>.content</code> class of a <code>.card</code>.
          </p><br />

          <h2>Avoiding Over-Nesting</h2>
          <p>
            While nesting is powerful, it’s important to avoid over-nesting, which can lead to overly specific selectors that are harder to manage. As a general rule, try to limit nesting to 2-3 levels deep to maintain readability.
          </p><br />

          <h2>Example with HTML</h2>
          <p>Here’s how nesting would correspond to HTML:</p>
          <pre>
            <code>
              {`<div class="nav">
  <ul>
    <li>Home</li>
    <li>About</li>
    <li>Contact</li>
  </ul>
</div>`}
            </code>
          </pre><br />

          <h2>Summary</h2>
          <p>
            Sass nesting allows you to write more organized and maintainable styles by grouping related styles together. It enhances the clarity of your stylesheets, making them easier to read and understand. Just remember to use it judiciously to avoid overly complex selectors!
          </p>
       


              </div>
            )}

{selectedChapter === 'chapter106' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Sass @import </h1>
           
              

          <p>
            The <code>@import</code> directive in Sass (Syntactically Awesome Style Sheets) is used to include styles from one Sass file into another. This helps in organizing and managing your stylesheets by breaking them into smaller, more manageable files. Here’s a breakdown of how it works and its benefits:
          </p><br />

          <h2>How to Use <code>@import</code></h2>
          <p style={{paddingBottom:"6px"}}>You can use the <code>@import</code> directive to import other Sass or CSS files into your main stylesheet.</p>

          <h3>Basic Syntax</h3>
          <pre>
            <code>
              {`@import 'path/to/your/file';`}
            </code>
          </pre><br />

          <h2>Example</h2>
          <p>Suppose you have the following file structure:</p>
          <pre>
            <code>
              {`styles/
  ├── _variables.scss
  ├── _mixins.scss
  ├── _base.scss
  └── main.scss`}
            </code>
          </pre><br />

          <h3>_variables.scss</h3>
          <pre>
            <code>
              {`$primary-color: #3498db;
$font-stack: 'Helvetica, Arial, sans-serif';`}
            </code>
          </pre><br />

          <h3>_mixins.scss</h3>
          <pre>
            <code>
              {`@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}`}
            </code>
          </pre><br />

          <h3>_base.scss</h3>
          <pre>
            <code>
              {`body {
  font-family: $font-stack;
  color: $primary-color;
}`}
            </code>
          </pre><br />

          <h3>main.scss</h3>
          <pre>
            <code>
              {`@import 'variables';
@import 'mixins';
@import 'base';

.button {
  background-color: $primary-color;
  @include border-radius(5px);
  padding: 10px;
}`}
            </code>
          </pre><br />

          <h2 style={{paddingBottom:"6px"}}>Benefits of Using <code>@import</code></h2>
          <ul>
            <li><strong>Modularity:</strong> Organizing styles into smaller files makes it easier to maintain and update your styles. You can group related styles together, such as variables, mixins, or specific components.</li><br />
            <li><strong>Reusability:</strong> You can reuse styles across different files by importing them. This avoids duplication and keeps your styles consistent.</li><br />
            <li><strong>Improved Readability:</strong> Smaller files can be easier to read and understand than one large stylesheet.</li>
          </ul><br />

          <h2>Note on File Naming</h2>
          <p>
            When using <code>@import</code>, it's common practice to name partial files (files that are meant to be imported) with a leading underscore (e.g., <code>_variables.scss</code>). This convention indicates that the file is a partial and should not be compiled into a standalone CSS file.
          </p><br />

          <h2>Importing CSS Files</h2>
          <p>You can also use <code>@import</code> to import regular CSS files:</p>
          <pre>
            <code>
              {`@import 'styles.css';`}
            </code>
          </pre><br />

          <h2>Transition to <code>@use</code> and <code>@forward</code></h2>
          <p>
            As of Dart Sass 1.23.0, the Sass team recommends using the <code>@use</code> and <code>@forward</code> directives instead of <code>@import</code> for better modularity and namespace management. The <code>@import</code> directive is still supported, but it’s encouraged to adopt the newer directives in new projects.
          </p><br />

          <h2>Summary</h2>
          <p>
            The <code>@import</code> directive in Sass is a powerful feature that helps organize your styles by allowing you to include one stylesheet within another. This modular approach enhances maintainability, readability, and reusability in your CSS code.
          </p>
   

              </div>
            )}

{selectedChapter === 'chapter107' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Sass @maixin </h1>
           
               

    <p>
      The <code>@mixin</code> directive in Sass (Syntactically Awesome Style Sheets) is a powerful feature that allows you to create reusable blocks of styles. Mixins can include styles, as well as arguments, making it easy to apply complex styles across your stylesheets without repeating code.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>How to Use <code>@mixin</code></h2>

    <h3>Declaring a Mixin</h3>
    <p>
      You can declare a mixin using the <code>@mixin</code> directive followed by the mixin name and an optional list of parameters:
    </p>
    <pre>
      <code>
        {`@mixin mixinName($arg1, $arg2) {
  // styles go here
}`}
      </code>
    </pre><br />

    <h3>Example of a Mixin</h3>
    <p>
      Here’s a simple example of a mixin that applies a border-radius style:
    </p>
    <pre>
      <code>
        {`@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}`}
      </code>
    </pre><br />

    <h3>Using a Mixin</h3>
    <p>
      To use a mixin, you call it with the <code>@include</code> directive:
    </p>
    <pre>
      <code>
        {`.button {
  @include border-radius(10px);
  background-color: blue;
  color: white;
}`}
      </code>
    </pre><br />

    <h2 style={{paddingBottom:"6px"}}>Benefits of Using Mixins</h2>
    <ul>
      <li><strong>Reusability:</strong> Mixins allow you to write styles once and reuse them throughout your stylesheets, reducing duplication.</li><br />
      <li><strong>Parameterization:</strong> You can pass arguments to mixins, making them flexible and adaptable to different contexts.</li><br />
      <li><strong>Cleaner Code:</strong> Using mixins helps keep your code organized and easier to read, especially when applying the same styles in multiple places.</li>
    </ul><br />

    <h2>Example with Multiple Properties</h2>
    <p>You can create mixins that handle multiple styles:</p>
    <pre>
      <code>
        {`@mixin button-styles($bg-color, $text-color) {
  background-color: $bg-color;
  color: $text-color;
  padding: 10px;
  border: none;
  border-radius: 5px;
}

.primary-button {
  @include button-styles(blue, white);
}

.secondary-button {
  @include button-styles(gray, black);
}`}
      </code>
    </pre><br />

    <h2>Summary</h2>
    <p>
      The <code>@mixin</code> directive in Sass is a powerful tool for creating reusable styles. It enhances maintainability, readability, and organization in your CSS by allowing you to define a set of styles once and apply them wherever needed with customizable parameters. This approach reduces code repetition and makes it easier to manage styles across larger projects.
    </p>
  


              </div>
            )}






{selectedChapter === 'chapter108' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Sass @extend </h1>
         

    <p>
      The <code>@extend</code> directive in Sass (Syntactically Awesome Style Sheets) allows one selector to inherit the styles of another. This helps reduce duplication in your CSS by enabling the sharing of styles among selectors without repeating code.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>How to Use <code>@extend</code></h2>

    <h3>Basic Syntax</h3>
    <pre>
      <code>
        {`
// Declare styles for selectorA
.selectorA {
  // styles for selectorA
}

// Extend selectorA in selectorB
.selectorB {
  @extend .selectorA;
  // additional styles for selectorB
}`}
      </code>
    </pre><br />

    <h2>Example</h2>
    <pre>
      <code>
        {`
// Base button styles
.button {
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: blue;
  color: white;
}

// Primary button extending button styles
.primary-button {
  @extend .button;
  background-color: green;
}

// Secondary button extending button styles
.secondary-button {
  @extend .button;
  background-color: gray;
}`}
      </code>
    </pre>

    <p>
      In this example:
      <ul>
        <li>Both <code>.primary-button</code> and <code>.secondary-button</code> inherit the styles from <code>.button</code>, but each can also have additional styles.</li><br />
        <li>This reduces redundancy, as you only need to define common styles in one place.</li>
      </ul>
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Benefits of Using <code>@extend</code></h2>
    <ul>
      <li><strong>Reduce Code Duplication:</strong> By sharing styles, you can keep your stylesheets DRY (Don't Repeat Yourself), making maintenance easier.</li><br />
      <li><strong>Maintainability:</strong> If you need to change a style, you only have to do it in one place, and it will be reflected wherever the selector is extended.</li><br />
      <li><strong>Cleaner Code:</strong> It can lead to cleaner and more organized code by avoiding repetitive styles.</li>
    </ul><br />

    <h2 style={{paddingBottom:"6px"}}>Important Considerations</h2>
    <ul>
      <li><strong>Inheritance:</strong> When using <code>@extend</code>, the extended selector will also inherit all the styles of the original selector, including any pseudo-classes or nested styles. This can sometimes lead to unexpected behavior if not managed carefully.</li><br />
      <li><strong>Specificity:</strong> Be mindful of the specificity of the selectors being extended, as it may affect the order in which styles are applied.</li>
    </ul><br />

    <h2>Summary</h2>
    <p>
      The <code>@extend</code> directive in Sass is a powerful tool for sharing styles between selectors. It enhances maintainability and readability by allowing you to define common styles in one place and reuse them across multiple selectors, helping to keep your stylesheets organized and reducing duplication.
    </p>


              </div>
            )}



            

{selectedChapter === 'chapter109' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Sass String</h1>
           
                   

    <p>
      In Sass (Syntactically Awesome Style Sheets), a string is a sequence of characters that is used to represent text. Sass extends the capabilities of regular CSS, allowing for more complex stylesheets.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Key Features of Sass Strings:</h2>
    <ol>
      <li>
        <strong>Basic Strings</strong>: Strings can be created using single quotes (`'`) or double quotes (`"`). For example:
        <pre>
          <code>$font-stack: 'Helvetica, sans-serif';</code>
        </pre>
      </li><br />
      <li>
        <strong>String Interpolation</strong>: You can use <code>#{}</code> to interpolate variables or expressions within strings. This is useful for dynamically generating class names or other string values:
        <pre>
          <code>
            $base: 'main';<br />
            .#{'$base'}-header {'{'}<br />
            &nbsp;&nbsp;color: blue;<br />
            {'}'}
          </code>
        </pre>
      </li><br />
      <li>
        <strong>String Functions</strong>: Sass provides several built-in functions for manipulating strings, such as:
        <ul>
          <li><code>str-length($string)</code>: Returns the length of a string.</li><br />
          <li><code>str-index($string, $substring)</code>: Returns the index of a substring within a string.</li><br />
          <li><code>to-lower-case($string)</code> and <code>to-upper-case($string)</code>: Change the case of strings.</li>
        </ul>
      </li><br />
      <li>
        <strong>Escaping Strings</strong>: If you need to include quotes within a string, you can escape them with a backslash:
        <pre>
          <code>$quote: 'He said, "Hello!"';</code>
        </pre>
      </li><br />
      <li>
        <strong>Concatenation</strong>: You can concatenate strings using the <code>+</code> operator:
        <pre>
          <code>$greeting: 'Hello, ' + 'world!';</code>
        </pre>
      </li>
    </ol><br />

    <h2>Example:</h2>
    <p>Here’s a simple example that demonstrates some of these features:</p>
    <pre>
      <code>
        $base-color: #3498db;<br />
        $theme-name: 'light';<br /><br />
        .button {'{'}<br />
        &nbsp;&nbsp;background-color: $base-color;<br />
        &nbsp;&nbsp;content: "This is a " + $theme-name + " theme";<br />
        {'}'}
      </code>
    </pre>
    <p>
      In this example, a button style is defined, and a string is created using concatenation. This allows for more dynamic and manageable CSS.
    </p>
 


              </div>
            )}

{selectedChapter === 'chapter110' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Sass Numeric </h1>
  
    
    <p>
      In Sass (Syntactically Awesome Style Sheets), a numeric value is a type of data used to represent numbers. Sass supports various numeric operations and provides additional capabilities compared to standard CSS.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Key Features of Sass Numeric Values:</h2>
    <ol>
      <li>
        <strong>Basic Numeric Types</strong>: Numeric values in Sass can be integers or decimals. For example:
        <pre>
          <code>$width: 100px;</code><br />
          <code>$padding: 1.5em;</code>
        </pre>
      </li><br />
      <li>
        <strong>Units</strong>: Sass supports various units, including:
        <ul>
          <li><strong>Length Units</strong>: <code>px</code>, <code>em</code>, <code>rem</code>, <code>%</code>, <code>vh</code>, <code>vw</code>, etc.</li><br />
          <li><strong>Time Units</strong>: <code>s</code>, <code>ms</code>.</li><br />
          <li><strong>Angle Units</strong>: <code>deg</code>, <code>rad</code>, <code>grad</code>.</li><br />
          <li><strong>Resolution Units</strong>: <code>dpi</code>, <code>dpcm</code>, <code>dppx</code>.</li>
        </ul>
      </li><br />
      <li>
        <strong>Arithmetic Operations</strong>: You can perform arithmetic operations directly on numeric values, such as addition, subtraction, multiplication, and division:
        <pre>
          <code>$base-padding: 10px;</code><br />
          <code>$double-padding: $base-padding * 2; // 20px</code>
        </pre>
      </li><br />
      <li>
        <strong>Units Conversion</strong>: Sass automatically handles unit conversion in many cases. For example, adding pixels and percentages will yield an error, but mixing compatible units will work:
        <pre>
          <code>$half: 50%;</code><br />
          <code>$full: $half * 2; // 100%</code>
        </pre>
      </li><br />
      <li>
        <strong>Using Functions</strong>: Sass provides functions to manipulate numeric values:
        <ul>
          <li><code>abs($number)</code>: Returns the absolute value.</li><br />
          <li><code>ceil($number)</code>: Rounds up to the nearest whole number.</li><br />
          <li><code>floor($number)</code>: Rounds down to the nearest whole number.</li><br />
          <li><code>round($number)</code>: Rounds to the nearest whole number.</li>
        </ul>
      </li>
    </ol><br />

    <h2>Example:</h2>
    <p>Here's an example that demonstrates some of these features:</p>
    <pre>
      <code>
        $base-font-size: 16px;<br />
        $large-font-size: $base-font-size * 1.5; // 24px<br /><br />
        .container {'{'}<br />
        &nbsp;&nbsp;padding: $base-font-size;<br />
        &nbsp;&nbsp;font-size: $large-font-size;<br />
        {'}'}
      </code>
    </pre>
    <pre>
      <code>
        .box {'{'}<br />
        &nbsp;&nbsp;width: 100%;<br />
        &nbsp;&nbsp;height: 200px + 50px; // 250px<br />
        {'}'}
      </code>
    </pre>
    
    <p>
      In this example, numeric values are used for font sizes and dimensions, showcasing arithmetic operations and the use of units.
    </p><br />

    <h2>Conclusion</h2>
    <p>
      Sass numeric values provide a powerful way to manage and manipulate measurements in your stylesheets, making it easier to create responsive designs and maintain consistent styling throughout your application.
    </p>




              </div>
            )}

{selectedChapter === 'chapter111' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Sass List</h1>
             

    <p>
      In Sass (Syntactically Awesome Style Sheets), a list is a data type that allows you to group multiple values together. Lists can be useful for managing collections of related values, such as a series of colors, font sizes, or any other group of similar data.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Key Features of Sass Lists:</h2>
    <ol>
      <li>
        <strong>Types of Lists</strong>:
        <ul>
          <li><strong>Space-separated lists</strong>: Created using spaces between values.</li><br />
          <li><strong>Comma-separated lists</strong>: Created using commas between values.</li>
        </ul><br />
        <p>Example:</p>
        <pre>
          <code>
            {`$colors: red blue green;`}
            <br />
            {`$sizes: 12px, 14px, 16px;`}
          </code>
        </pre>
      </li><br />

      <li>
        <strong>Accessing List Items</strong>: You can access individual items in a list using the <code>nth()</code> function:
        <pre>
          <code>
            {`$colors: red blue green;`}
            <br />
            {`$first-color: nth($colors, 1); // red`}
          </code>
        </pre>
      </li><br />

      <li>
        <strong>List Functions</strong>: Sass provides several built-in functions to manipulate lists:
        <ul>
          <li><code>length($list)</code>: Returns the number of items in a list.</li><br />
          <li><code>nth($list, $n)</code>: Returns the nth item of a list.</li><br />
          <li><code>append($list, $value)</code>: Adds a value to the end of a list.</li><br />
          <li><code>join($list1, $list2)</code>: Combines two lists into one.</li>
        </ul><br />
        <p>Example:</p>
        <pre>
          <code>
            {`$colors: red blue green;`}
            <br />
            {`$number-of-colors: length($colors); // 3`}
          </code>
        </pre>
      </li><br />

      <li>
        <strong>Iterating Over Lists</strong>: You can use the <code>@for</code>, <code>@each</code>, or <code>@while</code> directives to iterate over list items:
        <pre>
          <code>
            {`$colors: red blue green;`}
            <br />
            {`@each $color in $colors {`}
            <br />
            {`  .color-#{$color} {`}
            <br />
            {`    color: $color;`}
            <br />
            {`  }`}
            <br />
            {`}`}
          </code>
        </pre>
      </li><br />

      <li>
        <strong>Lists in Mixins</strong>: You can pass lists as arguments to mixins to create flexible styles:
        <pre>
          <code>
            {`@mixin list-styles($items) {`}
            <br />
            {`  @for $i from 1 through length($items) {`}
            <br />
            {`    .item-#{nth($items, $i)} {`}
            <br />
            {`      content: nth($items, $i);`}
            <br />
            {`    }`}
            <br />
            {`  }`}
            <br />
            {`}`}
            <br />
            {`@include list-styles(red blue green);`}
          </code>
        </pre>
      </li>
    </ol><br />

    <h2>Example:</h2>
    <p>Here’s a simple example that demonstrates how to use lists in Sass:</p>
    <pre>
      <code>
        {`$font-sizes: 12px, 14px, 16px, 18px;`}
        <br />
        {`$colors: red blue green;`}
        <br /><br />
        {`.container {`}
        <br />
        {`  @each $size in $font-sizes {`}
        <br />
        {`    font-size: $size;`}
        <br />
        {`  }`}
        <br />
        <br />
        {`  @each $color in $colors {`}
        <br />
        {`    &.color-#{$color} {`}
        <br />
        {`      background-color: $color;`}
        <br />
        {`    }`}
        <br />
        {`  }`}
        <br />
        {`}`}
      </code>
    </pre>

    <p>
      In this example, the code iterates over lists of font sizes and colors to generate CSS classes dynamically.
    </p><br />

    <h2>Conclusion</h2>
    <p>
      Sass lists provide a powerful way to group and manage multiple values, making it easier to write modular and maintainable styles. By leveraging lists, you can create more flexible and dynamic stylesheets.
    </p>


              </div>
            )}





{selectedChapter === 'chapter112' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Sass Map </h1>
  

    <p>
      Sass maps are a powerful data structure in Sass (Syntactically Awesome Style Sheets) that allows you to store pairs of keys and values, similar to dictionaries or objects in programming languages. Maps are particularly useful for organizing related data, such as styles for a theme or configuration settings, enabling more modular and maintainable stylesheets.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Key Features of Sass Maps:</h2>
    <ol>
      <li>
        <strong>Creating Maps</strong>: You can create maps using parentheses and separating keys and values with colons.
        <pre>
          <code>
            {`$theme-colors: (
  primary: #3498db,
  secondary: #2ecc71,
  warning: #e74c3c
);`}
          </code>
        </pre>
      </li><br />

      <li>
        <strong>Accessing Values</strong>: Use the <code>map-get()</code> function to retrieve values by their keys.
        <pre>
          <code>
            {`$primary-color: map-get($theme-colors, primary); // #3498db`}
          </code>
        </pre>
      </li><br />

      <li>
        <strong>Adding and Removing Items</strong>: You can use <code>map-merge()</code> to add new key-value pairs or merge two maps.
        <pre>
          <code>
            {`$new-colors: map-merge($theme-colors, (info: #8e44ad));`}
          </code>
        </pre>
      </li><br />

      <li>
        <strong>Iterating Over Maps</strong>: Use the <code>@each</code> directive to iterate over the keys and values in a map.
        <pre>
          <code>
            {`@each $key, $value in $theme-colors {
  .bg-#{$key} {
    background-color: $value;
  }
}`}
          </code>
        </pre>
      </li><br />

      <li>
        <strong>Map Functions</strong>: Sass provides various built-in functions to manipulate maps, including:
        <ul>
          <li><code>map-keys($map)</code>: Returns a list of keys in the map.</li><br />
          <li><code>map-values($map)</code>: Returns a list of values in the map.</li>
        </ul>
      </li>
    </ol><br />

    <h2>Example:</h2>
    <p>Here’s an example demonstrating how to use maps in Sass:</p>
    <pre>
      <code>
        {`$theme-colors: (
  primary: #3498db,
  secondary: #2ecc71,
  warning: #e74c3c
);

.button {
  background-color: map-get($theme-colors, primary);
  color: white;

  &:hover {
    background-color: map-get($theme-colors, secondary);
  }
}

.alert {
  @each $key, $value in $theme-colors {
    &.bg-#{$key} {
      background-color: $value;
    }
  }
}`}
      </code>
    </pre><br />

    <h2>Conclusion</h2>
    <p>
      Sass maps provide an efficient way to manage sets of related data, making it easier to write reusable and maintainable styles. They help keep your code organized and can significantly simplify complex styling scenarios.
    </p>


              </div>
            )}




{selectedChapter === 'chapter113' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Sass Selector</h1>
           
    
    <p>
      Sass selectors enhance the capabilities of CSS selectors by allowing for more complex and dynamic styling. 
      Sass (Syntactically Awesome Style Sheets) introduces features like nesting, which makes it easier to write and manage styles.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Key Features of Sass Selectors:</h2>
    <ol>
      <li>
        <strong>Nesting Selectors</strong>: Sass allows you to nest selectors within one another, making the styles easier to read and maintain.
        <pre>
          <code>
{`.nav {
  ul {
    list-style: none;
  }
  li {
    display: inline;
  }
  a {
    text-decoration: none;
  }
}`}
          </code>
        </pre>
      </li><br />

      <li>
        <strong>Parent Selector (`&`)</strong>: The parent selector (`&`) lets you reference the parent selector within nested styles, making it easy to create variations.
        <pre>
          <code>
{`.button {
  color: white;
  &:hover {
    background-color: blue;
  }
  &.active {
    background-color: green;
  }
}`}
          </code>
        </pre>
      </li><br />

      <li>
        <strong>Multiple Selectors</strong>: You can define multiple selectors on a single line for shared styles.
        <pre>
          <code>
{`h1, h2, h3 {
  font-family: 'Arial', sans-serif;
}`}
          </code>
        </pre>
      </li><br />

      <li>
        <strong>Placeholder Selectors</strong>: Sass allows you to define placeholder selectors (using `%`) for styles that can be reused without generating additional CSS.
        <pre>
          <code>
{`%button-styles {
  padding: 10px;
  border: none;
}

.button {
  @extend %button-styles;
  background-color: blue;
}

.link {
  @extend %button-styles;
  background-color: transparent;
}`}
          </code>
        </pre>
      </li><br />

      <li>
        <strong>Attribute Selectors</strong>: You can use standard CSS attribute selectors directly in Sass.
        <pre>
          <code>
{`input[type="text"] {
  border: 1px solid gray;
}`}
          </code>
        </pre>
      </li>
    </ol><br />

    <h2>Example:</h2>
    <p>Here’s a complete example that demonstrates various Sass selector features:</p>
    <pre>
      <code>
{`.header {
  background-color: #f8f9fa;

  h1 {
    font-size: 24px;
    color: #333;

    &:hover {
      color: #007bff;
    }
  }
}

.button {
  @extend %button-styles;
  background-color: #28a745;

  &.danger {
    background-color: #dc3545;
  }
}`}
      </code>
    </pre><br />

    <h2>Conclusion</h2>
    <p>
      Sass selectors provide powerful tools for writing cleaner and more efficient styles. 
      The ability to nest selectors, use the parent selector, and create placeholder selectors helps streamline the development process, making your stylesheets easier to read and maintain.
    </p>
  

              </div>
            )}






{selectedChapter === 'chapter114' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Sass Introspection </h1>
           
                   
             

    <p>
      Sass introspection refers to the ability to examine and manipulate the properties and characteristics of Sass constructs at runtime. This feature is particularly useful for creating dynamic stylesheets and allows developers to gather information about Sass data types such as maps, lists, and selectors.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Key Features of Sass Introspection:</h2>
    <ol>
      <li>
        <strong>Accessing Map Keys and Values:</strong> You can use functions like <code>map-keys()</code> and <code>map-values()</code> to retrieve keys and values from maps, enabling dynamic styling based on the data structure.
      </li><br />
      <li>
        <strong>Checking Data Types:</strong> Sass provides functions like <code>type-of()</code> to determine the data type of a variable. This can be helpful for conditional styling based on the type of data.
      </li><br />
      <li>
        <strong>Getting Lengths:</strong> Functions such as <code>length()</code> can be used to find out how many items are in a list or map, making it easier to iterate through data structures.
      </li><br />
      <li>
        <strong>Combining and Manipulating Data:</strong> Introspection functions allow for merging, appending, and dynamically adjusting lists and maps based on the properties of existing values.
      </li>
    </ol><br />

    <h2>Example of Sass Introspection:</h2>
    <pre>
      <code>
        {`$theme-colors: (
  primary: #3498db,
  secondary: #2ecc71,
  warning: #e74c3c
);

$keys: map-keys($theme-colors); // Returns a list of keys
$values: map-values($theme-colors); // Returns a list of values
$number-of-colors: length($theme-colors); // Returns the number of colors

.button {
  @for $i from 1 through $number-of-colors {
    $color: nth($values, $i);
    background-color: $color;

    &.color-#{nth($keys, $i)} {
      color: white;
    }
  }
}`}
      </code>
    </pre><br />

    <h2>Conclusion</h2>
    <p>
      Sass introspection enhances the flexibility of your stylesheets by enabling you to gather information about your data structures and make decisions based on that information. This capability can lead to more modular and maintainable styles, allowing for dynamic adjustments based on the properties of your Sass variables.
    </p>

              </div>
            )}





{selectedChapter === 'chapter115' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Sass Color </h1>
           
           

    <p>
      Sass colors refer to the ways you can define and manipulate colors in Sass (Syntactically Awesome Style Sheets). Sass enhances the color functionality available in standard CSS, making it easier to work with colors in your stylesheets. Here are some key features and functionalities related to colors in Sass:
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Key Features of Sass Colors:</h2>

    <ol>
      <li>
        <strong>Color Formats:</strong>
        <ul>
          <li><strong>Hexadecimal</strong>: <code>#3498db</code></li><br />
          <li><strong>RGB</strong>: <code>rgb(52, 152, 219)</code></li><br />
          <li><strong>RGBA</strong>: <code>rgba(52, 152, 219, 0.5)</code> (includes alpha for transparency)</li><br />
          <li><strong>HSL</strong>: <code>hsl(204, 70%, 53%)</code></li><br />
          <li><strong>HSLA</strong>: <code>hsla(204, 70%, 53%, 0.5)</code></li>
        </ul>
      </li><br />

      <li>
        <strong>Color Functions:</strong>
        <ul>
          <li><code>lighten($color, $amount)</code>: Lightens a color by a specified amount.</li><br />
          <li><code>darken($color, $amount)</code>: Darkens a color by a specified amount.</li><br />
          <li><code>saturate($color, $amount)</code>: Increases the saturation of a color.</li><br />
          <li><code>desaturate($color, $amount)</code>: Decreases the saturation of a color.</li><br />
          <li><code>mix($color1, $color2, $weight)</code>: Mixes two colors together at a specified weight.</li><br />
          <li><code>adjust-hue($color, $degrees)</code>: Changes the hue of a color.</li>
        </ul>
      </li><br />

      <li>
        <strong>Color Manipulation:</strong>
        <p>You can create dynamic color schemes by manipulating colors based on your design needs. For example:</p>
        <pre>
          <code>
            $primary-color: #3498db;<br />
            $light-primary: lighten($primary-color, 20%);<br />
            $dark-primary: darken($primary-color, 20%);
          </code>
        </pre>
      </li><br />

      <li>
        <strong>Using Color Functions in Styles:</strong>
        <p>You can directly use color functions in your styles:</p>
        <pre>
          <code>
            .button {'{'}<br />
            &nbsp;&nbsp;background-color: $primary-color;<br />
            &nbsp;&nbsp;color: white;<br />
            &nbsp;&nbsp;&:hover {'{'}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;background-color: darken($primary-color, 10%);<br />
            &nbsp;&nbsp;{'}'}<br />
            {'}'}
          </code>
        </pre>
      </li><br />

      <li>
        <strong>Color Variables:</strong>
        <p>You can define color variables to maintain consistency across your stylesheets:</p>
        <pre>
          <code>
            $color-success: #2ecc71;<br />
            $color-warning: #e74c3c;<br />
            <br />
            .alert-success {'{'}<br />
            &nbsp;&nbsp;background-color: $color-success;<br />
            {'}'}
          </code>
        </pre>
      </li>
    </ol><br />

    <h2>Example of Sass Color Usage:</h2>
    <p>Here’s a simple example demonstrating how to use colors in Sass:</p>
    <pre>
      <code>
        $base-color: #3498db;<br />
        <br />
        .header {'{'}<br />
        &nbsp;&nbsp;background-color: $base-color;<br />
        &nbsp;&nbsp;color: white;<br />
        <br />
        &nbsp;&nbsp;&:hover {'{'}<br />
        &nbsp;&nbsp;&nbsp;&nbsp;background-color: darken($base-color, 15%);<br />
        &nbsp;&nbsp;{'}'}<br />
        {'}'}
      </code>
    </pre><br />

    <p>
      <strong>.button</strong> example:
    </p>
    <pre>
      <code>
        .button {'{'}<br />
        &nbsp;&nbsp;background-color: lighten($base-color, 10%);<br />
        &nbsp;&nbsp;border: 1px solid $base-color;<br />
        <br />
        &nbsp;&nbsp;&:hover {'{'}<br />
        &nbsp;&nbsp;&nbsp;&nbsp;background-color: $base-color;<br />
        &nbsp;&nbsp;&nbsp;&nbsp;color: white;<br />
        &nbsp;&nbsp;{'}'}<br />
        {'}'}
      </code>
    </pre><br />

    <h2>Conclusion:</h2>
    <p>
      Sass colors provide powerful tools for defining and manipulating colors in your stylesheets. By using variables and functions, you can create dynamic and maintainable styles that adapt easily to design changes. This enhances both the aesthetic and functional aspects of your web design.
    </p>
  </div>
)}








{selectedChapter === 'chapter7' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Templates </h1>
           
          
   

                <p>
      In CSS, "templates" typically refer to a structured way to design layouts and styles using CSS. While CSS itself does not have a built-in concept of "templates," the term can relate to several concepts and methodologies used to create reusable styles and layouts. Here are some common interpretations:
    </p><br />

    <h2>1. CSS Frameworks</h2>
    <p>
      Many CSS frameworks (like Bootstrap, Tailwind CSS, or Bulma) provide predefined styles and components that act as templates for building layouts quickly. These frameworks come with utility classes or components that you can use to create responsive designs without starting from scratch.
    </p><br />

    <h2>2. CSS Grid and Flexbox</h2>
    <p style={{paddingBottom:""}}>
      CSS Grid and Flexbox can be considered as templating tools for layout. They allow developers to define structured layouts by specifying how elements should be arranged on a page. For example:
    </p>
    
    <h3>CSS Grid</h3>
    <pre>
      <code>
        {`.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}`}
      </code>
    </pre><br />

    <h3>Flexbox</h3>
    <pre>
      <code>
        {`.flex-container {
  display: flex;
  justify-content: space-between;
}`}
      </code>
    </pre><br />

    <h2>3. CSS Preprocessors</h2>
    <p>
      Tools like Sass and Less allow you to create "templates" for styles using mixins, functions, and variables. This makes it easier to maintain and reuse styles throughout your project. For example, you can create a button template:
    </p>
    
    <pre>
      <code>
        {`@mixin button($color) {
  background-color: $color;
  border: none;
  padding: 10px 15px;
  color: white;
}

.primary-button {
  @include button(#3498db);
}

.secondary-button {
  @include button(#2ecc71);
}`}
      </code>
    </pre><br />

    <h2>4. CSS Variables (Custom Properties)</h2>
    <p>
      CSS variables allow you to define reusable values that can be used throughout your styles, acting like templates for maintaining consistent styling:
    </p>
    
    <pre>
      <code>
        {`:root {
  --primary-color: #3498db;
}

.button {
  background-color: var(--primary-color);
}`}
      </code>
    </pre><br />

    <h2>5. HTML Templates with CSS</h2>
    <p>
      Sometimes, "templates" can refer to complete HTML structures styled with CSS. For instance, you might create a page layout with specific sections that can be reused across different pages:
    </p>
    
    <pre>
      <code>
        {`<div class="template-header">Header Content</div>
<div class="template-content">Main Content</div>
<div class="template-footer">Footer Content</div>`}
      </code>
    </pre><br />

    <h2>Conclusion</h2>
    <p>
      While CSS itself does not have a strict definition for "templates," the term encompasses various techniques and tools used to create reusable and maintainable styles and layouts. This includes CSS frameworks, layout systems (like Grid and Flexbox), preprocessors, CSS variables, and organized HTML structures.
    </p>



              </div>
            )}






{selectedChapter === 'chapter5' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Examples</h1>
       
    <p>
      In the context of CSS, "examples" usually refer to practical demonstrations or snippets that illustrate how to use specific CSS features, properties, or techniques. Here are some common examples across different CSS concepts:
    </p><br />

    <h2>1. Basic Styling</h2>
    <p><strong>Example:</strong> Changing text color and background.</p>
    <pre>
      <code>
        {`body {
  background-color: #f0f0f0;
  color: #333;
  font-family: Arial, sans-serif;
}`}
      </code>
    </pre><br />

    <h2>2. Flexbox Layout</h2>
    <p><strong>Example:</strong> Creating a simple flexbox layout.</p>
    <pre>
      <code>
        {`.container {
  display: flex;
  justify-content: space-between;
}

.item {
  flex: 1;
  padding: 10px;
}`}
      </code>
    </pre><br />

    <h2>3. CSS Grid Layout</h2>
    <p><strong>Example:</strong> Defining a grid layout with rows and columns.</p>
    <pre>
      <code>
        {`.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}`}
      </code>
    </pre><br />

    <h2>4. Media Queries</h2>
    <p><strong>Example:</strong> Making a responsive design.</p>
    <pre>
      <code>
        {`@media (max-width: 600px) {
  body {
    background-color: #ccc;
  }
}`}
      </code>
    </pre><br />

    <h2>5. Hover Effects</h2>
    <p><strong>Example:</strong> Changing button style on hover.</p>
    <pre>
      <code>
        {`.button {
  background-color: #3498db;
  color: white;
  padding: 10px 20px;
  border: none;
}

.button:hover {
  background-color: #2980b9;
}`}
      </code>
    </pre><br />

    <h2>6. Custom Properties (CSS Variables)</h2>
    <p><strong>Example:</strong> Using CSS variables for theming.</p>
    <pre>
      <code>
        {`:root {
  --main-color: #3498db;
}

.header {
  background-color: var(--main-color);
}`}
      </code>
    </pre><br />

    <h2>7. Transitions</h2>
    <p><strong>Example:</strong> Smooth transitions for hover effects.</p>
    <pre>
      <code>
        {`.box {
  width: 100px;
  height: 100px;
  background-color: #3498db;
  transition: background-color 0.3s;
}

.box:hover {
  background-color: #2980b9;
}`}
      </code>
    </pre><br />

    <h2>8. Keyframe Animations</h2>
    <p><strong>Example:</strong> Creating a simple animation.</p>
    <pre>
      <code>
        {`@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100px);
  }
}

.animated {
  animation: slide 1s ease-in-out;
}`}
      </code>
    </pre><br />

    <h2>Conclusion</h2>
    <p>
      These examples illustrate how to apply CSS in various scenarios, from basic styling to advanced layout techniques. They serve as a foundation for building more complex styles and layouts in web development.
    </p>


              </div>
            )}




{selectedChapter === 'chapter8' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Editor</h1>
    

    <p>
      In the context of CSS, "editor" typically refers to a code editor or integrated development environment (IDE) where developers write and edit their CSS code. These editors provide various features that enhance the coding experience. Here are some common features and types of editors used for CSS development:
    </p><br />

    <h2>1. Code Editors</h2>
    <ul>
      <li>
        <strong>Text Editors:</strong> Simple text editors (like Notepad or TextEdit) can be used for writing CSS, but they lack advanced features.
      </li><br />
      <li>
        <strong>Code Editors:</strong> More sophisticated editors like Visual Studio Code, Sublime Text, or Atom offer syntax highlighting, code completion, and other helpful tools.
      </li><br />
    </ul>

    <h2>2. Integrated Development Environments (IDEs)</h2>
    <p>
      IDEs like WebStorm or Eclipse provide a complete development environment, including tools for CSS, HTML, JavaScript, and more. They offer debugging, version control, and project management features.
    </p><br />

    <h2>3. Features of CSS Editors</h2>
    <ul>
      <li><strong>Syntax Highlighting:</strong> Color-coding for different CSS syntax elements makes it easier to read and identify code.</li><br />
      <li><strong>Auto-Completion:</strong> Suggestions for properties and values help speed up coding and reduce errors.</li><br />
      <li><strong>Linting:</strong> Real-time error detection and suggestions help maintain code quality.</li><br />
      <li><strong>Live Preview:</strong> Some editors allow you to see changes in real-time as you edit CSS, which is helpful for rapid development.</li><br />
      <li><strong>Extensions and Plugins:</strong> Many editors support extensions that enhance functionality, like adding CSS frameworks or preprocessors.</li>
    </ul><br />

    <h2>4. Online Editors</h2>
    <p>
      Websites like CodePen, JSFiddle, and CodeSandbox allow you to write and share CSS code online. They often include a live preview feature, making it easy to experiment with styles.
    </p><br />

    <h2>Conclusion</h2>
    <p>
      Using a good editor can significantly improve your productivity and make writing CSS easier and more efficient. The right choice of editor often depends on personal preference, project requirements, and specific features needed for development.
    </p>
 

              </div>
            )}





{selectedChapter === 'chapter6' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Snippets </h1>
             
  
    <p>
      In the context of CSS, "snippets" refer to small blocks of reusable code that can be inserted into stylesheets or development environments to save time and enhance productivity. These snippets can consist of commonly used CSS rules, layouts, or patterns, making it easier for developers to implement frequently used styles without having to write them from scratch each time.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Key Points about CSS Snippets:</h2>
    <ul>
      <li><strong>Reusability</strong>: Snippets are designed to be reused across different projects, which helps maintain consistency in styling.</li><br />
      <li><strong>Efficiency</strong>: By using snippets, developers can speed up their workflow, reducing the amount of code they need to write manually.</li><br />
      <li><strong>Common Use Cases</strong>:
        <ul><br />
          <li><strong>Buttons</strong>: Styles for common button types.</li><br />
          <li><strong>Grid Layouts</strong>: CSS rules for setting up grid layouts.</li><br />
          <li><strong>Media Queries</strong>: Standard breakpoints for responsive design.</li><br />
          <li><strong>Transitions and Animations</strong>: Predefined animations for hover effects or loading indicators.</li>
        </ul>
      </li><br />
      <li><strong>Snippet Managers</strong>: Many code editors and IDEs support snippet management, allowing developers to save and easily access their snippets. For example, Visual Studio Code has a built-in snippets feature where you can create custom snippets.</li><br />
      <li><strong>Online Resources</strong>: There are many online repositories and libraries where developers share CSS snippets, such as CodePen, GitHub Gists, or CSS Tricks.</li>
    </ul><br />

    <h2>Example of a CSS Snippet:</h2>
    <pre>
      <code>
        {`.button {
  background-color: #3498db;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.button:hover {
  background-color: #2980b9;
}`}
      </code>
    </pre><br />

    <p>
      This button snippet defines a common style for buttons, including a hover effect, making it easy to apply the same style throughout a project.
    </p>


                   

              </div>
            )}






{selectedChapter === 'chapter116' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Quiz </h1>
           
       
    
    <p>
      In the context of CSS, a "quiz" typically refers to an interactive assessment or test designed to evaluate a person's knowledge and understanding of CSS concepts, properties, and techniques. Quizzes can cover a range of topics, including:
    </p><br />

    <ul>
      <li><strong>CSS Basics</strong>: Questions about selectors, properties, and values.</li><br />
      <li><strong>Box Model</strong>: Understanding how margins, borders, padding, and content relate to each other.</li><br />
      <li><strong>Layout Techniques</strong>: Questions on Flexbox, Grid, and positioning.</li><br />
      <li><strong>Responsive Design</strong>: Concepts related to media queries and mobile-first design.</li><br />
      <li><strong>Advanced Features</strong>: Topics like animations, transitions, and CSS preprocessors (like Sass).</li>
    </ul><br />

    <h3>Purpose of CSS Quizzes</h3>
    <ul>
      <li><strong>Learning</strong>: Quizzes help reinforce learning and assess comprehension.</li><br />
      <li><strong>Practice</strong>: They provide practical scenarios that require applying CSS knowledge.</li><br />
      <li><strong>Skill Assessment</strong>: Quizzes can help gauge readiness for real-world projects or job interviews.</li>
    </ul><br />

    <h3>Format</h3>
    <ul>
      <li>Quizzes can be multiple-choice, true/false, or short answer.</li><br />
      <li>They may include code snippets that require debugging or completion.</li>
    </ul><br />

    <h3>Example Quiz Questions</h3> <br />
    <ol>
      <li>
        <strong>What does the `display` property do?</strong><br />
        <ul>
          <li>a) Changes the size of an element</li><br />
          <li>b) Defines how an element is displayed on the page</li><br />
          <li>c) Sets the background color of an element</li>
        </ul>
      </li><br />
      <li>
        <strong>Which CSS property is used to change the text color?</strong><br />
        <ul>
          <li>a) font-color</li><br />
          <li>b) color</li><br />
          <li>c) text-color</li>
        </ul>
      </li><br />
      <li>
        <strong>How do you center an element using Flexbox?</strong>
        <ul>
          <li>a) `justify-content: center;`</li>
          <li>b) `align-items: center;`</li>
          <li>c) Both a and b</li>
        </ul>
      </li>
    </ol><br />

    <h3>Conclusion</h3>
    <p>
      CSS quizzes are a valuable tool for both learners and experienced developers to test their knowledge and stay updated on best practices and new features in CSS.
    </p>
 


              </div>
            )}








{selectedChapter === 'chapter117' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}> Exercises</h1>
           

    <p>
      In the context of CSS, "exercises" refer to practical tasks or challenges designed to help learners apply their knowledge of CSS concepts and improve their skills. These exercises often involve writing CSS code to achieve specific styling goals or layouts. Here are some common types of CSS exercises:
    </p><br />

    <h2>Types of CSS Exercises</h2>

    <ol>
      <li>
        <strong>Basic Styling Exercises:</strong>
        <ul>
          <li>Change the background color of a webpage.</li><br />
          <li>Style headings with different fonts and sizes.</li>
        </ul>
      </li><br />
      <li>
        <strong>Box Model Challenges:</strong>
        <ul>
          <li>Create a layout that demonstrates the box model with padding, borders, and margins.</li><br />
          <li>Adjust the spacing of elements using margin and padding properties.</li>
        </ul>
      </li><br />
      <li>
        <strong>Flexbox Layouts:</strong>
        <ul>
          <li>Build a navigation bar using Flexbox.</li><br />
          <li>Create a card layout that aligns items using Flexbox properties.</li>
        </ul>
      </li><br />
      <li>
        <strong>Grid Layouts:</strong>
        <ul>
          <li>Design a photo gallery using CSS Grid.</li><br />
          <li>Create a responsive grid layout that adjusts based on screen size.</li>
        </ul>
      </li><br />
      <li>
        <strong>Responsive Design:</strong>
        <ul>
          <li>Implement media queries to make a layout responsive.</li><br />
          <li>Create a mobile-first design that adapts to larger screens.</li>
        </ul>
      </li><br />
      <li>
        <strong>Transitions and Animations:</strong>
        <ul>
          <li>Add hover effects to buttons using transitions.</li><br />
          <li>Create simple animations for elements when they appear on the page.</li>
        </ul>
      </li><br />
      <li>
        <strong>Real-World Projects:</strong>
        <ul>
          <li>Recreate a popular website’s layout to practice various CSS techniques.</li><br />
          <li>Build a small webpage from scratch using HTML and CSS.</li>
        </ul>
      </li>
    </ol><br />

    <h2>Purpose of CSS Exercises</h2>
    <ul>
      <li><strong>Reinforcement:</strong> Exercises help reinforce theoretical concepts through practical application.</li><br />
      <li><strong>Skill Development:</strong> They provide hands-on experience, which is crucial for mastering CSS.</li><br />
      <li><strong>Problem-Solving:</strong> Exercises encourage critical thinking and problem-solving skills.</li>
    </ul><br />

    <h2>Conclusion</h2>
    <p>
      CSS exercises are an effective way for learners to deepen their understanding of CSS and gain confidence in their styling abilities. Whether through structured tasks or creative projects, these exercises help bridge the gap between theory and real-world application.
    </p>



              </div>
            )}






{selectedChapter === 'chapter118' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Interview Prep </h1>
           
    
    <p>
      In the context of CSS, a "website" refers to a collection of web pages styled using Cascading Style Sheets (CSS) to enhance their appearance and layout. CSS plays a crucial role in web development by controlling the visual presentation of a website and separating content (HTML) from styling. Here are some key aspects of how CSS relates to websites:
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Key Aspects of CSS in Websites:</h2>

    <ol>
      <li>
        <strong>Styling and Layout:</strong>
        <ul>
          <li><strong>Visual Design:</strong> CSS is used to define colors, fonts, spacing, and other visual elements to create an attractive design.</li><br />
          <li><strong>Layout Techniques:</strong> Methods like Flexbox and Grid allow developers to arrange elements effectively, enabling responsive designs that adapt to various screen sizes.</li>
        </ul>
      </li><br />

      <li>
        <strong>Responsive Design:</strong>
        <ul>
          <li><strong>Media Queries:</strong> CSS enables the creation of designs that adjust layouts and styles based on the viewport size, ensuring usability on desktops, tablets, and smartphones.</li>
        </ul>
      </li><br />

      <li>
        <strong>Themes and Customization:</strong>
        <ul>
          <li><strong>Consistent Styling:</strong> CSS allows for uniform styles across all pages of a website, typically using a single stylesheet linked to multiple HTML files.</li><br />
          <li><strong>Theming:</strong> CSS variables (custom properties) facilitate the creation of themes that can be easily modified for a cohesive look.</li>
        </ul>
      </li><br />

      <li>
        <strong>Animations and Transitions:</strong>
        <ul>
          <li>CSS can add animations and transitions, enhancing user interactions and overall experience.</li>
        </ul>
      </li><br />

      <li>
        <strong>Performance:</strong>
        <ul>
          <li>Efficient CSS contributes to faster loading times and better performance. Practices like minimizing CSS files and optimizing styles improve user experiences.</li>
        </ul>
      </li><br />

      <li>
        <strong>Frameworks and Libraries:</strong>
        <ul>
          <li>Many websites utilize CSS frameworks (such as Bootstrap or Tailwind CSS) to streamline development and ensure modern, responsive designs without extensive custom CSS.</li>
        </ul>
      </li>
    </ol><br />

    <h2>Conclusion</h2>
    <p>
      CSS is essential for creating visually appealing and user-friendly websites. Mastering CSS is crucial for web developers aiming to build effective and engaging web experiences.
    </p>


              </div>
            )}





{selectedChapter === 'chapter119' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Bootcamp </h1>
           
       

    <p>
      In the context of CSS and web development, a "bootcamp" refers to an intensive training program designed to teach individuals the essential skills needed to become proficient in web development, including CSS. These programs typically focus on hands-on learning and practical application of coding skills.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Key Features of CSS Bootcamps:</h2>

    <ol>
      <li>
        <strong>Structured Curriculum:</strong>
        <p>
          Bootcamps usually offer a well-defined curriculum that covers foundational topics in CSS, such as selectors, properties, box model, layout techniques (like Flexbox and Grid), responsive design, and animations.
        </p>
      </li><br />
      <li>
        <strong>Hands-On Projects:</strong>
        <p>
          Participants often work on real-world projects that allow them to apply their CSS skills. This practical experience is crucial for building a portfolio.
        </p>
      </li><br />
      <li>
        <strong>Mentorship and Support:</strong>
        <p>
          Many bootcamps provide mentorship from experienced developers, offering guidance and feedback on projects and coding practices.
        </p>
      </li><br />
      <li>
        <strong>Collaborative Learning:</strong>
        <p>
          Students often work in groups, fostering collaboration and learning from peers, which can enhance the learning experience.
        </p>
      </li><br />
      <li>
        <strong>Career Preparation:</strong>
        <p>
          Bootcamps typically include job placement assistance, resume reviews, and interview preparation, helping graduates transition into the workforce.
        </p>
      </li><br />
      <li>
        <strong>Duration:</strong>
        <p>
          Bootcamps can vary in length but usually last from a few weeks to several months, depending on the intensity and depth of the program.
        </p>
      </li>
    </ol><br />

    <h2>Conclusion</h2>
    <p>
      CSS bootcamps are an effective way for individuals to quickly gain the skills necessary for a career in web development. They emphasize practical learning and often help students build the confidence needed to start working in the field.
    </p>

              </div>
            )}







{selectedChapter === 'chapter9' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Reference </h1>
           
            

    <p>
      In the context of CSS, a "reference" typically refers to various resources or materials that provide guidance, examples, or documentation related to CSS. Here are some key points about references in CSS:
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Types of CSS References:</h2>
    <ol>
      <li>
        <strong>Documentation:</strong>
        <ul>
          <li>
            <a href="https://developer.mozilla.org/en-US/docs/Web/CSS" target="_blank" rel="noopener noreferrer">
              MDN Web Docs
            </a>: A comprehensive resource for web technologies, including detailed CSS documentation, examples, and best practices.
          </li><br />
          <li>
            <a href="https://www.w3.org/Style/CSS/specs.en.html" target="_blank" rel="noopener noreferrer">
              W3C Specifications
            </a>: The World Wide Web Consortium (W3C) publishes formal specifications for CSS, outlining standards and features.
          </li>
        </ul>
      </li><br />
      <li>
        <strong>Books and Guides:</strong> Books that focus on CSS, web design, and development provide structured information and insights into best practices and advanced techniques.
      </li><br />
      <li>
        <strong>Online Tutorials:</strong> Websites like <a href="https://css-tricks.com" target="_blank" rel="noopener noreferrer">CSS-Tricks</a>, <a href="https://www.smashingmagazine.com" target="_blank" rel="noopener noreferrer">Smashing Magazine</a>, and various coding platforms offer tutorials, articles, and examples on CSS topics.
      </li><br />
      <li>
        <strong>CSS Framework Documentation:</strong> Frameworks like <a href="https://getbootstrap.com" target="_blank" rel="noopener noreferrer">Bootstrap</a>, <a href="https://tailwindcss.com" target="_blank" rel="noopener noreferrer">Tailwind CSS</a>, and <a href="https://get.foundation/sites/docs/" target="_blank" rel="noopener noreferrer">Foundation</a> have their own documentation that acts as a reference for using their classes and components effectively.
      </li><br />
      <li>
        <strong>Code Snippet Repositories:</strong> Platforms like <a href="https://codepen.io" target="_blank" rel="noopener noreferrer">CodePen</a>, <a href="https://gist.github.com" target="_blank" rel="noopener noreferrer">GitHub Gists</a>, or <a href="http://www.csszengarden.com" target="_blank" rel="noopener noreferrer">CSS Zen Garden</a> allow users to share CSS snippets, providing practical examples for reference.
      </li><br />
      <li>
        <strong>Browser Developer Tools:</strong> Most modern browsers come with built-in developer tools that allow you to inspect CSS styles applied to elements, providing a practical way to reference and test styles.
      </li>
    </ol><br />

    <h2 style={{paddingBottom:"6px"}}>Purpose of CSS References:</h2>
    <ul>
      <li><strong>Learning and Improvement:</strong> References are essential for beginners to learn CSS and for experienced developers to stay updated on new features.</li><br />
      <li><strong>Best Practices:</strong> They help ensure adherence to best practices and standards in web development.</li><br />
      <li><strong>Problem Solving:</strong> References can provide solutions to specific problems or challenges faced when working with CSS.</li>
    </ul><br />

    <h2>Conclusion</h2>
    <p>
      Using CSS references effectively can greatly enhance a developer's ability to create well-structured and visually appealing web designs. They serve as a vital resource for learning, troubleshooting, and staying current with the latest trends and standards in CSS.
    </p>
  </div>


            )}

{selectedChapter === 'chapter10' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Selectors</h1>
           
           

    <p>
      In CSS, a <strong>selector</strong> is a pattern used to select the elements you want to style. 
      Selectors determine which HTML elements are targeted by a set of CSS rules. Here's a breakdown of the main types of selectors:
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Types of Selectors</h2>

    <ol>
      <li>
        <strong>Universal Selector</strong> (`*`)<br />
        Selects all elements on a page.<br />
        <code>* &#123; margin: 0; padding: 0; &#125;</code>
      </li><br />

      <li>
        <strong>Type Selector</strong> (Element Selector)<br />
        Selects all instances of a specific HTML element.<br />
        <code>p &#123; color: blue; &#125;</code> (targets all <code>&lt;p&gt;</code> elements)
      </li><br />

      <li>
        <strong>Class Selector</strong> (`.`)<br />
        Selects elements with a specific class attribute.<br />
        <code>.example &#123; font-size: 16px; &#125;</code> (targets all elements with <code>class="example"</code>)
      </li><br />

      <li>
        <strong>ID Selector</strong> (`#`)<br />
        Selects a single element with a specific ID.<br />
        <code>#header &#123; background-color: gray; &#125;</code> (targets the element with <code>id="header"</code>)
      </li><br />

      <li>
        <strong>Attribute Selector</strong><br />
        Selects elements based on their attributes.<br />
        <code>input[type="text"] &#123; border: 1px solid black; &#125;</code> (targets <code>&lt;input&gt;</code> elements with <code>type="text"</code>)
      </li><br />

      <li>
        <strong>Descendant Selector</strong> (Space)<br />
        Selects elements that are descendants of a specified element.<br />
        <code>div p &#123; color: red; &#125;</code> (targets all <code>&lt;p&gt;</code> elements inside <code>&lt;div&gt;</code> elements)
      </li><br />

      <li>
        <strong>Child Selector</strong> {"(`>`)"}<br />
        Selects elements that are direct children of a specified element.<br />
        <code>ul {">"} li &#123; list-style-type: none; &#125;</code> (targets <code>&lt;li&gt;</code> elements that are direct children of a <code>&lt;ul&gt;</code>)
      </li><br />

      <li>
        <strong>Adjacent Sibling Selector</strong> (`+`)<br />
        Selects an element that is immediately following a specified element.<br />
        <code>h1 + p &#123; margin-top: 0; &#125;</code> (targets the first <code>&lt;p&gt;</code> that follows an <code>&lt;h1&gt;</code>)
      </li><br />

      <li>
        <strong>General Sibling Selector</strong> (`~`)<br />
        Selects all siblings that follow a specified element.<br />
        <code>h1 ~ p &#123; color: green; &#125;</code> (targets all <code>&lt;p&gt;</code> elements that follow an <code>&lt;h1&gt;</code>)
      </li>
    </ol><br />

    <h2>Conclusion</h2>
    <p>
      Selectors are fundamental in CSS as they allow developers to apply styles selectively and efficiently. 
      Mastering different types of selectors enables you to create well-structured and maintainable stylesheets.
    </p>
  

              </div>
            )}

{selectedChapter === 'chapter123' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Functions </h1>
           
          <p>
            In CSS, "functions" are used to perform calculations or generate values dynamically. Here are some common CSS functions:
          </p><br />
          <ol>
            <li>
              <strong>calc()</strong>: Allows you to perform calculations to set CSS property values. For example:
              <pre>
                <code>width: calc(100% - 20px);</code>
              </pre>
            </li><br />
            <li>
              <strong>var()</strong>: Used to insert the value of a CSS variable (custom property). For example:
              <pre>
                <code>--main-color: blue;</code>
                <code>color: var(--main-color);</code>
              </pre>
            </li><br />
            <li>
              <strong>rgb() / rgba()</strong>: Used to define colors in the RGB color model. The <code>rgba()</code> function includes an alpha channel for transparency:
              <pre>
                <code>background-color: rgb(255, 0, 0); /* Red */</code>
                <code>background-color: rgba(255, 0, 0, 0.5); /* Semi-transparent red */</code>
              </pre>
            </li><br />
            <li>
              <strong>hsl() / hsla()</strong>: Similar to RGB, but uses the Hue, Saturation, and Lightness model. <code>hsla()</code> includes an alpha channel:
              <pre>
                <code>background-color: hsl(120, 100%, 50%); /* Green */</code>
                <code>background-color: hsla(120, 100%, 50%, 0.5); /* Semi-transparent green */</code>
              </pre>
            </li><br />
            <li>
              <strong>url()</strong>: Used to specify a URL for images or other resources:
              <pre>
                <code>background-image: url('image.jpg');</code>
              </pre>
            </li><br />
            <li>
              <strong>linear-gradient() / radial-gradient()</strong>: Used to create gradient backgrounds:
              <pre>
                <code>background: linear-gradient(to right, red, blue);</code>
              </pre>
            </li><br />
            <li>
              <strong>transform()</strong>: Used in transformations like scaling, rotating, or translating elements:
              <pre>
                <code>transform: rotate(45deg);</code>
              </pre>
            </li>
          </ol><br />
          <p>
            These functions help to create dynamic styles, enhance flexibility, and improve maintainability in your CSS.
          </p>
 
 

              </div>
            )}

{selectedChapter === 'chapter124' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Reference Aural </h1>
           
             
          <p>
            In CSS, "reference aural" was part of the CSS3 specification aimed at enabling styles specifically for aural (speech) media. Although not widely supported or used, it provided properties to enhance the presentation of content for auditory users, such as those using screen readers or other speech synthesis tools.
          </p><br />

          <h2 style={{paddingBottom:"6px"}}>Key Properties</h2>
          <ul>
            <li>
              <strong>voice-family</strong>: Specifies the voice or type of voice to be used for speech synthesis.
              <pre>
                <code>voice-family: "Alice";</code>
              </pre>
            </li><br />
            <li>
              <strong>stress</strong>: Defines the emphasis or stress level of words.
              <pre>
                <code>stress: 80; /* 0-100 scale */</code>
              </pre>
            </li><br />
            <li>
              <strong>intonation</strong>: Specifies the intonation of speech.
              <pre>
                <code>intonation: 50; /* 0-100 scale */</code>
              </pre>
            </li><br />
            <li>
              <strong>speak</strong>: Controls whether the element is spoken or not.
              <pre>
                <code>speak: normal; /* or none, or spell-out */</code>
              </pre>
            </li><br />
            <li>
              <strong>speak-header</strong>: Defines how headers should be spoken.
              <pre>
                <code>speak-header: once;</code>
              </pre>
            </li><br />
            <li>
              <strong>speak-numeral</strong>: Specifies how numbers should be spoken.
              <pre>
                <code>speak-numeral: continuous; /* or auto, or digits */</code>
              </pre>
            </li>
          </ul><br />

          <h2>Example</h2>
          <p>
            Although aural styles are not commonly implemented, here’s how you might use them:
          </p>
          <pre>
            <code>
              {'@media speech {'}
              <br />
              {'  h1 {'}
              <br />
              {'    voice-family: "Alice";'}
              <br />
              {'    stress: 80;'}
              <br />
              {'    speak: normal;'}
              <br />
              {'  }'}
              <br />
              <br />
              {'  p {'}
              <br />
              {'    speak: normal;'}
              <br />
              {'    speak-numeral: continuous;'}
              <br />
              {'  }'}
              <br />
              {'}'}
            </code>
          </pre><br />

          <h2>Current Relevance</h2>
          <p>
            While the concept of aural styles exists, modern web development largely focuses on visual and responsive design. The aural features are not widely supported across browsers and devices, making them less relevant today. Instead, developers emphasize accessibility through semantic HTML, ARIA roles, and best practices for screen reader compatibility.
          </p>
          <p>
            If you're interested in enhancing accessibility, consider using these practices rather than relying on aural CSS.
          </p>
        


              </div>
            )}

{selectedChapter === 'chapter40' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Web Safe-Fonts </h1>
          
          <p>
            Web safe fonts are typefaces that are widely available across different operating systems and web browsers. Using these fonts helps ensure that your text appears consistently for all users, regardless of their device or platform. Here are some commonly used web safe fonts in CSS:
          </p><br />

          <h2>Common Web Safe Fonts</h2>
          <ol>
            <li>
              <strong>Arial</strong>:
              <pre>
                <code>font-family: Arial, sans-serif;</code>
              </pre>
            </li><br />
            <li>
              <strong>Verdana</strong>:
              <pre>
                <code>font-family: Verdana, sans-serif;</code>
              </pre>
            </li><br />
            <li>
              <strong>Helvetica</strong>:
              <pre>
                <code>font-family: Helvetica, sans-serif;</code>
              </pre>
            </li><br />
            <li>
              <strong>Times New Roman</strong>:
              <pre>
                <code>font-family: "Times New Roman", Times, serif;</code>
              </pre>
            </li><br />
            <li>
              <strong>Georgia</strong>:
              <pre>
                <code>font-family: Georgia, serif;</code>
              </pre>
            </li><br />
            <li>
              <strong>Courier New</strong>:
              <pre>
                <code>font-family: "Courier New", Courier, monospace;</code>
              </pre>
            </li><br />
            <li>
              <strong>Trebuchet MS</strong>:
              <pre>
                <code>font-family: "Trebuchet MS", Helvetica, sans-serif;</code>
              </pre>
            </li><br />
            <li>
              <strong>Comic Sans MS</strong>:
              <pre>
                <code>font-family: "Comic Sans MS", cursive, sans-serif;</code>
              </pre>
            </li><br />
            <li>
              <strong>Palatino Linotype</strong>:
              <pre>
                <code>font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;</code>
              </pre>
            </li><br />
            <li>
              <strong>Tahoma</strong>:
              <pre>
                <code>font-family: Tahoma, sans-serif;</code>
              </pre>
            </li><br />
          </ol>

          <h2>Example Usage in CSS</h2>
          <p>Here’s how you might apply a web safe font in your CSS:</p>
          <pre>
            <code>
              {'body {'}
              <br />
              {'    font-family: Arial, sans-serif;'}
              <br />
              {'}'}
              <br />
              <br />
              {'h1 {'}
              <br />
              {'    font-family: Georgia, serif;'}
              <br />
              {'}'}
              <br />
              <br />
              {'code {'}
              <br />
              {'    font-family: "Courier New", Courier, monospace;'}
              <br />
              {'}'}
            </code>
          </pre><br />

          <h2>Fallback Fonts</h2>
          <p>
            Always include fallback fonts in your font stack. If the primary font is not available, the browser will try the next one in the list. For example:
          </p>
          <pre>
            <code>font-family: "Open Sans", Arial, sans-serif;</code>
          </pre><br />

          <h2>Conclusion</h2>
          <p>
            Using web safe fonts helps maintain consistent typography across different environments. However, for more creative designs, consider using web fonts (like those from Google Fonts) that allow you to use a wider variety of typefaces while ensuring they render correctly across all devices.
          </p>
  


              </div>
            )}

{selectedChapter === 'chapter120' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Animatable </h1>
           
    
          <p>
            In CSS, "animatable" refers to properties that can be transitioned or animated using CSS animations or transitions. Not all CSS properties are animatable; only certain properties can change over time, creating visual effects.
          </p><br />

          <h2>Animatable Properties</h2>
          <ol>
            <li>
              <strong>Color Properties</strong>:
              <ul>
                <li><code>color</code></li>
                <li><code>background-color</code></li>
                <li><code>border-color</code></li>
                <li><code>text-shadow</code></li>
              </ul>
            </li><br />
            <li>
              <strong>Dimension Properties</strong>:
              <ul>
                <li><code>width</code></li>
                <li><code>height</code></li>
                <li><code>padding</code></li>
                <li><code>margin</code></li>
                <li><code>top</code>, <code>right</code>, <code>bottom</code>, <code>left</code> (positioning)</li>
              </ul>
            </li><br />
            <li>
              <strong>Transform Properties</strong>:
              <ul>
                <li><code>transform</code> (e.g., <code>translate</code>, <code>scale</code>, <code>rotate</code>, <code>skew</code>)</li>
              </ul>
            </li><br />
            <li>
              <strong>Opacity</strong>:
              <ul>
                <li><code>opacity</code></li>
              </ul>
            </li><br />
            <li>
              <strong>Visibility</strong>:
              <ul>
                <li><code>visibility</code> (though this requires workarounds since it has only two states)</li>
              </ul>
            </li><br />
            <li>
              <strong>Filters</strong>:
              <ul>
                <li><code>filter</code> (e.g., <code>blur</code>, <code>brightness</code>)</li>
              </ul>
            </li><br />
            <li>
              <strong>Other Properties</strong>:
              <ul>
                <li><code>left</code>, <code>right</code>, <code>top</code>, <code>bottom</code> (when used with positioning)</li>
                <li><code>box-shadow</code></li>
                <li><code>outline</code></li>
                <li><code>border-width</code>, <code>border-radius</code></li>
              </ul>
            </li>
          </ol><br />

          <h2>Example of CSS Animation</h2>
          <p>Here's a simple example using CSS transitions:</p>
          <pre>
            <code>
              {`.button {`}
              <br />
              {'  background-color: blue;'}
              <br />
              {'  color: white;'}
              <br />
              {'  transition: background-color 0.5s ease, transform 0.3s ease;'}
              <br />
              {'}'}
              <br />
              <br />
              {`.button:hover {`}
              <br />
              {'  background-color: green;'}
              <br />
              {'  transform: scale(1.1);'}
              <br />
              {'}'}
            </code>
          </pre><br />

          <h2>Example of CSS Animation Keyframes</h2>
          <p>You can also use keyframes to create more complex animations:</p>
          <pre>
            <code>
              {'@keyframes example {'}
              <br />
              {'  from {'}
              <br />
              {'    transform: translateY(0);'}
              <br />
              {'    opacity: 1;'}
              <br />
              {'  }'}
              <br />
              {'  to {'}
              <br />
              {'    transform: translateY(-20px);'}
              <br />
              {'    opacity: 0.5;'}
              <br />
              {'  }'}
              <br />
              {'}'}
              <br />
              <br />
              {`.element {`}
              <br />
              {'  animation: example 2s ease-in-out infinite alternate;'}
              <br />
              {'}'}
            </code>
          </pre><br />

          <h2>Conclusion</h2>
          <p>
            Animatable properties allow you to create dynamic and interactive experiences on the web. Using CSS animations and transitions effectively can enhance user interfaces, making them more engaging and visually appealing. Always check for browser compatibility and performance when using animations, especially on mobile devices.
          </p>
       


              </div>
            )}


{selectedChapter === 'chapter125' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>CSS PX-EM Convert </h1>
           
                   
   

          <p>
            In CSS, "PX-EM Convert" refers to converting pixel (px) values to em units, which is useful for creating responsive designs. Here’s a breakdown of what each unit represents and how to perform the conversion:
          </p><br />

          <h2 style={{paddingBottom:"6px"}}>Understanding the Units</h2>
          <ul>
            <li>
              <strong>Pixels (px)</strong>: An absolute unit commonly used in web design. It represents a fixed size on the screen.
            </li><br />
            <li>
              <strong>EM</strong>: A relative unit that scales based on the font size of the element (or its parent). For example, if the font size of the parent is 16px, then 1em equals 16px.
            </li>
          </ul><br />

          <h2>Why Convert PX to EM?</h2>
          <p>
            Using em units allows for more flexible designs that can scale better with user settings (like browser zoom) and enhance accessibility. For example, if a user increases their default font size, elements using em will adjust accordingly, while those set in px will remain fixed.
          </p><br />

          <h2>Conversion Formula</h2>
          <p>To convert px to em:</p>
          <pre>
            <code>em = px / font-size</code>
          </pre>
          <p>Where:</p>
          <ul>
            <li><strong>px</strong> is the value in pixels you want to convert.</li><br />
            <li><strong>font-size</strong> is the base font size in pixels (usually the font size of the parent or the root).</li>
          </ul><br />

          <h2 style={{paddingBottom:"6px"}}>Example Conversion</h2>
          <ol>
            <li>
              <strong>If the base font size is 16px:</strong>
              <ul>
                <li>To convert 32px to em:</li>
                <pre>
                  <code>em = 32px / 16px = 2em</code>
                </pre>
              </ul>
            </li><br />
            <li>
              <strong>In CSS:</strong>
              <pre>
                <code>
                  .example {'{\n'} 
                  font-size: 2em; {'/* Equivalent to 32px if base is 16px */\n'} 
                  {'}'}
                </code>
              </pre>
            </li>
          </ol><br />

          <h2>Quick Reference Conversion Table</h2>
          <table>
            <thead>
              <tr>
                <th>Pixels (px)</th>
                <th>EM (at 16px base)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>12px</td>
                <td>0.75em</td>
              </tr>
              <tr>
                <td>16px</td>
                <td>1em</td>
              </tr>
              <tr>
                <td>24px</td>
                <td>1.5em</td>
              </tr>
              <tr>
                <td>32px</td>
                <td>2em</td>
              </tr>
              <tr>
                <td>48px</td>
                <td>3em</td>
              </tr>
            </tbody>
          </table><br />

          <h2>Conclusion</h2>
          <p>
            Converting px to em units is a best practice for creating responsive designs that adapt to user preferences. It helps maintain consistent scaling throughout your website, improving usability and accessibility.
          </p>
      



              </div>
            )}





{selectedChapter === 'chapter122' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Default Values </h1>
          

          <p>
            In CSS, "default values" refer to the initial settings or inherent values that properties take on if not explicitly defined by the user. These values can vary depending on the browser and the specific CSS property. Understanding default values is essential for effective styling and layout design.
          </p><br />

          <h2 style={{paddingBottom:"6px"}}>Common Default Values</h2>
          <ol>
            <li>
              <strong>Color and Background</strong>
              <ul>
                <li><code>color</code>: The default value is typically black (<code>#000</code>) for text.</li><br />
                <li><code>background-color</code>: The default value is <code>transparent</code>.</li>
              </ul>
            </li><br />
            <li>
              <strong>Font Properties</strong>
              <ul>
                <li><code>font-size</code>: Usually set to <code>16px</code> (varies by browser).</li><br />
                <li><code>font-family</code>: The default is typically the browser's default serif or sans-serif font.</li>
              </ul>
            </li><br />
            <li>
              <strong>Box Model Properties</strong>
              <ul>
                <li><code>margin</code>: Default is <code>0</code>.</li><br />
                <li><code>padding</code>: Default is <code>0</code>.</li><br />
                <li><code>border</code>: Default is <code>none</code>.</li>
              </ul>
            </li><br />
            <li>
              <strong>Display Properties</strong>
              <ul>
                <li><code>display</code>: The default is <code>inline</code> for elements like <code>&lt;span&gt;</code>, and <code>block</code> for elements like <code>&lt;div&gt;</code>.</li>
              </ul>
            </li><br />
            <li>
              <strong>List Properties</strong>
              <ul>
                <li><code>list-style</code>: Default is <code>none</code> for lists.</li>
              </ul>
            </li><br />
            <li>
              <strong>Positioning</strong>
              <ul>
                <li><code>position</code>: Default is <code>static</code>, meaning elements are positioned according to the normal flow of the document.</li>
              </ul>
            </li><br />
            <li>
              <strong>Overflow</strong>
              <ul>
                <li><code>overflow</code>: Default is <code>visible</code>, meaning content that overflows the box will be visible.</li>
              </ul>
            </li>
          </ol><br />

          <h2  style={{paddingBottom:"6px"}}>Importance of Default Values</h2>
          <ul>
            <li><strong>Baseline for Styles</strong>: Knowing default values helps in creating consistent styles across different browsers.</li><br />
            <li><strong>Inheritance</strong>: Many properties inherit values from their parent elements, so understanding defaults can help in managing styles effectively.</li><br />
            <li><strong>Override Strategy</strong>: You can override defaults using specific styles to achieve desired layouts or designs.</li>
          </ul><br />

          <h2>Conclusion</h2>
          <p>
            Default values in CSS play a crucial role in styling web pages. Familiarity with these values enables developers to write cleaner, more effective CSS, ensuring designs work consistently across different environments.
          </p>
     


              </div>
            )}





{selectedChapter === 'chapter121' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading}>Browser Support </h1>
           

    <p>
      "Browser support" in CSS refers to the compatibility of CSS properties, features, and selectors across different web browsers. Understanding browser support is crucial for developers to ensure that the styles they implement will render consistently for users, regardless of the browser or device they are using.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Key Aspects of Browser Support</h2>

    <ol>
      <li>
        <strong>CSS Properties and Features:</strong>
        <ul>
          <li>
            Not all CSS properties are supported by every browser. For example, some newer properties like <code>grid</code> or <code>flex</code> have varying levels of support in older browsers.
          </li><br />
          <li>
            Features like CSS Grid and Flexbox have become widely supported, but older versions of browsers may not fully support them.
          </li>
        </ul>
      </li><br />

      <li>
        <strong>Vendor Prefixes:</strong>
        <ul>
          <li>
            Some CSS features require vendor prefixes to work in certain browsers. For example, properties like <code>transform</code> or <code>transition</code> might need prefixes like <code>-webkit-</code> or <code>-moz-</code> for compatibility with older versions of Chrome or Firefox.
          </li>
        </ul>
      </li><br />

      <li>
        <strong>Fallbacks:</strong>
        <ul>
          <li>
            Developers often implement fallbacks for features that may not be supported in all browsers. This could involve using simpler styles or alternative methods to achieve similar effects.
          </li>
        </ul>
      </li><br />

      <li>
        <strong>Tools for Checking Support:</strong>
        <ul>
          <li>
            Websites like <a href="https://caniuse.com/" target="_blank" rel="noopener noreferrer">Can I Use</a> provide comprehensive databases of CSS features and their support across different browsers and versions.
          </li><br />
          <li>
            CSS feature detection libraries like <code>Modernizr</code> can help developers conditionally apply styles based on feature availability.
          </li>
        </ul>
      </li><br />

      <li>
        <strong>Progressive Enhancement:</strong>
        <ul>
          <li>
            This approach involves designing a basic version of a web application that works across all browsers, and then enhancing it with advanced features that are supported in modern browsers.
          </li>
        </ul>
      </li>
    </ol><br />

    <h2>Conclusion</h2>
    <p>
      Browser support is a critical consideration in CSS development. Understanding which features are supported across different browsers helps ensure a consistent user experience and allows developers to make informed decisions about which CSS properties to use in their designs.
    </p>
 
              </div>
            )}







          </div>
        </div>
      </div>
  
  );
}

export default Csscoursedata;
