import React, { useState, useEffect, useRef } from "react";
import style from "./../Css/Recentjob.module.css"; // Adjust CSS import if needed
import Applyforinternship from './Applyforinternship'

// Dropdown Component with Search
const DropdownWithSearch = ({ items, placeholder, label, isOpen, toggleDropdown, onSelect }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const dropdownRef = useRef(null);

  // Filter items based on search query
  const filteredItems = items.filter(item => item.toLowerCase().includes(searchQuery.toLowerCase()));

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Handle click outside to close dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        toggleDropdown(); // Close the dropdown if clicking outside
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, toggleDropdown]);

  return (
    <div ref={dropdownRef} className={style.droupdownmenu}>
      <button onClick={toggleDropdown} className={style.dropdownbutton}>
        {label}
      </button>

      {isOpen && (
        <div className={style.droupdowncontent}>
          <input
            type="text"
            placeholder={placeholder || "Search..."}
            value={searchQuery}
            onChange={handleSearchChange}
            className={style.dropdownsearchbar}
          />
          <ul className={style.droupdownul}>
            {filteredItems.length > 0 ? (
              filteredItems.map((item, index) => (
                <li
                  key={index}
                  className={style.dropdownitemname}
                  onClick={() => onSelect(item)}
                >
                  {item}
                </li>
              ))
            ) : (
              <li className={style.dropdownitemname}>No items found</li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

// Main Recent Job Component
const Recentjob = () => {
  const [openDropdown, setOpenDropdown] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');

  const jobCategories = ['Frontend Developer', 'Backend Developer', 'Full Stack Developer', 'Data Scientist', 'UI/UX Designer'];
  const locations = ['Pune', 'Hyderabad', 'Bangalore', 'Chennai', 'Mumbai'];

  // Job listings data
  const jobs = [

    {
      title: "Data Analyst",
      company: "AMC Career",
      location: "Mumbai",
      category: "Data Scientist",
      qualification: "BE/B.Tech(Computer Science & Engineering (CSE))",
      experience: "0 Years",
      lastDate: "07 DEC. 2024",
      profile: "IT Software Engineer",
      updateDate: "12th Nov. 2024",
      link: "https://www.freshersworld.com/jobs/data-analyst-jobs-opening-in-amc-career-at-andheri-east-mumbai-2559278"
    },
    {
      title: "Data Analytics - SQL Expert",
      company: "AMC Career",
      location: "Mumbai",
      category: "Data Scientist",
      qualification: "BE/B.Tech(Computer Science & Engineering (CSE),Computer Engineering (Specialization in Data Science))",
      experience: "0 Years",
      lastDate: "05 DEC. 2024",
      profile: "IT Software Engineer",
      updateDate: "12th Nov. 2024",
      link: "https://www.freshersworld.com/jobs/data-analytics-sql-expert-jobs-opening-in-amc-career-at-vikhroli-mumbai-2559280"
    },
    {
      title: "Python Development",
      company: "FIKAA",
      location: "Mumbai",
      category: "Full Stack Developer",
      qualification: "BE/B.Tech, CS",
      experience: "0 Years",
      lastDate: "30 NOV. 2024",
      profile: "IT Software Engineer",
      updateDate: "12th Nov. 2024",
      link: "https://www.freshersworld.com/jobs/python-development-jobs-opening-in-fikaa-at-mumbai-2198561"
    },
    {
      title: "WordPress Development",
      company: "Techsphere",
      location: "Mumbai",
      category: "Full Stack Developer",
      qualification: "BCA, BE/B.Tech, BSc, CS",
      experience: "0 Years",
      lastDate: "30 NOV. 2024",
      profile: "IT Software Engineer",
      updateDate: "30th Oct. 2024",
      link: "https://www.freshersworld.com/jobs/wordpress-development-jobs-opening-in-techsphere-at-mumbai-2273287"
    },
    {
      title: "Software Development Engineer",
      company: "AMC Career",
      location: "Mumbai",
      category: "Full Stack Developer",
      qualification: "BE/B.Tech(Information Science/Technology (IS/IT), Mechanical Engineering)",
      experience: "0 Years",
      lastDate: "25 DEC. 2024",
      profile: "IT Software Engineer",
      updateDate: "12 NOV. 2024",
      link: "https://www.freshersworld.com/jobs/software-development-engineer-jobs-opening-in-amc-career-at-thane-vikhroli-worli-mumbai-2559283"
    },
    {
      title: "Node.js Development",
      company: "Kenmark ITan Solutions",
      location: "Mumbai",
      category: "Backend Developer",
      qualification: "BCA, BE/B.Tech, BSc, CS",
      experience: "0 Years",
      lastDate: "15 DEC. 2024",
      profile: "IT Software Engineer",
      updateDate: "11 NOV. 2024",
      link: "https://www.freshersworld.com/jobs/node-js-development-jobs-opening-in-kenmark-itan-solutions-at-mumbai-2164995"
    },
    {
      title: "Data Science",
      company: "Faclon",
      location: "Mumbai",
      category: "Data Scientist",
      qualification: "BCA, BE/B.Tech, BSc, CS",
      experience: "0 Years",
      lastDate: "30 NOV. 2024",
      profile: "IT Software Engineer",
      updateDate: "30 Oct. 2024",
      link: "https://www.freshersworld.com/jobs/data-science-jobs-opening-in-faclon-at-mumbai-2198522"
    },
    {
      title: "Flutter Development",
      company: "Quantasis Private Limited",
      location: "Mumbai",
      category: "Full Stack Developer",
      qualification: "BE/B.Tech, CS",
      experience: "0 Years",
      lastDate: "29 NOV. 2024",
      profile: "IT Software Engineer",
      updateDate: "23 Oct. 2024",
      link: "https://www.freshersworld.com/jobs/flutter-development-jobs-opening-in-quantasis-private-limited-at-mumbai-2198607"
    },
    {
      title: "Cloud Computing intern",
      company: "Quantasis Private Limited",
      location: "Mumbai",
      category: "Backend Developer",
      qualification: "BCA, BE/B.Tech, BSc, CS",
      experience: "0 Years",
      lastDate: "30 NOV. 2024",
      profile: "IT/Software , Internship , BSc/BCA/BBM",
      updateDate: "30 Oct. 2024",
      link: "https://www.freshersworld.com/jobs/cloud-computing-intern-jobs-opening-in-quantasis-private-limited-at-mumbai-2198692"
    }
  ];

  // Filter jobs based on selected category and location
  const filteredJobs = jobs.filter(job => {
    const matchesCategory = selectedCategory ? job.category === selectedCategory : true;
    const matchesLocation = selectedLocation
      ? job.location.replace(/\s+/g, '').toLowerCase().includes(selectedLocation.toLowerCase().replace(/\s+/g, ''))
      : true;
    return matchesCategory && matchesLocation;
  });
  

  const toggleDropdown = (dropdown) => {
    setOpenDropdown(openDropdown === dropdown ? null : dropdown);
  };

  return (
    <>
    <Applyforinternship/>
      <div className="container-fluid">
        <div className="container">
          <div className={`${style.recentrowhead} row`}>
            <div className="col">
              <h4 className={style.recentjobmainheading}>JOB UPDATES</h4>
            </div>
            <div className={`${style.jobserchbutton} col`}>
              <div className={style.droupdownwithsearch}>
                <DropdownWithSearch
                  items={jobCategories}
                  placeholder="Search job categories..."
                  label={<span>Job Categories <i className="fa-solid fa-chevron-down mx-2"></i></span>}
                  isOpen={openDropdown === 'categories'}
                  toggleDropdown={() => toggleDropdown('categories')}
                  onSelect={(item) => { setSelectedCategory(item); toggleDropdown(null); }}
                />
                <DropdownWithSearch
                  items={locations}
                  placeholder="Search locations..."
                  label={<span>Location <i className="fa-solid fa-chevron-down mx-2"></i></span>}
                  isOpen={openDropdown === 'locations'}
                  toggleDropdown={() => toggleDropdown('locations')}
                  onSelect={(item) => { setSelectedLocation(item); toggleDropdown(null); }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className={`${style.recentjobcontainer} container`}>
          {filteredJobs.length > 0 ? (
            filteredJobs.map((job, index) => (
              <div key={index} className={`${style.row4}`}>
                <div className={`${style.colimg} col-2`}>
                  <img src="Images/jobicon (5).png" alt="Job Icon" />
                </div>
                <div className={`${style.content} col-10`}>
                  <h3 className={style.coursename}>{job.title}</h3>
                  <h5>{job.company}</h5>
                  <h4 className={style.coursetime}>Location: {job.location}</h4>
                  <h5 className={style.courseduration}>Qualification: {job.qualification}</h5>
                  <h5 className={style.courseduration}>Experience: {job.experience}</h5>
                  <h5 className={style.courseduration}>Last date: {job.lastDate}</h5>
                  <h5 className={style.courseduration}>Profile: {job.profile}</h5>
                  <h5 className={style.courseduration}>Update date: {job.updateDate}</h5>
                  <div className={style.footcol2nd}>

                  <a className={style.btn} href={job.link}>Apply here</a>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <h4>No jobs found for the selected filters</h4>
          )}
        </div>
      </div>
    </>
  );
};

export default Recentjob;
