import React, { useEffect, useState } from 'react';
import style from './../Css/Landingpageanimation.module.css';

// Import your PNG images
import logo1 from './../assets/logos/logo1.png';
import logo2 from './../assets/logos/logo2.png';
import logo3 from './../assets/logos/logo3.png';
import logo4 from './../assets/logos/logo4.png';
import logo5 from './../assets/logos/logo5.png';
import logo6 from './../assets/logos/logo6.png';

const Landingpageanimation = () => {
  // List of logos for the train animation
  const logos = [logo1, logo2, logo3, logo4, logo5, logo6];

  // Repeat the logo list 100 times for an extended train
  const repeatedLogos = Array(100).fill(logos).flat();

  // State to control animation
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    // Scroll event handler
    const handleScroll = () => {
      setIsScrolling(true);
    };

    // Listen for scroll events
    window.addEventListener('scroll', handleScroll);

    // Stop animation after user stops scrolling
    const stopAnimationTimeout = setTimeout(() => {
      setIsScrolling(false);
    }, 1000);

    // Cleanup event listener
    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(stopAnimationTimeout);
    };
  }, [isScrolling]);

  return (
    <div className={style.logoTrainContainer}>
      <div className={`${style.logoTrain} ${isScrolling ? style.scrolling : ''}`}>
        {repeatedLogos.map((logo, index) => (
          <img
            key={index}
            src={logo}
            alt={`Logo ${index + 1}`}
            className={style.logo}
          />
        ))}
      </div>
    </div>
  );
};

export default Landingpageanimation;
