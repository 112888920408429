import React, { useState } from "react";
import Select from "react-select";
import style from "./../Css/Availableinternship.module.css";
import { Link } from "react-router-dom";
import Applyforinternship from './Applyforinternship'

const Availableinternship = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalImages, setModalImages] = useState([]);

  const categories = [
    { value: "E-Commerce-Platform", label: "E-Commerce Platform" },
    { value: "Travel-Booking-System", label: "Travel Booking System" },
    { value: "Inventory-Management-System", label: "Inventory Management System" },
    { value: "Booking/On-Demand-Services", label: "Booking/On-Demand Services" },
    { value: "Corporate/B2B-Services", label: "Corporate/B2B Services" },
  ];

  const handleCategoryChange = (selectedOption) => {
    setSelectedCategory(selectedOption);
  };

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
  };

  const handlePreview = (images) => {
    setModalImages(images);
    setModalVisible(true);
  };

  const handleClosePreview = () => {
    setModalVisible(false);
    setModalImages([]);
  };

  const internships = [
    {
      id: 1,
      category: "E-Commerce-Platform",
      title: "E-Commerce Platform",
      description: "This website is designed for live learning and live project internships.",
      skills: "HTML, CSS, JavaScript and Bootstrap.",
      images: ["merchant_hub/home.png", "merchant_hub/retailer_login.png", "merchant_hub/about.png"],
    },
    {
      id: 2,
      category: "Travel-Booking-System",
      title: "Travel Booking System",
      description: "This website is designed for live learning and live project internships.",
      skills: "HTML, CSS, JavaScript, Bootstrap and React JS.",
      images: ["flight_booking/about.png", "flight_booking/home.png", "flight_booking/profile.png"],
    },
    {
      id: 3,
      category: "Inventory-Management-System",
      title: "Inventory Management System",
      description: "This website is designed for live learning and live project internships.",
      skills: "HTML, CSS, JavaScript and Bootstrap.",
      images: [
        "medical_inventory_management/admin_home.png",
        "medical_inventory_management/Screenshot 2024-11-11 165321.png",
        "medical_inventory_management/Screenshot 2024-11-11 165347.png",
      ],
    },
    {
      id: 4,
      category: "Booking/On-Demand-Services",
      title: "Booking/On-Demand Services",
      description: "This website is designed for live learning and live project internships.",
      skills: "HTML, CSS, JavaScript and Bootstrap.",
      images: ["online_services/home.png",
            "online_services/about.png",
             "online_services/services.png"
           ],
    },
   
    {
      id: 5,
      category: "Corporate/B2B-Services",
      title: "Corporate/B2B Services",
      description: "This website is designed for live learning and live project internships.",
      skills: "HTML, CSS, JavaScript and Bootstrap.",
      images: [
        "online_counselling_system/home.png",
        "online_counselling_system/registration.png",
        "online_counselling_system/admin_dashboard.png",
      ],
    },
  ];

  // Filter internships based on the selected category
  const filteredInternships = selectedCategory
    ? internships.filter((internship) => internship.category === selectedCategory.value)
    : internships;

  return (
    <>
      <Applyforinternship/>
      <div className={`${style.internshipfluid} container-fluid`}>
        <div className="container">
          <div className={`${style.headerrow} row d-flex`}>
            <h1 className={style.internshipheading}>Available Internship</h1>
            <div className={style.dropdownContainer}>
              <Select
                options={categories}
                value={selectedCategory}
                onChange={handleCategoryChange}
                className={style.droupdown}
                placeholder="Select a category"
                isSearchable
                styles={customStyles}
              />
            </div>
          </div>

          <div className={`${style.internshiprow} row`}>
            {filteredInternships.map((card) => (
              <div className="col" key={card.id}>
                <div className={`${style.coursesbuyrowmain} ${style.liveprojectcard}`}>
                  <div className={`${style.coursesbuymain}`}>
                    <div className={style.courseslogomain}>
                      <img className={style.coursesbuylogo} src={card.images[0]} alt="Preview 1" />
                      <img className={style.coursesbuylogo} src={card.images[1]} alt="Preview 2" />
                      <img className={style.coursesbuylogo} src={card.images[2]} alt="Preview 3" />
                    </div>
                    <div className={style.coursesdetail}>
                      <h4 className={style.nameheading}>{card.title}</h4>
                      <span className={style.coursesdata}>{card.description}</span>
                      <h5 className={style.learnmorebuy}>{card.skills}</h5>
                    </div>
                    <div className={style.buttonz}>
                      <button
                        className={style.coursesbuybutton}
                        onClick={() => handlePreview(card.images)}
                      >
                        Preview
                      </button>
                      <Link
                        to={"/Apply_internshipfoam"}
                        style={{ textDecoration: "none" }}
                        className={style.coursesbuybutton}
                      >
                        Apply for Internship
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {modalVisible && (
        <div className={style.modalOverlay}>
          <div className={style.modalContent}>
            <button className={style.closeButton} onClick={handleClosePreview}>
              &times;
            </button>
            <div className={style.imageContainer}>
              {modalImages.map((img, index) => (
                <img key={index} src={img} alt={`Preview ${index}`} className={style.modalImage} />
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Availableinternship;
