import React, { useState, useEffect } from 'react';
import style from './../Css/Applyforinternship.module.css';
import { Link } from 'react-router-dom';


const Applyforinternship = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // Adjust the value according to your navbar height
      const navbarHeight = 50; // Change this to match your navbar height
      setScrolled(window.scrollY > navbarHeight);
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Link to={"/Apply_internshipfoam"} style={{ textDecoration: 'none'}} className={`${style.fixedBadge} ${scrolled ? style.scrolled : ''}`}>
      
        <a  className={style.badgeText} href="" >  Apply for Internship! <i className={`fa-regular fa-star ${style.badgeIcon}`}></i></a>
       
      
    </Link>
  );
}

export default Applyforinternship;
