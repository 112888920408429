import React, { useRef, useEffect } from 'react';
import style from "./../Css/NavBar.module.css";
import { Link } from 'react-router-dom'



const Navbar = () => {
  const offcanvasRef = useRef(null); // Ref to access the offcanvas element

  useEffect(() => {
    const offcanvas = new window.bootstrap.Offcanvas(offcanvasRef.current, {
      backdrop: false, // Disable backdrop
    });

    // Function to close offcanvas when clicking outside
    const handleClickOutside = (event) => {
      if (offcanvasRef.current && !offcanvasRef.current.contains(event.target)) {
        offcanvas.hide();
      }
    };

    // Function to close offcanvas when a list item is clicked
    const handleClickItem = () => {
      offcanvas.hide(); // Hide the offcanvas using Bootstrap's API
    };

    // Get all the list items inside the offcanvas
    const listItems = document.querySelectorAll('.offcanvas-body .nav-item');

    // Add click event listener to each list item
    listItems.forEach((item) => {
      item.addEventListener('click', handleClickItem);
    });

    // Add event listener for clicks outside the offcanvas
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up event listeners when the component unmounts
    return () => {
      listItems.forEach((item) => {
        item.removeEventListener('click', handleClickItem);
      });
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <nav className={`${style.navbar} navbar-sm`}>
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <ul className="navbar-nav d-flex flex-row">
              <li className="nav-item">
                <div
                  ref={offcanvasRef}
                  data-bs-dismiss="offcanvas" aria-label="Close"
                  className={`${style.OffCanvassshow} offcanvas offcanvas-start`} id="demo">
                  <div className="offcanvas-header">
                    <h1 className={`${style.sidebarheading} offcanvas-title`}>COURSES</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" />
                  </div>
                  <div className="offcanvas-body">

                    <ul className={style.sidebarlistitems}>
                  
                      <li className="nav-item">
                        <Link className={`${style.sidebarlink} nav-link`} to="/HTM_L_COURSE">
                          HTML
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to={"/CSS_COURSE"} className={`${style.sidebarlink} nav-link`} href="javascript:void(0)">
                          CSS
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to={"/BOOTSTRAP"} className={`${style.sidebarlink} nav-link`} href="javascript:void(0)">
                          BOOTSTRAP
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to={"/JAVA_SCRIPT_COURSE"} className={`${style.sidebarlink} nav-link`} href="javascript:void(0)">
                          JAVASCRIPT
                        </Link>
                      </li>
                      <li className="nav-item ">
                        <Link to={"/C_COURSE"} className={`${style.sidebarlink} nav-link`} href="javascript:void(0)">
                          C
                        </Link>
                      </li>
                      <li className="nav-item ">
                        <Link to={'/c++_COURSE'} className={`${style.sidebarlink} nav-link`} href="javascript:void(0)">
                          C + +
                        </Link>
                      </li>
                      
                      <li className="nav-item">
                        <Link to={"/PYTHON"} className={`${style.sidebarlink} nav-link`} href="javascript:void(0)">
                          PYTHON
                        </Link>
                      </li>


                      <li className="nav-item">
                        <Link to={"/DOTNET_COURSE"} className={`${style.sidebarlink} nav-link`} href="javascript:void(0)">
                          DOT NET
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link to={"/NODEJS_COURSE"} className={`${style.sidebarlink} nav-link`} href="javascript:void(0)">
                          NODE JS
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to={"/ORACLE_COURSE"} className={`${style.sidebarlink} nav-link`} href="javascript:void(0)">
                          ORACLE
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to={"/PHP_COURSE"} className={`${style.sidebarlink} nav-link`} href="javascript:void(0)">
                          PHP
                        </Link>
                      </li>


                    







          


                      


                    </ul>
                  </div>
                </div>
                <button
                  className={style.sidebar}
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#demo"
                >

                  <i class="fa-solid fa-bars"></i>



                </button>
              </li>
            </ul>
            <div className={style.scrollitemcontainer}>
              <ul className={style.scrollitem}>
             
                <li className="nav-item">
                  <Link className={`${style.navitem} nav-link`} to="/HTM_L_COURSE">
                    HTML
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to={"/CSS_COURSE"} className={`${style.navitem} nav-link`} href="javascript:void(0)">
                    CSS
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to={"/BOOTSTRAP"} className={`${style.navitem} nav-link`} href="javascript:void(0)">
                    BOOTSTRAP
                  </Link>
                </li>


                <li className="nav-item">
                  <Link to={"/JAVA_SCRIPT_COURSE"} className={`${style.navitem} nav-link`} href="javascript:void(0)">
                    JAVASCRIPT
                  </Link>
                </li>
                <li className="nav-item ">
                  <Link to={"/C_COURSE"} className={`${style.navitem} nav-link`} href="javascript:void(0)">
                    C
                  </Link>
                </li>
                <li className="nav-item ">
                  <Link to={'/c++_COURSE'} className={`${style.navitem} nav-link`} href="javascript:void(0)">
                    C + +
                  </Link>
                </li>
                
                <li className="nav-item">
                  <Link to={"/PYTHON"} className={`${style.navitem} nav-link`} href="javascript:void(0)">
                    PYTHON
                  </Link>
                </li>

                <li className="nav-item">
                  <Link to={"/DOTNET_COURSE"} className={`${style.navitem} nav-link`} href="javascript:void(0)">
                    DOT NET
                  </Link>
                </li>

                <li className="nav-item">
                  <Link to={"/NODEJS_COURSE"} className={`${style.navitem} nav-link`} href="javascript:void(0)">
                    NODE JS
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to={"/ORACLE_COURSE"} className={`${style.navitem} nav-link`} href="javascript:void(0)">
                    ORACLE
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to={"/PHP_COURSE"} className={`${style.navitem} nav-link`} href="javascript:void(0)">
                    PHP
                  </Link>
                </li>


              
              


                <li className="nav-item">
                  <Link to={"/JAVA_SCRIPT_COURSE"} className={`${style.navitem} nav-link`} href="javascript:void(0)">
                    JAVASCRIPT
                  </Link>
                </li>
                  
                <li className="nav-item">
                  <Link to={"/PYTHON"} className={`${style.navitem} nav-link`} href="javascript:void(0)">
                    PYTHON
                  </Link>
                </li>

                <li className="nav-item">
                  <Link to={"/DOTNET_COURSE"} className={`${style.navitem} nav-link`} href="javascript:void(0)">
                    DOT NET
                  </Link>
                </li>

                <li className="nav-item">
                  <Link to={"/NODEJS_COURSE"} className={`${style.navitem} nav-link`} href="javascript:void(0)">
                    NODE JS
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to={"/ORACLE_COURSE"} className={`${style.navitem} nav-link`} href="javascript:void(0)">
                    ORACLE
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to={"/PHP_COURSE"} className={`${style.navitem} nav-link`} href="javascript:void(0)">
                    PHP
                  </Link>
                </li>












           








              </ul>
            </div>
          </div>
        </div>
      </nav>

    </>
  );
}

export default Navbar;
