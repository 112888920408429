import { useRef, useState } from "react";
import React from "react";
import style from "./../Css/Liveproject.module.css";
import { Link } from "react-router-dom";
import Applyforinternship from './Applyforinternship'

const Liveproject = () => {
  // State for managing which section is active
  const [activeSection, setActiveSection] = useState(null);
  

  const [modalVisible, setModalVisible] = useState(false);
  const [modalImages, setModalImages] = useState([]);

  // Handle section change (for navigation bar)
  const handleSectionChange = (section) => {
    setActiveSection(section || null);
  };


  // Scroll container for horizontal scrolling
  const scrollContainerRef = useRef(null);
  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: 400, behavior: "smooth" });
    }
  };

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -400, behavior: "smooth" });
    }
  };

  // Modal handlers for multiple images preview
  const handlePreview = (images) => {
    setModalImages(images);
    setModalVisible(true);
  };

  const handleClosePreview = () => {
    setModalVisible(false);
    setModalImages([]);
  };

  return (
    <>
       <Applyforinternship/>
     
      {/* Modal for multiple images */}
      {modalVisible && (
        <div className={style.modalOverlay}>
          <div className={style.modalContent}>
            <button className={style.closeButton} onClick={handleClosePreview}>
              &times;
            </button>
            <div className={style.imageContainer}>
              {modalImages.map((img, index) => (
                <img
                  key={index}
                  src={img}
                  alt={`Preview ${index}`}
                  className={style.modalImage}
                />
              ))}
            </div>
          </div>
        </div>
      )}

      

      <div className={`${style.liveprojectcategories} container-fluid`}>
        <div className="container">
          {/* Categories bar */}
          <div className={style.scrollitemcategories}>
            <ul className={style.scrollitem}>
              <li className="nav-item">
                <a
                  className={`${style.navitem} ${style.navbackbtn} nav-link`}
                  href="javascript:void(0)"
                  onClick={() => handleSectionChange("")}
                >
                  <i className="fa-solid fa-left-long"></i>
                </a>
                <a
                  className={`${style.navitem} nav-link`}
                  href="javascript:void(0)"
                  onClick={() => handleSectionChange("webdevelopment")}
                >
                  Web Development
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`${style.navitem} nav-link`}
                  href="javascript:void(0)"
                  onClick={() => handleSectionChange("ecommerce")}
                >
                  E-commerce
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`${style.navitem} nav-link`}
                  href="javascript:void(0)"
                  onClick={() => handleSectionChange("portfolio")}
                >
                  Portfolio
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Main content */}
      <div className="container-fluid">
        <div className="container">
          {/* Landing view content */}
          {activeSection === null && (
            <div className={`${style.landingview} row`}>
              <div className={`${style.ourproject} col-6`}>
                <h2 className={style.mainheading}>Our project</h2>

                <div className={`${style.coursesbuyrowmain}`}>
                  <div className={`${style.coursesbuymain}`}>
                    <div className={style.courseslogomain}>
                      <img
                        className={style.coursesbuylogo}
                        src="merchant_hub/home.png"
                        alt=""
                      />
                      <img
                        className={style.coursesbuylogo}
                        src="merchant_hub/retailer_login.png"
                        alt=""
                      />
                      <img
                        className={style.coursesbuylogo}
                        src="merchant_hub/about.png"
                        alt=""
                      />
                    </div>
                    <div className={style.coursesdetail}>
                      <h4 className={style.nameheading}>E-Commerce Platform</h4>
                      <span className={style.coursesdata}>
                        This website design for Live learning and Live Project internship.
                      </span>
                      <h5 className={style.learnmorebuy}>
                        HTML, CSS, JavaScript, and Bootstrap.
                      </h5>
                    </div>
                    <div className={style.buttonz}>
                      {/* Preview button triggers modal for multiple images */}
                      <button
                        className={style.coursesbuybutton}
                        onClick={() =>
                          handlePreview([
                            "merchant_hub/home.png",
                            "merchant_hub/retailer_login.png",
                            "merchant_hub/about.png",
                          ])
                        }
                      >
                        Preview
                      </button>
                      <button className={style.coursesbuybutton}>
                        Source code <i className="fa-solid fa-download"></i>
                      </button>
                    </div>
                  </div>
                </div>


                <div className={`${style.coursesbuyrowmain}`}>
                  <div className={`${style.coursesbuymain}`}>
                    <div className={style.courseslogomain}>
                      <img
                        className={style.coursesbuylogo}
                        src="online_services/home.png"
                        alt=""
                      />
                      <img
                        className={style.coursesbuylogo}
                        src="online_services/about.png"
                        alt=""
                      />
                      <img
                        className={style.coursesbuylogo}
                        src="online_services/services.png"
                        alt=""
                      />
                    </div>
                    <div className={style.coursesdetail}>
                      <h4 className={style.nameheading}>Booking/On-Demand Services</h4>
                      <span className={style.coursesdata}>
                        This website design for Live learning and Live Project internship.
                      </span>
                      <h5 className={style.learnmorebuy}>
                        HTML, CSS, JavaScript, and Bootstrap.
                      </h5>
                    </div>
                    <div className={style.buttonz}>
                      {/* Preview button triggers modal for multiple images */}
                      <button
                        className={style.coursesbuybutton}
                        onClick={() =>
                          handlePreview([
                            "online_services/home.png",
                            "online_services/about.png",
                            "online_services/services.png",
                          ])
                        }
                      >
                        Preview
                      </button>
                      <button className={style.coursesbuybutton}>
                        Source code <i className="fa-solid fa-download"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* Live project section */}
              <div className={`${style.liveproject} col-6`}>
                <h2 className={style.mainheading}>Live project</h2>
                <div className={`${style.coursesbuyrowmain} ${style.liveprojectcard}`}>
                  <div className={`${style.coursesbuymain}`}>
                    <div className={style.courseslogomain}>
                      <img
                        className={style.coursesbuylogo}
                        src="flight_booking/about.png"
                        alt=""
                      />
                      <img
                        className={style.coursesbuylogo}
                        src="flight_booking/home.png"
                        alt=""
                      />
                      <img
                        className={style.coursesbuylogo}
                        src="flight_booking/profile.png"
                        alt=""
                      />
                    </div>
                    <div className={style.coursesdetail}>
                      <h4 className={style.nameheading}>Travel Booking System</h4>
                      <span className={style.coursesdata}>
                        This website design for Live learning and Live Project internship.
                      </span>
                      <h5 className={style.learnmorebuy}>
                        HTML, CSS, JavaScript, Bootstrap, and React JS.
                      </h5>
                    </div>
                    <div className={style.buttonz}>
                      {/* Preview button triggers modal for multiple images */}
                      <button
                        className={style.coursesbuybutton}
                        onClick={() =>
                          handlePreview([
                            "flight_booking/about.png",
                            "flight_booking/home.png",
                            "flight_booking/profile.png",
                          ])
                        }
                      >
                        Preview
                      </button>
                      <Link to={"/Apply_internshipfoam"} className={style.coursesbuybutton}>
                        Apply for Internship!
                      </Link>
                    </div>
                  </div>
                </div>

                <div className={`${style.coursesbuyrowmain} ${style.liveprojectcard}`}>
                  <div className={`${style.coursesbuymain}`}>
                    <div className={style.courseslogomain}>
                      <img
                        className={style.coursesbuylogo}
                        src="online_counselling_system/home.png"
                        alt=""
                      />
                      <img
                        className={style.coursesbuylogo}
                        src="online_counselling_system/registration.png"
                        alt=""
                      />
                      <img
                        className={style.coursesbuylogo}
                        src="online_counselling_system/admin_dashboard.png"
                        alt=""
                      />
                    </div>
                    <div className={style.coursesdetail}>
                      <h4 className={style.nameheading}>Corporate/B2B Services</h4>
                      <span className={style.coursesdata}>
                        This website design for Live learning and Live Project internship.
                      </span>
                      <h5 className={style.learnmorebuy}>
                      HTML, CSS, JavaScript and Bootstrap.
                      </h5>
                    </div>
                    <div className={style.buttonz}>
                      {/* Preview button triggers modal for multiple images */}
                      <button
                        className={style.coursesbuybutton}
                        onClick={() =>
                          handlePreview([
                            "online_counselling_system/home.png",
                            "online_counselling_system/registration.png",
                            "online_counselling_system/admin_dashboard.png",
                          ])
                        }
                      >
                        Preview
                      </button>
                      <Link to={"/Apply_internshipfoam"} className={style.coursesbuybutton}>
                        Apply for Internship!
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Additional sections (hidden when inactive) */}
      {/* <div className="container-fluid">
        {activeSection === "webdevelopment" && (
           <div className={`${style.webdevlopment} ${style.d} container`}>
           <div
             className={`${style.scrollContainer}`}
             ref={scrollContainerRef}
           >
             <div className={`${style.coursesbuyrow}`}>
               <div className={`${style.coursesbuy}`}>
                 <div className={style.courseslogo}>
                 <img
                        className={style.coursesbuylogo}
                        src="merchant_hub/home.png"
                        alt=""
                      />
                      <img
                        className={style.coursesbuylogo}
                        src="merchant_hub/retailer_login.png"
                        alt=""
                      />
                      <img
                        className={style.coursesbuylogo}
                        src="merchant_hub/about.png"
                        alt=""
                      />
                    </div>
                    <div className={style.coursesdetail}>
                      <h4 className={style.nameheading}>E-Commerce Platform</h4>
                      <span className={style.coursesdata}>
                        This website design for Live learning and Live Project internship.
                      </span>
                      <h5 className={style.learnmorebuy}>
                        HTML, CSS, JavaScript, and Bootstrap.
                      </h5>
                    </div>
                    <div className={style.buttonz}>
                      
                      <button
                        className={style.coursesbuybutton}
                        onClick={() =>
                          handlePreview([
                            "merchant_hub/home.png",
                            "merchant_hub/retailer_login.png",
                            "merchant_hub/about.png",
                          ])
                        }
                      >
                        Preview
                      </button>
                      <button className={style.coursesbuybutton}>
                        Source code <i className="fa-solid fa-download"></i>
                      </button>
                    </div>
               </div>
             </div>

             
             <div className={`${style.coursesbuyrow}`}>
               <div className={`${style.coursesbuy}`}>
                 <div className={style.courseslogo}>
                 <img
                        className={style.coursesbuylogo}
                        src="merchant_hub/home.png"
                        alt=""
                      />
                      <img
                        className={style.coursesbuylogo}
                        src="merchant_hub/retailer_login.png"
                        alt=""
                      />
                      <img
                        className={style.coursesbuylogo}
                        src="merchant_hub/about.png"
                        alt=""
                      />
                    </div>
                    <div className={style.coursesdetail}>
                      <h4 className={style.nameheading}>E-Commerce Platform</h4>
                      <span className={style.coursesdata}>
                        This website design for Live learning and Live Project internship.
                      </span>
                      <h5 className={style.learnmorebuy}>
                        HTML, CSS, JavaScript, and Bootstrap.
                      </h5>
                    </div>
                    <div className={style.buttonz}>
                      
                      <button
                        className={style.coursesbuybutton}
                        onClick={() =>
                          handlePreview([
                            "merchant_hub/home.png",
                            "merchant_hub/retailer_login.png",
                            "merchant_hub/about.png",
                          ])
                        }
                      >
                        Preview
                      </button>
                      <button className={style.coursesbuybutton}>
                        Source code <i className="fa-solid fa-download"></i>
                      </button>
                    </div>
                    </div>
             </div>
           </div>
          
           <div className={style.arrowContainer}>
             <button onClick={scrollLeft} className={style.leftArrow}>
               &lt;
             </button>
             <button onClick={scrollRight} className={style.rightArrow}>
               &gt;
             </button>
           </div>
         </div>
        )}
         </div> */}

<div className="container-fluid">
        {activeSection === "ecommerce" && (
           <div className={`${style.ecommerce} ${style.d} container`}>
           <div
             className={`${style.scrollContainer}`}
             ref={scrollContainerRef}
           >
             <div className={`${style.coursesbuyrow}`}>
               <div className={`${style.coursesbuy}`}>
                 <div className={style.courseslogo}>
                 <img
                        className={style.coursesbuylogo}
                        src="merchant_hub/home.png"
                        alt=""
                      />
                      <img
                        className={style.coursesbuylogo}
                        src="merchant_hub/retailer_login.png"
                        alt=""
                      />
                      <img
                        className={style.coursesbuylogo}
                        src="merchant_hub/about.png"
                        alt=""
                      />
                    </div>
                    <div className={style.coursesdetail}>
                      <h4 className={style.nameheading}>E-Commerce Platform</h4>
                      <span className={style.coursesdata}>
                        This website design for Live learning and Live Project internship.
                      </span>
                      <h5 className={style.learnmorebuy}>
                        HTML, CSS, JavaScript, and Bootstrap.
                      </h5>
                    </div>
                    <div className={style.buttonz}>
                      {/* Preview button triggers modal for multiple images */}
                      <button
                        className={style.coursesbuybutton}
                        onClick={() =>
                          handlePreview([
                            "merchant_hub/home.png",
                            "merchant_hub/retailer_login.png",
                            "merchant_hub/about.png",
                          ])
                        }
                      >
                        Preview
                      </button>
                      <button className={style.coursesbuybutton}>
                        Source code <i className="fa-solid fa-download"></i>
                      </button>
                    </div>
               </div>
             </div>

             {/* second row  */}
             {/* <div className={`${style.coursesbuyrow}`}>
               <div className={`${style.coursesbuy}`}>
                 <div className={style.courseslogo}>
                 <img
                        className={style.coursesbuylogo}
                        src="merchant_hub/home.png"
                        alt=""
                      />
                      <img
                        className={style.coursesbuylogo}
                        src="merchant_hub/retailer_login.png"
                        alt=""
                      />
                      <img
                        className={style.coursesbuylogo}
                        src="merchant_hub/about.png"
                        alt=""
                      />
                    </div>
                    <div className={style.coursesdetail}>
                      <h4 className={style.nameheading}>E-Commerce Platform</h4>
                      <span className={style.coursesdata}>
                        This website design for Live learning and Live Project internship.
                      </span>
                      <h5 className={style.learnmorebuy}>
                        HTML, CSS, JavaScript, and Bootstrap.
                      </h5>
                    </div>
                    <div className={style.buttonz}>
                      <button
                        className={style.coursesbuybutton}
                        onClick={() =>
                          handlePreview([
                            "merchant_hub/home.png",
                            "merchant_hub/retailer_login.png",
                            "merchant_hub/about.png",
                          ])
                        }
                      >
                        Preview
                      </button>
                      <button className={style.coursesbuybutton}>
                        Source code <i className="fa-solid fa-download"></i>
                      </button>
                    </div>
                    </div>
             </div> */}
           </div>
           <div className={style.arrowContainer}>
             <button onClick={scrollLeft} className={style.leftArrow}>
               &lt;
             </button>
             <button onClick={scrollRight} className={style.rightArrow}>
               &gt;
             </button>
           </div>
         </div>
        )}
         </div>

{/* <div className="container-fluid">
        {activeSection === "portfolio" && (
          <div className={`${style.portfolio} ${style.d} container`}>
          <div
            className={`${style.scrollContainer}`}
            ref={scrollContainerRef}
          >
            <div className={`${style.coursesbuyrow}`}>
              <div className={`${style.coursesbuy}`}>
                <div className={style.courseslogo}>
                <img
                        className={style.coursesbuylogo}
                        src="merchant_hub/home.png"
                        alt=""
                      />
                      <img
                        className={style.coursesbuylogo}
                        src="merchant_hub/retailer_login.png"
                        alt=""
                      />
                      <img
                        className={style.coursesbuylogo}
                        src="merchant_hub/about.png"
                        alt=""
                      />
                    </div>
                    <div className={style.coursesdetail}>
                      <h4 className={style.nameheading}>E-Commerce Platform</h4>
                      <span className={style.coursesdata}>
                        This website design for Live learning and Live Project internship.
                      </span>
                      <h5 className={style.learnmorebuy}>
                        HTML, CSS, JavaScript, and Bootstrap.
                      </h5>
                    </div>
                    <div className={style.buttonz}>
                      <button
                        className={style.coursesbuybutton}
                        onClick={() =>
                          handlePreview([
                            "merchant_hub/home.png",
                            "merchant_hub/retailer_login.png",
                            "merchant_hub/about.png",
                          ])
                        }
                      >
                        Preview
                      </button>
                      <button className={style.coursesbuybutton}>
                        Source code <i className="fa-solid fa-download"></i>
                      </button>
                    </div>
              </div>
            </div>

            <div className={`${style.coursesbuyrow}`}>
              <div className={`${style.coursesbuy}`}>
                <div className={style.courseslogo}>
                <img
                        className={style.coursesbuylogo}
                        src="merchant_hub/home.png"
                        alt=""
                      />
                      <img
                        className={style.coursesbuylogo}
                        src="merchant_hub/retailer_login.png"
                        alt=""
                      />
                      <img
                        className={style.coursesbuylogo}
                        src="merchant_hub/about.png"
                        alt=""
                      />
                    </div>
                    <div className={style.coursesdetail}>
                      <h4 className={style.nameheading}>E-Commerce Platform</h4>
                      <span className={style.coursesdata}>
                        This website design for Live learning and Live Project internship.
                      </span>
                      <h5 className={style.learnmorebuy}>
                        HTML, CSS, JavaScript, and Bootstrap.
                      </h5>
                    </div>
                    <div className={style.buttonz}>
                      <button
                        className={style.coursesbuybutton}
                        onClick={() =>
                          handlePreview([
                            "merchant_hub/home.png",
                            "merchant_hub/retailer_login.png",
                            "merchant_hub/about.png",
                          ])
                        }
                      >
                        Preview
                      </button>
                      <button className={style.coursesbuybutton}>
                        Source code <i className="fa-solid fa-download"></i>
                      </button>
                    </div>
              </div>
            </div>
          </div>
          <div className={style.arrowContainer}>
            <button onClick={scrollLeft} className={style.leftArrow}>
              &lt;
            </button>
            <button onClick={scrollRight} className={style.rightArrow}>
              &gt;
            </button>
          </div>
        </div>
        )}
      </div> */}
    </>
  );
};

export default Liveproject;
