import React from 'react';
import style from './../Css/Policies.module.css';

const PrivacyPolicy = () => {
  return (
    <div className='container px-1'>
      <div className={style.policy_container}>
        <h1 className={style.title}>Privacy Policy</h1>
        <p className={style.date}>Last updated: 17-11-2024</p>
        <p className={style.text}>
          At IITS CODE, accessible from https://iitscode.com/, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by IITS CODE and how we use it.
        </p>
        <p className={style.text}>If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.</p>
        <p className={style.text}>This Privacy Policy applies only to our online activities and is valid for visitors to our website with regard to the information that they shared and/or collect in IITS CODE. This policy is not applicable to any information collected offline or via channels other than this website. </p>
        <h2 className={style.section_title}>1. Consent</h2>
        <p className={style.text}>
          By using our website, you hereby consent to our Privacy Policy and agree to its terms.
        </p>
        <h2 className={style.section_title}>2. Information We Collect</h2>


        <p className={style.text}>
          The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.

          If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.

          When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.
          The personal information you are asked to provide, and the reasons why, will be made clear to you at the point of collection. This may include:
        </p>
        <ul className={style.text}>
          <li>Name, email address, phone number (when you contact us directly).</li>
          <li>Account information like name, company name, address, and telephone number (when you register).</li>
        </ul>

        <h2 className={style.section_title}>3. How We Use Your Information</h2>
        <p className={style.text}>
          We use your information in various ways, including:
        </p>
        <ul className={style.text}>
          <li>To provide, operate, and maintain our website.</li>
          <li>To improve and personalize user experience.</li>
          <li>To develop new products and features.</li>
          <li>To communicate with you for updates and marketing purposes.</li>
          <li>To prevent fraud and ensure security.</li>
        </ul>

        <h2 className={style.section_title}>4. Log Files</h2>
        <p className={style.text}>
          IITS CODE follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services’ analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users’ movement on the website, and gathering demographic information.
        </p>

        <h2 className={style.section_title}>5. Cookies and Web Beacons</h2>
        <p className={style.text}>
          Like any other website, IITS CODE uses ‘cookies’. These cookies are used to store information including visitors’ preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users’ experience by customizing our web page content based on visitors’ browser type and/or other information.
        </p>

        <h2 className={style.section_title}>6. Google DoubleClick DART Cookie</h2>
        <p className={style.text}>
          Google is one of a third-party vendor on our site. It also uses cookies, known as DART cookies, to serve ads to our site visitors based upon their visit to www.website.com and other sites on the internet. However, visitors may choose to decline the use of DART cookies by visiting the Google ad and content network Privacy Policy at the following URL 

 
        </p>

        <small>https://policies.google.com/technologies/ads</small>

        <h2 className={style.section_title}>7. Advertising Partners</h2>
        <p className={style.text}>
          You may consult this list to find the Privacy Policy for each of the advertising partners of IITS CODE.

          Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on IITS CODE , which are sent directly to users’ browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.

          Note that IITS CODE has no access to or control over these cookies that are used by third-party advertisers.
        </p>

        <h2 className={style.section_title}>Third Party Privacy Policies</h2>
        <p className={style.text}>IITS CODE Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options.

          You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers’ respective websites.</p>

        <h2 className={style.section_title}>8. CCPA Privacy Rights (Do Not Sell My Personal Information)</h2>
        <p className={style.text}>
          Under the CCPA, among other rights, California consumers have the right to:  </p>
        <p className={style.text}>
          Request that a business that collects a consumer’s personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.
        </p>   <p className={style.text}>
          Request that a business delete any personal data about the consumer that a business has collected.
        </p>   <p className={style.text}>
          Request that a business that sells a consumer’s personal data, not sell the consumer’s personal data.
        </p>   <p className={style.text}>
          If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.
        </p>

        <h2 className={style.section_title}>9. GDPR Data Protection Rights</h2>
        <ul className={style.text}>
          <li>  We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:</li>
          <li>The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service.</li>
          <li>The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.</li>
          <li>The right to erasure – You have the right to request that we erase your personal data, under certain conditions.</li>
          <li>The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.</li>
          <li>The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.
          </li>

          <li>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.
          </li>




        </ul>

        <h2 className={style.section_title}>10. Children's Information</h2>
        <p className={style.text}>
        Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.
        </p>
        <p className={style.text}>
    
IITS CODE does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.
  </p>

     <h2 className={style.section_title}>11. Changes to This Privacy Policy</h2>
        <p className={style.text}>
          We may update this Privacy Policy. Any changes will be posted on this page.
        </p>

        <h2 className={style.section_title}>12. Contact Us</h2>
        <p className={style.text}>
          If you have any questions about this Privacy Policy, please contact us at <a href="mailto:support@iitscode.com">support@iitscode.com</a>  .
        </p>
      </div>

    </div>
  );
};

export default PrivacyPolicy;
