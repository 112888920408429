import React from 'react';
import style from './../Css/Policies.module.css';

const CancellationAndRefund = () => {
  const currentDate = new Date().toLocaleDateString();

  return (
    <div className='container px-1'>
    <div className={style.policy_container}>
    <h1 className={style.title}>Cancellation and Refund Policy</h1>

    <h2 className={style.section_title}>1. No Refund Policy</h2>
    <p className={style.text}>
      We do not provide refunds to users. All sales are final, and payment made by you is non-refundable under any circumstances.
    </p>

    <h2 className={style.section_title}>2. Acceptable Use</h2>
    <p className={style.text}>
      You must not use this website in any way that causes or may cause damage to the website, impairs its availability or accessibility, or is unlawful, illegal, fraudulent, or harmful.
    </p>
    <p className={style.text}>
      You are prohibited from using the website to copy, store, host, transmit, send, use, publish, or distribute any material that consists of (or is linked to) spyware, viruses, Trojan horses, worms, keystroke loggers, rootkits, or other malicious software.
    </p>
    <p className={style.text}>
      You must not conduct any systematic or automated data collection activities (including, but not limited to, scraping, data mining, data extraction, and data harvesting) without IITS CODE express written consent.
    </p>

    <h2 className={style.section_title}>3. Payments and Refunds</h2>
    <p className={style.text}>
      By purchasing products or services through this website, you agree to pay for all charges, including any taxes and late fees. You are responsible for providing a valid payment method.
    </p>
    <p className={style.text}>
      All sales are final. Discounts are given at our sole discretion, and we are not obligated to provide any discount. Receipts for payments will be sent via email upon successful transaction.
    </p>
    <p className={style.text}>
      Prices for goods or services may change at any time and will be clearly announced on our website. By making a purchase, you agree to our cancellation and refund policy.
    </p>

    <h2 className={style.section_title}>4. Shipping Policy</h2>
    <p className={style.text}>
      IITS CODE ships products via Indian Registered Post. We are not liable for any loss or delay caused by Indian Post services during transit. A tracking ID will be provided via email once your order is shipped. The expected delivery time is 7-10 days.
    </p>

    <h2 className={style.section_title}>5. Limitations of Liability</h2>
    <p className={style.text}>
   IITS CODE will not be liable to you in relation to the contents of, or use of, or otherwise in connection with, this website:  </p>
    <ul className={style.text}>
      <li>Direct loss if the website is provided free of charge.</li>
      <li>Any indirect, special, or consequential loss.</li>
      <li>Losses related to business, revenue, income, profits, anticipated savings, contracts, business relationships, reputation, goodwill, or data corruption.</li>
    </ul>
    <p className={style.text}>
      These limitations apply even if IITS CODE has been advised of the potential loss.
    </p>

    <h2 className={style.section_title}>6. Exceptions</h2>
    <p className={style.text}>
    Nothing in this website disclaimer will exclude or limit any warranty implied by law that it would be unlawful to exclude or limit; and nothing in this website disclaimer will exclude or limit IITS CODE liability in respect of any  </p>
    <ul className={style.text}>
      <li>Death or personal injury caused by IITS CODE negligence.</li>
      <li>Fraud or fraudulent misrepresentation by IITS CODE.</li>
      <li>Any matter which it would be illegal or unlawful for IITS CODE to exclude or limit its liability.</li>
    </ul>
  </div>  </div>
  );
};

export default CancellationAndRefund;
