import React, { useState } from 'react';
import style from './../../Css/Courses.module.css'

function Ccoursedata() {
  // State to keep track of the selected chapter
  const [selectedChapter, setSelectedChapter] = useState('chapter1')

  // Function to handle chapter selection
  const handleChapterClick = (chapter) => {
    setSelectedChapter(chapter)
  }

  // State to track the active chapter
  const [activeChapter, setActiveChapter] = useState('chapter1');

  // Function to handle chapter click
  const handleChapterActive = (chapter) => {
    setActiveChapter(chapter);
  };
  const handleClick = (chapter) => {
    handleChapterActive(chapter);
    handleChapterClick(chapter);
    window.scrollTo(0, 0);
  };


  return (
    <div className={`${style.firstcontainer} container-fluid`}>

      <div className={`${style.therow} row`}>

        <div className={`${style.droupdownbtnbar} dropdown`}>
          <button className={`${style.droupdownbtn} btn dropdown-toggle`} type="button" data-bs-toggle="dropdown" aria-expanded="false">
            C
          </button>
          <ul className={`${style.dropdownmenu} dropdown-menu`}>

            <li
              onClick={() => handleClick('chapter1')}

              className={`${style.chapter1} ${activeChapter === 'chapter1' ? style.active : ''} text-decoration-none dropdown-item`}
            > Introduction
            </li>
            <li onClick={() => handleClick('chapter2')}
              className={`${style.chapter2} ${activeChapter === 'chapter2' ? style.active : ''}  dropdown-item `}>
              Compilation and Execution
            </li>

            <h5>Basic Elements of C</h5>
            <li
              onClick={() => handleClick('chapter3')}
              className={`${style.chapter3} ${activeChapter === 'chapter3' ? style.active : ''}  dropdown-item `}
            > Variables

            </li>

            <li onClick={() => handleClick('chapter4')}
              className={`${style.chapter4} ${activeChapter === 'chapter4' ? style.active : ''}  dropdown-item `}>
              Constants
            </li>



            <li onClick={() => handleClick('chapter5')}
              className={`${style.chapter5} ${activeChapter === 'chapter5' ? style.active : ''}  dropdown-item `}>
              Keywords
            </li>


            <li onClick={() => handleClick('chapter6')}
              className={`${style.chapter6} ${activeChapter === 'chapter6' ? style.active : ''}  dropdown-item `}>
              Comments
            </li>


            <li onClick={() => handleClick('chapter7')}
              className={`${style.chapter7} ${activeChapter === 'chapter7' ? style.active : ''}  dropdown-item `}>
              Format Specifiers
            </li>


            <h5>Input and Outut</h5>

            <li onClick={() => handleClick('chapter8')}
              className={`${style.chapter8} ${activeChapter === 'chapter8' ? style.active : ''}  dropdown-item `}>
              Output
            </li>

            <li onClick={() => handleClick('chapter9')}
              className={`${style.chapter9} ${activeChapter === 'chapter9' ? style.active : ''}  dropdown-item `}>
              Instructions
            </li>


            <h5>Operators and Control Flow</h5>

            <li onClick={() => handleClick('chapter10')}
              className={`${style.chapter10} ${activeChapter === 'chapter10' ? style.active : ''}  dropdown-item `}>
              Operators
            </li>

            <li onClick={() => handleClick('chapter11')}
              className={`${style.chapter11} ${activeChapter === 'chapter11' ? style.active : ''}  dropdown-item`}>
              Conditional Instructions
            </li>

            <li onClick={() => handleClick('chapter12')}
              className={`${style.chapter12} ${activeChapter === 'chapter12' ? style.active : ''} dropdown-item `}>
              Loop Control Instruction
            </li>


            <h5>Data Structures</h5>

            <li onClick={() => handleClick('chapter13')}
              className={`${style.chapter13} ${activeChapter === 'chapter13' ? style.active : ''}  dropdown-item `}>
              Array
            </li>


            <li onClick={() => handleClick('chapter14')}
              className={`${style.chapter14} ${activeChapter === 'chapter14' ? style.active : ''}  dropdown-item `}>
              Strings
            </li>

            <li onClick={() => handleClick('chapter15')}
              className={`${style.chapter15} ${activeChapter === 'chapter15' ? style.active : ''}  dropdown-item `}>
              Structures
            </li>

            <li onClick={() => handleClick('chapter16')}
              className={`${style.chapter16} ${activeChapter === 'chapter16' ? style.active : ''}  dropdown-item `}>
              Pointers
            </li>



            <h5>Functions</h5>

            <li onClick={() => handleClick('chapter17')}
              className={`${style.chapter17} ${activeChapter === 'chapter17' ? style.active : ''}  dropdown-item `}>
              Functions
            </li>

            <li onClick={() => handleClick('chapter18')}
              className={`${style.chapter18} ${activeChapter === 'chapter18' ? style.active : ''}  dropdown-item `}>
              Recursion
            </li>




          </ul>
        </div>





        <div className={`${style.leftcolumn} col-2`}>

          <ul className={`${style.chapters} `}>

            <h5 className={style.stickyheading} >C</h5>
            <li
              onClick={() => handleClick('chapter1')}

              className={`${style.chapter1} ${activeChapter === 'chapter1' ? style.active : ''} text-decoration-none`}
            >
              Introduction

            </li>

            <li onClick={() => handleClick('chapter2')}
              className={`${style.chapter2} ${activeChapter === 'chapter2' ? style.active : ''}`}>
              Compilation and Execution
            </li>

            <h5>Basic Elements of C</h5>
            <li
              onClick={() => handleClick('chapter3')}
              className={`${style.chapter3} ${activeChapter === 'chapter3' ? style.active : ''} `}
            > Variables

            </li>

            <li onClick={() => handleClick('chapter4')}
              className={`${style.chapter4} ${activeChapter === 'chapter4' ? style.active : ''}`}>
              Constants
            </li>



            <li onClick={() => handleClick('chapter5')}
              className={`${style.chapter5} ${activeChapter === 'chapter5' ? style.active : ''}`}>
              Keywords
            </li>


            <li onClick={() => handleClick('chapter6')}
              className={`${style.chapter6} ${activeChapter === 'chapter6' ? style.active : ''}`}>
              Comments
            </li>


            <li onClick={() => handleClick('chapter7')}
              className={`${style.chapter7} ${activeChapter === 'chapter7' ? style.active : ''}`}>
              Format Specifiers
            </li>


            <h5>Input and Outut</h5>

            <li onClick={() => handleClick('chapter8')}
              className={`${style.chapter8} ${activeChapter === 'chapter8' ? style.active : ''}`}>
              Output
            </li>

            <li onClick={() => handleClick('chapter9')}
              className={`${style.chapter9} ${activeChapter === 'chapter9' ? style.active : ''}`}>
              Instructions
            </li>


            <h5>Operators and Control Flow</h5>

            <li onClick={() => handleClick('chapter10')}
              className={`${style.chapter10} ${activeChapter === 'chapter10' ? style.active : ''}`}>
              Operators
            </li>

            <li onClick={() => handleClick('chapter11')}
              className={`${style.chapter11} ${activeChapter === 'chapter11' ? style.active : ''}`}>
              Conditional Instructions
            </li>

            <li onClick={() => handleClick('chapter12')}
              className={`${style.chapter12} ${activeChapter === 'chapter12' ? style.active : ''}`}>
              Loop Control Instruction
            </li>


            <h5>Data Structures</h5>

            <li onClick={() => handleClick('chapter13')}
              className={`${style.chapter13} ${activeChapter === 'chapter13' ? style.active : ''}`}>
              Array
            </li>


            <li onClick={() => handleClick('chapter14')}
              className={`${style.chapter14} ${activeChapter === 'chapter14' ? style.active : ''}`}>
              Strings
            </li>

            <li onClick={() => handleClick('chapter15')}
              className={`${style.chapter15} ${activeChapter === 'chapter15' ? style.active : ''}`}>
              Structures
            </li>

            <li onClick={() => handleClick('chapter16')}
              className={`${style.chapter16} ${activeChapter === 'chapter16' ? style.active : ''}`}>
              Pointers
            </li>



            <h5>Functions</h5>

            <li onClick={() => handleClick('chapter17')}
              className={`${style.chapter17} ${activeChapter === 'chapter17' ? style.active : ''}`}>
              Functions
            </li>

            <li onClick={() => handleClick('chapter18')}
              className={`${style.chapter18} ${activeChapter === 'chapter18' ? style.active : ''}`}>
              Recursion
            </li>







          </ul>
        </div>




        <div className={`${style.rightcolumn} col`}>
          {/* Chapter Content */}


          {selectedChapter === 'chapter1' && (
            <div className={style.chaptercontent}>
              <h1 className={style.heading}> Introduction</h1>

              <h2>What is Programming?</h2>
              <p>
                Computer programming is a way for humans to communicate with computers. Just like we use languages such as Hindi or English to communicate with each other, programming languages allow us to deliver instructions to the computer.
              </p><br />

              <h2>What is C?</h2>
              <p>
                C is a programming language, and one of the oldest and most influential. It was developed by Dennis Ritchie at AT&T’s Bell Labs, USA, in 1972. Despite its age, C is still widely used today due to its efficiency and control over system resources.
              </p><br />

              <h2>Uses of C</h2>
              <p>C is used in various applications, including:</p>
              <ol>
                <li>Major parts of operating systems like Windows, Linux, and macOS are written in C.</li><br />
                <li>C is used to write drivers for devices like printers, keyboards, and other hardware.</li><br />
                <li>It is popular for embedded systems programming, where programs need to run quickly with limited resources (e.g., microwaves, cameras, etc.).</li><br />
                <li>C is also used in game development, where performance and latency are critical to responding quickly to user inputs.</li>
              </ol> <br />

              <h2>C Basic Commands</h2>
              <table className={style.commandTable}>
                <thead>
                  <tr>
                    <th>C Basic Commands</th>
                    <th>Explanation</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><code>#include &lt;stdio.h&gt;</code></td>
                    <td>This includes the standard input-output header file (<code>stdio.h</code>) from the C library, necessary for functions like <code>printf</code> and <code>scanf</code>.</td>
                  </tr>
                  <tr>
                    <td><code>void main()</code></td>
                    <td>This is the main function where program execution begins in C.</td>
                  </tr>
                  <tr>
                    <td><code>{'{'}</code></td>
                    <td>Indicates the start of the <code>main</code> function.</td>
                  </tr>
                  <tr>
                    <td><code>printf("Hello, World!");</code></td>
                    <td>This command outputs text to the screen.</td>
                  </tr>
                  <tr>
                    <td><code>getch();</code></td>
                    <td>Pauses the program until the user presses a key. It is often used to keep the console window open.</td>
                  </tr>
                  <tr>
                    <td><code>{'}'}</code></td>
                    <td>Indicates the end of the <code>main</code> function.</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}




          {selectedChapter === 'chapter2' && (
            <div className={style.chaptercontent}>
              <h1 className={style.heading}>Compilation and Execution</h1>

              <p>
                A compiler is a program that converts a C program into machine language so that it can be understood by the computer. A C program is written in plain text, which consists of instructions in a particular sequence. The compiler performs basic checks and converts the program into an executable file.
              </p><br />

              <h2>Steps Involved in Compilation and Execution</h2>
              <ol>
                <li>
                  <strong>Preprocessing:</strong> The compiler processes preprocessor directives (like <code>#include</code> and <code>#define</code>) to prepare the code for compilation.
                </li><br />
                <li>
                  <strong>Compilation:</strong> The source code is translated into object code, which is a low-level representation of the program.
                </li><br />
                <li>
                  <strong>Assembly:</strong> The object code is converted into machine code by an assembler.
                </li><br />
                <li>
                  <strong>Linking:</strong> The linker combines the object code with libraries and other modules to create an executable file.
                </li><br />
                <li>
                  <strong>Loading:</strong> The operating system loads the executable into memory.
                </li><br />
                <li>
                  <strong>Execution:</strong> The CPU executes the machine code, running the program.
                </li>
              </ol><br />

              <h2 style={{ paddingBottom: "6px" }}>Importance of Compilation</h2>
              <ul>
                <li>
                  <strong>Error Detection:</strong> Compilers catch syntax and semantic errors before execution, making debugging easier.
                </li><br />
                <li>
                  <strong>Optimization:</strong> Compilers optimize the code to improve performance, reducing execution time and resource consumption.
                </li><br />
                <li>
                  <strong>Portability:</strong> The same source code can be compiled on different platforms to generate platform-specific executables.
                </li>
              </ul>
            </div>
          )}



          {selectedChapter === 'chapter3' && (
            <div className={style.chaptercontent}>
              <h1 className={style.heading}>Variables</h1>

              <p>
                A variable is a container that holds a value, similar to how a kitchen has containers for storing rice, dal, sugar, etc. In C, a variable stores the value of a constant.
              </p><br />

              <h2>Examples:</h2>
              <ul>
                <li><code>a = 3;</code> &nbsp; // <em>a</em> is assigned the value 3</li><br />
                <li><code>b = 4.7;</code> &nbsp; // <em>b</em> is assigned the value 4.7</li><br />
                <li><code>c = 'A';</code> &nbsp; // <em>c</em> is assigned the character 'A'</li>
              </ul><br />

              <h2 style={{ paddingBottom: "6px" }}>Rules for Naming Variables in C</h2>
              <ol>
                <li>The first character must be an alphabet or underscore (<code>_</code>).</li><br />
                <li>Commas and blank spaces are not allowed.</li><br />
                <li>No special symbols are allowed, except for the underscore (<code>_</code>).</li><br />
                <li>Variable names are case-sensitive.</li><br />
                <li>There is no strict limit on the length of variable names, but some compilers may have issues if the name exceeds 31 characters.</li>
              </ol><br />

              <p>
                It's important to create meaningful variable names to enhance the readability of your programs.
              </p>
            </div>
          )}



          {selectedChapter === 'chapter4' && (
            <div className={style.chaptercontent}>

              <h1 className={style.heading}>Constants</h1>

              <p>A variable is an entity whose value can be changed, but an entity whose value doesn’t change is called a constant. Constants are essential for defining fixed values in your programs, which improves readability and maintainability.</p><br />

              <h3 style={{ paddingBottom: "6px" }}>Types of Constants</h3>
              <ol>
                <li>
                  <strong>Integer Constants</strong> ➔ These are whole numbers that can be positive, negative, or zero.
                  <p>Examples: <code>-1, 0, 100, 25</code></p>
                </li><br />
                <li>
                  <strong>Real Constants</strong> ➔ These are numbers with a fractional part, also known as floating-point numbers.
                  <p>Examples: <code>-322.1, 2.5, 7.0, 3.14</code></p>
                </li><br />
                <li>
                  <strong>Character Constants</strong> ➔ These are single characters enclosed in single quotes.
                  <p>Examples: <code>'a', '$', '@', '1'</code></p>
                </li><br />
                <li>
                  <strong>String Constants</strong> ➔ These are sequences of characters enclosed in double quotes.
                  <p>Examples: <code>"Hello, World!", "C Programming"</code></p>
                </li>
              </ol><br />

              <h3 style={{ paddingBottom: "6px" }}>Using Constants in C</h3>
              <p>In C, you can define constants using the <code>#define</code> preprocessor directive or the <code>const</code> keyword. Here’s how to use both:</p><br />

              <h4>Using #define</h4>
              <pre>
                <code>{`#define PI 3.14\n\nint main() {\n    printf("Value of PI: %f", PI);\n    return 0;\n}`}</code>
              </pre><br />

              <h4>Using const</h4>
              <pre>
                <code>{`const float pi = 3.14;\n\nint main() {\n    printf("Value of PI: %f", pi);\n    return 0;\n}`}</code>
              </pre><br />

              <h3 style={{ paddingBottom: "6px" }}>Advantages of Using Constants</h3>
              <ul>
                <li><strong>Readability:</strong> Using descriptive names for constants makes your code easier to read and understand.</li><br />
                <li><strong>Maintainability:</strong> If a constant value needs to change, you only need to update it in one place.</li><br />
                <li><strong>Prevents Errors:</strong> Constants help prevent accidental modification of values that should remain unchanged.</li>
              </ul><br />

              <h3 style={{ paddingBottom: "6px" }}>Example: Using Constants in a Program</h3>
              <pre>
                <code>{`#include <stdio.h>\n#define GRAVITY 9.81\n\nint main() {\n    float mass = 70; // mass in kg\n    float weight = mass * GRAVITY;\n    printf("Weight: %.2f N\\n", weight);\n    return 0;\n}`}</code>
              </pre>

              <p>In this example, <code>GRAVITY</code> is defined as a constant using the <code>#define</code> directive. This makes it clear that the value of gravity should not change throughout the program.</p><br />

              <h3 style={{ paddingBottom: "6px" }}>Summary</h3>
              <p>Constants play a vital role in C programming by providing fixed values that can enhance code clarity and stability. Understanding how to use constants effectively is crucial for writing robust programs.</p>


            </div>
          )}




          {selectedChapter === 'chapter5' && (
            <div className={style.chaptercontent}>
              <h1 className={style.heading}>Keywords</h1>
              <p>
                Keywords are reserved words that have predefined meanings in C and cannot be used for any other purpose. There are 32 keywords in C:
              </p><br />

              <code><strong > auto, double, int, struct, break, long, else, switch, case, return, enum, typedef, char, register, extern, union, const, short, float, unsigned, continue, signed, for, void, default, sizeof, goto, volatile, do, static, if, while
              </strong> </code> <br /><br />

              <h2>Our First C Program</h2>
              <pre>
                <code>
                  {`#include <stdio.h>
#include <conio.h>

void main() {
    printf("Hello, I am learning C with IITS");
    getch();
}`}
                </code>
              </pre><br />

              <h2>Basic Structure of a C Program</h2>
              <p>All C programs follow a basic structure. The following rules apply to all C programs:</p>
              <ol>
                <li>Program execution starts from the <code>main()</code> function.</li><br />
                <li>All statements must end with a semicolon (<code>;</code>).</li><br />
                <li>Instructions are case-sensitive.</li><br />
                <li>Instructions are executed in the order they are written.</li>
              </ol>
            </div>
          )}




          {selectedChapter === 'chapter6' && (
            <div className={style.chaptercontent}>
              <h1 className={style.heading}>Comments</h1>

              <p>
                Comments are used to add explanations or notes to a program in plain language. They help clarify the code for anyone reading it, including the programmer themselves.
              </p><br />

              <h2 style={{ paddingBottom: "6px" }}>Types of Comments in C</h2>
              <ol>
                <li>
                  <strong>Single-line comment:</strong> <code>// This is a comment</code>
                </li><br />
                <li>
                  <strong>Multi-line comment:</strong> <code>/* This is a multi-line comment */</code>
                </li>
              </ol><br />

              <p>
                Comments are not executed by the compiler and are ignored during program execution.
              </p>

              <h2 style={{ paddingBottom: "6px" }}>Why Use Comments?</h2>
              <ul>
                <li><strong>Improving Readability:</strong> Comments help explain complex logic or algorithms, making the code easier to read and understand.</li><br />
                <li><strong>Documentation:</strong> They can serve as documentation for future reference, helping others (or yourself) when returning to the code later.</li><br />
                <li><strong>Debugging:</strong> Temporarily commenting out code helps in debugging and isolating issues without deleting any part of the code.</li><br />
                <li><strong>Version Control:</strong> Comments can provide context for changes made in the code, which is useful in version control systems.</li><br />
                <li><strong>Code Maintenance:</strong> Well-commented code is easier to maintain, as the intent and purpose of the code become clearer.</li>
              </ul><br />
            </div>
          )}



          {selectedChapter === 'chapter7' && (
            <div className={style.chaptercontent}>
              <h1 className={style.heading}>Format Specifiers</h1>

              <p>
                Format specifiers define the type of data to be printed on standard output.
                They are essential when printing formatted output with <code>printf()</code>
                or accepting input with <code>scanf()</code>.
              </p>
              <p>For example:</p>
              <pre>
                <code>printf("This is %d", i);</code>
              </pre><br />

              <h3 style={{ paddingBottom: "6px" }}>Common Format Specifiers</h3>
              <ul>
                <li><code>%d</code> → for integers</li><br />
                <li><code>%f</code> → for real (floating-point) values</li><br />
                <li><code>%c</code> → for characters</li><br />
                <li><code>%s</code> → for strings</li>
              </ul><br />

              <h2>Receiving Input from the User</h2>
              <p>
                To take input from the user and assign it to a variable, use the <code>scanf()</code> function.
              </p>
              <pre>
                <code>scanf("%d", &i);</code>
              </pre>
              <p>
                The <code>&</code> symbol (address of operator) means the value should be stored at the memory address of the variable <code>i</code>.
              </p>

              <pre>
                <code>
                  {`void main() {
    int test;
    printf("Enter a number: ");
    scanf("%d", &test);
    printf("Number = %d", test);
    getch();
}`}
                </code>
              </pre><br />

              <h2 style={{ paddingBottom: "6px" }}>Sample C Programs</h2>

              <h3>1. C Program to Add Two Integers</h3>
              <pre>
                <code>
                  {`#include <stdio.h>
#include <conio.h>

void main() {
    int num1, num2, sum;

    printf("Enter the value of num1: ");
    scanf("%d", &num1);

    printf("Enter the value of num2: ");
    scanf("%d", &num2);

    sum = num1 + num2;
    printf("%d + %d = %d", num1, num2, sum);

    getch();
}`}
                </code>
              </pre><br />

              <h3>2. C Program to Calculate the Area of a Rectangle</h3>

              <h4>a. Using Hard-Coded Inputs</h4>
              <pre >
                <code>
                  {`#include <stdio.h>
#include <conio.h>

void main() {
    int length = 3, breadth = 8;
    int area = length * breadth;

    printf("The area of this rectangle is %d", area);

    getch();
}`}
                </code>
              </pre><br />

              <h4>b. Using User-Supplied Inputs</h4>
              <pre>
                <code>
                  {`#include <stdio.h>
#include <conio.h>

void main() {
    int length, breadth;

    printf("What is the length of the rectangle? ");
    scanf("%d", &length);

    printf("What is the breadth of the rectangle? ");
    scanf("%d", &breadth);

    printf("The area of your rectangle is %d", length * breadth);

    getch();
}`}
                </code>
              </pre><br />

              <h3>3. C Program to Calculate the Area of a Circle</h3>
              <pre>
                <code>
                  {`#include <stdio.h>
#include <conio.h>

void main() {
    int radius = 3;
    float pi = 3.14;

    printf("The area of this circle is %f", pi * radius * radius);

    getch();
}`}
                </code>
              </pre><br />

              <h3>4. C Program to Calculate the Volume of a Cylinder</h3>
              <pre>
                <code>
                  {`#include <stdio.h>
#include <conio.h>

void main() {
    int radius = 3;
    float pi = 3.14;

    printf("The area of the circle is %f\n", pi * radius * radius);
    int height = 3;
    printf("The volume of the cylinder is %f\n", pi * radius * radius * height);

    getch();
}`}
                </code>
              </pre><br />

              <h3>5. C Program to Convert Celsius to Fahrenheit</h3>
              <pre>
                <code>
                  {`#include <stdio.h>
#include <conio.h>

void main() {
    float celsius = 37, fahrenheit;
    fahrenheit = (celsius * 9 / 5) + 32;

    printf("The Celsius temperature in Fahrenheit is %f", fahrenheit);

    getch();
}`}
                </code>
              </pre><br />

              <h3>6. C Program to Calculate Simple Interest</h3>
              <pre>
                <code>
                  {`#include <stdio.h>
#include <conio.h>

void main() {
    int principal = 100, rate = 4, years = 1;
    int simpleInterest = (principal * rate * years) / 100;

    printf("The value of Simple Interest is %d", simpleInterest);

    getch();
}`}
                </code>
              </pre>
            </div>
          )}





          {selectedChapter === 'chapter8' && (
            <div className={style.chaptercontent}>
              <h1 className={style.heading}>Output</h1>

              <p>
                In C programming, "output" refers to the information that a program displays to the user or sends to a file or device. This can include text, numbers, or other types of data. The output is typically generated using functions provided by libraries in C, such as:
              </p><br />

              <h2>Common Output Functions</h2>

              <ol>
                <li>
                  <strong>printf()</strong>:
                  <ul>
                    <li>Used for formatted output to the console.</li>
                    <li>Example:
                      <pre><code>printf("Hello, World!\\n");</code></pre>
                    </li>
                  </ul>
                </li><br />

                <li>
                  <strong>puts()</strong>:
                  <ul>
                    <li>Used to output a string followed by a newline.</li>
                    <li>Example:
                      <pre><code>puts("Hello, World!");</code></pre>
                    </li>
                  </ul>
                </li><br />

                <li>
                  <strong>putchar()</strong>:
                  <ul>
                    <li>Outputs a single character to the console.</li>
                    <li>Example:
                      <pre><code>putchar('A');</code></pre>
                    </li>
                  </ul>
                </li><br />

                <li>
                  <strong>File Output</strong>:
                  <ul>
                    <li>Write output to files using functions like <code>fprintf()</code>, <code>fputs()</code>, and <code>fwrite()</code>.</li>
                    <li>Example:
                      <pre><code>
                        FILE *file = fopen("output.txt", "w");<br />
                        fprintf(file, "Hello, File!\\n");<br />
                        fclose(file);
                      </code></pre>
                    </li>
                  </ul>
                </li>
              </ol><br />

              <h2 style={{ paddingBottom: "6px" }}>Key Points</h2>
              <ul>
                <li><strong>Format Specifiers:</strong> <code>printf()</code> uses format specifiers (like <code>%d</code> for integers, <code>%f</code> for floats, <code>%s</code> for strings) to determine how to display different data types.</li><br />
                <li><strong>Standard Output:</strong> By default, C programs output to the standard output stream (usually the console).</li><br />
                <li><strong>Redirection:</strong> You can redirect output to a file or another command in a shell environment.</li>
              </ul><br />

              <h2>Example Program</h2>
              <pre>
                <code>
                  {`#include <stdio.h>

int main() {
    int number = 42;
    float pi = 3.14;

    // Using printf for formatted output
    printf("The answer is: %d\\n", number);
    printf("The value of pi is approximately: %f\\n", pi);

    // Using puts for string output
    puts("This is a simple output example.");

    return 0;
}`}
                </code>
              </pre>

              <h2>Output of the Example Program</h2>
              <pre>
                <code>
                  The answer is: 42<br />
                  The value of pi is approximately: 3.140000<br />
                  This is a simple output example.
                </code>
              </pre><br />

              <h2>Conclusion</h2>
              <p>
                In summary, output in C programming is the means by which a program communicates results, data, or messages to the user or to files.
              </p>
            </div>
          )}

          {selectedChapter === 'chapter9' && (
            <div className={style.chaptercontent}>
              <h1 className={style.heading}> Instructions</h1>

              <p>
                A C program is a set of instructions, similar to a recipe that contains steps to prepare a particular dish.
              </p><br />

              <h2>Types of Instructions</h2>
              <ol>
                <li>Type Declaration Instruction</li><br />
                <li>Arithmetic Instruction</li><br />
                <li>Control Instruction</li>
              </ol><br />

              <h2>Type Declaration Instruction</h2>
              <p>
                Type declaration instructions specify the type of a variable. A variable declaration tells the compiler two things:
              </p>
              <ul>
                <li>The name of the variable</li><br />
                <li>The type of the variable</li>
              </ul>
              <p>Examples:</p>
              <pre>
                <code>
                  {`int a;\nfloat b;`}
                </code>
              </pre><br />

              <h3>Other Variations:</h3>
              <pre>
                <code>
                  {`int i = 10;\nint j = i;\nint a = 2;\n\nj1 = a + j - i;\n\nfloat b = a + 3; // Successfully compiled\nfloat a = 1.1;\n\nint a, b, c, d; // ERROR! Trying to use "a" before defining it.\na = b = c = d = 30; // Value of a, b, c, and d will be 30 each`}
                </code>
              </pre><br />

              <h2>Arithmetic Instruction</h2>
              <p>Operands can be <code>int</code> or <code>float</code>, and the arithmetic operators include:</p>
              <ul>
                <li>+ (Addition)</li>
                <li>- (Subtraction)</li>
                <li>* (Multiplication)</li>
                <li>/ (Division)</li>
                <li>% (Modular Division)</li>
              </ul>

              <pre>
                <code>
                  {`int b = 2, c = 3;\nint z;\nz = b * c; // Valid\nb * c = z; // Invalid\n\n% returns the remainder (cannot be applied to float)\n% sign is the same as the numerator (e.g., -5 % 2 = -1)`}
                </code>
              </pre><br />

              <h3>Note:</h3>
              <p>No operator is assumed to be present:</p>
              <pre>
                <code>
                  {`int I = ab; // Invalid\nint I = a * b; // Valid`}
                </code>
              </pre>
              <p>
                There is no operator for exponentiation in C, but you can use <code>pow(x, y)</code> from <code>&lt;math.h&gt;</code>.
              </p><br />

              <h2>Type Conversion</h2>
              <p>An arithmetic operation between:</p>
              <ul>
                <li><strong>int and int</strong> &rarr; <strong>int</strong></li><br />
                <li><strong>int and float</strong> &rarr; <strong>float</strong></li><br />
                <li><strong>float and float</strong> &rarr; <strong>float</strong></li>
              </ul><br />

              <h4 style={{ paddingBottom: "6px" }}>Examples:</h4>
              <ul>
                <li><code>2 / 5</code> &rarr; <strong>0</strong></li><br />
                <li><code>2.0 / 5</code> &rarr; <strong>0.4</strong> — IMPORTANT!</li><br />
                <li><code>5 / 2</code> &rarr; <strong>2</strong></li><br />
                <li><code>5.0 / 2</code> &rarr; <strong>2.5</strong> — IMPORTANT!</li>
              </ul><br />

              <h2 style={{ paddingBottom: "6px" }}>Note:</h2>
              <ul>
                <li><code>int a = 3.5;</code> &rarr; In this case, <code>3.5</code> (float) will be demoted to <code>3</code> (int) because <code>a</code> cannot store floats.</li><br />
                <li><code>float a = 8;</code> &rarr; <code>a</code> will store <code>8.0</code>.</li>
              </ul><br />

              <h2 style={{ paddingBottom: "6px" }}>Operator Precedence in C</h2>
              <p>
                Is <code>3 * x - 8 * y</code> evaluated as <strong>(3x) - (8y)</strong> or <strong>3(x - 8y)</strong>?
              </p>
              <p>
                In C language, simple mathematical rules like BODMAS no longer apply. The answer is provided by operator precedence and associativity.
              </p><br />

              <h2>Operator Priority</h2>
              <p>The following table lists the operator priority in C:</p>
              <table className={style.operatorTable}>
                <thead>
                  <tr>
                    <th>Priority</th>
                    <th>Operators</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>*, /, %</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>+, -</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>=</td>
                  </tr>
                </tbody>
              </table>
              <p>Operators of higher priority are evaluated first in the absence of parentheses.</p><br />

              <h3>Operator Associativity</h3>
              <p>
                When operators of equal priority are present in an expression, the tie is resolved by associativity. For example:
              </p>
              <ul>
                <li><code>x * y / z</code> → <code>(x * y) / z</code></li><br />
                <li><code>x / y * z</code> → <code>(x / y) * z</code></li>
              </ul>
              <p>* and / follow left-to-right associativity.</p>

              <h2>Control Instructions</h2>
              <p>Control instructions determine the flow of control in a program. The four types of control instructions in C are:</p>
              <ul>
                <li>Sequence control instruction</li><br />
                <li>Decision control instruction</li><br />
                <li>Loop control instruction</li><br />
                <li>Case control instruction</li>
              </ul>
            </div>
          )}

          {selectedChapter === 'chapter10' && (
            <div className={style.chaptercontent}>
              <h1 className={style.heading}>Operators</h1>




              <h3>Arithmetic Operators</h3>
              <p>An operator is a special symbol that tells the compiler to perform specific mathematical or logical operations. The following table lists arithmetic operators in C:</p>
              <table className={style.operatorTable}>
                <thead>
                  <tr>
                    <th>Operator</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr><td>+</td><td>Addition</td></tr>
                  <tr><td>-</td><td>Subtraction</td></tr>
                  <tr><td>*</td><td>Multiplication</td></tr>
                  <tr><td>/</td><td>Division</td></tr>
                  <tr><td>%</td><td>Modulus</td></tr>
                  <tr><td>++</td><td>Pre-increment and post-increment</td></tr>
                  <tr><td>--</td><td>Pre-decrement and post-decrement</td></tr>
                </tbody>
              </table>
              <p>Example:</p>
              <pre>
                <code>
                  {`#include <stdio.h>
void main() {
  int a = 10, b = 3, c;
  c = a + b;
  printf("Total (a + b) = %d\\n", c);

  c = a - b;
  printf("Sub (a - b) = %d\\n", c);

  c = a * b;
  printf("Mul (a * b) = %d\\n", c);

  c = a / b;
  printf("Div (a / b) = %d\\n", c);

  c = a % b;
  printf("Remainder (a %% b) = %d\\n", c);
}`}
                </code>
              </pre><br />

              <h3>Relational Operators</h3>
              <p>These operators are used to compare values and always result in a Boolean value (True or False). The following table lists relational operators in C:</p>
              <table className={style.operatorTable}>
                <thead>
                  <tr>
                    <th>Operator</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr><td>&gt;</td><td>Greater than</td></tr>
                  <tr><td>&lt;</td><td>Less than</td></tr>
                  <tr><td>&gt;=</td><td>Greater than or equal to</td></tr>
                  <tr><td>&lt;=</td><td>Less than or equal to</td></tr>
                  <tr><td>==</td><td>Equal to</td></tr>
                  <tr><td>!=</td><td>Not equal to</td></tr>
                </tbody>
              </table>
              <p>Example:</p>
              <pre>
                <code>
                  {`#include <stdio.h>
void main() {
  int a = 10, b = 20;
  printf("%d == %d result %d\\n", a, b, a == b);
  printf("%d > %d result %d\\n", a, b, a > b);
  printf("%d < %d result %d\\n", a, b, a < b);
  printf("%d != %d result %d\\n", a, b, a != b);
  printf("%d >= %d result %d\\n", a, b, a >= b);
  printf("%d <= %d result %d\\n", a, b, a <= b);
}`}
                </code>
              </pre>
              <br />

              <h3>Logical Operators</h3>
              <p>These operators are used to combine two or more conditions. The following table lists logical operators in C:</p>
              <table className={style.operatorTable}>
                <thead>
                  <tr>
                    <th>Operator</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr><td>&&</td><td>Logical And</td></tr>
                  <tr><td>||</td><td>Logical Or</td></tr>
                  <tr><td>!</td><td>Logical Not</td></tr>
                </tbody>
              </table>
              <p>Example:</p>
              <pre>
                <code>
                  {`#include <stdio.h>
void main() {
  int a = 30, b = 10, c = 10;
  if(a > b && a > c)
    printf("\\n a is greater than b and c ");
  else
    printf("\\n a is not greater than b and c ");
  if(a > b || b > c)
    printf("\\n a is greater than b or c ");
  else
    printf("\\n a is not greater than b or c ");
  if(!(a > b))
    printf("\\n a is not greater than b");
}`}
                </code>
              </pre>
              <br />

              <h3>Bitwise Operators</h3>
              <p>These operators work on bits of a binary number and perform bit-by-bit operations. The following table lists bitwise operators in C:</p>
              <table className={style.operatorTable}>
                <thead>
                  <tr>
                    <th>Operator</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr><td>|</td><td>Bitwise Or</td></tr>
                  <tr><td>&amp;</td><td>Bitwise And</td></tr>
                  <tr><td>~</td><td>Bitwise Not</td></tr>
                  <tr><td>^</td><td>Bitwise Exclusive Or</td></tr>
                </tbody>
              </table>
              <p>Example:</p>
              <pre>
                <code>
                  {`#include <stdio.h>
void main() {
  int m = 10, n = 20, AND, OR, XOR, NOT;
  AND = (m & n);
  OR = (m | n);
  NOT = (~m);
  XOR = (m ^ n);
  printf("AND value = %d\\n", AND);
  printf("OR value = %d\\n", OR);
  printf("NOT value = %d\\n", NOT);
  printf("XOR value = %d\\n", XOR);
}`}
                </code>
              </pre>

              <br />


              <h3>Assignment Operators</h3>
              <p>These operators are used to assign a new value to a variable. The following table lists assignment operators in C:</p>
              <table className={style.operatorTable}>
                <thead>
                  <tr>
                    <th>Operator</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr><td>=</td><td>Equal to</td></tr>
                  <tr><td>+=</td><td>Plus Equal to</td></tr>
                  <tr><td>-=</td><td>Minus Equal to</td></tr>
                  <tr><td>*=</td><td>Multiply Equal to</td></tr>
                  <tr><td>/=</td><td>Divide Equal to</td></tr>
                  <tr><td>%=</td><td>Modulus Equal to</td></tr>
                </tbody>
              </table>
              <p>Example:</p>
              <pre>
                <code>
                  {`#include <stdio.h>
void main() {
  int a = 10, b = a;
  printf("b = %d\\n", b);
  b += a;
  printf("b += a: %d\\n", b);
  b -= a;
  printf("b -= a: %d\\n", b);
  b *= a;
  printf("b *= a: %d\\n", b);
  b /= a;
  printf("b /= a: %d\\n", b);
  b %= a;
  printf("b %%= a: %d\\n", b);
}`}
                </code>
              </pre>

            </div>
          )}


          {selectedChapter === 'chapter11' && (
            <div className={style.chaptercontent}>
              <h1 className={style.heading}>Conditional Instructions</h1>
              <p>
                We make decisions based on conditions every day, like watching movies on Netflix if it's Sunday or ordering cake for a friend's birthday. In programming, especially in C, we also need to execute instructions based on certain conditions. The main decision-making constructs in C are:
              </p>
              <ul>
                <li>If-else statement</li><br />
                <li>Switch statement</li>
              </ul><br />

              <h2>If-Else Statement</h2>
              <p style={{ paddingBottom: "6px" }}>The syntax of an if-else statement in C looks like this:</p>
              <pre>
                <code>{`if (condition) {\n  // true-condition-statement\n} else {\n  // false-condition-statement\n}`}</code>
              </pre>

              <h3>Example:</h3>
              <pre>
                <code>{`int age = 23;\nif (age > 18) {\n  printf("You can drive");\n}`}</code>
              </pre>
              <p >Note: The else block is optional.</p><br />

              <h3>Else-If Clause</h3>
              <p>Instead of using multiple if statements, we can chain conditions using else if:</p>
              <pre>
                <code>{`if (condition) {\n  // Statement\n} else if (condition) {\n  // Statement\n} else {\n  // Statement\n}`}</code>
              </pre>
              <p>This reduces indentation and makes the code cleaner. The last "else" is executed only if all previous conditions fail.</p><br />

              <h3>Operator Precedence</h3>
              <table className={style.operatorTable}>
                <thead>
                  <tr>
                    <th>Priority</th>
                    <th>Operators</th>
                  </tr>
                </thead>
                <tbody>
                  {[
                    { priority: 1, operators: '!' },
                    { priority: 2, operators: '* , / , %' },
                    { priority: 3, operators: '+ , -' },
                    { priority: 4, operators: '< , > , <= , >=' },
                    { priority: 5, operators: '== , !=' },
                    { priority: 6, operators: '&&' },
                    { priority: 7, operators: '||' },
                    { priority: 8, operators: '=' },
                  ].map(({ priority, operators }) => (
                    <tr key={priority}>
                      <td>{priority}</td>
                      <td>{operators}</td>
                    </tr>
                  ))}
                </tbody>
              </table><br />

              <h2>Conditional Operator</h2>
              <p style={{ paddingBottom: "6px" }}>
                The conditional operator, also known as the ternary operator, is a concise way to perform conditional expressions in C programming. It is represented by the <code>?:</code> syntax and allows you to evaluate a condition and return one of two values based on whether the condition is true or false.
              </p>

              <h2>Syntax</h2>
              <pre><code>condition ? expression_if_true : expression_if_false;</code></pre><br />

              <h2 style={{ paddingBottom: "6px" }}>Explanation</h2>
              <ul>
                <li>Condition: This is an expression that evaluates to either true (non-zero) or false (zero).</li><br />
                <li>Expression if True: This expression is returned if the condition is true.</li><br />
                <li>Expression if False: This expression is returned if the condition is false.</li>
              </ul><br />

              <h4>Example</h4>
              <p>Here's a simple example that demonstrates the use of the conditional operator:</p>
              <pre>
                <code>{`#include <stdio.h>\n\nint main() {\n    int a = 10, b = 20;\n    int max;\n\n    // Using the conditional operator to find the maximum of two numbers\n    max = (a > b) ? a : b;\n\n    printf("The maximum value is: %d\\n", max);\n    return 0;\n}`}</code>
              </pre>

              <h2 style={{ paddingBottom: "6px" }}>Explanation of the Example</h2>
              <ul>
                <li>Condition: <code>(a{" >"}b)</code> checks if <code>a</code> is greater than <code>b</code>.</li><br />
                <li>True Case: If <code>a</code> is greater, <code>max</code> will be assigned the value of <code>a</code>.</li><br />
                <li>False Case: If <code>a</code> is not greater, <code>max</code> will be assigned the value of <code>b</code>.</li>
              </ul><br />

              <h2 style={{ paddingBottom: "6px" }}>Advantages</h2>
              <ul>
                <li>Conciseness: It allows you to write conditional logic in a single line.</li><br />
                <li>Readability: For simple conditions, it can make the code easier to read.</li>
              </ul><br />

              <h2>Diagrammatic Representation</h2>
              <p>You can visualize the flow of the conditional operator like this:</p>
              <pre>
                <code>{`
        +-------------------+
        |    Condition       |
        |    (a > b)         |
        +-------------------+
             /        \\
           Yes        No
          /            \\
    +------------+  +------------+
    | Return a   |  | Return b   |
    +------------+  +------------+
      `}</code>
              </pre><br />

              <h2>Summary</h2>
              <p>The conditional operator in C is a powerful tool for simplifying conditional logic in your code. It allows for cleaner and more efficient code when dealing with straightforward conditions.</p><br />

              <h2>Switch Case Control Instruction</h2>
              <p>The switch case is used to choose between multiple alternatives for a given variable. It simplifies code readability compared to an if-else-if ladder:</p>
              <pre>
                <code>{`switch (integer_expression) {\n  case c1: // code\n  case c2: // code\n  case c3: // code\n  default: // code\n}`}</code>
              </pre>
              <p>When the integer expression matches a case, that case and all subsequent statements execute.</p>
              <p>Note:</p>
              <ul>
                <li>Cases can be in any order.</li><br />
                <li>Char values are allowed since they can be evaluated as integers.</li><br />
                <li>Switch statements can be nested, but this is rarely practiced.</li>
              </ul><br />

              <h4>Example:</h4>
              <pre>
                <code>{`switch (greeting) {\n  case 1: \n    printf("Good Morning");\n    break;\n}`}</code>
              </pre><br />

              <h2 style={{ paddingBottom: "6px" }}>Some C Programs</h2>

              <h3>1. Pass or Fail Program</h3>
              <pre>
                <code>{`#include <stdio.h>\n\nint main() {\n  int physics, chemistry, maths;\n  float total;\n  printf("Enter Physics Marks:\\n");\n  scanf("%d", &physics);\n  printf("Enter Chemistry Marks:\\n");\n  scanf("%d", &chemistry);\n  printf("Enter Maths Marks:\\n");\n  scanf("%d", &maths);\n  total = (physics + maths + chemistry) / 3;\n  if ((total < 40) || physics < 33 || maths < 33 || chemistry < 33) {\n    printf("Percentage is %.2f and you are fail\\n", total);\n  } else {\n    printf("Percentage is %.2f and you are pass\\n", total);\n  }\n  return 0;\n}`}</code>
              </pre><br />

              <h3>2. Income Tax Calculation Program</h3>
              <pre>
                <code>{`#include <stdio.h>\n\nint main() {\n  float tax = 0, income;\n  printf("Enter your income:\\n");\n  scanf("%f", &income);\n\n  if (income >= 250000 && income <= 500000) {\n    tax += 0.05 * (income - 250000);\n  }\n  if (income > 500000 && income <= 1000000) {\n    tax += 0.20 * (income - 500000);\n  }\n  if (income > 1000000) {\n    tax += 0.30 * (income - 1000000);\n  }\n  printf("Your net income tax to be paid this month is %.2f\\n", tax);\n  return 0;\n}`}</code>
              </pre><br />

              <h3>3. Leap Year Check Program</h3>
              <pre>
                <code>{`#include <stdio.h>\n\nint main() {\n  int year;\n  printf("Enter a year:\\n");\n  scanf("%d", &year);\n  if (year % 4 == 0 && year % 100 != 0 || year % 400 == 0) {\n    printf("%d is a leap year\\n", year);\n  } else {\n    printf("%d is not a leap year\\n", year);\n  }\n  return 0;\n}`}</code>
              </pre>

              <p>In the leap year check, the conditions ensure that the year is either divisible by 4 and not 100 or divisible by 400 to be classified as a leap year.</p>
            </div>
          )}



          {selectedChapter === 'chapter12' && (
            <div className={style.chaptercontent}>
              <h1 className={style.heading}>Loop Control Instructions</h1>

              <p>
                Sometimes we want our programs to execute a few sets of instructions over and over again. For example, printing 1 to 100 or the first 100 even numbers. Hence, loops make it easy for a programmer to tell the computer that a given set of instructions must be executed repeatedly.
              </p><br />

              <h2>Types of Loops</h2>
              <p>Primarily, there are three types of loops in the C language:</p>
              <ul>
                <li>While loop</li><br />
                <li>Do-while loop</li><br />
                <li>For loop</li>
              </ul><br />

              <h2>While Loop</h2>
              <pre>
                <code>{`while (condition) {\n  // code;\n}`}</code>
              </pre>
              <p style={{ paddingBottom: "6px" }}>The block keeps executing as long as the condition is true.</p>

              <h3>Example:</h3>
              <pre>
                <code>{`int i = 0;\nwhile (i < 10) {\n  printf("The value of i is %d", i);\n  i++;\n}`}</code>
              </pre>
              <p >Note: If the condition never becomes false, the while loop keeps executing, leading to an infinite loop.</p><br />

              <h3>Increment and Decrement Operators</h3>
              <ul>
                <li><code>i++</code> – i is increased by 1</li>
                <li><code>i--</code> – i is decreased by 1</li>
              </ul>
              <pre>
                <code>{`printf("++i = %d", ++i);\nprintf("i++ = %d", i++);\nprintf("--i = %d", --i);\nprintf("i-- = %d", i--);`}</code>
              </pre><br />

              <h2>Do-While Loop</h2>
              <p>
                The do-while loop is a post-tested loop. It is used when we need to execute the loop at least once. It is often used in menu-driven programs where the termination condition depends upon user input.
              </p>
              <pre>
                <code>{`do {\n  // code;\n} while (condition);`}</code>
              </pre>
              <p>The difference between a while loop and a do-while loop is that the while loop checks the condition first, while the do-while loop executes the code before checking the condition.</p><br />

              <h3>Example:</h3>
              <pre>
                <code>{`#include <stdio.h>\n\nint main() {\n  int i = 1;\n  do {\n    printf("%d\\n", i);\n    i++;\n  } while (i <= 10);\n  return 0;\n}`}</code>
              </pre><br />

              <h2>For Loop</h2>
              <p>The syntax of a for loop looks like this:</p>
              <pre style={{ paddingBottom: "6px" }}>
                <code>{`for (initialization; condition; increment) {\n  // code;\n}`}</code>
              </pre>

              <h3>Example:</h3>
              <pre >
                <code>{`for (i = 0; i < 3; i++) {\n  printf("%d\\n", i);\n}`}</code>
              </pre><br />

              <h3>Decrementing For Loop:</h3>
              <pre>
                <code>{`for (i = 5; i > 0; i--) {\n  printf("%d\\n", i);\n}`}</code>
              </pre><br />

              <h2>Break Statement</h2>
              <p style={{ paddingBottom: "6px" }}>The break statement is used to exit the loop, irrespective of whether the condition is true or false.</p>

              <h3>Example:</h3>
              <pre>
                <code>{`for (i = 0; i < 1000; i++) {\n  printf("%d\\n", i);\n  if (i == 5) {\n    break;\n  }\n}`}</code>
              </pre><br />

              <h2>Continue Statement</h2>
              <p style={{ paddingBottom: "6px" }}>The continue statement is used to skip the current iteration of the loop and move to the next iteration.</p>

              <h3>Example:</h3>
              <pre>
                <code>{`int skip = 5;\nint i = 0;\nwhile (i < 10) {\n  i++;\n  if (i != skip) continue;\n  printf("%d\\n", i);\n}`}</code>
              </pre><br />

              <h2>Nested Loops</h2>
              <p style={{ paddingBottom: "6px" }}>Loops can be nested inside each other. For example, a while loop can be placed inside a for loop.</p>

              <h3>Syntax:</h3>
              <pre>
                <code>{`for (outer_condition) {\n  while (inner_condition) {\n    // inner loop statements;\n  }\n  // outer loop statements;\n}`}</code>
              </pre><br />

              <h3>Example:</h3>
              <pre>
                <code>{`#include <stdio.h>\n\nint main() {\n  int i, j;\n  for (i = 1; i <= 3; i++) {\n    for (j = 1; j <= 3; j++) {\n      printf("%d %d\\n", i, j);\n    }\n  }\n  return 0;\n}`}</code>
              </pre><br />

              <h2 style={{ paddingBottom: "6px" }}>Some C Programs</h2>
              <ol>
                <li>
                  <h3>1. Print natural numbers from 10 to 20 using a while loop:</h3>
                  <pre>
                    <code>{`#include <stdio.h>\n\nint main() {\n  int i = 0;\n  while (i <= 20) {\n    if (i >= 10) {\n      printf("The value of i is %d\\n", i);\n    }\n    i++;\n  }\n  return 0;\n}`}</code>
                  </pre>
                </li><br />

                <li>
                  <h3>2. Print the first n natural numbers using a do-while loop:</h3>
                  <pre>
                    <code>{`#include <stdio.h>\n\nint main() {\n  int i = 0, n;\n  printf("Enter the value of n: ");\n  scanf("%d", &n);\n  do {\n    printf("The number is %d\\n", i + 1);\n    i++;\n  } while (i < n);\n  return 0;\n}`}</code>
                  </pre>
                </li><br />

                <li>
                  <h3>3. Print the first n natural numbers using a for loop:</h3>
                  <pre>
                    <code>{`#include <stdio.h>\n\nint main() {\n  int i, n;\n  printf("Enter the value of n: ");\n  scanf("%d", &n);\n  for (i = 1; i <= n; i++) {\n    printf("The number is: %d\\n", i);\n  }\n  return 0;\n}`}</code>
                  </pre>
                </li><br />

                <li>
                  <h3>4. Print n natural numbers in reverse order:</h3>
                  <pre>
                    <code>{`#include <stdio.h>\n\nint main() {\n  int n, i;\n  printf("Enter the value of n: ");\n  scanf("%d", &n);\n  for (i = n; i > 0; i--) {\n    printf("The value of i is %d\\n", i);\n  }\n  return 0;\n}`}</code>
                  </pre>
                </li><br />

                <li>
                  <h3>5. Print the multiplication table of a given number n:</h3>
                  <pre>
                    <code>{`#include <stdio.h>\n\nint main() {\n  int n, i = 1;\n  printf("Enter the value of n: ");\n  scanf("%d", &n);\n  while (i <= 10) {\n    printf("%d X %d = %d\\n", n, i, n * i);\n    i++;\n  }\n  return 0;\n}`}</code>
                  </pre>
                </li><br />

                <li>
                  <h3>6. Print the multiplication table of 10 in reverse order:</h3>
                  <pre>
                    <code>{`#include <stdio.h>\n\nint main() {\n  int i = 10;\n  while (i > 0) {\n    printf("10 X %d = %d\\n", i, 10 * i);\n    i--;\n  }\n  return 0;\n}`}</code>
                  </pre>
                </li>
              </ol>
            </div>
          )}


          {selectedChapter === 'chapter13' && (
            <div className={style.chaptercontent}>
              <h1 className={style.heading}>Array</h1>

              <p>
                An array is a collection of similar data elements. For example, if you create an integer array, you can only store integer values in that array. Similarly, a floating-point array can only store float values.
              </p><br />

              <h2>Array Basics</h2>
              <p>
                An array variable is capable of storing multiple values. The syntax for declaring an array is:
              </p>
              <pre>
                <code>{`Data-Type Array-Name[Array-Size];\nint marks[3];`}</code>
              </pre>
              <p>Values can be assigned to the <code>marks</code> array as follows:</p>
              <pre>
                <code>{`marks[0] = 33;\nmarks[1] = 40;\nmarks[2] = 60;`}</code>
              </pre><br />

              <h3>Array Indexing</h3>
              <p>Note that array indexing starts at 0:</p>
              <pre>
                <code>{`marks => [0, 1, 2]`}</code>
              </pre>
              <p>Accessing array elements can be done using their indices. For example:</p>
              <pre>
                <code>{`// Accessing elements\nint first = marks[0];\nint second = marks[1];`}</code>
              </pre>
              <p>The last element of an array of size <code>n</code> can be accessed using <code>marks[n-1]</code>.</p><br />

              <h3>Memory Addresses</h3>
              <p>If the starting address of <code>marks[0]</code> is 1220d, then the addresses of the subsequent elements would be:</p>
              <pre>
                <code>{`marks[1] => 1224d\nmarks[2] => 1228d`}</code>
              </pre>
              <p >This is because the size of an integer is typically 4 bytes.</p><br />

              <h2>Initialization of Arrays</h2>
              <p>Arrays can be initialized at the time of declaration:</p>
              <pre>
                <code>{`int marks[3] = {33, 40, 60};\nint marks[] = {33, 40, 60};`}</code>
              </pre><br />

              <h2>Input and Output of Array Elements</h2>
              <p>To take input from the user and store it in an array:</p>
              <pre>
                <code>{`scanf("%d", &marks[1]);\nscanf("%d", &marks[i-1]);`}</code>
              </pre>
              <p>To print individual elements:</p>
              <pre style={{ paddingBottom: "6px" }}>
                <code>{`printf("%d", marks[0]);\nprintf("%d", marks[1]);\nprintf("%d", marks[i-1]);`}</code>
              </pre><br />

              <h3>Changing Array Elements</h3>
              <p>Example of changing the value of array elements:</p>
              <pre>
                <code>{`marks[1] = 15;\nmarks[2] = 75;`}</code>
              </pre><br />

              <h2 style={{ paddingBottom: "6px" }}>Array Examples</h2>

              <h3>Program to Take 5 Values from the User and Store Them in an Array</h3>
              <pre>
                <code>{`#include <stdio.h>\n\nvoid main() {\n  int values[5];\n  printf("Enter five values: ");\n  for(int i = 0; i < 5; i++) {\n    scanf("%d", &values[i]);\n  }\n  printf("Displaying elements of the array: \\n");\n  for(int i = 0; i < 5; i++) {\n    printf("%d\\n", values[i]);\n  }\n}`}</code>
              </pre><br />

              <h2>Multi-Dimensional Arrays</h2>
              <p>You can create arrays of arrays, known as multi-dimensional arrays. For example:</p>
              <pre>
                <code>{`int x[3][4]; // 2D array with 3 rows and 4 columns`}</code>
              </pre>
              <p>Think of the array as a table:</p>
              <pre>
                <code>{`Row 1: x[0][0], x[0][1], x[0][2], x[0][3]\nRow 2: x[1][0], x[1][1], x[1][2], x[1][3]\nRow 3: x[2][0], x[2][1], x[2][2], x[2][3]`}</code>
              </pre>
              <p>A three-dimensional array can be declared similarly:</p>
              <pre>
                <code>{`int y[2][4][3]; // 3D array`}</code>
              </pre><br />

              <h2 style={{ paddingBottom: "6px" }}>Initializing Multi-Dimensional Arrays</h2>
              <h3>2D Array Initialization</h3>
              <pre style={{ paddingBottom: "6px" }}>
                <code>{`int c[2][3] = {{1, 2, 3}, {4, 5, 6}};\nint c[2][3] = {1, 2, 3, 4, 5, 6};`}</code>
              </pre><br />

              <h3>3D Array Initialization</h3>
              <pre>
                <code>{`int test[2][3][4] = {\n  {{1, 2, 3, 4}, {1, 2, 3, 4}, {1, 2, 3, 4}},\n  {{1, 2, 3, 4}, {1, 2, 3, 4}, {1, 2, 3, 4}}\n};`}</code>
              </pre><br />

              <h2 style={{ paddingBottom: "6px" }}>Example C Programs</h2>

              <h3>1. Calculate Sum of Arrays</h3>
              <pre>
                <code>{`#include <stdio.h>\n\nvoid main() {\n  int num[5] = {10, 20, 30, 40, 50}, i, sum = 0;\n  for(i = 0; i < 5; i++) {\n    sum += num[i];\n  }\n  printf("Sum = %d", sum);\n}`}</code>
              </pre><br />

              <h3>2. Calculate Average of Arrays</h3>
              <pre>
                <code>{`#include <stdio.h>\n\nvoid main() {\n  int num[4], sum = 0, i;\n  float avg;\n  printf("Enter 4 numbers: ");\n  for(i = 0; i < 4; i++) {\n    scanf("%d", &num[i]);\n    sum += num[i];\n  }\n  avg = sum / 4.0;\n  printf("Average = %f", avg);\n}`}</code>
              </pre><br />

              <h3>3. Find Minimum and Maximum Number in an Array</h3>
              <pre>
                <code>{`#include <stdio.h>\n\nvoid main() {\n  int a[10], i, n, max, min;\n  printf("Enter the range: ");\n  scanf("%d", &n);\n  printf("Enter the elements:\n");\n  for(i = 0; i < n; i++) {\n    scanf("%d", &a[i]);\n  }\n  max = min = a[0];\n  for(i = 1; i < n; i++) {\n    if(a[i] > max) max = a[i];\n    if(a[i] < min) min = a[i];\n  }\n  printf("Maximum = %d\\n", max);\n  printf("Minimum = %d", min);\n}`}</code>
              </pre><br />

              <h3>4. Print an Array in Reverse Order</h3>
              <pre>
                <code>{`#include <stdio.h>\n\nvoid main() {\n  int num[5] = {10, 30, 50, 70, 90};\n  for(int i = 4; i >= 0; i--) {\n    printf("%d\\t", num[i]);\n  }\n}`}</code>
              </pre><br />

              <h3>5. Delete Element in an Array</h3>
              <pre>
                <code>{`#include <stdio.h>\n\nvoid main() {\n  int a[20], n, i, pos;\n  printf("Enter number of elements: ");\n  scanf("%d", &n);\n  printf("Enter elements:\n");\n  for(i = 0; i < n; i++) {\n    scanf("%d", &a[i]);\n  }\n  printf("Enter position to delete: ");\n  scanf("%d", &pos);\n  if(pos >= n + 1) {\n    printf("Deletion not possible.");\n  } else {\n    for(i = pos - 1; i < n - 1; i++) {\n      a[i] = a[i + 1];\n    }\n    printf("After deletion:\n");\n    for(i = 0; i < n - 1; i++) {\n      printf("%d\\n", a[i]);\n    }\n  }\n}`}</code>
              </pre>
            </div>
          )}


          {selectedChapter === 'chapter14' && (
            <div className={style.chaptercontent}>
              <h1 className={style.heading} > String </h1>

              <p>
                In C programming, a string is a sequence of characters terminated with a null character <code>"\0"</code>. For example:
              </p>
              <pre>
                <code>{`char c[] = "IITS";`}</code>
              </pre>
              <p>
                When the compiler encounters a sequence of characters enclosed in double quotation marks, it appends a null character <code>"\0"</code> at the end by default.
              </p><br />

              <h2>Initializing Strings</h2>
              <p>You can initialize strings in a number of ways:</p>
              <pre>
                <code>{`char c[] = "IITS";\nchar c[50] = "IITS";\nchar c[] = {'I', 'I', 'T', 'S', '\\0'};\nchar c[50] = {'I', 'I', 'T', 'S', '\\0'};`}</code>
              </pre><br />

              <h2>Printing Strings</h2>
              <p>
                A string can be printed character by character using <code>printf</code> and <code>%c</code>:
              </p>
              <pre>
                <code>{`char str[] = "IITS";\nfor(int i = 0; i < 4; i++) {\n    printf("%c", str[i]);\n}`}</code>
              </pre>
              <p>
                However, there is a more convenient way to print strings in C:
              </p>
              <pre>
                <code>{`printf("%s", str);  // Print the entire string`}</code>
              </pre><br />

              <h2>String in Memory</h2>
              <p>A string is stored like an array in memory:</p>
              <pre>
                <code>{`Memory Address   Value\n-----------------------\n801              'I'\n802              'I'\n803              'T'\n804              'S'\n805              '\\0'  // Null terminator`}</code>
              </pre>
              <p>Memory Layout:</p>
              <pre>
                <code>{`+-----------------+\n| Address | Value  |\n+---------+--------+\n|   801   |   I    |\n|   802   |   I    |\n|   803   |   T    |\n|   804   |   S    |\n|   805   |   \\0   |\n+-----------------+`}</code>
              </pre><br />

              <h2>Taking String Input from the User</h2>
              <p>
                We can use <code>%s</code> with <code>scanf</code> to take string input from the user:
              </p>
              <pre>
                <code>{`char str[50];\nscanf("%s", str);`}</code>
              </pre>
              <p>
                The <code>scanf</code> function reads the sequence of characters until it encounters whitespace (space, newline, tab, etc.).
                <strong>Note:</strong>
                <ul>
                  <li>The string should be long enough to fit into the array.</li>
                  <li><code>scanf</code> cannot be used to input multi-word strings with spaces.</li>
                </ul>
              </p><br />

              <h2>Using <code>gets()</code> and <code>puts()</code> Functions</h2>
              <p>
                <code>gets()</code> can be used to receive a multi-word string:
              </p>
              <pre>
                <code>{`char str[30];\ngets(str);  // The entered string is stored in str.`}</code>
              </pre>
              <p>
                Multiple <code>gets()</code> calls will be needed for multiple strings. Likewise, <code>puts()</code> can be used to output a string:
              </p>
              <pre>
                <code>{`puts(str);  // Print the string`}</code>
              </pre><br />

              <h2>Standard Library Functions</h2>
              <p>C provides a set of standard library functions for string manipulation. Some of the most commonly used string functions are:</p>
              <ul>
                <li><code>strlen()</code></li>
                <li><code>strcpy()</code></li>
                <li><code>strcmp()</code></li>
                <li><code>strrev()</code></li>
                <li><code>strlwr()</code></li>
                <li><code>strupr()</code></li>
              </ul><br />

              <h3><code>strlen()</code></h3>
              <p>This function counts the number of characters in the string, excluding the null character:</p>
              <pre>
                <code>{`printf("%zu", strlen(str));`}</code>
              </pre>
              <p>This function is declared under the <code>&lt;string.h&gt;</code> header file.</p><br />

              <h3><code>strcpy()</code></h3>
              <p>This function copies the content of the second string into the first string passed to it:</p>
              <pre>
                <code>{`char source[] = "IITS";\nchar target[30];\nstrcpy(target, source);  // target now contains "IITS"`}</code>
              </pre>
              <p>The target string should have enough capacity to store the source string.</p><br />

              <h3><code>strcmp()</code></h3>
              <p>This function is used to compare two strings:</p>
              <pre>
                <code>{`char str1[] = "IITS", str2[] = "IITS";\nstrcmp(str1, str2);`}</code>
              </pre><br />

              <h3><code>strrev()</code></h3>
              <p>This function reverses a string:</p>
              <pre>
                <code>{`printf("%s", strrev(str));`}</code>
              </pre><br />

              <h3><code>strlwr()</code></h3>
              <p>This function converts a string to lowercase:</p>
              <pre>
                <code>{`printf("%s", strlwr(str));`}</code>
              </pre><br />

              <h3><code>strupr()</code></h3>
              <p>This function converts a string to uppercase:</p>
              <pre>
                <code>{`printf("%s", strupr(str));`}</code>
              </pre><br />


            </div>
          )}


          {selectedChapter === 'chapter15' && (
            <div className={style.chaptercontent}>
              <h1 className={style.heading}>Structures</h1>

              <p>
                In C programming, a struct (or structure) is a collection of variables (which can be of different types) under a single name.
              </p><br />

              <h2>Define Structure</h2>
              <p>
                Before creating structure variables, you need to define its data type using the “struct” keyword.
              </p>
              <p>
                <strong>Syntax:</strong>
              </p>
              <pre>
                <code>
                  struct structureName {'{'} <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;dataType member1; <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;dataType member2; <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;..... <br />
                  {'}'};
                </code>
              </pre>

              <h4>Example:</h4>
              <pre>
                <code>
                  struct person {'{'} <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;char name[20]; <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;int citNo; <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;float salary; <br />
                  {'}'}; <br />
                </code>
              </pre>
              <p>
                Here, a derived type “struct person” is defined. You can now create variables of this type.
              </p><br />

              <h2>Structure Variables</h2>
              <p>
                When a “struct” type is declared, no storage or memory is allocated. To allocate memory and work with it, you need to create variables.
              </p>
              <p>Here’s how to create structure variables:</p>
              <pre>
                <code>
                  struct person {'{'} <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;// code <br />
                  {'}'}; <br />
                  <br />
                  void main() {'{'} <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;struct person person1, person2, p[20]; <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;getch(); <br />
                  {'}'} <br />
                </code>
              </pre>

              <p>Another way of creating a struct variable:</p>
              <pre>
                <code>
                  struct person {'{'} <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;// code <br />
                  {'}'} person1, person2, p[20]; <br />
                </code>
              </pre>
              <p>In both cases:</p>
              <ul>
                <li>“person1” and “person2” are “struct person” variables.</li>
                <li>“p[20]” is a “struct person” array of size 20.</li>
              </ul><br />

              <h2>Access Members of a Structure</h2>
              <p>There are two operators used for accessing members of a structure:</p>
              <ol>
                <li>“.” – Member operator</li>
                <li>“-{'>'}” – Structure pointer operator (arrow operator)</li>
              </ol>
              <p>
                To access the “salary” of “person2”, use:
              </p>
              <p>
                By using the member operator: <strong>person2.salary</strong>
              </p>
              <p>
                By using the arrow operator: <strong>person2-{'>'}salary</strong>
              </p>

              <h4>Example:</h4>
              <pre>
                <code>
                  #include&lt;stdio.h&gt; <br />
                  #include&lt;conio.h&gt; <br />
                  #include&lt;string.h&gt; <br />
        // create struct with person1 variable <br />
                  struct person {'{'} <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;char name[20]; <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;int citNo; <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;float salary; <br />
                  {'}'} person1; <br />
                  <br />
                  void main() {'{'} <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;// assign value to name of person1 <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;strcpy(person1.name, "Tony Stark"); <br />
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;// assign values to other person1 variables <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;person1.citNo = 1984; <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;person1.salary = 25000; <br />
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;// print struct variables <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;printf("Name: %s\\n", person1.name); <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;printf("Citizenship No.: %d\\n", person1.citNo); <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;printf("Salary: %.2f", person1.salary); <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;getch(); <br />
                  {'}'} <br />
                </code>
              </pre>
              <p>
                In this program, we have created a “struct” named “person” and a variable of “person” named “person1”. In “main()”, we assigned values to the variables defined in “person” for the “person1” object.
              </p>
              <p>
                Notice that we have used the “strcpy()” function to assign the value to “person1.name” because “name” is a “char” array (C-string) and we cannot use the assignment operator “=” with it after declaration.
              </p><br />

              <h2 style={{ paddingBottom: "6px" }}>Keyword types</h2>
              <p>
                The “typedef” keyword creates an alias name for data types. It simplifies the syntax of declaring variables, especially with structures.
              </p>

              <h4>Example:</h4>
              <pre>
                <code>
                  struct Distance {'{'} <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;int feet; <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;float inch; <br />
                  {'}'}; <br />
                  int main() {'{'} <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;struct Distance d1, d2; <br />
                  {'}'} <br />
                </code>
              </pre>

              <p>Using “typedef” for a simplified syntax:</p>
              <pre>
                <code>
                  typedef struct Distance {'{'} <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;int feet; <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;float inch; <br />
                  {'}'} dis; <br />
                  int main() {'{'} <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;dis d1, d2; <br />
                  {'}'} <br />
                </code>
              </pre><br />

              <h2>Why Structure in C</h2>
              <p>
                If you want to store information about a person—like their name, citizenship number, and salary—you could create separate variables for each. However, if you need to store information for multiple people, this quickly becomes cumbersome.
              </p>
              <p>
                A better approach is to group all related information under a single name using the “person” structure, allowing for easier data management.
              </p><br />

              <h2 style={{ paddingBottom: "6px" }}>Some C Programs</h2>

              <h3>1. C Program to Store and Display Information of a Student Using Structure</h3>
              <pre>
                <code>
                  #include&lt;stdio.h&gt; <br />
                  #include&lt;conio.h&gt; <br />
                  <br />
                  struct student {'{'} <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;char name[50]; <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;int roll; <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;float marks; <br />
                  {'}'} s; <br />
                  <br />
                  void main() {'{'} <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;printf("Enter Information:\\n"); <br />
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;printf("Enter Name: "); <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;gets(s.name); <br />
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;printf("Enter Roll Number: "); <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;scanf("%d", &s.roll); <br />
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;printf("Enter Marks: "); <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;scanf("%f", &s.marks); <br />
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;printf("\\nDisplaying Information:\\n"); <br />
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;printf("Name: %s\\n", s.name); <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;printf("Roll No: %d\\n", s.roll); <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;printf("Marks: %.2f\\n", s.marks); <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;getch(); <br />
                  {'}'} <br />
                </code>
              </pre><br />

              <h3>2. C Program to Add Two Distances Using Structure</h3>
              <pre>
                <code>
                  #include&lt;stdio.h&gt; <br />
                  #include&lt;conio.h&gt; <br />
                  <br />
                  struct distance {'{'} <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;int feet; <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;float inch; <br />
                  {'}'} d1, d2, result; <br />
                  <br />
                  void main() {'{'} <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;printf("Enter information for first distance:\\n"); <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;printf("Enter feet: "); <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;scanf("%d", &d1.feet); <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;printf("Enter inch: "); <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;scanf("%f", &d1.inch); <br />
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;printf("Enter information for second distance:\\n"); <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;printf("Enter feet: "); <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;scanf("%d", &d2.feet); <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;printf("Enter inch: "); <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;scanf("%f", &d2.inch); <br />
                  <br />
        // Adding the two distances <br />
                  result.feet = d1.feet + d2.feet; <br />
                  result.inch = d1.inch + d2.inch; <br />
                  <br />
        // Checking if inches are greater than 12 <br />
                  if(result.inch {">"}= 12) {'{'} <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;result.feet += (int)(result.inch / 12); <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;result.inch = result.inch - (12 * (int)(result.inch / 12)); <br />
                  {'}'} <br />
                  <br />
                  printf("\\nSum of distances: %d\'-%.1f\\\"", result.feet, result.inch); <br />
                  getch(); <br />
                  {'}'} <br />
                </code>
              </pre>
            </div>
          )}


          {selectedChapter === 'chapter16' && (
            <div className={style.chaptercontent}>
              <h1 className={style.heading}>Pointers</h1>

              <p>A pointer is a special variable that stores the address of another variable.</p><br />

              <h2>Pointer Syntax</h2>
              <p>Here is how we can declare pointers:</p>
              <ul>
                <li><code>int *j;</code> &rarr; Declare a variable <code>j</code> of type <code>int</code> pointer.</li><br />
                <li><code>j = &i;</code> &rarr; Store the address of <code>i</code> in <code>j</code>.</li>
              </ul><br />
              <p>
                Here, we have declared a pointer <code>j</code> of <code>int</code> type. Just like a pointer of type integer, we also have pointers to <code>char</code>, <code>float</code>, etc.
              </p>
              <ul>
                <li><code>float *j;</code> &rarr; Pointer to <code>float</code></li><br />
                <li><code>char *j;</code> &rarr; Pointer to <code>char</code></li>
              </ul><br />

              <h2>Assigning Addresses to Pointers</h2>
              <pre>
                <code>
                  int *pc, c;<br />
                  c = 5;<br />
                  pc = &c;
                </code>
              </pre>
              <p>
                Here, <code>5</code> is assigned to the variable <code>c</code>, and the address of <code>c</code> is assigned to the <code>pc</code> pointer.
              </p><br />

              <h2>Getting Values Pointed by Pointers</h2>
              <p>
                To get the value of the thing pointed to by the pointer, we use the <code>*</code> operator. For example:
              </p>
              <pre>
                <code>
                  int *pc, c;<br />
                  c = 5;<br />
                  pc = &c;<br />
                  printf("%d", *pc); // Output: 5
                </code>
              </pre>
              <p>
                Here, the address of <code>c</code> is assigned to the <code>pc</code> pointer. To get the value stored at that address, we use <code>*pc</code>.
              </p><br />

              <h1>A Program to Demonstrate Pointers</h1>
              <pre>
                <code>
                  #include &lt;stdio.h&gt;<br />
                  #include &lt;conio.h&gt;<br />
                  void main() <br />
                  {'{'}<br />
                  &nbsp;&nbsp;&nbsp;&nbsp;int i = 5;<br />
                  &nbsp;&nbsp;&nbsp;&nbsp;int *j;<br />
                  &nbsp;&nbsp;&nbsp;&nbsp;j = &i;<br />
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;printf("\nAddress of i = %u", &i);<br />
                  &nbsp;&nbsp;&nbsp;&nbsp;printf("\nValue of j = %u", j);<br />
                  &nbsp;&nbsp;&nbsp;&nbsp;printf("\nAddress of j = %u", &j);<br />
                  &nbsp;&nbsp;&nbsp;&nbsp;printf("\nValue of i = %d", i);<br />
                  &nbsp;&nbsp;&nbsp;&nbsp;printf("\nValue of i = %d", *(&i));<br />
                  &nbsp;&nbsp;&nbsp;&nbsp;printf("\nValue of i = %d", *j);<br />
                  &nbsp;&nbsp;&nbsp;&nbsp;getch();<br />
                  {'}'}
                </code>
              </pre>
              <h2>Output:</h2>
              <pre>
                <code>
                  Address of i = 64487580<br />
                  Value of j = 64487580<br />
                  Address of j = 6487568<br />
                  Value of i = 5<br />
                  Value of i = 5<br />
                  Value of i = 5
                </code>
              </pre><br />

              <h2>Pointer to a Pointer</h2>
              <p>
                Just like “j” is a pointer to “i” (storing the address of “i”), we can have another variable “k” which can further store the address of “j”.
              </p>
              <pre>
                <code>
                  int **k;<br />
                  k = &j;
                </code>
              </pre><br />

              <h2>Type of Function Calls</h2>
              <p>Based on the way we pass arguments to the function, function calls are of two types:</p>
              <ol style={{ paddingBottom: "6px" }}>
                <li>Call by value &rarr; Sending the values of arguments</li>
                <li>Call by reference &rarr; Sending the address of arguments</li>
              </ol><br />

              <h3>Call by Value</h3>
              <p>
                Here, the value of the arguments is passed to the function. Consider this example:
              </p>
              <pre style={{ paddingBottom: "6px" }}>
                <code>
                  int c = sum(3, 4);<br />
        // Assume x = 3 and y = 4<br />
        // If sum is defined as sum(int a, int b), the values “3” and “4” are copied to “a” and “b”.<br />
                  Now even if we change “a” and “b”, nothing happens to this; this is call by value.
                </code>
              </pre><br />

              <h3>Call by Reference</h3>
              <p>
                Here the address of the variables is passed to the function as arguments.
              </p>
              <p>
                Now since the addresses are passed to the function, it can modify the value of a variable in the calling function using “*” and “&” operators.
              </p>
              <h2>Example:</h2>
              <p>This function is capable of swapping the values passed to it.</p>
              <pre>
                <code>
                  void swap(int *x, int *y) <br />
                  {'{'} <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;int temp;<br />
                  &nbsp;&nbsp;&nbsp;&nbsp;temp = *x;<br />
                  &nbsp;&nbsp;&nbsp;&nbsp;*x = *y;<br />
                  &nbsp;&nbsp;&nbsp;&nbsp;*y = temp;<br />
                  {'}'} <br />
                  <br />
                  int main() <br />
                  {'{'} <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;int a = 3;<br />
                  &nbsp;&nbsp;&nbsp;&nbsp;int b = 4;<br />
                  &nbsp;&nbsp;&nbsp;&nbsp;swap(&a, &b); <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;return 0;<br />
                  {'}'}
                </code>
              </pre><br />

              <h2 style={{ paddingBottom: "6px" }}>Some C Programs</h2>

              <h3>1. C program to find out the address of the variable.</h3>
              <pre>
                <code>
                  #include&lt;stdio.h&gt; <br />
                  #include&lt;conio.h&gt; <br />
                  void main() <br />
                  {'{'} <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;int a=5; <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;printf("Value of a variable is:%d", a); <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;printf("Address of a variable is:%u", &a); <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;getch(); <br />
                  {'}'}
                </code>
              </pre><br />

              <h3>2. C program to print value and address of variable using pointer.</h3>
              <pre>
                <code>
                  #include&lt;stdio.h&gt; <br />
                  #include&lt;conio.h&gt; <br />
                  void main() <br />
                  {'{'} <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;int a=5, b=10, *ptr1, *ptr2; <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;ptr1 = &a; <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;ptr2 = &b; <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;printf("Value of a is= %d\\n", *ptr1); <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;printf("Address of a is= %u\\n", ptr1); <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;printf("Value of b is= %d\\n", *ptr2); <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;printf("Address of b is= %u\\n", ptr2); <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;getch(); <br />
                  {'}'}
                </code>
              </pre><br />

              <h3>3. C program to find the sum of two numbers using pointers.</h3>
              <pre>
                <code>
                  #include&lt;stdio.h&gt; <br />
                  #include&lt;conio.h&gt; <br />
                  void sum(int *x, int *y) <br />
                  {'{'} <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;int z = *x + *y; <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;printf("Sum of two numbers is= %d", z); <br />
                  {'}'} <br />
                  void main() <br />
                  {'{'} <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;int a = 10, b = 20; <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;sum(&a, &b); <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;getch(); <br />
                  {'}'}
                </code>
              </pre>
            </div>
          )}


          {selectedChapter === 'chapter17' && (
            <div className={style.chaptercontent}>
              <h1 className={style.heading}>Functions</h1>

              <p>
                Sometimes our program grows too large for a programmer to track which piece of code is doing what.
                A function is a way to break our code into manageable chunks, making it easier to reuse and maintain.
              </p><br />

              <h2>What is a Function?</h2>
              <p>
                A function is a block of code that performs a specific task.
                Functions can be reused by the programmer any number of times within a program.
              </p><br />

              <h2>Types of Functions</h2>
              <p>There are two types of functions in C programming:</p>
              <ul>
                <li><strong>Standard Library Functions:</strong> These are built-in functions defined in header files.</li><br />
                <li><strong>User-defined Functions:</strong> Functions created by the user according to specific needs.</li>
              </ul><br />

              <h3>Standard Library Functions</h3>
              <p>Examples of standard library functions include:</p>
              <ul>
                <li><code>printf()</code>: Sends formatted output to the screen. It is defined in the <code>stdio.h</code> header file.</li><br />
                <li><code>sqrt()</code>: Calculates the square root of a number, defined in the <code>math.h</code> header file.</li>
              </ul><br />

              <h3>User-defined Functions</h3>
              <p>You can create functions tailored to your needs. For instance, to create and color a circle based on its radius and color, you could define functions like <code>createCircle()</code> and <code>color()</code>.</p><br />

              <h2>Function Syntax and Example</h2>
              <pre>
                <code>{`#include <stdio.h>\n#include <conio.h>\n\nint addNumbers(int a, int b); // Function prototype\n\nvoid main() {\n    int n1, n2, sum;\n    printf("Enter two numbers: ");\n    scanf("%d %d", &n1, &n2);\n    sum = addNumbers(n1, n2); // Function call\n    printf("sum = %d", sum);\n    getch();\n}\n\nint addNumbers(int a, int b) { // Function definition\n    int result;\n    result = a + b;\n    return result; // Return statement\n}`}</code>
              </pre>
              <p>
                Note: If <code>n1</code> is of <code>char</code> type, then <code>a</code> should also be <code>char</code>. Similarly, if <code>n2</code> is <code>float</code>, then <code>b</code> should be <code>float</code>.
              </p><br />

              <h2>Function Declaration</h2>
              <p>
                Function declaration is a way to tell the compiler about the function we are going to define in the program.
                Here, <code>void</code> indicates that the function returns nothing.
                The function declaration is not needed if the user-defined function is defined before the main function.
              </p>
              <p>Syntax of function declaration:</p>
              <p><code>returnType functionName(type1 argument1, type2 argument2, …);</code></p><br />

              <h2>Function Call</h2>
              <p>
                Function call is a way to tell the compiler to execute the function body at the time the call is made.
                Note that the program execution starts from the main function in the sequence the instructions are written.
              </p>
              <p>Syntax of function call:</p>
              <p><code>functionName(argument1, argument2, …);</code></p><br />

              <h2>Function Definition</h2>
              <p>
                This part contains the exact set of instructions that are executed during the function call.
                When a function is called from <code>main()</code>, the main function falls asleep and gets temporarily suspended.
                During this time, the control goes to the function being called. When the function body is done executing, <code>main()</code> resumes.
              </p>
              <p>Syntax of function definition:</p>
              <pre>
                <code>{`returnType functionName(type1 argument1, type2 argument2, …) {\n    // Body of the function\n}`}</code>
              </pre><br />

              <h2>Passing Arguments to a Function</h2>
              <p >
                In programming, an argument refers to the variable passed to the function.
                In the above example, two variables <code>n1</code> and <code>n2</code> are passed during the function call.
                The parameters <code>a</code> and <code>b</code> accept the passed arguments in the function definition.
                These arguments are called formal parameters of the function.
              </p><br />

              <h3>How to Pass Arguments to the Function?</h3>
              <pre>
                <code>{`#include <stdio.h>\n#include <conio.h>\n\nint addNumbers(int a, int b);\n\nint main() {\n    //...\n    sum = addNumbers(n1, n2);\n    //...\n}\n\nint addNumbers(int a, int b) {\n    //...\n}`}</code>
              </pre>
              <p>
                The type of arguments passed to a function and the formal parameters must match; otherwise, the compiler will throw an error.
              </p><br />

              <h2>Return Statement</h2>
              <p>
                The <code>return</code> statement terminates the execution of a function and returns a value to the calling function.
                Control is transferred back to the calling function after the <code>return</code> statement.
              </p>

              <pre style={{ paddingBottom: "6px" }}>
                <code>{`#include <stdio.h>\n#include <conio.h>\n\nint addNumbers(int a, int b);\n\nvoid main() {\n    //...\n    sum = addNumbers(n1, n2);\n    //...\n}\n\nint addNumbers(int a, int b) {\n    //...\n    return result;\n}`}</code>
              </pre>

              <h3>Syntax of Return Statement</h3>
              <p><code>return (expression);</code></p>
              <p>
                The type of value returned must match the return type specified in the function declaration and definition.
              </p>
            </div>
          )}
          {selectedChapter === 'chapter18' && (
            <div className={style.chaptercontent}>
              <h1 className={style.heading}>Recursion</h1>

              <p>A function that calls itself is known as a recursive function. This technique is called recursion.</p>
              <br />

              <h3>How Does Recursion Work?</h3>
              <p>
                Recursion continues until a specific condition is met to prevent it from running indefinitely. To avoid infinite recursion, use an <code>if...else</code> statement (or a similar approach), where one branch makes the recursive call and the other does not.
              </p>
              <p>
                Each time a recursive function is called, a new instance of that function is created and added to the call stack. This allows the program to keep track of where each function call is in its execution.
              </p>
              <p>
                When the base case is reached (the condition that stops recursion), the function starts returning values back through the call stack. This process unwinds each instance of the function, allowing the program to resume where it left off.
              </p>
              <p>
                It’s important to ensure that your recursive function modifies its parameters in a way that it gets closer to the base case with each call. This prevents the function from calling itself indefinitely, leading to a stack overflow error.
              </p>
              <p>
                A classic example is the calculation of factorials. For instance, the factorial of 5 (denoted as 5!) is calculated as 5 * 4!, and this continues down to 1! = 1, with 0! defined as 1. Each multiplication waits for the previous call to return its result.
              </p>
              <br />

              <h2>Advantages and Disadvantages of Recursion</h2>
              <p>
                Recursion makes programs elegant. However, if performance is vital, use loops instead, as recursion is usually much slower. That being said, recursion is an important concept and is frequently used in data structures and algorithms.
              </p>
              <br />

              <h2 style={{ paddingBottom: "6px" }}>Some C Programs</h2>

              <h3>C Program to Calculate Sum of Two Numbers Using Different Types of Functions</h3>

              <h4>No Arguments Passed and No Return Value</h4>
              <pre>
                <code>{`#include <stdio.h>\n#include <conio.h>\n\nvoid sum();\n\nvoid main() {\n    sum();\n    getch();\n}\n\nvoid sum() {\n    int a, b, c;\n    printf("Enter the value of a: ");\n    scanf("%d", &a);\n    printf("Enter the value of b: ");\n    scanf("%d", &b);\n    c = a + b;\n    printf("a + b is: %d", c);\n}`}</code>
              </pre>
              <br />

              <h4>Arguments Passed and No Return Value</h4>
              <pre>
                <code>{`#include <stdio.h>\n#include <conio.h>\n\nvoid sum(int, int);\n\nvoid main() {\n    int x, y;\n    printf("Enter the value of x: ");\n    scanf("%d", &x);\n    printf("Enter the value of y: ");\n    scanf("%d", &y);\n    sum(x, y);\n    getch();\n}\n\nvoid sum(int a, int b) {\n    int c;\n    c = a + b;\n    printf("a + b: %d", c);\n}`}</code>
              </pre>
              <br />

              <h4>No Arguments Passed but a Return Value</h4>
              <pre>
                <code>{`#include <stdio.h>\n#include <conio.h>\n\nint sum();\n\nvoid main() {\n    int ans;\n    ans = sum();\n    printf("The value of a + b is: %d", ans);\n    getch();\n}\n\nint sum() {\n    int a, b, c;\n    printf("Enter the value of a: ");\n    scanf("%d", &a);\n    printf("Enter the value of b: ");\n    scanf("%d", &b);\n    c = a + b;\n    return (c);\n}`}</code>
              </pre>
              <br />

              <h4>Arguments Passed and a Return Value</h4>
              <pre>
                <code>{`#include <stdio.h>\n#include <conio.h>\n\nint sum(int, int);\n\nvoid main() {\n    int a, b, ans;\n    printf("Enter the value of a: ");\n    scanf("%d", &a);\n    printf("Enter the value of b: ");\n    scanf("%d", &b);\n    ans = sum(a, b);\n    printf("The value of a + b is: %d", ans);\n    getch();\n}\n\nint sum(int x, int y) {\n    return x + y;\n}`}</code>
              </pre>
              <br />

              <h3>Average of Three Numbers</h3>
              <pre>
                <code>{`#include <stdio.h>\n#include <conio.h>\n\nfloat average(int a, int b, int c);\n\nvoid main() {\n    int a, b, c;\n    printf("Enter the value of a: ");\n    scanf("%d", &a);\n    printf("Enter the value of b: ");\n    scanf("%d", &b);\n    printf("Enter the value of c: ");\n    scanf("%d", &c);\n    printf("The value of average is: %f", average(a, b, c));\n    getch();\n}\n\nfloat average(int a, int b, int c) {\n    return (float)(a + b + c) / 3;\n}`}</code>
              </pre>
              <br />

              <h3>Celsius to Fahrenheit Conversion</h3>
              <pre>
                <code>{`#include <stdio.h>\n#include <conio.h>\n\nint temperature(int);\n\nvoid main() {\n    int celsius;\n    printf("Enter the temperature in Celsius: ");\n    scanf("%d", &celsius);\n    printf("Temperature in Fahrenheit is: %d", temperature(celsius));\n    getch();\n}\n\nint temperature(int cel) {\n    return (cel * 9 / 5) + 32;\n}`}</code>
              </pre>
              <br />

              <h3>Sum of N Natural Numbers Using Recursion</h3>
              <pre>
                <code>{`#include <stdio.h>\n#include <conio.h>\n\nint sum(int n);\n\nvoid main() {\n    int number;\n    printf("Enter a positive integer: ");\n    scanf("%d", &number);\n    printf("Sum = %d", sum(number));\n    getch();\n}\n\nint sum(int n) {\n    return (n != 0) ? n + sum(n - 1) : n;\n}`}</code>
              </pre>
              <br />

              <h3>Finding Minimum and Maximum in an Array</h3>
              <pre>
                <code>{`#include <stdio.h>\n#include <conio.h>\n\nvoid findMax();\nvoid findMin();\n\nvoid main() {\n    findMax();\n    findMin();\n    getch();\n}\n\nvoid findMax() {\n    int a[5], i;\n    printf("Enter five elements:\n");\n    for (i = 0; i < 5; i++) {\n        scanf("%d", &a[i]);\n    }\n    int max = a[0];\n    for (i = 1; i < 5; i++) {\n        if (a[i] > max) {\n            max = a[i];\n        }\n    }\n    printf("Maximum = %d\n", max);\n}\n\nvoid findMin() {\n    int a[5], i;\n    printf("Enter five elements:\n");\n    for (i = 0; i < 5; i++) {\n        scanf("%d", &a[i]);\n    }\n    int min = a[0];\n    for (i = 1; i < 5; i++) {\n        if (a[i] < min) {\n            min = a[i];\n        }\n    }\n    printf("Minimum = %d\n", min);\n}`}</code>
              </pre>
              <br />

              <h3>Multiplication Table</h3>
              <pre>
                <code>{`#include <stdio.h>\n#include <conio.h>\n\nvoid printTable();\n\nvoid main() {\n    printTable();\n    getch();\n}\n\nvoid printTable() {\n    int i, n;\n    printf("Enter the number: ");\n    scanf("%d", &n);\n    for (i = 1; i <= 10; i++) {\n        printf("%d X %d = %d\\n", n, i, n * i);\n    }\n}`}</code>
              </pre>
            </div>
          )}







        </div>
      </div>
    </div>

  );

}


export default Ccoursedata;


