import React, { useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import style from "./../Css/Header2.module.css";
import Login from './Login';
import Signup from './Signup';
import Passwordreset from './Passwordreset';

const Header2 = () => {
    const [isCollapsed, setIsCollapsed] = useState(true);

    // Function to handle link click and hide the navbar
    const handleLinkClick = () => {
        setIsCollapsed(true);
    };

    // Function to handle clicks outside the navbar
    const handleOutsideClick = (e) => {
        const navbar = document.getElementById('mynavbar');
        const toggler = document.querySelector('.navbar-toggler');

        if (
            navbar && 
            !navbar.contains(e.target) && 
            toggler && 
            !toggler.contains(e.target)
        ) {
            setIsCollapsed(true);
        }
    };

    useEffect(() => {
        // Add a click event listener to the window
        window.addEventListener('click', handleOutsideClick);

        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    return (
        <>
            <nav className={`${style.navbar} navbar navbar-expand-lg`}>
                <div className="container">
                    <Link className={`${style.logo} navbar-brand`} to="/#/Landing_page">
                        IITS CODE
                    </Link>
                    <button
                        className="navbar-toggler"
                        type="button"
                        onClick={() => setIsCollapsed(!isCollapsed)} 
                        aria-controls="mynavbar"
                        aria-expanded={!isCollapsed} 
                    >
                        <span className="navbar-toggler-icon" />
                    </button>
                    <div className={`${style.navmobileview} collapse navbar-collapse ${isCollapsed ? '' : 'show'}`} id="mynavbar">
                        <ul className="navbar-nav me-auto">
                            <li className="nav-item">
                                <Link className={`${style.navitems} nav-link`} to="/Landing_page" onClick={handleLinkClick}>
                                    Home
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className={`${style.navitems} nav-link`} to="/LiveProject" onClick={handleLinkClick}>
                                    Live project
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className={`${style.navitems} nav-link`} to="/Live_classes" onClick={handleLinkClick}>
                                    Live connect
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className={`${style.navitems} nav-link`} to="/Recent_job" onClick={handleLinkClick}>
                                    Recent Jobs
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className={`${style.navitems} nav-link`} to="/Available_internship" onClick={handleLinkClick}>
                                    Internships
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className={`${style.navitems} ${style.certificatshow} nav-link`} to="/certificate_show" onClick={handleLinkClick}>
                                   CERTIFICATE <i class="fa-solid fa-certificate"></i>
                                </Link>
                            </li>
                        </ul>
                        <div className={`${style.logincolum} col`}>
                            <Link to="/Log_in" className={`${style.btn} btn-outline-success ms-2}`} data-bs-toggle="modal" data-bs-target="#loginModal">
                                Login
                            </Link>
                        </div>
                    </div>
                </div>
            </nav>
            <Login />
            <Signup />
            <Passwordreset />
        </>
    );
};

export default Header2;
