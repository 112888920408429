import React, { useState } from 'react';
import style from './../../Css/Courses.module.css'

function  Nodejsdata() {
    // State to keep track of the selected chapter
    const [selectedChapter, setSelectedChapter] = useState('chapter1')

    // Function to handle chapter selection
    const handleChapterClick = (chapter) => {
        setSelectedChapter(chapter)
    }

    // State to track the active chapter
    const [activeChapter, setActiveChapter] = useState('chapter1');

    // Function to handle chapter click
    const handleChapterActive = (chapter) => {
        setActiveChapter(chapter);
    };
    const handleClick = (chapter) => {
        handleChapterActive(chapter);
        handleChapterClick(chapter);
        window.scrollTo(0,0);
    };

    return (
        <div className={`${style.firstcontainer} container-fluid`}>

            <div className={`${style.therow} row`}>

                <div className={`${style.droupdownbtnbar} dropdown`}>
                    <button className={`${style.droupdownbtn} btn dropdown-toggle`} type="button" data-bs-toggle="dropdown" aria-expanded="false">
                     Oracle
                    </button>
                    <ul className={`${style.dropdownmenu} dropdown-menu`}>
                        <li
                            onClick={() => handleClick('chapter1')}

                            className={`${style.chapter1} ${activeChapter === 'chapter1' ? style.active : ''} text-decoration-none dropdown-item`}>
                      Introduction 
                        </li>

                        <li
                            onClick={() => handleClick('chapter2')}
                            className={`${style.chapter2} ${activeChapter === 'chapter2' ? style.active : ''} dropdown-item `}>
                         Overview of Oracle Database
                        </li>


                            <li onClick={() => handleClick('chapter39')}
                             className={`${style.chapter39} ${activeChapter === 'chapter39' ? style.active : ''} dropdown-item `}>
                            History and Evolution
                             </li>

<h5>Database Concepts</h5>
                             <li onClick={() => handleClick('chapter40')}
                             className={`${style.chapter40} ${activeChapter === 'chapter40' ? style.active : ''} dropdown-item `}>
                        Relational Database Management System 
                             </li>

                             <li onClick={() => handleClick('chapter41')}
                             className={`${style.chapter41} ${activeChapter === 'chapter41' ? style.active : ''} dropdown-item `}>
                       Tables, Rows, and Columns
                             </li>

                             <li onClick={() => handleClick('chapter42')}
                             className={`${style.chapter42} ${activeChapter === 'chapter42' ? style.active : ''} dropdown-item `}>
                       Primary Keys and Foreign Keys
                             </li>

                        
 <h5>SQL Basics</h5>
                            <li onClick={() => handleClick('chapter43')}
                             className={`${style.chapter43} ${activeChapter === 'chapter43' ? style.active : ''} dropdown-item `}>
                        Introduction Of SQL

                             </li>
                             
                            <li onClick={() => handleClick('chapter44')}
                             className={`${style.chapter44} ${activeChapter === 'chapter44' ? style.active : ''} dropdown-item `}>
                        SQL Syntax and Structure
                             </li>


                             <li onClick={() => handleClick('chapter45')}
                             className={`${style.chapter45} ${activeChapter === 'chapter45' ? style.active : ''} dropdown-item `}>
                        Data Query Language
                             </li>

                             <li onClick={() => handleClick('chapter46')}
                             className={`${style.chapter46} ${activeChapter === 'chapter46' ? style.active : ''} dropdown-item `}>
                        Data Manipulation Language
                             </li>

                             <li onClick={() => handleClick('chapter3')}
                             className={`${style.chapter47} ${activeChapter === 'chapter47' ? style.active : ''} dropdown-item `}>
                        Data Defination Language
                             </li>

                             <li onClick={() => handleClick('chapter4')}
                             className={`${style.chapter48} ${activeChapter === 'chapter48' ? style.active : ''} dropdown-item `}>
                        Data Control Language
                             </li>


<h5>Advanced SQL</h5>
                          

                          <li onClick={() => handleClick('chapter5')} 
                          className={`${style.chapter5} ${activeChapter === 'chapter5' ? style.active : ''} dropdown-item `}>
                          Joins
                          </li>

                          <li onClick={() => handleClick('chapter6')}
                          className={`${style.chapter6} ${activeChapter === 'chapter6' ? style.active : ''} dropdown-item `}>
                          Subqueries and Nested Queries
                          </li>

                          <li onClick={() => handleClick('chapter7')} 
                          className={`${style.chapter7} ${activeChapter === 'chapter7' ? style.active : ''} dropdown-item `}>
                          Aggregate Function
                          </li>

                          <li onClick={() => handleClick('chapter8')}
                          className={`${style.chapter8} ${activeChapter === 'chapter8' ? style.active : ''} dropdown-item `}>
                          GROUP BY and HAVING Clauses
                          </li>

                          <li onClick={() => handleClick('chapter9')}
                            className={`${style.chapter9} ${activeChapter === 'chapter9' ? style.active : ''} dropdown-item `}>
                          Set Operations
                          </li>

<h5>PL/SQL Fundamental</h5>
                          <li onClick={() => handleClick('chapter10')}
                          className={`${style.chapter10} ${activeChapter === 'chapter10' ? style.active : ''} dropdown-item `}>
                          Intoduction of PL/SQL
                          </li>

                          <li onClick={() => handleClick('chapter11')} 
                          className={`${style.chapter11} ${activeChapter === 'chapter11' ? style.active : ''} dropdown-item `}>
                        PL/SQL Synatx and Structure
                          </li>

                          <li onClick={() => handleClick('chapter12')}
                          className={`${style.chapter12} ${activeChapter === 'chapter12' ? style.active : ''} dropdown-item `}>
                          Variables and Data Types 
                          </li>

                          <li onClick={() => handleClick('chapter13')} 
                          className={`${style.chapter13} ${activeChapter === 'chapter13' ? style.active : ''} dropdown-item `}>
                        Control Structure
                          </li>

                          <li onClick={() => handleClick('chapter14')} 
                          className={`${style.chapter14} ${activeChapter === 'chapter14' ? style.active : ''} dropdown-item `}>
                          Procedures and Functions
                          </li>

                          <li onClick={() => handleClick('chapter15')} 
                            className={`${style.chapter15} ${activeChapter === 'chapter15' ? style.active : ''} dropdown-item `}>
                            Exception Handling
                          </li>


<h5>Oracle Database Administration </h5>
                          <li onClick={() => handleClick('chapter16')} 
                          className={`${style.chapter16} ${activeChapter === 'chapter16' ? style.active : ''} dropdown-item `}>
                          User Management and Roles
                          </li>

                          <li onClick={() => handleClick('chapter17')}
                            className={`${style.chapter17} ${activeChapter === 'chapter17' ? style.active : ''} dropdown-item `}> 
                          Tablespace and Datafile Management
                          </li>


                          <li onClick={() => handleClick('chapter18')} 
                          className={`${style.chapter18} ${activeChapter === 'chapter18' ? style.active : ''} dropdown-item `}>
                          Backup and Recovery Startingies
                          </li>


                          <li onClick={() => handleClick('chapter19')}
                          className={`${style.chapter19} ${activeChapter === 'chapter19' ? style.active : ''} dropdown-item `}>
                        Performing Tuning Basics
                          </li>


                          <li onClick={() => handleClick('chapter20')} 
                          className={`${style.chapter20} ${activeChapter === 'chapter20' ? style.active : ''} dropdown-item `}>
                          Monitoring and Trobleshooting
                          </li>

<h5>Oracle Development Tools </h5>
                          <li onClick={() => handleClick('chapter21')}
                          className={`${style.chapter21} ${activeChapter === 'chapter21' ? style.active : ''} dropdown-item `}>
                          Overview of Oracle SQL Devloper
                          </li>


                          <li onClick={() => handleClick('chapter22')} 
                          className={`${style.chapter22} ${activeChapter === 'chapter22' ? style.active : ''} dropdown-item `}>
                          Using Oracle APEX for Application Development
                          </li>


                          <li onClick={() => handleClick('chapter23')} 
                          className={`${style.chapter23} ${activeChapter === 'chapter23' ? style.active : ''} dropdown-item `}>
                        Working with Oracle Data Modeler
                          </li>

                        
                          <li onClick={() => handleClick('chapter24')} 
                          className={`${style.chapter24} ${activeChapter === 'chapter24' ? style.active : ''} dropdown-item `}>
                       Data Modeling 
                          </li>

                        
                          <li onClick={() => handleClick('chapter25')} 
                          className={`${style.chapter25} ${activeChapter === 'chapter25' ? style.active : ''} dropdown-item `}>
                      Entity-Relational (ER)  Modeling
                          </li>

                        
                          <li onClick={() => handleClick('chapter26')} 
                          className={`${style.chapter26} ${activeChapter === 'chapter26' ? style.active : ''} dropdown-item `}>
                        Normalization and Denormalization
                          </li>
<h5>Database Security</h5>
                          <li onClick={() => handleClick('chapter27')} 
                          className={`${style.chapter27} ${activeChapter === 'chapter27' ? style.active : ''} dropdown-item `}>
                      Security Best Practices
                          </li>

                        
                          <li onClick={() => handleClick('chapter28')} 
                          className={`${style.chapter28} ${activeChapter === 'chapter28' ? style.active : ''} dropdown-item `}>
                          Role-Based Access Control (RBAC)
                          </li>
                        

                          <li onClick={() => handleClick('chapter29')} 
                          className={`${style.chapter29} ${activeChapter === 'chapter29' ? style.active : ''} dropdown-item `}>
                      Data Encryption and Auditing
                          </li>

<h5>Working with Oracle Objects</h5>                
                          <li onClick={() => handleClick('chapter30')} 
                          className={`${style.chapter30} ${activeChapter === 'chapter30' ? style.active : ''} dropdown-item `}>
                          Creating and Managing Table
                          </li>

                          <li onClick={() => handleClick('chapter31')} 
                          className={`${style.chapter31} ${activeChapter === 'chapter31' ? style.active : ''} dropdown-item `}>
                          Working with Views, Indexes and Sequences 
                          </li>

                        
                          <li onClick={() => handleClick('chapter32')} 
                          className={`${style.chapter32} ${activeChapter === 'chapter32' ? style.active : ''} dropdown-item `}>
                          Materialized Views and Their Uses
                          </li>
                        
    <h5>Handling Large Data Sets</h5>                    
                          <li onClick={() => handleClick('chapter33')} 
                          className={`${style.chapter33} ${activeChapter === 'chapter33' ? style.active : ''} dropdown-item `}>
                          Bulk Data Processing
                          </li>
                        
                          <li onClick={() => handleClick('chapter34')} 
                          className={`${style.chapter34} ${activeChapter === 'chapter34' ? style.active : ''} dropdown-item `}>
                            Performance Optimization Techniques
                          </li>
              <h5>Oracle APIs and Integration</h5>          
                          <li onClick={() => handleClick('chapter35')} 
                          className={`${style.chapter35} ${activeChapter === 'chapter35' ? style.active : ''} dropdown-item `}>
                        Using Oracle Call Interface (OCI)
                          </li>
                        
                          <li onClick={() => handleClick('chapter36')} 
                          className={`${style.chapter36} ${activeChapter === 'chapter36' ? style.active : ''} dropdown-item `}>
                            Integrating with Java, .NET, and other programming languages
                          </li>

<h5>Project and Case Studies</h5>
                          <li onClick={() => handleClick('chapter37')} 
                          className={`${style.chapter37} ${activeChapter === 'chapter37' ? style.active : ''} dropdown-item `}>
                          Real-world Application and Scenarious
                          </li>
                        
                          <li onClick={() => handleClick('chapter38')} 
                          className={`${style.chapter38} ${activeChapter === 'chapter38' ? style.active : ''} dropdown-item `}>
                        Hands-on Projects to Reinforce Learning
                          </li>
                        






              </ul>
                </div>





                <div className={`${style.leftcolumn} col-2`}>

                    <ul className={`${style.chapters} `}>
                    <h5 className={style.stickyheading}>Oracle</h5>
                        <li
                            onClick={() => handleClick('chapter1')}

                            className={`${style.chapter1} ${activeChapter === 'chapter1' ? style.active : ''} text-decoration-none`}>
                         Introduction 
                            </li>


                            <li
                            onClick={() => handleClick('chapter2')}
                            className={`${style.chapter2} ${activeChapter === 'chapter2' ? style.active : ''} `}>
                         Overview of Oracle Database
                        </li>


                            <li onClick={() => handleClick('chapter39')}
                             className={`${style.chapter39} ${activeChapter === 'chapter39' ? style.active : ''}`}>
                            History and Evolution
                             </li>

<h5>Database Concepts</h5>
                             <li onClick={() => handleClick('chapter40')}
                             className={`${style.chapter40} ${activeChapter === 'chapter40' ? style.active : ''}`}>
                        Relational Database Management System 
                             </li>

                             <li onClick={() => handleClick('chapter41')}
                             className={`${style.chapter41} ${activeChapter === 'chapter41' ? style.active : ''}`}>
                       Tables, Rows, and Columns
                             </li>

                             <li onClick={() => handleClick('chapter42')}
                             className={`${style.chapter42} ${activeChapter === 'chapter42' ? style.active : ''}`}>
                       Primary Keys and Foreign Keys
                             </li>

                        
 <h5>SQL Basics</h5>
                            <li onClick={() => handleClick('chapter43')}
                             className={`${style.chapter43} ${activeChapter === 'chapter43' ? style.active : ''}`}>
                        Introduction Of SQL

                             </li>
                             
                            <li onClick={() => handleClick('chapter44')}
                             className={`${style.chapter44} ${activeChapter === 'chapter44' ? style.active : ''}`}>
                        SQL Syntax and Structure
                             </li>


                             <li onClick={() => handleClick('chapter45')}
                             className={`${style.chapter45} ${activeChapter === 'chapter45' ? style.active : ''}`}>
                        Data Query Language
                             </li>

                             <li onClick={() => handleClick('chapter46')}
                             className={`${style.chapter46} ${activeChapter === 'chapter46' ? style.active : ''}`}>
                        Data Manipulation Language
                             </li>

                             <li onClick={() => handleClick('chapter3')}
                             className={`${style.chapter47} ${activeChapter === 'chapter47' ? style.active : ''}`}>
                        Data Defination Language
                             </li>

                             <li onClick={() => handleClick('chapter4')}
                             className={`${style.chapter48} ${activeChapter === 'chapter48' ? style.active : ''}`}>
                        Data Control Language
                             </li>


<h5>Advanced SQL</h5>
                          

                          <li onClick={() => handleClick('chapter5')} 
                          className={`${style.chapter5} ${activeChapter === 'chapter5' ? style.active : ''}`}>
                          Joins
                          </li>

                          <li onClick={() => handleClick('chapter6')}
                          className={`${style.chapter6} ${activeChapter === 'chapter6' ? style.active : ''}`}>
                          Subqueries and Nested Queries
                          </li>

                          <li onClick={() => handleClick('chapter7')} 
                          className={`${style.chapter7} ${activeChapter === 'chapter7' ? style.active : ''}`}>
                          Aggregate Function
                          </li>

                          <li onClick={() => handleClick('chapter8')}
                          className={`${style.chapter8} ${activeChapter === 'chapter8' ? style.active : ''}`}>
                          GROUP BY and HAVING Clauses
                          </li>

                          <li onClick={() => handleClick('chapter9')}
                            className={`${style.chapter9} ${activeChapter === 'chapter9' ? style.active : ''}`}>
                          Set Operations
                          </li>

<h5>PL/SQL Fundamental</h5>
                          <li onClick={() => handleClick('chapter10')}
                          className={`${style.chapter10} ${activeChapter === 'chapter10' ? style.active : ''}`}>
                          Intoduction of PL/SQL
                          </li>

                          <li onClick={() => handleClick('chapter11')} 
                          className={`${style.chapter11} ${activeChapter === 'chapter11' ? style.active : ''}`}>
                        PL/SQL Synatx and Structure
                          </li>

                          <li onClick={() => handleClick('chapter12')}
                          className={`${style.chapter12} ${activeChapter === 'chapter12' ? style.active : ''}`}>
                          Variables and Data Types 
                          </li>

                          <li onClick={() => handleClick('chapter13')} 
                          className={`${style.chapter13} ${activeChapter === 'chapter13' ? style.active : ''}`}>
                        Control Structure
                          </li>

                          <li onClick={() => handleClick('chapter14')} 
                          className={`${style.chapter14} ${activeChapter === 'chapter14' ? style.active : ''}`}>
                          Procedures and Functions
                          </li>

                          <li onClick={() => handleClick('chapter15')} 
                            className={`${style.chapter15} ${activeChapter === 'chapter15' ? style.active : ''}`}>
                            Exception Handling
                          </li>


<h5>Oracle Database Administration </h5>
                          <li onClick={() => handleClick('chapter16')} 
                          className={`${style.chapter16} ${activeChapter === 'chapter16' ? style.active : ''}`}>
                          User Management and Roles
                          </li>

                          <li onClick={() => handleClick('chapter17')}
                            className={`${style.chapter17} ${activeChapter === 'chapter17' ? style.active : ''}`}> 
                          Tablespace and Datafile Management
                          </li>


                          <li onClick={() => handleClick('chapter18')} 
                          className={`${style.chapter18} ${activeChapter === 'chapter18' ? style.active : ''}`}>
                          Backup and Recovery Startingies
                          </li>


                          <li onClick={() => handleClick('chapter19')}
                          className={`${style.chapter19} ${activeChapter === 'chapter19' ? style.active : ''} `}>
                        Performing Tuning Basics
                          </li>


                          <li onClick={() => handleClick('chapter20')} 
                          className={`${style.chapter20} ${activeChapter === 'chapter20' ? style.active : ''}`}>
                          Monitoring and Trobleshooting
                          </li>

<h5>Oracle Development Tools </h5>
                          <li onClick={() => handleClick('chapter21')}
                          className={`${style.chapter21} ${activeChapter === 'chapter21' ? style.active : ''}`}>
                          Overview of Oracle SQL Devloper
                          </li>


                          <li onClick={() => handleClick('chapter22')} 
                          className={`${style.chapter22} ${activeChapter === 'chapter22' ? style.active : ''}`}>
                          Using Oracle APEX for Application Development
                          </li>


                          <li onClick={() => handleClick('chapter23')} 
                          className={`${style.chapter23} ${activeChapter === 'chapter23' ? style.active : ''}  `}>
                        Working with Oracle Data Modeler
                          </li>

                        
                          <li onClick={() => handleClick('chapter24')} 
                          className={`${style.chapter24} ${activeChapter === 'chapter24' ? style.active : ''}  `}>
                       Data Modeling 
                          </li>

                        
                          <li onClick={() => handleClick('chapter25')} 
                          className={`${style.chapter25} ${activeChapter === 'chapter25' ? style.active : ''}  `}>
                      Entity-Relational (ER)  Modeling
                          </li>

                        
                          <li onClick={() => handleClick('chapter26')} 
                          className={`${style.chapter26} ${activeChapter === 'chapter26' ? style.active : ''}  `}>
                        Normalization and Denormalization
                          </li>
<h5>Database Security</h5>
                          <li onClick={() => handleClick('chapter27')} 
                          className={`${style.chapter27} ${activeChapter === 'chapter27' ? style.active : ''}  `}>
                      Security Best Practices
                          </li>

                        
                          <li onClick={() => handleClick('chapter28')} 
                          className={`${style.chapter28} ${activeChapter === 'chapter28' ? style.active : ''}  `}>
                          Role-Based Access Control (RBAC)
                          </li>
                        

                          <li onClick={() => handleClick('chapter29')} 
                          className={`${style.chapter29} ${activeChapter === 'chapter29' ? style.active : ''}  `}>
                      Data Encryption and Auditing
                          </li>

<h5>Working with Oracle Objects</h5>                
                          <li onClick={() => handleClick('chapter30')} 
                          className={`${style.chapter30} ${activeChapter === 'chapter30' ? style.active : ''}  `}>
                          Creating and Managing Table
                          </li>

                          <li onClick={() => handleClick('chapter31')} 
                          className={`${style.chapter31} ${activeChapter === 'chapter31' ? style.active : ''}  `}>
                          Working with Views, Indexes and Sequences 
                          </li>

                        
                          <li onClick={() => handleClick('chapter32')} 
                          className={`${style.chapter32} ${activeChapter === 'chapter32' ? style.active : ''}  `}>
                          Materialized Views and Their Uses
                          </li>
                        
    <h5>Handling Large Data Sets</h5>                    
                          <li onClick={() => handleClick('chapter33')} 
                          className={`${style.chapter33} ${activeChapter === 'chapter33' ? style.active : ''}  `}>
                          Bulk Data Processing
                          </li>
                        
                          <li onClick={() => handleClick('chapter34')} 
                          className={`${style.chapter34} ${activeChapter === 'chapter34' ? style.active : ''}  `}>
                            Performance Optimization Techniques
                          </li>
              <h5>Oracle APIs and Integration</h5>          
                          <li onClick={() => handleClick('chapter35')} 
                          className={`${style.chapter35} ${activeChapter === 'chapter35' ? style.active : ''}  `}>
                        Using Oracle Call Interface (OCI)
                          </li>
                        
                          <li onClick={() => handleClick('chapter36')} 
                          className={`${style.chapter36} ${activeChapter === 'chapter36' ? style.active : ''}  `}>
                            Integrating with Java, .NET, and other programming languages
                          </li>

<h5>Project and Case Studies</h5>
                          <li onClick={() => handleClick('chapter37')} 
                          className={`${style.chapter37} ${activeChapter === 'chapter37' ? style.active : ''}  `}>
                          Real-world Application and Scenarious
                          </li>
                        
                          <li onClick={() => handleClick('chapter38')} 
                          className={`${style.chapter38} ${activeChapter === 'chapter38' ? style.active : ''}  `}>
                        Hands-on Projects to Reinforce Learning
                          </li>
                        


                    </ul>
                </div>




                <div className={`${style.rightcolumn} col`}>
                    {/* Chapter Content */}



                    {selectedChapter === 'chapter1' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Introduction</h1>

    <p>
      Oracle is a widely used relational database management system (RDBMS) developed by Oracle Corporation. Known for its high performance, scalability, and reliability, Oracle Database is designed to manage large amounts of data and support complex transactions in enterprise environments. It is used by organizations of all sizes across various industries for storing, retrieving, and managing data securely.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Key Features of Oracle Database:</h2>
    <ol>
      <li>
        <strong>Relational Database Management</strong>: Oracle is a relational database, meaning data is stored in tables with rows and columns, and relationships between these tables are maintained through foreign keys.
      </li><br />
      <li>
        <strong>Scalability and Performance</strong>: Oracle Database is designed to handle large datasets and high volumes of transactions, making it suitable for enterprise-level applications.
      </li><br />
      <li>
        <strong>Data Security</strong>: Oracle provides advanced security features, including encryption, access controls, and auditing to protect data from unauthorized access.
      </li><br />
      <li>
        <strong>High Availability</strong>: Features like Oracle Real Application Clusters (RAC) and Data Guard ensure high availability and disaster recovery by replicating data across multiple servers and locations.
      </li>
      <li>
        <strong>Multimodel Support</strong>: Oracle supports not only relational data but also unstructured and semi-structured data like JSON, XML, and spatial data.
      </li><br />
      <li>
        <strong>PL/SQL</strong>: Oracle includes PL/SQL (Procedural Language/SQL), which extends SQL with procedural programming constructs, allowing users to write complex queries and applications.
      </li><br />
      <li>
        <strong>Backup and Recovery</strong>: Oracle offers comprehensive backup and recovery solutions to prevent data loss and ensure business continuity.
      </li><br />
      <li>
        <strong>Data Warehousing and Analytics</strong>: Oracle provides tools for data warehousing, OLAP (Online Analytical Processing), and advanced analytics, making it a powerful platform for business intelligence.
      </li>
    </ol><br />

    <p>
      Overall, Oracle is recognized for its robustness in handling mission-critical applications and supporting modern data needs, from traditional transactional systems to cloud-based and big data environments.
    </p>
  </div>
)}


{selectedChapter === 'chapter2' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Overview of Oracle Database</h1>

    <p>
      Oracle Database is a powerful relational database management system (RDBMS) developed by Oracle Corporation. It is widely recognized for its robustness, scalability, and performance, making it a preferred choice for enterprises and organizations around the globe. Here are some key aspects of Oracle Database:
    </p><br />

    <h2>1. Relational Database Architecture</h2>
    <p>
      Oracle Database is based on the relational model, which organizes data into tables (relations) linked by relationships. Each table consists of rows and columns, where each row represents a record and each column represents a field of data. This structure allows for efficient data retrieval and manipulation through SQL (Structured Query Language).
    </p><br />

    <h2>2. Scalability</h2>
    <p>
      Oracle Database is designed to handle large volumes of data and high transaction rates. Its architecture supports both vertical and horizontal scaling, making it suitable for a wide range of applications—from small-scale systems to large enterprise environments.
    </p><br />

    <h2>3. Data Integrity and Security</h2>
    <p>
      Oracle places a strong emphasis on data integrity and security. It provides advanced features like:
    </p>
    <ul>
      <li><strong>ACID Compliance:</strong> Ensures transactions are processed reliably.</li><br />
      <li><strong>Access Control:</strong> Role-based permissions to restrict data access.</li><br />
      <li><strong>Encryption:</strong> Protects data at rest and in transit.</li><br />
      <li><strong>Auditing:</strong> Monitors and logs access and changes to data.</li>
    </ul><br />

    <h2>4. High Availability and Disaster Recovery</h2>
    <p>
      Oracle Database includes features like Oracle Real Application Clusters (RAC) and Data Guard to ensure continuous operation and data availability. These technologies allow for automatic failover and data replication across multiple locations, enhancing resilience against outages.
    </p><br />

    <h2>5. Multimodel Capabilities</h2>
    <p>
      Oracle supports various data models, including relational, document, graph, and key-value, enabling users to work with different types of data within a single database environment. This flexibility is particularly beneficial for modern applications that require diverse data storage solutions.
    </p><br />

    <h2>6. PL/SQL</h2>
    <p>
      Oracle Database includes PL/SQL (Procedural Language/SQL), an extension of SQL that adds procedural constructs, allowing developers to write complex scripts and applications. PL/SQL facilitates the creation of stored procedures, triggers, and functions, enhancing the database's programmability.
    </p><br />

    <h2>7. Backup and Recovery Solutions</h2>
    <p>
      Oracle provides robust backup and recovery features, such as Recovery Manager (RMAN), to ensure data protection and restore capabilities. These tools help organizations safeguard their data against corruption or loss and facilitate business continuity.
    </p><br />

    <h2>8. Advanced Analytics and Data Warehousing</h2>
    <p>
      Oracle Database includes built-in analytical capabilities and support for data warehousing. It offers features like OLAP (Online Analytical Processing), data mining, and machine learning, making it a powerful platform for business intelligence and analytics.
    </p><br />

    <h2>Conclusion</h2>
    <p>
      Oracle Database is a comprehensive solution for managing and processing data in various environments, from transactional systems to analytics and big data applications. Its rich feature set and reliability make it a leader in the database management industry, catering to the needs of organizations of all sizes.
    </p>
  </div>
)}

{selectedChapter === 'chapter39' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>History and Evolution of Oracle</h1>


    <h3>1. Founding and Early Years (1977-1980)</h3>
    <p>
      Oracle Corporation was founded in 1977 by Larry Ellison, Bob Miner, and Ed Oates. Initially called 
      "Software Development Laboratories," the company aimed to create a relational database management 
      system (RDBMS) based on Edgar F. Codd's relational model. In 1979, the company released its first 
      product, Oracle Version 2, which became the first commercially available RDBMS to use SQL 
      (Structured Query Language).
    </p><br />

    <h3>2. Growth and Expansion (1980s)</h3>
    <p>
      Throughout the 1980s, Oracle expanded rapidly, gaining a reputation for reliability and performance. 
      The introduction of Oracle Version 3 in 1983 and Version 4 in 1984 added features like multi-user 
      support and PL/SQL, Oracle's procedural extension to SQL. By the mid-1980s, Oracle had established 
      itself as a leader in the database market, competing against companies like IBM and Sybase.
    </p><br />

    <h3>3. Going Public (1986)</h3>
    <p>
      In 1986, Oracle Corporation went public, raising $22 million in its initial public offering (IPO). 
      This move provided the necessary capital for further research and development, helping the company 
      enhance its product offerings.
    </p><br />

    <h3>4. Technological Advancements (1990s)</h3>
    <p>
      The 1990s marked significant advancements for Oracle. The company released several versions of its 
      database software, introducing features like:
    </p>
    <ul>
      <li><strong>Oracle 7 (1992)</strong>: Featured support for object-oriented programming and a more 
      sophisticated data dictionary.</li><br />
      <li><strong>Oracle 8 (1997)</strong>: Introduced native support for object-oriented data and improved 
      performance and scalability.</li>
    </ul><br />
    <p>
      In 1995, Oracle introduced Oracle Applications, a suite of enterprise resource planning (ERP) 
      software, expanding its reach beyond database management into business applications.
    </p><br />

    <h3>5. Diversification and New Markets (2000s)</h3>
    <p>
      In the early 2000s, Oracle began diversifying its product line through acquisitions. Key acquisitions 
      included:
    </p>
    <ul>
      <li><strong>PeopleSoft (2005)</strong>: Strengthened Oracle's position in the ERP market.</li><br />
      <li><strong>Siebel Systems (2006)</strong>: Enhanced Oracle's customer relationship management (CRM) 
      capabilities.</li><br />
      <li><strong>Sun Microsystems (2010)</strong>: Provided hardware integration with Oracle's software, 
      including the acquisition of the popular Solaris operating system.</li>
    </ul><br />

    <h3>6. Cloud Computing and Modernization (2010s)</h3>
    <p>
      With the rise of cloud computing, Oracle shifted its focus to cloud-based solutions. The introduction 
      of Oracle Cloud in 2012 offered infrastructure as a service (IaaS) and platform as a service (PaaS) 
      solutions. Oracle's cloud offerings expanded to include:
    </p>
    <ul>
      <li><strong>Oracle Database Cloud</strong>: Enabling customers to run Oracle databases in the cloud.</li>
      <li><strong>Oracle Cloud Applications</strong>: A suite of SaaS products for various business functions.</li>
    </ul><br />

    <h3>7. Ongoing Innovation (2020s)</h3>
    <p>
      In recent years, Oracle has continued to innovate with features like autonomous databases that utilize 
      machine learning to automate database management tasks. The company has also emphasized security, 
      data privacy, and compliance in its cloud offerings.
    </p><br />

    <h2>Conclusion</h2>
    <p>
      Oracle's history reflects its commitment to innovation and adaptability in a rapidly changing technology 
      landscape. From its origins as a relational database provider to a comprehensive cloud computing and 
      enterprise software company, Oracle has maintained its position as a leader in the database management 
      industry for over four decades. Today, it serves millions of users worldwide, offering a wide range of 
      products and services that cater to various business needs.
    </p>
  </div>
)}




{selectedChapter === 'chapter40' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Relational Database Management System (RDBMS)</h1>
    <p>
      A Relational Database Management System (RDBMS) is a type of database management system that stores data in a structured format, using rows and columns. Oracle Corporation's Oracle Database is one of the most widely used RDBMS, known for its scalability, performance, and advanced features.
    </p><br />

    <h3 style={{paddingBottom:"6px"}}>Key Characteristics of Oracle RDBMS:</h3>
    <ol>
      <li>
        <strong>Data Organization:</strong>
        <p>
          Data is organized into tables (also known as relations), where each table contains rows and columns. Each row represents a unique record, and each column represents an attribute of the record.
        </p>
      </li><br />
      <li>
        <strong>Structured Query Language (SQL):</strong>
        <p>
          Oracle RDBMS utilizes SQL as its primary language for querying and managing the database. SQL provides a standardized way to perform operations such as inserting, updating, deleting, and retrieving data.
        </p>
      </li><br />
      <li>
        <strong>Data Integrity:</strong>
        <p>
          Oracle enforces data integrity through constraints (e.g., primary keys, foreign keys, unique constraints) that ensure accuracy and consistency of the data within the database.
        </p>
      </li><br />
      <li>
        <strong>Relationships Between Tables:</strong>
        <p>
          Relationships can be established between tables using foreign keys, allowing for efficient data retrieval and maintaining data integrity across related tables.
        </p>
      </li><br />
      <li>
        <strong>ACID Properties:</strong>
        <p>
          Oracle RDBMS adheres to ACID (Atomicity, Consistency, Isolation, Durability) principles, ensuring reliable transaction processing. This means that all transactions are completed fully or not at all, maintaining the consistency of the database.
        </p>
      </li><br />
      <li>
        <strong>Scalability and Performance:</strong>
        <p>
          Designed to handle large volumes of data and high transaction rates, Oracle RDBMS supports both vertical and horizontal scaling. This makes it suitable for enterprise-level applications.
        </p>
      </li><br />
      <li>
        <strong>Backup and Recovery:</strong>
        <p>
          Oracle provides comprehensive backup and recovery solutions, such as Recovery Manager (RMAN), to protect data against loss or corruption and ensure business continuity.
        </p>
      </li><br />
      <li>
        <strong>Security Features:</strong>
        <p>
          Oracle RDBMS offers advanced security features, including user authentication, role-based access control, data encryption, and auditing, ensuring that data is protected from unauthorized access.
        </p>
      </li><br />
      <li>
        <strong>Multi-User Support:</strong>
        <p>
          Multiple users can access the database simultaneously without conflicts, thanks to Oracle's sophisticated locking mechanisms and transaction management.
        </p>
      </li><br />
      <li>
        <strong>Support for Complex Queries:</strong>
        <p>
          Oracle RDBMS supports complex queries, including joins, subqueries, and analytical functions, enabling users to perform advanced data analysis and reporting.
        </p>
      </li>
    </ol><br />

    <h3>Conclusion</h3>
    <p>
      In summary, Oracle's RDBMS provides a robust and flexible platform for managing relational data. Its comprehensive features cater to a wide range of applications, from small-scale systems to large enterprise environments, making it a preferred choice for organizations looking to manage and analyze their data effectively.
    </p>
  </div>
)}




{selectedChapter === 'chapter41' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Tables, Rows, and Columns</h1>

    <p>
      In Oracle Database, data is organized into a structured format using tables, which consist of rows and columns. This structure enables efficient data management and retrieval.
    </p><br />

    <h2>1. Tables</h2>
    <ul>
      <li>
        <strong>Definition</strong>: A table is a collection of related data entries that consists of columns and rows. Each table in an Oracle Database represents a specific entity (e.g., customers, orders, products).
      </li><br />
      <li>
        <strong>Structure</strong>: Tables are defined by their schema, which includes the table name, the names of the columns, their data types, and any constraints (e.g., primary keys, foreign keys).
      </li><br />
      <li>
        <strong>Example</strong>: An example of a simple table might be <code>customers</code>, which stores information about customers.
        <pre>
          <code>
            CREATE TABLE customers (
                customer_id NUMBER PRIMARY KEY,
                name VARCHAR2(100),
                email VARCHAR2(100)
            );
          </code>
        </pre>
      </li>
    </ul><br />

    <h2>2. Rows</h2>
    <ul>
      <li>
        <strong>Definition</strong>: A row (or record) represents a single entry in a table. Each row contains data related to a specific instance of the entity represented by the table.
      </li><br />
      <li>
        <strong>Example</strong>: In the <code>customers</code> table, each row might represent a different customer.
        <table>
          <thead>
            <tr>
              <th>customer_id</th>
              <th>name</th>
              <th>email</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>John Doe</td>
              <td>john@example.com</td>
            </tr>
            <tr>
              <td>2</td>
              <td>Jane Smith</td>
              <td>jane@example.com</td>
            </tr>
          </tbody>
        </table>
      </li>
    </ul><br />

    <h2>3. Columns</h2>
    <ul>
      <li>
        <strong>Definition</strong>: Columns define the attributes or fields of the data stored in the table. Each column has a specific data type (e.g., INTEGER, VARCHAR2, DATE) that dictates the kind of data it can hold.
      </li><br />
      <li>
        <strong>Example</strong>: In the <code>customers</code> table:
        <ul>
          <li><code>customer_id</code> is a column of type NUMBER, serving as the unique identifier for each customer.</li><br />
          <li><code>name</code> is a column of type VARCHAR2, used to store the customer's name.</li><br />
          <li><code>email</code> is another VARCHAR2 column used to store the customer's email address.</li>
        </ul>
      </li>
    </ul><br />

    <h2>Conclusion</h2>
    <p>
      Understanding the concepts of tables, rows, and columns is fundamental to working with Oracle Database. This structured approach to data organization enables efficient querying, data integrity, and ease of maintenance. By defining the relationships between tables through foreign keys, Oracle allows for a robust data model that supports complex queries and analytics.
    </p>
  </div>
)}

{selectedChapter === 'chapter42' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Primary Keys and Foreign Keys</h1>

    <p><strong>Primary Keys</strong> and <strong>Foreign Keys</strong> are essential components of relational databases like Oracle, helping ensure data integrity and proper relationships between tables.</p><br />

  

    <h3>Primary Key</h3>
    <p><strong>Definition</strong>: A <strong>primary key</strong> is a column or set of columns that uniquely identifies each record in a table. It ensures that no two rows have the same value(s) for the column(s) defined as the primary key.</p>

    <ul>
      <li><strong>Uniqueness</strong>: Every value in the primary key column must be unique.</li><br />
      <li><strong>Non-null</strong>: A primary key column cannot have NULL values.</li><br />
      <li><strong>Stability</strong>: Once assigned, the primary key should not change over time.</li>
    </ul><br />

    <p><strong>Example</strong>: In a <code>customers</code> table, the <code>customer_id</code> can serve as a primary key to uniquely identify each customer.</p>

    <pre>
      <code>{`
        CREATE TABLE customers (
          customer_id NUMBER PRIMARY KEY,
          name VARCHAR2(100),
          email VARCHAR2(100)
        );
      `}</code>
    </pre>

 <br />

    <h3>Foreign Key</h3>
    <p><strong>Definition</strong>: A <strong>foreign key</strong> is a column or set of columns in one table that references the <strong>primary key</strong> of another table. It creates a relationship between the two tables and ensures that the value in the foreign key column corresponds to an existing record in the referenced table.</p>

    <ul>
      <li><strong>Referential Integrity</strong>: Foreign keys enforce relationships between tables, ensuring that invalid data cannot be inserted.</li><br />
      <li><strong>Consistency</strong>: Changes to the primary key in the parent table are automatically propagated to the foreign key in the child table if the relationship is maintained through constraints.</li>
    </ul><br />

    <p><strong>Example</strong>: In an <code>orders</code> table, a <code>customer_id</code> foreign key can reference the <code>customer_id</code> primary key in the <code>customers</code> table.</p>

    <pre>
      <code>{`
        CREATE TABLE orders (
          order_id NUMBER PRIMARY KEY,
          customer_id NUMBER,
          order_date DATE,
          FOREIGN KEY (customer_id) REFERENCES customers(customer_id)
        );
      `}</code>
    </pre>

 <br />

    <h3>Relationship Between Primary and Foreign Keys</h3>
    <p><strong>Parent-Child Relationship</strong>: The <strong>primary key</strong> in the parent table (e.g., <code>customers</code>) is referenced by the <strong>foreign key</strong> in the child table (e.g., <code>orders</code>). This enforces the relationship between the two tables.</p>

    <ul>
      <li><strong>ON DELETE CASCADE</strong>: If a parent row is deleted, all corresponding child rows will be automatically deleted.</li><br />
      <li><strong>ON UPDATE CASCADE</strong>: If the primary key in the parent table is updated, the corresponding foreign key values in the child table are also updated.</li>
    </ul>

 <br />

    <h3>Conclusion</h3>
    <p><strong>Primary Keys</strong> ensure each row in a table is unique and identifiable. <strong>Foreign Keys</strong> maintain relationships between different tables and enforce referential integrity. These keys are essential for creating a well-structured relational database in Oracle, allowing for efficient data management and retrieval.</p>

   
  </div>
)}



{selectedChapter === 'chapter44' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>SQL Syntax and Structure</h1>
    <p>
      <strong>SQL (Structured Query Language)</strong> is a standardized programming language used to manage and manipulate relational databases. In Oracle, SQL is employed for various tasks, including querying data, modifying data, and managing database objects.
    </p><br />

    <h3>1. Basic SQL Syntax</h3>

    <p>
      SQL statements generally follow a simple structure. The basic syntax consists of keywords and clauses that perform specific operations. The general format for an SQL statement is:
    </p>
    <pre>
      <code>[SQL Command] [object_type] [object_name] [optional clauses];</code>
    </pre>
    <p>
      For example, a <code>SELECT</code> statement to retrieve data from a table would look like this:
    </p>
    <pre>
      <code>SELECT column1, column2 FROM table_name WHERE condition;</code>
    </pre><br />

    <h3 style={{paddingBottom:"6px"}}>2. Components of SQL Statements</h3>
    <ul>
      <li>
        <strong>Keywords</strong>: Reserved words that define the operation (e.g., <code>SELECT</code>, <code>INSERT</code>, <code>UPDATE</code>, <code>DELETE</code>, <code>CREATE</code>, <code>ALTER</code>, <code>DROP</code>).
      </li><br />
      <li>
        <strong>Identifiers</strong>: Names used for database objects, such as tables, columns, and indexes.
      </li><br />
      <li>
        <strong>Clauses</strong>: Optional components that further refine the command (e.g., <code>WHERE</code>, <code>ORDER BY</code>, <code>GROUP BY</code>).
      </li><br />
      <li>
        <strong>Operators</strong>: Symbols used to perform operations (e.g., <code>=</code>, <code>&gt;</code>, <code>&lt;</code>, <code>AND</code>, <code>OR</code>).
      </li>
    </ul><br />

    <h3 style={{paddingBottom:"6px"}}>3. Common SQL Commands</h3>
    <ul>
      <li>
        <strong>Data Query Language (DQL)</strong>: Used to query the database.
        <pre>
          <code>SELECT * FROM employees;</code>
        </pre>
      </li><br />
      <li>
        <strong>Data Manipulation Language (DML)</strong>: Used to manipulate data.
        <ul>
          <li>
            Insert:
            <pre>
              <code>INSERT INTO employees (employee_id, first_name, last_name) VALUES (1, 'John', 'Doe');</code>
            </pre>
          </li><br />
          <li>
            Update:
            <pre>
              <code>UPDATE employees SET email = 'john.doe@example.com' WHERE employee_id = 1;</code>
            </pre>
          </li><br />
          <li>
            Delete:
            <pre>
              <code>DELETE FROM employees WHERE employee_id = 1;</code>
            </pre>
          </li>
        </ul>
      </li><br />
      <li>
        <strong>Data Definition Language (DDL)</strong>: Used to define database structures.
        <pre>
          <code>
            CREATE TABLE employees (
                employee_id NUMBER PRIMARY KEY,
                first_name VARCHAR2(50),
                last_name VARCHAR2(50)
            );
          </code>
        </pre>
      </li><br />
      <li>
        <strong>Data Control Language (DCL)</strong>: Used to control access to data.
        <pre>
          <code>GRANT SELECT ON employees TO user_name;</code>
        </pre>
      </li>
    </ul><br />

    <h3>4. Structure of SQL Statements</h3>
    <p>
      An SQL statement generally follows this order:
    </p>
    <ol>
      <li><strong>Action Clause</strong>: The command (e.g., <code>SELECT</code>, <code>INSERT</code>).</li><br />
      <li><strong>Target</strong>: The object on which the action is performed (e.g., table name).</li><br />
      <li><strong>Set Clause</strong>: Specifies the columns and values to modify (for <code>INSERT</code> and <code>UPDATE</code>).</li><br />
      <li><strong>Condition Clause</strong>: Filters the data (e.g., <code>WHERE</code> clause).</li><br />
      <li><strong>Order/Group Clause</strong>: Sorts or groups the results (e.g., <code>ORDER BY</code>, <code>GROUP BY</code>).</li>
    </ol><br />

    <h3>Conclusion</h3>
    <p>
      Understanding SQL syntax and structure is fundamental to effectively interacting with Oracle Database. This knowledge allows users to efficiently retrieve and manipulate data while ensuring the integrity and organization of their database systems.
    </p>

   

  </div>
)}



{selectedChapter === 'chapter45' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Data Query Language (DQL): SELECT Statement</h1>

    <p>The <strong>Data Query Language (DQL)</strong> is used to query the database and retrieve data stored in tables. The primary command in DQL is the <code>SELECT</code> statement, which allows users to specify which data they want to retrieve.</p>

   <br />

    <h2>1. Basic Syntax of the SELECT Statement</h2>
    <p>The basic syntax for a <code>SELECT</code> statement is as follows:</p>
    <pre>
      <code>SELECT [columns] FROM [table] [WHERE condition];</code>
    </pre>
    <p>Here, <code>[columns]</code> specifies the columns to retrieve, <code>[table]</code> is the table from which to retrieve data, and the <code>[WHERE]</code> clause is optional for filtering results.</p><br />

    <h2>2. Selecting All Columns</h2>
    <p>To select all columns from a table, you can use the asterisk (<code>*</code>) wildcard:</p>
    <pre>
      <code>SELECT * FROM employees;</code>
    </pre><br />

    <h2>3. Selecting Specific Columns</h2>
    <p>To select specific columns, list the column names separated by commas:</p>
    <pre>
      <code>SELECT first_name, last_name FROM employees;</code>
    </pre><br />

    <h2>4. Using the WHERE Clause</h2>
    <p>The <code>WHERE</code> clause filters records based on specified conditions:</p>
    <pre>
      <code>SELECT * FROM employees WHERE department_id = 10;</code>
    </pre><br />

    <h2>5. Sorting Results</h2>
    <p>You can sort the results using the <code>ORDER BY</code> clause:</p>
    <pre>
      <code>SELECT first_name, last_name FROM employees ORDER BY last_name ASC;</code>
    </pre>
    <p>The <code>ASC</code> keyword sorts the results in ascending order. Use <code>DESC</code> for descending order.</p><br />

    <h2>6. Limiting Results</h2>
    <p>To limit the number of results returned, you can use the <code>ROWNUM</code> or <code>FETCH FIRST</code> clause:</p>
    <pre>
      <code>{`SELECT * FROM employees WHERE ROWNUM <= 5;`}</code>
      <code>{`SELECT * FROM employees FETCH FIRST 5 ROWS ONLY;`}</code>
    </pre><br />

    <h2>7. Conclusion</h2>
    <p>The <code>SELECT</code> statement is a powerful tool in Oracle for querying data. Understanding its syntax and various options allows users to efficiently retrieve and manipulate data from their databases.</p>


  </div>
)}



{selectedChapter === 'chapter46' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Data Manipulation Language (DML): INSERT, UPDATE, DELETE   </h1>

    <p>The <strong>Data Manipulation Language (DML)</strong> is a subset of SQL used for managing data within relational databases. DML provides commands for adding, modifying, and removing data stored in tables. The three primary DML commands are <code>INSERT</code>, <code>UPDATE</code>, and <code>DELETE</code>.</p>

 <br />

    <h2>1. INSERT Statement</h2>
    <p>The <code>INSERT</code> statement is used to add new records to a table. The basic syntax is:</p>
    <pre>
      <code>INSERT INTO table_name (column1, column2, ...) VALUES (value1, value2, ...);</code>
    </pre>
    <p>Example:</p>
    <pre>
      <code>
      INSERT INTO employees (employee_id, first_name, last_name, hire_date)
      VALUES (1, 'John', 'Doe', '2023-01-01');
      </code>
    </pre><br />

    <h2>2. UPDATE Statement</h2>
    <p>The <code>UPDATE</code> statement is used to modify existing records in a table. The syntax is as follows:</p>
    <pre>
      <code>UPDATE table_name SET column1 = value1, column2 = value2 WHERE condition;</code>
    </pre>
    <p>Example:</p>
    <pre>
      <code>
      UPDATE employees SET email = 'john.doe@example.com' WHERE employee_id = 1;
      </code>
    </pre><br />

    <h2>3. DELETE Statement</h2>
    <p>The <code>DELETE</code> statement is used to remove records from a table. The syntax is:</p>
    <pre>
      <code>DELETE FROM table_name WHERE condition;</code>
    </pre>
    <p>Example:</p>
    <pre>
      <code>
      DELETE FROM employees WHERE employee_id = 1;
      </code>
    </pre><br />

    <h2>4. Using WHERE Clause</h2>
    <p>For both <code>UPDATE</code> and <code>DELETE</code>, the <code>WHERE</code> clause is crucial to specify which records to modify or remove. Omitting the <code>WHERE</code> clause will affect all records in the table.</p>

    <h2>5. Conclusion</h2>
    <p>DML is essential for manipulating data in Oracle databases. Mastery of <code>INSERT</code>, <code>UPDATE</code>, and <code>DELETE</code> commands is vital for effective data management, allowing users to maintain and update their database records efficiently.</p>

   </div>
)}





{selectedChapter === 'chapter3' && (
  <div className={style.chaptercontent}>
 <h1 className={style.heading}>Data Defination Language (DDL): CREAT, ALTER, DROP </h1>

    <p>The <strong>Data Definition Language (DDL)</strong> is a subset of SQL used to define and manage database structures, such as tables, indexes, and schemas. DDL commands provide the means to create, modify, and remove database objects. The three primary DDL commands are <code>CREATE</code>, <code>ALTER</code>, and <code>DROP</code>.</p>

  <br />

    <h2>1. CREATE Statement</h2>
    <p>The <code>CREATE</code> statement is used to create new database objects, such as tables, views, or indexes. The basic syntax for creating a table is:</p>
    <pre>
      <code>{`
      CREATE TABLE table_name (
          column1 datatype [constraints],
          column2 datatype [constraints],
          ...
      );
      `}</code>
    </pre>
    <p>Example:</p>
    <pre>
      <code>{`
      CREATE TABLE employees (
          employee_id NUMBER PRIMARY KEY,
          first_name VARCHAR2(50),
          last_name VARCHAR2(50),
          hire_date DATE
      );
      `}</code>
    </pre><br />

    <h2>2. ALTER Statement</h2>
    <p>The <code>ALTER</code> statement is used to modify existing database objects. You can use it to add, modify, or drop columns in a table. The basic syntax is:</p>
    <pre>
      <code>{`
      ALTER TABLE table_name 
      {ADD | MODIFY | DROP} column_name datatype;
      `}</code>
    </pre>
    <p>Example of adding a column:</p>
    <pre>
      <code>{`
      ALTER TABLE employees 
      ADD email VARCHAR2(100);
      `}</code>
    </pre>
    <p>Example of modifying a column:</p>
    <pre>
      <code>{`
      ALTER TABLE employees 
      MODIFY last_name VARCHAR2(100);
      `}</code>
    </pre>
    <p>Example of dropping a column:</p>
    <pre>
      <code>{`
      ALTER TABLE employees 
      DROP COLUMN email;
      `}</code>
    </pre><br />

    <h2>3. DROP Statement</h2>
    <p>The <code>DROP</code> statement is used to remove existing database objects. The basic syntax is:</p>
    <pre>
      <code>{`
      DROP TABLE table_name;
     `} </code>
    </pre>
    <p>Example:</p>
    <pre>
      <code>{`
      DROP TABLE employees;
     `} </code>
    </pre>
    <p>Note: Dropping a table removes the table and all its data permanently.</p><br />

    <h2>4. Conclusion</h2>
    <p>DDL is fundamental for defining and managing the structure of Oracle databases. Mastery of <code>CREATE</code>, <code>ALTER</code>, and <code>DROP</code> commands is essential for effective database design and management, allowing users to build and modify their database schemas efficiently.</p>

  
  </div>
)}






{selectedChapter === 'chapter4' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Data Control Language (DCL): GRAANT and REVOKE</h1>

    <p>The <strong>Data Control Language (DCL)</strong> is a subset of SQL used to control access to data within the database. It provides commands to grant or revoke user privileges on database objects, ensuring that users have the appropriate access rights for performing operations on those objects.</p>

  <br />

    <h2>1. GRANT Statement</h2>
    <p>The <code>GRANT</code> statement is used to give specific privileges to users or roles, allowing them to perform certain actions on database objects. The basic syntax is:</p>
    <pre>
      <code>{`
      GRANT privilege_type ON object_type object_name TO user_name;
       `} </code>
    </pre>
    <p>Example:</p>
    <pre>
      <code>{`
      GRANT SELECT, INSERT ON employees TO user_name;
       `}</code>
    </pre>
    <p>This grants the user <code>user_name</code> the ability to <code>SELECT</code> and <code>INSERT</code> data into the <code>employees</code> table.</p><br />

    <h2>2. REVOKE Statement</h2>
    <p>The <code>REVOKE</code> statement is used to remove previously granted privileges from users or roles. The basic syntax is:</p>
    <pre>
      <code>{`
      REVOKE privilege_type ON object_type object_name FROM user_name;
       `}</code>
    </pre>
    <p>Example:</p>
    <pre>
      <code>{`
      REVOKE INSERT ON employees FROM user_name;
       `}</code>
    </pre>
    <p>This revokes the <code>INSERT</code> privilege on the <code>employees</code> table from the user <code>user_name</code>.</p><br />

    <h2>3. Conclusion</h2>
    <p>DCL commands are essential for maintaining security and data integrity in Oracle databases. By effectively using <code>GRANT</code> and <code>REVOKE</code>, database administrators can control access to sensitive data and ensure that users have the necessary permissions to perform their tasks without compromising the database's security.</p>

 
  </div>
)}


{selectedChapter === 'chapter5' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Joins: INNER JOIN, OUTER JOIN, CROSS JOIN </h1>
  
          <h3>1. <strong>INNER JOIN</strong></h3>
          <p>An <code>INNER JOIN</code> returns only the rows where there is a match in both tables based on a specified condition. If no match is found, the rows are excluded from the result set.</p>

          <p><strong>Syntax:</strong></p>
          <pre>
            <code>
              {`SELECT columns
FROM table1
INNER JOIN table2 ON table1.column = table2.column;`}
            </code>
          </pre>

          <p><strong>Example:</strong></p>
          <pre>
            <code>
              {`SELECT e.employee_id, e.first_name, d.department_name
FROM employees e
INNER JOIN departments d ON e.department_id = d.department_id;`}
            </code>
          </pre>
          <p>- In this example, only employees who belong to a department (i.e., have a matching <code>department_id</code>) will be included in the result.</p>

          <hr /><br />

          <h3>2. <strong>OUTER JOIN</strong></h3>
          <p>An <code>OUTER JOIN</code> returns all the rows from one or both tables, even if there is no match. There are three types of outer joins:</p>

          <ul>
            <li>
              <p><strong>LEFT OUTER JOIN:</strong> Returns all rows from the left (first) table, along with the matched rows from the right (second) table. If no match is found, <code>NULL</code> is returned for columns of the right table.</p>

              <p><strong>Syntax:</strong></p>
              <pre>
                <code>
                  {`SELECT columns
FROM table1
LEFT OUTER JOIN table2 ON table1.column = table2.column;`}
                </code>
              </pre>

              <p><strong>Example:</strong></p>
              <pre>
                <code>
                  {`SELECT e.employee_id, e.first_name, d.department_name
FROM employees e
LEFT OUTER JOIN departments d ON e.department_id = d.department_id;`}
                </code>
              </pre>
              <p>- This query returns all employees, even if they don’t belong to a department (i.e., where <code>department_id</code> is <code>NULL</code>).</p>
            </li>

            <li>
              <p><strong>RIGHT OUTER JOIN:</strong> Returns all rows from the right (second) table, along with the matched rows from the left (first) table. If no match is found, <code>NULL</code> is returned for columns of the left table.</p>

              <p><strong>Syntax:</strong></p>
              <pre>
                <code>
                  {`SELECT columns
FROM table1
RIGHT OUTER JOIN table2 ON table1.column = table2.column;`}
                </code>
              </pre>

              <p><strong>Example:</strong></p>
              <pre>
                <code>
                  {`SELECT e.employee_id, e.first_name, d.department_name
FROM employees e
RIGHT OUTER JOIN departments d ON e.department_id = d.department_id;`}
                </code>
              </pre>
              <p>- This query returns all departments, even those with no employees.</p>
            </li><br />

            <li>
              <p><strong>FULL OUTER JOIN:</strong> Returns all rows when there is a match in either table. Rows in one table without a corresponding match in the other will still appear, with <code>NULL</code> in the unmatched columns.</p>

              <p><strong>Syntax:</strong></p>
              <pre>
                <code>
                  {`SELECT columns
FROM table1
FULL OUTER JOIN table2 ON table1.column = table2.column;`}
                </code>
              </pre>

              <p><strong>Example:</strong></p>
              <pre>
                <code>
                  {`SELECT e.employee_id, e.first_name, d.department_name
FROM employees e
FULL OUTER JOIN departments d ON e.department_id = d.department_id;`}
                </code>
              </pre>
              <p>- This query returns all employees and all departments, with <code>NULL</code> in the department fields for employees without departments and vice versa.</p>
            </li>
          </ul><br />

       

          <h3>3. <strong>CROSS JOIN</strong></h3>
          <p>A <code>CROSS JOIN</code> produces a Cartesian product, meaning it returns every possible combination of rows from both tables. This join doesn’t require an <code>ON</code> condition.</p>

          <p><strong>Syntax:</strong></p>
          <pre>
            <code>
              {`SELECT columns
FROM table1
CROSS JOIN table2;`}
            </code>
          </pre>

          <p><strong>Example:</strong></p>
          <pre>
            <code>
              {`SELECT e.employee_id, e.first_name, d.department_name
FROM employees e
CROSS JOIN departments d;`}
            </code>
          </pre>
          <p>- This query returns all combinations of employees and departments. If there are 10 employees and 5 departments, the result set will have 50 rows (10 × 5 = 50).</p>

       <br />

          <h3>Summary of Joins:</h3>
          <ul>
            <li><strong>INNER JOIN</strong>: Matches and returns only the rows with matching values in both tables.</li><br />
            <li><strong>LEFT OUTER JOIN</strong>: Returns all rows from the left table, and matched rows from the right table.</li><br />
            <li><strong>RIGHT OUTER JOIN</strong>: Returns all rows from the right table, and matched rows from the left table.</li><br />
            <li><strong>FULL OUTER JOIN</strong>: Returns all rows from both tables, matched and unmatched.</li><br />
            <li><strong>CROSS JOIN</strong>: Returns the Cartesian product of two tables, i.e., every possible combination of rows.</li>
          </ul><br />

          <p>These join types are fundamental for querying and combining data from multiple tables in Oracle databases.</p>
     

     
</div>
)}



{selectedChapter === 'chapter6' && (
  <div className={style.chaptercontent}>

<h1 className={style.heading}> Subqueries and Nested Queries</h1>


    <p>
      In Oracle SQL, <strong>subqueries</strong> (also known as nested queries) are queries embedded within another SQL query. They allow you to perform complex queries by breaking them down into smaller, manageable components. Subqueries can be used in various SQL clauses such as <code>SELECT</code>, <code>FROM</code>, <code>WHERE</code>, and <code>HAVING</code>. Here’s a detailed breakdown of subqueries and nested queries:
    </p><br />

    <h2>1. Subqueries</h2>
    <p style={{paddingBottom:"6px"}}>
      A subquery is a query that is nested inside another SQL query. The outer query is the main query, and the inner query is the subquery. Subqueries can return a single value, a list of values, or a complete table depending on how they are structured.
    </p>

    <h3 style={{paddingBottom:"6px"}}>Types of Subqueries:</h3>
    <ul>
      <li><strong>Single-row subquery</strong>: Returns a single row and can be used with comparison operators such as <code>=</code>, <code>&lt;</code>, <code>&gt;</code>, etc.</li><br />
      <li><strong>Multiple-row subquery</strong>: Returns multiple rows and is typically used with operators like <code>IN</code>, <code>ANY</code>, <code>ALL</code>.</li><br />
      <li><strong>Scalar subquery</strong>: Returns a single value (one column, one row).</li><br />
      <li><strong>Table subquery</strong>: Returns a set of rows and columns, which can be treated as a table.</li>
    </ul><br />

    <h3>Example of a Subquery:</h3>
    <pre>
      <code>
        {`SELECT employee_id, first_name, salary\nFROM employees\nWHERE salary > (SELECT AVG(salary) FROM employees);`}
      </code>
    </pre>
    <p>
      In this example, the subquery <code>(SELECT AVG(salary) FROM employees)</code> calculates the average salary of all employees. The outer query then retrieves employees whose salaries are greater than that average.
    </p><br />

    <h2>2. Nested Queries</h2>
    <p style={{paddingBottom:"6px"}}>
      Nested queries refer to the same concept as subqueries but emphasize the depth of nesting. A nested query can contain another subquery within it, which allows for more complex queries.
    </p>

    <h3>Example of a Nested Query:</h3>
    <pre>
      <code>
        {`SELECT department_name\nFROM departments\nWHERE department_id IN (\n    SELECT department_id\n    FROM employees\n    WHERE salary > (\n        SELECT AVG(salary)\n        FROM employees\n    )\n);`}
      </code>
    </pre>
    <p>
      In this example:
    </p>
    <ul>
      <li>The innermost query calculates the average salary of all employees.</li>
      <li>The middle query retrieves the <code>department_id</code>s of employees whose salaries are above the average.</li>
      <li>The outer query retrieves the <code>department_name</code>s of those departments.</li>
    </ul><br />

    <h2>3. Using Subqueries in Various Clauses</h2>
    <p style={{paddingBottom:"6px"}}>Subqueries can be used in different parts of a SQL statement:</p>
    <h3>In the SELECT clause:</h3>
    <pre>
      <code>
        {`SELECT employee_id,\n       (SELECT department_name FROM departments WHERE department_id = e.department_id) AS department_name\nFROM employees e;`}
      </code>
    </pre><br />

    <h3>In the FROM clause:</h3>
    <pre>
      <code>
        {`SELECT dept.department_name, emp.total_salary\nFROM (SELECT department_id, SUM(salary) AS total_salary FROM employees GROUP BY department_id) emp\nJOIN departments dept ON emp.department_id = dept.department_id;`}
      </code>
    </pre><br />

    <h2 style={{paddingBottom:"6px"}}>4. Advantages of Using Subqueries</h2>
    <ul>
      <li><strong>Modularity</strong>: Breaks complex queries into simpler parts, making them easier to read and maintain.</li><br />
      <li><strong>Reusability</strong>: Allows you to reuse the logic of a subquery in multiple places.</li><br />

      <li><strong>Flexibility</strong>: Enables dynamic filtering based on the results of another query.</li>
    </ul><br />

    <h2>5. Performance Considerations</h2>
    <p>
      While subqueries provide flexibility, they can also impact performance, especially if used inefficiently. In some cases, using joins or common table expressions (CTEs) may yield better performance than deeply nested subqueries.
    </p><br />

    <h2>Conclusion</h2>
    <p>
      Subqueries and nested queries are powerful tools in Oracle SQL, allowing for complex data retrieval and manipulation. Understanding how to effectively use them can enhance your ability to write efficient SQL queries and derive meaningful insights from your data.
    </p>


  </div>
)}





  {selectedChapter === 'chapter7' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Aggregate functions </h1>

    <p>
      In Oracle, <strong>aggregate functions</strong> are used to perform calculations on a set of rows and return a single value. Here are five of the most common aggregate functions:
    </p><br />

    <h2>1. <strong>COUNT</strong></h2>
    <p>
      The <code>COUNT</code> function returns the number of rows that match a specified condition. It can count all rows, including <code>NULL</code> values, or only non-null rows.
    </p>
    <p>
      <strong>Syntax:</strong>
      <br />
      <code>
        SELECT COUNT(column)<br />
        FROM table;
      </code>
    </p>
    <p>
      <strong>Example:</strong>
      <br />
      <code>
        SELECT COUNT(employee_id)<br />
        FROM employees;
      </code>
      <br />
      - This will return the total number of employees.
    </p>
    <p>
      To count all rows, including those with <code>NULL</code> values:
      <br />
      <code>
        SELECT COUNT(*)<br />
        FROM employees;
      </code>
    </p>

  <br />

    <h2>2. <strong>SUM</strong></h2>
    <p>
      The <code>SUM</code> function returns the total sum of a numeric column's values. It ignores <code>NULL</code> values.
    </p>
    <p>
      <strong>Syntax:</strong>
      <br />
      <code>
        SELECT SUM(column)<br />
        FROM table;
      </code>
    </p>
    <p>
      <strong>Example:</strong>
      <br />
      <code>
        SELECT SUM(salary)<br />
        FROM employees;
      </code>
      <br />
      - This will return the total sum of all employee salaries.
    </p>

   <br />

    <h2>3. <strong>AVG</strong></h2>
    <p>
      The <code>AVG</code> function calculates the average of a numeric column's values, ignoring <code>NULL</code> values.
    </p>
    <p>
      <strong>Syntax:</strong>
      <br />
      <code>
        SELECT AVG(column)<br />
        FROM table;
      </code>
    </p>
    <p>
      <strong>Example:</strong>
      <br />
      <code>
        SELECT AVG(salary)<br />
        FROM employees;
      </code>
      <br />
      - This will return the average salary of all employees.
    </p>

  <br />

    <h2>4. <strong>MIN</strong></h2>
    <p>
      The <code>MIN</code> function returns the smallest value in a column. It can be applied to both numeric and non-numeric data types, such as dates.
    </p>
    <p>
      <strong>Syntax:</strong>
      <br />
      <code>
        SELECT MIN(column)<br />
        FROM table;
      </code>
    </p>
    <p>
      <strong>Example:</strong>
      <br />
      <code>
        SELECT MIN(salary)<br />
        FROM employees;
      </code>
      <br />
      - This will return the minimum salary of all employees.
    </p>

   <br />

    <h2>5. <strong>MAX</strong></h2>
    <p>
      The <code>MAX</code> function returns the largest value in a column. Like <code>MIN</code>, it can be applied to both numeric and non-numeric data types.
    </p>
    <p>
      <strong>Syntax:</strong>
      <br />
      <code>
        SELECT MAX(column)<br />
        FROM table;
      </code>
    </p>
    <p>
      <strong>Example:</strong>
      <br />
      <code>
        SELECT MAX(salary)<br />
        FROM employees;
      </code>
      <br />
      - This will return the highest salary among all employees.
    </p>

   <br />

    <h2>Summary of Aggregate Functions:</h2>
    <ul>
      <li><strong>COUNT</strong>: Counts rows or non-null values in a column.</li><br />
      <li><strong>SUM</strong>: Returns the total sum of a column's numeric values.</li><br />
      <li><strong>AVG</strong>: Calculates the average value of a numeric column.</li><br />
      <li><strong>MIN</strong>: Finds the smallest value in a column.</li><br />
      <li><strong>MAX</strong>: Finds the largest value in a column.</li>
    </ul>
    
    <p>
      These functions are widely used in SQL queries to summarize and analyze data across multiple rows.
    </p>


  </div>
)}


 
 
    {selectedChapter === 'chapter8' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Group BY & HAVING clauses </h1>

    <p>
      In Oracle SQL, the <code>GROUP BY</code> and <code>HAVING</code> clauses are used in conjunction with aggregate functions to group rows that have the same values in specified columns into summary rows. Here’s a breakdown of each:
    </p><br />

    <h2>GROUP BY Clause</h2>
    <p style={{paddingBottom:"6px"}}>
      <strong>Purpose:</strong> The <code>GROUP BY</code> clause is used to arrange identical data into groups. This is often used with aggregate functions like <code>SUM()</code>, <code>COUNT()</code>, <code>AVG()</code>, etc., to perform operations on each group of data.
    </p>
    <h3>Syntax:</h3>
    <pre>
      <code>
        {`SELECT column1, aggregate_function(column2)`}
        <br />
        {`FROM table_name`}
        <br />
        {`WHERE condition`}
        <br />
        {`GROUP BY column1;`}
      </code>
    </pre><br />
    <h3>Example:</h3>
    <pre>
      <code>
        {`SELECT department_id, COUNT(employee_id) AS employee_count`}
      
        {`FROM employees`}
      
        {`GROUP BY department_id;`}
      </code>
    </pre>
    <p>
      This query counts the number of employees in each department.
    </p><br />

    <h2>HAVING Clause</h2>
    <p GROUP BY and HAVING Clauses>
      <strong>Purpose:</strong> The <code>HAVING</code> clause is used to filter records that work on summarized group data. Unlike the <code>WHERE</code> clause, which filters rows before grouping, <code>HAVING</code> filters groups after aggregation.
    </p>
    <h3>Syntax:</h3>
    <pre>
      <code>
        {`SELECT column1, aggregate_function(column2)`}
      
        {`FROM table_name`}
      
        {`GROUP BY column1`}
       
        {`HAVING condition;`}
        </code>
    </pre><br />
    <h3>Example:</h3>
    <pre>
      <code>
        {`SELECT department_id, COUNT(employee_id) AS employee_count`}
   
        {`FROM employees`}
       
        {`GROUP BY department_id`}
       
        {`HAVING COUNT(employee_id) > 10;`}
      </code>
    </pre>
    <p>
      This query counts the number of employees in each department but only returns departments that have more than 10 employees.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Summary</h2>
    <ul>
      <li>Use <strong>GROUP BY</strong> to aggregate data based on specific columns.</li><br />
      <li>Use <strong>HAVING</strong> to filter those aggregated results based on conditions applied to the aggregated values.</li>
    </ul><br />
    
    <p>
      Together, these clauses allow for powerful data analysis and reporting in Oracle SQL.
    </p>
  </div>
)}







{selectedChapter === 'chapter9' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Set Operations: UNION, INTRSECT, and EXCEPT</h1>


    <p>
      In <strong>Oracle SQL</strong>, set operations are used to combine the results of multiple <code>SELECT</code> queries. These operations allow you to perform actions like combining, intersecting, or excluding data from multiple result sets. Oracle supports three main set operations: <code>UNION</code>, <code>INTERSECT</code>, and <code>MINUS</code> (instead of <code>EXCEPT</code> used in other databases). Each operation has specific behaviors and rules.
    </p><br />

    <h2>1. <strong>UNION</strong> (Oracle)</h2>
    <p style={{paddingBottom:"6px"}}><strong>Definition</strong>: Combines the result sets of two or more <code>SELECT</code> queries, returning only distinct rows. Duplicates are automatically removed.</p>
    
    <h3>Syntax:</h3>
    <pre>
      <code>
        SELECT column_list FROM table1<br/>
        UNION<br/>
        SELECT column_list FROM table2;
      </code>
    </pre><br />

    <h3>Example:</h3>
    <pre>
      <code>
        SELECT department_id FROM employees<br/>
        UNION<br/>
        SELECT department_id FROM departments;
      </code>
    </pre>
    <p>This query combines the department IDs from both <code>employees</code> and <code>departments</code> tables and returns only unique values.</p><br />

    <h3 style={{paddingBottom:"6px"}}>Key points:</h3>
    <ul>
      <li>Use <code>UNION ALL</code> if you want to retain duplicate rows in the combined result set.</li><br />
      <li>The number of columns and their data types must be identical in both queries.</li><br />
      <li>Oracle processes <code>UNION</code> by removing duplicates, which can have a performance cost compared to <code>UNION ALL</code>.</li>
    </ul><br />

    <h2>2. <strong>INTERSECT</strong> (Oracle)</h2>
    <p style={{paddingBottom:"6px"}}><strong>Definition</strong>: Returns only the rows that are common between the result sets of two or more <code>SELECT</code> queries.</p>

    <h3>Syntax:</h3>
    <pre>
      <code>
        SELECT column_list FROM table1<br/>
        INTERSECT<br/>
        SELECT column_list FROM table2;
      </code>
    </pre><br />

    <h3>Example:</h3>
    <pre>
      <code>
        SELECT employee_id FROM employees<br/>
        INTERSECT<br/>
        SELECT manager_id FROM departments;
      </code>
    </pre>
    <p>This query returns only the <code>employee_id</code> values that exist in both the <code>employees</code> and <code>departments</code> tables (those employees who are also managers).</p><br />

    <h3 style={{paddingBottom:"6px"}}>Key points:</h3>
    <ul>
      <li>The <code>INTERSECT</code> operator only returns rows that are present in both SELECT queries.</li><br />
      <li>Like <code>UNION</code>, the number of columns and data types must match between the two queries.</li><br />
      <li>Duplicates are automatically removed from the result.</li>
    </ul><br />

    <h2>3. <strong>MINUS</strong> (Oracle equivalent to EXCEPT)</h2>
    <p style={{paddingBottom:"6px"}}><strong>Definition</strong>: Returns the rows that are in the result set of the first <code>SELECT</code> query but not in the second. This is Oracle’s equivalent to the <code>EXCEPT</code> operator used in other databases.</p>

    <h3>Syntax:</h3>
    <pre>
      <code>
        SELECT column_list FROM table1<br/>
        MINUS<br/>
        SELECT column_list FROM table2;
      </code>
    </pre><br />

    <h3>Example:</h3>
    <pre>
      <code>
        SELECT employee_id FROM employees<br/>
        MINUS<br/>
        SELECT employee_id FROM managers;
      </code>
    </pre>
    <p>This query returns the <code>employee_id</code> values that are in the <code>employees</code> table but not in the <code>managers</code> table.</p><br />

    <h3 style={{paddingBottom:"6px"}}>Key points:</h3>
    <ul>
      <li>The <code>MINUS</code> operator returns only rows from the first query that do not appear in the second query.</li><br />
      <li>Like the other set operations, the columns must be the same in both queries.</li><br />
      <li>The result contains only unique rows, and duplicates are removed.</li>
    </ul><br />

    <h2>Summary of Oracle Set Operations:</h2>
    <table>
      <thead>
        <tr>
          <th>Operation</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><strong>UNION</strong></td>
          <td>Combines two result sets and returns all distinct rows.</td>
        </tr>
        <tr>
          <td><strong>UNION ALL</strong></td>
          <td>Combines two result sets and includes duplicates.</td>
        </tr>
        <tr>
          <td><strong>INTERSECT</strong></td>
          <td>Returns only the rows common to both result sets.</td>
        </tr>
        <tr>
          <td><strong>MINUS</strong></td>
          <td>Returns rows from the first result set not found in the second.</td>
        </tr>
      </tbody>
    </table><br />

    <h3 style={{paddingBottom:"6px"}}>Rules for Set Operations in Oracle:</h3>
    <ul>
      <li><strong>Column Data Types and Number</strong>: All participating <code>SELECT</code> statements must return the same number of columns with compatible data types.</li><br />
      <li><strong>Order of Execution</strong>: Operations like <code>UNION</code>, <code>INTERSECT</code>, and <code>MINUS</code> are evaluated left to right unless parentheses change the order.</li><br />
      <li><strong>Sort Order</strong>: The result set of set operations is sorted by default. You can override this with the <code>ORDER BY</code> clause, but it can only be applied to the final result.</li>
    </ul><br />



  </div>
)}





{selectedChapter === 'chapter10' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Intoduction of PL/SQL </h1>

    <div>
      <p>
        <strong>PL/SQL (Procedural Language/SQL)</strong> is Oracle Corporation’s procedural extension to SQL, which is Oracle's native language for querying and managing data in databases. It combines the data manipulation capabilities of SQL with the procedural constructs of programming languages like loops, conditions, and variables, allowing developers to write highly structured and efficient database applications.
      </p><br />

      <h3 style={{paddingBottom:"6px"}}>Key Features of PL/SQL:</h3>

      <ol>
        <li>
          <strong>Block-Structured Language:</strong> PL/SQL is organized into blocks, each containing a declarative part (for variable declarations), an executable part (where SQL and PL/SQL statements are written), and an optional exception-handling part (for managing runtime errors).
          <pre>
            <code>{`
DECLARE
  -- Declarative part
  variable_name datatype;
BEGIN
  -- Executable part
  SQL statements;
  PL/SQL statements;
EXCEPTION
  -- Exception handling part
  WHEN others THEN
    handle_error;
END;
            `}</code>
          </pre>
        </li><br />

        <li>
          <strong>Tight Integration with SQL:</strong> PL/SQL works closely with SQL, allowing you to use SQL statements (e.g., SELECT, INSERT, UPDATE, DELETE) directly within PL/SQL blocks. This enables easy manipulation and retrieval of data within procedural code.
        </li><br />

        <li>
          <strong>Support for Procedural Constructs:</strong> PL/SQL supports procedural features like:
          <ul>
            <li><strong>Variables and Constants:</strong> Allows storage and manipulation of data using variables.</li><br />
            <li><strong>Control Structures:</strong> Includes constructs like IF-THEN-ELSE, loops (FOR, WHILE), and CASE statements for flow control.</li><br />
            <li><strong>Functions and Procedures:</strong> Developers can write reusable code as stored procedures or functions, which are stored in the database for efficient execution.</li>
          </ul>
        </li><br />

        <li>
          <strong>Exception Handling:</strong> PL/SQL has a robust mechanism for handling errors using the EXCEPTION block. This allows for graceful error handling and ensures that runtime errors don’t crash the entire application.
          <pre>
            <code>{`
EXCEPTION
  WHEN NO_DATA_FOUND THEN
    DBMS_OUTPUT.PUT_LINE('No data found!');
  WHEN OTHERS THEN
    DBMS_OUTPUT.PUT_LINE('Some other error occurred');
END;
            `}</code>
          </pre>
        </li><br />

        <li>
          <strong>Support for Cursors:</strong> PL/SQL uses cursors to handle query results. A cursor is a pointer to the context area where SQL statements are executed. There are two types of cursors:
          <ul>
            <li><strong>Implicit Cursor:</strong> Automatically created by Oracle for single-row queries.</li><br />
            <li><strong>Explicit Cursor:</strong> Created explicitly by the developer to handle multi-row queries.</li>
          </ul>
          <pre>
            <code>{`
DECLARE
  CURSOR employee_cur IS
    SELECT employee_id, first_name FROM employees;
BEGIN
  FOR rec IN employee_cur LOOP
    DBMS_OUTPUT.PUT_LINE(rec.employee_id || ': ' || rec.first_name);
  END LOOP;
END;
            `}</code>
          </pre>
        </li><br />

        <li>
          <strong>Triggers:</strong> PL/SQL supports triggers, which are special types of stored procedures that automatically execute when a specific event occurs (e.g., before or after an insert, update, or delete operation).
        </li><br />

        <li>
          <strong>Portability:</strong> PL/SQL code is portable across any Oracle database environment, ensuring that business logic can be consistently deployed across systems.
        </li>
      </ol><br />

      <h3 style={{paddingBottom:"6px"}}>Benefits of Using PL/SQL:</h3>

      <ul>
        <li><strong>Performance:</strong> PL/SQL allows for bulk processing, minimizing context switching between SQL and procedural logic, improving performance.</li><br />
        <li><strong>Reusability:</strong> Functions, procedures, and packages written in PL/SQL can be reused across different applications, promoting modular development.</li><br />
        <li><strong>Error Handling:</strong> The built-in exception-handling mechanism helps gracefully recover from errors without affecting the overall application logic.</li><br />
        <li><strong>Security:</strong> PL/SQL centralizes logic and security controls, ensuring sensitive operations are not exposed outside the database.</li><br />
        <li><strong>Scalability:</strong> PL/SQL allows creating highly scalable applications by reducing network traffic and handling business logic inside the database.</li>
      </ul><br />

      <h3>PL/SQL Block Structure:</h3>

      <p>A typical PL/SQL block consists of three parts:</p>
      <ol>
        <li><strong>Declarative Section:</strong> This section is used to declare variables, constants, and other data types.</li><br />
        <li><strong>Executable Section:</strong> The main section where the logic (SQL statements and procedural code) is written.</li><br />
        <li><strong>Exception Section:</strong> Handles errors that occur during execution.</li>
      </ol>

      <pre>
        <code>{`
DECLARE
  v_emp_name employees.first_name%TYPE;
BEGIN
  SELECT first_name INTO v_emp_name
  FROM employees
  WHERE employee_id = 100;

  DBMS_OUTPUT.PUT_LINE('Employee Name: ' || v_emp_name);
EXCEPTION
  WHEN NO_DATA_FOUND THEN
    DBMS_OUTPUT.PUT_LINE('No employee found with that ID.');
  WHEN OTHERS THEN
    DBMS_OUTPUT.PUT_LINE('An error occurred.');
END;
        `}</code>
      </pre><br />

      <h3>Conclusion:</h3>

      <p>
        PL/SQL is an essential tool for Oracle developers, extending SQL with procedural logic. It allows for advanced data manipulation, automation, and performance optimization. PL/SQL is widely used for creating stored procedures, functions, triggers, and packages, enabling robust, scalable, and secure database applications.
      </p>

      <p>Let me know if you need further clarification or examples!</p>
    </div>




  </div>
)}



{selectedChapter === 'chapter11' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> PL/SQL syntax & structure</h1>

    <p>
      PL/SQL (Procedural Language/SQL) is Oracle's procedural extension for SQL. It allows developers to write complex scripts and manage the execution of SQL statements within a structured programming framework. Here’s a breakdown of PL/SQL syntax and structure:
    </p><br />

    <h2>1. PL/SQL Block Structure</h2>
    <p>
      PL/SQL code is organized into blocks, which can be anonymous or named (like procedures and functions). Each block has three main sections:
    </p>
    <ul>
      <li><strong>Declarative Section</strong>: This is where you declare variables, constants, cursors, and exceptions. It is optional.</li><br />
      <li><strong>Executable Section</strong>: This section contains the SQL statements and PL/SQL instructions that perform actions. It is mandatory.</li><br />
      <li><strong>Exception Handling Section</strong>: This section is used to handle errors that may occur during the execution of the block. It is also optional.</li>
    </ul>

    <h3>Syntax</h3>
    <pre>
      <code>
{`DECLARE
  -- Declarative section
  variable_name datatype [NOT NULL] := initial_value;  -- Declaration of variables
BEGIN
  -- Executable section
  SQL statements;  -- Executable statements
  -- Other PL/SQL logic
EXCEPTION
  -- Exception handling section
  WHEN exception_name THEN
    -- Handling code
END;`}
      </code>
    </pre><br />

    <h2 style={{paddingBottom:"6px"}}>2. Key Components</h2>

    <h3>A. Declarative Section</h3>
    <p>
      Variables are declared using the <code>variable_name datatype</code> syntax. You can also initialize them at the time of declaration.
    </p>
    <pre>
      <code>
{`DECLARE
  v_employee_name VARCHAR2(100);  -- Declaration of a variable
  v_salary NUMBER := 5000;  -- Declaration and initialization`}
      </code>
    </pre><br />

    <h3>B. Executable Section</h3>
    <p>You can execute any SQL statement (SELECT, INSERT, UPDATE, DELETE) within this section.</p>
    <pre>
      <code>
{`BEGIN
  SELECT employee_name INTO v_employee_name FROM employees WHERE employee_id = 1;
  DBMS_OUTPUT.PUT_LINE('Employee Name: ' || v_employee_name);
END;`}
      </code>
    </pre><br />

    <h3>C. Exception Handling Section</h3>
    <p>You can define how to respond to various exceptions using the <code>WHEN</code> clause.</p>
    <pre>
      <code>
{`EXCEPTION
  WHEN NO_DATA_FOUND THEN
    DBMS_OUTPUT.PUT_LINE('No employee found with the specified ID.');
  WHEN OTHERS THEN
    DBMS_OUTPUT.PUT_LINE('An error occurred: ' || SQLERRM);`}
      </code>
    </pre><br />

    <h2>3. PL/SQL Control Structures</h2>
    <p>PL/SQL supports various control structures for managing flow:</p>

    <h3>A. Conditional Statements</h3>
    <h4>IF Statement</h4>
    <pre>
      <code>
{`IF condition THEN
    -- Statements
ELSIF another_condition THEN
    -- Statements
ELSE
    -- Statements
END IF;`}
      </code>
    </pre><br />

    <h4>CASE Statement</h4>
    <pre>
      <code>
{`CASE expression
    WHEN value1 THEN
        -- Statements
    WHEN value2 THEN
        -- Statements
    ELSE
        -- Statements
END CASE;`}
      </code>
    </pre><br />

    <h3>B. Loops</h3>
    <h4>Basic LOOP</h4>
    <pre>
      <code>
{`LOOP
    -- Statements
    EXIT WHEN condition;
END LOOP;`}
      </code>
    </pre><br />

    <h4>WHILE LOOP</h4>
    <pre>
      <code>
{`WHILE condition LOOP
    -- Statements
END LOOP;`}
      </code>
    </pre><br />

    <h4>FOR LOOP</h4>
    <pre>
      <code>
{`FOR counter IN start_value..end_value LOOP
    -- Statements
END LOOP;`}
      </code>
    </pre><br />

    <h2>4. Named Blocks</h2>
    <p>Named blocks can be procedures, functions, or packages.</p>

    <h4>Procedure</h4>
    <pre>
      <code>
{`CREATE OR REPLACE PROCEDURE procedure_name AS
BEGIN
    -- Statements
END procedure_name;`}
      </code>
    </pre><br />

    <h4>Function</h4>
    <pre>
      <code>
{`CREATE OR REPLACE FUNCTION function_name RETURN datatype AS
BEGIN
    -- Statements
    RETURN value;
END function_name;`}
      </code>
    </pre><br />

    <h2>5. Cursors</h2>
    <p>Cursors allow you to fetch multiple rows from a query.</p>
    <ul>
      <li><strong>Implicit Cursor</strong>: Automatically created by Oracle for SQL statements that return a single row.</li><br />
      <li><strong>Explicit Cursor</strong>: Defined by the programmer for queries that return multiple rows.</li>
    </ul>
    
    <h3>Example</h3>
    <pre>
      <code>
{`DECLARE
  CURSOR emp_cursor IS
    SELECT employee_name FROM employees;
  v_employee_name employees.employee_name%TYPE;
BEGIN
  OPEN emp_cursor;
  LOOP
    FETCH emp_cursor INTO v_employee_name;
    EXIT WHEN emp_cursor%NOTFOUND;
    DBMS_OUTPUT.PUT_LINE('Employee Name: ' || v_employee_name);
  END LOOP;
  CLOSE emp_cursor;
END;`}
      </code>
    </pre><br />

    <h2>Conclusion</h2>
    <p>
      PL/SQL's syntax and structure provide a powerful and flexible way to combine SQL with procedural programming, enabling developers to build complex applications within the Oracle database environment. Understanding the block structure, control structures, exception handling, and cursors is essential for effectively using PL/SQL. If you have any specific areas you'd like to delve deeper into, let me know!
    </p>
 


    </div>
)}





{selectedChapter === 'chapter12' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Variables and Data Types</h1>
    <p>In Oracle, <strong>variables</strong> are used to store data that can be manipulated and used within PL/SQL blocks, procedures, functions, or anonymous blocks. Each variable in Oracle has a <strong>data type</strong> that defines the type of data it can hold. These data types can be numbers, characters, dates, and more complex types like records and collections.</p><br />

    <h4>1. Declaring Variables</h4>
    <p style={{paddingBottom:"6px"}}>In PL/SQL, variables are declared in the <strong>declarative section</strong> of a block. You must specify the variable's name and its data type, and optionally, an initial value.</p>
    <p><strong>Syntax:</strong></p>
    <pre>
      <code>
        variable_name datatype [NOT NULL] [:= initial_value];
      </code>
    </pre>
    <p><strong>Example:</strong></p>
    <pre>
      <code>
        DECLARE
        &nbsp;&nbsp;employee_name VARCHAR2(50);   -- Variable to hold an employee's name<br />
        &nbsp;&nbsp;salary NUMBER(10, 2) := 5000; -- Variable with an initial value of 5000<br />
        &nbsp;&nbsp;hire_date DATE := SYSDATE;    -- Variable initialized to current date<br />
        BEGIN
        &nbsp;&nbsp;-- Executable statements<br />
        END;
      </code>
    </pre><br />

    <h4>2. PL/SQL Data Types</h4>
    <p>Oracle provides various <strong>data types</strong> to define the kind of data that a variable can store.</p>

    <h5>2.1 Character Data Types</h5>
    <p>Used for storing alphanumeric characters.</p>
    <ul>
      <li><strong>VARCHAR2(size)</strong>: Variable-length character string, up to 32,767 bytes. The size specifies the maximum length.</li><br />
      <pre>
        <code>employee_name VARCHAR2(50); -- String up to 50 characters</code>
      </pre><br />
      <li><strong>CHAR(size)</strong>: Fixed-length character string, where the size defines the number of characters.</li>
      <pre>
        <code>department_code CHAR(10); -- String that always has 10 characters</code>
      </pre><br />
      <li><strong>CLOB</strong>: Used to store large blocks of character data (Character Large Object).</li>
      <pre>
        <code>article_content CLOB; -- Used for storing large text data</code>
      </pre>
    </ul><br />

    <h5>2.2 Number Data Types</h5>
    <ul>
      <li><strong>NUMBER(p, s)</strong>: Stores fixed and floating-point numbers.</li>
      <pre>
        <code>salary NUMBER(10, 2); -- Number with up to 10 digits, 2 after decimal</code>
      </pre><br />
      <li><strong>INTEGER</strong>: Equivalent to <code>NUMBER(38)</code> and used for storing whole numbers.</li>
      <pre>
        <code>total_employees INTEGER; -- Whole number without decimals</code>
      </pre><br />
      <li><strong>BINARY_FLOAT</strong> and <strong>BINARY_DOUBLE</strong>: These are used to store floating-point numbers.</li>
      <pre>
        <code>pi_value BINARY_DOUBLE := 3.14159; -- High-speed floating-point number</code>
      </pre>
    </ul>
<br />
    <h5>2.3 Date and Time Data Types</h5>
    <ul>
      <li><strong>DATE</strong>: Stores date and time values to the nearest second.</li>
      <pre>
        <code>hire_date DATE := SYSDATE; -- Current system date and time</code>
      </pre><br />
      <li><strong>TIMESTAMP</strong>: Stores date and time with fractional seconds.</li>
      <pre>
        <code>timestamp_example TIMESTAMP := SYSTIMESTAMP;</code>
      </pre><br />
      <li><strong>INTERVAL</strong>: Used for storing a period of time (such as months or days).</li>
      <pre>
        <code>duration INTERVAL YEAR TO MONTH := INTERVAL '1-3' YEAR TO MONTH;</code>
      </pre>
    </ul><br />

    <h5>2.4 Boolean Data Type</h5>
    <ul>
      <li><strong>BOOLEAN</strong>: Can hold a value of <code>TRUE</code>, <code>FALSE</code>, or <code>NULL</code>.</li>
      <pre>
        <code>is_valid BOOLEAN := TRUE;</code>
      </pre>
    </ul><br />

    <h5>2.5 LOB (Large Object) Data Types</h5>
    <ul>
      <li><strong>BLOB</strong>: Binary Large Object, used for storing large amounts of binary data like images.</li>
      <pre>
        <code>image_data BLOB; -- Used to store binary data such as images</code>
      </pre><br />
      <li><strong>CLOB</strong>: Character Large Object, for large text data.</li>
      <pre>
        <code>document_content CLOB; -- Used to store large text data</code>
      </pre>
    </ul><br />

    <h4>3. Anchored Data Types</h4>
    <p>PL/SQL allows the use of <strong>anchored data types</strong> to base the data type of a variable on the data type of a column or another variable.</p>
    <ul>
      <li><strong>%TYPE</strong>: Anchors the variable to the data type of a column or another variable.</li>
      <pre>
        <code>employee_name employees.first_name%TYPE;</code>
      </pre><br />
      <li><strong>%ROWTYPE</strong>: Anchors the variable to the row type of a table or cursor, making it possible to store an entire row of data.</li>
      <pre>
        <code>employee_record employees%ROWTYPE;</code>
      </pre>
    </ul><br />

    <h4>4. Assigning Values to Variables</h4>
    <p>Values can be assigned to variables using the <code>:=</code> assignment operator or through SQL <code>SELECT INTO</code> statements.</p>

    <h5>Example of assignment:</h5>
    <pre>
      <code>salary := 6000;<br />hire_date := '01-JAN-2024';</code>
    </pre>

    <h5>Example of using <code>SELECT INTO</code>:</h5>
    <pre>
      <code>
        DECLARE<br />
        &nbsp;&nbsp;employee_name employees.first_name%TYPE;<br />
        BEGIN<br />
        &nbsp;&nbsp;SELECT first_name INTO employee_name<br />
        &nbsp;&nbsp;FROM employees<br />
        &nbsp;&nbsp;WHERE employee_id = 100;<br />
        END;
      </code>
    </pre><br />

    <h4>5. Variable Scope</h4>
    <p>Variables declared in PL/SQL have <strong>local scope</strong>, meaning they are only accessible within the block in which they are declared. If declared in a procedure or function, they are local to that procedure or function.</p>

    <h5>Example of a PL/SQL block using variables:</h5>
    <pre>
      <code>
        DECLARE
        &nbsp;&nbsp;v_employee_id employees.employee_id%TYPE;<br />
        &nbsp;&nbsp;v_employee_name employees.first_name%TYPE;<br />
        &nbsp;&nbsp;v_salary NUMBER(7, 2);<br />
        &nbsp;&nbsp;v_hire_date DATE;<br />
        BEGIN
        &nbsp;&nbsp;v_employee_id := 101;<br />
        &nbsp;&nbsp;v_salary := 7500;<br />
        &nbsp;&nbsp;SELECT first_name, hire_date INTO v_employee_name, v_hire_date<br />
        &nbsp;&nbsp;FROM employees<br />
        &nbsp;&nbsp;WHERE employee_id = v_employee_id;<br />
        &nbsp;&nbsp;DBMS_OUTPUT.PUT_LINE('Employee: ' || v_employee_name || ' was hired on ' || v_hire_date || ' with a salary of ' || v_salary);<br />
        END;
      </code>
    </pre><br />

    <h4>Conclusion</h4>
    <p>Variables in Oracle are crucial for holding temporary values used within PL/SQL programs. Oracle offers a rich set of data types to handle various kinds of data such as numbers, characters, dates, and large objects. Using anchored data types ensures flexibility and consistency with database columns. Understanding these concepts is essential for writing efficient and maintainable PL/SQL code.</p>

       
  </div>
)}



{selectedChapter === 'chapter13' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>PL/SQL Control Structures in Oracle</h1>
    <p>
      Control structures are essential in PL/SQL to manage the flow of execution. PL/SQL provides various control structures like conditional statements (<code>IF</code>, <code>CASE</code>) and iterative loops (<code>LOOP</code>, <code>WHILE</code>, <code>FOR</code>) to allow branching and repeated execution of code.
    </p>

   <br />

    <h3>1. IF Statement</h3>
    <p>
      The <code>IF</code> statement is used for conditional branching, where code is executed based on whether a condition is <strong>TRUE</strong> or <strong>FALSE</strong>.
    </p>
    <strong>Syntax</strong>:
    <pre><code>{`IF condition THEN
  -- Code to execute when condition is true
ELSIF another_condition THEN
  -- Code to execute when another_condition is true
ELSE
  -- Code to execute if none of the conditions are true
END IF;`}</code></pre>

    <strong>Example</strong>:
    <pre><code>{`DECLARE
  salary NUMBER := 5000;
BEGIN
  IF salary > 10000 THEN
    DBMS_OUTPUT.PUT_LINE('High salary');
  ELSIF salary BETWEEN 5000 AND 10000 THEN
    DBMS_OUTPUT.PUT_LINE('Medium salary');
  ELSE
    DBMS_OUTPUT.PUT_LINE('Low salary');
  END IF;
END;`}</code></pre>

    <p>
      In this example, the output will be <code>"Medium salary"</code> since the value of <code>salary</code> falls between 5000 and 10000.
    </p>

   <br />
    <h3>2. CASE Statement</h3>
    <p>
      The <code>CASE</code> statement provides an alternative to multiple <code>IF</code> statements and is used to evaluate a condition or expression and execute the corresponding block of code.
    </p>

    <h4>2.1 Simple CASE Statement</h4>
    <strong>Syntax</strong>:
    <pre><code>{`CASE expression
  WHEN value1 THEN
    -- Code to execute when expression matches value1
  WHEN value2 THEN
    -- Code to execute when expression matches value2
  ELSE
    -- Code to execute when no values match
END CASE;`}</code></pre>

    <strong>Example</strong>:
    <pre><code>{`DECLARE
  grade CHAR := 'B';
BEGIN
  CASE grade
    WHEN 'A' THEN
      DBMS_OUTPUT.PUT_LINE('Excellent');
    WHEN 'B' THEN
      DBMS_OUTPUT.PUT_LINE('Good');
    WHEN 'C' THEN
      DBMS_OUTPUT.PUT_LINE('Average');
    ELSE
      DBMS_OUTPUT.PUT_LINE('Poor');
  END CASE;
END;`}</code></pre>

    <p>
      In this case, the output will be <code>"Good"</code> since the value of <code>grade</code> is <code>'B'</code>.
    </p><br />

    <h4>2.2 Searched CASE Statement</h4>
    <strong>Syntax</strong>:
    <pre><code>{`CASE
  WHEN condition1 THEN
    -- Code to execute when condition1 is true
  WHEN condition2 THEN
    -- Code to execute when condition2 is true
  ELSE
    -- Code to execute if none of the conditions are true
END CASE;`}</code></pre>

    <strong>Example</strong>:
    <pre><code>{`DECLARE
  salary NUMBER := 8500;
BEGIN
  CASE
    WHEN salary > 10000 THEN
      DBMS_OUTPUT.PUT_LINE('High salary');
    WHEN salary BETWEEN 5000 AND 10000 THEN
      DBMS_OUTPUT.PUT_LINE('Medium salary');
    ELSE
      DBMS_OUTPUT.PUT_LINE('Low salary');
  END CASE;
END;`}</code></pre>

  <br />

    <h3>3. Loops in PL/SQL</h3>
    <p>
      PL/SQL provides several loop structures for repeated execution of code.
    </p>

    <h4>3.1 Basic LOOP</h4>
    <strong>Syntax</strong>:
    <pre><code>{`LOOP
  -- Code to execute repeatedly
  EXIT WHEN condition;
END LOOP;`}</code></pre>

    <strong>Example</strong>:
    <pre><code>{`DECLARE
  counter NUMBER := 1;
BEGIN
  LOOP
    DBMS_OUTPUT.PUT_LINE('Counter: ' || counter);
    counter := counter + 1;
    EXIT WHEN counter > 5;
  END LOOP;
END;`}</code></pre>

    <p>
      This loop will print the counter from 1 to 5.
    </p>

 <br />

    <h4>3.2 WHILE LOOP</h4>
    <strong>Syntax</strong>:
    <pre><code>{`WHILE condition LOOP
  -- Code to execute while condition is true
END LOOP;`}</code></pre>

    <strong>Example</strong>:
    <pre><code>{`DECLARE
  counter NUMBER := 1;
BEGIN
  WHILE counter <= 5 LOOP
    DBMS_OUTPUT.PUT_LINE('Counter: ' || counter);
    counter := counter + 1;
  END LOOP;
END;`}</code></pre>

    <p>
      The <code>WHILE</code> loop will continue to print the counter until it exceeds 5.
    </p>

 <br />

    <h4>3.3 FOR LOOP</h4>
    <strong>Syntax</strong>:
    <pre><code>{`FOR variable IN start_value..end_value LOOP
  -- Code to execute
END LOOP;`}</code></pre>

    <strong>Example</strong>:
    <pre><code>{`BEGIN
  FOR counter IN 1..5 LOOP
    DBMS_OUTPUT.PUT_LINE('Counter: ' || counter);
  END LOOP;
END;`}</code></pre>

    <p>
      This <code>FOR</code> loop will print the counter values from 1 to 5.
    </p>

<br />

    <h3>4. Nested Loops</h3>
    <p>
      PL/SQL allows loops to be nested within one another for more complex iteration.
    </p>
    <strong>Example</strong>:
    <pre><code>{`BEGIN
  FOR i IN 1..3 LOOP
    DBMS_OUTPUT.PUT_LINE('Outer loop iteration: ' || i);
    FOR j IN 1..2 LOOP
      DBMS_OUTPUT.PUT_LINE('  Inner loop iteration: ' || j);
    END LOOP;
  END LOOP;
END;`}</code></pre>

    <p>
      In this example, the outer loop will iterate 3 times, and for each outer loop iteration, the inner loop will iterate 2 times.
    </p>

<br />

    <h2>Conclusion</h2>
    <p>
      PL/SQL control structures, including conditional statements (<code>IF</code>, <code>CASE</code>) and iterative loops (<code>LOOP</code>, <code>WHILE</code>, <code>FOR</code>), are powerful tools for managing the flow of execution within your programs. Understanding these structures allows for efficient handling of logical conditions and repetitive tasks in PL/SQL blocks.
    </p>
  </div>
)}




{selectedChapter === 'chapter14' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Procedures and Functions</h1>
    <p>
      Here’s a detailed overview of PL/SQL procedures and functions in Oracle, including their syntax, structure, and examples. 
      PL/SQL procedures and functions are essential components that enable developers to encapsulate code for reuse and better organization within Oracle databases. 
      Both allow you to execute a set of SQL statements, but they serve different purposes and have distinct characteristics.
    </p><br />

    <h2>1. Procedures</h2>
    <p style={{paddingBottom:"6px"}}>
      A <strong>procedure</strong> is a named PL/SQL block that performs a specific action. 
      It can take parameters and may not return a value.
    </p>

    <h3>Syntax</h3>
    <pre>
      <code>
{`CREATE OR REPLACE PROCEDURE procedure_name
  [ (parameter1 [IN | OUT | IN OUT] datatype, ... ) ]
AS
BEGIN
  -- Executable statements
END procedure_name;`}
      </code>
    </pre><br />

    <h3>Example</h3>
    <pre>
      <code>
{`CREATE OR REPLACE PROCEDURE raise_salary (
  p_employee_id IN NUMBER,
  p_percentage IN NUMBER
) AS
BEGIN
  UPDATE employees
  SET salary = salary + (salary * p_percentage / 100)
  WHERE employee_id = p_employee_id;
  COMMIT;
END raise_salary;`}
      </code>
    </pre>
    <p>
      <strong>Explanation:</strong> This procedure, <code>raise_salary</code>, takes an employee ID and a percentage increase as parameters. 
      It updates the salary of the specified employee.
    </p><br />

    <h2>2. Functions</h2>
    <p style={{paddingBottom:"6px"}}>
      A <strong>function</strong> is similar to a procedure but is designed to return a value. 
      It can also accept parameters.
    </p>

    <h3>Syntax</h3>
    <pre>
      <code>
{`CREATE OR REPLACE FUNCTION function_name
  RETURN datatype
  [ (parameter1 [IN | OUT | IN OUT] datatype, ... ) ]
AS
BEGIN
  -- Executable statements
  RETURN value;
END function_name;`}
      </code>
    </pre><br />

    <h3>Example</h3>
    <pre>
      <code>
{`CREATE OR REPLACE FUNCTION calculate_bonus (
  p_salary IN NUMBER
) RETURN NUMBER AS
  v_bonus NUMBER;
BEGIN
  v_bonus := p_salary * 0.10; -- Calculate 10% of salary
  RETURN v_bonus;
END calculate_bonus;`}
      </code>
    </pre>
    <p>
      <strong>Explanation:</strong> This function, <code>calculate_bonus</code>, takes a salary as a parameter and returns 10% of that salary as the bonus.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>3. Calling Procedures and Functions</h2>
    <h3>Calling a Procedure</h3>
    <pre>
      <code>
{`BEGIN
  raise_salary(100, 10); -- Raises salary for employee with ID 100 by 10%
END;`}
      </code>
    </pre><br />

    <h3>Calling a Function</h3>
    <pre>
      <code>
{`DECLARE
  v_bonus NUMBER;
BEGIN
  v_bonus := calculate_bonus(5000); -- Calculates bonus for a salary of 5000
  DBMS_OUTPUT.PUT_LINE('Bonus: ' || v_bonus);
END;`}
      </code>
    </pre><br />

    <h2>4. Differences Between Procedures and Functions</h2>
    <table>
      <thead>
        <tr>
          <th>Feature</th>
          <th>Procedure</th>
          <th>Function</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Return Value</td>
          <td>Does not return a value</td>
          <td>Returns a single value</td>
        </tr>
        <tr>
          <td>Invocation</td>
          <td>Can be invoked using <code>CALL</code> or directly in PL/SQL blocks</td>
          <td>Can be called within SQL statements</td>
        </tr>
        <tr>
          <td>Purpose</td>
          <td>Performs an action</td>
          <td>Computes and returns a value</td>
        </tr>
      </tbody>
    </table><br />

    <p>
      Understanding PL/SQL procedures and functions allows you to write modular, reusable code in Oracle databases, leading to better organization and easier maintenance of your PL/SQL scripts. 
      If you have further questions or need more examples, feel free to ask!
    </p>
  </div>
)}






{selectedChapter === 'chapter15' && (
<div className={style.chaptercontent}>
  <h1 className={style.heading} >PL/SQL Exception handling in oracle </h1>


    <p>
      PL/SQL provides a robust mechanism for handling exceptions, which are runtime errors that occur during the execution of a PL/SQL block. 
      Exception handling allows developers to gracefully manage errors, providing a way to log error messages or execute alternative actions without terminating the program abruptly.
    </p><br />

    <h2>1. Types of Exceptions</h2>
    <p>There are two main types of exceptions in PL/SQL:</p>
    <ul>
      <li><strong>Predefined Exceptions</strong>: These are exceptions defined by Oracle, such as <code>NO_DATA_FOUND</code>, <code>TOO_MANY_ROWS</code>, and <code>ZERO_DIVIDE</code>. You can handle these exceptions directly by name.</li><br />
      <li><strong>User-Defined Exceptions</strong>: These are exceptions that you define in your PL/SQL code. You can raise these exceptions explicitly using the <code>RAISE</code> statement.</li>
    </ul><br />

    <h2>2. Basic Syntax</h2>
    <pre>
      <code>
{`BEGIN
  -- Executable statements
EXCEPTION
  WHEN exception_name THEN
    -- Exception handling code
  WHEN another_exception THEN
    -- Handling code for another exception
  WHEN OTHERS THEN
    -- Handling code for any other exception
END;`}
      </code>
    </pre><br />

    <h2>3. Example of Exception Handling</h2>
    <pre>
      <code>
{`DECLARE
  v_salary NUMBER;
BEGIN
  SELECT salary INTO v_salary FROM employees WHERE employee_id = 100;

  -- Example of a possible division by zero
  v_salary := v_salary / 0;  -- This will raise a ZERO_DIVIDE exception

EXCEPTION
  WHEN NO_DATA_FOUND THEN
    DBMS_OUTPUT.PUT_LINE('No employee found with the specified ID.');
  WHEN ZERO_DIVIDE THEN
    DBMS_OUTPUT.PUT_LINE('Division by zero error occurred.');
  WHEN OTHERS THEN
    DBMS_OUTPUT.PUT_LINE('An unexpected error occurred: ' || SQLERRM);
END;`}
      </code>
    </pre><br />

    <h2>4. Raising User-Defined Exceptions</h2>
    <pre>
      <code>
{`DECLARE
  v_salary NUMBER;
  insufficient_funds EXCEPTION; -- User-defined exception
BEGIN
  v_salary := 1000;  -- Assume this is the balance

  IF v_salary < 500 THEN
    RAISE insufficient_funds;  -- Raise the user-defined exception
  END IF;

  DBMS_OUTPUT.PUT_LINE('Transaction successful.');

EXCEPTION
  WHEN insufficient_funds THEN
    DBMS_OUTPUT.PUT_LINE('Insufficient funds for this transaction.');
  WHEN OTHERS THEN
    DBMS_OUTPUT.PUT_LINE('An unexpected error occurred: ' || SQLERRM);
END;`}
      </code>
    </pre><br />

    <h2>5. Using <code>SQLERRM</code></h2>
    <p>
      The <code>SQLERRM</code> function returns the error message associated with an exception. 
      You can use it in the <code>WHEN OTHERS</code> block to log unexpected errors.
    </p>
 



  </div>
)}




{selectedChapter === 'chapter16' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>User Management and Roles </h1>

    <p>
      In Oracle databases, user management is essential for maintaining security and access control. Users are assigned specific roles that define their permissions, allowing for organized access to database resources.
    </p><br />

    <h2>1. Creating a User</h2>
    <p>To create a new user in Oracle, you can use the <code>CREATE USER</code> statement. Here’s the syntax:</p>
    <pre>
      <code>{`CREATE USER username IDENTIFIED BY password;`}</code>
    </pre>
    <h3>Example</h3>
    <pre>
      <code>{`CREATE USER john_doe IDENTIFIED BY securePass123;`}</code>
    </pre>
    <p>
      This command creates a new user named <code>john_doe</code> with the password <code>securePass123</code>.
    </p><br />

    <h2>2. Granting Privileges to a User</h2>
    <p>Once a user is created, you must grant privileges to allow them to perform actions on the database. The syntax is:</p>
    <pre>
      <code>{`GRANT privilege_type TO username;`}</code>
    </pre>
    <h3>Example</h3>
    <pre>
      <code>{`GRANT CREATE SESSION TO john_doe;`}</code>
    </pre>
    <p>
      This command grants the <code>CREATE SESSION</code> privilege to the user <code>john_doe</code>, allowing them to log in to the database.
    </p><br />

    <h2>3. Creating Roles</h2>
    <p>Roles are a collection of privileges that can be granted to users. This simplifies the management of user permissions. The syntax for creating a role is:</p>
    <pre>
      <code>{`CREATE ROLE role_name;`}</code>
    </pre>
    <h3>Example</h3>
    <pre>
      <code>{`CREATE ROLE data_analyst;`}</code>
    </pre>
    <p>
      This command creates a new role named <code>data_analyst</code>.
    </p><br />

    <h2>4. Granting Privileges to a Role</h2>
    <p>You can grant specific privileges to a role using the following syntax:</p>
    <pre>
      <code>{`GRANT privilege_type TO role_name;`}</code>
    </pre>
    <h3>Example</h3>
    <pre>
      <code>{`GRANT SELECT, INSERT, UPDATE ON employees TO data_analyst;`}</code>
    </pre>
    <p>
      This command grants the <code>SELECT</code>, <code>INSERT</code>, and <code>UPDATE</code> privileges on the <code>employees</code> table to the <code>data_analyst</code> role.
    </p><br />

    <h2>5. Assigning Roles to Users</h2>
    <p>To assign a role to a user, you can use the <code>GRANT</code> statement as follows:</p>
    <pre>
      <code>{`GRANT role_name TO username;`}</code>
    </pre>
    <h3>Example</h3>
    <pre>
      <code>{`GRANT data_analyst TO john_doe;`}</code>
    </pre>
    <p>
      This command grants the <code>data_analyst</code> role to the user <code>john_doe</code>, allowing them to inherit the privileges associated with that role.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>6. Revoking Privileges and Roles</h2>
    <h3>Revoking Privileges</h3>
    <p>You can revoke privileges from users using the <code>REVOKE</code> statement:</p>
    <pre>
      <code>{`REVOKE privilege_type FROM username;`}</code>
    </pre>
    <h3>Example</h3>
    <pre>
      <code>{`REVOKE CREATE SESSION FROM john_doe;`}</code>
    </pre><br />
    
    <h3>Revoking Roles</h3>
    <p>You can also revoke roles from users:</p>
    <pre>
      <code>{`REVOKE role_name FROM username;`}</code>
    </pre>
    <h3>Example</h3>
    <pre>
      <code>{`REVOKE data_analyst FROM john_doe;`}</code>
    </pre><br />

    <h2 style={{paddingBottom:"6px"}}>7. Viewing User and Role Information</h2>
    <h3>To list all users:</h3>
    <pre>
      <code>{`SELECT username FROM all_users;`}</code>
    </pre><br />
    <h3>To list all roles:</h3>
    <pre>
      <code>{`SELECT role FROM dba_roles;`}</code>
    </pre>
  </div>
)}


{selectedChapter === 'chapter17' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Tablespace and Datafile Management</h1>
    
    <p>
      In Oracle databases, tablespaces and datafiles are critical components for organizing and managing data storage. Understanding how they work allows for efficient database management and optimization.
    </p><br />

    <h2>1. What is a Tablespace?</h2>
    <p>
      A <strong>tablespace</strong> is a logical storage unit within an Oracle database. It groups related logical structures, such as tables and indexes, to manage the physical storage of data.
    </p>
    <ul>
      <li><strong>Default Tablespace:</strong> Each user can be assigned a default tablespace where their objects will be created.</li><br />
      <li><strong>Temporary Tablespace:</strong> Used for sorting operations and managing temporary data.</li>
    </ul><br />

    <h2>2. Creating a Tablespace</h2>
    <p>To create a new tablespace, you can use the following SQL syntax:</p>
    <pre>
      <code>{`CREATE TABLESPACE tablespace_name 
DATAFILE 'datafile_name.dbf' 
SIZE initial_size [AUTOEXTEND ON [NEXT next_size]];`}</code>
    </pre>
    <h3>Example</h3>
    <pre>
      <code>{`CREATE TABLESPACE users 
DATAFILE 'users01.dbf' 
SIZE 100M AUTOEXTEND ON NEXT 10M;`}</code>
    </pre>
    <p>
      This command creates a new tablespace named <code>users</code> with an initial size of 100MB, which can automatically extend in increments of 10MB.
    </p><br />

    <h2>3. Adding a Datafile to a Tablespace</h2>
    <p>You can add additional datafiles to an existing tablespace using:</p>
    <pre>
      <code>{`ALTER TABLESPACE tablespace_name 
ADD DATAFILE 'new_datafile_name.dbf' 
SIZE size;`}</code>
    </pre>
    <h3>Example</h3>
    <pre>
      <code>{`ALTER TABLESPACE users 
ADD DATAFILE 'users02.dbf' 
SIZE 50M;`}</code>
    </pre>
    <p>
      This command adds a new datafile to the <code>users</code> tablespace with a size of 50MB.
    </p><br />

    <h2>4. Viewing Tablespace Information</h2>
    <p>To view information about tablespaces, you can query the data dictionary:</p>
    <pre>
      <code>{`SELECT tablespace_name, status, contents 
FROM dba_tablespaces;`}</code>
    </pre><br />

    <h2>5. Managing Tablespace Space Usage</h2>
    <p>You can check space usage in a tablespace with the following query:</p>
    <pre>
      <code>{`SELECT tablespace_name, 
       SUM(bytes)/1024/1024 AS size_mb,
       SUM(free_bytes)/1024/1024 AS free_mb 
FROM dba_data_files 
GROUP BY tablespace_name;`}</code>
    </pre><br />

    <h2>6. Dropping a Tablespace</h2>
    <p>To drop a tablespace, you can use:</p>
    <pre>
      <code>{`DROP TABLESPACE tablespace_name INCLUDING CONTENTS AND DATAFILES;`}</code>
    </pre>
    <h3>Example</h3>
    <pre>
      <code>{`DROP TABLESPACE users INCLUDING CONTENTS AND DATAFILES;`}</code>
    </pre>
    <p>
      This command removes the <code>users</code> tablespace along with all its contents and associated datafiles.
    </p>
  </div>
)}





{selectedChapter === 'chapter18' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Backup and Recovery Strategies</h1>

    <h2 style={{paddingBottom:"6px"}}>1. Importance of Backup and Recovery</h2>
    <ul>
      <li><strong>Data Loss Prevention:</strong> Regular backups prevent data loss due to hardware failures, human errors, or disasters.</li><br />
      <li><strong>Business Continuity:</strong> Effective recovery strategies ensure that business operations can resume quickly after a failure.</li>
    </ul><br />

    <h2 style={{paddingBottom:"6px"}}>2. Types of Backups</h2>
    <ul>
      <li><strong>Full Backup:</strong> A complete backup of the entire database.</li><br />
      <li><strong>Incremental Backup:</strong> Backups that only capture changes made since the last backup (full or incremental).</li><br />
      <li><strong>Differential Backup:</strong> Captures changes made since the last full backup.</li>
    </ul><br />

    <h2 style={{paddingBottom:"6px"}}>3. Backup Methods</h2>
    <ul>
      <li><strong>RMAN (Recovery Manager):</strong> A built-in tool in Oracle for performing backup and recovery operations.</li><br />
      <li><strong>User-managed Backups:</strong> Manual backup processes using operating system commands to copy database files.</li>
    </ul><br />

    <h2 style={{paddingBottom:"6px"}}>4. RMAN Commands for Backup</h2>
    <h3>Full Database Backup</h3>
    <pre>
      <code>{`RMAN> BACKUP DATABASE;`}</code>
    </pre><br />

    <h3>Incremental Backup</h3>
    <pre>
      <code>{`RMAN> BACKUP INCREMENTAL LEVEL 1 DATABASE;`}</code>
    </pre><br />

    <h3>Backup Control File</h3>
    <pre>
      <code>{`RMAN> BACKUP CURRENT CONTROLFILE;`}</code>
    </pre><br />

    <h2  style={{paddingBottom:"6px"}}>5. Recovery Strategies</h2>
    <ul>
      <li><strong>Incomplete Recovery:</strong> Restoring the database to a point in time before a failure occurred.</li><br />
      <li><strong>Complete Recovery:</strong> Restoring the database to its latest committed state.</li>
    </ul><br />

    <h2 style={{paddingBottom:"6px"}}>6. RMAN Commands for Recovery</h2>
    <h3>Restore Database</h3>
    <pre>
      <code>{`RMAN> RESTORE DATABASE;`}</code>
    </pre><br />

    <h3>Recover Database</h3>
    <pre>
      <code>{`RMAN> RECOVER DATABASE;`}</code>
    </pre><br />

    <h3>Performing Point-in-Time Recovery</h3>
    <pre>
      <code>{`RMAN> RUN {
    SET UNTIL TIME 'YYYY-MM-DD HH24:MI:SS';
    RESTORE DATABASE;
    RECOVER DATABASE;
}`}</code>
    </pre><br />

    <h2>7. Monitoring Backup and Recovery Operations</h2>
    <p>Regularly monitor the status of backups and recovery operations using views such as:</p>
    <ul>
      <li><code>V$BACKUP_SET:</code> Contains information about backup sets.</li><br />
      <li><code>V$RECOVERY_AREA_USAGE:</code> Provides usage details of the recovery area.</li>
    </ul><br />
  </div>
)}



{selectedChapter === 'chapter19' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Performing Tuning Basics</h1>

    <h2 style={{paddingBottom:"6px"}}>1. Importance of Tuning</h2>
    <ul>
      <li><strong>Performance Improvement:</strong> Proper tuning enhances the performance of SQL queries, leading to faster response times.</li><br />
      <li><strong>Resource Optimization:</strong> Efficient use of CPU, memory, and I/O resources helps reduce operational costs.</li>
    </ul><br />

    <h2 style={{paddingBottom:"6px"}}>2. Types of Tuning</h2>
    <ul>
      <li><strong>SQL Tuning:</strong> Optimizing individual SQL statements to improve their execution plans.</li><br />
      <li><strong>Instance Tuning:</strong> Configuring memory, I/O, and other parameters at the database instance level.</li><br />
      <li><strong>System Tuning:</strong> Adjusting hardware and operating system settings to optimize database performance.</li>
    </ul><br />

    <h2 style={{paddingBottom:"6px"}}>3. Identifying Performance Issues</h2>
    <ul>
      <li><strong>AWR (Automatic Workload Repository):</strong> Analyze performance statistics and identify problematic SQL queries.</li><br />
      <li><strong>ADDM (Automatic Database Diagnostic Monitor):</strong> Provides recommendations for performance improvements based on AWR data.</li>
    </ul><br />

    <h2>4. SQL Tuning</h2>
    <h3>Using the SQL Tuning Advisor</h3>
    <pre>
      <code>{`EXEC DBMS_SQLTUNE.create_tuning_task(
    sql_id => 'your_sql_id',
    task_name => 'my_tuning_task'
);

EXEC DBMS_SQLTUNE.execute_tuning_task('my_tuning_task');`}</code>
    </pre><br />

    <h3>Using Hints</h3>
    <pre>
      <code>{`SELECT /*+ INDEX(emp emp_idx) */ * FROM employees emp;`}</code>
    </pre><br />

    <h2>5. Instance Tuning</h2>
    <ul>
      <li><strong>Memory Configuration:</strong> Adjusting parameters like <code>SGA</code>, <code>PGA</code>, and <code>DB_CACHE_SIZE</code> to optimize memory usage.</li><br />
      <li><strong>I/O Configuration:</strong> Ensuring that I/O operations are efficient, such as using RAID configurations for datafiles.</li>
    </ul><br />

    <h2 style={{paddingBottom:"6px"}}>6. Monitoring Performance</h2>
    <h3>V$SQL View</h3>
    <pre>
      <code>{`SELECT sql_id, executions, elapsed_time, cpu_time
FROM v$sql
ORDER BY elapsed_time DESC;`}</code>
    </pre><br />

    <h3>V$SESSION View</h3>
    <pre>
      <code>{`SELECT sid, serial#, status, osuser, machine
FROM v$session
WHERE status = 'ACTIVE';`}</code>
    </pre><br />

    <h2 style={{paddingBottom:"6px"}}>7. Best Practices for Tuning</h2>
    <ul>
      <li><strong>Regular Monitoring:</strong> Continuously monitor performance metrics using AWR and SQL monitoring tools.</li><br />
      <li><strong>Query Optimization:</strong> Use proper indexing, avoid full table scans, and write efficient SQL queries.</li><br />
      <li><strong>Use Bind Variables:</strong> This can help reduce parsing overhead and improve performance.</li>
    </ul>
  </div>
)}






{selectedChapter === 'chapter20' && (
<div className={style.chaptercontent}>
  <h1 className={style.heading} >Monitoring and Troubleshooting </h1>

    <p>
      Monitoring and troubleshooting are critical aspects of database management in Oracle. They ensure the database runs efficiently, identify potential issues early, and maintain system performance.
    </p><br />

    <h3 style={{paddingBottom:"6px"}}>1. Importance of Monitoring</h3>
    <ul>
      <li><strong>Performance Optimization</strong>: Regular monitoring helps in identifying performance bottlenecks and optimizing resource utilization.</li><br />
      <li><strong>Proactive Issue Resolution</strong>: Early detection of issues prevents downtime and data loss, allowing for quicker resolutions.</li><br />
      <li><strong>Capacity Planning</strong>: Monitoring trends in resource usage helps in forecasting future needs and planning upgrades.</li>
    </ul><br />

    <h3 style={{paddingBottom:"6px"}} >2. Key Monitoring Tools in Oracle</h3>
    <ul>
      <li><strong>Oracle Enterprise Manager (OEM)</strong>: A web-based interface for managing and monitoring Oracle databases. It provides a comprehensive view of database performance, availability, and configuration.</li><br />
      <li><strong>Automatic Workload Repository (AWR)</strong>: A built-in repository that collects and maintains performance statistics. It allows you to generate reports to analyze database performance over time.</li><br />
      <li><strong>Active Session History (ASH)</strong>: Provides real-time monitoring of active sessions, allowing for detailed analysis of current workload and session activity.</li><br />
      <li><strong>SQL Trace</strong>: Enables detailed logging of SQL execution to identify slow-running queries and understand their execution paths.</li>
    </ul><br />

    <h3 style={{paddingBottom:"6px"}}>3. Monitoring Key Metrics</h3>
    <ul>
      <li>
        <strong>Session and User Activity</strong>: Monitoring active sessions helps identify long-running queries or blocking sessions.
        <pre>
          <code>{`SELECT username, status, osuser, machine
FROM v$session
WHERE status = 'ACTIVE';`}</code>
        </pre>
      </li><br />
      <li>
        <strong>Wait Events</strong>: Analyzing wait events gives insight into resource contention, allowing for targeted tuning.
        <pre>
          <code>{`SELECT event, total_waits, time_waited
FROM v$system_event;`}</code>
        </pre>
      </li><br />
      <li>
        <strong>System Resource Usage</strong>: Monitoring CPU, memory, and I/O usage is crucial for understanding the overall health of the database.
        <pre>
          <code>{`SELECT *
FROM v$sysstat;`}</code>
        </pre>
      </li>
    </ul><br />

    <h3>4. Troubleshooting Common Issues</h3>
    <ul>
      <li>
        <strong>Identifying Slow Queries</strong>: Use AWR or SQL Trace to analyze slow queries and their execution plans.
        <pre>
          <code>{`SELECT sql_id, elapsed_time, cpu_time
FROM v$sql
ORDER BY elapsed_time DESC;`}</code>
        </pre>
      </li><br />
      <li>
        <strong>Resolving Blocking Sessions</strong>: Identify blocking sessions using the following query and resolve them as necessary.
        <pre>
          <code>{`SELECT blocking_session, sid, serial#, wait_time, seconds_in_wait
FROM v$session
WHERE blocking_session IS NOT NULL;`}</code>
        </pre>
      </li><br />
      <li>
        <strong>Disk Space Issues</strong>: Monitor datafile sizes and free space to prevent potential issues related to insufficient space.
        <pre>
          <code>{`SELECT tablespace_name, SUM(bytes)/1024/1024 AS size_mb
FROM dba_data_files
GROUP BY tablespace_name;`}</code>
        </pre>
      </li>
    </ul><br />

    <h3 style={{paddingBottom:"6px"}}>5. Best Practices for Monitoring and Troubleshooting</h3>
    <ul>
      <li><strong>Set Up Alerts</strong>: Configure alerts for critical performance metrics, such as high CPU usage or long-running queries, to enable proactive management.</li><br />
      <li><strong>Regularly Review AWR Reports</strong>: Analyze AWR reports periodically to track performance trends and identify areas for improvement.</li><br />
      <li><strong>Utilize Baselines</strong>: Establish performance baselines to compare current performance against historical data, helping to identify deviations.</li>
    </ul><br />

    <h2>Conclusion</h2>
    <p>
      Monitoring and troubleshooting are vital for maintaining the health and performance of Oracle databases. By leveraging the right tools and practices, database administrators can ensure optimal performance, reduce downtime, and efficiently manage resources. If you have any additional questions or need further details, feel free to ask!
    </p>



  </div>

)}





{selectedChapter === 'chapter21' && (
<div className={style.chaptercontent}>
  <h1 className={style.heading} >Overview of Oracle SQL Devloper</h1>
    <p>
      Oracle SQL Developer is a free integrated development environment (IDE) that simplifies the development and management of Oracle Database in both cloud and on-premises environments. It provides a user-friendly interface and various features that enhance productivity for developers and database administrators.
    </p><br />
    
    <h3 style={{paddingBottom:"6px"}}>Key Features</h3>
    <ul>
      <li><strong>Database Object Management:</strong> Easily create, modify, and manage database objects such as tables, views, indexes, and more.</li><br />
      <li><strong>SQL Worksheet:</strong> A powerful SQL editor for running queries, scripts, and PL/SQL code, with features like syntax highlighting and code formatting.</li><br />
      <li><strong>Data Modeling:</strong> Build and visualize data models to understand database structures and relationships through diagramming tools.</li><br />
      <li><strong>Data Import/Export:</strong> Import and export data in various formats, including CSV, Excel, and XML, facilitating data migration and reporting.</li><br />
      <li><strong>Query Builder:</strong> A visual tool for building SQL queries without writing code, which can help beginners and reduce errors.</li><br />
      <li><strong>Reporting:</strong> Generate and customize reports based on your data, providing valuable insights into database performance and usage.</li>
    </ul><br />

    <h3 style={{paddingBottom:"6px"}} >Benefits of Using Oracle SQL Developer</h3>
    <ul>
      <li><strong>Cost-effective:</strong> Being a free tool, it offers a comprehensive feature set without the need for expensive licenses.</li><br />
      <li><strong>Productivity:</strong> The intuitive interface and rich features streamline common development tasks, allowing for quicker database management.</li><br />
      <li><strong>Cross-platform Support:</strong> SQL Developer is available on multiple platforms (Windows, macOS, and Linux), making it accessible to a wide range of users.</li><br />
      <li><strong>Integration:</strong> Easily connects to Oracle Cloud services, enabling users to manage both cloud and on-premises databases seamlessly.</li>
    </ul><br />

    <h3>Installation and Setup</h3>
    <p>
      To install Oracle SQL Developer, follow these steps:
    </p>
    <ol>
      <li>Download the latest version of Oracle SQL Developer from the official Oracle website.</li><br />
      <li>Unzip the downloaded file to your desired location.</li><br />
      <li>Run the <code>sqldeveloper.exe</code> (Windows) or the appropriate script for your operating system.</li><br />
      <li>Configure your connection settings to connect to your Oracle Database instance.</li>
    </ol><br />

    <h3>Conclusion</h3>
    <p>
      Oracle SQL Developer is a powerful tool that significantly enhances the development and management experience for Oracle Database users. With its extensive features and ease of use, it is an essential tool for both novice and experienced developers alike. For more information, you can explore Oracle's official documentation or seek assistance within the SQL Developer community.
    </p>



  </div>

)}





{selectedChapter === 'chapter22' && (
<div className={style.chaptercontent}>
  <h1 className={style.heading} >Using Oracle APEX for Application Development </h1>

    <p>
      Oracle APEX (Application Express) is a low-code development platform that enables users to build scalable, secure enterprise applications with minimal programming effort. It leverages the capabilities of Oracle Database and provides a user-friendly environment for application development.
    </p><br />

    <h3 style={{paddingBottom:"6px"}}>Key Features of Oracle APEX</h3>
    <ul>
      <li ><strong>Low-Code Development:</strong> APEX provides a low-code environment that allows developers to quickly build applications through drag-and-drop functionality and pre-built components.</li><br />
      <li><strong>Rapid Application Development (RAD):</strong> Developers can create applications quickly with the integrated development environment and wizards, significantly reducing development time.</li><br />
      <li><strong>Responsive Design:</strong> Applications built with APEX are responsive by default, allowing them to work seamlessly across various devices and screen sizes.</li><br />
      <li><strong>Robust Security Features:</strong> APEX provides built-in security features, including user authentication and authorization, to protect applications and data.</li><br />
      <li><strong>Integration Capabilities:</strong> Easily integrate with RESTful Web Services and other Oracle tools, enabling data access and manipulation across various platforms.</li>
    </ul><br />

    <h3 style={{paddingBottom:"6px"}}>Benefits of Using Oracle APEX</h3>
    <ul>
      <li><strong>Cost-effective:</strong> APEX is included with Oracle Database at no additional cost, making it a budget-friendly option for application development.</li><br />
      <li><strong>Collaboration:</strong> Supports collaborative development, allowing teams to work together efficiently on application projects.</li><br />
      <li><strong>Extensibility:</strong> APEX allows developers to extend applications using PL/SQL, JavaScript, and CSS, providing flexibility and customization options.</li><br />
      <li><strong>Community Support:</strong> A large and active community offers resources, tutorials, and forums to help developers troubleshoot and enhance their applications.</li>
    </ul><br />

    <h3 style={{paddingBottom:"6px"}}>Getting Started with Oracle APEX</h3>
    <ol>
      <li><strong>Access Oracle APEX:</strong> You can access APEX through Oracle Cloud or an on-premises Oracle Database instance.</li><br />
      <li><strong>Create a Workspace:</strong> Set up a workspace where you can develop and manage your applications.</li><br />
      <li><strong>Build Your Application:</strong> Use the Application Builder to create new applications, define data models, and design user interfaces.</li><br />
      <li><strong>Deploy Your Application:</strong> Once developed, you can deploy your application directly from APEX to your users.</li>
    </ol><br />

    <h3>Conclusion</h3>
    <p>
      Oracle APEX is a powerful tool for developing applications quickly and efficiently. Its low-code nature, combined with robust features and strong security, makes it an ideal choice for businesses looking to modernize their application development processes. For further information, refer to the official Oracle APEX documentation or community resources.
    </p>


   

  </div>

)}




{selectedChapter === 'chapter23' && (
<div className={style.chaptercontent}>
   <h1 className={style.heading}>Working with Oracle Data Modeler</h1>
    <p>
      Oracle Data Modeler is a powerful tool for designing and visualizing database structures. It provides a graphical interface for creating data models, which helps in understanding and organizing data relationships within an Oracle Database.
    </p><br />

    <h3 style={{paddingBottom:"6px"}}>Key Features of Oracle Data Modeler</h3>
    <ul>
      <li><strong>Visual Design:</strong> Create entity-relationship (ER) diagrams visually, allowing for easier design and communication of data structures.</li><br />
      <li><strong>Support for Various Modeling Types:</strong> Supports conceptual, logical, and physical data modeling, enabling comprehensive database design.</li><br />
      <li><strong>Reverse Engineering:</strong> Import existing database structures to create a visual representation of the current model.</li><br />
      <li><strong>Forward Engineering:</strong> Generate DDL scripts from data models to create or modify database structures directly.</li><br />
      <li><strong>Collaboration Features:</strong> Share models and collaborate with team members effectively through a shared repository.</li>
    </ul><br />

    <h3 style={{paddingBottom:"6px"}}>Benefits of Using Oracle Data Modeler</h3>
    <ul>
      <li><strong>Improved Productivity:</strong> The graphical interface streamlines the modeling process, allowing developers to focus on design rather than coding.</li><br />
      <li><strong>Enhanced Communication:</strong> Visual representations make it easier to communicate database structures to non-technical stakeholders.</li><br />
      <li><strong>Consistent Standards:</strong> Ensures adherence to design standards and best practices through built-in validation and guidelines.</li><br />
      <li><strong>Integration with Oracle Products:</strong> Seamlessly integrates with Oracle SQL Developer and other Oracle tools for a comprehensive development experience.</li>
    </ul><br />

    <h3 style={{paddingBottom:"6px"}}>Getting Started with Oracle Data Modeler</h3>
    <ol>
      <li><strong>Launch Oracle SQL Developer:</strong> Start SQL Developer, and then navigate to the Data Modeler section.</li><br />
      <li><strong>Create a New Model:</strong> Use the "New" option to start a new data model, selecting the appropriate modeling type (conceptual, logical, or physical).</li><br />
      <li><strong>Define Entities and Relationships:</strong> Drag and drop entities, attributes, and relationships onto the canvas to construct the data model.</li><br />
      <li><strong>Validate the Model:</strong> Use the validation features to check for any inconsistencies or errors in the model.</li><br />
      <li><strong>Generate DDL Scripts:</strong> Once the model is complete, generate DDL scripts for deployment to the Oracle Database.</li>
    </ol><br />

    <h3>Conclusion</h3>
    <p>
      Oracle Data Modeler is an essential tool for database architects and developers, providing the capabilities to design, visualize, and manage data models efficiently. Its integration with Oracle development tools enhances productivity and ensures high-quality database design. For more detailed guidance, refer to the official Oracle Data Modeler documentation or community resources.
    </p>


  </div>

)}



{selectedChapter === 'chapter24' && (
<div className={style.chaptercontent}>
    <h1 className={style.heading}>Data Modeling (Conceptual, Logical, and Physical)</h1>

    
    <p>
      In Oracle database design, data modeling is divided into three main stages: conceptual, logical, and physical. Each of these stages plays a crucial role in ensuring that the database is designed to meet business requirements while being efficient and scalable.
    </p><br />

    <h3>1. Conceptual Data Modeling</h3>
    <p>
      Conceptual data modeling is the first step in the data modeling process. It provides a high-level, business-oriented representation of data without delving into technical details.
    </p>
    <ul>
      <li>
        <strong>Business-Focused:</strong> Identifies the main entities and their relationships based on business requirements.
      </li><br />
      <li>
        <strong>No Technical Specifications:</strong> The conceptual model does not specify data types, constraints, or implementation details.
      </li><br />
      <li>
        <strong>Use of ER Diagrams:</strong> Entity-Relationship (ER) diagrams are typically used to represent the entities and relationships at this stage.
      </li>
    </ul><br />

    <h3>2. Logical Data Modeling</h3>
    <p>
      The logical data model provides more detail than the conceptual model by defining the structure of the data in terms of attributes, relationships, and keys. However, it is still platform-independent, meaning it does not concern itself with how the database will be physically implemented.
    </p>
    <ul>
      <li>
        <strong>Attributes and Relationships:</strong> Specifies the attributes (columns) for each entity and defines the relationships between them.
      </li><br />
      <li>
        <strong>Normalization:</strong> The model applies normalization rules to minimize data redundancy and ensure data integrity.
      </li><br />
      <li>
        <strong>Primary and Foreign Keys:</strong> Identifies primary keys (unique identifiers) and foreign keys (links between related entities).
      </li>
    </ul><br />

    <h3>3. Physical Data Modeling</h3>
    <p>
      Physical data modeling is the final stage, where the logical model is translated into a database-specific implementation. This involves defining the actual database tables, columns, indexes, and storage details tailored to Oracle's database system.
    </p>
    <ul>
      <li>
        <strong>Oracle-Specific:</strong> The model is tailored to the Oracle Database platform, defining specific data types, indexes, and storage structures.
      </li><br />
      <li>
        <strong>Performance Optimization:</strong> Includes considerations like indexing, partitioning, and tablespace allocation to optimize performance.
      </li><br />
      <li>
        <strong>Database Constraints:</strong> Implements constraints such as NOT NULL, UNIQUE, and CHECK to ensure data integrity.
      </li>
    </ul><br />

    <h3 style={{paddingBottom:"6px"}}>Key Data Modeling Tools in Oracle</h3>
    <ul>
      <li>
        <strong>Oracle SQL Developer Data Modeler:</strong> A powerful tool for creating and managing conceptual, logical, and physical data models. It supports forward and reverse engineering of database designs.
      </li><br />
      <li>
        <strong>ER Diagrams:</strong> Useful for both conceptual and logical modeling to visually represent entities and their relationships.
      </li><br />
      <li>
        <strong>Oracle Designer:</strong> A legacy tool for more complex modeling needs, offering advanced support for large-scale databases.
      </li>
    </ul><br />

    <h3>Conclusion</h3>
    <p>
      Understanding the stages of conceptual, logical, and physical data modeling is key to designing efficient, scalable Oracle databases. By following this structured approach, you can ensure that your database meets both business requirements and performance goals. If you need more guidance or specific examples, Oracle’s documentation on data modeling provides further insights.
    </p>
  </div>
)}





{selectedChapter === 'chapter25' && (
<div className={style.chaptercontent}>
 
    <h1 className={style.heading}>Entity-Relational (ER) Modeling</h1>

    <p>
      Entity-Relational (ER) modeling is a key technique used in database design to visually represent the structure and relationships between data entities. It serves as a bridge between conceptual and logical data modeling by mapping out the entities, their attributes, and the relationships that connect them.
    </p><br />

    <h3>1. What is an Entity?</h3>
    <p>
      In ER modeling, an <strong>entity</strong> represents a real-world object or concept, such as a person, place, or event, that can be distinctly identified in a database. 
    </p>
    <ul>
      <li>
        <strong>Example:</strong> In a business context, entities could include <code>Customer</code>, <code>Order</code>, or <code>Product</code>.
      </li>
      <li><br />
        Each entity has attributes (or properties), such as a customer's name, address, or email.
      </li>
    </ul><br />

    <h3>2. What is a Relationship?</h3>
    <p>
      A <strong>relationship</strong> in ER modeling defines how entities are related to each other. Relationships can be one-to-one, one-to-many, or many-to-many, depending on how entities interact with each other.
    </p>
    <ul>
      <li>
        <strong>One-to-One Relationship (1:1):</strong> One instance of an entity is related to one instance of another entity.
      </li><br />
      <li>
        <strong>One-to-Many Relationship (1:N):</strong> One instance of an entity can be related to multiple instances of another entity.
      </li><br />
      <li>
        <strong>Many-to-Many Relationship (M:N):</strong> Multiple instances of one entity are related to multiple instances of another entity.
      </li>
    </ul><br />

    <h3>3. Attributes in ER Modeling</h3>
    <p>
      Attributes define the specific properties of an entity. In ER diagrams, these attributes are usually represented as ovals connected to their respective entities.
    </p>
    <ul>
      <li>
        <strong>Primary Key:</strong> A unique attribute or set of attributes that identifies each instance of an entity. For example, a <code>CustomerID</code> in a <code>Customer</code> entity.
      </li><br />
      <li>
        <strong>Foreign Key:</strong> An attribute in one entity that links to the primary key of another entity, establishing a relationship between the two.
      </li>
    </ul><br />

    <h3>4. Entity-Relationship Diagrams (ERD)</h3>
    <p>
      ER diagrams (ERD) are visual representations of entities, attributes, and relationships. They help in conceptualizing and designing database structures, making it easier to communicate ideas with stakeholders before implementation.
    </p>
    <ul>
      <li>
        ER diagrams use different notations to represent entities, relationships, and cardinality (the number of instances in a relationship).
      </li><br />
      <li>
        In Oracle, ER diagrams can be created using tools like Oracle SQL Developer Data Modeler or other ER diagramming software.
      </li>
    </ul><br />

    <h3>5. Cardinality in Relationships</h3>
    <p>
      Cardinality defines the numeric relationship between entities. For instance, it describes whether a single entity instance can relate to one or more instances of another entity.
    </p>
    <ul>
      <li><strong>One-to-One (1:1):</strong> One entity instance is related to exactly one instance of another entity.</li><br />
      <li><strong>One-to-Many (1:N):</strong> One entity instance is related to many instances of another entity. For example, a single <code>Customer</code> can place many <code>Orders</code>.</li><br />
      <li><strong>Many-to-Many (M:N):</strong> Multiple instances of one entity are related to multiple instances of another entity. For example, many <code>Students</code> can enroll in many <code>Courses</code>.</li>
    </ul><br />

    <h3>6. The Role of ER Modeling in Database Design</h3>
    <p>
      ER modeling is crucial for both the conceptual and logical stages of database design. It helps ensure that all business requirements are accurately captured before the physical implementation of the database.
    </p>
    <ul>
      <li>
        ER models provide a clear blueprint for developers and database administrators (DBAs) to implement and manage databases effectively.
      </li><br />
      <li>
        They also help in identifying potential design flaws, such as redundant data or incorrect relationships, early in the design process.
      </li>
    </ul><br />

    <h3>7. Tools for ER Modeling in Oracle</h3>
    <p>
      Oracle provides tools that assist in ER modeling and database design, such as:
    </p>
    <ul>
      <li><strong>Oracle SQL Developer Data Modeler:</strong> A comprehensive tool that supports creating ER diagrams for database design.</li><br />
      <li><strong>Oracle Designer:</strong> A legacy tool for large-scale database design and modeling, still used in some organizations.</li>
    </ul><br />

    <h3>Conclusion</h3>
    <p>
      Entity-Relational (ER) modeling is a fundamental technique in Oracle database design. It helps database architects and administrators map out the structure of data and their relationships, ensuring the database is well-organized and meets business needs. By using ER diagrams and tools like Oracle SQL Developer Data Modeler, you can effectively design and visualize your Oracle databases.
    </p>

  </div>

)}



{selectedChapter === 'chapter26' && (
<div className={style.chaptercontent}>

    <h1 className={style.heading}>Normalization and Denormalization</h1>

    <p><strong>Normalization</strong> and <strong>Denormalization</strong> are concepts typically associated with database design, but they can be discussed in the context of how you handle data in <strong>Node.js</strong> applications, particularly when interacting with databases.</p><br />

    <h3>1. Normalization in Node.js (Database Context)</h3>

    <p>Normalization refers to the process of organizing data in a database to reduce redundancy and improve data integrity. In a normalized database, data is structured across multiple tables, ensuring that each piece of data is stored only once. This avoids anomalies like data inconsistency.</p>

    <p>In Node.js, normalization is often applied when working with relational databases like MySQL, PostgreSQL, or even when modeling data in a NoSQL database (like MongoDB) to ensure efficient queries and updates.</p>

    <strong>Example in Node.js:</strong>

    <p>Suppose you're using a Node.js app with a relational database. You might have two separate tables: <em>users</em> and <em>orders</em>, where the orders are related to users by a foreign key. This separation ensures that the <em>users</em> table only holds user information, while the <em>orders</em> table holds order information, avoiding duplication of user data for every order.</p>

    <pre><code>{`
    const userSchema = new Schema({
      name: String,
      email: String
    });

    const orderSchema = new Schema({
      userId: Schema.Types.ObjectId,
      product: String,
      amount: Number
    });

    // Now, you can query orders and join them with user data only when necessary
    `}</code></pre><br />

    <h3>2. Denormalization in Node.js (Database Context)</h3>

    <p>Denormalization is the opposite of normalization. It involves combining data into fewer tables or structures, which can result in redundant data but makes reads faster, as there's no need for complex joins or queries. Denormalization is commonly used in NoSQL databases (like MongoDB) where performance in reading data is prioritized over data redundancy.</p>

    <strong>Example in Node.js:</strong>

    <p>In a denormalized approach, you might store all user and order data in one document, like in MongoDB:</p>

    <pre><code>{`
    const orderSchema = new Schema({
      user: {
        name: String,
        email: String
      },
      product: String,
      amount: Number
    });

    // Now, every order document contains both user data and order data, avoiding the need to join tables.
    `}</code></pre><br />

    <h3>Trade-offs in Node.js</h3>

    <ul>
      <li><strong>Normalization:</strong> 
        <ul>
          <li>Reduces data redundancy.</li><br />
          <li>May require more complex queries involving joins.</li><br />
          <li>Great for ensuring data consistency.</li>
        </ul>
      </li><br />

      <li><strong>Denormalization:</strong> 
        <ul>
          <li>Improves read performance by avoiding joins.</li><br />
          <li>Can lead to data duplication and potential update anomalies.</li><br />
          <li>Useful in read-heavy applications.</li>
        </ul>
      </li>
    </ul><br />

    <p>In Node.js, the choice between normalization and denormalization depends on the specific application’s needs, whether it prioritizes data integrity and consistency (normalization) or performance and scalability (denormalization).</p>
  </div>
)}


{selectedChapter === 'chapter27' && (
<div className={style.chaptercontent}>
 <h1 className={style.heading}>Security Best Practices</h1>

    <p>
      Data security is a critical aspect of managing Oracle databases. By adhering to best practices, organizations can protect sensitive data, ensure compliance with regulations, and mitigate risks from internal and external threats.
    </p><br />

    <h2>1. Principle of Least Privilege</h2>
    <p>
      The Principle of Least Privilege dictates that users should have the minimum level of access necessary to perform their job functions. This reduces the attack surface and limits the potential damage from compromised accounts.
    </p>
    <p><strong>Best Practice:</strong> Regularly review and adjust user permissions, roles, and privileges to ensure they are appropriate for current job requirements.</p><br />

    <h2>2. Use Strong Authentication and Password Policies</h2>
    <p>
      Enforcing strong authentication methods, such as multi-factor authentication (MFA), and implementing stringent password policies help secure access to the database.
    </p>
    <pre>
      <code>{`ALTER PROFILE default LIMIT PASSWORD_LIFE_TIME 90 PASSWORD_GRACE_TIME 10;`}</code>
    </pre>
    <p><strong>Example:</strong> This sets the password to expire after 90 days and allows a grace period of 10 days to change the password.</p><br />

    <h2>3. Encrypt Sensitive Data</h2>
    <p>
      Encrypting sensitive data ensures that even if the data is compromised, it cannot be read or used. Oracle offers Transparent Data Encryption (TDE) to secure data at rest.
    </p>
    <pre>
      <code>{`ALTER TABLE employees MODIFY (ssn ENCRYPT);`}</code>
    </pre>
    <p><strong>Example:</strong> Encrypts the `ssn` column in the `employees` table.</p><br />

    <h2>4. Regularly Apply Security Patches</h2>
    <p>
      Keeping Oracle software up to date with the latest security patches is essential for protecting against known vulnerabilities.
    </p>
    <p><strong>Best Practice:</strong> Develop a regular schedule for applying patches and updates as part of your maintenance plan.</p><br />

    <h2>5. Audit Database Activity</h2>
    <p>
      Auditing tracks user activity, helping detect suspicious actions and providing insights into unauthorized access attempts. Use Oracle's fine-grained auditing features for detailed monitoring.
    </p>
    <pre>
      <code>{`AUDIT SELECT ON employees BY ACCESS;`}</code>
    </pre>
    <p><strong>Example:</strong> Audits every `SELECT` statement on the `employees` table.</p><br />

    <h2>6. Backup and Recovery Procedures</h2>
    <p>
      Implementing regular backups and a tested recovery plan ensures that data can be restored in case of a security breach or data loss.
    </p>
    <p><strong>Best Practice:</strong> Regularly test your backup and recovery procedures to ensure data integrity and availability.</p><br />

    <h2>Conclusion</h2>
    <p>
      Following these security best practices in Oracle databases helps safeguard sensitive information, minimize risks, and ensure compliance with security standards. Database administrators should continuously review and update security measures to address new threats.
    </p>


  </div>

)}

{selectedChapter === 'chapter28' && (
<div className={style.chaptercontent}>
    <h1 className={style.heading}>Role-Based Access Control (RBAC)</h1>

    <p>
      Role-Based Access Control (RBAC) is a security approach in Oracle that restricts system access to authorized users based on their roles within an organization. By assigning roles to users, RBAC simplifies the management of user privileges and ensures secure and controlled access to sensitive data.
    </p><br />

    <h2>1. What is Role-Based Access Control?</h2>
    <p>
      In RBAC, access permissions are assigned to roles, and roles are then assigned to users. This ensures that users only have the necessary privileges based on their responsibilities, enhancing security and reducing the risk of unauthorized access.
    </p><br />

    <h2>2. Creating and Assigning Roles</h2>
    <p>
      In Oracle, you can create custom roles and assign specific privileges to those roles. Once the role is created, it can be granted to users as needed.
    </p>
    <pre>
      <code>{`CREATE ROLE manager_role;`}</code>
    </pre>
    <p><strong>Example:</strong> This command creates a new role called `manager_role`.</p>

    <pre>
      <code>{`GRANT SELECT, UPDATE ON employees TO manager_role;`}</code>
    </pre>
    <p><strong>Example:</strong> This grants `SELECT` and `UPDATE` privileges on the `employees` table to the `manager_role`.</p>

    <pre>
      <code>{`GRANT manager_role TO john_doe;`}</code>
    </pre>
    <p><strong>Example:</strong> This assigns the `manager_role` to a user named `john_doe`, giving them the privileges associated with the role.</p><br />

    <h2>3. Managing User Privileges with Roles</h2>
    <p>
      Using roles helps manage user privileges more efficiently by allowing changes to be made at the role level rather than individually for each user. This makes it easier to grant, modify, or revoke permissions when users change positions within the organization.
    </p><br />

    <h2>4. Predefined Roles in Oracle</h2>
    <p>
      Oracle includes several predefined roles to simplify the management of common administrative and security tasks. Some of the commonly used predefined roles are:
    </p>
    <ul>
      <li><strong>DBA</strong>: Provides complete control over the database.</li><br />
      <li><strong>CONNECT</strong>: Allows users to connect to the database.</li><br />
      <li><strong>RESOURCE</strong>: Grants privileges for creating and managing database objects like tables and indexes.</li>
    </ul><br />

    <h2 style={{paddingBottom:"6px"}}>5. Best Practices for Role-Based Access Control</h2>
    <ul>
      <li><strong>Grant Roles Based on Job Functions</strong>: Only assign users the roles necessary for their job responsibilities to avoid over-privileging.</li><br />
      <li><strong>Regularly Review Roles and Privileges</strong>: Periodically audit roles and privileges to ensure they align with the organization's current security policies.</li><br />
      <li><strong>Use Principle of Least Privilege</strong>: Limit privileges assigned to roles to the minimum required for users to perform their duties.</li>
    </ul><br />

    <h2>Conclusion</h2>
    <p>
      Role-Based Access Control in Oracle provides an efficient way to manage user privileges and ensure that access to sensitive data is restricted based on the user's responsibilities. By using roles, administrators can streamline security management and enhance data protection.
    </p>
  </div>
)}


 



{selectedChapter === 'chapter29' && (
<div className={style.chaptercontent}>
    <h1 className={style.heading}>Data Encryption and Auditing</h1>

    <p>
      Data encryption and auditing are essential components of a robust security framework in Oracle databases. They help protect sensitive information from unauthorized access and ensure compliance with regulatory requirements.
    </p><br />

    <h2>1. Data Encryption in Oracle</h2>
    <p style={{paddingBottom:"6px"}}>
      Data encryption in Oracle is the process of converting plaintext data into ciphertext to prevent unauthorized access. Oracle provides several options for encrypting data at different levels:
    </p>

    <h3>1.1 Transparent Data Encryption (TDE)</h3>
    <p>
      Transparent Data Encryption (TDE) encrypts database files at the storage level without requiring changes to the application. TDE ensures that sensitive data is encrypted on disk, protecting it from unauthorized access.
    </p>
    <pre>
      <code>{`ALTER TABLESPACE users 
SET ENCRYPTION ON USING 'AES256';`}</code>
    </pre>
    <p><strong>Example:</strong> This command enables encryption for the `users` tablespace using AES-256 encryption.</p><br />

    <h3>1.2 Column-Level Encryption</h3>
    <p>
      For specific sensitive data, Oracle allows column-level encryption. This method encrypts individual columns in a table, ensuring that sensitive information is stored securely.
    </p>
    <pre>
      <code>{`CREATE TABLE employees (
    employee_id NUMBER,
    name VARCHAR2(100),
    salary NUMBER ENCRYPT USING 'AES256'
);`}</code>
    </pre>
    <p><strong>Example:</strong> This command creates an `employees` table with the `salary` column encrypted using AES-256.</p><br />

    <h2>2. Auditing in Oracle</h2>
    <p style={{paddingBottom:"6px"}}>
      Auditing is the process of tracking database activities to ensure compliance with security policies and detect unauthorized actions. Oracle provides various auditing options:
    </p>

    <h3>2.1 Standard Auditing</h3>
    <p>
      Standard auditing allows database administrators to monitor specific actions performed by users. It captures events such as logins, DML operations, and system changes.
    </p>
    <pre>
      <code>{`AUDIT SELECT ON employees BY ACCESS;`}</code>
    </pre>
    <p><strong>Example:</strong> This command enables auditing for `SELECT` operations on the `employees` table.</p><br />

    <h3>2.2 Fine-Grained Auditing (FGA)</h3>
    <p>
      Fine-Grained Auditing allows for more granular control over which actions to audit based on specific conditions. This enables monitoring sensitive data access without overwhelming the audit trail.
    </p>
    <pre>
      <code>{`DBMS_FGA.ADD_POLICY(
    object_schema => 'HR',
    object_name => 'employees',
    policy_name => 'emp_salary_access',
    statement_types => 'SELECT',
    audit_trail => DBMS_FGA.DB + DBMS_FGA.EXTENDED
);`}</code>
    </pre>
    <p><strong>Example:</strong> This command adds a fine-grained audit policy to monitor `SELECT` access to the `salary` column in the `employees` table.</p><br />

    <h2 style={{paddingBottom:"6px"}}>3. Best Practices for Data Encryption and Auditing</h2>
    <ul>
      <li><strong>Implement Strong Encryption Standards</strong>: Use robust encryption algorithms, such as AES-256, to secure sensitive data.</li><br />
      <li><strong>Regularly Review Audit Trails</strong>: Periodically analyze audit logs to detect suspicious activities and ensure compliance.</li><br />
      <li><strong>Limit Access to Encrypted Data</strong>: Ensure that only authorized users can access or decrypt sensitive data to mitigate risks.</li>
    </ul><br />

    <h2>Conclusion</h2>
    <p>
      Data encryption and auditing are crucial for safeguarding sensitive information and ensuring regulatory compliance in Oracle databases. By implementing these security measures, organizations can significantly enhance their data protection strategies.
    </p>
  </div>
)}



  



{selectedChapter === 'chapter30' && (
<div className={style.chaptercontent}>

    <h1 className={style.heading}>Creating and Managing Tables</h1>

    <p>
      Creating and managing tables is a fundamental aspect of database design in Oracle. Tables are used to store data in rows and columns, and understanding how to create, modify, and manage them is essential for effective database management.
    </p><br />

    <h2>1. Creating a Table</h2>
    <p>
      To create a table in Oracle, you can use the following SQL syntax:
    </p>
    <pre>
      <code>{`CREATE TABLE table_name (
    column1 datatype [constraints],
    column2 datatype [constraints],
    ...
);`}</code>
    </pre>
    <h3>Example:</h3>
    <pre>
      <code>{`CREATE TABLE employees (
    employee_id NUMBER PRIMARY KEY,
    name VARCHAR2(100) NOT NULL,
    hire_date DATE,
    salary NUMBER(8, 2)
);`}</code>
    </pre>
    <p>
      This command creates an `employees` table with an `employee_id` as the primary key, ensuring that each employee has a unique identifier.
    </p><br />

    <h2>2. Modifying a Table</h2>
    <p>
      You can modify an existing table using the `ALTER TABLE` command. This command allows you to add, modify, or drop columns and constraints.
    </p>
    <h3>Adding a Column:</h3>
    <pre>
      <code>{`ALTER TABLE employees 
ADD (email VARCHAR2(100));`}</code>
    </pre>
    <h3>Modifying a Column:</h3>
    <pre>
      <code>{`ALTER TABLE employees 
MODIFY (salary NUMBER(10, 2));`}</code>
    </pre>
    <h3>Dropping a Column:</h3>
    <pre>
      <code>{`ALTER TABLE employees 
DROP COLUMN email;`}</code>
    </pre><br />

    <h2>3. Dropping a Table</h2>
    <p>
      If a table is no longer needed, it can be removed using the `DROP TABLE` command. Be cautious, as this action cannot be undone and all data in the table will be lost.
    </p>
    <pre>
      <code>{`DROP TABLE employees;`}</code>
    </pre>
    <p>
      This command permanently removes the `employees` table from the database.
    </p><br />

    <h2>4. Managing Table Constraints</h2>
    <p>
      Constraints enforce rules on the data in a table. Some common types of constraints include:
    </p>
    <ul>
      <li><strong>NOT NULL</strong>: Ensures that a column cannot have a NULL value.</li><br />
      <li><strong>UNIQUE</strong>: Ensures that all values in a column are different.</li><br />
      <li><strong>CHECK</strong>: Ensures that all values in a column satisfy a specific condition.</li><br />
      <li><strong>FOREIGN KEY</strong>: Establishes a link between the data in two tables.</li>
    </ul>
    <h3>Example:</h3>
    <pre>
      <code>{`ALTER TABLE employees 
ADD CONSTRAINT chk_salary CHECK (salary > 0);`}</code>
    </pre>
    <p>
      This command adds a check constraint to the `employees` table to ensure that all salaries are greater than zero.
    </p><br />

    <h2>5. Best Practices for Creating and Managing Tables</h2>
    <ul>
      <li><strong>Plan Your Table Structure:</strong> Before creating tables, plan the data structure to ensure efficiency and normalization.</li><br />
      <li><strong>Use Descriptive Names:</strong> Use clear and descriptive names for tables and columns to improve readability and maintainability.</li><br />
      <li><strong>Implement Proper Constraints:</strong> Always apply necessary constraints to ensure data integrity.</li>
    </ul><br />

    <h2>Conclusion</h2>
    <p>
      Creating and managing tables effectively is crucial for maintaining a well-organized and efficient database. By following best practices and understanding the SQL commands available, database administrators can ensure optimal data management in Oracle.
    </p>
  </div>
)}






{selectedChapter === 'chapter31' && (
<div className={style.chaptercontent}>
    <h1 className={style.heading}>Working with Oracle Objects: Views, Indexes, and Sequences</h1>

    <p>
      Views, indexes, and sequences are essential components of Oracle databases that enhance data retrieval and management. Understanding how to effectively work with these objects is crucial for optimizing database performance and organization.
    </p><br />

    <h2>1. Working with Views</h2>
    <p>
      A <strong>view</strong> is a virtual table that provides a way to present data from one or more tables. Views do not store data themselves but retrieve data stored in the underlying tables.
    </p>
    <h3>Creating a View:</h3>
    <pre>
      <code>{`CREATE VIEW view_name AS
SELECT column1, column2
FROM table_name
WHERE condition;`}</code>
    </pre>
    <h3>Example:</h3>
    <pre>
      <code>{`CREATE VIEW employee_view AS
SELECT employee_id, name, salary
FROM employees
WHERE salary > 50000;`}</code>
    </pre>
    <p>
      This command creates a view named `employee_view` that displays only employees with a salary greater than 50,000.
    </p>

    <h3>Querying a View:</h3>
    <pre>
      <code>{`SELECT * FROM employee_view;`}</code>
    </pre>
    <p>
      You can query a view just like a regular table to retrieve data.
    </p><br />

    <h2>2. Working with Indexes</h2>
    <p>
      An <strong>index</strong> is a database object that improves the speed of data retrieval operations on a table at the cost of additional space and maintenance overhead.
    </p>
    <h3>Creating an Index:</h3>
    <pre>
      <code>{`CREATE INDEX index_name ON table_name (column1, column2);`}</code>
    </pre>
    <h3>Example:</h3>
    <pre>
      <code>{`CREATE INDEX idx_employee_name ON employees (name);`}</code>
    </pre>
    <p>
      This command creates an index on the `name` column of the `employees` table, which can significantly speed up queries that search by employee name.
    </p>

    <h3>Dropping an Index:</h3>
    <pre>
      <code>{`DROP INDEX index_name;`}</code>
    </pre>
    <p>
      You can remove an index when it's no longer needed using the above command.
    </p><br />

    <h2>3. Working with Sequences</h2>
    <p>
      A <strong>sequence</strong> is a database object that generates unique numeric values, often used for primary keys.
    </p>
    <h3>Creating a Sequence:</h3>
    <pre>
      <code>{`CREATE SEQUENCE sequence_name
START WITH initial_value
INCREMENT BY increment_value;`}</code>
    </pre>
    <h3>Example:</h3>
    <pre>
      <code>{`CREATE SEQUENCE emp_id_seq
START WITH 1
INCREMENT BY 1;`}</code>
    </pre>
    <p>
      This command creates a sequence named `emp_id_seq` that starts at 1 and increments by 1 for each new value generated.
    </p>

    <h3>Using a Sequence:</h3>
    <pre>
      <code>{`SELECT emp_id_seq.NEXTVAL FROM dual;`}</code>
    </pre>
    <p>
      You can retrieve the next value from the sequence using the above command. This value can be used for inserting new rows.
    </p><br />

    <h2>4. Best Practices for Working with Views, Indexes, and Sequences</h2>
    <ul>
      <li><strong>Use Views for Security:</strong> Use views to restrict access to sensitive data while providing necessary information to users.</li><br />
      <li><strong>Index Selectively:</strong> Create indexes on columns frequently used in WHERE clauses to improve query performance.</li><br />
      <li><strong>Manage Sequences Wisely:</strong> Ensure sequences are used appropriately to maintain data integrity and avoid duplicates.</li>
    </ul><br />

    <h2>Conclusion</h2>
    <p>
      Working with views, indexes, and sequences is essential for enhancing data access and integrity in Oracle databases. By understanding how to create and manage these objects, you can optimize performance and streamline database operations.
    </p>
  </div>
)}



{selectedChapter === 'chapter32' && (
<div className={style.chaptercontent}>
    <h1 className={style.heading}> Materialized Views and Their Uses</h1>

    <p>
      A <strong>materialized view</strong> is a database object that contains the results of a query. It is a snapshot of the data at a specific point in time, which can be refreshed to reflect changes in the underlying tables. Materialized views are particularly useful for improving performance and optimizing query execution in Oracle databases.
    </p><br />

    <h2>1. What is a Materialized View?</h2>
    <p>
      Unlike a regular view, which retrieves data dynamically from the base tables every time it is queried, a materialized view stores the result set physically in the database. This allows for faster access to data, especially in scenarios where complex queries are involved.
    </p><br />

    <h2>2. Creating a Materialized View</h2>
    <p>
      You can create a materialized view using the following syntax:
    </p>
    <pre>
      <code>{`CREATE MATERIALIZED VIEW view_name AS
SELECT column1, column2
FROM table_name
WHERE condition;`}</code>
    </pre>
    <h3>Example:</h3>
    <pre>
      <code>{`CREATE MATERIALIZED VIEW sales_summary AS
SELECT product_id, SUM(sales_amount) AS total_sales
FROM sales
GROUP BY product_id;`}</code>
    </pre>
    <p>
      This command creates a materialized view named `sales_summary` that aggregates total sales by product.
    </p><br />

    <h2>3. Refreshing a Materialized View</h2>
    <p>
      Since materialized views store a snapshot of data, they need to be refreshed to reflect changes in the underlying tables. You can refresh a materialized view using:
    </p>
    <pre>
      <code>{`EXEC DBMS_MVIEW.REFRESH('view_name');`}</code>
    </pre>
    <p>
      You can also set up automatic refresh intervals or define refresh methods (complete, fast, or force) when creating the view.
    </p>

    <h3>Example of Automatic Refresh:</h3>
    <pre>
      <code>{`CREATE MATERIALIZED VIEW sales_summary
REFRESH FAST ON COMMIT AS
SELECT product_id, SUM(sales_amount) AS total_sales
FROM sales
GROUP BY product_id;`}</code>
    </pre>
    <p>
      This example creates a materialized view that refreshes automatically whenever a commit occurs in the `sales` table.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>4. Benefits of Using Materialized Views</h2>
    <ul>
      <li><strong>Performance Improvement:</strong> Materialized views can significantly reduce query response times for complex aggregations and joins.</li><br />
      <li><strong>Data Consolidation:</strong> They allow for consolidating data from multiple tables into a single object, simplifying reporting and analysis.</li><br />
      <li><strong>Resource Optimization:</strong> By storing pre-computed results, they reduce the load on the underlying tables, improving overall system performance.</li>
    </ul><br />

    <h2 style={{paddingBottom:"6px"}}>5. Use Cases for Materialized Views</h2>
    <ul>
      <li><strong>Reporting:</strong> Materialized views are commonly used in reporting scenarios where aggregate data is needed frequently.</li><br />
      <li><strong>Data Warehousing:</strong> They are essential in data warehousing environments where data is extracted, transformed, and loaded for analysis.</li><br />
      <li><strong>Performance Tuning:</strong> In high-transaction environments, materialized views can help mitigate performance issues caused by heavy querying of base tables.</li>
    </ul><br />

    <h2>Conclusion</h2>
    <p>
      Materialized views are powerful tools in Oracle databases that enhance performance and facilitate efficient data management. By leveraging materialized views, database administrators can optimize query performance, streamline reporting, and effectively manage data across complex database environments.
    </p>
  </div>
)}










{selectedChapter === 'chapter33' && (
<div className={style.chaptercontent}>
    <h1 className={style.heading}>Bulk Data Processing: BULK COLLECT and FORALL</h1>

    <p>
      Bulk data processing in Oracle enables efficient handling of large datasets by reducing the context switching between the SQL and PL/SQL engines. The primary features for bulk processing are <strong>BULK COLLECT</strong> and <strong>FORALL</strong>, which enhance performance when working with collections.
    </p><br />

    <h2>1. What is BULK COLLECT?</h2>
    <p>
      <strong>BULK COLLECT</strong> is used to fetch multiple rows from a query into a collection (such as an array) in a single context switch. This approach minimizes the overhead of context switching, leading to improved performance, especially when processing large amounts of data.
    </p>
    
    <h3>Example of BULK COLLECT:</h3>
    <pre>
      <code>{`DECLARE
  TYPE emp_rec IS RECORD (
    emp_id     NUMBER,
    emp_name   VARCHAR2(100)
  );

  TYPE emp_tab IS TABLE OF emp_rec INDEX BY PLS_INTEGER;
  employees emp_tab;

BEGIN
  SELECT emp_id, emp_name
  BULK COLLECT INTO employees
  FROM employees_table
  WHERE department_id = 10;

  FOR i IN 1 .. employees.COUNT LOOP
    DBMS_OUTPUT.PUT_LINE('ID: ' || employees(i).emp_id || ', Name: ' || employees(i).emp_name);
  END LOOP;
END;`}</code>
    </pre>
    <p>
      In this example, the <code>BULK COLLECT</code> statement retrieves all employees in department 10 into the <code>employees</code> collection.
    </p><br />

    <h2>2. What is FORALL?</h2>
    <p>
      <strong>FORALL</strong> is used to perform DML operations (INSERT, UPDATE, DELETE) on all elements of a collection in a single context switch. This improves performance by batching DML operations instead of executing them one at a time.
    </p>

    <h3>Example of FORALL:</h3>
    <pre>
      <code>{`DECLARE
  TYPE emp_tab IS TABLE OF employees_table%ROWTYPE;
  employees emp_tab;

BEGIN
  -- Assume employees collection is populated
  employees(1).emp_id := 101;
  employees(1).emp_name := 'John Doe';

  employees(2).emp_id := 102;
  employees(2).emp_name := 'Jane Smith';

  -- Using FORALL to insert data
  FORALL i IN 1 .. employees.COUNT
    INSERT INTO employees_table (emp_id, emp_name) VALUES (employees(i).emp_id, employees(i).emp_name);

  COMMIT;
END;`}</code>
    </pre>
    <p>
      In this example, the <code>FORALL</code> statement is used to insert multiple employee records into the <code>employees_table</code> in one go, which is more efficient than inserting each record individually.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>3. Benefits of BULK COLLECT and FORALL</h2>
    <ul>
      <li><strong>Performance Improvement:</strong> Reduces the number of context switches between SQL and PL/SQL engines, leading to faster execution times.</li><br />
      <li><strong>Resource Efficiency:</strong> Minimizes resource consumption by processing data in bulk rather than one row at a time.</li><br />
      <li><strong>Simplified Code:</strong> Allows for cleaner and more concise code by handling multiple rows in a single operation.</li>
    </ul><br />

    <h2 style={{paddingBottom:"6px"}} >4. Best Practices</h2>
    <ul>
      <li>Use BULK COLLECT to minimize the number of fetches when retrieving data.</li><br />
      <li>Combine BULK COLLECT with FORALL for efficient DML operations on collections.</li><br />
      <li>Handle exceptions appropriately to manage errors that may occur during bulk processing.</li>
    </ul><br />

    <h2>Conclusion</h2>
    <p>
      BULK COLLECT and FORALL are powerful features in Oracle that significantly enhance the performance of bulk data processing. By leveraging these constructs, developers can efficiently handle large datasets while minimizing resource consumption and execution time.
    </p>
  </div>
)}





{selectedChapter === 'chapter34' && (
<div className={style.chaptercontent}>
    <h1 className={style.heading}>Performance Optimization Techniques</h1>

    <p>
      Optimizing performance when handling large datasets in Oracle is essential for maintaining efficient database operations. This section covers various techniques and best practices to enhance performance during data processing and manipulation.
    </p><br />

    <h2>1. Understanding Performance Bottlenecks</h2>
    <p>
      Before applying optimization techniques, it's crucial to identify the areas where performance bottlenecks occur. Common sources of bottlenecks include:
    </p>
    <ul>
      <li>Slow SQL queries</li><br />
      <li>High resource usage (CPU, memory, I/O)</li><br />
      <li>Contention for locks or resources</li>
    </ul><br />

    <h2>2. Optimizing SQL Queries</h2>
    <p>
      Efficient SQL query design is a key factor in performance optimization. Techniques include:
    </p>
    <ul>
      <li><strong>Use of Indexes:</strong> Proper indexing can significantly speed up data retrieval. Ensure that the appropriate indexes are in place for frequently queried columns.</li><br />
      <li><strong>Query Refactoring:</strong> Rewrite complex queries to simplify them, breaking them into smaller, more manageable parts if necessary.</li><br />
      <li><strong>Statistics Gathering:</strong> Regularly gather statistics to help the optimizer make informed decisions about query execution plans.</li>
    </ul>

    <h3>Example of Indexing:</h3>
    <pre>
      <code>{`CREATE INDEX idx_emp_name ON employees_table(emp_name);`}</code>
    </pre>
    <p>
      This command creates an index on the <code>emp_name</code> column, which can improve the performance of queries filtering by employee names.
    </p><br />

    <h2>3. Efficient Data Access Methods</h2>
    <p>
      Choosing the right method for data access can also enhance performance:
    </p>
    <ul>
      <li><strong>BULK COLLECT and FORALL:</strong> As discussed earlier, these techniques reduce context switches and improve DML operations on collections.</li><br />
      <li><strong>Using PL/SQL:</strong> Utilize PL/SQL for batch processing to minimize the number of SQL calls, especially for large datasets.</li>
    </ul><br />

    <h2>4. Partitioning Large Tables</h2>
    <p>
      Table partitioning helps in managing large datasets more efficiently by dividing a large table into smaller, more manageable pieces:
    </p>
    <ul>
      <li><strong>Range Partitioning:</strong> Divide data based on a range of values (e.g., dates).</li><br />
      <li><strong>List Partitioning:</strong> Separate data based on discrete values (e.g., department IDs).</li>
    </ul>
    <p>
      Partitioning can improve query performance and make maintenance tasks more efficient.
    </p><br />

    <h2>5. Monitoring and Tuning Performance</h2>
    <p>
      Continuous monitoring and tuning are necessary to maintain optimal performance. Use tools like:
    </p>
    <ul>
      <li><strong>Automatic Workload Repository (AWR):</strong> Analyze performance data and trends over time.</li><br />
      <li><strong>Oracle Enterprise Manager (OEM):</strong> Monitor database performance and receive alerts for critical metrics.</li>
    </ul><br />

    <h2>6. Best Practices for Performance Optimization</h2>
    <ul>
      <li>Regularly review and optimize SQL queries.</li><br />
      <li>Utilize appropriate indexing strategies.</li><br />
      <li>Monitor performance metrics to identify and address bottlenecks proactively.</li><br />
      <li>Implement partitioning strategies for large tables.</li>
    </ul><br />

    <h2>Conclusion</h2>
    <p>
      Performance optimization techniques are crucial when handling large datasets in Oracle. By applying these strategies, database administrators and developers can enhance the efficiency and speed of data processing, leading to improved overall database performance.
    </p>
  </div>
)}



{selectedChapter === 'chapter35' && (
<div className={style.chaptercontent}>
    <h1 className={style.heading}>Using Oracle Call Interface (OCI) </h1>


    <p>
      The Oracle Call Interface (OCI) is a powerful and low-level API that allows applications written in C and C++ to communicate with Oracle databases. OCI provides a comprehensive set of functions to manage database connections, execute SQL statements, and handle results.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>1. Key Features of OCI</h2>
    <ul>
      <li><strong>Performance:</strong> OCI is optimized for high performance, making it suitable for applications that require efficient database interactions.</li><br />
      <li><strong>Flexibility:</strong> Offers detailed control over database operations, allowing developers to manage connections and sessions effectively.</li><br />
      <li><strong>Support for PL/SQL:</strong> Directly call PL/SQL procedures and functions, facilitating complex business logic execution within the database.</li>
    </ul><br />

    <h2>2. Setting Up OCI</h2>
    <p>
      To use OCI, you'll need to ensure that the Oracle Instant Client is installed on your machine. After installation, include the necessary OCI headers and link against the OCI library in your C/C++ project.
    </p>

    <h3 style={{paddingBottom:"6px"}}>Installation Steps:</h3>
    <ol>
      <li>Download and install the Oracle Instant Client from the Oracle website.</li><br />
      <li>Set your <code>PATH</code> environment variable to include the directory where the Instant Client is installed.</li><br />
      <li>Include OCI header files in your source code.</li><br />
      <li>Link against the OCI library during compilation.</li>
    </ol><br />

    <h2>3. Basic OCI Example</h2>
    <p>
      Here's a simple example demonstrating how to connect to an Oracle database and execute a SQL query using OCI:
    </p>
    <pre>
      <code>{`#include <stdio.h>
#include <stdlib.h>
#include <oci.h>

void checkError(OCIError *errhp, sword status) {
    if (status != OCI_SUCCESS) {
        text errbuf[512];
        sb4 errcode = 0;
        OCIErrorGet(errhp, (ub4*)&errcode, NULL, errbuf, (ub4)sizeof(errbuf));
        printf("Error - Code: %d, Message: %s\\n", errcode, errbuf);
        exit(1);
    }
}

int main() {
    OCIEnv *envhp;
    OCIError *errhp;
    OCIServiceCtx *srvhp;
    OCIServer *srvhp;
    OCIHandleAlloc((dvoid*)0, (dvoid**)&envhp, OCI_HTYPE_ENV, 0, (dvoid**)0);
    OCIHandleAlloc(envhp, (dvoid**)&errhp, OCI_HTYPE_ERROR, 0, (dvoid**)0);

    // Initialize and log on to the Oracle server
    OCIHandleAlloc(envhp, (dvoid**)&srvhp, OCI_HTYPE_SERVER, 0, (dvoid**)0);
    OCIHandleAlloc(envhp, (dvoid**)&srvhp, OCI_HTYPE_SVCCTX, 0, (dvoid**)0);
    OCIServerAttach(srvhp, errhp, (text*)"//localhost:1521/XE", strlen("//localhost:1521/XE"), 0);
    
    // Your SQL execution code goes here
    
    OCIServerDetach(srvhp, errhp, 0);
    OCIHandleFree(srvhp, OCI_HTYPE_SERVER);
    OCIHandleFree(errhp, OCI_HTYPE_ERROR);
    OCIHandleFree(envhp, OCI_HTYPE_ENV);
    return 0;
}`}</code>
    </pre><br />

    <h2 style={{paddingBottom:"6px"}}>4. Common OCI Functions</h2>
    <ul>
      <li><strong>OCIEnvCreate:</strong> Initializes the OCI environment.</li><br />
      <li><strong>OCIHandleAlloc:</strong> Allocates an OCI handle for various data structures.</li><br />
      <li><strong>OCIServerAttach:</strong> Connects to the Oracle server.</li><br />
      <li><strong>OCIStmtPrepare:</strong> Prepares a SQL statement for execution.</li><br />
      <li><strong>OCIStmtExecute:</strong> Executes the prepared SQL statement.</li><br />
      <li><strong>OCIServerDetach:</strong> Disconnects from the Oracle server.</li><br />
      <li><strong>OCIHandleFree:</strong> Frees the allocated OCI handle.</li>
    </ul><br />

    <h2>5. Conclusion</h2>
    <p>
      The Oracle Call Interface (OCI) provides a flexible and high-performance way to interact with Oracle databases using C and C++. By leveraging OCI's capabilities, developers can build robust applications that efficiently manage database operations.
    </p>
  </div>
)}



  {selectedChapter === 'chapter36' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Integrating with Java, .NET, and Other Programming Languages</h1>

    <p>
      Oracle provides a variety of APIs and libraries for integrating with different programming languages such as Java, .NET, Python, and more. This flexibility allows developers to interact with Oracle databases effectively, leveraging the strengths of their preferred programming languages.
    </p><br />

    <h2>1. Overview of Oracle Database Connectivity</h2>
    <p>
      Connecting to an Oracle database can be done using various methods, each suited for different programming environments:
    </p>
    <ul>
      <li><strong>JDBC (Java Database Connectivity)</strong>: A standard Java API for connecting to relational databases, including Oracle.</li><br />
      <li><strong>ODP.NET (Oracle Data Provider for .NET)</strong>: A set of .NET libraries for building applications that connect to Oracle databases.</li><br />
      <li><strong>OCI (Oracle Call Interface)</strong>: A low-level API for C and C++ applications.</li><br />
      <li><strong>Python cx_Oracle</strong>: A Python library that enables access to Oracle databases.</li>
    </ul><br />

    <h2>2. Integrating with Java using JDBC</h2>
    <p>
      JDBC is the primary means for Java applications to interact with Oracle databases. Here’s a basic example of how to set up a JDBC connection:
    </p>
    <pre>
      <code>{`import java.sql.Connection;
import java.sql.DriverManager;
import java.sql.SQLException;

public class OracleJDBCExample {
    public static void main(String[] args) {
        String jdbcUrl = "jdbc:oracle:thin:@//localhost:1521/orclpdb";
        String username = "your_username";
        String password = "your_password";

        try (Connection connection = DriverManager.getConnection(jdbcUrl, username, password)) {
            System.out.println("Connected to Oracle Database!");
        } catch (SQLException e) {
            e.printStackTrace();
        }
    }
}`}</code>
    </pre>
    <p>
      This code snippet demonstrates how to connect to an Oracle database using JDBC.
    </p><br />

    <h2>3. Integrating with .NET using ODP.NET</h2>
    <p>
      ODP.NET allows .NET applications to interact with Oracle databases. Here's an example of a simple connection:
    </p>
    <pre>
      <code>{`using Oracle.ManagedDataAccess.Client;

public class ODPNetExample {
    public static void Main(string[] args) {
        string connectionString = "User Id=your_username;Password=your_password;Data Source=localhost:1521/orclpdb;";

        using (OracleConnection connection = new OracleConnection(connectionString)) {
            connection.Open();
            Console.WriteLine("Connected to Oracle Database!");
        }
    }
}`}</code>
    </pre><br />

    <h2>4. Integrating with Python using cx_Oracle</h2>
    <p>
      The cx_Oracle library enables Python applications to connect to Oracle databases. Here’s how to set it up:
    </p>
    <pre>
      <code>{`import cx_Oracle

dsn_tns = cx_Oracle.makedsn('localhost', '1521', service_name='orclpdb')
connection = cx_Oracle.connect(user='your_username', password='your_password', dsn=dsn_tns)

print("Connected to Oracle Database!")
connection.close()`}</code>
    </pre><br />

    <h2>5. Error Handling and Best Practices</h2>
    <p>
      When integrating with Oracle databases, error handling is crucial. Make sure to:
    </p>
    <ul>
      <li>Implement proper exception handling in your code to catch database-related errors.</li><br />
      <li>Use connection pooling to manage database connections efficiently.</li><br />
      <li>Always close database connections to free up resources.</li>
    </ul><br />

    <h2>6. Conclusion</h2>
    <p>
      Integrating Oracle databases with various programming languages is straightforward thanks to the robust APIs available. By leveraging JDBC, ODP.NET, and cx_Oracle, developers can build applications that interact seamlessly with Oracle databases, optimizing their functionality and performance.
    </p>
  </div>
)}





{selectedChapter === 'chapter37' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Real-world Application and Scenarios</h1>

    <p>
      In real-world applications, Node.js is widely used for building efficient and scalable server-side applications, especially for handling asynchronous I/O operations. Here are some practical use cases and scenarios where Node.js shines:
    </p><br />

    <h3 style={{paddingBottom:"6px"}}>1. <strong>RESTful APIs and Microservices</strong></h3>
    <p><strong>Scenario:</strong> A company wants to develop a microservice architecture to handle user authentication, data processing, and notifications.</p><br />
    <p><strong>Application:</strong> Node.js is ideal for building RESTful APIs due to its non-blocking, event-driven nature. Microservices can handle tasks like user management, authentication, and payment processing by running as separate services that can scale independently.</p><br />
    <p><strong>Example:</strong> A Node.js application might serve a REST API that interacts with a database, returning JSON data to the frontend for a single-page application (SPA).</p><br />

    <h3 style={{paddingBottom:"6px"}}>2. <strong>Real-Time Chat Applications</strong></h3>
    <p><strong>Scenario:</strong> A social networking platform requires real-time communication between users, with features like messaging, typing indicators, and presence updates.</p><br />
    <p><strong>Application:</strong> Node.js, with libraries like Socket.io, excels at real-time applications that require continuous communication between the client and server.</p><br />
    <p><strong>Example:</strong> A chat app where multiple users can join different rooms and send messages in real-time.</p><br />

    <h3 style={{paddingBottom:"6px"}}>3. <strong>Streaming Applications</strong></h3>
    <p><strong>Scenario:</strong> A video-streaming platform wants to serve content to millions of users while efficiently managing bandwidth.</p><br />
    <p><strong>Application:</strong> Node.js is suited for media streaming applications because it can process files while they are being uploaded or downloaded, thanks to its native stream API.</p><br />
    <p><strong>Example:</strong> Video-on-demand platforms like Netflix, which serve large video files in chunks to users as they stream content.</p><br />

    <h3 style={{paddingBottom:"6px"}}>4. <strong>E-Commerce Websites</strong></h3>
    <p><strong>Scenario:</strong> An online store needs a robust and scalable platform that can handle high traffic volumes and provide dynamic content to users.</p><br />
    <p><strong>Application:</strong> Node.js is effective for building scalable and high-performance e-commerce platforms, where dynamic pricing, real-time user actions, and stock updates are handled seamlessly.</p><br />
    <p><strong>Example:</strong> An e-commerce website where Node.js handles the backend operations like user sessions, inventory updates, and order management.</p><br />

    <h3 style={{paddingBottom:"6px"}}>5. <strong>Internet of Things (IoT)</strong></h3>
    <p><strong>Scenario:</strong> A company is developing a network of sensors to monitor environmental conditions in real-time, requiring fast data transmission and analysis.</p><br />
    <p><strong>Application:</strong> Node.js can handle multiple devices and concurrent connections with ease, making it suitable for IoT applications where large amounts of data are transmitted from various sources.</p><br />
    <p><strong>Example:</strong> A smart home system where devices like thermostats, lights, and security cameras communicate through a central Node.js server.</p><br />

    <h3 style={{paddingBottom:"6px"}}>6. <strong>Collaborative Tools</strong></h3>
    <p><strong>Scenario:</strong> A company needs a collaborative platform where users can edit documents, whiteboards, or design files in real-time.</p><br />
    <p><strong>Application:</strong> Node.js, in combination with WebSockets, allows multiple users to collaborate in real time, syncing changes immediately across clients.</p><br />
    <p><strong>Example:</strong> Collaborative text editors like Google Docs or shared task management tools like Trello, where updates are visible to all users in real-time.</p><br />

    <h3 style={{paddingBottom:"6px"}}>7. <strong>Single Page Applications (SPAs)</strong></h3>
    <p><strong>Scenario:</strong> A company wants to build a highly interactive web application, such as a dashboard, that updates dynamically without reloading the page.</p><br />
    <p><strong>Application:</strong> Node.js works effectively as a backend to serve the API required by a SPA, often built using front-end frameworks like React.js or Angular. The non-blocking nature ensures fast and smooth data handling.</p><br />
    <p><strong>Example:</strong> An analytics dashboard where data is updated in real-time as users interact with the interface.</p><br />

    <h3 style={{paddingBottom:"6px"}}>8. <strong>Command-Line Tools</strong></h3>
    <p><strong>Scenario:</strong> A company needs a command-line interface (CLI) to automate tasks like deployment or continuous integration.</p><br />
    <p><strong>Application:</strong> Node.js can be used to create powerful CLI tools by leveraging libraries like <code>commander</code> or <code>inquirer</code>. These tools automate repetitive tasks like code linting, testing, or deployment.</p><br />
    <p><strong>Example:</strong> A CLI tool to deploy web applications to cloud services or automate Git operations in a development workflow.</p><br />

    <h3 style={{paddingBottom:"6px"}}>9. <strong>Proxy Servers</strong></h3>
    <p><strong>Scenario:</strong> A company wants to create a proxy server to handle multiple requests while keeping the main application secure.</p><br />
    <p><strong>Application:</strong> Node.js can be used to build lightweight proxy servers to handle traffic, add caching, or load balance requests across multiple servers.</p><br />
    <p><strong>Example:</strong> A reverse proxy that forwards requests to different microservices or APIs, providing caching for static resources to improve performance.</p><br />

    <h3 style={{paddingBottom:"6px"}}>10. <strong>Data-Intensive Real-Time Applications</strong></h3>
    <p><strong>Scenario:</strong> A financial services company needs an application that monitors stock prices, updating users in real-time with minimal latency.</p><br />
    <p><strong>Application:</strong> Node.js, with its non-blocking I/O and event-driven architecture, can efficiently handle real-time data streams and notifications.</p><br />
    <p><strong>Example:</strong> A stock trading platform that pushes updates on price changes to traders in real-time.</p><br />

    <p>
      These scenarios showcase the versatility of Node.js in building modern, scalable, and high-performance applications across various industries. Its ability to handle concurrent operations efficiently makes it an excellent choice for applications that require real-time updates or involve heavy I/O operations.
    </p>
  </div>
)}




{selectedChapter === 'chapter38' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Hands-on Projects to Reinforce Learning in Node.js</h1>

    <p>
      Hands-on projects are a great way to reinforce your learning in Node.js. Here are some practical project ideas that can help solidify key concepts such as APIs, database integration, real-time communication, file handling, and more.
    </p><br />

    <h3 style={{paddingBottom:"6px"}}>1. <strong>Build a RESTful API</strong></h3>
    <p><strong>Skills Practiced:</strong> Creating endpoints, handling HTTP methods (GET, POST, PUT, DELETE), request and response handling.</p><br />
    <p><strong>Project:</strong> Build a simple RESTful API for managing a collection of resources, like a <em>To-Do List</em>, <em>Blog Posts</em>, or <em>User Profiles</em>.</p><br />
    <ul>
      <li>CRUD operations (Create, Read, Update, Delete).</li><br />
      <li>Use Express.js to set up the routes.</li><br />
      <li>Store data in a file (for beginners) or connect to a database like MongoDB.</li><br />
      <li>Add validation using a package like <code>joi</code>.</li>
    </ul><br />

    <h3 style={{paddingBottom:"6px"}}>2. <strong>Real-Time Chat Application</strong></h3>
    <p><strong>Skills Practiced:</strong> WebSockets, real-time communication, user management.</p><br />
    <p><strong>Project:</strong> Create a chat application where users can join chat rooms and send messages in real time.</p>
    <ul>
      <li>Use <code>Socket.io</code> to manage real-time communication between users.</li><br />
      <li>Create different chat rooms where users can join and leave.</li><br />
      <li>Show notifications when users are typing or when new users join the chat.</li><br />
      <li>Store chat history in a database like MongoDB.</li>
    </ul><br />

    <h3 style={{paddingBottom:"6px"}}>3. <strong>URL Shortener</strong></h3>
    <p><strong>Skills Practiced:</strong> Database operations, URL routing, API creation.</p><br />
    <p><strong>Project:</strong> Build a simple URL shortener service similar to Bit.ly.</p><br />
    <ul>
      <li>Users can input a long URL, and the application generates a shorter version.</li><br />
      <li>Redirect users to the original URL when they visit the shortened URL.</li><br />
      <li>Implement basic analytics, like tracking the number of times a short link is visited.</li><br />
      <li>Use a database like MongoDB to store the URL mappings.</li>
    </ul><br />

    <h3 style={{paddingBottom:"6px"}}>4. <strong>Weather Application (Using External APIs)</strong></h3>
    <p><strong>Skills Practiced:</strong> API consumption, working with third-party services, asynchronous programming.</p><br />
    <p><strong>Project:</strong> Create an application that fetches real-time weather data for a location.</p><br />
    <ul>
      <li>Use an external weather API (like OpenWeatherMap) to get weather information.</li><br />
      <li>Build an API that accepts a city name and returns weather details.</li><br />
      <li>Display current weather, forecast, and additional data like humidity, wind speed, etc.</li><br />
      <li>Add error handling for invalid city names or failed API requests.</li>
    </ul><br />

    <h3 style={{paddingBottom:"6px"}}>5. <strong>Task Management App</strong></h3>
    <p><strong>Skills Practiced:</strong> Full-stack development, user authentication, session management.</p><br />
    <p><strong>Project:</strong> Build a task management or <em>To-Do</em> app where users can create and manage their tasks.</p><br />
    <ul>
      <li>Allow users to sign up, log in, and manage their tasks.</li><br />
      <li>Use <code>JWT</code> (JSON Web Token) for authentication.</li><br />
      <li>Allow users to create, update, and delete tasks.</li><br />
      <li>Implement priority levels or deadlines for each task.</li><br />
      <li>Use MongoDB for storing users and tasks.</li>
    </ul><br />

    <h3 style={{paddingBottom:"6px"}}>6. <strong>File Uploader</strong></h3>
    <p><strong>Skills Practiced:</strong> File handling, data validation, security best practices.</p><br />
    <p><strong>Project:</strong> Create an application that allows users to upload and manage files (images, documents, etc.).</p><br />
    <ul>
      <li>Use <code>multer</code> middleware to handle file uploads in Node.js.</li><br />
      <li>Restrict file types (e.g., only allow images or PDFs).</li><br />
      <li>Store the files in a folder on the server and keep references in a database.</li><br />
      <li>Implement user authentication so that users can upload and view only their files.</li><br />
      <li>Optionally, upload files to a cloud service like AWS S3.</li>
    </ul><br />

    <h3 style={{paddingBottom:"6px"}}>7. <strong>Blog Platform with Markdown Support</strong></h3>
    <p><strong>Skills Practiced:</strong> Full-stack development, templating engines, content management.</p><br />
    <p><strong>Project:</strong> Build a simple blog platform where users can write and publish blog posts using Markdown.</p><br />
    <ul>
      <li>Create an interface for writing blog posts in Markdown.</li><br />
      <li>Convert Markdown to HTML using a library like <code>marked</code>.</li><br />
      <li>Implement user authentication so that only logged-in users can create or edit posts.</li><br />
      <li>Store posts in a MongoDB or MySQL database.</li><br />
      <li>Display a list of posts on the main page, with individual post pages.</li>
    </ul><br />

    <h3 style={{paddingBottom:"6px"}}>8. <strong>E-commerce Platform</strong></h3>
    <p><strong>Skills Practiced:</strong> Handling payments, CRUD operations, authentication, database integration.</p><br />
    <p><strong>Project:</strong> Build a basic e-commerce website where users can browse products and make purchases.</p><br />
    <ul>
      <li>Create product listings with categories, prices, and descriptions.</li><br />
      <li>Allow users to add products to a shopping cart.</li><br />
      <li>Implement user authentication so that users can sign up and log in.</li><br />
      <li>Use Stripe or PayPal for handling payments.</li><br />
      <li>Store user orders and product details in a database.</li>
    </ul><br />

    <h3 style={{paddingBottom:"6px"}}>9. <strong>Real-Time Collaborative Whiteboard</strong></h3>
    <p><strong>Skills Practiced:</strong> Real-time communication, WebSockets, collaboration tools.</p><br />
    <p><strong>Project:</strong> Build a real-time collaborative whiteboard where multiple users can draw and write at the same time.</p><br />
    <ul>
      <li>Use <code>Socket.io</code> to synchronize the drawing between users in real-time.</li><br />
      <li>Implement different colors and brush sizes for the drawing tool.</li><br />
      <li>Allow users to join different rooms to collaborate on separate boards.</li><br />
      <li>Optionally, store the final board as an image file on the server.</li>
    </ul><br />

    <h3  style={{paddingBottom:"6px"}}>10. <strong>Social Media API</strong></h3>
    <p><strong>Skills Practiced:</strong> Building complex APIs, relationships between data, authentication, pagination.</p><br />
    <p><strong>Project:</strong> Build a backend API for a social media platform where users can follow each other, post content, and interact.</p><br />
    <ul>
      <li>Implement endpoints for user signup, login, following/unfollowing users.</li><br />
      <li>Allow users to post text, images, or links, and to comment on and like posts.</li><br />
      <li>Use pagination to handle large amounts of data, such as posts and user lists.</li><br />
      <li>Add JWT-based authentication to protect user data.</li>
    </ul><br />

    <h3 style={{paddingBottom:"6px"}}>11. <strong>Polling/Voting App</strong></h3>
    <p><strong>Skills Practiced:</strong> Database management, API creation, user interactions.</p><br />
    <p><strong>Project:</strong> Build an application where users can create polls and vote on them.</p><br />
    <ul>
      <li>Users can create a poll with multiple options.</li><br />
      <li>Other users can vote on a poll, with results displayed in real-time.</li><br />
      <li>Use MongoDB or another database to store polls and votes.</li><br />
      <li>Implement user authentication for poll creation and tracking of who voted.</li>
    </ul><br /> 

    <h3 style={{paddingBottom:"6px"}}>12. <strong>Expense Tracker</strong></h3>
    <p><strong>Skills Practiced:</strong> Data handling, CRUD operations, database integration.</p><br />
    <p><strong>Project:</strong> Build an expense tracker that helps users manage their expenses and incomes.</p><br />
    <ul>
      <li>Users can add, edit, and delete expense and income entries.</li><br />
      <li>Display an overview of the user’s financial status with categories (food, entertainment, etc.).</li><br />
      <li>Use MongoDB to store users and their financial records.</li><br />
      <li>Implement basic authentication for tracking multiple users.</li>
    </ul><br />

    <p>These projects will help you master various aspects of Node.js, including APIs, databases, real-time communication, file handling, and authentication. You can start simple and progressively add more features to each project as your knowledge and skills grow.</p>
  </div>
)}



                </div>
            </div>
        </div>

    );

}


export default Nodejsdata;


