import React, { useState, useEffect, useRef } from 'react';
import style from './../Css/Services.module.css';

const Services = () => {
    const [selectedService, setSelectedService] = useState(null);
    const servicesRef = useRef(null); // Reference to the services list container

    const services = [
        { title: 'Live Classes', description: 'Our live, instructor-led classes provide an engaging and interactive learning environment where you can directly interact with experienced professionals. With real-time Q&A, collaborative discussions, and immediate feedback, our live sessions ensure you gain a deep understanding of the subject matter while staying connected with your peers and instructors.' },
        { title: 'Comprehensive Courses', description: 'We offer a wide range of expertly designed courses to cater to various skill levels and industries. From foundational knowledge to specialized expertise, our courses are structured to provide practical skills and industry-relevant insights, empowering you to excel in your career. Whether you are looking to upskill or explore a new field, our curriculum is tailored to meet your needs.' },
        { title: 'Internship Programs', description: 'Our internship programs bridge the gap between theory and practice. By partnering with top companies, we provide you with hands-on experience in a professional environment, allowing you to apply your classroom learning in real-world scenarios. These internships not only enhance your skillset but also give you a competitive edge when entering the job market.' },
        { title: 'Job Placement Assistance', description: 'Our job placement assistance is designed to help you seamlessly transition from education to employment. With a network of industry-leading companies, we connect you with job opportunities that match your skills and career goals. From personalized resume crafting to mock interviews and career counseling, we support you every step of the way to ensure you are prepared for success in the workforce.' },
        { title: 'Expert Instructors and Mentors', description: 'Learning from the best is key to your success. Our team of expert instructors and mentors brings years of industry experience to the classroom, providing invaluable insights, guidance, and support. Whether you are looking for academic advice or career guidance, our mentors are dedicated to helping you achieve your goals and unlock your full potential.' }
    ];

    const handleServiceClick = (index) => {
        setSelectedService(selectedService === index ? null : index);
    };

    // const handleMouseLeave = () => {
    //     setTimeout(() => setSelectedService(null), 100); 
    // };

    // Close description when clicking outside the services list
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (servicesRef.current && !servicesRef.current.contains(event.target)) {
                setSelectedService(null); 
            }
        };

        // Add event listener for clicks outside
        document.addEventListener('click', handleClickOutside);

       
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <section className={`${style.services} container-fluid`} id='Service_s'>
            <div className={`${style.servicesHeader} container`}>
                <img className={style.servicesimg} src="/Images/services-img.png" alt="" />
                <h4>OUR SERVICES</h4>
                <h2>
                    What <span>Services</span> We’re Offering
                </h2>
                <p>
                Integration IT Services <br />
                    We offer services that can help businesses improve their visibility and business reputation online,
                    expand market reach, and increase turnover through effective digital strategies. Following are the services we provide.
                </p>
                <div className={style.servicesList} ref={servicesRef}>
                    {services.map((service, index) => (
                        <div key={index}>
                            <div
                                className={`${style.serviceItem} ${selectedService === index ? style.serviceItemActive : ''}`}
                                onClick={() => handleServiceClick(index)} 
                                onMouseEnter={() => setSelectedService(index)} 
                                // onMouseLeave={handleMouseLeave} 
                            >
                                <h3>{service.title}</h3>
                                <span className={style.arrowIcon}> </span>
                            </div>
                            {selectedService === index && (
                                <div className={style.serviceDescription}>
                                    <p>{service.description}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Services;
