import React from 'react';
import style from './../Css/Policies.module.css';

const TermsOfService = () => {
  return (
    <div className='container px-1'>
    <div className={style.policy_container}>
      <h1 className={style.title}>Terms of Service</h1>
      <p className={style.date}>Last updated: 17-11-2024</p>

      <h2 className={style.section_title}>Welcome to iits code - IT Services & Learning Platform!</h2>
      <p className={style.text}>
        These terms and conditions outline the rules and regulations for the use of iits code's Website..
      </p>

      <p className={style.text}>
        By accessing this website, you agree to accept these terms and conditions. If you do not agree with any of the terms and conditions, please refrain from using our website.
      </p>

      <p className={style.text}>
      The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice, and all Agreements: “Client”, “You” and “Your” refers to you, the person logging on to this website and compliant with the Company’s terms and conditions. “The Company”, “Ourselves”, “We”, “Our” and “Us”, refers to our Company. “Party”, “Parties”, or “Us”, refers to both the Client and ourselves. All terms refer to the offer, acceptance, and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner for the express purpose of meeting the Client’s needs in respect of the provision of the Company’s stated services, in accordance with and subject to, prevailing law of India. Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to the same.
      </p>

      <h2 className={style.section_title}>Terminology:</h2>
      <p className={style.text}>
        The following terminology applies to these Terms and Conditions, Privacy Statement, Disclaimer Notice, and all Agreements: “Client”, “You” and “Your” refer to you, the person logging onto this website and complying with the Company’s terms and conditions. “The Company”, “We”, “Our” and “Us” refer to iits code. “Party”, “Parties” or “Us” refer to both the Client and ourselves. All terms refer to the offer, acceptance, and consideration of payment necessary to provide our services to the Client in the most appropriate manner, subject to the prevailing law of India. Any use of the above terminology, whether in singular or plural form, is taken as interchangeable.
      </p>

      <h2 className={style.section_title}>Cookies</h2>
      <p className={style.text}>
        We use cookies on our website. By accessing iits code, you agree to the use of cookies in accordance with our Privacy Policy.
      </p>
      <p className={style.text}>
        Cookies are used on interactive websites to retrieve the user’s details for each visit. This helps make it easier for users to navigate and access specific areas of the site. Some of our affiliate/advertising partners may also use cookies.
      </p>

      <h2 className={style.section_title}>License</h2>
      <p className={style.text}>
        Unless otherwise stated, iits code and/or its licensors own the intellectual property rights for all material on this website. All rights are reserved. You may access the material for personal use subject to the restrictions set in these terms and conditions.
      </p>
      <p className={style.text}>
        You must not:
      </p>
      <ul className={style.text}>
        <li>Republish any content from iits code.</li>
        <li>Sell, rent, or sub-license material from iits code.</li>
        <li>Reproduce, duplicate, or copy material from iits code.</li>
        <li>Redistribute content from iits code.</li>
      </ul>
      <p className={style.text}>
      Parts of this website offer an opportunity for users to post and exchange opinions and information in certain areas of the website. IITS CODE does not filter, edit, publish or review Comments prior to their presence on the website. Comments do not reflect the views and opinions of IITS CODE,its agents and/or affiliates. Comments reflect the views and opinions of the person who post their views and opinions. To the extent permitted by applicable laws, IITS CODE shall not be liable for the Comments or for any liability, damages or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this website.
      </p>

      <h2 className={style.section_title}>User Comments</h2>
      <p className={style.text}>
        Parts of this website provide an opportunity for users to post and exchange opinions in certain areas. We do not filter, edit, or review comments before they appear on the site. Comments reflect the views and opinions of the person posting them, not necessarily those of iits code, its agents, or affiliates.
      </p>
      <p className={style.text}>
        By posting comments, you confirm that:
      </p>
      <ul className={style.text}>
        <li>You have the necessary licenses to post comments.</li>
        <li>The comments do not infringe on any intellectual property rights.</li>
        <li>The comments are not defamatory, libelous, offensive, or illegal.</li>
        <li>You grant iits code a non-exclusive license to use, reproduce, edit, and authorize others to use your comments in any format or media.</li>
      </ul>

      <h2 className={style.section_title}>Hyperlinking to Our Content</h2>
      <p className={style.text}>
        The following organizations may link to our website without prior written approval:
      </p>
      <ul className={style.text}>
        <li>Government agencies.</li>
        <li>Search engines.</li>
        <li>News organizations.</li>
        <li>Online directory distributors.</li>
        <li>Accredited businesses (excluding non-profit organizations).</li>
      </ul>
      <p className={style.text}>
        We may approve other link requests from organizations like consumer information sources, online communities, educational institutions, etc. All links must not mislead, falsely imply sponsorship, or suggest endorsement.
      </p>

      <h2 className={style.section_title}>iFrames</h2>
      <p className={style.text}>
        Without written consent, you may not frame our webpages to alter the visual presentation or appearance of the website.
      </p>

      <h2 className={style.section_title}>Content Liability</h2>
      <p className={style.text}>
        We are not responsible for any content that appears on your website. You agree to defend and protect us from any claims arising from your site. Any links to our site must not be interpreted as libelous, obscene, or criminal, or infringe on third-party rights.
      </p>

      <h2 className={style.section_title}>Your Privacy</h2>
      <p className={style.text}>
        Please read our Privacy Policy for more information on how we handle your personal data.
      </p>

      <h2 className={style.section_title}>Reservation of Rights</h2>
      <p className={style.text}>
        We reserve the right to request that you remove all links to our website. If we ask, you must comply. We may also amend these terms and conditions at any time. By continuing to link to our website, you agree to adhere to the updated terms.
      </p>

      <h2 className={style.section_title}>Removal of Links</h2>
      <p className={style.text}>
        If you find any link on our website that is offensive for any reason, you may contact us. While we are not obligated to remove links, we will consider your request.
      </p>

      <h2 className={style.section_title}>Disclaimer</h2>
      <p className={style.text}>
        To the fullest extent permitted by law, we exclude all representations, warranties, and conditions related to the website and its use. Nothing in this disclaimer will:
      </p>
      <ul className={style.text}>
        <li>Limit or exclude liability for death or personal injury.</li>
        <li>Limit or exclude liability for fraud or fraudulent misrepresentation.</li>
        <li>Limit any liabilities in a way not permitted by law.</li>
      </ul>
      <p className={style.text}>
        As long as the website and services are provided free of charge, we will not be liable for any loss or damage of any kind.
      </p>
    </div>
    </div>
  );
};

export default TermsOfService;
