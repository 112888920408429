import React, { useState } from 'react';
import style from './../Css/Passwordreset.module.css';
import axios from 'axios';
import API_BASE_URL from '../apiConfig'; // Import API base URL

const Passwordreset = () => {
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [responseMessage, setResponseMessage] = useState('');

    const handleSubmit = async () => {
        // Validate input fields
        if (!email || !mobile) {
            setResponseMessage('Please fill in both fields.');
            return;
        }

        try {
            // Send POST request using Axios to backend
            const response = await axios.post(`${API_BASE_URL}/reset_password.php`, {
                email: email,
                mobile: mobile,
            });

            // Handle response from the backend
            if (response.data.success) {
                setResponseMessage('Password reset link has been sent to your email.');
            } else {
                setResponseMessage(response.data.message || 'Email and mobile number do not match.');
            }
        } catch (error) {
            console.error('Error:', error);
            setResponseMessage('An error occurred. Please try again.');
        }

        // Hide the message after 5 seconds
        setTimeout(() => {
            setResponseMessage('');
        }, 5000);
    };

    return (
        <>
            <div className="modal fade" id="ResetpassModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className={`${style.loginmodalbox} modal-body`}>
                            <h1 className={`${style.loginheading} top`}>Password Reset</h1>

                            {/* Email Input Field */}
                            <label className={style.logincontent} htmlFor="Gmail">
                                Gmail
                                <div className={style.loginicons}>
                                    <i className={`${style.loginIcons} fa-solid fa-at`}></i>
                                    <input
                                        className={style.loginmodalinputemail}
                                        type="email"
                                        placeholder="Enter your email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                </div>
                            </label>

                            {/* Mobile Number Input Field */}
                            <label className={style.logincontent} htmlFor="mobile">
                                Mobile Number
                                <div className={style.loginicons}>
                                    <i className={`${style.loginIcons} fa-solid fa-phone`}></i>
                                    <input
                                        className={style.loginmodalinputemail}
                                        type="text"
                                        placeholder="Enter your mobile number"
                                        value={mobile}
                                        onChange={(e) => setMobile(e.target.value)}
                                        required
                                    />
                                </div>
                            </label>

                            {/* Submit Button */}
                            <button type="button" className={`${style.loginmodalbtn} btn`} onClick={handleSubmit}>
                                Submit
                            </button>

                            {/* Response Message */}
                            {responseMessage && <p>{responseMessage}</p>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Passwordreset;
