import React, { useRef } from "react";
import Landingpage from './Landingpage';
import Applyforinternship from './Applyforinternship';
import Aboutus from "./Aboutus";
import ContactForm from "./ContactForm";
import Services from "./Services";

function LandingPageshow() {
  const aboutUsRef = useRef(null); 
  const serviceRef = useRef(null);
  const contactRef = useRef(null);

  return (
    <>
      <Applyforinternship />
      <Landingpage />
      <div ref={serviceRef}>
        <Services />
      </div>
      <div ref={aboutUsRef}>
        <Aboutus />
      </div>
      <div ref={contactRef}>
        <ContactForm />
      </div>
  
    </>
  );
}

export default LandingPageshow;
