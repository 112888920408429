import React, { useState } from 'react';
import style from './../../Css/Courses.module.css'

function Htmlcoursedata() {
  // State to keep track of the selected chapter
  const [selectedChapter, setSelectedChapter] = useState('chapter1')

  // Function to handle chapter selection
  const handleChapterClick = (chapter) => {
    setSelectedChapter(chapter)
  }

  // State to track the active chapter
  const [activeChapter, setActiveChapter] = useState('chapter1');

  // Function to handle chapter click
  const handleChapterActive = (chapter) => {
    setActiveChapter(chapter);
  };
  const handleClick = (chapter) => {
    handleChapterActive(chapter);
    handleChapterClick(chapter);
    window.scrollTo(0,0);
  };


  return (
    <div className={`${style.firstcontainer} container-fluid`}>
    
        <div className={`${style.therow} row`}>

        <div className={`${style.droupdownbtnbar} dropdown`}>
                    <button className={`${style.droupdownbtn} btn dropdown-toggle`} type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  HTML
                    </button>
                    
                    <ul className={`${style.dropdownmenu} dropdown-menu`}>

                   

                        <li onClick={() => handleClick('chapter1')}
                            className={`${style.chapter1} ${activeChapter === 'chapter1' ? style.active : ''} text-decoration-none dropdown-item`} >
                       Home
                        </li>

                        <li
                            onClick={() => handleClick('chapter2')}
                            className={`${style.chapter2} ${activeChapter === 'chapter2' ? style.active : ''} dropdown-item `}>
                            Introduction 
                        </li>


                        <li
                            onClick={() => handleClick('chapter30')}
                            className={`${style.chapter30} ${activeChapter === 'chapter30' ? style.active : ''} dropdown-item `}>
                          How to
                        </li>



                         
              <li onClick={() => handleClick('chapter3')}
              className={`${style.chapter3} ${activeChapter === 'chapter3' ? style.active : ''}  dropdown-item  `}>
               Elements
              </li>

              <li onClick={() => handleClick('chapter4')} 
                className={`${style.chapter4} ${activeChapter === 'chapter4' ? style.active : ''}  dropdown-item  `}>
                 Attributes
              </li>

              <li onClick={() => handleClick('chapter5')} 
              className={`${style.chapter5} ${activeChapter === 'chapter5' ? style.active : ''}  dropdown-item  `}>
                 Tags
              </li>

              <h5>Text Formatting</h5>

              <li onClick={() => handleClick('chapter6')} 
              className={`${style.chapter6} ${activeChapter === 'chapter6' ? style.active : ''}  dropdown-item  `}>
                 Heading
              </li>

              <li onClick={() => handleClick('chapter7')}
              className={`${style.chapter7} ${activeChapter === 'chapter7' ? style.active : ''}  dropdown-item  `}>
                 Paragraph
              </li>

              <li onClick={() => handleClick('chapter8')} 
              className={`${style.chapter8} ${activeChapter === 'chapter8' ? style.active : ''}  dropdown-item  `}>
                 CSS
              </li>

              <li onClick={() => handleClick('chapter9')} 
              className={`${style.chapter9} ${activeChapter === 'chapter9' ? style.active : ''}  dropdown-item  `}>
                 Classes
              </li>

              <li onClick={() => handleClick('chapter10')} 
               className={`${style.chapter10} ${activeChapter === 'chapter10' ? style.active : ''}  dropdown-item  `}>
                 Id
              </li>


               <h5>Links and Images </h5>

              <li onClick={() => handleClick('chapter11')}
               className={`${style.chapter11} ${activeChapter === 'chapter11' ? style.active : ''}  dropdown-item  `}>
                 Link
              </li>

              <li onClick={() => handleClick('chapter12')}
                className={`${style.chapter12} ${activeChapter === 'chapter12' ? style.active : ''}  dropdown-item  `}>
                 Images
              </li>

              <li onClick={() => handleClick('chapter13')}
               className={`${style.chapter13} ${activeChapter === 'chapter13' ? style.active : ''}  dropdown-item  `}>
                 Background Images
              </li>


              <h5>Structure and Layout</h5>

              <li onClick={() => handleClick('chapter14')} 
              className={`${style.chapter14} ${activeChapter === 'chapter14' ? style.active : ''}  dropdown-item  `}>
                Table
              </li>

              <li onClick={() => handleClick('chapter15')}
               className={`${style.chapter15} ${activeChapter === 'chapter15' ? style.active : ''}  dropdown-item  `}>
                 Div
              </li>


              <li onClick={() => handleClick('chapter16')} 
               className={`${style.chapter16} ${activeChapter === 'chapter16' ? style.active : ''}  dropdown-item  `}>
                 Layout
              </li>


              <h5>Forms</h5>

              <li onClick={() =>handleClick('chapter17')} 
               className={`${style.chapter17} ${activeChapter === 'chapter17' ? style.active : ''}  dropdown-item  `}>
                Form
              </li>

              <li onClick={() =>handleClick('chapter18')}
               className={`${style.chapter18} ${activeChapter === 'chapter18' ? style.active : ''}  dropdown-item  `}>
                Form Attributes
              </li>

              <li onClick={() =>handleClick('chapter19')} 
                className={`${style.chapter19} ${activeChapter === 'chapter19' ? style.active : ''}  dropdown-item  `}>
                 Form Elements
              </li>

              <li onClick={() =>handleClick('chapter20')}
                className={`${style.chapter20} ${activeChapter === 'chapter20' ? style.active : ''}  dropdown-item  `}>
               Input Types
              </li>

              <li onClick={() =>handleClick('chapter21')}
               className={`${style.chapter21} ${activeChapter === 'chapter21' ? style.active : ''}  dropdown-item  `}>
                Input Attributes
              </li>

              <li onClick={() =>handleClick('chapter22')} 
               className={`${style.chapter22} ${activeChapter === 'chapter22' ? style.active : ''}  dropdown-item  `}>
                Input Form Attributes
              </li>



              <h5>Advanced Topics</h5>

              <li onClick={() => handleClick('chapter23')} 
                className={`${style.chapter23} ${activeChapter === 'chapter23' ? style.active : ''}  dropdown-item  `}>
               Iframes
              </li>

              <li onClick={() => handleClick('chapter24')} 
              className={`${style.chapter24} ${activeChapter === 'chapter24' ? style.active : ''}  dropdown-item  `}>
               Javascript
              </li>

              <li onClick={() => handleClick('chapter25')}
                className={`${style.chapter25} ${activeChapter === 'chapter25' ? style.active : ''}  dropdown-item  `}>
                File Path
              </li>


              <li onClick={() => handleClick('chapter26')}
                className={`${style.chapter26} ${activeChapter === 'chapter26' ? style.active : ''}  dropdown-item  `}>
                Responsive
              </li>


              <li onClick={() => handleClick('chapter27')} 
               className={`${style.chapter27} ${activeChapter === 'chapter27' ? style.active : ''}  dropdown-item  `}>
               Entites
              </li>

              <li onClick={() => handleClick('chapter28')} 
               className={`${style.chapter28} ${activeChapter === 'chapter28' ? style.active : ''}  dropdown-item  `}>
                Symbols
              </li>


              <li onClick={() => handleClick('chapter29')} 
               className={`${style.chapter29} ${activeChapter === 'chapter29' ? style.active : ''}  dropdown-item  `}>
                 Emojis
              </li>


              </ul>
                </div>




          <div className={`${style.leftcolumn} col-2`}>
            {/* Chapter List */}
            <ul className={style.chapters}>
         <h5 className={style.stickyheading}>HTML </h5>
              <li
                onClick={() => handleClick('chapter1')} 
               
                className={`${style.chapter1} ${style.chapters} ${activeChapter === 'chapter1' ? style.active : ''} text-decoration-none`}
              >
               Home
              </li>
              <li
                onClick={() => handleClick('chapter2')}
                className={`${style.chapter2} ${activeChapter === 'chapter2' ? style.active : ''}`}
              >
                 Introduction 
              </li>

              <li onClick={() => handleClick('chapter30')}
              className={`${style.chapter30} ${activeChapter === 'chapter30' ? style.active : ''}`}>
              How to 
              </li>
              
              <li onClick={() => handleClick('chapter3')}
              className={`${style.chapter3} ${activeChapter === 'chapter3' ? style.active : ''}`}>
               Elements
              </li>

              <li onClick={() => handleClick('chapter4')} 
                className={`${style.chapter4} ${activeChapter === 'chapter4' ? style.active : ''}`}>
                 Attributes
              </li>

              <li onClick={() => handleClick('chapter5')} 
              className={`${style.chapter5} ${activeChapter === 'chapter5' ? style.active : ''}`}>
                 Tags
              </li>

              <h5>Text Formatting</h5>

              <li onClick={() => handleClick('chapter6')} 
              className={`${style.chapter6} ${activeChapter === 'chapter6' ? style.active : ''}`}>
                 Heading
              </li>

              <li onClick={() => handleClick('chapter7')}
              className={`${style.chapter7} ${activeChapter === 'chapter7' ? style.active : ''}`}>
                 Paragraph
              </li>

              <li onClick={() => handleClick('chapter8')} 
              className={`${style.chapter8} ${activeChapter === 'chapter8' ? style.active : ''}`}>
                 CSS
              </li>

              <li onClick={() => handleClick('chapter9')} 
              className={`${style.chapter9} ${activeChapter === 'chapter9' ? style.active : ''}`}>
                 Classes
              </li>

              <li onClick={() => handleClick('chapter10')} 
               className={`${style.chapter10} ${activeChapter === 'chapter10' ? style.active : ''}`}>
                 Id
              </li>


               <h5>Links and Images </h5>

              <li onClick={() => handleClick('chapter11')}
               className={`${style.chapter11} ${activeChapter === 'chapter11' ? style.active : ''}`}>
                 Link
              </li>

              <li onClick={() => handleClick('chapter12')}
                className={`${style.chapter12} ${activeChapter === 'chapter12' ? style.active : ''}`}>
                 Images
              </li>

              <li onClick={() => handleClick('chapter13')}
               className={`${style.chapter13} ${activeChapter === 'chapter13' ? style.active : ''}`}>
                 Background Images
              </li>


              <h5>Structure and Layout</h5>

              <li onClick={() => handleClick('chapter14')} 
              className={`${style.chapter14} ${activeChapter === 'chapter14' ? style.active : ''}`}>
                Table
              </li>

              <li onClick={() => handleClick('chapter15')}
               className={`${style.chapter15} ${activeChapter === 'chapter15' ? style.active : ''}`}>
                 Div
              </li>


              <li onClick={() => handleClick('chapter16')} 
               className={`${style.chapter16} ${activeChapter === 'chapter16' ? style.active : ''}`}>
                 Layout
              </li>


              <h5>Forms</h5>

              <li onClick={() =>handleClick('chapter17')} 
               className={`${style.chapter17} ${activeChapter === 'chapter17' ? style.active : ''}`}>
                Form
              </li>

              <li onClick={() =>handleClick('chapter18')}
               className={`${style.chapter18} ${activeChapter === 'chapter18' ? style.active : ''}`}>
                Form Attributes
              </li>

              <li onClick={() =>handleClick('chapter19')} 
                className={`${style.chapter19} ${activeChapter === 'chapter19' ? style.active : ''}`}>
                 Form Elements
              </li>

              <li onClick={() =>handleClick('chapter20')}
                className={`${style.chapter20} ${activeChapter === 'chapter20' ? style.active : ''}`}>
               Input Types
              </li>

              <li onClick={() =>handleClick('chapter21')}
               className={`${style.chapter21} ${activeChapter === 'chapter21' ? style.active : ''}`}>
                Input Attributes
              </li>

              <li onClick={() =>handleClick('chapter22')} 
               className={`${style.chapter22} ${activeChapter === 'chapter22' ? style.active : ''}`}>
                Input Form Attributes
              </li>



              <h5>Advanced Topics</h5>

              <li onClick={() => handleClick('chapter23')} 
                className={`${style.chapter23} ${activeChapter === 'chapter23' ? style.active : ''}`}>
               Iframes
              </li>

              <li onClick={() => handleClick('chapter24')} 
              className={`${style.chapter24} ${activeChapter === 'chapter24' ? style.active : ''}`}>
               Javascript
              </li>

              <li onClick={() => handleClick('chapter25')}
                className={`${style.chapter25} ${activeChapter === 'chapter25' ? style.active : ''}`}>
                File Path
              </li>


              <li onClick={() => handleClick('chapter26')}
                className={`${style.chapter26} ${activeChapter === 'chapter26' ? style.active : ''}`}>
                Responsive
              </li>


              <li onClick={() => handleClick('chapter27')} 
               className={`${style.chapter27} ${activeChapter === 'chapter27' ? style.active : ''}`}>
               Entites
              </li>

              <li onClick={() => handleClick('chapter28')} 
               className={`${style.chapter28} ${activeChapter === 'chapter28' ? style.active : ''}`}>
                Symbols
              </li>


              <li onClick={() => handleClick('chapter29')} 
               className={`${style.chapter29} ${activeChapter === 'chapter29' ? style.active : ''}`}>
                 Emojis
              </li>




        




            </ul>
          </div>




          <div className={`${style.rightcolumn} col`}>
            {/* Chapter Content */}



            {selectedChapter === 'chapter1' && (


              <div className={style.chaptercontent}>
                <h1 className={style.heading} >HTML Tutorial</h1>

                <h2>What is HTML?</h2>
                <p>
                  HTML is a markup language that describes the structure of web pages using a system of tags and attributes. These tags define various elements on a page, such as headings, paragraphs, links, images, and other content.
                </p><br />

                <h2>Basic Structure of an HTML Document</h2>
                <p>An HTML document is made up of several key components:</p>
                <ul>
                  <li>
                    <strong>DOCTYPE Declaration</strong>: Specifies the HTML version and helps the browser render the page correctly. For HTML5, it is written as <code>&lt;!DOCTYPE html&gt;</code>.
                  </li><br />
                  <li>
                    <strong>HTML Element</strong>: The root element that wraps all other elements in the document. It is denoted by <code>&lt;html&gt;</code> and <code>&lt;/html&gt;</code>.
                  </li><br />
                  <li>
                    <strong>Head Element</strong>: Contains meta-information about the document, such as the title and links to stylesheets. It is enclosed in <code>&lt;head&gt;</code> and <code>&lt;/head&gt;</code> tags.
                  </li><br />
                  <li>
                    <strong>Body Element</strong>: Contains the content of the web page, including text, images, and other media. It is enclosed in <code>&lt;body&gt;</code> and <code>&lt;/body&gt;</code> tags.
                  </li>
                </ul>

                <h4>Example of a Basic HTML Document</h4>
                <pre>
                  <code>{`<!DOCTYPE html>
                    <html>
                    <head>
                      <title>My First HTML Page</title>
                    </head>
                    <body>
                      <h1>Hello, World!</h1>
                      <p>This is a simple HTML page.</p>
                    </body>
                    </html>`}</code>
                </pre>

                <p>
                  This example illustrates a basic HTML document structure. It includes a <code>&lt;title&gt;</code> element in the head section, and a heading and paragraph in the body section.
                </p><br />

                <h3>Why Learn HTML?</h3>
                <p>
                  Learning HTML is essential for anyone interested in web development. It provides the foundation for understanding how web pages are constructed and allows you to create and modify your own webpages. With a solid grasp of HTML, you can then explore CSS (Cascading Style Sheets) and JavaScript to enhance your web development skills.
                </p>


              </div>
            )}





{selectedChapter === 'chapter2' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Introduction</h1>

    <p>HTML, or HyperText Markup Language, is the standard language used to create and design webpages. It structures content on the web by using a system of tags and attributes. HTML tags define elements like headings, paragraphs, links, images, and other types of content.</p><br />

    <h2 className={style.subheading}>Core Components of HTML</h2>
    <p>Here's a brief overview of its core components:</p>

    <ul className={style.list}>
      <li>
        <strong>Elements:</strong> The basic building blocks of HTML. They are represented by tags, such as <code>&lt;p&gt;</code> for paragraphs, <code>&lt;h1&gt;</code> to <code>&lt;h6&gt;</code> for headings, and <code>&lt;a&gt;</code> for links.
      </li><br />
      <li>
        <strong>Attributes:</strong> Provide additional information about elements, like <code>href</code> in an <code>&lt;a&gt;</code> tag to specify the destination of a link or <code>src</code> in an <code>&lt;img&gt;</code> tag to specify the path to an image.
      </li><br />
      <li>
        <strong>Document Structure:</strong> HTML documents have a specific structure, including the <code>&lt;!DOCTYPE html&gt;</code> declaration, <code>&lt;html&gt;</code> root element, <code>&lt;head&gt;</code> for metadata and links to stylesheets or scripts, and <code>&lt;body&gt;</code> for the actual content.
      </li>
    </ul><br />

    <h2 className={style.subheading}>HTML Evolution</h2>
    <p>HTML has gone through several versions, each introducing new features and improvements:</p>
    <ul className={style.list}>
      <li><strong>HTML 1.0:</strong> The first version, primarily focused on basic text formatting and hyperlinking.</li><br />
      <li><strong>HTML 4.01:</strong> Introduced better support for multimedia, tables, and forms, laying the foundation for modern web development.</li><br />
      <li><strong>HTML5:</strong> The latest major revision, introduced support for multimedia (audio and video), new semantic tags (like <code>&lt;article&gt;</code> and <code>&lt;section&gt;</code>), and enhanced integration with CSS and JavaScript for dynamic, interactive content.</li>
    </ul><br />

    <h2 className={style.subheading}>HTML in Modern Web Development</h2>
    <p>HTML forms the backbone of webpages, but it’s often used in conjunction with other technologies:</p>
    <ul className={style.list}>
      <li><strong>CSS (Cascading Style Sheets):</strong> Used for styling and layout, allowing developers to control the appearance of elements (colors, fonts, spacing, etc.) independently of the content.</li><br />
      <li><strong>JavaScript:</strong> Adds interactivity and dynamic behavior to web pages, such as animations, form validation, and content updates without reloading the page.</li><br />
      <li><strong>APIs and Frameworks:</strong> HTML interacts with modern frameworks like React, Angular, and Vue.js to build complex, component-based web applications. These frameworks often use HTML templates in conjunction with JavaScript to manage content and interactions efficiently.</li>
    </ul><br />

    <p>HTML, CSS, and JavaScript together form the foundation of front-end web development. While HTML structures the content, CSS enhances its presentation, and JavaScript adds interactivity, making the web dynamic and user-friendly.</p>
  </div>
)}





            {selectedChapter === 'chapter3' && (
              <div className={style.chaptercontent}>



<h1 className={style.heading} >Elements</h1>
                <p>HTML (HyperText Markup Language) is the standard language used to create and design webpages. In HTML, elements are the building blocks of a webpage. An HTML element is made up of:</p>
                <ol>
                  <li>
                    <strong>Opening Tag</strong>: This marks the beginning of the element. It usually includes the element name in angle brackets, such as <code>&lt;p&gt;</code> for a paragraph.
                  </li><br/>
                  <li>
                    <strong>Content</strong>: This is the information or text that the element displays on the webpage. For example, in a paragraph element <code>&lt;p&gt;This is a paragraph.&lt;/p&gt;</code>, the content is "This is a paragraph."
                  </li><br/>
                  <li>
                    <strong>Closing Tag</strong>: This marks the end of the element and is similar to the opening tag but includes a forward slash before the element name, such as <code>&lt;/p&gt;</code> for closing a paragraph.
                  </li><br/>
                  <li>
                    <strong>Attributes</strong> (optional): These provide additional information about the element and are included in the opening tag. For example, <code>&lt;a href="https://example.com"&gt;Visit Example&lt;/a&gt;</code> includes an <code>href</code> attribute that specifies the URL for a link.
                  </li><br/>
                </ol>
                <p>Here’s a simple example of an HTML element:</p>
                <pre>
                  <code>{`<a href="https://example.com" target="_blank">Visit Example</a>`}</code>
                </pre>
                <p>In this example:</p>
                <ul>
                  <li><code>&lt;a&gt;</code> is the opening tag.</li><br />
                  <li><code>href="https://example.com"</code> is an attribute specifying the link destination.</li><br />
                  <li><strong>Visit Example</strong> is the content that appears as clickable text.</li><br />
                  <li><code>&lt;/a&gt;</code> is the closing tag.</li>
                </ul><br />
                <p>HTML elements come in two main types:</p>
                <ul>
                  <li>
                    <strong>Block-level Elements</strong>: These elements typically start on a new line and take up the full width available. Examples include <code>&lt;div&gt;</code>, <code>&lt;p&gt;</code>, and <code>&lt;h1&gt;</code>.
                  </li><br />
                  <li>
                    <strong>Inline Elements</strong>: These elements do not start on a new line and only take up as much width as necessary. Examples include <code>&lt;span&gt;</code>, <code>&lt;a&gt;</code>, and <code>&lt;strong&gt;</code>.
                  </li>
                </ul>
                <p>Combining these elements allows you to create structured and styled webpages.</p>
              </div>
            )
            }




            {selectedChapter === 'chapter4' && (
              <div className={style.chaptercontent}>

             <h1 className={style.heading} > Attributes</h1>
                <p>
                  HTML attributes provide additional information about HTML elements. They are used to define properties or behaviors of an element and are specified within the opening tag of the element.
                </p><br />

                <p>Here’s a basic rundown:</p>

                <ul>
                  <li>
                    <strong>Syntax:</strong> Attributes are written in the form of <code>name="value"</code>. For example, in <code>a href="https://example.com"Link /a</code>, <code>href</code> is an attribute of the <code>a</code> (anchor) element, and its value is <code>"https://example.com"</code>.
                  </li><br />

                  <li>
                    <strong style={{paddingBottom:"6px"}}>Common Attributes:</strong>
                    <ul>
                      <li><code>href</code>: Specifies the URL in a link (<code>&lt;a&gt;</code> tag).</li><br />
                      <li><code>src</code>: Defines the source of an image (<code>&lt;img&gt;</code> tag).</li><br />
                      <li><code>alt</code>: Provides alternative text for an image (<code>&lt;img&gt;</code> tag).</li><br />
                      <li><code>class</code>: Assigns one or more class names to an element for styling or scripting (<code>&lt;div&gt;</code>, <code>&lt;p&gt;</code>, etc.).</li><br />
                      <li><code>id</code>: Assigns a unique identifier to an element.</li><br />
                      <li><code>style</code>: Applies inline CSS styles directly to an element.</li>
                    </ul>
                  </li><br />

                  <li>
                    <strong>Boolean Attributes:</strong> Some attributes are boolean, meaning they can be present or absent but do not require a value. For example, the <code>disabled</code> attribute on a <code>&lt;button&gt;</code> element indicates that the button is not clickable if the attribute is present.
                  </li><br />

                  <li>
                    <strong>Global Attributes:</strong> Some attributes can be used on almost any HTML element. These include <code>id</code>, <code>class</code>, <code>style</code>, <code>title</code>, and others.
                  </li><br />

                  <li>
                    <strong>Event Attributes:</strong> These attributes allow you to execute JavaScript code in response to events, such as <code>onclick</code>, <code>onmouseover</code>, and <code>onchange</code>.
                  </li>
                </ul>

                <p>
                  Attributes help define how elements behave and are displayed, making them crucial for both structure and styling in HTML documents.
                </p>


              </div>
            )}




{selectedChapter === 'chapter5' && (
              <div className={style.chaptercontent}>
              <h1 className={style.heading} > Tags</h1>



                <p>
                  HTML (Hypertext Markup Language) is the standard language used to create and design web pages. It consists of a variety of tags, each serving a specific purpose. Here is a comprehensive list of HTML tags and their uses:
                </p><br />

                <h2 style={{paddingBottom:"6px"}}>Document Structure</h2>
                <ol>
                  <li><strong>{'<!DOCTYPE html>'}</strong> - Declares the document type and version of HTML.</li><br />
                  <li><strong>{'<html>'}</strong> - Root element of an HTML document.</li><br />
                  <li><strong>{'<head>'}</strong> - Contains meta-information about the document (e.g., title, links to stylesheets).</li><br />
                  <li><strong>{'<title>'}</strong> - Sets the title of the document, displayed in the browser&apos;s title bar or tab.</li><br />
                  <li><strong>{'<meta>'}</strong> - Provides metadata about the document, such as character set or viewport settings.</li><br />
                  <li><strong>{'<link>'}</strong> - Specifies relationships between the current document and external resources, commonly used for linking stylesheets.</li><br />
                  <li><strong>{'<style>'}</strong> - Contains CSS styles for the document.</li><br />
                  <li><strong>{'<script>'}</strong> - Contains or links to JavaScript code.</li><br />
                  <li><strong>{'<body>'}</strong> - Contains the content of the document.</li>
                </ol><br/>

                <h2 style={{paddingBottom:"6px"}}>Headings and Text</h2>
                <ol>
                  <li><strong>{'<h1>'} to {'<h6>'}</strong> - Define headings, <code>{'<h1>'}</code> is the highest level, and <code>{'<h6>'}</code> is the lowest.</li><br />
                  <li><strong>{'<p>'}</strong> - Defines a paragraph.</li><br />
                  <li><strong>{'<br>'}</strong> - Inserts a line break.</li><br />
                  <li><strong>{'<hr>'}</strong> - Creates a horizontal rule (line).</li><br />
                  <li><strong>{'<blockquote>'}</strong> - Defines a block of text that is a quotation from another source.</li><br />
                  <li><strong>{'<pre>'}</strong> - Displays preformatted text, preserving spaces and line breaks.</li><br />
                  <li><strong>{'<strong>'}</strong> - Defines important text, typically rendered as bold.</li><br />
                  <li><strong>{'<em>'}</strong> - Defines emphasized text, typically rendered as italic.</li><br />
                  <li><strong>{'<b>'}</strong> - Defines bold text.</li><br />
                  <li><strong>{'<i>'}</strong> - Defines italic text.</li><br />
                  <li><strong>{'<u>'}</strong> - Defines underlined text.</li><br />
                  <li><strong>{'<mark>'}</strong> - Highlights text.</li><br />
                  <li><strong>{'<small>'}</strong> - Defines smaller text.</li><br />
                  <li><strong>{'<del>'}</strong> - Defines text that has been deleted from the document.</li><br />
                  <li><strong>{'<ins>'}</strong> - Defines text that has been inserted into the document.</li>
                </ol><br/>

                <h2 style={{paddingBottom:"6px"}}>Links and Anchors</h2>
                <ol>
                  <li><strong>{'<a>'}</strong> - Defines a hyperlink, used to link to other documents or resources.</li><br />
                  <li><strong>{'<nav>'}</strong> - Defines navigation links.</li>
                </ol><br/>

                <h2 style={{paddingBottom:"6px"}}>Lists</h2>
                <ol>
                  <li><strong>{'<ul>'}</strong> - Defines an unordered (bulleted) list.</li><br />
                  <li><strong>{'<ol>'}</strong> - Defines an ordered (numbered) list.</li><br />
                  <li><strong>{'<li>'}</strong> - Defines a list item.</li>
                </ol><br/>

                <h2 style={{paddingBottom:"6px"}}>Images and Multimedia</h2>
                <ol>
                  <li><strong>{'<img>'}</strong> - Embeds an image.</li><br />
                  <li><strong>{'<figure>'}</strong> - Specifies self-contained content, often with a caption.</li><br />
                  <li><strong>{'<figcaption>'}</strong> - Defines a caption for a <code>{'<figure>'}</code> element.</li><br />
                  <li><strong>{'<video>'}</strong> - Embeds a video.</li><br />
                  <li><strong>{'<source>'}</strong> - Specifies multiple media resources for <code>{'<video>'}</code> and <code>{'<audio>'}</code>.</li><br />
                  <li><strong>{'<audio>'}</strong> - Embeds audio content.</li><br />
                  <li><strong>{'<track>'}</strong> - Provides text tracks (e.g., subtitles) for <code>{'<video>'}</code> and <code>{'<audio>'}</code>.</li>
                </ol><br/>

                <h2 style={{paddingBottom:"6px"}}>Tables</h2>
                <ol>
                  <li><strong>{'<table>'}</strong> - Defines a table.</li><br />
                  <li><strong>{'<caption>'}</strong> - Defines a caption for a table.</li><br />
                  <li><strong>{'<thead>'}</strong> - Groups the header content in a table.</li><br />
                  <li><strong>{'<tbody>'}</strong> - Groups the body content in a table.</li><br />
                  <li><strong>{'<tfoot>'}</strong> - Groups the footer content in a table.</li><br />
                  <li><strong>{'<tr>'}</strong> - Defines a row in a table.</li><br />
                  <li><strong>{'<td>'}</strong> - Defines a cell in a table row.</li><br />
                  <li><strong>{'<th>'}</strong> - Defines a header cell in a table.</li><br />
                  <li><strong>{'<col>'}</strong> - Specifies column properties for an HTML table.</li><br />
                  <li><strong>{'<colgroup>'}</strong> - Specifies a group of one or more columns in a table for formatting.</li>
                </ol><br/>

                <h2 style={{paddingBottom:"6px"}}>Forms and Input</h2>
                <ol>
                  <li><strong>{'<form>'}</strong> - Defines an HTML form for user input.</li><br />
                  <li><strong>{'<input>'}</strong> - Defines an input control (e.g., text fields, checkboxes).</li><br />
                  <li><strong>{'<textarea>'}</strong> - Defines a multiline text input control.</li><br />
                  <li><strong>{'<button>'}</strong> - Defines a clickable button.</li><br />
                  <li><strong>{'<select>'}</strong> - Defines a drop-down list.</li><br />
                  <li><strong>{'<option>'}</strong> - Defines an option in a drop-down list.</li><br />
                  <li><strong>{'<label>'}</strong> - Defines a label for a <code>{'<input>'}</code> element.</li><br />
                  <li><strong>{'<fieldset>'}</strong> - Groups related elements in a form.</li><br />
                  <li><strong>{'<legend>'}</strong> - Defines a caption for a <code>{'<fieldset>'}</code> element.</li><br />
                  <li><strong>{'<datalist>'}</strong> - Contains a set of <code>{'<option>'}</code> elements for an <code>{'<input>'}</code> element.</li><br />
                  <li><strong>{'<output>'}</strong> - Represents the result of a calculation or user action.</li>
                </ol><br/>

                <h2 style={{paddingBottom:"6px"}}>Semantics and Layout</h2>
                <ol>
                  <li><strong>{'<div>'}</strong> - Defines a division or section in a document, often used for styling.</li><br />
                  <li><strong>{'<span>'}</strong> - Defines a section of text or inline elements, used for styling.</li><br />
                  <li><strong>{'<header>'}</strong> - Represents introductory content or a group of introductory content.</li><br />
                  <li><strong>{'<nav>'}</strong> - Defines a set of navigation links.</li><br />
                  <li><strong>{'<main>'}</strong> - Specifies the main content of a document.</li><br />
                  <li><strong>{'<section>'}</strong> - Defines a section in a document.</li><br />
                  <li><strong>{'<article>'}</strong> - Represents a self-contained piece of content.</li><br />
                  <li><strong>{'<aside>'}</strong> - Defines content aside from the main content (e.g., a sidebar).</li><br />
                  <li><strong>{'<footer>'}</strong> - Represents the footer of a document or section.</li><br />
                  <li><strong>{'<address>'}</strong> - Defines contact information for the author or owner of a document.</li><br />
                  <li><strong>{'<details>'}</strong> - Defines a disclosure widget from which the user can obtain additional information or controls .</li><br />
                  <li><strong>{'<summary>'}</strong> - Defines a visible heading for a <code>{'<details>'}</code> element.</li><br />
                  <li><strong>{'<dialog>'}</strong> - Defines a dialog box or other interactive component.</li><br />
                  <li><strong>{'<data>'}</strong> - Links a content with a machine-readable translation.</li><br />
                  <li><strong>{'<time>'}</strong> - Represents a specific period in time.</li>
                </ol><br/>

                <h2 style={{paddingBottom:"6px"}}>Scripting and Embedding</h2>
                <ol>
                  <li><strong>{'<script>'}</strong> - Contains or links to JavaScript code.</li><br />
                  <li><strong>{'<noscript>'}</strong> - Defines alternate content for users who have disabled JavaScript.</li><br />
                  <li><strong>{'<iframe>'}</strong> - Embeds another HTML page within the current page.</li><br />
                  <li><strong>{'<embed>'}</strong> - Defines a container for an external application or interactive content (e.g., a plugin).</li><br />
                  <li><strong>{'<object>'}</strong> - Defines an embedded object, such as an image or interactive content.</li><br />
                  <li><strong>{'<param>'}</strong> - Defines parameters for an <code>{'<object>'}</code> element.</li><br />
                  <li><strong>{'<map>'}</strong> - Defines an image map (clickable areas within an image).</li><br />
                  <li><strong>{'<area>'}</strong> - Defines a clickable area within an image map.</li>
                </ol><br/>

                <h2 style={{paddingBottom:"6px"}}>Deprecated Elements</h2>
                <ol>
                  <li><strong>{'<font>'}</strong> - Defines font size, color, and face. (Deprecated in HTML5)</li><br />
                  <li><strong>{'<center>'}</strong> - Centers text or other elements. (Deprecated in HTML5)</li><br />
                  <li><strong>{'<u>'}</strong> - Defines text with underline. (Deprecated in favor of CSS styles)</li>
                </ol>

                <p>
                  This list covers the most commonly used HTML tags. HTML5 introduced new tags and features to improve semantic markup and enhance web accessibility. For the latest and more detailed information, referring to the <a href="https://html.spec.whatwg.org/" target="_blank" rel="noopener noreferrer">HTML Living Standard</a> or <a href="https://developer.mozilla.org/en-US/docs/Web/HTML" target="_blank" rel="noopener noreferrer">MDN Web Docs</a> is recommended.
                </p>

              </div>
            )}




            {selectedChapter === 'chapter6' && (
              <div className={style.chaptercontent}>
                 <h1 className={style.heading} > Heading</h1>

                <p>
                  In HTML, headings are used to define the structure of your content and indicate the importance of different sections. There are six levels of headings, ranging from <code>&lt;h1&gt;</code> to <code>&lt;h6&gt;</code>, with <code>&lt;h1&gt;</code> being the highest (most important) level and <code>&lt;h6&gt;</code> being the lowest (least important).
                </p><br />

                <p>Here’s a quick example:</p>

                <pre>
                  <code>
                    {`<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Heading Example</title>
</head>
<body>
    <h1>Main Heading</h1>
    <h2>Subheading Level 2</h2>
    <h3>Subheading Level 3</h3>
    <h4>Subheading Level 4</h4>
    <h5>Subheading Level 5</h5>
    <h6>Subheading Level 6</h6>
</body>
</html>`}
                  </code>
                </pre>

                <p>
                  <code>&lt;h1&gt;</code> is typically used for the main title of the page.
                  <br />
                  <code>&lt;h2&gt;</code> to <code>&lt;h6&gt;</code> are used for subsections and lower-level headings.
                </p>

                <p>
                  Headings help with the organization of content, improve readability, and are important for SEO (Search Engine Optimization) as they help search engines understand the structure and relevance of your content.
                </p>



              </div>
            )
            }



            {selectedChapter === 'chapter7' && (
              <div className={style.chaptercontent}>
               <h1 className={style.heading} > Paragraph</h1>
                <p>
                  In HTML, a paragraph is defined using the <code>&lt;p&gt;</code> tag. This tag is used to group together a block of text into a distinctsection or paragraph.
                  When the HTML code is rendered by a web browser, the browser displays each paragraph with some space above and below it to visually
                   separate it from other paragraphs.
                </p><br />
                <p>Here’s a basic example of how to use the <code>&lt;p&gt;</code> tag:</p>
                <pre>
                  <code>
                    {`<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Paragraph Example</title>
</head>
<body>
    <p>This is the  paragraph </p>
   
</body>
</html>`}
                  </code>
                </pre>
                <p>
                  In this example:
                  <ul>
                    <li>The <code>&lt;p&gt;</code> tag starts the paragraph, and the browser will automatically add space before and after the content of the tag.</li><br />
                    <li>Each <code>&lt;p&gt;</code> tag creates a new paragraph, and the text inside each tag is displayed as a separate block of text.</li>
                  </ul>
                </p>
                <p>Using <code>&lt;p&gt;</code> tags helps in organizing and structuring the content of a webpage for readability and proper formatting.</p>




              </div>
            )

            }






            {selectedChapter === 'chapter8' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading} > CSS</h1>
                

      <p>
        HTML (HyperText Markup Language) and CSS (Cascading Style Sheets) are foundational technologies for creating and designing web pages.
      </p><br/>

      <h2 style={{paddingBottom:"6px"}}>HTML</h2>
      <p>It provides the structure of a web page.</p>
      <p>
        HTML uses elements (or tags) to define the content and layout of a web page, such as headings, paragraphs, links, images, and other content.
      </p>
      <p>
        For example, <code>&lt;h1&gt;</code> defines a top-level heading, <code>&lt;p&gt;</code> defines a paragraph, and <code>&lt;a&gt;</code> defines a hyperlink.
      </p><br/>

      <h2 style={{paddingBottom:"6px"}}>CSS</h2>
      <p>It controls the presentation and layout of the HTML elements.</p>
      <p>
        CSS allows you to style elements by setting properties such as colors, fonts, spacing, and positioning.
      </p>
      <p>
        For example, you can use CSS to change the color of text, set the background of a page, or arrange elements in a grid.
      </p>

      <p>
        Together, HTML and CSS enable you to create well-structured and visually appealing web pages. HTML sets up the content, while CSS styles and arranges that content.
      </p><br/>

      <h3>CSS Types</h3>
      <p style={{paddingBottom:"6px"}}>In HTML, there are three primary types of CSS (Cascading Style Sheets) that you can use to style your web pages. Each type has its own use case and scope:</p>

      <h4 style={{paddingBottom:"6px"}}>1. Inline CSS</h4>
      <ul>
        <li><strong>Description:</strong> CSS rules are applied directly within an HTML element using the <code>style</code> attribute.</li><br />
        <li><strong>Use Case:</strong> Quick styling for individual elements. Not recommended for extensive styling due to limited reusability and maintainability.</li><br />
        <li><strong>Syntax:</strong>
          <pre>
            <code>
              {`<p style="color: blue; font-size: 16px;">This is an inline-styled paragraph.</p>`}
            </code>
          </pre>
        </li>
      </ul><br/>

      <h4 style={{paddingBottom:"6px"}}>2. Internal CSS</h4>
      <ul>
        <li><strong>Description:</strong> CSS rules are written inside a <code>&lt;style&gt;</code> tag within the <code>&lt;head&gt;</code> section of the HTML document.</li><br />
        <li><strong>Use Case:</strong> Styling specific to a single HTML document. Useful for styling the document without affecting other pages.</li><br />
        <li><strong>Syntax:</strong>
          <pre>
            <code>
              {`<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Internal CSS Example</title>
  <style>
    body { font-family: Arial, sans-serif; background-color: #f4f4f4; }
    h1 { color: #333; }
    p { color: #555; }
  </style>
</head>
<body>
  <h1>Internal CSS Example</h1>
  <p>This paragraph is styled using internal CSS.</p>
</body>
</html>`}
            </code>
          </pre>
        </li>
      </ul><br/>

      <h4>3. External CSS</h4>
      <ul>
        <li><strong>Description:</strong> CSS rules are written in a separate <code>.css</code> file, which is linked to the HTML document using the <code>&lt;link&gt;</code> tag.</li><br />
        <li><strong>Use Case:</strong> The most efficient method for applying styles across multiple HTML pages. Promotes consistency and reusability, and keeps HTML files cleaner.</li><br />
        <li><strong>Syntax:</strong>
          <pre>
            <code>
              {`<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>External CSS Example</title>
  <link rel="stylesheet" href="styles.css">
</head>
<body>
  <h1>External CSS Example</h1>
  <p>This paragraph is styled using external CSS.</p>
</body>
</html>`}
            </code>
          </pre>
        </li>
      </ul><br/>

      <p><strong>Summary:</strong></p>
      <ul>
        <li><strong>Inline CSS:</strong> For individual elements, quick and specific styling.</li><br />
        <li><strong>Internal CSS:</strong> For styling a single HTML document, included in the <code>&lt;head&gt;</code> section.</li><br />
        <li><strong>External CSS:</strong> For styling multiple HTML documents, linked via a separate <code>.css</code> file.</li>
      </ul><br />

      <p>
        Each type of CSS has its own strengths and is suitable for different scenarios, with external CSS being the most versatile and maintainable for larger projects.
      </p>

   </div>
            )}




{selectedChapter === 'chapter9' && (
              <div className={style.chaptercontent}>
                 <h1 className={style.heading} > Classes</h1>

                <p>
                  In HTML, a class is an attribute you can add to HTML elements to apply styles or target elements with JavaScript.
                  Classes are a way to group elements for styling with CSS or for behavior with JavaScript.
                </p><br />
                <h4 style={{paddingBottom:"6px"}}>Here's a quick rundown:</h4>

                <h2>1. Syntax</h2>
                <p>
                  To use a class in HTML, you add the <code>class</code> attribute to an element, like this:
                </p>
                <pre>
                  <code>{`<div class="my-class">Content here</div>`}</code>
                </pre>
                <p>
                  You can also assign multiple classes to an element by separating them with spaces:
                </p>
                <pre>
                  <code>{`<div class="class-one class-two">Content here</div>`}</code>
                </pre><br/>

                <h2>2. CSS Styling</h2>
                <p>
                  In CSS, you can use class selectors to apply styles. To target an element with a specific class, use a dot <code>.</code> followed by the class name:
                </p>
                <pre>
                  <code>{`.my-class {\n  color: blue;\n  font-size: 20px;\n}`}</code>
                </pre>
                <p>
                  This CSS rule will style any element with the class <code>my-class</code> to have blue text and a font size of 20 pixels.
                </p><br/>

                <h2>3. JavaScript</h2>
                <p>
                  In JavaScript, classes can be used to select and manipulate elements. For example, you can select elements with a specific class using <code>document.querySelector</code> or <code>document.getElementsByClassName</code>:
                </p>
                <pre>
                  <code>{`// Select the first element with the class 'my-class'\nlet element = document.querySelector('.my-class');\n\n// Add a new class to the element\nelement.classList.add('another-class');`}</code>
                </pre><br/>

                <h2 style={{paddingBottom:"6px"}}>4. Benefits</h2>
                <ul>
                  <li><strong>Reusability:</strong> You can apply the same class to multiple elements, making it easier to apply consistent styles or behaviors.</li><br />
                  <li><strong>Organization:</strong> Helps in organizing styles and scripts efficiently.</li><br />
                  <li><strong>Flexibility:</strong> Classes allow for more flexible and modular code, separating content from design and behavior.</li>
                </ul>

                <h4>Example</h4>
                <p>Here’s a simple example demonstrating the use of classes:</p>
                <pre>
                  <code>{`<!DOCTYPE html>\n<html lang="en">\n<head>\n  <meta charset="UTF-8">\n  <meta name="viewport" content="width=device-width, initial-scale=1.0">\n  <title>Example</title>\n  <style>\n    .highlight {\n      background-color: yellow;\n    }\n    .bold {\n      font-weight: bold;\n    }\n  </style>\n</head>\n<body>\n  <p class="highlight">This paragraph is highlighted.</p>\n  <p class="bold">This paragraph is bold.</p>\n  <p class="highlight bold">This paragraph is both highlighted and bold.</p>\n</body>\n</html>`}</code>
                </pre>
                <p>In this example, you see how multiple classes can be applied to elements and how CSS rules are defined for those classes.</p>

              </div>
            )}

{selectedChapter === 'chapter10' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading} > Id</h1>
                <p>
                  In HTML, an <code>id</code> is an attribute you can add to an HTML element to uniquely identify it within the page. Unlike classes, which can be used to group multiple elements together, an <code>id</code> is intended to be unique to a single element. This means no two elements on the same page should have the same <code>id</code>.
                </p><br />

                <h2>1. Syntax</h2>
                <p>
                  To use an <code>id</code>, you add the <code>id</code> attribute to an element:
                </p>
                <pre>
                  <code>{`<div id="unique-element">Content here</div>`}</code>
                </pre><br />

                <h2>2. CSS Styling</h2>
                <p>
                  In CSS, you can use <code>id</code> selectors to apply styles to a specific element. To target an element with a specific <code>id</code>, use a hash <code>#</code> followed by the <code>id</code> name:
                </p>
                <pre>
                  <code>{`#unique-element {\n  color: red;\n  font-size: 24px;\n}`}</code>
                </pre>
                <p>
                  This CSS rule will style the element with the <code>id</code> <code>unique-element</code> to have red text and a font size of 24 pixels.
                </p><br />

                <h2>3. JavaScript</h2>
                <p>
                  In JavaScript, <code>id</code> attributes are used to select and manipulate elements. For example, you can use <code>document.getElementById</code> to get a reference to the element with a specific <code>id</code>:
                </p>
                <pre>
                  <code>{`// Select the element with the id 'unique-element'\nlet element = document.getElementById('unique-element');\n\n// Change the text content of the element\nelement.textContent = 'New Content';`}</code>
                </pre><br />

                <h2 style={{paddingBottom:"6px"}}>4. HTML Specifications</h2>
                <ul>
                  <li><strong>Uniqueness:</strong> The <code>id</code> value must be unique within the document. If multiple elements have the same <code>id</code>, only the first one will be targeted by CSS and JavaScript, and it can lead to unpredictable behavior.</li><br />
                  <li><strong>Case Sensitivity:</strong> <code>id</code> values are case-sensitive. <code>#Unique-Element</code> and <code>#unique-element</code> would be considered different IDs.</li>
                </ul><br />

                <h2 style={{paddingBottom:"6px"}}>5. Benefits</h2>
                <ul>
                  <li><strong>Direct Targeting:</strong> <code>id</code> attributes allow for direct targeting of elements, which can be useful for precise styling or manipulation.</li><br />
                  <li><strong>Anchor Links:</strong> <code>id</code> attributes can be used for creating anchor links within a page. For example, <code>&lt;a href="#section1"&gt;Go to Section 1&lt;/a&gt;</code> will scroll the page to the element with <code>id="section1"</code>.</li>
                </ul>

                <h4>Example</h4>
                <p>Here’s an example of how <code>id</code> attributes can be used in HTML:</p>
                <pre>
                  <code>{`<!DOCTYPE html>\n<html lang="en">\n<head>\n  <meta charset="UTF-8">\n  <meta name="viewport" content="width=device-width, initial-scale=1.0">\n  <title>Example</title>\n  <style>\n    #header {\n      background-color: lightblue;\n      text-align: center;\n      padding: 10px;\n    }\n    #content {\n      padding: 20px;\n    }\n  </style>\n</head>\n<body>\n  <div id="header">\n    <h1>Welcome to My Website</h1>\n  </div>\n  <div id="content">\n    <p>This is the main content area.</p>\n    <a href="#header">Back to Top</a>\n  </div>\n</body>\n</html>`}</code>
                </pre>
                <p>
                  In this example:
                  <ul>
                    <li>The <code>id</code> <code>header</code> is used to style the header section and to create an anchor link.</li><br />
                    <li>The <code>id</code> <code>content</code> is used to style the main content area.</li>
                  </ul>
                </p>
                <p>
                  By using <code>id</code> attributes, you can ensure that specific elements are easily accessible and can be individually targeted for styling or scripting.
                </p>
              </div>
            )}



{selectedChapter === 'chapter11' && (
              <div className={style.chaptercontent}>

         <h1 className={style.heading} > Link</h1>
        <p>
          An HTML link, or hyperlink, is a fundamental component of web pages that allows users to navigate from one web page to another or to different sections of the same page. It’s created using the <code>&lt;a&gt;</code> (anchor) tag in HTML.
        </p><br />
        <p>Here’s a basic example of how an HTML link looks:</p>
        <pre>
          <code>
            {`<a href="https://www.example.com">Visit Example.com</a>`}
          </code>
        </pre>
        <p>
          In this example:
          <ul>
            <li><code>&lt;a&gt;</code> is the anchor tag that defines the link.</li><br />
            <li><code>href</code> is an attribute that specifies the URL or location to which the link points.</li><br />
            <li><code>Visit Example.com</code> is the text that users will see and can click on.</li>
          </ul>
        </p>
        <p>
          When a user clicks on the link, their browser will navigate to the URL specified in the <code>href</code> attribute. Links can also be used to link to different parts of the same page or to initiate actions like sending emails. For example:
        </p>
        <pre>
          <code>
            {`<a href="#section2">Go to Section 2</a>`}
          </code>
        </pre>
        <pre>
          <code>
            {`<a href="mailto:example@example.com">Email Us</a>`}
          </code>
        </pre>
    

                </div>
            )}


           


            {selectedChapter === 'chapter12' && (
              <div className={style.chaptercontent}>

             <h1 className={style.heading} > Images</h1>



                <p>In HTML, images are included using the <code>&lt;img&gt;</code> tag. This tag is a self-closing element, meaning it doesn’t have a closing tag. It’s used to embed images into a web page.</p><br /> 

                <p>Here’s a basic example of how to use the <code>&lt;img&gt;</code> tag:</p>

                <pre><code>{`<img src="path-to-image.jpg" alt="Description of the image">`}</code></pre><br />

                <h3 style={{paddingBottom:"6px"}}>Key Attributes of the <code>&lt;img&gt;</code> Tag</h3>

                <ol>
                  <li><strong><code>src</code></strong>: Specifies the path to the image file. This can be a relative path (relative to the HTML file) or an absolute URL.
                    <pre><code>{`<img src="images/picture.jpg" alt="A beautiful scenery">`}</code></pre>
                  </li><br />
                  <li><strong><code>alt</code></strong>: Provides alternative text for the image if it cannot be displayed. This is important for accessibility and SEO. It describes the content or function of the image.
                    <pre><code>{`<img src="logo.png" alt="Company Logo">`}</code></pre>
                  </li><br />
                  <li><strong><code>width</code></strong> and <strong><code>height</code></strong>: Define the dimensions of the image. They can be specified in pixels or as a percentage of the containing element.
                    <pre><code>{`<img src="photo.jpg" alt="Sample Photo" width="300" height="200">`}</code></pre>
                  </li><br />
                  <li><strong><code>title</code></strong>: Gives additional information about the image, usually displayed as a tooltip when the user hovers over the image.
                    <pre><code>{`<img src="icon.png" alt="Icon" title="This is an icon">`}</code></pre>
                  </li>
                </ol>

                <h3>Example</h3>

                <p>Here’s a full example of an HTML snippet that includes an image:</p>

                <pre><code>{`<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Image Example</title>
</head>
<body>
    <h1>Welcome to My Website</h1>
    <img src="images/landscape.jpg" alt="A breathtaking landscape" width="600">
    <p>This is an example of how to include an image in an HTML page.</p>
</body>
</html>`}</code></pre>

                <p>In this example, the image located at <code>images/landscape.jpg</code> is displayed with a width of 600 pixels and includes descriptive text for accessibility.</p>



              </div>
            )}






            {selectedChapter === 'chapter13' && (
              <div className={style.chaptercontent}>
                 <h1 className={style.heading} > Background Image</h1>

                <p>In HTML, you can use background images to add visual interest to your web pages. Background images are typically set using CSS, which allows you to control the appearance and placement of the images. Here’s a basic guide on how to use background images in your HTML documents:</p><br />

                <h2 style={{paddingBottom:"6px"}}>Using CSS for Background Images</h2>

                <ol>
                  <li>
                    <strong>Inline CSS:</strong> You can add a background image directly within an HTML element using the <code>style</code> attribute. This method is less common but useful for quick changes.
                    <pre><code>{`<div style={{ backgroundImage: "url('path/to/image.jpg')" }}> {/* Content here */} </div>`}</code></pre>
                  </li><br />

                  <li>
                    <strong>Internal CSS:</strong> You can define the background image within a <code>&lt;style&gt;</code> block in the <code>&lt;head&gt;</code> section of your HTML document.
                    <pre><code>{`<html>
<head>
  <style>
    .background {
      background-image: url('path/to/image.jpg');
      background-size: cover; /* Ensures the image covers the entire element */
      background-position: center; /* Centers the image */
      background-repeat: no-repeat; /* Prevents the image from repeating */
    }
  </style>
</head>
<body>
  <div class="background"> {/* Content here */} </div>
</body>
</html>`}</code></pre>
                  </li><br />

                  <li>
                    <strong>External CSS:</strong> This is the most common method, where you define the background image in a separate CSS file. This approach is useful for maintaining and managing styles across multiple pages.
                    <pre><code>{`/* styles.css */
.background {
  background-image: url('path/to/image.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}`}</code></pre>
                    <pre><code>{`<html>
<head>
  <link rel="stylesheet" href="styles.css">
</head>
<body>
  <div class="background"> {/* Content here */} </div>
</body>
</html>`}</code></pre>
                  </li>
                </ol><br/>

                <h2 style={{paddingBottom:"6px"}}>CSS Background Image Properties</h2>
                <ul>
                  <li><code>background-image</code>: Specifies the image to use as the background.</li><br />
                  <li><code>background-size</code>: Controls the size of the background image. Common values are <code>cover</code> (scales the image to cover the element) and <code>contain</code> (scales the image to fit the element while preserving its aspect ratio).</li><br />
                  <li><code>background-position</code>: Sets the starting position of the background image (e.g., <code>center</code>, <code>top right</code>).</li><br />
                  <li><code>background-repeat</code>: Determines whether the background image repeats (e.g., <code>no-repeat</code>, <code>repeat-x</code>, <code>repeat-y</code>).</li>
                </ul>

                <h4>Example</h4>
                <pre><code>{`<html>
<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Background Image Example</title>
  <link rel="stylesheet" href="styles.css">
</head>
<body>
  <div class="background">
    <h1>Welcome to My Website</h1>
  </div>
</body>
</html>`}</code></pre>

                <pre><code>{`/* styles.css */
.background {
  background-image: url('background.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh; /* Makes the div full viewport height */
  color: white; /* Sets text color to white for better contrast */
  display: flex;
  align-items: center;
  justify-content: center;
}`}</code></pre>

                <p>This example sets a background image for a <code>&lt;div&gt;</code> that covers the entire viewport height, centers the content, and ensures the image doesn’t repeat.</p>
              </div>
            )

            }




            {selectedChapter === 'chapter14' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading} > Table</h1>
                <p>An HTML table is a structure used to display tabular data on a web page. It organizes data into rows and columns, allowing for clear and systematic presentation of information. Tables in HTML are defined using a combination of <code>&lt;table&gt;</code>, <code>&lt;tr&gt;</code>, <code>&lt;th&gt;</code>, and <code>&lt;td&gt;</code> elements. Here’s a brief overview of these elements:</p><br />

                <ul>
                  <li><strong><code>&lt;table&gt;</code></strong>: Defines the table itself.</li><br />
                  <li><strong><code>&lt;tr&gt;</code></strong>: Defines a row in the table.</li><br />
                  <li><strong><code>&lt;th&gt;</code></strong>: Defines a header cell in the table, typically used for column headings.</li><br />
                  <li><strong><code>&lt;td&gt;</code></strong>: Defines a data cell in the table, used to hold the data.</li>
                </ul><br />

                <h2>Basic Structure</h2>
                <p>Here’s a simple example of an HTML table:</p>

                <pre>
                  <code>
                    {`<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>HTML Table Example</title>
    <style>
        table {
            width: 100%;
            border-collapse: collapse; /* Removes spacing between borders */
        }
        th, td {
            border: 1px solid black;
            padding: 8px;
            text-align: left;
        }
        th {
            background-color: #f2f2f2;
        }
    </style>
</head>
<body>
    <table>
        <thead>
            <tr>
                <th>Header 1</th>
                <th>Header 2</th>
                <th>Header 3</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>Row 1, Cell 1</td>
                <td>Row 1, Cell 2</td>
                <td>Row 1, Cell 3</td>
            </tr>
            <tr>
                <td>Row 2, Cell 1</td>
                <td>Row 2, Cell 2</td>
                <td>Row 2, Cell 3</td>
            </tr>
            <tr>
                <td>Row 3, Cell 1</td>
                <td>Row 3, Cell 2</td>
                <td>Row 3, Cell 3</td>
            </tr>
        </tbody>
    </table>
</body>
</html>`}
                  </code>
                </pre><br />

                <h2 style={{paddingBottom:"6px"}}>Breakdown</h2>
                <ul>
                  <li><strong><code>&lt;thead&gt;</code></strong>: (Optional) Groups the header content in a table. It can be used to separate the table headings from the rest of the data.</li><br />
                  <li><strong><code>&lt;tbody&gt;</code></strong>: (Optional) Groups the body content in a table. It is used to contain the rows of data.</li><br />
                  <li><strong><code>&lt;tfoot&gt;</code></strong>: (Optional) Groups footer content in a table. It is often used for summaries or totals.</li>
                </ul><br />

                <h2  style={{paddingBottom:"6px"}}>Attributes and Styling</h2>
                <ul>
                  <li><strong>border-collapse</strong>: CSS property used to control the spacing between borders of table cells.</li><br />
                  <li><strong>width</strong>: Sets the width of the table.</li><br />
                  <li><strong>border</strong>: Sets the border style for the table and its cells.</li>
                </ul><br />

                <h2 style={{paddingBottom:"6px"}}>Accessibility Considerations</h2>
                <ul>
                  <li><strong>Use <code>&lt;caption&gt;</code></strong>: Add a <code>&lt;caption&gt;</code> tag within the <code>&lt;table&gt;</code> element to provide a description of the table’s content.</li><br />
                  <li><strong>Headers for Rows and Columns</strong>: Use <code>&lt;th&gt;</code> tags to define header cells, which helps screen readers understand the table structure.</li>
                </ul>

                <p>Tables are a powerful way to display structured data and can be styled and formatted extensively using CSS to fit the design needs of a website.</p>






              </div>
            )

            }




            {selectedChapter === 'chapter15' && (
              <div className={style.chaptercontent}>

                   <h1 className={style.heading} >Div</h1>
                <p>
                  In HTML, the <code>&lt;div&gt;</code> element is a block-level container used to group and style sections of a webpage.
                  It's short for "division," and it doesn’t inherently have any visual representation; its purpose is
                  mainly for applying CSS styles or JavaScript functionality to sections of your HTML document.
                </p><br />
                <p>Here’s a basic example of how <code>&lt;div&gt;</code> might be used:</p>
                <pre>
                  <code>
                    {`<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Example</title>
    <style>
        .container {
            width: 80%;
            margin: 0 auto;
        }
        .header {
            background-color: #f4f4f4;
            padding: 10px;
        }
        .content {
            background-color: #fff;
            padding: 20px;
        }
        .footer {
            background-color: #f4f4f4;
            padding: 10px;
            text-align: center;
        }
    </style>
</head>
<body>
    <div class="container">
        <div class="header">
            <h1>Page Header</h1>
        </div>
        <div class="content">
            <p>This is the main content area.</p>
        </div>
        <div class="footer">
            <p>Page Footer</p>
        </div>
    </div>
</body>
</html>`}
                  </code>
                </pre>
                <p>
                  In this example:
                 
                   The <code>.container</code> <code>&lt;div&gt;</code> groups the header, content, and footer sections together and centers them.
                
                   The <code>.header</code>, <code>.content</code>, and <code>.footer</code> <code>&lt;div&gt;</code> elements apply specific styles and structure to those sections.
                
                  The <code>&lt;div&gt;</code> element is incredibly versatile and commonly used in web development for layout and design purposes.
                </p>


              </div>
            )}



{selectedChapter === 'chapter16' && (
              <div className={style.chaptercontent}>
              <h1 className={style.heading} > Layout</h1>

                <p>In HTML and web design, layout elements and techniques are used to arrange and organize content on a webpage. These techniques help ensure that the content is presented in a visually appealing and functional manner. Here’s a breakdown of key layout elements and techniques in HTML:</p><br />

                <h2 style={{paddingBottom:"6px"}}>Layout Elements</h2>

                <ol>
                  <li>
                    <strong>&lt;header&gt;</strong>
                    <p>Used for introductory content or navigation. Typically contains site logos, navigation menus, and other introductory elements.</p>
                    <pre>
                      <code>
                        {`<header>
  <h1>Website Title</h1>
  <nav>
    <a href="#">Home</a>
    <a href="#">About</a>
    <a href="#">Contact</a>
  </nav>
</header>`}
                      </code>
                    </pre>
                  </li><br />
                  <li>
                    <strong>&lt;nav&gt;</strong>
                    <p>Defines a section of navigation links. Often used inside the &lt;header&gt; or &lt;footer&gt; for site-wide navigation.</p>
                    <pre>
                      <code>
                        {`<nav>
  <ul>
    <li><a href="#">Home</a></li>
    <li><a href="#">Services</a></li>
    <li><a href="#">Contact</a></li>
  </ul>
</nav>`}
                      </code>
                    </pre>
                  </li><br />
                  <li>
                    <strong>&lt;main&gt;</strong>
                    <p>Represents the main content of the &lt;body&gt;, excluding headers, footers, and sidebars. Should be unique per page.</p>
                    <pre>
                      <code>
                        {`<main>
  <h2>Main Content</h2>
  <p>This is where the primary content of the page goes.</p>
</main>`}
                      </code>
                    </pre>
                  </li><br />
                  <li>
                    <strong>&lt;section&gt;</strong>
                    <p>Represents a section of content, typically with a heading. Used to group related content.</p>
                    <pre>
                      <code>
                        {`<section>
  <h2>Section Title</h2>
  <p>Content for this section.</p>
</section>`}
                      </code>
                    </pre>
                  </li><br />
                  <li>
                    <strong>&lt;article&gt;</strong>
                    <p>Represents a self-contained piece of content that could be distributed or reused independently.</p>
                    <pre>
                      <code>
                        {`<article>
  <h2>Article Title</h2>
  <p>Content of the article.</p>
</article>`}
                      </code>
                    </pre>
                  </li><br />
                  <li>
                    <strong>&lt;aside&gt;</strong>
                    <p>Contains content that is tangentially related to the content around it, like sidebars or pull quotes.</p>
                    <pre>
                      <code>
                        {`<aside>
  <h2>Related Information</h2>
  <p>Additional details or links related to the main content.</p>
</aside>`}
                      </code>
                    </pre>
                  </li><br />
                  <li>
                    <strong>&lt;footer&gt;</strong>
                    <p>Contains footer content, such as contact information, copyright notices, and additional links.</p>
                    <pre>
                      <code>
                        {`<footer>
  <p>&copy; 2024 My Website</p>
</footer>`}
                      </code>
                    </pre>
                  </li>
                </ol><br />

                <h2 style={{paddingBottom:"6px"}}>Layout Techniques</h2>

                <ol>
                  <li>
                    <strong>CSS Grid Layout</strong>
                    <p>A powerful layout system that allows you to create complex grid-based layouts.</p>
                    <pre>
                      <code>
                        {`.container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}
.item {
  background: lightgray;
  padding: 20px;
}`}

                        {`<div class="container">
  <div class="item">Item 1</div>
  <div class="item">Item 2</div>
  <div class="item">Item 3</div>
</div>`}
                      </code>
                    </pre>
                  </li><br />
                  <li>
                    <strong>CSS Flexbox</strong>
                    <p>A layout model that allows you to create flexible and responsive layouts.</p>
                    <pre>
                      <code>
                        {`.container {
  display: flex;
  justify-content: space-between;
}
.item {
  background: lightblue;
  padding: 20px;
}`}

                        {`<div class="container">
  <div class="item">Item 1</div>
  <div class="item">Item 2</div>
  <div class="item">Item 3</div>
</div>`}
                      </code>
                    </pre>
                  </li><br />
                  <li>
                    <strong>Float-based Layouts</strong>
                    <p>An older technique that uses the <code>float</code> property to position elements.</p>
                    <pre>
                      <code>
                        {`.container {
  overflow: auto;
}
.item {
  float: left;
  width: 30%;
  margin: 1%;
  background: lightcoral;
  padding: 20px;
}`}

                        {`<div class="container">
  <div class="item">Item 1</div>
  <div class="item">Item 2</div>
  <div class="item">Item 3</div>
</div>`}
                      </code>
                    </pre>
                  </li><br />
                  <li>
                    <strong>Positioning</strong>
                    <p>CSS positioning (<code>static</code>, <code>relative</code>, <code>absolute</code>, <code>fixed</code>, <code>sticky</code>) allows precise control over element placement.</p>
                    <pre>
                      <code>
                        {`.relative {
  position: relative;
  left: 20px;
}
.absolute {
  position: absolute;
  top: 10px;
  right: 10px;
}`}

                        {`<div class="relative">Relative Position</div>
<div class="absolute">Absolute Position</div>`}
                      </code>
                    </pre>
                  </li><br />
                  <li>
                    <strong>Responsive Design</strong>
                    <p>Techniques like media queries allow layouts to adapt to different screen sizes and orientations.</p>
                    <pre>
                      <code>
                        {`@media (max-width: 600px) {
  .container {
    flex-direction: column;
  }
}`}

                        {`<div class="container">
  <div class="item">Item 1</div>
  <div class="item">Item 2</div>
</div>`}
                      </code>
                    </pre>
                  </li>
                </ol><br />

                <h2>Conclusion</h2>
                <p>Using a combination of these layout elements and techniques allows for flexible and effective design of web pages. Modern web design often involves using CSS Grid and Flexbox for most layout tasks due to their versatility and ease of use, but understanding older techniques like floats and positioning can still be valuable for specific use cases and maintaining legacy systems.</p>




              </div>
            )}



{selectedChapter === 'chapter17' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading} > Form</h1>
                <p>
                  In HTML, a form is a way to collect user input. It is created using the
                  <code>&lt;form&gt;</code> element and can contain various input elements like text fields, checkboxes, radio buttons, and buttons. Forms are essential for interactive web applications where user data needs to be gathered and processed.
                </p><br />
                <p>Here’s a basic example of an HTML form:</p>
                <pre>
                  <code>
                    {`<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>HTML Form Example</title>
</head>
<body>
    <form action="/submit" method="post">
        <label htmlFor="name">Name:</label>
        <input type="text" id="name" name="name" required />
        
        <label htmlFor="email">Email:</label>
        <input type="email" id="email" name="email" required />
        
        <label htmlFor="message">Message:</label>
        <textarea id="message" name="message" rows="4" required></textarea>
        
        <button type="submit">Submit</button>
    </form>
</body>
</html>`}
                  </code>
                </pre>
                <p> In this example:</p>
                <ul>
                  <li>
                    The <code>&lt;form&gt;</code> element wraps all the input elements and includes attributes such as <code>action</code> and <code>method</code>:
                    <ul>
                      <li><code>action</code> specifies the URL where the form data will be sent when the form is submitted.</li><br />
                      <li><code>method</code> specifies the HTTP method to use when sending the data (usually <code>post</code> or <code>get</code>).</li>
                    </ul>
                  </li><br />
                  <li>
                    Inside the form, there are various input elements:
                    <ul>
                      <li><code>&lt;label&gt;</code> elements provide labels for the input fields.</li><br />
                      <li><code>&lt;input&gt;</code> elements are used for user inputs such as text and email.</li><br />
                      <li><code>&lt;textarea&gt;</code> is used for multi-line text input.</li><br />
                      <li><code>&lt;button type="submit"&gt;</code> creates a button that submits the form.</li>
                    </ul>
                  </li><br />
                  <li>
                    Forms can also include other elements like checkboxes (<code>&lt;input type="checkbox"&gt;</code>), radio buttons (<code>&lt;input type="radio"&gt;</code>), and select menus (<code>&lt;select&gt;</code> with <code>&lt;option&gt;</code> elements).
                  </li><br />
                  <li>
                    Forms are a fundamental part of web development for tasks like user registration, search functionality, and feedback collection.
                  </li>
                </ul>
              </div>
            )}







{selectedChapter === 'chapter18' && (
              <div className={style.chaptercontent}>
                 <h1 className={style.heading} >Form Attributes</h1>



                <p>
                  In HTML, form attributes are used to define properties and behaviors of forms and their elements. Here’s a rundown of key form-related attributes:
                </p><br />

                <h2 style={{paddingBottom:"6px"}}>Form Tag Attributes</h2>

                <ol>
                  <li>
                    <strong>action</strong>: Specifies the URL where the form data will be sent when the form is submitted.
                    <pre>
                      <code>{'<form action="/submit-form">'}</code>
                    </pre>
                  </li><br />

                  <li>
                    <strong>method</strong>: Defines the HTTP method (GET or POST) the browser uses to submit the form data.
                    <pre>
                      <code>{'<form method="post">'}</code>
                    </pre>
                  </li><br />

                  <li>
                    <strong>enctype</strong>: Specifies how the form data should be encoded when submitting it to the server. Common values include <code>application/x-www-form-urlencoded</code> (default), <code>multipart/form-data</code> (for file uploads), and <code>text/plain</code>.
                    <pre>
                      <code>{'<form enctype="multipart/form-data">'}</code>
                    </pre>
                  </li><br />

                  <li>
                    <strong>target</strong>: Determines where to display the response after submitting the form. Values can include <code>_self</code> (default, same frame), <code>_blank</code> (new window or tab), <code>_parent</code>, and <code>_top</code>.
                    <pre>
                      <code>{'<form target="_blank">'}</code>
                    </pre>
                  </li><br />

                  <li>
                    <strong>name</strong>: Assigns a name to the form, which can be used to reference it in scripts.
                    <pre>
                      <code>{'<form name="myForm">'}</code>
                    </pre>
                  </li><br />

                  <li>
                    <strong>autocomplete</strong>: Controls whether the browser should autocomplete form fields. Values can be <code>on</code> (default) or <code>off</code>.
                    <pre>
                      <code>{'<form autocomplete="off">'}</code>
                    </pre>
                  </li><br />

                  <li>
                    <strong>novalidate</strong>: Disables the default HTML5 form validation for the form.
                    <pre>
                      <code>{'<form novalidate>'}</code>
                    </pre>
                  </li>
                </ol><br />

                <h2 style={{paddingBottom:"6px"}}>Input, Select, and Textarea Attributes</h2>

                <ol>
                  <li>
                    <strong>type</strong> (for <code>&lt;input&gt;</code>): Specifies the type of input element (e.g., text, password, checkbox).
                    <pre>
                      <code>{'<input type="text">'}</code>
                    </pre>
                  </li><br />

                  <li>
                    <strong>name</strong>: Gives a name to the form element, which is used to reference the data in form submissions.
                    <pre>
                      <code>{'<input name="username">'}</code>
                    </pre>
                  </li><br />

                  <li>
                    <strong>value</strong>: Sets the default value of the form element.
                    <pre>
                      <code>{'<input value="Submit">'}</code>
                    </pre>
                  </li><br />

                  <li>
                    <strong>placeholder</strong>: Provides a hint to the user about what to enter in the input field.
                    <pre>
                      <code>{'<input placeholder="Enter your name">'}</code>
                    </pre>
                  </li><br />

                  <li>
                    <strong>required</strong>: Makes the form field mandatory to fill out before submission.
                    <pre>
                      <code>{'<input required>'}</code>
                    </pre>
                  </li><br />

                  <li>
                    <strong>disabled</strong>: Disables the form element so it can’t be interacted with or submitted.
                    <pre>
                      <code>{'<input disabled>'}</code>
                    </pre>
                  </li><br />

                  <li>
                    <strong>readonly</strong>: Makes the form field non-editable but still visible.
                    <pre>
                      <code>{'<input readonly>'}</code>
                    </pre>
                  </li><br />

                  <li>
                    <strong>maxlength</strong> (for <code>&lt;input&gt;</code> and <code>&lt;textarea&gt;</code>): Limits the number of characters that can be entered.
                    <pre>
                      <code>{'<input maxlength="10">'}</code>
                    </pre>
                  </li><br />

                  <li>
                    <strong>rows</strong> and <strong>cols</strong> (for <code>&lt;textarea&gt;</code>): Define the visible height and width of the textarea.
                    <pre>
                      <code>{'<textarea rows="4" cols="50"></textarea>'}</code>
                    </pre>
                  </li><br />

                  <li>
                    <strong>multiple</strong> (for <code>&lt;input type="file"&gt;</code>): Allows multiple files to be selected for upload.
                    <pre>
                      <code>{'<input type="file" multiple>'}</code>
                    </pre>
                  </li>
                </ol><br />

                <p>
                  These attributes help you control how forms behave and how users interact with them.
                </p>



              </div>
            )}



{selectedChapter === 'chapter19' && (

<div className={style.chaptercontent}>
<h1 className={style.heading} >Form Elements</h1>

  <p>
    In HTML, a form element is a container that allows users to submit data to a server. It groups together various input elements, such as text fields, checkboxes, and buttons, into a single unit that can be submitted as a whole. Forms are essential for capturing user input and interacting with backend services.
  </p><br />

  <p>Here’s a breakdown of the key aspects of the <code>&lt;form&gt;</code> element in HTML:</p>

  <h2>Basic Structure</h2>
  <pre>
    <code>{`<form action="/submit-url" method="post">\n  {/* Form elements go here */}\n</form>`}</code>
  </pre><br />

  <h2 style={{paddingBottom:"6px"}}>Key Attributes of the <code>&lt;form&gt;</code> Element</h2>
  <ol>
    <li>
      <strong>action</strong>: Specifies the URL where the form data will be sent when the form is submitted. This is usually the endpoint of a server-side script that will process the form data.
      <pre>
        <code>{`<form action="/submit-form">`}</code>
      </pre>
    </li><br />
    <li>
      <strong>method</strong>: Defines the HTTP method (GET or POST) used to send the form data.
      <ul>
        <li><code>GET</code>: Appends form data to the URL, suitable for non-sensitive data retrieval.</li><br />
        <li><code>POST</code>: Sends form data in the body of the request, suitable for sensitive or large amounts of data.</li>
      </ul><br />
      <pre>
        <code>{`<form method="post">`}</code>
      </pre>
    </li><br />
    <li>
      <strong>enctype</strong>: Determines how the form data should be encoded when submitting it to the server. It is most relevant when submitting files.
      <ul>
        <li><code>application/x-www-form-urlencoded</code> (default): Encodes form data as key-value pairs.</li><br />
        <li><code>multipart/form-data</code>: Used for forms that include file uploads.</li><br />
        <li><code>text/plain</code>: Encodes form data as plain text.</li>
      </ul><br />
      <pre>
        <code>{`<form enctype="multipart/form-data">`}</code>
      </pre>
    </li><br />
    <li>
      <strong>target</strong>: Specifies where to display the response after the form is submitted.
      <ul>
        <li><code>_self</code>: Default, loads the response in the same frame.</li><br />
        <li><code>_blank</code>: Loads the response in a new window or tab.</li><br />
        <li><code>_parent</code>: Loads the response in the parent frame.</li><br />
        <li><code>_top</code>: Loads the response in the full window.</li>
      </ul>
      <pre>
        <code>{`<form target="_blank">`}</code>
      </pre>
    </li><br />
    <li>
      <strong>name</strong>: Assigns a name to the form, which can be used to reference it in scripts.
      <pre>
        <code>{`<form name="myForm">`}</code>
      </pre>
    </li><br />
    <li>
      <strong>autocomplete</strong>: Controls whether the browser should automatically fill in form fields based on user history.
      <ul>
        <li><code>on</code>: Allows the browser to autocomplete fields.</li><br />
        <li><code>off</code>: Prevents the browser from autocompleting fields.</li>
      </ul><br />
      <pre>
        <code>{`<form autocomplete="off">`}</code>
      </pre>
    </li><br />
    <li>
      <strong>novalidate</strong>: Disables HTML5 form validation for the form. This is useful if you want to handle validation on the client side with JavaScript.
      <pre>
        <code>{`<form novalidate>`}</code>
      </pre>
    </li>
  </ol><br />

  <h2 style={{paddingBottom:"6px"}}>Common Form Elements</h2>
  <ol>
    <li>
      <strong>&lt;input&gt;</strong>: Allows various types of user input, including text, passwords, checkboxes, radio buttons, and more.
      <pre>
        <code>{`<input type="text" name="username">`}</code>
      </pre>
    </li><br />
    <li>
      <strong>&lt;textarea&gt;</strong>: Provides a multi-line text input area.
      <pre>
        <code>{`<textarea name="message"></textarea>`}</code>
      </pre>
    </li><br />
    <li>
      <strong>&lt;select&gt;</strong>: Creates a dropdown list of options.
      <pre>
        <code>{`<select name="options">\n  <option value="option1">Option 1</option>\n  <option value="option2">Option 2</option>\n</select>`}</code>
      </pre>
    </li><br />
    <li>
      <strong>&lt;button&gt;</strong>: Defines a clickable button.
      <pre>
        <code>{`<button type="submit">Submit</button>`}</code>
      </pre>
    </li><br />
    <li>
      <strong>&lt;fieldset&gt;</strong>: Groups related form controls and labels, often with a legend.
      <pre>
        <code>{`<fieldset>\n  <legend>Personal Information</legend>\n  {/* Form controls go here */}\n</fieldset>`}</code>
      </pre>
    </li><br />
    <li>
      <strong>&lt;legend&gt;</strong>: Provides a caption for the <code>&lt;fieldset&gt;</code>.
      <pre>
        <code>{`<legend>Account Information</legend>`}</code>
      </pre>
    </li><br />
    <li>
      <strong>&lt;label&gt;</strong>: Defines a label for an <code>&lt;input&gt;</code> element, improving accessibility and usability.
      <pre>
        <code>{`<label for="username">Username:</label>\n<input type="text" id="username" name="username">`}</code>
      </pre>
    </li>
  </ol><br />

  <h2>Example Form</h2>
  <pre>
    <code>{`<form action="/submit" method="post">\n  <label for="name">Name:</label>\n  <input type="text" id="name" name="name" required>\n  \n  <label for="email">Email:</label>\n  <input type="email" id="email" name="email" required>\n  \n  <fieldset>\n    <legend>Gender</legend>\n    <label>\n      <input type="radio" name="gender" value="male"> Male\n    </label>\n    <label>\n      <input type="radio" name="gender" value="female"> Female\n    </label>\n  </fieldset>\n  \n  <label for="comments">Comments:</label>\n  <textarea id="comments" name="comments"></textarea>\n  \n  <button type="submit">Submit</button>\n</form>`}</code>
  </pre>

  <p>
    This form will send data to <code>/submit</code> using the POST method when the user clicks the submit button. It includes text inputs, radio buttons, a textarea, and a submit button.
  </p>



</div>
)}






{selectedChapter === 'chapter20' && (
              <div className={style.chaptercontent}>

<h1 className={style.heading} >Input Types</h1>

          <p>
            The <code>input</code> element in HTML can be of various types, which determine the kind of data that can be entered and how it is presented. The <code>type</code> attribute specifies the type of <code>&lt;input&gt;</code> element and influences its behavior and appearance. Here’s a comprehensive list of HTML input types and their uses:
          </p><br />
          <h3 style={{paddingBottom:"6px"}}>Common HTML Input Types</h3>
          <ol>
            <li>
              <strong>text</strong>: A single-line text field.
              <pre>
                <code>{`<input type="text" name="username" placeholder="Enter your username">`}</code>
              </pre>
            </li><br />
            <li>
              <strong>password</strong>: A field that hides user input, typically used for passwords.
              <pre>
                <code>{`<input type="password" name="password" placeholder="Enter your password">`}</code>
              </pre>
            </li><br />
            <li>
              <strong>email</strong>: A field for entering email addresses; browsers can validate the format.
              <pre>
                <code>{`<input type="email" name="email" placeholder="Enter your email">`}</code>
              </pre>
            </li><br />
            <li>
              <strong>number</strong>: A field for entering numerical values with optional restrictions (like min and max).
              <pre>
                <code>{`<input type="number" name="age" min="1" max="100" placeholder="Enter your age">`}</code>
              </pre>
            </li><br />
            <li>
              <strong>tel</strong>: A field for entering telephone numbers.
              <pre>
                <code>{`<input type="tel" name="phone" placeholder="Enter your phone number">`}</code>
              </pre>
            </li><br />
            <li>
              <strong>url</strong>: A field for entering URLs; browsers can validate the format.
              <pre>
                <code>{`<input type="url" name="website" placeholder="Enter your website URL">`}</code>
              </pre>
            </li><br />
            <li>
              <strong>date</strong>: A field for selecting dates from a calendar picker.
              <pre>
                <code>{`<input type="date" name="birthdate">`}</code>
              </pre>
            </li><br />
            <li>
              <strong>time</strong>: A field for selecting time.
              <pre>
                <code>{`<input type="time" name="appointment">`}</code>
              </pre>
            </li><br />
            <li>
              <strong>datetime-local</strong>: A field for selecting both date and time, without a time zone.
              <pre>
                <code>{`<input type="datetime-local" name="event">`}</code>
              </pre>
            </li><br />
            <li>
              <strong>range</strong>: A slider for selecting a value from a range.
              <pre>
                <code>{`<input type="range" name="volume" min="0" max="100" value="50">`}</code>
              </pre>
            </li><br />
            <li>
              <strong>checkbox</strong>: A box that can be checked or unchecked.
              <pre>
                <code>{`<input type="checkbox" name="subscribe" value="newsletter"> Subscribe to newsletter`}</code>
              </pre>
            </li><br />
            <li>
              <strong>radio</strong>: A set of options where only one can be selected at a time.
              <pre>
                <code>{`<input type="radio" name="gender" value="male"> Male\n<input type="radio" name="gender" value="female"> Female`}</code>
              </pre>
            </li><br />
            <li>
              <strong>file</strong>: A field for uploading files.
              <pre>
                <code>{`<input type="file" name="resume">`}</code>
              </pre>
            </li><br />
            <li>
              <strong>submit</strong>: A button to submit the form.
              <pre>
                <code>{`<input type="submit" value="Submit">`}</code>
              </pre>
            </li><br />
            <li>
              <strong>reset</strong>: A button to reset all form fields to their default values.
              <pre>
                <code>{`<input type="reset" value="Reset">`}</code>
              </pre>
            </li><br />
            <li>
              <strong>hidden</strong>: A field that is not visible to the user but can hold data.
              <pre>
                <code>{`<input type="hidden" name="sessionToken" value="xyz123">`}</code>
              </pre>
            </li><br />
            <li>
              <strong>search</strong>: A text field optimized for search queries.
              <pre>
                <code>{`<input type="search" name="query" placeholder="Search">`}</code>
              </pre>
            </li>
          </ol><br />
          <h3>Example Form</h3>
          <pre>
            <code>{`<form>\n  <label for="username">Username:</label>\n  <input type="text" id="username" name="username">\n\n  <label for="password">Password:</label>\n  <input type="password" id="password" name="password">\n\n  <label for="email">Email:</label>\n  <input type="email" id="email" name="email">\n\n  <label for="age">Age:</label>\n  <input type="number" id="age" name="age" min="1" max="100">\n\n  <label for="phone">Phone:</label>\n  <input type="tel" id="phone" name="phone">\n\n  <label for="website">Website:</label>\n  <input type="url" id="website" name="website">\n\n  <label for="birthdate">Birthdate:</label>\n  <input type="date" id="birthdate" name="birthdate">\n\n  <label for="appointment">Appointment Time:</label>\n  <input type="time" id="appointment" name="appointment">\n\n  <label for="event">Event:</label>\n  <input type="datetime-local" id="event" name="event">\n\n  <label for="volume">Volume:</label>\n  <input type="range" id="volume" name="volume" min="0" max="100">\n\n  <label>\n  <input type="checkbox" name="subscribe" value="newsletter"> Subscribe to newsletter\n  </label>\n\n  <fieldset>\n    <legend>Gender:</legend>\n    <label>\n      <input type="radio" name="gender" value="male"> Male\n    </label>\n    <label>\n      <input type="radio" name="gender" value="female"> Female\n    </label>\n  </fieldset>\n\n  <label for="resume">Resume:</label>\n  <input type="file" id="resume" name="resume">\n\n  <input type="submit" value="Submit">\n  <input type="reset" value="Reset">\n</form>`}</code>
          </pre><br />
          <h3>Summary</h3>
          <p>
            Each <code>input</code> type serves a specific purpose and helps in collecting various kinds of data from users, while also providing different user interface elements and validation rules.
          </p>
      


              </div>
            )}



{selectedChapter === 'chapter21' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading} > Input Attributes</h1>

                <p>
                  In HTML, input attributes are used with the <code>&lt;input&gt;</code> element to define the type of data that can be entered into a form field and to customize the behavior and appearance of the input field. Here are some commonly used HTML input attributes:
                </p><br />

                <ol>
                  <li>
                    <strong>type</strong>: Specifies the type of input control to display. For example, <code>text</code>, <code>password</code>, <code>checkbox</code>, <code>radio</code>, <code>date</code>, <code>email</code>, <code>number</code>, etc.
                    <pre>
                      <code>
                        {`<input type="text" />\n<input type="email" />`}
                      </code>
                    </pre>
                  </li><br />
                  <li>
                    <strong>name</strong>: Defines the name of the input element, which is used to reference form data after submission.
                    <pre>
                      <code>
                        {`<input type="text" name="username" />`}
                      </code>
                    </pre>
                  </li><br />
                  <li>
                    <strong>value</strong>: Sets the default value of the input field.
                    <pre>
                      <code>
                        {`<input type="text" value="default text" />`}
                      </code>
                    </pre>
                  </li><br />
                  <li>
                    <strong>placeholder</strong>: Provides a hint to the user about what to enter in the input field.
                    <pre>
                      <code>
                        {`<input type="text" placeholder="Enter your name" />`}
                      </code>
                    </pre>
                  </li><br />
                  <li>
                    <strong>required</strong>: Makes the input field mandatory; the form cannot be submitted until this field is filled out.
                    <pre>
                      <code>
                        {`<input type="text" required />`}
                      </code>
                    </pre>
                  </li><br />
                  <li>
                    <strong>readonly</strong>: Makes the input field read-only, meaning the user cannot change its value.
                    <pre>
                      <code>
                        {`<input type="text" value="Read-only text" readonly />`}
                      </code>
                    </pre>
                  </li><br />
                  <li>
                    <strong>disabled</strong>: Disables the input field, preventing the user from interacting with it.
                    <pre>
                      <code>
                        {`<input type="text" value="Disabled text" disabled />`}
                      </code>
                    </pre>
                  </li><br />
                  <li>
                    <strong>maxlength</strong>: Limits the number of characters that can be entered in the input field.
                    <pre>
                      <code>
                        {`<input type="text" maxlength="10" />`}
                      </code>
                    </pre>
                  </li><br />
                  <li>
                    <strong>min</strong> and <strong>max</strong>: Used with <code>number</code>, <code>date</code>, and <code>range</code> input types to specify the minimum and maximum allowable values.
                    <pre>
                      <code>
                        {`<input type="number" min="1" max="10" />`}
                      </code>
                    </pre>
                  </li><br />
                  <li>
                    <strong>pattern</strong>: Defines a regular expression that the input's value must match to be valid.
                    <pre>
                      <code>
                        {`<input type="text" pattern="[A-Za-z]{3}" />`}
                      </code>
                    </pre>
                  </li><br />
                  <li>
                    <strong>size</strong>: Specifies the width of the input field in characters.
                    <pre>
                      <code>
                        {`<input type="text" size="20" />`}
                      </code>
                    </pre>
                  </li><br />
                  <li>
                    <strong>autofocus</strong>: Automatically focuses the input field when the page loads.
                    <pre>
                      <code>
                        {`<input type="text" autofocus />`}
                      </code>
                    </pre>
                  </li><br />
                  <li>
                    <strong>autocomplete</strong>: Controls whether the browser should autocomplete the field. It can be set to "on" or "off".
                    <pre>
                      <code>
                        {`<input type="text" autocomplete="off" />`}
                      </code>
                    </pre>
                  </li>
                </ol><br />

                <p>
                  These attributes help control the behavior and user experience of form inputs in web applications.
                </p>



              </div>
            )}

            

{selectedChapter === 'chapter22' && (
              <div className={style.chaptercontent}>
             <h1 className={style.heading} >Input Form Attributes</h1>


                <p>
                  In HTML, the <code>form</code> element is used to collect user input. The <code>input</code> element, within a form, is used to create various types of form controls. These controls include text fields, checkboxes, radio buttons, and more. The <code>input</code> element has several attributes that you can use to specify its behavior and appearance. Here’s a summary of some commonly used <code>input</code> attributes:
                </p><br />

                <ol>
                  <li>
                    <strong><code>type</code></strong>: Defines the type of input control to display (e.g., text, password, checkbox, radio, etc.).
                    <pre><code>{`<input type="text" name="username">`}</code></pre>
                  </li><br />

                  <li>
                    <strong><code>name</code></strong>: Specifies the name of the input control, which is used to reference form data after submission.
                    <pre><code>{`<input type="email" name="user_email">`}</code></pre>
                  </li><br />

                  <li>
                    <strong><code>value</code></strong>: Defines the initial value of the input control.
                    <pre><code>{`<input type="text" name="username" value="JohnDoe">`}</code></pre>
                  </li><br />

                  <li>
                    <strong><code>id</code></strong>: Provides a unique identifier for the input element, which can be used for styling with CSS or scripting with JavaScript.
                    <pre><code>{`<input type="text" id="username" name="username">`}</code></pre>
                  </li><br />

                  <li>
                    <strong><code>placeholder</code></strong>: Provides a hint or description of the expected value in the input field, which disappears when the user starts typing.
                    <pre><code>{`<input type="text" placeholder="Enter your username">`}</code></pre>
                  </li><br />

                  <li>
                    <strong><code>required</code></strong>: Indicates that the input field must be filled out before submitting the form.
                    <pre><code>{`<input type="password" name="password" required>`}</code></pre>
                  </li><br />

                  <li>
                    <strong><code>disabled</code></strong>: Disables the input field, making it unmodifiable and unselectable.
                    <pre><code>{`<input type="text" name="username" disabled>`}</code></pre>
                  </li><br />

                  <li>
                    <strong><code>readonly</code></strong>: Makes the input field non-editable but still selectable, allowing users to copy its content.
                    <pre><code>{`<input type="text" name="username" readonly>`}</code></pre>
                  </li><br />

                  <li>
                    <strong><code>maxlength</code></strong>: Limits the number of characters that can be entered in the input field.
                    <pre><code>{`<input type="text" name="username" maxlength="20">`}</code></pre>
                  </li><br />

                  <li>
                    <strong><code>min</code> and <code>max</code></strong>: Define the minimum and maximum values for numeric inputs.
                    <pre><code>{`<input type="number" name="age" min="1" max="120">`}</code></pre>
                  </li><br />

                  <li>
                    <strong><code>step</code></strong>: Specifies the legal number intervals for numeric inputs.
                    <pre><code>{`<input type="number" name="quantity" step="0.01">`}</code></pre>
                  </li><br />

                  <li>
                    <strong><code>pattern</code></strong>: Defines a regular expression pattern that the input value must match.
                    <pre><code>{`<input type="text" name="zip" pattern="\\d{5}">`}</code></pre>
                  </li><br />

                  <li>
                    <strong><code>autofocus</code></strong>: Automatically focuses the input field when the page loads.
                    <pre><code>{`<input type="text" name="username" autofocus>`}</code></pre>
                  </li><br />

                  <li>
                    <strong><code>form</code></strong>: Associates the input element with a specific form using the form's ID.
                    <pre><code>{`<input type="text" name="username" form="myForm">`}</code></pre>
                  </li>
                </ol>

                <p>
                  These attributes help you control the behavior, validation, and presentation of form elements in your HTML forms.
                </p>
              </div>
            )}


           
            {selectedChapter === 'chapter23' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading} > Iframes</h1>
                <p>An <code>&lt;iframe&gt;</code> (short for inline frame) in HTML is an element used to embed another HTML document within the current document. Essentially, it allows you to display a webpage or a portion of a webpage inside another webpage.</p><br />
                <p>Here’s a basic example of an <code>&lt;iframe&gt;</code>:</p>
                <pre>
                  <code>{`<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Example of iframe</title>
</head>
<body>
    <h1>Embedding an iframe</h1>
    <iframe src="https://www.example.com" width="600" height="400" frameborder="0" allowfullscreen></iframe>
</body>
</html>`}</code>
                </pre><br />
                <h2 style={{paddingBottom:"6px"}}>Key Attributes of <code>&lt;iframe&gt;</code>:</h2>
                <ul>
                  <li><strong>src</strong>: Specifies the URL of the document to be displayed in the iframe.</li><br />
                  <li><strong>width</strong> and <strong>height</strong>: Define the dimensions of the iframe.</li><br />
                  <li><strong>frameborder</strong>: Controls the border around the iframe (use <code>0</code> for no border).</li><br />
                  <li><strong>allowfullscreen</strong>: Allows the iframe to be displayed in fullscreen mode.</li><br />
                  <li><strong>name</strong>: Assigns a name to the iframe, which can be used to target it with links or scripts.</li><br />
                  <li><strong>loading</strong>: Can be set to <code>lazy</code> to enable lazy loading of the iframe content, which improves performance by deferring the loading of the iframe until it is in the viewport.</li>
                </ul><br />
                <h2 style={{paddingBottom:"6px"}}>Use Cases:</h2>
                <ul>
                  <li><strong>Embedding External Content</strong>: Useful for including external content like maps, videos, or other interactive elements without needing to integrate them directly into your webpage.</li><br />
                  <li><strong>Advertisements</strong>: Often used for placing ads within a webpage.</li><br />
                  <li><strong>Content Isolation</strong>: Provides a way to isolate content or third-party code from the main page.</li>
                </ul><br />
                <h2>Security Considerations:</h2>
                <p>When using iframes, be mindful of security implications such as:</p>
                <ul>
                  <li><strong>Cross-site Scripting (XSS)</strong>: Content within iframes could potentially be manipulated if not handled carefully.</li><br />
                  <li><strong>Clickjacking</strong>: Malicious sites might use iframes to trick users into clicking on something different from what they perceive. The <code>X-Frame-Options</code> HTTP header can help mitigate this by controlling whether a page can be displayed in a frame.</li>
                </ul>
                <p>Using iframes effectively requires understanding these aspects and ensuring that the embedded content is trustworthy and that you handle any potential security issues appropriately.</p>
              </div>
            )}
 

            {selectedChapter === 'chapter24' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading} > Javascript</h1>
                <p>
                  JavaScript in HTML refers to the use of JavaScript code within HTML documents to add interactivity, enhance functionality, and create dynamic behavior on web pages. JavaScript is a versatile scripting language that runs in the browser, allowing developers to manipulate HTML and CSS, handle events, and communicate with servers.
                </p><br />
                <p>Here’s a breakdown of how JavaScript is used within HTML:</p>

                <h2>1. Embedding JavaScript in HTML</h2><br />

                <h3>Inline JavaScript</h3>
                <p>
                  JavaScript code can be placed directly within an HTML element's event attribute. This is called inline JavaScript.
                </p>
                <pre>
                  {`<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Inline JavaScript Example</title>
</head>
<body>
    <button onclick="alert('Hello, World!')">Click Me</button>
</body>
</html>`}
                </pre><br />

                <h3>Internal JavaScript</h3>
                <p>
                  JavaScript code can be included within the <code>&lt;script&gt;</code> tag in the HTML document. This is useful for scripts that are specific to a single page.
                </p>
                <pre>
                  {`<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Internal JavaScript Example</title>
</head>
<body>
    <button id="myButton">Click Me</button>

    <script>
        document.getElementById('myButton').onclick = function() {
            alert('Button was clicked!');
        };
    </script>
</body>
</html>`}
                </pre><br />


                <h3>External JavaScript</h3>
                <p>
                  JavaScript code can be placed in an external file and linked to the HTML document using the <code>&lt;script&gt;</code> tag. This is useful for separating JavaScript code from HTML and reusing scripts across multiple pages.
                </p>
                <p><strong>script.js:</strong></p>
                <pre>
                  {`function showAlert() {
    alert('Hello from an external script!');
}`}
                </pre>
                <p><strong>index.html:</strong></p>
                <pre>
                  {`<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>External JavaScript Example</title>
</head>
<body>
    <button onclick="showAlert()">Click Me</button>

    <script src="script.js" defer></script>
</body>
</html>`}
                </pre><br />


                <h2 style={{paddingBottom:"6px"}}>2. Common Uses of JavaScript in HTML</h2>
                <ul>
                  <li><strong>Event Handling:</strong> JavaScript can be used to respond to user actions, such as clicks, form submissions, or keyboard events.</li><br />
                  <li><strong>DOM Manipulation:</strong> JavaScript can change the content, structure, or style of HTML elements dynamically.</li><br />
                  <li><strong>Form Validation:</strong> JavaScript can validate user input before submitting forms, ensuring data correctness and improving user experience.</li><br />
                  <li><strong>Asynchronous Operations:</strong> JavaScript can perform asynchronous operations, such as fetching data from a server using AJAX (Asynchronous JavaScript and XML) or the Fetch API, without reloading the page.</li><br />
                  <li><strong>Animations and Effects:</strong> JavaScript can create animations and visual effects to enhance the user interface.</li>
                </ul><br />


                <h2 style={{paddingBottom:"6px"}}>3. Best Practices</h2>
                <ul>
                  <li><strong>Separation of Concerns:</strong> Keep JavaScript code separate from HTML by using external script files whenever possible.</li><br />
                  <li><strong>Minimize Inline JavaScript:</strong> Avoid inline JavaScript to keep HTML clean and maintainable.</li><br />
                  <li><strong>Use <code>defer</code> or <code>async</code>:</strong> When including external JavaScript files, use the <code>defer</code> or <code>async</code> attributes to control how the script is loaded and executed.</li><br />
                  <li><strong>Security:</strong> Be mindful of security concerns such as Cross-Site Scripting (XSS). Always validate and sanitize user inputs.</li>
                </ul><br />

                <p>By integrating JavaScript into HTML, developers can create rich, interactive web applications that respond to user actions and enhance the overall user experience.</p>



              </div>
            )}



            {selectedChapter === 'chapter25' && (
              <div className={style.chaptercontent}>
                 <h1 className={style.heading} > File Path</h1>

                <p>In HTML, a file path is a way of specifying the location of a file relative to the HTML document. It is used to link to other files like images, stylesheets, scripts, or other HTML documents.</p><br />

                <p>File paths can be either <strong>absolute</strong> or <strong>relative</strong>:</p>

                <ol>
                  <li>
                    <strong>Absolute File Path</strong>: This specifies the complete URL to the file. For example:
                    <pre>
                      <code>
                        &lt;img src="https://www.example.com/images/pic.jpg" alt="Picture"&gt;
                      </code>
                    </pre>
                  </li>
                  <li>
                    <strong>Relative File Path</strong>: This specifies the location of the file relative to the location of the current HTML document. This is often used to keep file structures organized within a project. For example:
                    <ul>
                      <li>
                        <strong>Same Directory</strong>:
                        <pre>
                          <code>
                            &lt;link rel="stylesheet" href="styles.css"&gt;
                          </code>
                        </pre>
                      </li>
                      <li>
                        <strong>Subdirectory</strong>:
                        <pre>
                          <code>
                            &lt;script src="scripts/main.js"&gt;&lt;/script&gt;
                          </code>
                        </pre>
                      </li>
                      <li>
                        <strong>Parent Directory</strong>:
                        <pre>
                          <code>
                            &lt;img src="../images/pic.jpg" alt="Picture"&gt;
                          </code>
                        </pre>
                      </li>
                    </ul>
                  </li>
                </ol>

                <p>Here’s a breakdown of some common relative path examples:</p>
                <ul>
                  <li><code>index.html</code> (a file in the same directory)</li><br />
                  <li><code>css/style.css</code> (a file in a subdirectory named <code>css</code>)</li><br />
                  <li><code>../images/logo.png</code> (a file in the parent directory, within a subdirectory named <code>images</code>)</li>
                </ul>

                <p>Using the correct file path is crucial for making sure that links, images, and resources load correctly when your HTML page is viewed.</p>




              </div>
            )}


           


            {selectedChapter === 'chapter26' && (
              <div className={style.chaptercontent}>
                 <h1 className={style.heading} > Responsive</h1>


                <p>
                  In HTML and web design, <strong>"responsive"</strong> refers to a design approach that ensures web content looks and functions well across a variety of devices and screen sizes. This approach aims to create a seamless user experience whether the content is viewed on a smartphone, tablet, laptop, or desktop computer.
                </p><br />
                <p><strong>Key aspects of responsive design include:</strong></p>
                <ol>
                  <li>
                    <strong>Fluid Grids:</strong> Rather than using fixed-width layouts, responsive designs use fluid grids that scale proportionally. This means that the layout adapts to the size of the screen.
                  </li><br/>
                  <li>
                    <strong>Flexible Images:</strong> Images and media are also made responsive by using CSS techniques that ensure they scale appropriately within their containing elements. This prevents them from overflowing or becoming distorted on different screen sizes.
                  </li><br/>
                  <li>
                    <strong>Media Queries:</strong> CSS media queries are used to apply different styles based on the characteristics of the device or screen. For example, you might use media queries to change the layout or font size for mobile devices compared to larger screens.
                  </li><br/>
                  <li>
                    <strong>Viewport Meta Tag:</strong> This HTML meta tag is crucial for responsive design on mobile devices. It controls the layout on mobile browsers by setting the viewport width and scale. For instance:
                    <pre>
                      <code>{`<meta name="viewport" content="width=device-width, initial-scale=1">`}</code>
                    </pre>
                  </li><br/>
                  <li>
                    <strong>Responsive Typography:</strong> Text sizes and line heights that adjust based on the screen size or device's resolution ensure readability across different devices.
                  </li>
                </ol>
                <p>
                  By implementing these techniques, web designers can create websites that provide a consistent and optimized experience for users, regardless of how they access the site.
                </p>

              </div>
            )}





            {selectedChapter === 'chapter27' && (
              <div className={style.chaptercontent}>
                <h1 className={style.heading} > Entities</h1>

                <p>
                  In HTML, <strong>"entities"</strong> are special codes used to represent characters that either have special meanings in HTML or are not easily typable on a standard keyboard. These entities ensure that certain characters are rendered correctly by the browser.
                </p><br />
                <h2 style={{paddingBottom:"6px"}}>Common HTML Entities</h2>
                <ul>
                  <li>
                    <strong style={{paddingBottom:"6px"}}>Reserved Characters:</strong>
                    <ul>
                      <li>&lt; (less than) is represented as <code>&amp;lt;</code></li><br />
                      <li>&gt; (greater than) is represented as <code>&amp;gt;</code></li><br />
                      <li>&amp; (ampersand) is represented as <code>&amp;amp;</code></li><br />
                      <li>&quot; (double quote) is represented as <code>&amp;quot;</code></li><br />
                      <li>&apos; (single quote) is represented as <code>&amp;apos;</code> (less common but valid)</li>
                    </ul>
                  </li><br />
                  <li>
                    <strong style={{paddingBottom:"6px"}}>Non-Printable Characters:</strong>
                    <ul>
                      <li>&nbsp; (non-breaking space) is represented as <code>&amp;nbsp;</code></li><br />
                      <li>&copy; (copyright symbol) is represented as <code>&amp;copy;</code></li><br />
                      <li>&reg; (registered trademark symbol) is represented as <code>&amp;reg;</code></li>
                    </ul>
                  </li><br />
                  <li>
                    <strong style={{paddingBottom:"6px"}}>Special Symbols:</strong>
                    <ul>
                      <li>&euro; (Euro sign) is represented as <code>&amp;euro;</code></li><br />
                      <li>&cent; (cent sign) is represented as <code>&amp;cent;</code></li><br />
                      <li>&trade; (trademark sign) is represented as <code>&amp;trade;</code></li><br />
                      <li>&infin; (infinity symbol) is represented as <code>&amp;infin;</code></li>
                    </ul>
                  </li><br />
                  <li>
                    <strong style={{paddingBottom:"6px"}}>Mathematical Symbols:</strong>
                    <ul>
                      <li>&plusmn; (plus-minus sign) is represented as <code>&amp;plusmn;</code></li><br />
                      <li>&radic; (square root) is represented as <code>&amp;radic;</code></li>
                    </ul>
                  </li><br />
                </ul>
                <h2>How to Use HTML Entities</h2>
                <p>
                  HTML entities are written as an ampersand (<code>&amp;</code>), followed by the entity name (or a numeric code), and ending with a semicolon (<code>; </code>).
                </p>
                <p><strong>Named Entities:</strong></p>
                <pre>
                  <code>&amp;copy; 2024 Your Company</code>
                </pre><br />
                <p><strong>Numeric Entities:</strong></p>
                <ul>
                  <li>Decimal: <code>&#169;</code> (for &copy;)</li><br />
                  <li>Hexadecimal: <code>&#x00A9;</code> (for &copy;)</li>
                </ul><br />
                <h2>Why Use Entities?</h2>
                <ul>
                  <li><strong>Special Characters:</strong> To display characters that are not directly typable or have special meanings in HTML.</li><br />
                  <li><strong>Consistency:</strong> To ensure characters are consistently rendered across different browsers and systems.</li><br />
                  <li><strong>HTML Syntax:</strong> To avoid conflicts with HTML syntax, particularly with characters like &lt;, &gt;, and &amp; which are used for HTML tags and entities themselves.</li>
                </ul>
                <p>
                  Using HTML entities helps maintain the integrity of your HTML code and ensures that special characters are displayed correctly across all browsers and platforms.
                </p>



              </div>
            )}


            {selectedChapter === 'chapter28' && (
              <div className={style.chaptercontent}>
               <h1 className={style.heading} >Symbols</h1>


                <p>
                  In HTML, <strong>symbols</strong> typically refer to special characters that are not easily typable or have special meanings within HTML. These symbols include punctuation marks, mathematical symbols, currency signs, and various other special characters. They are often represented using HTML entities to ensure they display correctly across different browsers and devices.
                </p><br />
                <h2>Types of HTML Symbols</h2>
                <ol>
                  <li>
                    <strong>Reserved Characters:</strong>
                    <ul>
                      <li>&lt; (less than) is represented as <code>&amp;lt;</code></li><br />
                      <li>&gt; (greater than) is represented as <code>&amp;gt;</code></li><br />
                      <li>&amp; (ampersand) is represented as <code>&amp;amp;</code></li><br />
                      <li>&quot; (double quote) is represented as <code>&amp;quot;</code></li><br />
                      <li>&apos; (single quote) is represented as <code>&amp;apos;</code> (less common but valid)</li>
                    </ul>
                  </li><br />
                  <li>
                    <strong>Currency Symbols:</strong>
                    <ul>
                      <li>&euro; (Euro sign) is represented as <code>&amp;euro;</code></li><br />
                      <li>&pound; (British pound) is represented as <code>&amp;pound;</code></li><br />
                      <li>&yen; (Japanese yen) is represented as <code>&amp;yen;</code></li><br />
                      <li>&cent; (cent sign) is represented as <code>&amp;cent;</code></li>
                    </ul>
                  </li><br />
                  <li>
                    <strong>Mathematical Symbols:</strong>
                    <ul>
                      <li>&plusmn; (plus-minus sign) is represented as <code>&amp;plusmn;</code></li><br />
                      <li>&radic; (square root) is represented as <code>&amp;radic;</code></li><br />
                      <li>&infin; (infinity) is represented as <code>&amp;infin;</code></li>
                    </ul>
                  </li><br />
                  <li>
                    <strong>Special Symbols:</strong>
                    <ul>
                      <li>&copy; (copyright symbol) is represented as <code>&amp;copy;</code></li><br />
                      <li>&reg; (registered trademark symbol) is represented as <code>&amp;reg;</code></li><br />
                      <li>&trade; (trademark symbol) is represented as <code>&amp;trade;</code></li><br />
                      <li>&sect; (section sign) is represented as <code>&amp;sect;</code></li>
                    </ul>
                  </li><br />
                  <li>
                    <strong>Mathematical Operators:</strong>
                    <ul>
                      <li>&divide; (division sign) is represented as <code>&amp;divide;</code></li><br />
                      <li>&times; (multiplication sign) is represented as <code>&amp;times;</code></li>
                    </ul>
                  </li><br />
                  <li>
                    <strong>Punctuation and Miscellaneous Symbols:</strong>
                    <ul>
                      <li>&bull; (bullet) is represented as <code>&amp;bull;</code></li><br />
                      <li>&#9834; (musical note) is represented as <code>&#9834;</code> (or <code>&#x266A;</code> in hexadecimal)</li><br />
                      <li>&#9992; (airplane) is represented as <code>&#9992;</code> (or <code>&#x2708;</code> in hexadecimal)</li>
                    </ul>
                  </li><br />
                </ol>
                <h2>How to Use HTML Symbols</h2>
                <p>
                  In HTML, symbols are included using HTML entities. An HTML entity starts with an ampersand (<code>&amp;</code>), followed by the entity name or a numeric code, and ends with a semicolon (<code>;</code>).
                </p>
                <p><strong>Named Entities:</strong></p>
                <pre>
                  <code>&amp;copy; 2024 Your Company</code>
                </pre><br />
                <p><strong>Numeric Entities:</strong></p>
                <ul>
                  <li>Decimal: <code>&#169;</code> (for &copy;)</li>
                  <li>Hexadecimal: <code>&#x00A9;</code> (for &copy;)</li>
                </ul><br />
                <h2 style={{paddingBottom:"6px"}}>Why Use HTML Symbols?</h2>
                <ul>
                  <li><strong>Display Special Characters:</strong> To include characters that are not readily available on standard keyboards.</li><br />
                  <li><strong>Avoid Conflicts:</strong> To ensure that reserved HTML characters are displayed correctly and do not interfere with HTML syntax.</li><br />
                  <li><strong>Cross-Browser Consistency:</strong> To ensure consistent rendering of symbols across different browsers and devices.</li>
                </ul>
                <p>
                  Using HTML symbols helps in creating clear, readable, and well-formatted web content by ensuring special characters and symbols are correctly displayed.
                </p>

              </div>
            )}



            {selectedChapter === 'chapter29' && (
              <div className={style.chaptercontent}>


<h1 className={style.heading} > Emojis</h1>
                <p>
                  In HTML, emojis can be included in a webpage using their Unicode code points. Unicode is a standard that assigns a unique number to every character, including emojis.
                </p><br />
                <p>Here’s how you can include emojis in HTML:</p>

                <h2>1. Directly Using Emoji Characters</h2>
                <pre>
                  <code>
                    {`<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Emoji Example</title>
</head>
<body>
    <p>Here is a smiley face emoji: 😊</p>
</body>
</html>`}
                  </code>
                </pre><br />

                <h2>2. Using Unicode Code Points</h2>
                <pre><br />
                  <code>
                    {`<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Emoji Example</title>
</head>
<body>
    <p>Here is a smiley face emoji: &#128522;</p> <!-- 😊 -->
</body>
</html>`}
                  </code>
                </pre><br />

                <h2>3. Using CSS</h2>
                <pre><br />
                  <code>
                    {`<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Emoji Example</title>
    <style>
        .emoji:before {
            content: "\\1F60A"; /* Unicode for 😊 */
            font-size: 2em;
        }
    </style>
</head>
<body>
    <p class="emoji"></p>
</body>
</html>`}
                  </code>
                </pre><br />

                <h3 style={{paddingBottom:"6px"}}>Things to Keep in Mind</h3>
                <ul>
                  <li><strong>Encoding:</strong> Ensure your HTML file is saved with UTF-8 encoding to correctly display emojis.</li><br />
                  <li><strong>Font Support:</strong> Not all fonts may support all emojis, so the display might vary depending on the user's system.</li>
                </ul>
                <p>
                  Emojis are widely supported across modern browsers and devices, so using them directly in your HTML or via Unicode code points is a reliable method to include them in your web pages.
                </p>

              </div>
            )}


{selectedChapter === 'chapter30' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>How to Run HTML Code</h1>

    <p>
      Here’s a comprehensive step-by-step guide for beginners to write, save, and run HTML code:
    </p>

    <br />

    <h2>Step 1: Set Up a Text Editor</h2>
    <p style={{paddingBottom:"6px"}}>
      To write HTML code, you need a text editor. There are several options available:
    </p>
    <h3 style={{paddingBottom:"6px"}}>Recommended Text Editors:</h3>
    <ul>
      <li><b>Notepad</b> (Windows default text editor, simple but lacks advanced features)</li><br />
      <li><b>Visual Studio Code</b> (Highly recommended for its features and extensions)</li><br />
      <li><b>Sublime Text</b> (Lightweight and user-friendly)</li><br />
      <li><b>Notepad++</b> (Enhanced version of Notepad with syntax highlighting)</li><br />
      <li><b>Atom</b> (Good for beginners, but slower compared to VS Code)</li>
    </ul>

    <br />

    <h2>Step 2: Write Your HTML Code</h2>
    <p>Here’s an example of basic HTML code:</p>
    <pre>
      <code>
        {`<!DOCTYPE html>
<html>
<head>
    <title>My First HTML Page</title>
</head>
<body>
    <h1>Welcome to HTML!</h1>
    <p>This is my first web page.</p>
</body>
</html>`}
      </code>
    </pre>
    <ul>
      <li><b>&lt;!DOCTYPE html&gt;</b>: Declares the document as HTML5.</li><br />
      <li><b>&lt;html&gt;</b>: The root element of the HTML document.</li><br />
      <li><b>&lt;head&gt;</b>: Contains metadata (title, styles, etc.).</li><br />
      <li><b>&lt;body&gt;</b>: Contains the visible content.</li>
    </ul>

   <br />

    <h2 style={{paddingBottom:"6px"}}>Step 3: Save the File</h2>
    <ul>
      <li><b>File Extension:</b> Save your file with the <code>.html</code> extension (e.g., <code>index.html</code>).</li><br />
      <li><b>Location:</b> Save it in an easily accessible folder, such as "Documents" or "Desktop".</li><br />
      <li><b>Encoding:</b> Ensure the file is saved with UTF-8 encoding (most editors do this automatically).</li>
    </ul>

    <br />

    <h2 style={{paddingBottom:"6px"}}>Step 4: Open the HTML File in a Browser</h2>
    <h3>Option 1: Double-Click the File</h3>
    <p>
      Locate the saved <code>.html</code> file, double-click it, and it will open in your default web browser
      (e.g., Google Chrome, Firefox, Edge).
    </p><br />
    <h3>Option 2: Open with a Specific Browser</h3>
    <p>
      Right-click the file, choose <b>Open With</b>, and select a browser like:
    </p>
    <ul>
      <li>Google Chrome</li><br />
      <li>Mozilla Firefox</li><br />
      <li>Microsoft Edge</li><br />
      <li>Safari</li>
    </ul>

    <br />

    <h2>Step 5: (Optional) Use a Live Server</h2>
    <p>
      For real-time preview and easier development, you can set up a live server:
    </p>
    <ol>
      <li>
        <b>Install Visual Studio Code.</b>
      </li><br />
      <li>
        <b>Install the Live Server Extension:</b>
        <ul>
          <li>Open the Extensions view in VS Code (<code>Ctrl + Shift + X</code>).</li><br />
          <li>Search for "Live Server" and click <b>Install</b>.</li>
        </ul>
      </li><br />
      <li>
        <b>Run Live Server:</b>
        <ul>
          <li>Open your HTML file in VS Code.</li><br />
          <li>Right-click in the editor and select <b>Open with Live Server</b>.</li><br />
          <li>Your file will open in the browser, and any changes you make will automatically refresh.</li>
        </ul>
      </li>
    </ol>

<br />

    <h2>Step 6: Test Your Code Online (Optional)</h2>
    <p>You can use online tools to test your HTML code without installing anything:</p>
    <ul>
      <li><a href="https://codepen.io/" target="_blank" rel="noopener noreferrer">CodePen</a></li>
      <li><a href="https://jsfiddle.net/" target="_blank" rel="noopener noreferrer">JSFiddle</a></li>
      <li><a href="https://playcode.io/" target="_blank" rel="noopener noreferrer">PlayCode</a></li>
      <li><a href="https://jsbin.com/" target="_blank" rel="noopener noreferrer">JSBin</a></li>
    </ul>

    <br />
    <h2>Tips for Beginners</h2>
    <ol>
      <li><b>Keep it Simple:</b> Start with basic HTML tags like <code>&lt;h1&gt;</code>, <code>&lt;p&gt;</code>, <code>&lt;img&gt;</code>, <code>&lt;a&gt;</code>, etc.</li><br />
      <li><b>Structure Matters:</b> Indent your code to make it readable.</li><br />
      <li><b>Practice:</b> Create multiple pages with simple content to understand how tags work.</li><br />
      <li>
        <b>Learn Basics of CSS and JavaScript:</b> To make your pages more interactive and visually appealing.
      </li>
    </ol><br />

    <p>By following these steps, you’ll quickly gain confidence in creating and running HTML code!</p>
  </div>
)}



          </div>
        </div>
      </div>
  
  );
}

export default Htmlcoursedata;
