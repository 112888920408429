import React, { useState, useEffect } from 'react';
import style from "./../Css/Pakages.module.css";

const Packages = () => {

  const [colClass, setColClass] = useState('col-md-3');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1333.5) {
        setColClass('col-md-4');
      } 
      if (window.innerWidth <= 1018.5) {
        setColClass('col-md-6');
      }else {
        setColClass('col-md-3');
      }

    };

    // Initial check
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Cleanup listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  return (
    <>
      {/* Main Container */}
      <div className={`${style.packagescontainer} container-fluid`}>
        <div className="container-fluid">
          {/* Header Section */}
          <div className="row text-center">
            <h1 className={style.packageheading}>
              Your Path to Success Starts Here
            </h1>
            <p className={style.packagedescription}>
              Choose the package that suits your learning needs and goals.
            </p>
          </div>

          {/* Package Rows */}
          <div className={`${style.packagesrow} row`}>
            {/* Column 1: Web Development Packages */}
            <div className={`${style.packagescol} ${colClass}`}>
              {/* Basic Web Development Package */}
              <div className={style.packagebox}>
                <div className={style.plans}>
                  <h5 className={style.heading}>Web Development</h5>
                  <h1 className={style.packageprice}>
                    <i className="fa-solid fa-indian-rupee-sign"></i> 15,000/-
                  </h1>
                </div>
                <p className={style.description}>
                  Learn the basics of web development, including HTML, CSS,
                  JavaScript, PHP, and MySQL.
                </p>
                <ul className={style.featureList}>
                  <li>6 Months live classes</li>
                  <li>Hands-on project</li>
                  <li>Completion certificate</li>
                </ul>
                <button className={style.packagebutton}>Coming Soon</button>
              </div>
            </div>

            {/* Advanced Web Development Package */}
            <div className={`${style.packagescol} ${colClass}`}>
              <div className={style.packagebox}>
                <div className={style.plans}>
                  <h5 className={style.heading}>Advanced Web Development</h5>
                  <h1 className={style.packageprice}>
                    <i className="fa-solid fa-indian-rupee-sign"></i> 18,000/-
                  </h1>
                </div>
                <p className={style.description}>
                  Take your web development skills to the next level with
                  advanced HTML, CSS, JavaScript, React JS, PHP, MySQL and cloud
                  deployment.
                </p>
                <ul className={style.featureList}>
                  <li>7 Months live classes</li>
                  <li>Full-stack project</li>
                  <li>Personalized mentor support</li>
                </ul>
                <button className={style.packagebutton}>Coming Soon</button>
              </div>
            </div>

            {/* Column 2: App Development and UI/UX Packages */}
            <div className={`${style.packagescol} ${colClass}`}>
              {/* App Development Package */}
              <div className={style.packagebox}>
                <div className={style.plans}>
                  <h5 className={style.heading}>Android App Development</h5>
                  <h1 className={style.packageprice}>
                    <i className="fa-solid fa-indian-rupee-sign"></i> 25,000/-
                  </h1>
                </div>
                <p className={style.description}>
                  Learn cross-platform app development using Flutter or React
                  Native.
                </p>
                <ul className={style.featureList}>
                  <li>7 Months live classes</li>
                  <li>Hands-on projects</li>
                  <li>Job interview preparation</li>
                </ul>
                <button className={style.packagebutton}>Coming Soon</button>
              </div>
              </div>

              {/* iOS App Development Package */}
              <div className={`${style.packagescol} ${colClass}`}>
              <div className={style.packagebox}>
                <div className={style.plans}>
                  <h5 className={style.heading}>iOS App Development</h5>
                  <h1 className={style.packageprice}>
                    <i className="fa-solid fa-indian-rupee-sign"></i> 30,000/-
                  </h1>
                </div>
                <p className={style.description}>
                  Learn iOS app development with Swift and Xcode, and build
                  high-performance apps for the App Store.
                </p>
                <ul className={style.featureList}>
                  <li>6 Months live classes</li>
                  <li>Real-world iOS projects</li>
                  <li>App Store submission guidance</li>
                </ul>
                <button className={style.packagebutton}>Coming Soon</button>
              </div>
            </div>

            {/* Column 3: Data Science and Machine Learning Packages */}
            <div className={`${style.packagescol} ${colClass}`}>
              {/* Data Science Package */}
              <div className={style.packagebox}>
                <div className={style.plans}>
                  <h5 className={style.heading}>Data Science</h5>
                  <h1 className={style.packageprice}>
                    <i className="fa-solid fa-indian-rupee-sign"></i> 35,000/-
                  </h1>
                </div>
                <p className={style.description}>
                  Learn data analysis, Python, Python Libraries-
                  Pandas,Numpy,Matplotlib,Seaborn, visualization,
                  MySQL,PowerBi,Excel,Tableau
                </p>
                <ul className={style.featureList}>
                  <li>7 Months live classes</li>
                  <li>Real-world datasets</li>
                  <li>Hands-on with top data tools</li>
                </ul>
                <button className={style.packagebutton}>Coming Soon</button>
              </div>
              </div>

              {/* Machine Learning & AI Package */}
              <div className={`${style.packagescol} ${colClass}`}>
              <div className={style.packagebox}>
                <div className={style.plans}>
                  <h5 className={style.heading}>
                    Machine Learning <br /> & AI
                  </h5>
                  <h1 className={style.packageprice}>
                    <i className="fa-solid fa-indian-rupee-sign"></i> 40,000/-
                  </h1>
                </div>
                <p className={style.description}>
                  Learning Supervised and Unsupervised Machine Learning (ML)
                  algorithms along with Deep Learning (DL) and Neural Networks
                </p>
                <ul className={style.featureList}>
                  <li>9 Months live classes</li>
                  <li>Hands-on AI projects</li>
                  <li>Deep learning with TensorFlow</li>
                </ul>
                <button className={style.packagebutton}>Coming Soon</button>
              </div>
            </div>

            {/* Column 4: Cloud Computing and Cybersecurity Packages */}
            <div className={`${style.packagescol} ${colClass}`}>
              {/* UI/UX Design Package */}
              <div className={style.packagebox}>
                <div className={style.plans}>
                  <h5 className={style.heading}>UI/UX Design</h5>
                  <h1 className={style.packageprice}>
                    <i className="fa-solid fa-indian-rupee-sign"></i> 15,000/-
                  </h1>
                </div>
                <p className={style.description}>
                  Master UI/UX design with hands-on projects and industry tools
                  like Figma, Sketch, slime, Adobe XD.
                </p>
                <ul className={style.featureList}>
                  <li>4 Months live classes</li>
                  <li>Design a full prototype</li>
                  <li>Access to Figma, Adobe XD</li>
                </ul>
                <button className={style.packagebutton}>Coming Soon</button>
              </div>
              </div>

              {/* Testing Package */}
              <div className={`${style.packagescol} ${colClass}`}>
              <div className={style.packagebox}>
                <div className={style.plans}>
                  <h5 className={style.heading}>Testing</h5>
                  <h1 className={style.packageprice}>
                    <i className="fa-solid fa-indian-rupee-sign"></i> 15,500/-
                  </h1>
                </div>
                <p className={style.description}>
                  Master the art of software testing with hands-on experience in
                  manual and automated testing tools.
                </p>
                <ul className={style.featureList}>
                  <li>4 Months live classes</li>
                  <li>Real-world testing projects</li>
                  <li>ISTQB certification prep</li>
                </ul>
                <button className={style.packagebutton}>Coming Soon</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Packages;
