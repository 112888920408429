import React, { useRef } from "react";
import style from "./../Css/Coursesbuy.module.css";
import { Link } from "react-router-dom";

const Coursesbuy = () => {
  const scrollContainerRef = useRef(null);
  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: 400, behavior: "smooth" });
    }
  };

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -400, behavior: "smooth" });
    }
  };

  return (
    <>
      <div id="coursesbuyy" className={`${style.coursebuycontainer_main} container-fluid`}>
        <div className={style.coursesbuycontainer}>
          <div className="heading">
            <h1 className={style.coursesbuyheading}>
              Transform Your Potential with Our Courses
            </h1>
            {/* <h6 className={style.coursesbuysubtitle}>Select the Right Course for You</h6> */}
          </div>

          <div className={`${style.scrollContainer}`} ref={scrollContainerRef}>
            <div className={style.coursesbuyrow}>
              {/*C Course */}
              <div className={style.coursesbuy}>
                <div className={style.courseslogo}>
                  <img
                    className={style.coursesbuylogo}
                   
                     src="Images/icons8-c-programming-240.png"
                    alt="C Course"
                  />
                </div>
                <div className={style.coursesdetail}>
                <div className={style.divrowall}>
                  <h4 className={style.nameheading}>C</h4>
                  </div>
                  <div className={style.divrowall}>
                  <h6 className={style.coursesdata}>C Prog. Language</h6>
                  </div>
                  <div className={style.divrowall}>
                  <p className={style.price}>
                    <i class="fa-solid fa-indian-rupee-sign"></i> Rs. 4,500/-
                  </p>
                  </div>
                  <div className={style.divrowall}>
                  <p className={style.courseduration}>Duration: 45 Days</p>
                  </div>
                  <div className={style.divrowall}>
                  <p className={style.coursetechnologies}>Technologies: C</p>
                  </div>
                  <div className={style.divrowall}>
                  <div className={style.buttoncontainer}>
                    <a className={style.learnmorebuy} href="#">
                      Learn more
                    </a>
                    <Link
                      className={style.coursesbuybutton}
                      to={"/"}
                    >
                      Coming Soon
                    </Link>
                  </div>
                  </div>
                </div>
              </div>

              {/* C++ Course */}
              <div className={style.coursesbuy}>
                <div className={style.courseslogo}>
                  <img
                    className={style.coursesbuylogo}
                    src="Images/icons8-c++-480.png"
                    alt="C++ Course"
                  />
                </div>
                <div className={style.coursesdetail}>
                <div className={style.divrowall}>
                  <h4 className={style.nameheading}>C++</h4>
                  </div>
                  <div className={style.divrowall}>
                  <h6 className={style.coursesdata}>C++ Prog. Language</h6>
                  </div>
                  <div className={style.divrowall}>
                  <p className={style.price}>
                    <i class="fa-solid fa-indian-rupee-sign"></i> Rs. 4,500/-
                  </p>
                  </div>
                  <div className={style.divrowall}>
                  <p className={style.courseduration}>Duration: 45 Days</p>
                  </div>
                  <div className={style.divrowall}>
                  <p className={style.coursetechnologies}>Technologies: C++</p>
                  </div>
                  <div className={style.divrowall}>
                  <div className={style.buttoncontainer}>
                    <a className={style.learnmorebuy} href="#">
                      Learn more
                    </a>
                    <Link
                      className={style.coursesbuybutton}
                      to={"/"}
                    >
                      Coming Soon
                    </Link>
                  </div>
                  </div>
                </div>
              </div>

              {/* Java se Course */}
              <div className={style.coursesbuy}>
                <div className={style.courseslogo}>
                  <img
                    className={style.coursesbuylogo}
                    src="Images/javaicon.png"
                    alt="Java SE Course"
                  />
                </div>
                <div className={style.coursesdetail}>
                <div className={style.divrowall}>
                  <h4 className={style.nameheading}>Java</h4>
                  </div>
                  <div className={style.divrowall}>
                  <h6 className={style.coursesdata}>Java SE(Core Java)</h6>
                  </div>
                  <div className={style.divrowall}>
                  <p className={style.price}>
                    <i class="fa-solid fa-indian-rupee-sign"></i>Rs. 6,000/-{" "}
                  </p>
                  </div>
                  <div className={style.divrowall}>
                  <p className={style.courseduration}>Duration: 2 Months</p>
                  </div>
                  <div className={style.divrowall}>
                  <p className={style.coursetechnologies}>
                    Technologies:Java SE(Core Java)
                  </p>
                  </div>
                  <div className={style.divrowall}>
                  <div className={style.buttoncontainer}>
                    <a className={style.learnmorebuy} href="#">
                      Learn more
                    </a>
                    <Link
                      className={style.coursesbuybutton}
                      to={"/"}
                    >
                      Coming Soon
                    </Link>
                  </div>
                  </div>
                </div>
              </div>


              {/* java ee  Course */}
              <div className={style.coursesbuy}>
                <div className={style.courseslogo}>
                  <img
                    className={style.coursesbuylogo}
                    src="Images/javaicon.png"
                    alt="Java EE Course"
                  />
                </div>
                <div className={style.coursesdetail}>
                <div className={style.divrowall}>
                  <h4 className={style.nameheading}>Adv. Java</h4>
                  </div>
                  <div className={style.divrowall}>
                  <h6 className={style.coursesdata}>Java EE(Adv. Java)</h6>
                  </div>
                  <div className={style.divrowall}>
                  <p className={style.price}>
                    <i class="fa-solid fa-indian-rupee-sign"></i>Rs. 6,000/-{" "}
                  </p>
                  </div>
                  <div className={style.divrowall}>
                  <p className={style.courseduration}>Duration: 2 Months</p>
                  </div>
                  <div className={style.divrowall}>
                  <p className={style.coursetechnologies}>
                  Technologies: Java EE(Adv. Java)
                  </p>
                  </div>
                  <div className={style.divrowall}>
                  <div className={style.buttoncontainer}>
                    <a className={style.learnmorebuy} href="#">
                      Learn more
                    </a>
                    <Link
                      className={style.coursesbuybutton}
                      to={"/"}
                    >
                      Coming Soon
                    </Link>
                  </div>
                  </div>
                </div>
              </div>

             
              
              {/* AWS Course */}
               <div className={style.coursesbuy}>
                <div className={style.courseslogo}>
                  <img
                    className={style.coursesbuylogo}
                    src="Images/icons8-amazon-aws-96.png"
                    alt="AWS Course"
                  />
                </div>
                <div className={style.coursesdetail}>
                <div className={style.divrowall}>
                  <h4 className={style.nameheading}>AWS</h4>
                  </div>
                  <div className={style.divrowall}>
                  <h6 className={style.coursesdata}>Amazon Web Services</h6>
                  </div>
                  <div className={style.divrowall}>
                  <p className={style.price}>
                    <i class="fa-solid fa-indian-rupee-sign"></i>Rs. 30,000/-{" "}
                  </p>
                  </div>
                  <div className={style.divrowall}>
                  <p className={style.courseduration}>Duration: 3 Months</p>
                  </div>
                  <div className={style.divrowall}>
                  <p className={style.coursetechnologies}>
                  Technologies: Server Cloud Technology
                  </p>
                  </div>
                  <div className={style.divrowall}>
                  <div className={style.buttoncontainer}>
                    <a className={style.learnmorebuy} href="#">
                      Learn more
                    </a>
                    <Link
                      className={style.coursesbuybutton}
                      to={"/"}
                    >
                      Coming Soon
                    </Link>
                  </div>
                  </div>
                </div>
              </div>


              {/* Python Course */}
              <div className={style.coursesbuy}>
                <div className={style.courseslogo}>
                  <img
                    className={style.coursesbuylogo}
                    src="Images/pythonicon.png"
                    alt="Python Course"
                  />
                </div>
                <div className={style.coursesdetail}>
                <div className={style.divrowall}>
                  <h4 className={style.nameheading}>Python</h4>
                  </div>
                  <div className={style.divrowall}>
                  <h6 className={style.coursesdata}>Python</h6>
                  </div>
                  <div className={style.divrowall}>
                  <p className={style.price}>
                    <i class="fa-solid fa-indian-rupee-sign"></i> Rs. 5,000/-
                  </p>
                  </div>
                  <div className={style.divrowall}>
                  <p className={style.courseduration}>Duration: 45 Days</p>
                  </div>
                  <div className={style.divrowall}>
                  <p className={style.coursetechnologies}>
                    Technologies: Python
                  </p>
                  </div>
                  <div className={style.divrowall}>
                  <div className={style.buttoncontainer}>
                    <a className={style.learnmorebuy} href="#">
                      Learn more
                    </a>
                    <Link
                      className={style.coursesbuybutton}
                      to={"/"}
                    >
                      Coming Soon
                    </Link>
                  </div>
                  </div>
                </div>
              </div>



               {/* HTML, CSS Course */}
               <div className={style.coursesbuy}>
                <div className={style.courseslogo}>
                  <img
                    className={style.coursesbuylogo}
                    src="Images/htmlicon.png"
                    alt="HTML, CSS Course"
                  />
                   <img
                    className={style.coursesbuylogo}
                    src="Images/cssicon.png"
                    alt="HTML, CSS Course"
                  />
                </div>
                <div className={style.coursesdetail}>
                <div className={style.divrowall}>
                  <h4 className={style.nameheading}>HTML, CSS</h4>
                  </div>
                  <div className={style.divrowall}>
                  <h6 className={style.coursesdata}>Inc. Bootstrap</h6>
                  </div>
                  <div className={style.divrowall}>
                  <p className={style.price}>
                    <i class="fa-solid fa-indian-rupee-sign"></i> Rs. 4,000/-
                  </p>
                  </div>
                  <div className={style.divrowall}>
                  <p className={style.courseduration}>Duration: 45 Days</p>
                  </div>
                  <div className={style.divrowall}>
                  <p className={style.coursetechnologies}>
                  Technologies: HTML, CSS and Bootstrap
                  </p>
                  </div>
                  <div className={style.divrowall}>
                  <div className={style.buttoncontainer}>
                    <a className={style.learnmorebuy} href="#">
                      Learn more
                    </a>
                    <Link
                      className={style.coursesbuybutton}
                      to={"/"}
                    >
                      Coming Soon
                    </Link>
                  </div>
                  </div>
                </div>
              </div>




{/* Javascript Course */}
<div className={style.coursesbuy}>
                <div className={style.courseslogo}>
                <img
                    className={style.coursesbuylogo}
                    src="Images/javascript.png"
                    alt="JavaScript Course"
                  />
                </div>
                <div className={style.coursesdetail}>
                <div className={style.divrowall}>
                  <h4 className={style.nameheading}>JavaScript</h4>
                  </div>
                  <div className={style.divrowall}>
                  <h6 className={style.coursesdata}>JavaScript</h6>
                  </div>
                  <div className={style.divrowall}>
                  <p className={style.price}>
                    <i class="fa-solid fa-indian-rupee-sign"></i> Rs. 4,500/-
                  </p>
                  </div>
                  <div className={style.divrowall}>
                  <p className={style.courseduration}>Duration: 45 Days</p>
                  </div>
                  <div className={style.divrowall}>
                  <p className={style.coursetechnologies}>
                  Technologies: JavaScript
                  </p>
                  </div>
                  <div className={style.divrowall}>
                  <div className={style.buttoncontainer}>
                    <a className={style.learnmorebuy} href="#">
                      Learn more
                    </a>
                    <Link
                      className={style.coursesbuybutton}
                      to={"/"}
                    >
                      Coming Soon
                    </Link>
                  </div>
                  </div>
                </div>
              </div>





              {/* React js Course */}
<div className={style.coursesbuy}>
                <div className={style.courseslogo}>
                <img
                    className={style.coursesbuylogo}
                    src="Images/reacticon (1).png"
                    alt="React JS Course"
                  />
                </div>
                <div className={style.coursesdetail}>
                <div className={style.divrowall}>
                  <h4 className={style.nameheading}>React JS</h4>
                  </div>
                  <div className={style.divrowall}>
                  <h6 className={style.coursesdata}>React JS</h6>
                  </div>
                  <div className={style.divrowall}>
                  <p className={style.price}>
                    <i class="fa-solid fa-indian-rupee-sign"></i> Rs. 5,000/-
                  </p>
                  </div>
                  <div className={style.divrowall}>
                  <p className={style.courseduration}>Duration: 1 Month</p>
                  </div>
                  <div className={style.divrowall}>
                  <p className={style.coursetechnologies}>
                  Technologies: React JS
                  </p>
                  </div>
                  <div className={style.divrowall}>
                  <div className={style.buttoncontainer}>
                    <a className={style.learnmorebuy} href="#">
                      Learn more
                    </a>
                    <Link
                      className={style.coursesbuybutton}
                      to={"/"}
                    >
                      Coming Soon
                    </Link>
                  </div>
                  </div>
                </div>
              </div>




             {/* Node JS Course */}
<div className={style.coursesbuy}>
                <div className={style.courseslogo}>
                <img
                    className={style.coursesbuylogo}
                    src="Images/programing.png"
                    alt="Node JS Course"
                  />
                </div>
                <div className={style.coursesdetail}>
                <div className={style.divrowall}>
                  <h4 className={style.nameheading}>Node JS</h4>
                  </div>
                  <div className={style.divrowall}>
                  <h6 className={style.coursesdata}>Node JS</h6>
                  </div>
                  <div className={style.divrowall}>
                  <p className={style.price}>
                    <i class="fa-solid fa-indian-rupee-sign"></i> Rs. 6,000/-
                  </p>
                  </div>
                  <div className={style.divrowall}>
                  <p className={style.courseduration}>Duration: 2 Month</p>
                  </div>
                  <div className={style.divrowall}>
                  <p className={style.coursetechnologies}>
                  Technologies: Node JS
                  </p>
                  </div>
                  <div className={style.divrowall}>
                  <div className={style.buttoncontainer}>
                    <a className={style.learnmorebuy} href="#">
                      Learn more
                    </a>
                    <Link
                      className={style.coursesbuybutton}
                      to={"/"}
                    >
                      Coming Soon
                    </Link>
                  </div>
                  </div>
                </div>
              </div>
              


             

             
              




  {/* PHP Course */}
  <div className={style.coursesbuy}>
                <div className={style.courseslogo}>
                <img
                    className={style.coursesbuylogo}
                    src="Images/phplogo.png"
                    alt="PHP Course"
                  />
                </div>
                <div className={style.coursesdetail}>
                <div className={style.divrowall}>
                  <h4 className={style.nameheading}>PHP</h4>
                  </div>
                  <div className={style.divrowall}>
                  <h6 className={style.coursesdata}>Personal Home Page</h6>
                  </div>
                  <div className={style.divrowall}>
                  <p className={style.price}>
                    <i class="fa-solid fa-indian-rupee-sign"></i> Rs. 5,000/-
                  </p>
                  </div>
                  <div className={style.divrowall}>
                  <p className={style.courseduration}>Duration: 2 Month</p>
                  </div>
                  <div className={style.divrowall}>
                  <p className={style.coursetechnologies}>
                  Technologies: PHP
                  </p>
                  </div>
                  <div className={style.divrowall}>
                  <div className={style.buttoncontainer}>
                    <a className={style.learnmorebuy} href="#">
                      Learn more
                    </a>
                    <Link
                      className={style.coursesbuybutton}
                      to={"/"}
                    >
                      Coming Soon
                    </Link>
                  </div>
                  </div>
                </div>
              </div>



               {/* Oracle Course */}
  <div className={style.coursesbuy}>
                <div className={style.courseslogo}>
                <img
                    className={style.coursesbuylogo}
                    src="Images/oracle.png"
                    alt="Oracle Course"
                  />
                </div>
                <div className={style.coursesdetail}>
                <div className={style.divrowall}>
                  <h4 className={style.nameheading}>Oracle</h4>
                  </div>
                  <div className={style.divrowall}>
                  <h6 className={style.coursesdata}>Oracle Corporation</h6>
                  </div>
                  <div className={style.divrowall}>
                  <p className={style.price}>
                    <i class="fa-solid fa-indian-rupee-sign"></i> Rs. 6,000/-
                  </p>
                  </div>
                  <div className={style.divrowall}>
                  <p className={style.courseduration}>Duration: 45 Days</p>
                  </div>
                  <div className={style.divrowall}>
                  <p className={style.coursetechnologies}>
                  Technologies: Oracle
                  </p>
                  </div>
                  <div className={style.divrowall}>
                  <div className={style.buttoncontainer}>
                    <a className={style.learnmorebuy} href="#">
                      Learn more
                    </a>
                    <Link
                      className={style.coursesbuybutton}
                      to={"/"}
                    >
                      Coming Soon
                    </Link>
                  </div>
                  </div>
                </div>
              </div>





                     {/* MySQL Course */}
  <div className={style.coursesbuy}>
                <div className={style.courseslogo}>
                <img
                    className={style.coursesbuylogo}
                    src="Images/icons8-mysql-240.png"
                    alt="MySQL Course"
                  />
                </div>
                <div className={style.coursesdetail}>
                <div className={style.divrowall}>
                  <h4 className={style.nameheading}>MySQL</h4>
                  </div>
                  <div className={style.divrowall}>
                  <h6 className={style.coursesdata}>Structured Query Language</h6>
                  </div>
                  <div className={style.divrowall}>
                  <p className={style.price}>
                    <i class="fa-solid fa-indian-rupee-sign"></i> Rs. 6,000/-
                  </p>
                  </div>
                  <div className={style.divrowall}>
                  <p className={style.courseduration}>Duration: 45 Days</p>
                  </div>
                  <div className={style.divrowall}>
                  <p className={style.coursetechnologies}>
                  Technologies: MySQL Database
                  </p>
                  </div>
                  <div className={style.divrowall}>
                  <div className={style.buttoncontainer}>
                    <a className={style.learnmorebuy} href="#">
                      Learn more
                    </a>
                    <Link
                      className={style.coursesbuybutton}
                      to={"/"}
                    >
                      Coming Soon
                    </Link>
                  </div>
                  </div>
                </div>
              </div>




                 {/* ASP.Net Course */}
  <div className={style.coursesbuy}>
                <div className={style.courseslogo}>
                <img
                    className={style.coursesbuylogo}
                    src="Images/social.png"
                    alt="ASP.Net Course"
                  />
                </div>
                <div className={style.coursesdetail}>
                <div className={style.divrowall}>
                  <h4 className={style.nameheading}>ASP.Net</h4>
                  </div>
                  <div className={style.divrowall}>
                  <h6 className={style.coursesdata}>Active Server Pages .NET</h6>
                  </div>
                  <div className={style.divrowall}>
                  <p className={style.price}>
                    <i class="fa-solid fa-indian-rupee-sign"></i> Rs. 4,500/-
                  </p>
                  </div>
                  <div className={style.divrowall}>
                  <p className={style.courseduration}>Duration: 45 Days</p>
                  </div>
                  <div className={style.divrowall}>
                  <p className={style.coursetechnologies}>
                  Technologies: ASP.Net
                  </p>
                  </div>
                  <div className={style.divrowall}>
                  <div className={style.buttoncontainer}>
                    <a className={style.learnmorebuy} href="#">
                      Learn more
                    </a>
                    <Link
                      className={style.coursesbuybutton}
                      to={"/"}
                    >
                      Coming Soon
                    </Link>
                  </div>
                  </div>
                </div>
              </div>








              

       

              

             

              {/* C#.Net Course */}
              <div className={style.coursesbuy}>
                <div className={style.courseslogo}>
                  <img
                    className={style.coursesbuylogo}
                    src="Images/c-sharp.png"
                    alt="C#.Net Course"
                  />
                </div>
                <div className={style.coursesdetail}>
                <div className={style.divrowall}>
                  <h4 className={style.nameheading}>C#.Net</h4>
                  </div>
                  <div className={style.divrowall}>
                  <h6 className={style.coursesdata}>
                    {" "}
                    C-Sharp on the .NET Framework
                  </h6>
                  </div>
                  <div className={style.divrowall}>
                  <p className={style.price}>
                    <i class="fa-solid fa-indian-rupee-sign"></i> Rs. 4,500/-
                  </p>
                  </div>
                  <div className={style.divrowall}>
                  <p className={style.courseduration}>Duration: 45 Days</p>
                  </div>
                  <div className={style.divrowall}>
                  <p className={style.coursetechnologies}>
                    Technologies: C#.Net
                  </p>
                  </div>
                  <div className={style.divrowall}>
                  <div className={style.buttoncontainer}>
                    <a className={style.learnmorebuy} href="#">
                      Learn more
                    </a>
                    <Link
                      className={style.coursesbuybutton}
                      to={"/"}
                    >
                      Coming Soon
                    </Link>
                  </div>
                  </div>
                </div>
              </div>

              {/* Android Development Course */}
              <div className={style.coursesbuy}>
                <div className={style.courseslogo}>
                  <img
                    className={style.coursesbuylogo}
                    src="Images/icons8-android-400.png"
                    alt="C#.Net Course"
                  />
                </div>
                <div className={style.coursesdetail}>
                <div className={style.divrowall}>
                  <h4 className={style.nameheading}>Android Development</h4>
                  </div>
                  <div className={style.divrowall}>
                  <h6 className={style.coursesdata}> Android Development</h6>
                  </div>
                  <div className={style.divrowall}>
                  <p className={style.price}>
                    <i class="fa-solid fa-indian-rupee-sign"></i> Rs. 25,000/-
                  </p>
                  </div>
                  <div className={style.divrowall}>
                  <p className={style.courseduration}>Duration: 8 Months</p>
                  </div>
                  <div className={style.divrowall}>
                  <p className={style.coursetechnologies}>
                    Technologies:     xml,java ME
                  </p>
                  </div>
                  <div className={style.divrowall}>
                  <div className={style.buttoncontainer}>
                    <a className={style.learnmorebuy} href="#">
                      Learn more
                    </a>
                    <Link
                      className={style.coursesbuybutton}
                      to={"/"}
                    >
                      Coming Soon
                    </Link>
                  </div>
                  </div>
                </div>
              </div>
            </div>

            {/* second row  */}
            {/* <div className={`${style.coursesbuyrow}`}>
                            <div className={`${style.coursesbuy}`}>
                                <div className={style.courseslogo}>
                              
                                    <img className={style.coursesbuylogo} src="Images/javaicon.png" alt="" />
                                </div>
                                <div className={style.coursesdetail}>
                                    <h4 className={style.nameheading}>JAVA</h4>
                                    <h6 className={style.coursesdata}>Just Another Virtual Accelerator</h6>
                                    <a className={style.learnmorebuy} href="#">Learn more</a>
                                    <button className={style.coursesbuybutton}>Buy</button>
                                </div>

                            </div>
                            <div className={`${style.coursesbuy}`}>
                            <div className={style.courseslogo}>
                       
                                    <img className={style.coursesbuylogo} src="Images/pythonicon.png" alt="" />
                                </div>
                                <div className={style.coursesdetail}>
                                    <h4 className={style.nameheading}>PYTHON</h4>
                                    <h6 className={style.coursesdata}>programming language</h6>
                                    <a className={style.learnmorebuy} href="#">Learn more</a>
                                    <button className={style.coursesbuybutton}>Buy</button>
                                </div>
                            </div>
                            <div className={`${style.coursesbuy}`}>
                            <div className={style.courseslogo}>
                             
                                    <img className={style.coursesbuylogo} src="Images/reacticon.png" alt="" />
                                </div>
                                <div className={style.coursesdetail}>
                                    <h4 className={style.nameheading}>REACT</h4>
                                    <h6 className={style.coursesdata}>React JS </h6>
                                    <a className={style.learnmorebuy} href="#">Learn more</a>
                                    <button className={style.coursesbuybutton}>Buy</button>
                                </div>
                            </div>
                            <div className={`${style.coursesbuy}`}>
                            <div className={style.courseslogo}>
                                    <i class={`${style.coursesbuylogo} fa-brands fa-html5`}></i>
                                </div>
                                <div className={style.coursesdetail}>
                                    <h4 className={style.nameheading}>HTML</h4>
                                    <h6 className={style.coursesdata}>Hyper Text Markup Language</h6>
                                    <a className={style.learnmorebuy} href="#">Learn more</a>
                                    <button className={style.coursesbuybutton}>Buy</button>
                                </div>
                            </div>
                            <div className={`${style.coursesbuy}`}>
                            <div className={style.courseslogo}>
                                    <i class={`${style.coursesbuylogo} fa-brands fa-html5`}></i>
                                </div>
                                <div className={style.coursesdetail}>
                                    <h4 className={style.nameheading}>HTML</h4>
                                    <h6 className={style.coursesdata}>Hyper Text Markup Language</h6>
                                    <a className={style.learnmorebuy} href="#">Learn more</a>
                                    <button className={style.coursesbuybutton}>Buy</button>
                                </div>
                            </div>
                            <div className={`${style.coursesbuy}`}>
                            <div className={style.courseslogo}>
                                    <i class={`${style.coursesbuylogo} fa-brands fa-html5`}></i>
                                </div>
                                <div className={style.coursesdetail}>
                                    <h4 className={style.nameheading}>HTML</h4>
                                    <h6 className={style.coursesdata}>Hyper Text Markup Language</h6>
                                    <a className={style.learnmorebuy} href="#">Learn more</a>
                                    <button className={style.coursesbuybutton}>Buy</button>
                                </div>
                            </div>
                            <div className={`${style.coursesbuy}`}>
                            <div className={style.courseslogo}>
                                    <i class={`${style.coursesbuylogo} fa-brands fa-html5`}></i>
                                </div>
                                <div className={style.coursesdetail}>
                                    <h4 className={style.nameheading}>HTML</h4>
                                    <h6 className={style.coursesdata}>Hyper Text Markup Language</h6>
                                    <a className={style.learnmorebuy} href="#">Learn more</a>
                                    <button className={style.coursesbuybutton}>Buy</button>
                                </div>
                            </div>
                            <div className={`${style.coursesbuy}`}>
                            <div className={style.courseslogo}>
                                    <i class={`${style.coursesbuylogo} fa-brands fa-html5`}></i>
                                </div>
                                <div className={style.coursesdetail}>
                                    <h4 className={style.nameheading}>HTML</h4>
                                    <h6 className={style.coursesdata}>Hyper Text Markup Language</h6>
                                    <a className={style.learnmorebuy} href="#">Learn more</a>
                                    <button className={style.coursesbuybutton}>Buy</button>
                                </div>
                            </div>
                            <div className={`${style.coursesbuy}`}>
                            <div className={style.courseslogo}>
                                    <i class={`${style.coursesbuylogo} fa-brands fa-html5`}></i>
                                </div>
                                <div className={style.coursesdetail}>
                                    <h4 className={style.nameheading}>HTML</h4>
                                    <h6 className={style.coursesdata}>Hyper Text Markup Language</h6>
                                    <a className={style.learnmorebuy} href="#">Learn more</a>
                                    <button className={style.coursesbuybutton}>Buy</button>
                                </div>
                            </div>
                            <div className={`${style.coursesbuy}`}>
                            <div className={style.courseslogo}>
                                    <i class={`${style.coursesbuylogo} fa-brands fa-html5`}></i>
                                </div>
                                <div className={style.coursesdetail}>
                                    <h4 className={style.nameheading}>HTML</h4>
                                    <h6 className={style.coursesdata}>Hyper Text Markup Language</h6>
                                    <a className={style.learnmorebuy} href="#">Learn more</a>
                                    <button className={style.coursesbuybutton}>Buy</button>
                                </div>
                            </div>
                        </div> */}
          </div>

          {/* Arrow buttons at the bottom */}
          <div className={`${style.arrowContainer} `}>
            <button onClick={scrollLeft} className={style.leftArrow}>
              <i class={` ${style.arrowicon} fa-solid fa-caret-left`}></i>
            </button>
            <button onClick={scrollRight} className={style.rightArrow}>
              <i class={` ${style.arrowicon} fa-solid fa-caret-right `}></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Coursesbuy;
