import React from 'react';
import style from './../Css/CertificateSHOW.module.css';
import Applyforinternship from './Applyforinternship'

const CertificateSHOW = () => {
    return (
        <>
        <Applyforinternship/>
        <div className={style.containercerti}>
            <h2 className={style.header}>Our Certifications</h2>
            <p className={style.subHeader}>We provide certifications for various courses and achievements. Below are the types of certifications available:</p>
            <div className={style.certification_img}>
                <img className={style.certiimg} src="/Images/CERTIFICATIONSHOW2.png" alt="" />
            </div>
            <div className="row">

                <div className={style.certificatesContainer}>
                    <div className={`${style.certificateCard} col`}>
                        <h3 className={style.certificateTitle}>Course Completion Certificate</h3>
                        <p className={style.certificateDescription}>
                            Issued to students who successfully complete a course. This certificate proves your proficiency in the specific subject or skill.
                        </p>
                    </div>

                    <div className={`${style.certificateCard} col`}>
                        <h3 className={style.certificateTitle}>Specialization Certificate</h3>
                        <p className={style.certificateDescription}>
                            Awarded to students who complete a series of advanced courses within a particular field or specialization.
                        </p>
                    </div>

                    <div className={`${style.certificateCard} col`}>
                        <h3 className={style.certificateTitle}>Achievement Certificate</h3>
                        <p className={style.certificateDescription}>
                            Given to students who demonstrate outstanding performance or achieve specific milestones within the course or program.
                        </p>
                    </div>

                    <div className={`${style.certificateCard} col`}>
                        <h3 className={style.certificateTitle}>Project Completion Certificate</h3>
                        <p className={style.certificateDescription}>
                            Recognizes the successful completion of a project as part of a course or program, demonstrating applied knowledge and skills.
                        </p>
                    </div>
                </div>
            </div>


        </div>
        </>
    );
}

export default CertificateSHOW;
