import React, { useState } from 'react';
import style from './../Css/Footer.module.css';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import API_BASE_URL from '../apiConfig';

const Footer = () => {

    const navigate = useNavigate();
 
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    // Handle email input change
    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };
       // Handle subscribe button click
       const handleSubscribe = async () => {
        if (!email) {
            setMessage('Please enter your email.');
            return;
        }

        try {
            const response = await axios.post(`${API_BASE_URL}/subscribe.php`,  {
                email: email,
            });

            // Handle response from the backend
            if (response.data.status === 'success') {
                setMessage('Subscribed successfully!');
                setEmail('');
            } else {
                setMessage(response.data.message);
            }
        } catch (error) {
            setMessage('Failed to subscribe. Please try again later.');
        }
    };

    // Adjust scroll position to account for fixed navbar
    const scrollToSection = (sectionId) => {
        setTimeout(() => {
            const section = document.getElementById(sectionId);
            if (section) {
                window.scrollTo({
                    top: section.offsetTop - 80,  // Adjust the offset to match your navbar height
                    behavior: 'smooth',
                });
            }
        }, 10);
    };


    const handleAboutUsClick = (e) => {
        e.preventDefault();
        navigate('/');
        scrollToSection('ABOUT_SECTION');
    };


    const handleContactUsClick = (e) => {
        e.preventDefault();
        navigate('/');
        scrollToSection('Contact_Us');
    };


    const handleServicesClick = (e) => {
        e.preventDefault();
        navigate('/');
        scrollToSection('Service_s');
    };

    return (
        <>
             <div className={`${style.footerhead} container-fluid`}>
                <div className="container">
                    <div className="row d-flex justify-content-between mt-3 mb-3">
                        <div className={`${style.updates} col-md-4`}>
                            <p>Get our updates</p>
                            <p>Subscribe to our newsletter for the latest news and updates</p>
                        </div>
                        <div className={`${style.subscribe} col-md-auto`}>
                            <div className="row">
                                <div className="col">
                                    <input
                                        className={`${style.subscribeinput} w-100`}
                                        type="text"
                                        placeholder="Your email address"
                                        value={email}
                                        onChange={handleEmailChange}
                                    />
                                </div>

                                <div className="col-auto">
                                    <button className={style.subscribebtn} onClick={handleSubscribe}>
                                        Subscribe
                                    </button>
                                </div>
                            </div>
                            <p className={`${style.subtxt} pt-3`}>By subscribing you agree to our privacy policy.</p>
                            {message && <p className={style.message}>{message}</p>}
                        </div>
                    </div>
                </div>
            </div>


            <div className={`${style.footerbase} container-fluid`}>
                <div className="container">
                    <div className="row ">
                        <div className={style.footerLinksmain}>
                         

                            <div className={style.footer_flex}>

                                <p><Link onClick={handleAboutUsClick} className={style.footerlink}>About us</Link></p>
                                <p><Link onClick={handleContactUsClick} className={style.footerlink}>Contact us</Link></p>

                                <p><Link onClick={handleServicesClick} className={style.footerlink}>Services</Link></p>
                                <p><Link to={'/Privacy_policy'} className={style.footerlink} >Privacy Policy</Link></p>
                                <p><Link className={style.footerlink} to={'/TERMSofServices'} >Terms & Conditions</Link></p>

                            </div>
                        </div>
                        <div className={`${style.footerbase} row  d-flex justify-content-between`}>
                            <div className="col">
                                <p className={style.logofooter}>
                                    IITS CODE
                                </p>
                                <p className={`${style.copywrite}`}> © 2024 All right reserved.</p>
                            </div>
                            <div className={`${style.footerbase_mid} col`}>
                          
                                <p><Link to={'/Cancellation_Refund'} className={style.footerlink} >Cancellation & Refund</Link></p>
                                <p><Link className={style.footerlink} to={'/Shipping_Delivery'} >Shipping & Delivery</Link></p>
                            </div>
                            <div className={` col`}>

                                <div className={style.followUsContainer}>
                                    <h3 className={style.followUsHeading}>Follow Us On</h3>
                                    <div className={style.socialIcons}>
                                        <a href="https://www.instagram.com/iits_code/profilecard/?igsh=MXMxc3J2YjkyYXc5bQ==" target="_blank" rel="noopener noreferrer" className={style.socialIcon}>
                                            <i className="fab fa-instagram"></i> 
                                        </a>
                                        <a href="https://www.facebook.com/profile.php?id=61567940919551" target="_blank" rel="noopener noreferrer" className={style.socialIcon}>
                                            <i className="fab fa-facebook"></i>
                                        </a>
                                        <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className={style.socialIcon}>
                                            <i className="fab fa-twitter"></i>
                                        </a>
                                        <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className={style.socialIcon}>
                                            <i className="fab fa-linkedin"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>
            </div>









        </>
    );
}

export default Footer;
