import React from "react";
import Landingpageanimation from "./Landingpageanimation";
import style from "./../Css/Landingpage.module.css";
import Spline from "@splinetool/react-spline";
import { Link } from "react-router-dom";
import Coursesbuy from "./Coursesbuy";
import Packages from "./Packages";
import Aboutus from "./Aboutus";

const Landingpage = () => {


  return (
    <>
      <div className={` ${style.landbg} container-fluid `}>
     
          <div className={`${style.landingview} container-fluid`}>

            <div className={`${style.leftcol} col`}>
              <div className={style.typewriter}>welcome to IITS CODE </div>
              <h1 className={style.heading}>
                Unlock Your Potential with live <b>classes</b>
              </h1>
              <p className={style.subtitle}>
                Our live classes offer interactive learning experiences led by
                expert instructors. Gain practical skills and earn a certificate
                to advance your career.
              </p>

              <Landingpageanimation />



            </div>



          </div>



          <Coursesbuy />
          <Packages />


        </div>
 
    </>
  );
};

export default Landingpage;
