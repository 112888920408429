import React from 'react';
import style from './../Css/Aboutus.module.css'

const Aboutus = () => {
    return (

        <>
            <div className={`${style.about_container} container-fluid`} id='ABOUT_SECTION'>
                <div className="container">
                    <div className={`${style.about_row} row`}>

                        <div className={`${style.leftside} col`}>

                            <h1> Milestones </h1>

                            <h3><i class="fa-solid fa-check" > </i> Founded in 2015</h3>

                            <h3><i class="fa-solid fa-check" > </i>Teached over 20k + student</h3>


                            <h3><i class="fa-solid fa-check"> </i> More than 70% job selection</h3>

                            <h3><i class="fa-solid fa-check"> </i> 24/7 Native support</h3>

                            <h2>IITS CODE</h2>

                        </div>



                        <div className={`${style.rightside} col`}>
                            <h1>About Company</h1>
                            <p>Welcome to Integration IT Services, where technology meets education! Founded with a passion for empowering the next generation of tech leaders, IITs Code provides live, interactive coding classes and comprehensive IT services. Our mission is to make world-class IT education accessible and engaging for all, while delivering top-tier IT solutions tailored to meet the diverse needs of modern businesses.</p>
                            <h2>Our mission</h2>
                            <p>At IITs Code, we believe that knowledge is power, and in today’s digital world, coding and IT skills are essential tools. Our mission is to equip students, professionals, and businesses with the skills and resources they need to thrive in the ever-evolving tech landscape. From foundational coding courses to advanced IT strategies, we are committed to fostering innovation, creativity, and problem-solving through our programs and services.</p>
                            <h2>What We Offer</h2>
                            <p> <b>Live Coding Classes :</b> Our courses are designed for learners at every level, from beginners to advanced coders. With a hands-on, interactive approach, we provide live classes taught by industry experts. Students get real-time feedback, access to mentorship, and practical projects that build confidence and skills in real-world coding.</p>

                            <p><b>IT Services :</b> In addition to our educational offerings, we specialize in delivering a range of IT solutions for businesses. From custom software development and IT consulting to digital transformation strategies, we are here to support companies in achieving operational efficiency and staying competitive in the digital age.</p>

                            {/* <button>Join Us</button> */}
                        </div>
                    </div>
                </div>
            </div>









        </>
    )
}

export default Aboutus