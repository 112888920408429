import React, { useState } from 'react';
import style from './../../Css/Courses.module.css'

function Cplusplusdata() {
    // State to keep track of the selected chapter
    const [selectedChapter, setSelectedChapter] = useState('chapter1')

    // Function to handle chapter selection
    const handleChapterClick = (chapter) => {
        setSelectedChapter(chapter)
    }

    // State to track the active chapter
    const [activeChapter, setActiveChapter] = useState('chapter1');

    // Function to handle chapter click
    const handleChapterActive = (chapter) => {
        setActiveChapter(chapter);
    };
    const handleClick = (chapter) => {
        handleChapterActive(chapter);
        handleChapterClick(chapter);
        window.scrollTo(0,0);
    };


    return (
        <div className={`${style.firstcontainer} container-fluid`}>

            <div className={`${style.therow} row`}>

                <div className={`${style.droupdownbtnbar} dropdown`}>
                    <button className={`${style.droupdownbtn} btn dropdown-toggle`} type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        C++
                    </button>
                    <ul className={`${style.dropdownmenu} dropdown-menu`}>

                 
                        <li
                            onClick={() => handleClick('chapter1')}

                            className={`${style.chapter1} ${activeChapter === 'chapter1' ? style.active : ''} text-decoration-none dropdown-item`}>
                          
                          Introduction

                        </li>


                        <li
                            onClick={() => handleClick('chapter2')}
                            className={`${style.chapter2} ${activeChapter === 'chapter2' ? style.active : ''} dropdown-item `}>
                              History and Evolution
                            
                        </li>

                        <li onClick={() => handleClick('chapter3')}
                             className={`${style.chapter3} ${activeChapter === 'chapter3' ? style.active : ''} dropdown-item `}>
                             Setting up a Development Environment
              </li>

    

              <li onClick={() => handleClick('chapter4')} 
                className={`${style.chapter4} ${activeChapter === 'chapter4' ? style.active : ''} dropdown-item `}>
          Basic Syntax and Structure
              </li>


              <li onClick={() => handleClick('chapter5')} 
              className={`${style.chapter5} ${activeChapter === 'chapter5' ? style.active : ''} dropdown-item `}>
              Compilation and Execution Process
              </li>


<h5>Basic Concepts</h5>
              <li onClick={() => handleClick('chapter6')}
              className={`${style.chapter6} ${activeChapter === 'chapter6' ? style.active : ''} dropdown-item `}>
             Data Types    
              </li>

              <li onClick={() => handleClick('chapter7')} 
              className={`${style.chapter7} ${activeChapter === 'chapter7' ? style.active : ''} dropdown-item `}>
              Variables and Constants
              </li>

              <li onClick={() => handleClick('chapter8')}
               className={`${style.chapter8} ${activeChapter === 'chapter8' ? style.active : ''} dropdown-item `}>
              Input and Output
              </li>

              <li onClick={() => handleClick('chapter9')}
                className={`${style.chapter9} ${activeChapter === 'chapter9' ? style.active : ''} dropdown-item `}>
               Operators
              </li>


<h5>Control Flow</h5>
              <li onClick={() => handleClick('chapter10')}
               className={`${style.chapter10} ${activeChapter === 'chapter10' ? style.active : ''} dropdown-item `}>
               Conditional Statements
              </li>

              <li onClick={() => handleClick('chapter11')} 
              className={`${style.chapter11} ${activeChapter === 'chapter11' ? style.active : ''} dropdown-item `}>
              Loops
                  </li>

              <li onClick={() => handleClick('chapter12')}
               className={`${style.chapter12} ${activeChapter === 'chapter12' ? style.active : ''} dropdown-item `}>
                Break and Continue
              </li>


 <h5>Functions</h5>  
              <li onClick={() => handleClick('chapter13')} 
              className={`${style.chapter13} ${activeChapter === 'chapter13' ? style.active : ''} dropdown-item `}>
               Function Declaration and Definition 
               </li>

              <li onClick={() => handleClick('chapter14')} 
               className={`${style.chapter14} ${activeChapter === 'chapter14' ? style.active : ''} dropdown-item `}>
                 Function Overloading
              </li>

              <li onClick={() => handleClick('chapter15')} 
                className={`${style.chapter15} ${activeChapter === 'chapter15' ? style.active : ''} dropdown-item `}>
               Default Arguments
              </li>

              <li onClick={() => handleClick('chapter16')} 
              className={`${style.chapter16} ${activeChapter === 'chapter16' ? style.active : ''} dropdown-item `}>
                  Inline Functions
              </li>

              <li onClick={() => handleClick('chapter17')}
                className={`${style.chapter17} ${activeChapter === 'chapter17' ? style.active : ''} dropdown-item `}>
              Recursion
              </li>


<h5>Arrays and Strings</h5>
              <li onClick={() => handleClick('chapter18')} 
               className={`${style.chapter18} ${activeChapter === 'chapter18' ? style.active : ''} dropdown-item `}>
                 Arrays
              </li>


              <li onClick={() => handleClick('chapter19')}
               className={`${style.chapter19} ${activeChapter === 'chapter19' ? style.active : ''} dropdown-item `}>
           Strings
              </li>

              <li onClick={() => handleClick('chapter20')} 
              className={`${style.chapter20} ${activeChapter === 'chapter20' ? style.active : ''} dropdown-item `}>
           String Manipulation Functions
              </li>


<h5>Pointer and Reference</h5>
              <li onClick={() => handleClick('chapter21')}
               className={`${style.chapter21} ${activeChapter === 'chapter21' ? style.active : ''} dropdown-item `}>
              Inroduction of Pointers
              </li>

              <li onClick={() => handleClick('chapter22')} 
              className={`${style.chapter22} ${activeChapter === 'chapter22' ? style.active : ''} dropdown-item `}>
                Pointer Arithmetic
              </li>

              <li onClick={() => handleClick('chapter23')} 
               className={`${style.chapter23} ${activeChapter === 'chapter23' ? style.active : ''} dropdown-item `}>
               Dynamic Memory Allocation
              </li>

              <li onClick={() => handleClick('chapter24')} 
               className={`${style.chapter24} ${activeChapter === 'chapter24' ? style.active : ''} dropdown-item `}>
                Reference vs. Pointers
              </li>

              <li onClick={() => handleClick('chapter25')} 
               className={`${style.chapter25} ${activeChapter === 'chapter25' ? style.active : ''} dropdown-item `}>
                 Smart Pointers
              </li>

             <h5>  Object-Oriented Programming (OOP)</h5>
              <li onClick={() => handleClick('chapter26')} 
               className={`${style.chapter26} ${activeChapter === 'chapter26' ? style.active : ''} dropdown-item `}>
                 Class and Object
              </li>
             
              <li onClick={() => handleClick('chapter27')} 
               className={`${style.chapter27} ${activeChapter === 'chapter27' ? style.active : ''} dropdown-item `}>
                    Contructors and Destructors
              </li>
              <li onClick={() => handleClick('chapter28')} 
               className={`${style.chapter28} ${activeChapter === 'chapter28' ? style.active : ''}  dropdown-item `}>
                 Access Specifiers 
                   </li>

              <li onClick={() => handleClick('chapter29')} 
               className={`${style.chapter29} ${activeChapter === 'chapter29' ? style.active : ''} dropdown-item `}>
                 Inheritance
              </li>

              <li onClick={() => handleClick('chapter30')} 
               className={`${style.chapter30} ${activeChapter === 'chapter30' ? style.active : ''}  dropdown-item `}>
                Polymorphism
              </li>
              <li onClick={() => handleClick('chapter31')} 
               className={`${style.chapter31} ${activeChapter === 'chapter31' ? style.active : ''} dropdown-item `}>
                Abstract Classes and Interfaces
              </li>
<h5>Advanced OOP Concepts</h5>
              <li onClick={() => handleClick('chapter32')} 
               className={`${style.chapter32} ${activeChapter === 'chapter32' ? style.active : ''} dropdown-item `}>
                Operator Overloading
              </li>
 
              <li onClick={() => handleClick('chapter33')} 
               className={`${style.chapter33} ${activeChapter === 'chapter33' ? style.active : ''} dropdown-item `}>
                 Friend Functions and Classes
              </li>

              <li onClick={() => handleClick('chapter34')} 
               className={`${style.chapter34} ${activeChapter === 'chapter34' ? style.active : ''} dropdown-item `}>
                 Templates
              </li>

              <li onClick={() => handleClick('chapter35')} 
               className={`${style.chapter35} ${activeChapter === 'chapter35' ? style.active : ''} dropdown-item `}>
                Exception Handling
              </li>

              <li onClick={() => handleClick('chapter36')} 
               className={`${style.chapter36} ${activeChapter === 'chapter36' ? style.active : ''} dropdown-item `}>
                 Function Objects and Lambda Expression
              </li>

   <h5>Standard Templates Library</h5>
              <li onClick={() => handleClick('chapter37')} 
               className={`${style.chapter37} ${activeChapter === 'chapter37' ? style.active : ''} dropdown-item `}>
              Introduction of STL
              </li>

              <li onClick={() => handleClick('chapter38')} 
               className={`${style.chapter38} ${activeChapter === 'chapter38' ? style.active : ''} dropdown-item `}>
              Containers 
              </li>

              <li onClick={() => handleClick('chapter39')} 
               className={`${style.chapter39} ${activeChapter === 'chapter39' ? style.active : ''} dropdown-item `}>
              Iterator
              </li>

              <li onClick={() => handleClick('chapter40')} 
               className={`${style.chapter40} ${activeChapter === 'chapter40' ? style.active : ''} dropdown-item `}>
                 Algorithms
              </li>

              <li onClick={() => handleClick('chapter41')} 
               className={`${style.chapter41} ${activeChapter === 'chapter41' ? style.active : ''} dropdown-item `}>
               Functors
              </li>

<h5>File Handling</h5>
              <li onClick={() => handleClick('chapter42')} 
               className={`${style.chapter42} ${activeChapter === 'chapter42' ? style.active : ''} dropdown-item `}>
                  File Input and Output
              </li>

              <li onClick={() => handleClick('chapter43')} 
               className={`${style.chapter43} ${activeChapter === 'chapter43' ? style.active : ''} dropdown-item `}>
              Reading and Writing Text and Binary Files 
              </li>

              <li onClick={() => handleClick('chapter44')} 
               className={`${style.chapter44} ${activeChapter === 'chapter44' ? style.active : ''} dropdown-item `}>
              File Streams and Error Handling
              </li>

<h5>Memory Management</h5>
              <li onClick={() => handleClick('chapter45')} 
               className={`${style.chapter45} ${activeChapter === 'chapter45' ? style.active : ''} dropdown-item `}>
                  Static vs. Dynamic Memory
              </li>

              <li onClick={() => handleClick('chapter46')} 
               className={`${style.chapter46} ${activeChapter === 'chapter46' ? style.active : ''} dropdown-item `}>
                 Memory Leaks and Debugging
              </li>

              <li onClick={() => handleClick('chapter47')} 
               className={`${style.chapter47} ${activeChapter === 'chapter47' ? style.active : ''} dropdown-item `}>
                  Resouurce Acquisition Is Initilization
              </li>

<h5>Concurrency and Multithreading</h5>
              <li onClick={() => handleClick('chapter48')} 
               className={`${style.chapter48} ${activeChapter === 'chapter48' ? style.active : ''} dropdown-item `}>
      Introduction of Threads
              </li>

              <li onClick={() => handleClick('chapter49')} 
               className={`${style.chapter49} ${activeChapter === 'chapter49' ? style.active : ''} dropdown-item `}>
      Creating and Managing Threads
              </li>

              <li onClick={() => handleClick('chapter50')} 
               className={`${style.chapter50} ${activeChapter === 'chapter50' ? style.active : ''} dropdown-item `}>
               Mutexes and Locks
              </li>


              <li onClick={() => handleClick('chapter51')} 
               className={`${style.chapter51} ${activeChapter === 'chapter51' ? style.active : ''} dropdown-item `}>
                  Condition Variables
              </li>


              <li onClick={() => handleClick('chapter52')} 
               className={`${style.chapter52} ${activeChapter === 'chapter52' ? style.active : ''} dropdown-item `}>
      Thread Safety and Data Races
              </li>

<h5>Advanced Features</h5>
              <li onClick={() => handleClick('chapter53')} 
               className={`${style.chapter53} ${activeChapter === 'chapter53' ? style.active : ''} dropdown-item `}>
       C++ 11/14/17 Features 
              </li>



              <li onClick={() => handleClick('chapter54')} 
               className={`${style.chapter54} ${activeChapter === 'chapter54' ? style.active : ''} dropdown-item `}>
      
      
      Move Semantics
              </li>


              <li onClick={() => handleClick('chapter55')} 
               className={`${style.chapter55} ${activeChapter === 'chapter55' ? style.active : ''} dropdown-item `}>
    Copy and Move Constructors
      </li>

      
      <li onClick={() => handleClick('chapter56')} 
               className={`${style.chapter56} ${activeChapter === 'chapter56' ? style.active : ''} dropdown-item `}>
      Namespace and Scope Resolution
      </li>

      <li onClick={() => handleClick('chapter57')} 
               className={`${style.chapter57} ${activeChapter === 'chapter57' ? style.active : ''} dropdown-item `}>
        Preprocessor Directives
      </li>


   <h5>Design Patterns</h5>

      <li onClick={() => handleClick('chapter58')} 
               className={`${style.chapter58} ${activeChapter === 'chapter58' ? style.active : ''} dropdown-item `}>
     Inroduction to Design Patterns
      </li>



      <li onClick={() => handleClick('chapter59')} 
               className={`${style.chapter59} ${activeChapter === 'chapter59' ? style.active : ''} dropdown-item `}>
       Common Patterns 
      </li>


<h5>Best Practices</h5>
      <li onClick={() => handleClick('chapter60')} 
               className={`${style.chapter60} ${activeChapter === 'chapter60' ? style.active : ''} dropdown-item `}>
   Coding Standards
      </li>


      <li onClick={() => handleClick('chapter61')} 
               className={`${style.chapter61} ${activeChapter === 'chapter61' ? style.active : ''} dropdown-item `}>
      Code Optimization Techniques
      </li>

      <li onClick={() => handleClick('chapter62')} 
               className={`${style.chapter62} ${activeChapter === 'chapter62' ? style.active : ''} dropdown-item `}>
       Debugging and Testing
      </li>


 


             



              </ul>
                </div>





                <div className={`${style.leftcolumn} col-2`}>

                    <ul className={`${style.chapters} `}>

                    <h5 className={style.stickyheading}>C++</h5>
                        <li
                            onClick={() => handleClick('chapter1')}

                            className={`${style.chapter1} ${activeChapter === 'chapter1' ? style.active : ''} text-decoration-none`}>
                             Introduction
                            </li>


                            <li
                            onClick={() => handleClick('chapter2')}
                            className={`${style.chapter2} ${activeChapter === 'chapter2' ? style.active : ''} `}>
                              History and Evolution
                            
                        </li>

                        <li onClick={() => handleClick('chapter3')}
                             className={`${style.chapter3} ${activeChapter === 'chapter3' ? style.active : ''}`}>
                             Setting up a Development Environment
              </li>

    

              <li onClick={() => handleClick('chapter4')} 
                className={`${style.chapter4} ${activeChapter === 'chapter4' ? style.active : ''}`}>
          Basic Syntax and Structure
              </li>


              <li onClick={() => handleClick('chapter5')} 
              className={`${style.chapter5} ${activeChapter === 'chapter5' ? style.active : ''}`}>
              Compilation and Execution Process
              </li>


<h5>Basic Concepts</h5>
              <li onClick={() => handleClick('chapter6')}
              className={`${style.chapter6} ${activeChapter === 'chapter6' ? style.active : ''}`}>
             Data Types    
              </li>

              <li onClick={() => handleClick('chapter7')} 
              className={`${style.chapter7} ${activeChapter === 'chapter7' ? style.active : ''}`}>
              Variables and Constants
              </li>

              <li onClick={() => handleClick('chapter8')}
               className={`${style.chapter8} ${activeChapter === 'chapter8' ? style.active : ''}`}>
              Input and Output
              </li>

              <li onClick={() => handleClick('chapter9')}
                className={`${style.chapter9} ${activeChapter === 'chapter9' ? style.active : ''}`}>
               Operators
              </li>


<h5>Control Flow</h5>
              <li onClick={() => handleClick('chapter10')}
               className={`${style.chapter10} ${activeChapter === 'chapter10' ? style.active : ''}`}>
               Conditional Statements
              </li>

              <li onClick={() => handleClick('chapter11')} 
              className={`${style.chapter11} ${activeChapter === 'chapter11' ? style.active : ''}`}>
              Loops
                  </li>

              <li onClick={() => handleClick('chapter12')}
               className={`${style.chapter12} ${activeChapter === 'chapter12' ? style.active : ''}`}>
                Break and Continue
              </li>


 <h5>Functions</h5>  
              <li onClick={() => handleClick('chapter13')} 
              className={`${style.chapter13} ${activeChapter === 'chapter13' ? style.active : ''}`}>
               Function Declaration and Definition 
               </li>

              <li onClick={() => handleClick('chapter14')} 
               className={`${style.chapter14} ${activeChapter === 'chapter14' ? style.active : ''}`}>
                 Function Overloading
              </li>

              <li onClick={() => handleClick('chapter15')} 
                className={`${style.chapter15} ${activeChapter === 'chapter15' ? style.active : ''}`}>
               Default Arguments
              </li>

              <li onClick={() => handleClick('chapter16')} 
              className={`${style.chapter16} ${activeChapter === 'chapter16' ? style.active : ''}`}>
                  Inline Functions
              </li>

              <li onClick={() => handleClick('chapter17')}
                className={`${style.chapter17} ${activeChapter === 'chapter17' ? style.active : ''}`}>
              Recursion
              </li>


<h5>Arrays and Strings</h5>
              <li onClick={() => handleClick('chapter18')} 
               className={`${style.chapter18} ${activeChapter === 'chapter18' ? style.active : ''}`}>
                 Arrays
              </li>


              <li onClick={() => handleClick('chapter19')}
               className={`${style.chapter19} ${activeChapter === 'chapter19' ? style.active : ''} `}>
           Strings
              </li>

              <li onClick={() => handleClick('chapter20')} 
              className={`${style.chapter20} ${activeChapter === 'chapter20' ? style.active : ''}`}>
           String Manipulation Functions
              </li>


<h5>Pointer and Reference</h5>
              <li onClick={() => handleClick('chapter21')}
               className={`${style.chapter21} ${activeChapter === 'chapter21' ? style.active : ''}`}>
              Inroduction of Pointers
              </li>

              <li onClick={() => handleClick('chapter22')} 
              className={`${style.chapter22} ${activeChapter === 'chapter22' ? style.active : ''}`}>
                Pointer Arithmetic
              </li>

              <li onClick={() => handleClick('chapter23')} 
               className={`${style.chapter23} ${activeChapter === 'chapter23' ? style.active : ''}  `}>
               Dynamic Memory Allocation
              </li>

              <li onClick={() => handleClick('chapter24')} 
               className={`${style.chapter24} ${activeChapter === 'chapter24' ? style.active : ''}  `}>
                Reference vs. Pointers
              </li>

              <li onClick={() => handleClick('chapter25')} 
               className={`${style.chapter25} ${activeChapter === 'chapter25' ? style.active : ''}  `}>
                 Smart Pointers
              </li>

             <h5>  Object-Oriented Programming (OOP)</h5>
              <li onClick={() => handleClick('chapter26')} 
               className={`${style.chapter26} ${activeChapter === 'chapter26' ? style.active : ''}  `}>
                 Class and Object
              </li>
             
              <li onClick={() => handleClick('chapter27')} 
               className={`${style.chapter27} ${activeChapter === 'chapter27' ? style.active : ''}  `}>
                    Contructors and Destructors
              </li>
              <li onClick={() => handleClick('chapter28')} 
               className={`${style.chapter28} ${activeChapter === 'chapter28' ? style.active : ''}  `}>
                 Access Specifiers 
                   </li>

              <li onClick={() => handleClick('chapter29')} 
               className={`${style.chapter29} ${activeChapter === 'chapter29' ? style.active : ''}  `}>
                 Inheritance
              </li>

              <li onClick={() => handleClick('chapter30')} 
               className={`${style.chapter30} ${activeChapter === 'chapter30' ? style.active : ''}  `}>
                Polymorphism
              </li>
              <li onClick={() => handleClick('chapter31')} 
               className={`${style.chapter31} ${activeChapter === 'chapter31' ? style.active : ''}  `}>
                Abstract Classes and Interfaces
              </li>
<h5>Advanced OOP Concepts</h5>
              <li onClick={() => handleClick('chapter32')} 
               className={`${style.chapter32} ${activeChapter === 'chapter32' ? style.active : ''}  `}>
                Operator Overloading
              </li>
 
              <li onClick={() => handleClick('chapter33')} 
               className={`${style.chapter33} ${activeChapter === 'chapter33' ? style.active : ''}  `}>
                 Friend Functions and Classes
              </li>

              <li onClick={() => handleClick('chapter34')} 
               className={`${style.chapter34} ${activeChapter === 'chapter34' ? style.active : ''}  `}>
                 Templates
              </li>

              <li onClick={() => handleClick('chapter35')} 
               className={`${style.chapter35} ${activeChapter === 'chapter35' ? style.active : ''}  `}>
                Exception Handling
              </li>

              <li onClick={() => handleClick('chapter36')} 
               className={`${style.chapter36} ${activeChapter === 'chapter36' ? style.active : ''}  `}>
                 Function Objects and Lambda Expression
              </li>

   <h5>Standard Templates Library</h5>
              <li onClick={() => handleClick('chapter37')} 
               className={`${style.chapter37} ${activeChapter === 'chapter37' ? style.active : ''}  `}>
              Introduction of STL
              </li>

              <li onClick={() => handleClick('chapter38')} 
               className={`${style.chapter38} ${activeChapter === 'chapter38' ? style.active : ''}  `}>
              Containers 
              </li>

              <li onClick={() => handleClick('chapter39')} 
               className={`${style.chapter39} ${activeChapter === 'chapter39' ? style.active : ''}  `}>
              Iterator
              </li>

              <li onClick={() => handleClick('chapter40')} 
               className={`${style.chapter40} ${activeChapter === 'chapter40' ? style.active : ''}  `}>
                 Algorithms
              </li>

              <li onClick={() => handleClick('chapter41')} 
               className={`${style.chapter41} ${activeChapter === 'chapter41' ? style.active : ''}  `}>
               Functors
              </li>

<h5>File Handling</h5>
              <li onClick={() => handleClick('chapter42')} 
               className={`${style.chapter42} ${activeChapter === 'chapter42' ? style.active : ''}  `}>
                  File Input and Output
              </li>

              <li onClick={() => handleClick('chapter43')} 
               className={`${style.chapter43} ${activeChapter === 'chapter43' ? style.active : ''}  `}>
              Reading and Writing Text and Binary Files 
              </li>

              <li onClick={() => handleClick('chapter44')} 
               className={`${style.chapter44} ${activeChapter === 'chapter44' ? style.active : ''}  `}>
              File Streams and Error Handling
              </li>

<h5>Memory Management</h5>
              <li onClick={() => handleClick('chapter45')} 
               className={`${style.chapter45} ${activeChapter === 'chapter45' ? style.active : ''}  `}>
                  Static vs. Dynamic Memory
              </li>

              <li onClick={() => handleClick('chapter46')} 
               className={`${style.chapter46} ${activeChapter === 'chapter46' ? style.active : ''}  `}>
                 Memory Leaks and Debugging
              </li>

              <li onClick={() => handleClick('chapter47')} 
               className={`${style.chapter47} ${activeChapter === 'chapter47' ? style.active : ''}  `}>
                  Resouurce Acquisition Is Initilization
              </li>

<h5>Concurrency and Multithreading</h5>
              <li onClick={() => handleClick('chapter48')} 
               className={`${style.chapter48} ${activeChapter === 'chapter48' ? style.active : ''}  `}>
      Introduction of Threads
              </li>

              <li onClick={() => handleClick('chapter49')} 
               className={`${style.chapter49} ${activeChapter === 'chapter49' ? style.active : ''}  `}>
      Creating and Managing Threads
              </li>

              <li onClick={() => handleClick('chapter50')} 
               className={`${style.chapter50} ${activeChapter === 'chapter50' ? style.active : ''}  `}>
               Mutexes and Locks
              </li>


              <li onClick={() => handleClick('chapter51')} 
               className={`${style.chapter51} ${activeChapter === 'chapter51' ? style.active : ''}  `}>
                  Condition Variables
              </li>


              <li onClick={() => handleClick('chapter52')} 
               className={`${style.chapter52} ${activeChapter === 'chapter52' ? style.active : ''}  `}>
      Thread Safety and Data Races
              </li>

<h5>Advanced Features</h5>
              <li onClick={() => handleClick('chapter53')} 
               className={`${style.chapter53} ${activeChapter === 'chapter53' ? style.active : ''}  `}>
       C++ 11/14/17 Features 
              </li>



              <li onClick={() => handleClick('chapter54')} 
               className={`${style.chapter54} ${activeChapter === 'chapter54' ? style.active : ''}  `}>
      
      
      Move Semantics
              </li>


              <li onClick={() => handleClick('chapter55')} 
               className={`${style.chapter55} ${activeChapter === 'chapter55' ? style.active : ''}  `}>
    Copy and Move Constructors
      </li>

      
      <li onClick={() => handleClick('chapter56')} 
               className={`${style.chapter56} ${activeChapter === 'chapter56' ? style.active : ''}  `}>
      Namespace and Scope Resolution
      </li>

      <li onClick={() => handleClick('chapter57')} 
               className={`${style.chapter57} ${activeChapter === 'chapter57' ? style.active : ''}  `}>
        Preprocessor Directives
      </li>


   <h5>Design Patterns</h5>

      <li onClick={() => handleClick('chapter58')} 
               className={`${style.chapter58} ${activeChapter === 'chapter58' ? style.active : ''}  `}>
     Inroduction to Design Patterns
      </li>



      <li onClick={() => handleClick('chapter59')} 
               className={`${style.chapter59} ${activeChapter === 'chapter59' ? style.active : ''}  `}>
       Common Patterns 
      </li>


<h5>Best Practices</h5>
      <li onClick={() => handleClick('chapter60')} 
               className={`${style.chapter60} ${activeChapter === 'chapter60' ? style.active : ''}  `}>
   Coding Standards
      </li>


      <li onClick={() => handleClick('chapter61')} 
               className={`${style.chapter61} ${activeChapter === 'chapter61' ? style.active : ''}  `}>
      Code Optimization Techniques
      </li>

      <li onClick={() => handleClick('chapter62')} 
               className={`${style.chapter62} ${activeChapter === 'chapter62' ? style.active : ''}  `}>
       Debugging and Testing
      </li>


 



                    </ul>
                </div>




                <div className={`${style.rightcolumn} col`}>
                    {/* Chapter Content */}


                    {selectedChapter === 'chapter1' && (
<div className={style.chaptercontent}>
<h1 className={style.heading}>Introduction</h1>

          <h3>Overview:</h3>
          <p>
            C++ is a powerful, high-level programming language that extends the capabilities of the C programming language. Developed by Bjarne Stroustrup in the early 1980s, C++ incorporates object-oriented programming (OOP) principles, enabling developers to create modular and reusable code. It is widely used in software development, game development, systems programming, and applications requiring high performance.
          </p><br />

          <h3 style={{paddingBottom:"6px"}}>Key Features:</h3>
          <ul>
            <li>
              <strong>Object-Oriented Programming:</strong> C++ supports classes and objects, encapsulation, inheritance, and polymorphism, allowing for the design of complex systems with a focus on reusability and scalability.
            </li><br />
            <li>
              <strong>Standard Template Library (STL):</strong> C++ includes a rich set of libraries and templates that provide data structures (like vectors, lists, and maps) and algorithms (like sorting and searching) for efficient programming.
            </li><br />
            <li>
              <strong>Performance:</strong> C++ is known for its performance and efficiency, making it suitable for system-level programming, real-time applications, and resource-constrained environments.
            </li><br />
            <li>
              <strong>Portability:</strong> C++ code can be compiled on various platforms with minimal changes, making it a versatile choice for cross-platform development.
            </li><br />
            <li>
              <strong>Low-level Manipulation:</strong> C++ allows direct manipulation of hardware and memory, giving developers fine-grained control over system resources.
            </li>
          </ul><br />

          <h3>Basic Syntax:</h3>
          <p>
            A simple C++ program typically includes:
          </p>
          <ul>
            <li><code>#include &lt;iostream&gt;:</code> A preprocessor directive to include the input-output stream library.</li><br />
            <li><code>int main():</code> The main function where the execution begins.</li><br />
            <li><code>std::cout:</code> Used for outputting data to the console.</li><br />
            <li>Return statement to indicate the end of the program.</li>
          </ul><br />

          <h3>Example:</h3>
          <pre>
            <code>
              {`#include <iostream>

int main() {
    std::cout << "Hello, World!" << std::endl;
    return 0;
}`}
            </code>
          </pre><br />

          <h3 style={{paddingBottom:"6px"}}>Applications:</h3>
          <ul>
            <li>Game development (e.g., Unreal Engine)</li><br />
            <li>Systems software (e.g., operating systems)</li><br />
            <li>Embedded systems</li><br />
            <li>High-performance applications (e.g., financial systems, simulations)</li><br />
            <li>Application software (e.g., GUI applications)</li>
          </ul><br />

          <h3>Conclusion:</h3>
          <p>
            C++ is a versatile and robust programming language that balances low-level memory manipulation with high-level abstraction features. Its widespread use and rich feature set make it a valuable language for both beginners and experienced developers.
          </p>
       

</div>
)}



{selectedChapter === 'chapter2' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> History and Evolution   </h1>

          <h3>1. Origins (1979-1983):</h3>
          <p>
            C++ was developed by Bjarne Stroustrup at Bell Labs in Murray Hill, New Jersey. It began as an enhancement to the C programming language, aiming to include features that support object-oriented programming. Initially called "C with Classes," it introduced basic OOP features such as classes, basic inheritance, and static member functions.
          </p><br />

          <h3>2. Name Change (1983):</h3>
          <p>
            The language was officially renamed C++ in 1983. The name reflects the increment operator in C, symbolizing the evolution from C to C++. This phase introduced additional features such as function overloading and default arguments.
          </p><br />

          <h3>3. Standardization (1998):</h3>
          <p>
            The first standardized version of C++, known as C++98, was published by the International Organization for Standardization (ISO) in 1998. This standard formalized many features that had become widely used, including the Standard Template Library (STL), which provided a collection of generic classes and functions for data structures and algorithms.
          </p><br />

          <h3>4. C++03:</h3>
          <p>
            In 2003, a minor revision called C++03 was released to fix defects in the C++98 standard. This version did not introduce significant new features but improved the stability and consistency of the language.
          </p><br />

          <h3>5. C++11 (2011):</h3>
          <p>
            C++11 marked a major update to the language, introducing numerous features aimed at improving performance, safety, and expressiveness. Key additions included:
          </p>
          <ul>
            <li>Lambda expressions</li><br />
            <li>Auto keyword for type inference</li><br />
            <li>Range-based for loops</li><br />
            <li>Smart pointers (e.g., <code>std::unique_ptr</code>, <code>std::shared_ptr</code>)</li><br />
            <li>Move semantics</li><br />
            <li>Improved support for multithreading</li>
          </ul><br />

          <h3>6. C++14 (2014):</h3>
          <p>
            C++14 built upon the features of C++11 with minor enhancements and bug fixes. It introduced features like:
          </p>
          <ul>
            <li>Generic lambdas</li><br />
            <li><code>std::make_unique</code> for unique pointer creation</li><br />
            <li>Relaxed <code>constexpr</code> restrictions</li><br />
          </ul><br />

          <h3>7. C++17 (2017):</h3>
          <p>
            C++17 continued the evolution with several significant additions, including:
          </p>
          <ul>
            <li>Standard filesystem library</li><br />
            <li>Parallel algorithms</li><br />
            <li><code>std::optional</code> and <code>std::variant</code> for safer code</li><br />
            <li>Structured bindings for easier unpacking of tuples and pairs</li>
          </ul><br />

          <h3>8. C++20 (2020):</h3>
          <p>
            C++20 is a major update that introduced several powerful features, including:
          </p>
          <ul>
            <li>Concepts for specifying template requirements</li><br />
            <li>Ranges for easier manipulation of collections</li><br />
            <li>Coroutines for asynchronous programming</li><br />
            <li>Improved <code>constexpr</code> capabilities</li><br />
            <li>New standard attributes like <code>[[likely]]</code> and <code>[[unlikely]]</code></li>
          </ul><br />

          <h3>9. C++23 and Beyond:</h3>
          <p>
            The development of C++ continues with ongoing proposals and discussions for the C++23 standard, which aims to introduce even more features and improvements based on community feedback.
          </p><br />

          <h3>Conclusion:</h3>
          <p>
            C++ has evolved significantly since its inception, adapting to the changing needs of software development while maintaining its foundational principles. Its rich history reflects a balance between high-level abstraction and low-level control, making it a versatile language widely used in various domains, from systems programming to game development. The ongoing standardization efforts ensure that C++ remains relevant and powerful for future generations of developers.
          </p>
      

  </div>
)}





{selectedChapter === 'chapter3' && (
  <div className={style.chaptercontent}>

    <h1 className={style.heading}>  Setting up a Development Environment  </h1>

          <p>
            Setting up a development environment for C++ is essential for writing, compiling, and debugging C++ programs effectively. Here’s a step-by-step guide to help you get started:
          </p><br />

          <h3>1. Choose a Text Editor or Integrated Development Environment (IDE)</h3>
          <p>
            You can either use a simple text editor or a more feature-rich IDE. Popular options include:
          </p>
          <ul>
            <li><strong>IDEs:</strong>
              <ul>
                <li><strong>Visual Studio:</strong> A powerful IDE for Windows that provides extensive support for C++ development.</li><br />
                <li><strong>Code::Blocks:</strong> A free, cross-platform IDE that supports multiple compilers.</li><br />
                <li><strong>CLion:</strong> A commercial IDE from JetBrains with advanced features and tools for C++ development.</li>
              </ul>
            </li><br />
            <li><strong>Text Editors:</strong>
              <ul>
                <li><strong>Visual Studio Code:</strong> A lightweight editor that can be enhanced with extensions for C++ development.</li><br />
                <li><strong>Sublime Text:</strong> A fast and customizable text editor suitable for coding in C++.</li><br />
                <li><strong>Notepad++:</strong> A simple yet effective text editor for Windows.</li>
              </ul>
            </li>
          </ul><br />

          <h3>2. Install a C++ Compiler</h3>
          <p>
            A compiler converts C++ code into executable programs. Here are some popular compilers:
          </p>
          <ul>
            <li><strong>GCC (GNU Compiler Collection):</strong> Widely used and available on multiple platforms (Windows, macOS, Linux). To install:
              <ul>
                <li><strong>Windows:</strong> Use MinGW or WSL (Windows Subsystem for Linux).</li><br />
                <li><strong>Linux:</strong> Install via package manager (e.g., <code>sudo apt install g++</code> on Ubuntu).</li><br />
                <li><strong>macOS:</strong> Install via Homebrew (<code>brew install gcc</code>).</li>
              </ul>
            </li><br />
            <li><strong>Clang:</strong> A compiler that emphasizes performance and is used extensively in macOS and Linux environments.</li><br />
            <li><strong>Microsoft Visual C++ (MSVC):</strong> Comes with Visual Studio and is specifically tailored for Windows development.</li>
          </ul><br />

          <h3>3. Set Up the Environment Variables (if needed)</h3>
          <p>
            On Windows, you may need to add your compiler's bin directory to the system PATH:
          </p>
          <ol>
            <li>Right-click on "This PC" or "Computer" and select "Properties."</li><br />
            <li>Click on "Advanced system settings."</li><br />
            <li>Click the "Environment Variables" button.</li><br />
            <li>In the "System variables" section, find the <code>Path</code> variable and add the path to your compiler's <code>bin</code> directory (e.g., <code>C:\MinGW\bin</code>).</li>
          </ol><br />

          <h3>4. Write Your First C++ Program</h3>
          <p>
            Create a new file with a <code>.cpp</code> extension (e.g., <code>hello.cpp</code>) and write a simple C++ program:
          </p>
          <pre>
            <code>
              {`#include <iostream>

int main() {
    std::cout << "Hello, World!" << std::endl;
    return 0;
}`}
            </code>
          </pre><br />

          <h3>5. Compile and Run the Program</h3>
          <p>
            To compile and run your program, open a terminal or command prompt and navigate to the directory where your C++ file is saved. Use the following commands:
          </p>
          <ul>
            <li><strong>Using GCC:</strong>
              <pre>
                <code>
                  {`g++ hello.cpp -o hello   # Compile the program
./hello                   # Run the executable (Linux/macOS)
hello.exe                 # Run the executable (Windows)`}
                </code>
              </pre>
            </li>
            <li><strong>Using MSVC:</strong>
              <pre>
                <code>
                  {`cl hello.cpp              # Compile and run in the same command`}
                </code>
              </pre>
            </li>
          </ul><br />

          <h3>6. Debugging and Testing</h3>
          <p>
            For debugging, use built-in tools in your IDE or terminal commands:
          </p>
          <ul>
            <li><strong>GDB (GNU Debugger):</strong> A powerful tool for debugging C++ applications.
              <pre>
                <code>
                  {`g++ -g hello.cpp -o hello  # Compile with debug information
gdb ./hello                 # Start debugging`}
                </code>
              </pre>
            </li>
          </ul><br />

          <h3>7. Additional Tools</h3>
          <ul>
            <li><strong>Version Control:</strong> Consider using Git for version control to manage your code effectively.</li><br />
            <li><strong>Build Systems:</strong> Use tools like CMake or Make to manage complex projects and automate the build process.</li>
          </ul><br />

          <h3>Conclusion</h3>
          <p>
            Setting up a C++ development environment involves selecting the right tools, installing a compiler, and configuring your system. By following these steps, you'll be well-equipped to start coding in C++. As you progress, you can explore advanced tools and libraries to enhance your development experience.
          </p>
     

   
  </div>
)}





{selectedChapter === 'chapter4' && (
<div className={style.chaptercontent}>
<h1 className={style.heading}>  Basic Syntax and Structure  </h1>
 

    <h2>1. Basic Structure of a C++ Program</h2>

    <p style={{paddingBottom:"6px"}}>
      A typical C++ program consists of the following parts:
    </p>

    <ul>
      <li>
        <strong>Preprocessor Directives:</strong> These are lines that begin with a <code>#</code> and instruct the compiler to include libraries or define macros. For example:
        <pre><code>#include &lt;iostream&gt;</code></pre>
      </li><br />
      <li>
        <strong>Main Function:</strong> This is where program execution begins. Every C++ program must have a <code>main()</code> function.
        <pre><code>{`
int main() {'\n'}
    // Code goes here{' '}
    return 0;{' '}
}
        `}</code></pre>
      </li><br />
      <li>
        <strong>Return Statement:</strong> Indicates the end of the <code>main()</code> function and returns a value to the operating system. A return value of <code>0</code> usually signifies successful execution.
      </li>
    </ul><br />

    <h2 style={{paddingBottom:"6px"}}>2. Basic Syntax Elements</h2>

    <ul>
      <li>
        <strong>Statements:</strong> Each line of code that performs an action is a statement, ending with a semicolon (<code>;</code>).
        <pre><code>{`std::cout << "Hello, World!"; // Outputs text to the console`}</code></pre>
      </li><br />
      <li>
        <strong>Comments:</strong> Use comments to annotate your code. There are two types:
        <ul>
          <li>Single-line comments: <code>// This is a comment</code></li>
          <li>Multi-line comments:
            <pre><code>/* This is a 
multi-line comment */</code></pre>
          </li>
        </ul>
      </li><br />
      <li>
        <strong>Variables and Data Types:</strong> Variables are used to store data. You need to declare a variable with a specific data type:
        <pre><code>{`
int age = 25; // Integer{' '}
double salary = 50000.50; // Double{' '}
char grade = 'A'; // Character
         `} </code></pre>
      </li>
      <li>
        <strong>Operators:</strong> C++ supports various operators for arithmetic, comparison, logical operations, etc. For example:
        <pre><code>{`
int sum = a + b; // Addition{' '}
bool isEqual = (a == b); // Comparison
         `} </code></pre>
      </li>
    </ul><br />

    <h2>3. Control Structures</h2>

    <ul>
      <li>
        <strong>Conditional Statements:</strong> Use <code>if</code>, <code>else if</code>, and <code>else</code> to execute code based on conditions.
        <pre><code>{`
if (age >= 18) {'\n'}
    std::cout << "Adult";{' '}
else {'\n'}
    std::cout << "Minor";
        `}</code></pre>
      </li><br />
      <li>
        <strong>Loops:</strong> Use loops to repeat actions:
        <ul>
          <li>
            <strong>For Loop:</strong>
            <pre><code>{`
for (int i = 0; i < 5; i++) {'\n'}
    std::cout << i;
             `} </code></pre>
          </li><br />
          <li>
            <strong>While Loop:</strong>
            <pre><code>{`
int i = 0;{' '}
while (i < 5) {'\n'}
    std::cout << i;{' '}
    i++;
             `} </code></pre>
          </li>
        </ul>
      </li>
    </ul><br />

    <h2>4. Functions</h2>

    <p>
      Functions are blocks of code that perform specific tasks. They can take parameters and return values:
    </p>
    <pre><code>{`
int add(int a, int b) {'\n'}
{{' '}
    return a + b;{' '}
}
 `} </code></pre><br />

    <h2>5. Input and Output</h2>

    <p>
      C++ uses the standard input/output stream library <code>&lt;iostream&gt;</code> for input and output:
    </p>
    <ul>
      <li>
        <strong>Output:</strong> Use <code>std::cout</code> for console output.
      </li><br />
      <li>
        <strong>Input:</strong> Use <code>std::cin</code> for reading input from the user.
        <pre><code>{`
std::cout << "Enter your age: ";{' '}
int age;{' '}
std::cin >> age;
`}</code></pre>
      </li>
    </ul><br />

    <h2>Conclusion</h2>

    <p>
      The basic syntax and structure of C++ provide a foundation for writing programs. By understanding how to use preprocessor directives, define functions, declare variables, and control program flow, you can create effective and efficient C++ applications. As you become more familiar with these elements, you can start exploring more advanced features and concepts in the language.
    </p>

</div>
)
}



{selectedChapter === 'chapter5' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Compilation and Execution Process</h1>

    <p>
      The compilation and execution process in C++ involves several steps that convert human-readable code into machine-readable instructions. Here’s an overview of the key stages:
    </p><br />

    <h2>1. Writing the Source Code</h2>
    <p>
      C++ source code is written in a text file with a <code>.cpp</code> extension. The code consists of various constructs like functions, classes, and variables.
    </p><br />

    <h2>2. Preprocessing</h2>
    <p>
      Before compilation, the preprocessor handles directives (lines starting with <code>#</code>). This phase includes:
    </p>
    <ul>
      <li>
        <strong>File Inclusion:</strong> For example, <code>#include &lt;iostream&gt;</code> includes the standard input-output library.
      </li><br />
      <li>
        <strong>Macro Definitions:</strong> <code>#define</code> allows defining constants or macros.
      </li><br />
      <li>
        <strong>Conditional Compilation:</strong> Using directives like <code>#ifdef</code> to include/exclude parts of the code based on defined conditions.
      </li><br />
    </ul>
    <p>
      The result of preprocessing is an expanded source code file, which is ready for the compiler.
    </p><br />

    <h2>3. Compilation</h2>
    <p>
      During the compilation phase, the compiler translates the preprocessed source code into an intermediate form, usually an assembly language or object code. Key tasks include:
    </p>
    <ul>
      <li>
        <strong>Syntax Analysis:</strong> The compiler checks the code for syntax errors.
      </li><br />
      <li>
        <strong>Semantic Analysis:</strong> The compiler verifies that the code adheres to the rules of the C++ language.
      </li><br />
      <li>
        <strong>Code Optimization:</strong> The compiler optimizes the code for performance and efficiency.
      </li><br />
      <li>
        <strong>Object File Generation:</strong> The output is an object file with a <code>.o</code> or <code>.obj</code> extension, which contains machine code.
      </li>
    </ul><br />

    <h2>4. Linking</h2>
    <p>
      Linking combines one or more object files into a single executable program. This stage involves:
    </p>
    <ul>
      <li>
        <strong>Static Linking:</strong> Includes all necessary library code directly into the executable.
      </li><br />
      <li>
        <strong>Dynamic Linking:</strong> Links to libraries that are loaded at runtime, which helps in reducing the executable size.
      </li>
    </ul><br />
    <p>
      The linker resolves any external references (like function calls) to ensure that all parts of the program can communicate correctly.
    </p><br />

    <h2>5. Loading</h2>
    <p>
      Once linked, the executable is loaded into memory by the operating system. The loader prepares the program for execution, setting up the necessary memory space.
    </p><br />

    <h2>6. Execution</h2>
    <p>
      The CPU executes the program instructions in the loaded executable. This involves:
    </p><br />
    <ul>
      <li>
        <strong>Running the <code>main()</code> Function:</strong> The program starts executing from the <code>main()</code> function.
      </li><br />
      <li>
        <strong>Performing Input/Output:</strong> The program can interact with the user via console input/output or files.
      </li>
    </ul><br />

    <h2>7. Termination</h2>
    <p>
      Once the program has completed its tasks, it terminates, returning control to the operating system. The return value of the <code>main()</code> function indicates success (usually <code>0</code>) or failure (non-zero).
    </p><br />

    <h2>Conclusion</h2>
    <p>
      Understanding the compilation and execution process in C++ is crucial for debugging, optimization, and effective program development. Each step in this process contributes to transforming high-level code into a functioning application.
    </p>
  </div>
)}


{selectedChapter === 'chapter6' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Data Types</h1>

    <p>
      In C++, data types specify the type of data that a variable can hold. Understanding data types is crucial for efficient memory management and for ensuring that operations are performed correctly. Here’s an overview of the primary data types in C++:
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>1. Basic Data Types</h2>
    <ul>
      <li>
        <strong>int</strong>: Represents integer values. The size is typically 4 bytes, but this can vary based on the system.
        <pre><code>{`int age = 30;`}</code></pre>
      </li><br />
      <li>
        <strong>float</strong>: Represents single-precision floating-point numbers. Generally, it occupies 4 bytes.
        <pre><code>{`float height = 5.9f;`}</code></pre>
      </li><br />
      <li>
        <strong>double</strong>: Represents double-precision floating-point numbers, allowing for greater precision. It usually occupies 8 bytes.
        <pre><code>{`double salary = 75000.50;`}</code></pre>
      </li><br />
      <li>
        <strong>char</strong>: Represents a single character and occupies 1 byte. It can hold any ASCII character.
        <pre><code>{`char grade = 'A';`}</code></pre>
      </li>
    </ul><br />

    <h2 style={{paddingBottom:"6px"}}>2. Derived Data Types</h2>
    <ul>
      <li>
        <strong>Array</strong>: A collection of elements of the same data type, stored in contiguous memory locations.
        <pre><code>{`int numbers[5] = {1, 2, 3, 4, 5};`}</code></pre>
      </li><br />
      <li>
        <strong>Pointer</strong>: Stores the memory address of another variable.
        <pre><code>{`int* ptr = &age;`}</code></pre>
      </li><br />
      <li>
        <strong>Function</strong>: A function can also be considered a data type since it can return a value.
        <pre><code>{`int add(int a, int b) { return a + b; }`}</code></pre>
      </li>
    </ul><br />

    <h2 style={{paddingBottom:"6px"}}>3. User-Defined Data Types</h2>
    <ul>
      <li>
        <strong>Struct</strong>: A structure allows you to combine different data types into a single unit.
        <pre><code>{`struct Person { std::string name; int age; };`}</code></pre>
      </li><br />
      <li>
        <strong>Class</strong>: Similar to a struct but can include access specifiers and methods for encapsulation and data hiding.
        <pre><code>{`class Employee { private: std::string name; double salary; public: void setName(std::string n) { name = n; } };`}</code></pre>
      </li><br />
      <li>
        <strong>Union</strong>: A union allows storing different data types in the same memory location, but only one at a time.
        <pre><code>{`union Data { int intVal; float floatVal; char charVal; };`}</code></pre>
      </li><br />
      <li>
        <strong>Enum</strong>: An enumeration defines a variable that can hold a set of predefined constants.
        <pre><code>{`enum Color { RED, GREEN, BLUE };`}</code></pre>
      </li>
    </ul><br />

    <h2 style={{paddingBottom:"6px"}}>4. Void Type</h2>
    <ul>
      <li>
        <strong>void</strong>: Represents the absence of type. It is commonly used in function return types to indicate that no value is returned.
        <pre><code>{`void display() { std::cout << "Hello, World!"; }`}</code></pre>
      </li>
    </ul><br />

    <h2>Conclusion</h2>
    <p>
      C++ provides a rich set of data types that allow for the creation of efficient and complex programs. By understanding and using these data types effectively, you can optimize memory usage and improve the performance of your applications.
    </p>
  </div>
)}

{selectedChapter === 'chapter7' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Variables and Constants</h1>

    <p>
      In C++, <strong>variables</strong> and <strong>constants</strong> are fundamental concepts used to store and manipulate data. Understanding how they work is essential for effective programming.
    </p><br />

    <h2>Variables</h2>
    <p style={{paddingBottom:"6px"}}>
      A <strong>variable</strong> is a named storage location in memory that can hold a value. The value of a variable can change during the program's execution. Variables must be declared with a specific data type, which determines what kind of data they can hold (e.g., integers, floating-point numbers, characters).
    </p>

    <h3>Declaration and Initialization</h3>
    <p>
      To declare a variable, you specify the data type followed by the variable name. You can also initialize it at the time of declaration:
    </p>
    <pre>
      <code>
        {`int age;               // Declaration\nage = 25;              // Initialization\n\nfloat height = 5.9f;   // Declaration and initialization`}
      </code>
    </pre><br />

    <h3>Scope and Lifetime</h3>
    <p>
      The <strong>scope</strong> of a variable refers to the part of the program where the variable is accessible. Variables declared inside a function are local to that function, while those declared outside are global.
    </p><br />
    <p>
      The <strong>lifetime</strong> of a variable is the duration for which the variable exists in memory. Local variables exist only during the execution of the block in which they are defined.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Constants</h2>
    <p>
      A <strong>constant</strong> is a value that cannot be changed once it has been assigned. Constants are useful for defining fixed values that should not be altered, enhancing code readability and maintainability.
    </p>

    <h3>Defining Constants</h3>
    <p>In C++, you can define constants in several ways:</p>
    <ol>
      <li>
        <strong>Using the <code>const</code> keyword:</strong>
        <pre>
          <code>
            {`const int DAYS_IN_WEEK = 7; // Constant integer\nconst float PI = 3.14f;      // Constant floating-point`}
          </code>
        </pre>
      </li><br />
      <li>
        <strong>Using <code>#define</code> preprocessor directive:</strong>
        <pre>
          <code>
            {`#define MAX_SIZE 100 // Constant using define`}
          </code>
        </pre>
      </li><br />
      <li>
        <strong>Using <code>constexpr</code> (for compile-time constants):</strong>
        <pre>
          <code>
            {`constexpr int MAX_LIMIT = 1000; // Compile-time constant`}
          </code>
        </pre>
      </li>
    </ol><br />

    <h2 style={{paddingBottom:"6px"}}>Summary</h2>
    <p>
      <ul>
        <li><strong>Variables</strong> are mutable data holders whose values can change throughout the program.</li><br />
        <li><strong>Constants</strong> are immutable values that remain fixed after being defined, improving code clarity and reliability.</li>
      </ul>
    </p><br />

    <p>
      Using variables and constants effectively is crucial for writing organized and efficient C++ programs.
    </p>
  </div>
)}


{selectedChapter === 'chapter8' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Input and Output</h1>

    <p>
      In C++, <strong>Input and Output (I/O)</strong> operations are essential for interacting with users and processing data. C++ provides a rich set of libraries and mechanisms for handling I/O, primarily through the Standard Input/Output Stream Library, <code>&lt;iostream&gt;</code>. Here’s a breakdown of how input and output work in C++.
    </p><br />

    <h2>Input</h2>
    <p style={{paddingBottom:"6px"}}>
      <strong>Input</strong> refers to the data received by the program from the user or another source. In C++, input is typically handled using the <code>std::cin</code> object, which is an instance of the input stream class.
    </p>

    <h3>Basic Input Example</h3>
    <p>
      To read data from the user, you can use the extraction operator (<code>&gt;&gt;</code>) with <code>std::cin</code>:
    </p>
    <pre>
      <code>
        {`#include <iostream>

int main() {
    int age;
    std::cout << "Enter your age: "; // Prompt for input
    std::cin >> age;                  // Read input
    std::cout << "You are " << age << " years old." << std::endl; // Output the result
    return 0;
}`}
      </code>
    </pre><br />

    <h3>Input with Multiple Variables</h3>
    <p>
      You can read multiple variables in a single line:
    </p>
    <pre>
      <code>
        {`int a, b;
std::cout << "Enter two integers: ";
std::cin >> a >> b; // Read two integers`}
      </code>
    </pre><br /> 

    <h2>Output</h2>
    <p style={{paddingBottom:"6px"}}>
      <strong>Output</strong> refers to the data sent from the program to the user or another destination. In C++, output is typically handled using the <code>std::cout</code> object, which is an instance of the output stream class.
    </p>

    <h3>Basic Output Example</h3>
    <p>
      To display data, you can use the insertion operator (<code>&lt;&lt;</code>) with <code>std::cout</code>:
    </p>
    <pre>
      <code>
        {`#include <iostream>

int main() {
    std::cout << "Hello, World!" << std::endl; // Print to console
    return 0;
}`}
      </code>
    </pre>

    <h3>Formatting Output</h3>
    <p>
      You can format output in various ways, such as setting precision for floating-point numbers:
    </p>
    <pre>
      <code>
        {`#include <iostream>
#include <iomanip> // for std::setprecision

int main() {
    double pi = 3.14159;
    std::cout << "Value of pi: " << std::setprecision(3) << pi << std::endl; // Outputs "Value of pi: 3.14"
    return 0;
}`}
      </code>
    </pre><br />

    <h2>File I/O</h2>
    <p style={{paddingBottom:"6px"}}>
      In addition to standard console I/O, C++ also supports file input and output through file stream classes (<code>std::ifstream</code> for input and <code>std::ofstream</code> for output).
    </p>

    <h3>File Input Example</h3>
    <p>
      To read data from a file:
    </p>
    <pre>
      <code>
        {`#include <iostream>
#include <fstream>

int main() {
    std::ifstream inputFile("data.txt");
    int number;
    if (inputFile.is_open()) {
        while (inputFile >> number) {
            std::cout << number << std::endl; // Output each number from the file
        }
        inputFile.close();
    } else {
        std::cerr << "Unable to open file." << std::endl;
    }
    return 0;
}`}
      </code>
    </pre><br />

    <h3>File Output Example</h3>
    <p>
      To write data to a file:
    </p>
    <pre>
      <code>
        {`#include <iostream>
#include <fstream>

int main() {
    std::ofstream outputFile("output.txt");
    if (outputFile.is_open()) {
        outputFile << "Hello, File!" << std::endl; // Write to file
        outputFile.close();
    } else {
        std::cerr << "Unable to open file." << std::endl;
    }
    return 0;
}`}
      </code>
    </pre><br />

    <h2>Conclusion</h2>
    <p>
      Understanding input and output in C++ is fundamental for creating interactive programs. The use of <code>std::cin</code> and <code>std::cout</code> for console I/O, along with file stream classes for file operations, allows developers to effectively manage data flow in their applications.
    </p>
  </div>
)}


{selectedChapter === 'chapter9' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Operators</h1>
    
    <p>
      In C++, operators are special symbols that perform operations on variables and values. They can be categorized into several types, including arithmetic, relational, logical, and bitwise operators. Here’s a breakdown of each type:
    </p><br />

    <h2>1. Arithmetic Operators</h2>
    <p>Arithmetic operators are used to perform basic mathematical operations. The primary arithmetic operators in C++ include:</p>
    <ul>
      <li><strong>Addition (`+`)</strong>: Adds two operands.
        <pre><code>int sum = a + b;</code></pre>
      </li><br />
      <li><strong>Subtraction (`-`)</strong>: Subtracts the second operand from the first.
        <pre><code>int difference = a - b;</code></pre>
      </li><br />
      <li><strong>Multiplication (`*`)</strong>: Multiplies two operands.
        <pre><code>int product = a * b;</code></pre>
      </li><br />
      <li><strong>Division (`/`)</strong>: Divides the first operand by the second. If both operands are integers, the result is also an integer (i.e., it truncates the decimal).
        <pre><code>int quotient = a / b;</code></pre>
      </li><br />
      <li><strong>Modulus (`%`)</strong>: Returns the remainder of the division of two integers.
        <pre><code>int remainder = a % b;</code></pre>
      </li><br />
    </ul>

    <h2>2. Relational Operators</h2>
    <p>Relational operators are used to compare two values or expressions. They return a boolean result (true or false). The main relational operators are:</p>
    <ul>
      <li><strong>Equal to (`==`)</strong>: Checks if two operands are equal.
        <pre><code>if (a == b) { /* code */ }</code></pre>
      </li><br />
      <li><strong>Not equal to (`!=`)</strong>: Checks if two operands are not equal.
        <pre><code>if (a != b) { /* code */ }</code></pre>
      </li><br />
      <li><strong>Greater than (`{">"}`)</strong>: Checks if the left operand is greater than the right.
        <pre><code>if (a {">"} b) { /* code */ }</code></pre>
      </li><br />
      <li><strong>Less than (`{"<"}`)</strong>: Checks if the left operand is less than the right.
        <pre><code>if (a {"<"} b) { /* code */ }</code></pre>
      </li><br />
      <li><strong>Greater than or equal to (`{">"}=`)</strong>: Checks if the left operand is greater than or equal to the right.
        <pre><code>if (a {">"}= b) { /* code */ }</code></pre>
      </li><br />
      <li><strong>Less than or equal to (`{"<"}=`)</strong>: Checks if the left operand is less than or equal to the right.
        <pre><code>if (a {"<"}= b) { /* code */ }</code></pre>
      </li>
    </ul><br />

    <h2>3. Logical Operators</h2>
    <p>Logical operators are used to combine multiple boolean expressions. They return true or false based on the logical operation. The primary logical operators are:</p>
    <ul>
      <li><strong>Logical AND (`&&`)</strong>: Returns true if both operands are true.
        <pre><code>if (a {">"} 0 && b{">"} 0) { /* code */ }</code></pre>
      </li><br />
      <li><strong>Logical OR (`||`)</strong>: Returns true if at least one of the operands is true.
        <pre><code>if (a {">"} 0 || b {">"} 0) { /* code */ }</code></pre>
      </li><br />
      <li><strong>Logical NOT (`!`)</strong>: Reverses the boolean value of the operand.
        <pre><code>if (!(a {">"} 0)) { /* code */ }</code></pre>
      </li>
    </ul><br />

    <h2>4. Bitwise Operators</h2>
    <p>Bitwise operators perform operations on the binary representations of integers. The main bitwise operators are:</p>
    <ul>
      <li><strong>Bitwise AND (`&`)</strong>: Compares each bit of two numbers and returns a new number with bits set to 1 where both bits are 1.
        <pre><code>int result = a & b;</code></pre>
      </li><br />
      <li><strong>Bitwise OR (`|`)</strong>: Compares each bit of two numbers and returns a new number with bits set to 1 where at least one of the bits is 1.
        <pre><code>int result = a | b;</code></pre>
      </li><br />
      <li><strong>Bitwise XOR (`^`)</strong>: Compares each bit of two numbers and returns a new number with bits set to 1 where the bits are different.
        <pre><code>int result = a ^ b;</code></pre>
      </li><br />
      <li><strong>Bitwise NOT (`~`)</strong>: Inverts all the bits of the operand.
        <pre><code>int result = ~a;</code></pre>
      </li><br />
      <li><strong>Left Shift {"(`<<`)"}</strong>: Shifts the bits of the left operand to the left by the number of positions specified by the right operand, filling with zeros.
        <pre><code>int result = a {"<<"} 2; // Shifts bits of a left by 2 positions</code></pre>
      </li><br />
      <li><strong>Right Shift {"(`>>`)"}</strong>: Shifts the bits of the left operand to the right by the number of positions specified by the right operand.
        <pre><code>int result = a {">>"} 2; // Shifts bits of a right by 2 positions</code></pre>
      </li>
    </ul><br />

    <h2>Conclusion</h2>
    <p>
      Understanding these operators is crucial for performing calculations, making decisions, and manipulating data in C++. Each type of operator has its own specific use cases, enabling developers to write efficient and effective code.
    </p>
  </div>
)}





{selectedChapter === 'chapter10' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  Conditional Statements </h1>
    <p>
      In C++, <strong>conditional statements</strong> are used to perform different actions based on whether a specified condition evaluates to true or false. They allow for decision-making in programs, enabling the execution of certain blocks of code based on the outcome of logical tests.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Types of Conditional Statements</h2>

    <h3>1. if Statement</h3>
    <p>
      The simplest form of a conditional statement, which executes a block of code if a specified condition is true.
    </p>
    <pre>
      <code>
{`if (condition) {
    // Code to execute if condition is true
}`}
      </code>
    </pre>
    <p><strong>Example:</strong></p>
    <pre>
      <code>
{`int age = 20;
if (age >= 18) {
    std::cout << "You are an adult." << std::endl;
}`}
      </code>
    </pre><br />

    <h3>2. if-else Statement</h3>
    <p>
      Extends the <code>if</code> statement to provide an alternative block of code that executes if the condition is false.
    </p>
    <pre>
      <code>
{`if (condition) {
    // Code to execute if condition is true
} else {
    // Code to execute if condition is false
}`}
      </code>
    </pre>
    <p><strong>Example:</strong></p>
    <pre>
      <code>
{`int age = 16;
if (age >= 18) {
    std::cout << "You are an adult." << std::endl;
} else {
    std::cout << "You are a minor." << std::endl;
}`}
      </code>
    </pre><br />

    <h3>3. else if Statement</h3>
    <p>
      Allows for multiple conditions to be tested in sequence. If the first condition is false, the program checks the next condition, and so on.
    </p>
    <pre>
      <code>
{`if (condition1) {
    // Code for condition1
} else if (condition2) {
    // Code for condition2
} else {
    // Code if none of the conditions are true
}`}
      </code>
    </pre>
    <p><strong>Example:</strong></p>
    <pre>
      <code>
{`int score = 85;
if (score >= 90) {
    std::cout << "Grade: A" << std::endl;
} else if (score >= 80) {
    std::cout << "Grade: B" << std::endl;
} else {
    std::cout << "Grade: C" << std::endl;
}`}
      </code>
    </pre><br />

    <h3>4. Switch Statement</h3>
    <p>
      A multi-way branch statement that allows a variable to be tested for equality against a list of values (cases). It is useful for handling multiple discrete values.
    </p>
    <pre>
      <code>
{`switch (variable) {
    case value1:
        // Code for value1
        break;
    case value2:
        // Code for value2
        break;
    default:
        // Code if none of the cases match
}`}
      </code>
    </pre>
    <p><strong>Example:</strong></p>
    <pre>
      <code>
{`char grade = 'B';
switch (grade) {
    case 'A':
        std::cout << "Excellent!" << std::endl;
        break;
    case 'B':
        std::cout << "Good job!" << std::endl;
        break;
    case 'C':
        std::cout << "You passed." << std::endl;
        break;
    default:
        std::cout << "Invalid grade." << std::endl;
}`}
      </code>
    </pre><br />

    <h2>Summary</h2>
    <p>
      Conditional statements in C++ are essential for controlling the flow of execution in a program. They allow developers to write more flexible and responsive code by making decisions based on variable states and conditions. By using <code>if</code>, <code>else if</code>, <code>else</code>, and <code>switch</code> statements, programmers can implement complex logic and handle various scenarios effectively.
    </p>

  </div>
)}


{selectedChapter === 'chapter11' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Loops (for, while, do-while)    </h1>
   

    <p>
      In C++, <strong>loops</strong> are fundamental constructs that allow for repeated execution of a block of code as long as a specified condition is true. 
      There are three primary types of loops: <code>for</code>, <code>while</code>, and <code>do-while</code>.
    </p><br />

    <h2>1. <strong>for Loop</strong></h2>
    <p>
      The <code>for</code> loop is typically used when the number of iterations is known beforehand. It consists of three parts: initialization, condition, and increment/decrement.
    </p>
    <pre>
      <code>
        {`for (initialization; condition; increment/decrement) {\n    // Code to execute in each iteration\n}`}
      </code>
    </pre>
    <p><strong>Example:</strong></p>
    <pre>
      <code>
        {`for (int i = 0; i < 5; i++) {\n    std::cout << "Iteration: " << i << std::endl;\n}`}
      </code>
    </pre>
    <p>In this example, the loop will print the iteration number from 0 to 4.</p><br />

    <h2>2. <strong>while Loop</strong></h2>
    <p>
      The <code>while</code> loop continues executing as long as the specified condition is true. It checks the condition before each iteration, so it may not execute at all if the condition is initially false.
    </p>
    <pre>
      <code>
        {`while (condition) {\n    // Code to execute while condition is true\n}`}
      </code>
    </pre>
    <p><strong>Example:</strong></p>
    <pre>
      <code>
        {`int count = 0;\nwhile (count < 5) {\n    std::cout << "Count: " << count << std::endl;\n    count++; // Increment count\n}`}
      </code>
    </pre>
    <p>Here, the loop will print the count from 0 to 4. The condition is checked before each iteration.</p><br />

    <h2>3. <strong>do-while Loop</strong></h2>
    <p>
      The <code>do-while</code> loop is similar to the <code>while</code> loop, but it guarantees that the block of code is executed at least once. The condition is checked after the execution of the code block.
    </p>
    <pre>
      <code>
        {`do {\n    // Code to execute\n} while (condition);`}
      </code>
    </pre>
    <p><strong>Example:</strong></p>
    <pre>
      <code>
        {`int number = 0;\ndo {\n    std::cout << "Number: " << number << std::endl;\n    number++; // Increment number\n} while (number < 5);`}
      </code>
    </pre>
    <p>In this case, the loop will print the number from 0 to 4. Since the condition is checked after the execution, the loop will execute at least once.</p>
<br />
    <h2 style={{paddingBottom:"6px"}}>Summary</h2>
    <ul>
      <li><strong>for Loop</strong>: Best used when the number of iterations is known in advance.</li><br />
      <li><strong>while Loop</strong>: Executes as long as the condition is true; may not execute at all if the condition is false.</li><br />
      <li><strong>do-while Loop</strong>: Executes at least once before checking the condition; useful when the initial execution is necessary.</li>
    </ul><br />
    <p>
      Loops are essential for tasks like iterating through arrays, processing data, and repeating operations until a condition is met. 
      Understanding these loop constructs allows developers to write efficient and flexible C++ programs.
    </p>
  </div>
)}

 





{selectedChapter === 'chapter12' && (
<div className={style.chaptercontent}>
  <h1 className={style.heading} >  Break and Continue   </h1>


    <p>
      In C++, <code>break</code> and <code>continue</code> are control flow statements used to manage the flow of loops. They help alter the behavior of loop execution based on certain conditions.
    </p><br />

    <h2>1. <code>break</code> Statement</h2>

    <p style={{paddingBottom:"6px"}}>
      The <code>break</code> statement is used to terminate the nearest enclosing loop or switch statement immediately. When <code>break</code> is encountered, the program control jumps to the next statement following the loop or switch.
    </p>

    <h3>Example of <code>break</code>:</h3>

    <pre>
      <code>
      #include &lt;iostream.h&gt;<br />
      #include &lt;conio.h&gt;<br />

int main() &#123;<br />
    for (int i = 0; i &lt; 10; i++) &#123; <br />
        if (i == 5) &#123; <br />
            break; // Exit the loop when i equals 5 <br />
        &#125;<br />
        std::cout &lt;&lt; i &lt;&lt; std::endl;<br />
    &#125;<br />
    return 0;<br />
&#125;<br />
      </code>
    </pre>

    <p><strong>Output:</strong></p>
    <pre>
      <code>
0
1
2
3
4
      </code>
    </pre>

    <p>
      In this example, the loop will terminate when <code>i</code> reaches 5, so only numbers 0 through 4 are printed.
    </p><br />

    <h2>2. <code>continue</code> Statement</h2>

    <p style={{paddingBottom:"6px"}}>
      The <code>continue</code> statement skips the current iteration of the nearest enclosing loop and proceeds to the next iteration. When <code>continue</code> is executed, the remaining code in the loop for that iteration is skipped, and control jumps to the loop's condition check.
    </p>

    <h3>Example of <code>continue</code>:</h3>

    <pre>
      <code>
#include &lt;iostream.h&gt;<br />
#include &lt;conio.h&gt;<br />

int main() &#123;<br />
    for (int i = 0; i &lt; 10; i++) &#123;<br />
        if (i % 2 == 0) &#123;<br />
            continue; // Skip even numbers <br />

        &#125;<br />
        std::cout &lt;&lt; i &lt;&lt; std::endl; // This will print only odd numbers <br />
    &#125;<br />
    return 0;<br />
&#125;<br />
      </code>
    </pre>

    <p><strong>Output:</strong></p>
    <pre>
      <code>
1
3
5
7
9
      </code>
    </pre>

    <p>
      In this example, the <code>continue</code> statement causes the loop to skip the printing of even numbers, resulting in only the odd numbers being printed.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Summary</h2>
    <ul>
      <li><code>break</code>: Terminates the loop or switch statement and transfers control to the next statement outside of the loop.</li><br />
      <li><code>continue</code>: Skips the current iteration of the loop and moves control back to the loop's condition check for the next iteration.</li>
    </ul><br />

    <p>
      These statements are useful for controlling loop execution and can lead to cleaner and more efficient code in specific scenarios.
    </p>
  </div>
)}

          





{selectedChapter === 'chapter13' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Function Declaration and Definition</h1>

    <p>
      In C++, functions are fundamental building blocks that allow you to encapsulate a set of instructions that can be reused throughout your program. Understanding function declaration and definition is crucial for effective programming.
    </p><br />

    <h2>Function Declaration</h2>
    <p style={{paddingBottom:"6px"}}>
      A <strong>function declaration</strong> (also known as a function prototype) introduces the function's name, its return type, and its parameters (if any) to the compiler. It does not provide the actual implementation of the function. The declaration informs the compiler about the function's existence so that it can be used before its definition appears in the code.
    </p>

    <h3>Syntax:</h3>
    <pre>
      <code>
        returnType functionName(parameterType1 parameterName1, parameterType2 parameterName2, ...);
      </code>
    </pre><br />

    <h3>Example:</h3>
    <pre>
      <code>
        int add(int a, int b); // Function declaration
      </code>
    </pre>
    <p>
      In this example, <code>add</code> is declared as a function that takes two integer parameters and returns an integer.
    </p><br />

    <h2>Function Definition</h2>
    <p style={{paddingBottom:"6px"}}>
      A <strong>function definition</strong> provides the actual body of the function, including the code that will be executed when the function is called. The definition specifies what the function does and must match the declaration.
    </p>

    <h3>Syntax:</h3>
    <pre>
      <code>
        returnType functionName(parameterType1 parameterName1, parameterType2 parameterName2, ...) {'{' }
          // Function body
          // Code to execute
        {'}'}
      </code>
    </pre><br />

    <h3>Example:</h3>
    <pre>
      <code>
        int add(int a, int b) {'{' }
            return a + b; // Function definition
        {'}'}
      </code>
    </pre>
    <p>
      In this example, the <code>add</code> function is defined, which returns the sum of two integers.
    </p><br />

    <h2>Putting It All Together</h2>
    <p>Here’s a complete example showing both declaration and definition:</p>

    <pre>
      <code>{`
        #include &lt;iostream&gt;

        // Function declaration
        int add(int a, int b);

        int main() {'{' }
            int result = add(5, 3); // Function call
            std::cout << "The sum is: " << result << std::endl; 
            return 0;
        {'}'}

        // Function definition
        int add(int a, int b) {'{' }
            return a + b; 
        {'}'}
      `}</code>
    </pre><br />

    <h2 style={{paddingBottom:"6px"}}>Summary</h2>
    <ul>
      <li>
        <strong>Function Declaration</strong>: Introduces the function to the compiler, specifying its name, return type, and parameters. It does not include the function body.
      </li><br />
      <li>
        <strong>Function Definition</strong>: Provides the actual implementation of the function, including the code that will be executed when the function is called.
      </li>
    </ul><br />

    <p>
      Understanding these concepts allows you to organize your code better and improve its modularity and readability.
    </p>
  </div>
)}






{selectedChapter === 'chapter14' && (
<div className={style.chaptercontent}>
  <h1 className={style.heading} > Function Overloading </h1>

    <p>
      Function overloading in C++ allows multiple functions to have the same name but different parameter lists. This enables the same function name to be used for different types or numbers of parameters, enhancing code readability and flexibility.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Key Points about Function Overloading:</h2>
    <ol>
      <li>
        <strong>Same Function Name:</strong> Functions can share the same name as long as their signatures (the number and type of parameters) differ.
      </li><br />
      <li>
        <strong>Different Parameters:</strong> The compiler determines which function to call based on the arguments passed during the function call. This can involve:
        <ul>
          <li>Different types of parameters (e.g., <code>int</code>, <code>float</code>, <code>double</code>).</li><br />
          <li>Different numbers of parameters.</li>
        </ul>
      </li><br />
      <li>
        <strong>Return Type:</strong> The return type alone cannot be used to distinguish overloaded functions. The parameter list must differ.
      </li>
    </ol><br/>

    <h2>Example of Function Overloading:</h2>
    <pre>
      <code>{`
        #include &lt;iostream&gt;

        class Calculator {
        public:
            // Function to add two integers
            int add(int a, int b) {
                return a + b;
            }

            // Function to add two floating-point numbers
            double add(double a, double b) {
                return a + b;
            }

            // Function to add three integers
            int add(int a, int b, int c) {
                return a + b + c;
            }
        };
      

        int main() {
            Calculator calc;

            std::cout &lt;&lt; "Sum of two integers: " &lt;&lt; calc.add(5, 3) &lt;&lt; std::endl;      
            std::cout &lt;&lt; "Sum of two doubles: " &lt;&lt; calc.add(2.5, 3.5) &lt;&lt; std::endl;   
            std::cout &lt;&lt; "Sum of three integers: " &lt;&lt; calc.add(1, 2, 3) &lt;&lt; std::endl; 

            return 0;
        }
   `}</code>
    </pre><br />


    <h2>Output:</h2>
    <pre>
      <code>{`
        Sum of two integers: 8
        Sum of two doubles: 6
        Sum of three integers: 6
      `}</code>
    </pre><br />

    <h2>Summary:</h2>
    <p>
      Function overloading is a powerful feature in C++ that improves code clarity and usability by allowing functions with the same name to operate on different data types or numbers of arguments. This leads to more intuitive and maintainable code.
    </p>
  </div>
)}



  {selectedChapter === 'chapter15' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Default Arguments </h1>

    <p>
      In C++, <strong>default arguments</strong> allow you to specify default values for function parameters. This means that if a caller does not provide a value for those parameters when calling the function, the default value will be used instead. Default arguments enhance code flexibility and readability by reducing the number of function overloads needed.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Key Points About Default Arguments:</h2>
    <ol>
      <li>
        <strong>Declaration:</strong> Default arguments are specified in the function declaration. If a function is defined with default arguments, they should appear in the declaration but not necessarily in the definition.
      </li><br />
      <li>
        <strong>Positioning:</strong> Default arguments must be specified from right to left. This means if you have multiple parameters, all parameters to the right of a parameter with a default value must also have default values.
      </li><br />
      <li>
        <strong>Overriding Defaults:</strong> When a function with default arguments is called, you can override the default values by passing your own arguments.
      </li>
    </ol><br />

    <h2>Example:</h2>
    <pre>
      <code>
{`#include <iostream>

// Function declaration with default arguments
void display(int a, int b = 5, int c = 10) {
    std::cout << "a: " << a << ", b: " << b << ", c: " << c << std::endl;
}

int main() {
    display(1);              // Calls display(1, 5, 10)
    display(2, 3);          // Calls display(2, 3, 10)
    display(4, 5, 6);       // Calls display(4, 5, 6)

    return 0;
}`}
      </code>
    </pre><br />

    <h2>Output:</h2>
    <pre>
      <code>
{`a: 1, b: 5, c: 10
a: 2, b: 3, c: 10
a: 4, b: 5, c: 6`}
      </code>
    </pre><br />

    <h2 style={{paddingBottom:"6px"}}>Summary:</h2>
    <ul>
      <li><strong>Default arguments</strong> provide a way to specify default values for function parameters, enhancing code flexibility.</li><br />
      <li>They help reduce the need for multiple overloaded functions.</li><br />
      <li>Default arguments must be specified from right to left in the parameter list.</li>
    </ul><br />

    <p>
      Understanding and using default arguments can make your functions more versatile and easier to use.
    </p>
  </div>
)}
   
  


  {selectedChapter === 'chapter16' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Inline Functions</h1>

    <p>
      In C++, <strong>inline functions</strong> are a feature that allows the compiler to replace a function call with the actual code of the function. This can potentially reduce the overhead of function calls, especially for small, frequently called functions.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Key Points About Inline Functions:</h2>

    <ol>
      <li>
        <strong>Definition</strong>: An inline function is defined using the <code>inline</code> keyword before the function's return type. This suggests to the compiler that it should attempt to insert the function's code directly into the calling location.
      </li><br />
      <li>
        <strong>Usage</strong>: Inline functions are typically used for small, simple functions where the performance gain from avoiding a function call outweighs the increase in code size.
      </li><br />
      <li>
        <strong>Performance</strong>: By replacing the function call with the function code, inline functions can improve performance, especially in scenarios involving tight loops or recursive calls.
      </li><br />
      <li>
        <strong>Limitations</strong>:
        <ul>
          <li>The compiler may ignore the <code>inline</code> request and treat the function as a regular function, especially if the function is too complex or if it cannot be resolved at compile time.</li><br />
          <li>Inline functions should be defined in header files if they are to be used in multiple translation units, as their definitions must be visible at each point of use.</li>
        </ul>
      </li>
    </ol><br />

    <h2>Example of Inline Function:</h2>
    <pre>
      <code>{`
        #include &lt;iostream&gt;

        // Inline function definition
        inline int square(int x) {
            return x * x;
        }

        int main() {
            std::cout &lt;&lt; "Square of 5: " &lt;&lt; square(5) &lt;&lt; std::endl; // Outputs: 25
            std::cout &lt;&lt; "Square of 10: " &lt;&lt; square(10) &lt;&lt; std::endl; // Outputs: 100
            return 0;
        }
      `}</code>
    </pre><br />

    <h2>Summary:</h2>
    <p>
      Inline functions provide a mechanism to reduce function call overhead and can lead to more efficient code. However, they should be used judiciously, keeping in mind their limitations and the potential increase in code size due to code duplication.
    </p>

  
  </div>
)}



  {selectedChapter === 'chapter17' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Recursion</h1>

    <p>
      In C++, <strong>recursion</strong> is a programming technique where a function calls itself to solve a problem. This approach is often used to break down complex problems into simpler subproblems. A recursive function typically has two main components:
    </p><br />

    <ol>
      <li>
        <strong>Base Case</strong>: This is the condition under which the function stops calling itself, preventing infinite recursion and leading to a solution.
      </li><br />
      <li>
        <strong>Recursive Case</strong>: This is where the function calls itself with modified arguments, progressively moving towards the base case.
      </li>
    </ol><br />

    <h2 style={{paddingBottom:"6px"}}>Key Characteristics of Recursion:</h2>
    <ul>
      <li><strong>Simplicity</strong>: Recursive solutions can often be more straightforward and easier to understand compared to their iterative counterparts.</li><br />
      <li><strong>Stack Memory</strong>: Each recursive call adds a new layer to the call stack, which consumes memory. Excessive recursion can lead to a stack overflow if the base case is not reached.</li><br />
      <li><strong>Performance</strong>: Recursive functions can sometimes be less efficient than iterative solutions due to the overhead of multiple function calls and the potential for redundant calculations (though techniques like memoization can mitigate this).</li>
    </ul><br />

    <h2>Example of Recursion:</h2>
    <p>Here's a simple example of a recursive function to calculate the factorial of a number:</p>

    <pre>
      <code>
{`#include <iostream>

// Recursive function to calculate factorial
int factorial(int n) {
    // Base case
    if (n <= 1) {
        return 1;
    }
    // Recursive case
    return n * factorial(n - 1);
}

int main() {
    int number = 5;
    std::cout << "Factorial of " << number << " is: " << factorial(number) << std::endl; // Outputs: 120
    return 0;
}`}
      </code>
    </pre><br />

    <h2>Summary:</h2>
    <p>
      Recursion is a powerful technique in C++ that allows functions to be defined in terms of themselves, enabling elegant solutions for problems that can be broken down into smaller, similar problems. Understanding how to effectively implement recursion, including defining base and recursive cases, is essential for leveraging this programming concept.
    </p>

  </div>
)}

{selectedChapter === 'chapter18' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Arrays (One-dimensional, Multi-dimensional)</h1>

    <p>
      In C++, <strong>arrays</strong> are collections of elements of the same type, stored in contiguous memory locations. They are used to group related data, making it easier to manage and manipulate.
    </p><br />

    <h2>One-Dimensional Arrays</h2>
    
    <p style={{paddingBottom:"6px"}}>
      A <strong>one-dimensional array</strong> is the simplest form of an array, functioning as a list of elements, where each element can be accessed using a single index.
    </p>

    <h3>Declaration and Initialization:</h3>
    <p>You can declare a one-dimensional array by specifying its data type and size:</p>
    
    <pre>
      <code>
        {`dataType arrayName[arraySize]; // Declaration\n\n// Initialization\ndataType arrayName[arraySize] = {value1, value2, ..., valueN};`}
      </code>
    </pre><br />

    <h3>Example:</h3>
    <pre>
      <code>
        {`#include <iostream>\n\nint main() {\n    // Declaration and initialization of a one-dimensional array\n    int numbers[5] = {10, 20, 30, 40, 50};\n\n    // Accessing and printing array elements\n    for (int i = 0; i < 5; i++) {\n        std::cout << "Element at index " << i << ": " << numbers[i] << std::endl;\n    }\n\n    return 0;\n}`}
      </code>
    </pre><br />

    <h2>Multi-Dimensional Arrays</h2>

    <p style={{paddingBottom:"6px"}} >
      A <strong>multi-dimensional array</strong> is an array that contains more than one dimension. The most commonly used multi-dimensional array is a two-dimensional array, which can be visualized as a table or grid of values.
    </p>

    <h3>Declaration and Initialization:</h3>
    <p>You can declare a two-dimensional array by specifying its data type and size for each dimension:</p>

    <pre>
      <code>
        {`dataType arrayName[arraySize1][arraySize2]; // Declaration\n\n// Initialization\ndataType arrayName[arraySize1][arraySize2] = {\n    {value1, value2, ...},\n    {value3, value4, ...},\n    ...\n};`}
      </code>
    </pre><br />

    <h3>Example:</h3>
    <pre>
      <code>
        {`#include <iostream>\n\nint main() {\n   // Declaration and initialization of a two-dimensional array\n    int matrix[2][3] = {\n        {1, 2, 3},\n        {4, 5, 6}\n    };\n\n    // Accessing and printing array elements\n    for (int i = 0; i < 2; i++) {\n        for (int j = 0; j < 3; j++) {\n            std::cout << "Element at [" << i << "][" << j << "]: " << matrix[i][j] << std::endl;\n        }\n    }\n\n    return 0;\n}`}
      </code>
    </pre><br />

    <h2 style={{paddingBottom:"6px"}}>Summary</h2>

    <ul>
      <li><strong>One-Dimensional Arrays</strong>: Simple lists of elements accessed using a single index, ideal for storing a linear collection of related data.</li><br />
      <li><strong>Multi-Dimensional Arrays</strong>: Arrays with two or more dimensions, useful for representing tabular data or grids, accessed using multiple indices.</li>
    </ul><br />

    <p>
      Understanding how to declare, initialize, and manipulate both one-dimensional and multi-dimensional arrays is fundamental in C++ programming.
    </p>
  </div>
)}


  {selectedChapter === 'chapter19' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>String (C-Style string and C++ string class)</h1>

    <p>
      In C++, strings can be represented in two primary ways: as C-style strings (character arrays) and as C++ strings (using the <code>std::string</code> class). Each method has its own characteristics and use cases.
    </p><br />

    <h2>C-Style Strings</h2>
    <p>
      A <strong>C-style string</strong> is essentially an array of characters terminated by a null character (<code>'\\0'</code>). This representation is rooted in the C programming language and provides a low-level approach to string manipulation.
    </p><br />

    <h3 style={{paddingBottom:"6px"}}>Key Characteristics:</h3>
    <ol>
      <li><strong>Null-Termination</strong>: A C-style string ends with a null character, which indicates the end of the string.</li><br />
      <li><strong>Fixed Size</strong>: The size of the character array must be defined at the time of declaration. You cannot change the size of a C-style string once it is declared.</li><br />
      <li><strong>Manipulation</strong>: Functions from the <code>&lt;cstring&gt;</code> library (like <code>strcpy</code>, <code>strlen</code>, etc.) are used for string manipulation, requiring careful handling to avoid buffer overflows.</li>
    </ol><br />

    <h3>Example of C-Style String:</h3>
    <pre>
      <code>
{`#include <iostream>
#include <cstring>

int main() {
    // Declaration and initialization of a C-style string
    char greeting[20] = "Hello, World!";
    
    // Accessing and printing the C-style string
    std::cout << "C-Style String: " << greeting << std::endl;
    std::cout << "Length: " << strlen(greeting) << std::endl; // Using strlen from <cstring>

    return 0;
}`}
      </code>
    </pre><br />

    <h2>C++ String Class</h2>
    <p>
      The <strong>C++ string class</strong> (<code>std::string</code>) is part of the C++ Standard Library and provides a more flexible and powerful way to handle strings compared to C-style strings.
    </p><br />

    <h3 style={{paddingBottom:"6px"}}>Key Characteristics:</h3>
    <ol>
      <li><strong>Dynamic Size</strong>: The size of a <code>std::string</code> can change dynamically, allowing for easier manipulation (addition, deletion, etc.) of characters.</li><br />
      <li><strong>Ease of Use</strong>: The <code>std::string</code> class offers various member functions (like <code>length()</code>, <code>append()</code>, <code>substr()</code>, etc.) for string manipulation, making it more user-friendly.</li><br />
      <li><strong>Automatic Memory Management</strong>: The class manages memory automatically, reducing the risk of memory-related issues.</li>
    </ol><br />

    <h3>Example of C++ String:</h3>
    <pre>
      <code>
{`#include <iostream>
#include <string>

int main() {
    // Declaration and initialization of a C++ string
    std::string greeting = "Hello, World!";
    
    // Accessing and printing the C++ string
    std::cout << "C++ String: " << greeting << std::endl;
    std::cout << "Length: " << greeting.length() << std::endl; // Using length() member function

    return 0;
}`}
      </code>
    </pre><br />

    <h2 style={{paddingBottom:"6px"}}>Summary</h2>
    <ul>
      <li><strong>C-Style Strings</strong>: Low-level character arrays ending with a null character. They require manual memory management and string manipulation using functions from <code>&lt;cstring&gt;</code>.</li><br />
      <li><strong>C++ String Class</strong>: A high-level class that simplifies string manipulation, allows dynamic sizing, and manages memory automatically. It is generally preferred in C++ programming due to its ease of use and safety.</li>
    </ul><br />

    <p>
      Understanding both representations is important, especially when dealing with legacy C code or interfacing with C libraries.
    </p>


  </div>

)}



{selectedChapter === 'chapter20' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>String Manipulation Functions in C++</h1>

    <p>
      In C++, string manipulation functions are essential for performing various operations on strings, whether they are C-style strings (character arrays) or C++ strings (using the <code>std::string</code> class). Below is an overview of common string manipulation functions for both types.
    </p><br />

    <h2>C-Style String Functions</h2>
    <p>
      C-style strings are manipulated using functions from the <code>&lt;cstring&gt;</code> header. Here are some key functions:
    </p><br />

    <ol>
      <li>
        <strong><code>strlen(const char* str)</code></strong>: 
        <p>Returns the length of the string (number of characters before the null terminator).</p>
        <pre><code> 
#include &lt;cstring&gt;
int length = strlen("Hello"); // length is 5
        </code></pre>
      </li><br />

      <li>
        <strong><code>strcpy(char* dest, const char* src)</code></strong>: 
        <p>Copies the source string (src) to the destination string (dest).</p>
        <pre><code> 
char dest[20];
strcpy(dest, "Hello"); // dest now contains "Hello"
        </code></pre>
      </li><br />

      <li>
        <strong><code>strcat(char* dest, const char* src)</code></strong>: 
        <p>Appends the source string (src) to the end of the destination string (dest).</p>
        <pre><code> 
char dest[20] = "Hello";
strcat(dest, " World"); // dest now contains "Hello World"
        </code></pre>
      </li><br />

      <li>
        <strong><code>strcmp(const char* str1, const char* str2)</code></strong>: 
        <p>Compares two strings. Returns 0 if they are equal, a positive value if str1 {">"} str2, and a negative value if str1 {"<"} str2.</p>
        <pre><code> 
int result = strcmp("abc", "xyz"); // result is negative
        </code></pre>
      </li><br />

      <li>
        <strong><code>strchr(const char* str, int character)</code></strong>: 
        <p>Searches for the first occurrence of a character in a string. Returns a pointer to the character in the string or <code>nullptr</code> if not found.</p>
        <pre><code> 
char* p = strchr("Hello", 'e'); // p points to the 'e' in "Hello"
        </code></pre>
      </li>
    </ol><br />

    <h2>C++ String Class Functions</h2>
    <p>
      The <code>std::string</code> class provides a rich set of member functions for string manipulation. Here are some common ones:
    </p><br />

    <ol>
      <li>
        <strong><code>.length()</code> / <code>.size()</code></strong>: 
        <p>Returns the number of characters in the string.</p>
        <pre><code> 
std::string str = "Hello";
size_t len = str.length(); // len is 5
        </code></pre>
      </li><br />

      <li>
        <strong><code>.append(const std::string& str)</code></strong>: 
        <p>Appends the given string to the end of the current string.</p>
        <pre><code> 
str.append(" World"); // str now contains "Hello World"
        </code></pre>
      </li><br />

      <li>
        <strong><code>.substr(size_t pos, size_t len)</code></strong>: 
        <p>Returns a substring starting at position <code>pos</code> with a length of <code>len</code>.</p>
        <pre><code> 
std::string sub = str.substr(0, 5); // sub contains "Hello"
        </code></pre>
      </li><br />

      <li>
        <strong><code>.find(const std::string& str)</code></strong>: 
        <p>Searches for the first occurrence of the substring <code>str</code> and returns its position or <code>std::string::npos</code> if not found.</p>
        <pre><code> 
size_t pos = str.find("World"); // pos is 5
        </code></pre>
      </li><br />

      <li>
        <strong><code>.replace(size_t pos, size_t len, const std::string& str)</code></strong>: 
        <p>Replaces part of the string starting at <code>pos</code> with the given string <code>str</code>.</p>
        <pre><code> 
str.replace(6, 5, "C++"); // str now contains "Hello C++"
        </code></pre>
      </li><br />

      <li>
        <strong><code>.clear()</code></strong>: 
        <p>Clears the contents of the string.</p>
        <pre><code> 
str.clear(); // str is now empty
        </code></pre>
      </li><br />

      <li>
        <strong><code>.c_str()</code></strong>: 
        <p>Returns a pointer to a null-terminated character array representing the string's content.</p>
        <pre><code> 
const char* cstr = str.c_str(); // Get C-style string
        </code></pre>
      </li>
    </ol><br />

    <h2>Summary</h2>
    <p>
      Understanding these string manipulation functions is crucial for effective string handling in C++. C-style strings require careful management of memory and termination, while the <code>std::string</code> class offers a more user-friendly and safe approach to string manipulation. Each method serves different needs depending on the context of the application.
    </p>
  </div>
)}





  {selectedChapter === 'chapter21' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Introduction to Pointers in C++</h1>

    <p>
      In C++, a <strong>pointer</strong> is a variable that stores the memory address of another variable. Pointers are a powerful feature of C++ that allow for dynamic memory management, efficient array handling, and the implementation of data structures like linked lists and trees.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Key Concepts of Pointers:</h2>

    <ol>
      <li>
        <strong>Memory Address</strong>: Every variable in C++ is stored at a specific location in memory, which can be accessed via its memory address.
      </li><br />
      <li>
        <strong>Pointer Declaration</strong>: A pointer is declared by specifying the data type it points to, followed by an asterisk (`*`). For example:
        <pre><code>{`int* ptr; // ptr is a pointer to an integer`}</code></pre>
      </li><br />
      <li>
        <strong>Pointer Initialization</strong>: You can initialize a pointer to the address of a variable using the address-of operator (`&`):
        <pre><code>{`int var = 10;\nint* ptr = &var; // ptr now holds the address of var`}</code></pre>
      </li><br />
      <li>
        <strong>Dereferencing</strong>: To access or modify the value stored at the memory address a pointer points to, you use the dereference operator (`*`):
        <pre><code>{`int value = *ptr; // Retrieves the value at the address stored in ptr\n*ptr = 20; // Changes the value of var to 20`}</code></pre>
      </li><br />
      <li>
        <strong>Dynamic Memory Allocation</strong>: Pointers are essential for dynamic memory management using the `new` and `delete` operators:
        <pre><code>{`int* dynamicVar = new int; // Allocates memory for an integer\n*dynamicVar = 30; // Assigns a value\ndelete dynamicVar; // Frees the allocated memory`}</code></pre>
      </li><br />
      <li>
        <strong>Null Pointer</strong>: A pointer that is not assigned to any valid memory address is called a null pointer. It is often initialized using `nullptr`:
        <pre><code>{`int* ptr = nullptr; // ptr is a null pointer`}</code></pre>
      </li>
    </ol><br />

    <h2 style={{paddingBottom:"6px"}}>Advantages of Pointers:</h2>
    <ul>
      <li><strong>Efficiency</strong>: Pointers allow direct access to memory and can make certain operations more efficient.</li><br />
      <li><strong>Dynamic Memory Management</strong>: They enable the creation of dynamic data structures and allow for memory to be allocated and deallocated as needed.</li><br />
      <li><strong>Array and String Manipulation</strong>: Pointers provide a flexible way to work with arrays and strings.</li>
    </ul><br />

    <h2>Summary</h2>
    <p>
      Understanding pointers is crucial for mastering C++ programming. They provide powerful capabilities for managing memory and data structures, but they also require careful handling to avoid issues such as memory leaks and dangling pointers.
    </p>
  </div>
)}





{selectedChapter === 'chapter22' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Pointer Arithmetic</h1>

    <p>
      Pointer arithmetic refers to the operations that can be performed on pointers to navigate through the memory addresses of an array or a block of dynamically allocated memory. This capability allows you to manipulate pointers in a way that can facilitate operations like traversing arrays or modifying elements.
    </p><br />

    <h3 style={{paddingBottom:"6px"}}>Key Concepts of Pointer Arithmetic:</h3>

    <ol>
      <li>
        <strong>Incrementing and Decrementing Pointers:</strong>
        <p>
          When you increment a pointer (using <code>++</code>), it moves to the next memory address of the type it points to. For example, if you have a pointer to an integer and you increment it, it will move by <code>sizeof(int)</code> bytes. Similarly, decrementing a pointer (using <code>--</code>) moves it back by the size of the data type it points to.
        </p>
        <pre>
          <code>
{`int arr[] = {10, 20, 30};
int* ptr = arr;  // ptr points to the first element (10)
ptr++;           // ptr now points to the second element (20)`}
          </code>
        </pre>
      </li><br />

      <li>
        <strong>Pointer Addition and Subtraction:</strong>
        <p>
          You can add an integer to a pointer to move it forward by that many elements. For example, <code>ptr + 2</code> moves the pointer two elements forward. Subtracting a pointer from another pointer of the same type yields the number of elements between them.
        </p>
        <pre>
          <code>
{`int* ptr2 = arr + 2; // ptr2 points to the third element (30)
int distance = ptr2 - ptr; // distance is 2`}
          </code>
        </pre>
      </li><br />

      <li>
        <strong>Pointer Comparison:</strong>
        <p>
          Pointers can be compared using relational operators (<code>&lt;</code>, <code>&gt;</code>, <code>==</code>, etc.). This is often used to check if a pointer is within the bounds of an array or to find the relative positions of two pointers.
        </p>
        <pre>
          <code>
{`if (ptr < ptr2) {
    // ptr points to an earlier element than ptr2
}`}
          </code>
        </pre>
      </li><br />

      <li>
        <strong>Usage in Arrays:</strong>
        <p>
          Pointer arithmetic is commonly used to traverse arrays. By incrementing the pointer, you can access each element of the array sequentially.
        </p>
        <pre>
          <code>
{`for (int* p = arr; p < arr + 3; p++) {
    std::cout << *p << " "; // Outputs: 10 20 30
}`}
          </code>
        </pre>
      </li><br />

      <li>
        <strong>Caveats:</strong>
        <p>
          While pointer arithmetic is powerful, it can lead to undefined behavior if you go out of bounds of the allocated memory. Always ensure that pointers stay within the valid range.
        </p>
      </li>
    </ol><br />

    <h3>Summary</h3>
    <p>
      Pointer arithmetic is a powerful feature in C++ that enhances the flexibility and efficiency of array and memory management. It allows developers to perform operations that facilitate traversal and manipulation of memory, but it also requires careful handling to avoid errors and undefined behavior.
    </p>
  </div>
)}


      {selectedChapter === 'chapter23' && (
        <div className={style.chaptercontent}>
          <h1 className={style.heading}>
            Dynamic Memory Allocation (new and delete)
          </h1>

          <p>
            In C++, <strong>dynamic memory allocation</strong> allows a program
            to allocate memory at runtime (i.e., during program execution)
            rather than at compile time. This is particularly useful when the
            size of data structures (like arrays) or objects is not known until
            the program is running.
          </p><br />

          <h3 style={{paddingBottom:"6px"}}>Key Concepts:</h3>

          <ul>
            <li>
              <strong>
                <code>new</code> operator
              </strong>
              : Allocates memory on the heap for a variable or object at
              runtime. It also returns a pointer to the newly allocated memory.
              When using <code>new</code>, the memory is not automatically
              deallocated, and the programmer must handle this manually.
            </li><br />
            <li>
              <strong>
                <code>delete</code> operator
              </strong>
              : Frees (deallocates) the memory that was previously allocated
              with <code>new</code>, returning it to the heap for reuse. Using{' '}
              <code>delete</code> prevents memory leaks, which occur when memory
              is allocated but not properly released.
            </li>
          </ul><br />

          <h3 style={{paddingBottom:"6px"}}>Syntax:</h3>
          <ol>
            <li>
              <strong>
                <code>new</code> operator
              </strong>
              :
              <pre>
                <code>
                  int* ptr = new int; // Allocates memory for an integer{'\n'}
                  int* arr = new int[10]; // Allocates memory for an array of 10
                  integers
                </code>
              </pre>
            </li><br />

            <li>
              <strong>
                <code>delete</code> operator
              </strong>
              :
              <pre>
                <code>
                  delete ptr; // Frees the memory allocated for the
                  integer{'\n'}
                  delete[] arr; // Frees the memory allocated for the array
                </code>
              </pre>
            </li>
          </ol><br />

          <h3>Example:</h3>
          <pre>
            <code>{`
              #include &lt;iostream&gt;{'\n'}
              using namespace std;{'\n'}
              {'\n'}
              int main() {'{'}
              {'\n'} {'  '}
              // Dynamically allocate memory for an integer{'\n'} {'  '}int* p =
              new int;{'\n'} {'  '}*p = 5;{'\n'} {'  '}cout &lt;&lt; "Value of
              p: " &lt;&lt; *p &lt;&lt; endl;{'\n'}
              {'\n'} {'  '}
              // Dynamically allocate memory for an array{'\n'} {'  '}int* arr =
              new int[5];{'\n'} {'  '}for(int i = 0; i &lt; 5; ++i) {'{'}{'\n'}
              {'    '}arr[i] = i * 2;{'\n'} {'  '}
              {'}'}
              {'\n'} {'  '}cout &lt;&lt; "Array values: ";{'\n'} {'  '}for(int i
              = 0; i &lt; 5; ++i) {'{'}{'\n'} {'    '}cout &lt;&lt; arr[i] &lt;&lt;
              " ";{'\n'} {'  '}
              {'}'}
              {'\n'} {'  '}cout &lt;&lt; endl;{'\n'}
              {'\n'} {'  '}
              // Deallocate memory{'\n'} {'  '}delete p;{'\n'} {'  '}delete[]
              arr;{'\n'}
              {'\n'} {'  '}return 0;{'\n'}
              {'}'}
            `}</code>
          </pre><br />

          <h3>Important Notes:</h3>
          <ul>
            <li>
              Always use <code>delete</code> for memory allocated with{' '}
              <code>new</code> to avoid memory leaks.
            </li><br />
            <li>
              Use <code>delete[]</code> to deallocate arrays allocated with{' '}
              <code>new[]</code>.
            </li><br />
            <li>
              After <code>delete</code>, the pointer should not be used unless
              it is reassigned, or it may cause undefined behavior.
            </li>
          </ul><br />

          <p>
            Dynamic memory allocation is essential for managing resources
            efficiently, especially in cases where the size of data structures
            or objects varies during runtime.
          </p>
    
  </div>

)}


  {selectedChapter === 'chapter24' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Reference vs. Pointers</h1>

    <p>
      In C++, <strong>references</strong> and <strong>pointers</strong> are both ways to indirectly access data stored in memory. While they serve similar purposes, they differ in how they behave, their syntax, and their usage. Here’s a detailed comparison:
    </p><br />

    <h2>Reference</h2>
    <p style={{paddingBottom:"6px"}}>A reference is an alias for an existing variable. Once a reference is initialized, it cannot be changed to refer to another variable. It must always reference a valid object.</p>

    <h3 style={{paddingBottom:"6px"}}>Key Characteristics:</h3>
    <ul>
      <li><strong>Initialization</strong>: A reference must be initialized when it is declared and cannot be changed later.</li><br />
      <li><strong>No null</strong>: A reference cannot be null, meaning it always refers to a valid object or variable.</li><br />
      <li><strong>Automatic dereferencing</strong>: Unlike pointers, references do not require explicit dereferencing using the <code>*</code> operator. Accessing the reference is equivalent to accessing the object directly.</li><br />
      <li><strong>Syntactic simplicity</strong>: References are simpler and more intuitive to use compared to pointers.</li>
    </ul><br />

    <h3>Syntax:</h3>
    <pre>
      <code>
        int a = 10;{'\n'}
        int&amp; ref = a;  // 'ref' is a reference to 'a'{'\n'}
        {'\n'}
        ref = 20;  // Modifies 'a', as 'ref' is an alias for 'a'
      </code>
    </pre><br />

    <h3 style={{paddingBottom:"6px"}}>Use Cases:</h3>
    <ul>
      <li><strong>Function Parameters</strong>: Passing large objects by reference avoids copying overhead.</li><br />
      <li><strong>Return Values</strong>: Functions can return references to allow modification of original data.</li>
    </ul><br />

    

    <h2>Pointer</h2>
    <p style={{paddingBottom:"6px"}}>
      A pointer is a variable that stores the memory address of another variable. Pointers allow more flexibility than references, such as changing what they point to or being assigned null values, but they also require explicit dereferencing.
    </p>

    <h3 style={{paddingBottom:"6px"}}>Key Characteristics:</h3>
    <ul>
      <li><strong>Can be reassigned</strong>: A pointer can point to different objects during its lifetime.</li><br />
      <li><strong>Can be null</strong>: Pointers can store a <code>nullptr</code>, which means they don’t point to any object.</li><br />
      <li><strong>Explicit dereferencing</strong>: To access the value of the object a pointer points to, you must use the <code>*</code> operator.</li><br />
      <li><strong>Memory management</strong>: Pointers can be used with dynamic memory allocation (<code>new</code>, <code>delete</code>).</li><br />
    </ul>

    <h3>Syntax:</h3>
    <pre>
      <code>
        int a = 10;{'\n'}
        int* ptr = &amp;a;  // 'ptr' is a pointer to 'a'{'\n'}
        {'\n'}
        *ptr = 20;  // Modifies 'a' through the pointer
      </code>
    </pre><br />


    <h3>Use Cases:</h3>
    <ul>
      <li><strong>Dynamic memory allocation</strong>: Pointers are used to manage heap memory with <code>new</code> and <code>delete</code>.</li><br />
      <li><strong>Function pointers</strong>: Pointers can store the address of functions and allow for dynamic dispatch.</li>
    </ul>

   <br />

    <h2>Comparison of References and Pointers</h2>
    <table className={style.table}>
      <thead>
        <tr>
          <th>Feature</th>
          <th>Reference</th>
          <th>Pointer</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Syntax</td>
          <td>Uses <code>&amp;</code> during initialization</td>
          <td>Uses <code>*</code> for declaration and <code>&amp;</code> to get the address</td>
        </tr>
        <tr>
          <td>Reassignment</td>
          <td>Cannot be reassigned</td>
          <td>Can be reassigned to point to different objects</td>
        </tr>
        <tr>
          <td>Nullability</td>
          <td>Cannot be null</td>
          <td>Can be null (<code>nullptr</code>)</td>
        </tr>
        <tr>
          <td>Dereferencing</td>
          <td>Implicit (no need for <code>*</code>)</td>
          <td>Requires explicit <code>*</code> operator</td>
        </tr>
        <tr>
          <td>Initialization</td>
          <td>Must be initialized at declaration</td>
          <td>Can be initialized later</td>
        </tr>
        <tr>
          <td>Memory management</td>
          <td>Not applicable</td>
          <td>Can manage dynamic memory with <code>new</code> and <code>delete</code></td>
        </tr>
        <tr>
          <td>Use in arrays</td>
          <td>Not applicable</td>
          <td>Can point to array elements</td>
        </tr>
        <tr>
          <td>Size</td>
          <td>Same size as the object it refers to</td>
          <td>Typically the size of a memory address (e.g., 4 or 8 bytes)</td>
        </tr>
      </tbody>
    </table>

    <br />

    <h3>Example: Reference vs. Pointer</h3>
    <pre>
      <code>
        #include &lt;iostream&gt;{'\n'}
        using namespace std;{'\n'}
        {'\n'}
        int main() {'{'}{'\n'}
          {'  '}int a = 10;{'\n'}
          {'  '}int b = 30;{'\n'}
          {'\n'}
          {'  '}// Reference{'\n'}
          {'  '}int&amp; ref = a;  // ref is an alias for a{'\n'}
          {'  '}ref = 20;  // Modifies a, now a = 20{'\n'}
          {'\n'}
          {'  '}// Pointer{'\n'}
          {'  '}int* ptr = &amp;a;  // ptr points to a{'\n'}
          {'  '}*ptr = 25;  // Modifies a through the pointer, now a = 25{'\n'}
          {'\n'}
          {'  '}ptr = &amp;b;  // ptr now points to b{'\n'}
          {'  '}*ptr = 40;  // Modifies b, now b = 40{'\n'}
          {'\n'}
          {'  '}cout &lt;&lt; "a = " &lt;&lt; a &lt;&lt; ", b = " &lt;&lt; b &lt;&lt; endl;  // Output: a = 25, b = 40{'\n'}
          {'\n'}
          {'  '}return 0;{'\n'}
        {'}'}{'\n'}
      </code>
    </pre><br />

    <h3 style={{paddingBottom:"6px"}}>Summary:</h3>
    <ul>
      <li><strong>References</strong> are simpler to use and safer but less flexible because they cannot be null or reassigned.</li><br />
      <li><strong>Pointers</strong> are more powerful, providing the ability to point to different objects, manage dynamic memory, and be null, but they require careful handling to avoid issues like null pointer dereferencing or memory leaks.</li>
    </ul>
  </div>
)}





      {selectedChapter === 'chapter25' && (
        <div className={style.chaptercontent}>
          <h1 className={style.heading}>  Smart Pointers (unique_ptr, shared_ptr web_ptr)</h1>

          <p>
            In C++, <strong>smart pointers</strong> are special classes that
            manage dynamically allocated memory automatically. They ensure that
            memory is properly deallocated when it's no longer needed, thus
            preventing memory leaks and other common issues like dangling
            pointers. C++ provides three main types of smart pointers: 
            <code>unique_ptr</code>, <code>shared_ptr</code>, and <code>weak_ptr</code>.
          </p><br />

          <h3>1. <code>unique_ptr</code>:</h3>
          <p>
            <strong>Description</strong>: A smart pointer that owns and manages
            the lifetime of a dynamically allocated object. It guarantees that
            there is only <strong>one owner</strong> of the object, meaning the
            object will be deleted when the <code>unique_ptr</code> goes out of
            scope.
          </p><br />
          <p><strong>Characteristics:</strong></p>
          <ul>
            <li>Cannot be copied.</li>
            <li>
              Can be moved using <code>std::move()</code>, transferring ownership
              to another <code>unique_ptr</code>.
            </li>
          </ul><br />

          <p><strong>Usage:</strong></p>
          <pre>
            <code>
              {`std::unique_ptr<int> ptr1 = std::make_unique<int>(10);  // Allocates an int with value 10
std::unique_ptr<int> ptr2 = std::move(ptr1);  // Transfers ownership from ptr1 to ptr2`}
            </code>
          </pre><br />

          <p><strong>Benefits:</strong></p>
          <ul>
            <li>
              Helps avoid memory leaks by automatically deallocating memory when
              the pointer goes out of scope.
            </li><br />
            <li>Provides exclusive ownership, improving safety.</li>
          </ul><br />

          <h3>2. <code>shared_ptr</code>:</h3>
          <p>
            <strong>Description</strong>: A smart pointer that allows multiple
            pointers to own the same object. The object is destroyed only when
            the last <code>shared_ptr</code> that owns it is destroyed (i.e.,
            when the reference count drops to zero).
          </p><br />
          <p><strong>Characteristics:</strong></p>
          <ul>
            <li>Can be shared between multiple <code>shared_ptr</code> instances.</li>
            <li>
              Maintains a <strong>reference count</strong> internally to track
              how many <code>shared_ptr</code>s are pointing to the object.
            </li>
          </ul><br />

          <p><strong>Usage:</strong></p>
          <pre>
            <code>
              {`std::shared_ptr<int> ptr1 = std::make_shared<int>(20);  // Allocates an int with value 20
std::shared_ptr<int> ptr2 = ptr1;  // ptr1 and ptr2 share ownership of the object`}
            </code>
          </pre><br />

          <p><strong>Benefits:</strong></p>
          <ul>
            <li>Multiple <code>shared_ptr</code>s can safely point to the same object.</li>
            <li>
              The object is automatically deallocated when no <code>shared_ptr</code>
              instances are referencing it.
            </li>
          </ul><br />

          <h3>3. <code>weak_ptr</code>:</h3>
          <p>
            <strong>Description</strong>: A smart pointer that holds a
            <strong> non-owning</strong> reference to an object managed by a
            <code>shared_ptr</code>. It is used to break circular references
            that can occur with <code>shared_ptr</code>s (where two objects
            refer to each other, preventing proper deallocation).
          </p><br />
          <p><strong style={{paddingBottom:"6px"}}>Characteristics:</strong></p>
          <ul>
            <li>Does not contribute to the reference count of the object.</li><br />
            <li>
              Can be converted to a <code>shared_ptr</code> using <code>lock()</code>,
              if the object still exists.
            </li>
          </ul><br />

          <p><strong>Usage:</strong></p>
          <pre>
            <code>
              {`std::shared_ptr<int> sptr = std::make_shared<int>(30);  // Shared ownership
std::weak_ptr<int> wptr = sptr;  // Weak reference to the object

if (auto temp = wptr.lock()) {  // Converts weak_ptr to shared_ptr if object still exists
    std::cout << *temp << std::endl;  // Safe to use the object
}`}
            </code>
          </pre><br />

          <p><strong>Benefits:</strong></p>
          <ul>
            <li>Prevents circular dependencies between <code>shared_ptr</code>s.</li><br />
            <li>Useful for caching and observer patterns.</li>
          </ul><br />

          <h3>Comparison:</h3>
          <table>
            <thead>
              <tr>
                <th>Feature</th>
                <th><code>unique_ptr</code></th>
                <th><code>shared_ptr</code></th>
                <th><code>weak_ptr</code></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Ownership</td>
                <td>Exclusive</td>
                <td>Shared</td>
                <td>No ownership</td>
              </tr>
              <tr>
                <td>Copyable</td>
                <td>No</td>
                <td>Yes (increases ref count)</td>
                <td>No (but can be assigned)</td>
              </tr>
              <tr>
                <td>Moveable</td>
                <td>Yes</td>
                <td>Yes</td>
                <td>Yes</td>
              </tr>
              <tr>
                <td>Use Case</td>
                <td>Single ownership</td>
                <td>Multiple ownership</td>
                <td>Observing without ownership</td>
              </tr>
            </tbody>
          </table><br />

          <h3>Example Code:</h3>
          <pre>
            <code>
              {`#include <iostream>
#include <memory>  // For smart pointers

int main() {
    // unique_ptr example
    std::unique_ptr<int> uptr = std::make_unique<int>(100);
    std::cout << "unique_ptr value: " << *uptr << std::endl;

    // shared_ptr example
    std::shared_ptr<int> sptr1 = std::make_shared<int>(200);
    std::shared_ptr<int> sptr2 = sptr1;  // Shared ownership
    std::cout << "shared_ptr value: " << *sptr1 << std::endl;
    std::cout << "shared_ptr reference count: " << sptr1.use_count() << std::endl;

    // weak_ptr example
    std::weak_ptr<int> wptr = sptr1;  // Weak reference
    if (auto temp = wptr.lock()) {    // Lock to get a shared_ptr if still valid
        std::cout << "weak_ptr value: " << *temp << std::endl;
    } else {
        std::cout << "Object no longer exists" << std::endl;
    }

    return 0;
}`}
            </code>
          </pre><br />

          <h3 style={{paddingBottom:"6px"}}>Summary:</h3>
          <ul>
            <li>
              <code>unique_ptr</code>: Provides exclusive ownership and is ideal for cases
              where only one pointer manages the object.
            </li><br />
            <li>
              <code>shared_ptr</code>: Allows shared ownership and automatic memory
              management when multiple pointers need access to the same object.
            </li><br />
            <li>
              <code>weak_ptr</code>: Provides a non-owning reference, useful for
              preventing circular references and safe access to objects managed
              by <code>shared_ptr</code>.
            </li>
          </ul>
        </div>
      )}


{selectedChapter === 'chapter26' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Class and Objects</h1>

    <p>In C++, <strong>classes</strong> and <strong>objects</strong> are fundamental concepts of object-oriented programming (OOP).</p>

    <h3>1. <strong>Class</strong>:</h3>
    <p>
      A class is a blueprint or template that defines the data and functions (methods) 
      that operate on that data. It encapsulates the data (attributes) and the 
      operations that can be performed on them (methods), thus supporting abstraction.
    </p>
    <p>
      A class defines the structure and behavior of the objects created from it 
      but does not allocate memory for them. The memory for attributes is 
      allocated when an object is created.
    </p><br />

    <h4>Syntax:</h4>
    <pre>
      <code>
        {`class ClassName {
    public:  // Access specifier
        // Attributes (data members)
        int attribute1;
        float attribute2;

        // Methods (member functions)
        void method() {
            // Function implementation
        }
};`}
      </code>
    </pre><br />

    <h3>2. <strong>Object</strong>:</h3>
    <p>
      An object is an instance of a class. It represents a real-world entity with a specific 
      state and behavior. When a class is defined, no memory is allocated until an 
      object is created. Each object has its own copy of the class's attributes.
    </p><br />

    <h4>Syntax:</h4>
    <pre>
      <code>
        {`ClassName objectName;  // Declaration of an object of ClassName`}
      </code>
    </pre><br />

    <h4>Example:</h4>
    <pre>
      <code>
        {`#include <iostream>
using namespace std;

class Car {
    public:
        string brand;
        string model;
        int year;

        // Method to display car details
        void displayInfo() {
            cout << "Brand: " << brand << ", Model: " << model << ", Year: " << year << endl;
        }
};

int main() {
    // Creating an object of class Car
    Car car1;
    car1.brand = "Toyota";
    car1.model = "Corolla";
    car1.year = 2020;

    // Accessing object members
    car1.displayInfo();

    return 0;
}`}
      </code>
    </pre><br />

    <h4>Key Concepts:</h4>
    <ul>
      <li><strong>Class</strong>: Defines the structure and behavior.</li><br />
      <li><strong>Object</strong>: Instance of a class with its own set of attributes and behaviors.</li>
    </ul>
  </div>
)}



{selectedChapter === 'chapter27' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Constructors and Destructors</h1>

    <p>
      In C++, <strong>constructors</strong> and <strong>destructors</strong> are special member functions of a class, used for initialization and cleanup when an object is created or destroyed.
    </p><br />

    <h3>1. <strong>Constructor</strong>:</h3>
    <p style={{paddingBottom:"6px"}}>
      A constructor is a special function that is automatically called when an object of a class is created. Its primary purpose is to initialize the object's attributes. Constructors have the same name as the class and do not have a return type.
    </p>

    <h4 style={{paddingBottom:"6px"}}>Types of Constructors:</h4>
    <ul>
      <li><strong>Default Constructor</strong>: Takes no arguments and initializes objects with default values.</li><br />
      <li><strong>Parameterized Constructor</strong>: Takes arguments to initialize an object with specific values.</li><br />
      <li><strong>Copy Constructor</strong>: Initializes an object by copying another object of the same class.</li>
    </ul><br />

    <h4  style={{paddingBottom:"6px"}}>Characteristics:</h4>
    <ul>
      <li>The constructor is called automatically when an object is created.</li><br />
      <li>Multiple constructors can be defined using overloading (constructor overloading).</li><br />
      <li>A constructor cannot return any value.</li>
    </ul><br />

    <h4>Syntax:</h4>
    <pre>
      <code>
        {`class ClassName {
    public:
        int attribute;
        
        // Default constructor
        ClassName() {
            attribute = 0;
        }

        // Parameterized constructor
        ClassName(int val) {
            attribute = val;
        }
};`}
      </code>
    </pre><br />

    <h4>Example:</h4>
    <pre>
      <code>
        {`#include <iostream>
using namespace std;

class Car {
    public:
        string brand;
        string model;
        int year;

        // Default constructor
        Car() {
            brand = "Unknown";
            model = "Unknown";
            year = 0;
        }

        // Parameterized constructor
        Car(string b, string m, int y) {
            brand = b;
            model = m;
            year = y;
        }

        void displayInfo() {
            cout << "Brand: " << brand << ", Model: " << model << ", Year: " << year << endl;
        }
};

int main() {
    // Creating objects using different constructors
    Car car1;  // Default constructor
    Car car2("Toyota", "Corolla", 2020);  // Parameterized constructor

    car1.displayInfo();
    car2.displayInfo();

    return 0;
}`}
      </code>
    </pre><br />

    <h3>2. <strong>Destructor</strong>:</h3>
    <p style={{paddingBottom:"6px"}}>
      A destructor is a special member function that is automatically called when an object goes out of scope or is explicitly deleted. Its primary purpose is to free up resources allocated during the object's lifetime (e.g., releasing memory).
    </p>

    <h4 style={{paddingBottom:"6px"}}>Characteristics:</h4>
    <ul>
      <li>The destructor has the same name as the class but is prefixed with a tilde <code>~</code>.</li><br />
      <li>There can be only one destructor for a class, and it cannot be overloaded.</li><br />
      <li>A destructor does not accept any parameters or return any value.</li>
      <li>It is called automatically when the object is destroyed, either by scope exit or by explicitly deleting the object.</li>
    </ul><br />

    <h4>Syntax:</h4>
    <pre>
      <code>
        {`class ClassName {
    public:
        // Destructor
        ~ClassName() {
            // Code to release resources
        }
};`}
      </code>
    </pre><br />

    <h4>Example:</h4>
    <pre>
      <code>
        {`#include <iostream>
using namespace std;

class Car {
    public:
        string brand;
        string model;
        int year;

        // Parameterized constructor
        Car(string b, string m, int y) {
            brand = b;
            model = m;
            year = y;
        }

        // Destructor
        ~Car() {
            cout << "Destructor called for " << brand << " " << model << endl;
        }

        void displayInfo() {
            cout << "Brand: " << brand << ", Model: " << model << ", Year: " << year << endl;
        }
};

int main() {
    Car car1("Toyota", "Corolla", 2020);
    car1.displayInfo();

    // Destructor is called automatically when car1 goes out of scope
    return 0;
}`}
      </code>
    </pre><br />

    <h4 style={{paddingBottom:"6px"}}>Key Concepts:</h4>
    <ul>
      <li><strong>Constructor</strong>: Initializes an object when it is created. Can be default, parameterized, or a copy constructor.</li><br />
      <li><strong>Destructor</strong>: Cleans up resources when an object is destroyed. Only one destructor is allowed per class, and it is called automatically.</li>
    </ul>
  </div>
)}


{selectedChapter === 'chapter28' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Access Specifiers (public, private, protected)</h1>

    <p>
      In C++, <strong>access specifiers</strong> define the visibility or accessibility of class members (attributes and methods) to outside code. They control how and where these members can be accessed. The three main access specifiers are <strong>public</strong>, <strong>private</strong>, and <strong>protected</strong>.
    </p><br />

    <h3>1. <strong>Public</strong></h3>
    <p>
      Members declared as <code>public</code> can be accessed from anywhere in the program. This includes access from outside the class, derived classes, and functions.
    </p>

    <h4>Example:</h4>
    <pre>
      <code>
        {`class MyClass {
    public:
        int publicVar; // Public variable

        void display() { // Public method
            cout << "Public Variable: " << publicVar << endl;
        }
};

int main() {
    MyClass obj;
    obj.publicVar = 10; // Accessible
    obj.display(); // Accessible
    return 0;
}`}
      </code>
    </pre><br />

    <h3>2. <strong>Private</strong></h3>
    <p>
      Members declared as <code>private</code> can only be accessed within the same class. They are not accessible from outside the class or from derived classes. This is the default access level if no specifier is provided.
    </p>

    <h4>Example:</h4>
    <pre>
      <code>
        {`class MyClass {
    private:
        int privateVar; // Private variable

    public:
        void setPrivateVar(int val) { // Public method to set private variable
            privateVar = val;
        }

        void display() { // Public method to display private variable
            cout << "Private Variable: " << privateVar << endl;
        }
};

int main() {
    MyClass obj;
    // obj.privateVar = 10; // Error: privateVar is not accessible
    obj.setPrivateVar(10); // Accessible through public method
    obj.display(); // Accessible
    return 0;
}`}
      </code>
    </pre><br />

    <h3>3. <strong>Protected</strong></h3>
    <p>
      Members declared as <code>protected</code> can be accessed within the same class and by derived classes (subclasses). However, they are not accessible from outside the class hierarchy.
    </p>

    <h4>Example:</h4>
    <pre>
      <code>
        {`class Base {
    protected:
        int protectedVar; // Protected variable
};

class Derived : public Base {
    public:
        void setProtectedVar(int val) { // Public method in derived class
            protectedVar = val; // Accessible within derived class
        }

        void display() {
            cout << "Protected Variable: " << protectedVar << endl; // Accessible
        }
};

int main() {
    Derived obj;
    obj.setProtectedVar(20); // Accessible through public method
    obj.display(); // Accessible
    // obj.protectedVar = 30; // Error: protectedVar is not accessible
    return 0;
}`}
      </code>
    </pre><br />

    <h3 style={{paddingBottom:"6px"}}>Summary of Access Specifiers:</h3>
    <ul>
      <li><strong>Public</strong>: Accessible from anywhere.</li><br />
      <li><strong>Private</strong>: Accessible only within the class itself.</li><br />
      <li><strong>Protected</strong>: Accessible within the class and by derived classes but not from outside.</li>
    </ul><br />

    <p>
      These access specifiers are essential for implementing encapsulation in C++, allowing you to control how the data within a class is accessed and modified.
    </p>
  </div>
)}


{selectedChapter === 'chapter29' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Inheritance (Single, Multiple, Multilevel, Hierarchical)</h1>

    <p>
      In C++, <strong>inheritance</strong> is a fundamental concept of object-oriented programming that allows a class (derived class) to inherit properties and behaviors (data members and member functions) from another class (base class). This promotes code reusability and establishes a natural hierarchy between classes. There are several types of inheritance:
    </p><br />

    <h3>1. Single Inheritance</h3>
    <pre>
      <code>
        {`#include <iostream>
using namespace std;

class Animal { // Base class
    public:
        void eat() {
            cout << "Animal is eating." << endl;
        }
};

class Dog : public Animal { // Derived class
    public:
        void bark() {
            cout << "Dog is barking." << endl;
        }
};

int main() {
    Dog dog;
    dog.eat(); // Inherited from Animal
    dog.bark(); // Dog's own method
    return 0;
}`}
      </code>
    </pre><br />

    <h3>2. Multiple Inheritance</h3>
    <pre>
      <code>
        {`#include <iostream>
using namespace std;

class Canine { // First base class
    public:
        void bark() {
            cout << "Canine is barking." << endl;
        }
};

class Pet { // Second base class
    public:
        void play() {
            cout << "Pet is playing." << endl;
        }
};

class Dog : public Canine, public Pet { // Derived class
    public:
        void display() {
            cout << "Dog is a type of Canine and a Pet." << endl;
        }
};

int main() {
    Dog dog;
    dog.bark(); // Inherited from Canine
    dog.play(); // Inherited from Pet
    dog.display(); // Dog's own method
    return 0;
}`}
      </code>
    </pre><br />

    <h3>3. Multilevel Inheritance</h3>
    <pre>
      <code>
        {`#include <iostream>
using namespace std;

class Animal { // Base class
    public:
        void eat() {
            cout << "Animal is eating." << endl;
        }
};

class Dog : public Animal { // Intermediate derived class
    public:
        void bark() {
            cout << "Dog is barking." << endl;
        }
};

class Puppy : public Dog { // Derived class
    public:
        void weep() {
            cout << "Puppy is weeping." << endl;
        }
};

int main() {
    Puppy puppy;
    puppy.eat(); // Inherited from Animal
    puppy.bark(); // Inherited from Dog
    puppy.weep(); // Puppy’s own method
    return 0;
}`}
      </code>
    </pre><br />

    <h3>4. Hierarchical Inheritance</h3>
    <pre>
      <code>
        {`#include <iostream>
using namespace std;

class Animal { // Base class
    public:
        void eat() {
            cout << "Animal is eating." << endl;
        }
};

class Dog : public Animal { // Derived class 1
    public:
        void bark() {
            cout << "Dog is barking." << endl;
        }
};

class Cat : public Animal { // Derived class 2
    public:
        void meow() {
            cout << "Cat is meowing." << endl;
        }
};

int main() {
    Dog dog;
    Cat cat;

    dog.eat(); // Inherited from Animal
    dog.bark(); // Dog's own method

    cat.eat(); // Inherited from Animal
    cat.meow(); // Cat's own method

    return 0;
}`}
      </code>
    </pre><br />

    <h3 style={{paddingBottom:"6px"}}>Summary of Inheritance Types:</h3>
    <ul>
      <li><strong>Single Inheritance</strong>: A derived class inherits from one base class.</li><br />
      <li><strong>Multiple Inheritance</strong>: A derived class inherits from multiple base classes.</li><br />
      <li><strong>Multilevel Inheritance</strong>: A derived class inherits from another derived class.</li><br />
      <li><strong>Hierarchical Inheritance</strong>: Multiple derived classes inherit from a single base class.</li>
    </ul><br />

    <p>
      Inheritance enhances code reusability and allows for better organization of code, promoting a natural classification of classes in C++.
    </p>
  </div>
)}


  {selectedChapter === 'chapter30' && (
<div className={style.chaptercontent}>
  <h1 className={style.heading}>Polymorphism (Compile-time and Run-time)</h1>

  <p>
    In C++, <strong>polymorphism</strong> is a core concept of object-oriented programming that allows objects of different classes to be treated as objects of a common base class. 
    It provides a way to perform a single action in different forms. Polymorphism can be categorized into two types: 
    <strong>compile-time polymorphism</strong> (also known as static polymorphism) and <strong>run-time polymorphism</strong> (also known as dynamic polymorphism).
  </p><br />

  <h2>1. Compile-Time Polymorphism</h2>
  <p>
    Compile-time polymorphism is resolved during the compilation of the program. It is achieved through:
  </p>
  <ul>
    <li><strong>Function Overloading</strong>: Multiple functions can have the same name but different parameter lists (number or types of parameters). The appropriate function is chosen based on the arguments passed at compile time.</li><br />
    <li><strong>Operator Overloading</strong>: This allows operators to be redefined and used in different ways depending on the data types of the operands.</li>
  </ul><br />

  <h3>Example of Function Overloading:</h3>
  <pre>
    <code>{`
#include &lt;iostream&gt;
using namespace std;

class Print {{
    public:
        void show(int i) {{
            cout &lt;&lt; "Integer: " &lt;&lt; i &lt;&lt; endl;
        }}

        void show(double d) {{
            cout &lt;&lt; "Double: " &lt;&lt; d &lt;&lt; endl;
        }}

        void show(string s) {{
            cout &lt;&lt; "String: " &lt;&lt; s &lt;&lt; endl;
        }}
}};

int main() {{
    Print print;
    print.show(5);         // Calls show(int)
    print.show(3.14);      // Calls show(double)
    print.show("Hello");   // Calls show(string)

    return 0;
}}
     `} </code>
  </pre><br />

  <h2>2. Run-Time Polymorphism</h2>
  <p>
    Run-time polymorphism is resolved during program execution. It is primarily achieved through <strong>inheritance</strong> and <strong>virtual functions</strong>. 
    When a base class declares a function as virtual, it allows derived classes to override that function. The appropriate function to call is determined at runtime based on the object type.
  </p><br />

  <h3>Example of Run-Time Polymorphism:</h3>
  <pre>
    <code>{`
#include &lt;iostream&gt;
using namespace std;

class Base {{
    public:
        virtual void show() {{ // Virtual function
            cout &lt;&lt; "Base class show function called." &lt;&lt; endl;
        }}
}};

class Derived : public Base {{
    public:
        void show() override {{ // Override base class function
            cout &lt;&lt; "Derived class show function called." &lt;&lt; endl;
        }}
}};

int main() {{
    Base* basePtr;          // Base class pointer
    Derived derivedObj;     // Derived class object
    basePtr = &derivedObj;  // Pointing to derived class object

    basePtr-&gt;show();       // Calls Derived class show() function (run-time polymorphism)

    return 0;
}}
      `}  </code>
  </pre><br />

  <h2 style={{paddingBottom:"6px"}}>Key Concepts:</h2>
  <ul>
    <li><strong style={{paddingBottom:"6px"}}>Compile-Time Polymorphism</strong>:
      <ul>
        <li>Resolved during compilation.</li><br />
        <li>Achieved through function and operator overloading.</li><br />
        <li>Fast as decisions are made at compile time.</li>
      </ul>
    </li><br />
    <li><strong style={{paddingBottom:"6px"}}>Run-Time Polymorphism</strong>:
      <ul>
        <li>Resolved during program execution.</li><br />
        <li>Achieved through virtual functions and inheritance.</li><br />
        <li>Allows dynamic method binding, making it flexible and powerful.</li>
      </ul>
    </li>
  </ul><br />

  <p>
    Polymorphism enhances the flexibility and maintainability of code, allowing the same interface to be used for different underlying forms (data types).
  </p>
</div>
)}



{selectedChapter === 'chapter31' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Abstract Classes and Interfaces</h1>

    <p>
      In C++, <strong>abstract classes</strong> and <strong>interfaces</strong> are fundamental concepts in object-oriented programming that help define abstract types and promote the implementation of polymorphism.
    </p><br />

    <h2>Abstract Classes</h2>
    <p style={{paddingBottom:"6px"}}>
      An abstract class is a class that cannot be instantiated on its own and is intended to be a base class for other derived classes. It can contain both complete (defined) and incomplete (pure virtual) methods. An abstract class is created when at least one of its member functions is declared as a <strong>pure virtual function</strong>. This is done by appending <code>= 0</code> to the function declaration.
    </p>

    <h3 style={{paddingBottom:"6px"}}>Key Characteristics:</h3>
    <ul>
      <li>Cannot be instantiated directly.</li><br />
      <li>May contain data members and fully implemented methods.</li><br />
      <li>Can have one or more pure virtual functions.</li><br />
      <li>Derived classes must provide implementations for all pure virtual functions to be instantiated.</li>
    </ul><br />

    <h3>Example:</h3>
    <pre>
      <code>{`
        #include &lt;iostream&gt;
        using namespace std;

        class Shape { // Abstract class
          public:
            // Pure virtual function
            virtual void draw() = 0; 

            // Regular function
            void display() {
              cout &lt;&lt; "Displaying shape." &lt;&lt; endl;
            }
        };

        class Circle : public Shape { // Derived class
          public:
            void draw() override { // Implementing pure virtual function
              cout &lt;&lt; "Drawing Circle." &lt;&lt; endl;
            }
        };

        class Square : public Shape { // Another derived class
          public:
            void draw() override { // Implementing pure virtual function
              cout &lt;&lt; "Drawing Square." &lt;&lt; endl;
            }
        };

        int main() {
          Circle circle;
          Square square;

          circle.display(); // Call to regular function
          circle.draw();    // Call to overridden function

          square.display(); // Call to regular function
          square.draw();    // Call to overridden function

          return 0;
        }
      `}</code>
    </pre><br />

    <h2>Interfaces</h2>
    <p style={{paddingBottom:"6px"}}>
      In C++, an interface is often defined as a class that only contains pure virtual functions and does not provide any implementation for them. Essentially, all members of an interface are pure virtual functions. While C++ does not have a dedicated <code>interface</code> keyword like some other languages (e.g., Java), the concept is achieved using abstract classes.
    </p>

    <h3 style={{paddingBottom:"6px"}}>Key Characteristics:</h3>
    <ul>
      <li>Only contains pure virtual functions (no data members or implementation).</li><br />
      <li>Cannot be instantiated directly.</li><br />
      <li>All derived classes must implement all functions.</li>
    </ul><br />

    <h3>Example:</h3>
    <pre>
      <code>{`
        #include &lt;iostream&gt;
        using namespace std;

        class IShape { // Interface
          public:
            virtual void draw() = 0; // Pure virtual function
            virtual void area() = 0; // Pure virtual function
        };

        class Rectangle : public IShape { // Derived class implementing interface
          private:
            int width, height;

          public:
            Rectangle(int w, int h) : width(w), height(h) {}

            void draw() override { // Implementing interface method
              cout &lt;&lt; "Drawing Rectangle." &lt;&lt; endl;
            }

            void area() override { // Implementing interface method
              cout &lt;&lt; "Area of Rectangle: " &lt;&lt; width * height &lt;&lt; endl;
            }
        };

        int main() {
          Rectangle rect(5, 3);
          rect.draw(); // Call to draw
          rect.area(); // Call to area

          return 0;
        }
       `} </code>
    </pre><br />

    <h2 style={{paddingBottom:"6px"}}>Summary</h2>
    <ul>
      <li><strong style={{paddingBottom:"6px"}}>Abstract Class</strong>: 
        <ul>
          <li>Can have both implemented and pure virtual functions.</li><br />
          <li>Can contain data members.</li><br />
          <li>Cannot be instantiated directly.</li>
        </ul>
      </li><br />
      <li><strong style={{paddingBottom:"6px"}}>Interface</strong>: 
        <ul>
          <li>Contains only pure virtual functions (no data members).</li><br />
          <li>Cannot be instantiated directly.</li>
        </ul>
      </li>
    </ul><br />

    <p>
      Both abstract classes and interfaces enable polymorphism and define a contract for derived classes to implement specific behaviors, promoting code reusability and maintainability.
    </p>
  </div>


)}
{selectedChapter === 'chapter32' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Operator Overloading</h1>
    <p>
      <strong>Operator Overloading</strong> in C++ is a powerful feature that allows developers to redefine the way operators work for user-defined types (classes). This enables objects of these types to be manipulated using standard operators (like <code>+</code>, <code>-</code>, <code>*</code>, etc.) in a way that is intuitive and expressive.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Key Concepts</h2>
    <ol>
      <li>
        <strong>Purpose</strong>: Operator overloading allows objects of user-defined classes to be used with operators just like built-in types. This enhances code readability and maintainability.
      </li><br />
      <li>
        <strong>Syntax</strong>: Operator overloading is done by defining a special member function in the class. The function name is the keyword <code>operator</code> followed by the symbol of the operator being overloaded.
      </li><br />
      <li>
        <strong style={{paddingBottom:"6px"}}>Member vs. Non-member Functions</strong>:
        <ul>
          <li>
            <strong>Member Function</strong>: The operator function is defined within the class and typically operates on the current object (the left operand).
          </li><br />
          <li>
            <strong>Non-member Function</strong>: The operator function is defined outside the class and can operate on two operands (both can be objects of the class).
          </li>
        </ul>
      </li>
    </ol><br />

    <h2>Example of Operator Overloading</h2>
    <p>Here’s a simple example demonstrating operator overloading for the <code>+</code> operator for a <code>Point</code> class that represents a point in a 2D space.</p>

    <pre>
      <code>{`
        #include &lt;iostream&gt;
        using namespace std;

        class Point {
            private:
                int x, y;

            public:
                // Constructor
                Point(int x = 0, int y = 0) : x(x), y(y) {}

                // Overloading the + operator
                Point operator+(const Point& other) {
                    return Point(x + other.x, y + other.y);
                }

                // Display function
                void display() const {
                    cout &lt;&lt; "(" &lt;&lt; x &lt;&lt; ", " &lt;&lt; y &lt;&lt; ")" &lt;&lt; endl;
                }
        };

        int main() {
            Point p1(1, 2);
            Point p2(3, 4);
            Point p3 = p1 + p2; // Using overloaded + operator

            cout &lt;&lt; "Point 1: ";
            p1.display();
            cout &lt;&lt; "Point 2: ";
            p2.display();
            cout &lt;&lt; "Point 3 (p1 + p2): ";
            p3.display();

            return 0;
        }
       `} </code>
    </pre><br />

    <h2 style={{paddingBottom:"6px"}}>Explanation of the Example</h2>
    <ol>
      <li>
        <strong>Class Definition</strong>: The <code>Point</code> class has two private data members, <code>x</code> and <code>y</code>, representing the coordinates of the point.
      </li><br />
      <li>
        <strong>Constructor</strong>: Initializes the point's coordinates.
      </li><br />
      <li>
        <strong style={{paddingBottom:"6px"}}>Operator Overloading</strong>: 
        <ul>
          <li>The <code>operator+</code> function takes another <code>Point</code> object as a parameter.</li><br />
          <li>It creates a new <code>Point</code> object with the coordinates of the sum of the two points.</li>
        </ul>
      </li><br />
      <li>
        <strong>Display Function</strong>: A method to output the coordinates of the point.
      </li><br />
      <li>
        <strong>Usage</strong>: In the <code>main()</code> function, two <code>Point</code> objects (<code>p1</code> and <code>p2</code>) are created, and their sum is calculated using the overloaded <code>+</code> operator.
      </li>
    </ol><br />

    <h2 style={{paddingBottom:"6px"}}>Benefits of Operator Overloading</h2>
    <ul>
      <li>
        <strong>Improved Code Readability</strong>: Operators can be used in a familiar way, making code that uses user-defined types more intuitive.
      </li><br />
      <li>
        <strong>Enhanced Functionality</strong>: Allows for the implementation of custom behaviors for standard operators, enabling more powerful abstractions.
      </li>
    </ul><br />

    <h2 style={{paddingBottom:"6px"}}>Considerations</h2>
    <ul>
      <li>Operator overloading should be used judiciously. It can lead to confusion if the overloaded operator behaves in a way that is unexpected or inconsistent with its typical usage.</li><br />
      <li>Not all operators can be overloaded. For example, the scope resolution operator (<code>::</code>), member access operator (<code>.</code>), and others cannot be overloaded.</li>
    </ul><br />

    <p>
      By utilizing operator overloading effectively, you can create classes that are easier to use and integrate seamlessly with C++'s built-in operations.
    </p>
  </div>
)}

{selectedChapter === 'chapter33' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Friend Functions and Classes</h1>

    <p>
      In C++, a <strong>friend function</strong> is a function that is not a member of a class but has the ability to access its private and protected members. This allows for more flexible designs and is particularly useful when a function needs to perform operations on objects of multiple classes or when operator overloading is involved.
    </p><br />

    <h2>Friend Functions</h2>
    <p>A <strong>friend function</strong> has the following key characteristics:</p>
    <ul>
      <li>Declared within a class using the keyword <code>friend</code>.</li><br />
      <li>Can access all private and protected members of the class.</li><br />
      <li>Defined outside the class but has access to the class's private data.</li><br />
      <li>Can be used for operator overloading.</li>
    </ul><br />

    <h3>Syntax:</h3>
    <pre>
      <code>
{`class ClassName {
    friend ReturnType friendFunction(Parameters); // Declaration of friend function
    // ...
};`}
      </code>
    </pre><br />

    <h3>Example:</h3>
    <pre>
      <code>
{`#include <iostream>
using namespace std;

class Box {
    private:
        double width;

    public:
        Box(double w) : width(w) {}

        // Friend function declaration
        friend void printWidth(Box box);
};

// Friend function definition
void printWidth(Box box) {
    cout << "Width: " << box.width << endl; // Accessing private member
}

int main() {
    Box box(10.5);
    printWidth(box); // Calling friend function
    return 0;
}`}
      </code>
    </pre><br />

    <h2>Friend Classes</h2>
    <p>A <strong>friend class</strong> is a class whose member functions can access the private and protected members of another class. This is useful when two classes are closely related and require mutual access to each other's data members.</p><br />

    <h3 style={{paddingBottom:"6px"}}>Key Characteristics:</h3>
    <ul>
      <li>Declared within a class using the keyword <code>friend</code>.</li><br />
      <li>All member functions of the friend class have access to the private and protected members of the other class.</li><br />
      <li>Promotes encapsulation while allowing specific classes to work closely together.</li>
    </ul><br />

    <h3>Syntax:</h3>
    <pre>
      <code>
{`class ClassName {
    friend class FriendClassName; // Declaration of friend class
    // ...
};`}
      </code>
    </pre><br />

    <h3>Example:</h3>
    <pre>
      <code>
{`#include <iostream>
using namespace std;

class Box; // Forward declaration

class BoxManager {
    public:
        void printBoxWidth(Box box); // Declaration of member function
};

class Box {
    private:
        double width;

    public:
        Box(double w) : width(w) {}

        // Friend class declaration
        friend class BoxManager; 
};

// Friend class member function definition
void BoxManager::printBoxWidth(Box box) {
    cout << "Width: " << box.width << endl; // Accessing private member
}

int main() {
    Box box(15.0);
    BoxManager manager;
    manager.printBoxWidth(box); // Calling friend class method
    return 0;
}`}
      </code>
    </pre><br />

    <h2 style={{paddingBottom:"6px"}}>Summary</h2>
    <ul>
      <li><strong>Friend Function:</strong> A non-member function that has access to the private and protected members of a class, declared inside the class using the <code>friend</code> keyword.</li><br />
      <li><strong>Friend Class:</strong> A class whose member functions have access to the private and protected members of another class, declared in the other class using the <code>friend</code> keyword.</li>
    </ul><br />

    <p>
      Using friend functions and classes allows for better encapsulation and helps in designing closely related classes that need to share data without exposing their internals unnecessarily.
    </p>
  </div>
)}


{selectedChapter === 'chapter34' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Templates (Function Templates and Class Templates)</h1>

    <p>
      Here's a comprehensive definition and explanation of <strong>Templates</strong> (Function Templates and Class Templates) in C++:
    </p><br />

    <h2>Templates in C++</h2>

    <p>
      <strong>Templates</strong> are a powerful feature in C++ that allows developers to write generic and reusable code. By using templates, you can create functions or classes that can operate with any data type. This reduces code duplication and improves type safety.
    </p><br />

    <h2>Function Templates</h2>

    <p style={{paddingBottom:"6px"}}>
      A <strong>function template</strong> allows you to define a function with a placeholder for the data type, enabling the same function to operate on different data types.
    </p>

    <h3 style={{paddingBottom:"6px"}}>Key Characteristics:</h3>
    <ul>
      <li>Function templates are defined using the <code>template</code> keyword.</li><br />
      <li>The template parameter is specified using angle brackets (<code>&lt;&gt;</code>).</li><br />
      <li>Can be used to create functions that work with any data type.</li>
    </ul><br />

    <h3>Syntax:</h3>
    <pre>
      <code>
{`template <typename T>
T functionName(T parameter) {
    // Function body
}`}
      </code>
    </pre><br />

    <h3>Example:</h3>
    <p>Here’s an example of a function template that returns the maximum of two values:</p>
    <pre>
      <code>
{`#include <iostream>
using namespace std;

template <typename T>
T getMax(T a, T b) {
    return (a > b) ? a : b;
}

int main() {
    cout << "Max of 10 and 20: " << getMax(10, 20) << endl;         // Works with integers
    cout << "Max of 10.5 and 20.5: " << getMax(10.5, 20.5) << endl; // Works with doubles
    cout << "Max of 'A' and 'B': " << getMax('A', 'B') << endl;    // Works with characters

    return 0;
}`}
      </code>
    </pre><br />

    <h2>Class Templates</h2>

    <p style={{paddingBottom:"6px"}}>
      A <strong>class template</strong> allows you to define a class with a placeholder for the data type. This enables the same class to handle different types without code duplication.
    </p>

    <h3 style={{paddingBottom:"6px"}}>Key Characteristics:</h3>
    <ul>
      <li>Class templates are also defined using the <code>template</code> keyword.</li><br />
      <li>Template parameters are specified using angle brackets (<code>&lt;&gt;</code>).</li><br />
      <li>Can be used to create classes that can store or manipulate data of any type.</li>
    </ul><br />

    <h3>Syntax:</h3>
    <pre>
      <code>
{`template <typename T>
class ClassName {
    // Class definition
};`}
      </code>
    </pre><br />

    <h3>Example:</h3>
    <p>Here’s an example of a class template for a simple stack implementation:</p>
    <pre>
      <code>
{`#include <iostream>
using namespace std;

template <typename T>
class Stack {
    private:
        T* arr;
        int top;
        int capacity;

    public:
        Stack(int size) {
            arr = new T[size];
            capacity = size;
            top = -1;
        }

        void push(T item) {
            if (top == capacity - 1) {
                cout << "Stack overflow!" << endl;
                return;
            }
            arr[++top] = item;
        }

        T pop() {
            if (top == -1) {
                cout << "Stack underflow!" << endl;
                return T(); // Return default value of T
            }
            return arr[top--];
        }

        bool isEmpty() const {
            return top == -1;
        }
};

int main() {
    Stack<int> intStack(5);
    intStack.push(10);
    intStack.push(20);
    cout << "Popped from intStack: " << intStack.pop() << endl;

    Stack<string> stringStack(5);
    stringStack.push("Hello");
    stringStack.push("World");
    cout << "Popped from stringStack: " << stringStack.pop() << endl;

    return 0;
}`}
      </code>
    </pre><br />

    <h2 style={{paddingBottom:"6px"}}>Summary</h2>
    <ul>
      <li><strong>Function Templates</strong>: Allow the creation of generic functions that can operate on different data types, promoting code reusability and reducing redundancy.</li><br />
      <li><strong>Class Templates</strong>: Enable the definition of generic classes, allowing them to handle multiple data types seamlessly.</li>
    </ul><br />

    <p>
      By utilizing templates, you can create versatile and maintainable code that can work with various data types while ensuring type safety and efficiency.
    </p>
  </div>
)}

{selectedChapter === 'chapter35' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Exception Handling (throw, catch, try)</h1>

    <p>
      <strong>Exception Handling</strong> is a mechanism in C++ that allows a program to respond to exceptional circumstances (errors) in a controlled manner. It enables developers to write robust programs that can handle runtime errors without crashing, making the code more maintainable and reliable.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Key Concepts</h2>
    <ol>
      <li>
        <strong>Exception</strong>: An event that disrupts the normal flow of the program's execution, which can occur due to various reasons, such as invalid input, resource unavailability, or hardware failures.
      </li><br />
      <li>
        <strong>Throwing Exceptions</strong>: The <code>throw</code> keyword is used to signal that an exception has occurred. Control is transferred to the nearest matching <code>catch</code> block.
      </li><br />
      <li>
        <strong>Catching Exceptions</strong>: The <code>catch</code> block is used to handle exceptions and is associated with a <code>try</code> block. It executes when an exception is thrown.
      </li><br />
      <li>
        <strong>Try Block</strong>: Contains code that may throw an exception. If an exception occurs, control is transferred to the corresponding <code>catch</code> block.
      </li>
    </ol><br />

    <h2>Syntax</h2>
    <pre>
      <code>
{`try {
    // Code that may throw an exception
} catch (ExceptionType1 e1) {
    // Handle exception of type ExceptionType1
} catch (ExceptionType2 e2) {
    // Handle exception of type ExceptionType2
} catch (...) {
    // Handle any other exceptions
}`}
      </code>
    </pre><br />

    <h2>Example</h2>
    <p>Here’s a simple example demonstrating exception handling in C++:</p>
    <pre>
      <code>
{`#include <iostream>
#include <stdexcept> // for std::runtime_error
using namespace std;

double divide(int numerator, int denominator) {
    if (denominator == 0) {
        throw runtime_error("Division by zero!"); // Throwing an exception
    }
    return static_cast<double>(numerator) / denominator;
}

int main() {
    int num1 = 10;
    int num2 = 0;

    try {
        cout << "Result: " << divide(num1, num2) << endl; // This may throw an exception
    } catch (const runtime_error& e) { // Catching specific exception
        cout << "Error: " << e.what() << endl; // Handling the exception
    } catch (...) { // Catching any other exceptions
        cout << "An unknown error occurred." << endl;
    }

    return 0;}`}
      </code>
    </pre><br />

    <h2 style={{paddingBottom:"6px"}}>Explanation of the Example</h2>
    <ol>
      <li>
        <strong>Function Definition</strong>: The <code>divide</code> function takes two integers, checks if the denominator is zero, and throws a <code>runtime_error</code> if it is.
      </li><br />
      <li>
        <strong>Throwing an Exception</strong>: The <code>throw</code> statement signals that an exceptional situation (division by zero) has occurred.
      </li><br />
      <li>
        <strong>Try Block</strong>: The call to the <code>divide</code> function is placed inside a <code>try</code> block, monitoring for exceptions.
      </li><br />
      <li>
        <strong>Catch Block</strong>: The <code>catch</code> block handles <code>runtime_error</code> exceptions, allowing the program to continue gracefully.
      </li><br />
      <li>
        <strong>Output</strong>: If an exception is thrown, the program outputs an error message instead of crashing.
      </li>
    </ol><br />

    <h2 style={{paddingBottom:"6px"}}>Benefits of Exception Handling</h2>
    <ul>
      <li><strong>Improved Control Flow</strong>: Provides a clear separation between regular code and error-handling code.</li><br />
      <li><strong>Resource Management</strong>: Facilitates resource cleanup (like closing files or releasing memory) using RAII (Resource Acquisition Is Initialization) techniques.</li><br />
      <li><strong>Increased Reliability</strong>: Helps in building robust applications that gracefully handle unexpected situations.</li>
    </ul><br />

    <h2>Summary</h2>
    <p>
      Exception handling in C++ allows developers to manage errors effectively using <code>try</code>, <code>catch</code>, and <code>throw</code>. It promotes cleaner code, reduces the likelihood of program crashes, and enhances overall application robustness. By leveraging exceptions, you can handle runtime errors gracefully and ensure a smoother user experience.
    </p>
  </div>
)}


{selectedChapter === 'chapter36' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Function Object and Lambda Expression</h1>

    <p>
      Here’s a comprehensive definition and explanation of <strong>Function Objects</strong> and <strong>Lambda Expressions</strong> in C++:
    </p><br />

    <h2>Function Objects</h2>

    <p>
      A <strong>function object</strong>, also known as a <strong>functor</strong>, is an instance of a class that can be called as if it were a function. This is achieved by overloading the <code>operator()</code> within the class. Function objects can maintain state and can be more flexible than regular functions since they can have member variables.
    </p><br />

    <h3 style={{paddingBottom:"6px"}}>Key Characteristics:</h3>
    <ul>
      <li>Defined as classes or structs that overload the function call operator <code>operator()</code>.</li><br />
      <li>Can have member variables and methods, allowing them to maintain state between calls.</li><br />
      <li>Often used in algorithms and the Standard Template Library (STL) functions where a callable entity is required.</li>
    </ul><br />

    <h3>Syntax:</h3>
    <pre>
      <code>
{`class Functor {
public:
    // Constructor to initialize state
    Functor(/* parameters */) {
        // Initialization
    }
    
    // Overloading operator()
    ReturnType operator()(/* parameters */) {
        // Function logic
    }
};`}
      </code>
    </pre><br />

    <h3>Example:</h3>
    <p>Here’s an example of a simple function object that adds a fixed value to its input:</p>
    <pre>
      <code>
{`#include <iostream>
using namespace std;

// Functor class
class Adder {
private:
    int addend;

public:
    Adder(int a) : addend(a) {} // Constructor to initialize addend

    // Overloading operator()
    int operator()(int x) const {
        return x + addend;
    }
};

int main() {
    Adder addFive(5); // Creating an instance of Adder with addend 5
    cout << "Adding 10: " << addFive(10) << endl; // Outputs: 15
    return 0;
}`}
      </code>
    </pre><br />

    <h2>Lambda Expressions</h2>

    <p>
      A <strong>lambda expression</strong> is a concise way to define an anonymous function in C++. Lambda expressions can capture variables from the surrounding scope and are often used for short-lived functions, such as those passed to algorithms or for callback functions.
    </p><br />

    <h3 style={{paddingBottom:"6px"}}>Key Characteristics:</h3>
    <ul>
      <li>Can capture variables by value or by reference from their enclosing scope.</li><br />
      <li>Defined using the syntax <code>{`[captures](parameters) -> return_type { body }`}</code>.</li><br />
      <li>Can be used wherever a callable entity is required, like function pointers or functors.</li>
    </ul><br />

    <h3>Syntax:</h3>
    <pre>
      <code>
{`[capture](parameters) -> return_type {
    // Function body
}`}
      </code>
    </pre><br />

    <h3>Example:</h3>
    <p>Here’s an example of a lambda expression that sums two numbers:</p>
    <pre>
      <code>
{`#include <iostream>
using namespace std;

int main() {
    // Lambda expression that adds two numbers
    auto add = [](int x, int y) {
        return x + y;
    };

    cout << "Adding 10 and 20: " << add(10, 20) << endl; // Outputs: 30
    return 0;
}`}
      </code>
    </pre><br />

    <h2 style={{paddingBottom:"6px"}}>Comparison of Function Objects and Lambda Expressions</h2>
    <ul>
      <li><strong>Syntax</strong>: Function objects require defining a class and overloading the <code>operator()</code>, while lambda expressions provide a more concise syntax.</li><br />
      <li><strong>State</strong>: Function objects can maintain state using member variables, while lambda expressions can capture state from the surrounding scope.</li><br />
      <li><strong>Usage</strong>: Both can be used in STL algorithms, but lambda expressions are typically preferred for short-lived and simple functionality due to their brevity.</li>
    </ul><br />

    <h2 style={{paddingBottom:"6px"}}>Summary</h2>
    <ul>
      <li><strong>Function Objects</strong>: These are classes that overload the <code>operator()</code>, allowing instances of the class to be called like functions. They can maintain state and are useful in various programming scenarios.</li><br />
      <li><strong>Lambda Expressions</strong>: These are anonymous functions defined in a concise way, capturing variables from their enclosing scope. They are particularly useful for short, inline functions, especially in STL algorithms.</li>
    </ul><br />

    <p>
      Using function objects and lambda expressions can lead to more readable and maintainable code, allowing for flexibility and powerful functional programming paradigms in C++.
    </p>
  </div>
)}



{selectedChapter === 'chapter37' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Introduction to the STL (Standard Template Library)</h1>

    <h2>What is STL?</h2>
    <p>
      The Standard Template Library (STL) is a powerful set of C++ template classes and functions that provide generic algorithms and data structures. It is part of the C++ Standard Library and is designed to facilitate code reuse and improve efficiency in programming by allowing developers to use pre-defined components for common programming tasks.
    </p><br />

    <h3 style={{paddingBottom:"6px"}}>Key Features of STL</h3>
    <ol>
      <li>
        <strong>Generic Programming:</strong> STL utilizes templates to create generic classes and functions. This means you can write algorithms that work with any data type without needing to write multiple versions of the same code.
      </li><br />
      <li>
        <strong>Containers:</strong> STL provides a variety of container classes that store collections of data. Some common types of containers include:
        <ul>
          <li><strong>Vector:</strong> A dynamic array that can grow in size.</li><br />
          <li><strong>List:</strong> A doubly linked list that allows for efficient insertions and deletions.</li><br />
          <li><strong>Deque:</strong> A double-ended queue that allows insertion and deletion from both ends.</li><br />
          <li><strong>Set:</strong> A collection of unique elements, automatically sorted.</li><br />
          <li><strong>Map:</strong> A collection of key-value pairs, with unique keys.</li>
        </ul>
      </li><br />
      <li>
        <strong style={{paddingBottom:"6px"}}>Algorithms:</strong> STL comes with a rich set of algorithms that can be applied to containers. These algorithms include sorting, searching, and manipulating data. Examples include:
        <ul>
          <li><code>sort()</code>: Sorts the elements in a container.</li><br />
          <li><code>find()</code>: Searches for an element in a container.</li><br />
          <li><code>copy()</code>: Copies elements from one container to another.</li>
        </ul>
      </li><br />
      <li>
        <strong>Iterators:</strong> STL uses iterators to provide a uniform way to access elements in containers. Iterators act as pointers and can be used to traverse through container elements. They abstract the underlying container type, allowing the same algorithm to work with different containers.
      </li><br />
      <li>
        <strong>Efficiency and Performance:</strong> STL is optimized for performance and can often provide better execution times compared to hand-written implementations of common algorithms and data structures.
      </li>
    </ol><br />

    <h3>Example Usage of STL</h3>
    <p>Here is a simple example demonstrating the use of STL in C++ with a vector and an algorithm:</p>

    <pre>
      <code>
{`#include <iostream>
#include <vector>
#include <algorithm> // For sort and for_each

int main() {
    // Create a vector
    std::vector<int> numbers = {4, 1, 3, 2, 5};

    // Sort the vector
    std::sort(numbers.begin(), numbers.end());

    // Print the sorted vector
    std::cout << "Sorted numbers: ";
    std::for_each(numbers.begin(), numbers.end(), [](int n) {
        std::cout << n << " ";
    });
    std::cout << std::endl;

    return 0;
}`}
      </code>
    </pre><br />

    <h3>Conclusion</h3>
    <p>
      The Standard Template Library (STL) is an essential component of C++ programming that provides robust and efficient data structures and algorithms. Its use of generic programming allows developers to write flexible and reusable code, significantly enhancing productivity and maintainability. By leveraging STL, programmers can focus more on solving problems rather than implementing basic data structures and algorithms from scratch.
    </p>
  </div>
)}


{selectedChapter === 'chapter38' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Containers (Vector, List, Deque, Set, Map)</h1>

    <p>
      Here’s a comprehensive definition of containers in C++, focusing on <strong>Vector</strong>, <strong>List</strong>, <strong>Deque</strong>, <strong>Set</strong>, and <strong>Map</strong>.
    </p>

    <h2>Containers in C++</h2>

    <p>
      In C++, containers are data structures that store collections of objects. They are part of the Standard Template Library (STL) and provide a way to manage data efficiently. Containers can be categorized into three main types: sequence containers, associative containers, and unordered containers. Below are the primary container types with their characteristics and use cases.
    </p><br />

    <h3>1. Sequence Containers</h3>

    <p  style={{paddingBottom:"6px"}}>Sequence containers store elements in a specific order and allow access based on the position of the elements.</p>

    <h4 style={{paddingBottom:"6px"}}>Vector</h4>
    <ul>
      <li><strong>Description</strong>: A dynamic array that can resize itself automatically as elements are added or removed.</li><br />
      <li><strong style={{paddingBottom:"6px"}}>Key Characteristics</strong>:
        <ul>
          <li>Provides fast random access to elements.</li><br />
          <li>Slower insertions and deletions compared to lists, especially in the middle.</li><br />
          <li>Contiguous memory allocation.</li>
        </ul>
      </li><br />
      <li><strong>Use Case</strong>: Ideal for situations where random access and resizing are required.</li>
    </ul>
    <pre>
      <code>{`
#include &lt;iostream&gt;
#include &lt;vector&gt;

int main() {{
    std::vector<int> numbers = {{1, 2, 3}}; // Initializing a vector

    numbers.push_back(4); // Adding an element
    std::cout &lt;&lt; "First element: " &lt;&lt; numbers[0] &lt;&lt; std::endl; // Accessing an element

    return 0;
}}
      `}</code>
    </pre><br />

    <h4 style={{paddingBottom:"6px"}}>List</h4>
    <ul>
      <li><strong>Description</strong>: A doubly linked list that allows efficient insertions and deletions from any part of the list.</li><br />
      <li><strong style={{paddingBottom:"6px"}}>Key Characteristics</strong>:
        <ul>
          <li>Does not provide random access.</li><br />
          <li>Each element (node) contains pointers to the next and previous nodes.</li>
        </ul>
      </li><br />
      <li><strong>Use Case</strong>: Suitable for applications that require frequent insertion and deletion of elements.</li>
    </ul>
    <pre>
      <code>{`
#include &lt;iostream&gt;
#include &lt;list&gt;

int main() {{
    std::list<int> numbers = {{1, 2, 3}}; // Initializing a list

    numbers.push_back(4); // Adding an element at the end
    numbers.push_front(0); // Adding an element at the front

    for (int num : numbers) {{
        std::cout &lt;&lt; num &lt;&lt; " "; // Output: 0 1 2 3 4
    }}

    return 0;
}}
       `} </code>
    </pre><br />

    <h4 style={{paddingBottom:"6px"}}>Deque (Double-ended Queue)</h4>
    <ul>
      <li><strong>Description</strong>: A container that allows insertion and deletion of elements from both ends efficiently.</li><br />
      <li><strong style={{paddingBottom:"6px"}}>Key Characteristics</strong>:
        <ul>
          <li>Provides random access like vectors.</li><br />
          <li>More efficient than vectors for certain operations (like insertions at the front).</li>
        </ul>
      </li><br />
      <li><strong>Use Case</strong>: Useful in scenarios where both ends of the container need to be manipulated frequently.</li>
    </ul>
    <pre>
      <code>{`
#include &lt;iostream&gt;
#include &lt;deque&gt;

int main() {{
    std::deque<int> numbers = {{1, 2, 3}}; // Initializing a deque

    numbers.push_back(4); // Adding an element at the end
    numbers.push_front(0); // Adding an element at the front

    for (int num : numbers) {{
        std::cout &lt;&lt; num &lt;&lt; " "; // Output: 0 1 2 3 4
    }}

    return 0;
}}
       `} </code>
    </pre><br />

    <h3>2. Associative Containers</h3>

    <p style={{paddingBottom:"6px"}}>Associative containers store elements based on keys, allowing for fast retrieval.</p>

    <h4 style={{paddingBottom:"6px"}}>Set</h4>
    <ul>
      <li><strong>Description</strong>: A collection of unique elements that are automatically sorted.</li><br />
      <li><strong style={{paddingBottom:"6px"}}>Key Characteristics</strong>:
        <ul>
          <li>Duplicate elements are not allowed.</li><br />
          <li>Elements are stored in a specific order based on their value.</li>
        </ul>
      </li><br />
      <li><strong>Use Case</strong>: Ideal for situations where you need to maintain a collection of unique items.</li>
    </ul>
    <pre>
      <code>{`
#include &lt;iostream&gt;
#include &lt;set&gt;

int main() {{
    std::set<int> numbers = {{3, 1, 4, 1}}; // Duplicates are ignored

    numbers.insert(2); // Adding an element
    for (int num : numbers) {{
        std::cout &lt;&lt; num &lt;&lt; " "; // Output: 1 2 3 4
    }}

    return 0;
}}
  `}  </code>
    </pre><br />

    <h4 style={{paddingBottom:"6px"}}>Map</h4>
    <ul>
      <li><strong>Description</strong>: A collection of key-value pairs, where each key is unique.</li><br />
      <li><strong style={{paddingBottom:"6px"}}>Key Characteristics</strong>:
        <ul>
          <li>Provides fast retrieval based on keys.</li><br />
          <li>Keys are automatically sorted.</li>
        </ul>
      </li><br />
      <li><strong>Use Case</strong>: Useful when you need to associate values with unique keys.</li>
    </ul>
    <pre>
      <code>{`
#include &lt;iostream&gt;
#include &lt;map&gt;

int main() {{
    std::map<std::string, int> ageMap; // Initializing a map

    ageMap["Alice"] = 30; // Adding key-value pairs
    ageMap["Bob"] = 25;

    for (const auto& pair : ageMap) {{
        std::cout &lt;&lt; pair.first &lt;&lt; ": " &lt;&lt; pair.second &lt;&lt; std::endl; // Output: Alice: 30, Bob: 25
    }}

    return 0;
}}
      `}</code>
    </pre><br />

    <h2>Conclusion</h2>

    <p>
      C++ provides a rich set of container types that are optimized for various tasks. Understanding the characteristics of each container allows developers to choose the right one for their specific needs, leading to more efficient and maintainable code. The STL containers significantly simplify common data structure implementations, allowing developers to focus on higher-level programming challenges.
    </p>
  </div>
)}



  {selectedChapter === 'chapter39' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Iterators</h1>

    <p>
      In C++, <strong>iterators</strong> are a powerful abstraction that allows for the traversal and manipulation of elements within various container types in the Standard Template Library (STL). They act as a bridge between algorithms and containers, providing a unified interface for accessing elements without exposing the underlying details of the container's structure.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Key Characteristics of Iterators:</h2>

    <ol>
      <li>
        <strong>Generalized Pointers</strong>: Iterators behave like pointers and can be incremented (to move to the next element), dereferenced (to access the value of the element), and compared (to check for equality).
      </li><br />
      <li>
        <strong>Types of Iterators</strong>: There are several types of iterators in C++, each providing different capabilities:
        <ul><br />
          <li><strong>Input Iterator</strong>: Allows reading elements from a container sequentially. Can only be used once per element.</li><br />
          <li><strong>Output Iterator</strong>: Allows writing elements to a container sequentially. Can only be used once per element.</li><br />
          <li><strong>Forward Iterator</strong>: Combines the capabilities of input and output iterators and can read and write elements. It can be used multiple times per element.</li><br />
          <li><strong>Bidirectional Iterator</strong>: Allows traversal in both directions (forward and backward). Supports incrementing and decrementing.</li><br />
          <li><strong>Random Access Iterator</strong>: Provides the ability to access elements at arbitrary positions, similar to array indexing. Supports all operations of bidirectional iterators, plus arithmetic operations for jumping multiple positions.</li>
        </ul>
      </li><br />
      <li>
        <strong>Iterator Categories</strong>: Based on their functionality, iterators are categorized into:
        <ul><br />
          <li><strong>Simple Iterators</strong>: Includes input and output iterators.</li><br />
          <li><strong>Sequential Iterators</strong>: Includes forward and bidirectional iterators.</li><br />
          <li><strong>Random Access Iterators</strong>: Includes iterators for containers like <code>vector</code>, <code>deque</code>, and <code>array</code>.</li>
        </ul>
      </li><br />
      <li>
        <strong>Usage</strong>: Iterators are widely used with STL algorithms to manipulate container elements without the need to know the underlying data structure. This enhances code reusability and flexibility.
      </li>
    </ol><br />

    <h2>Example of Using Iterators:</h2>

    <p>Here's a simple example demonstrating the use of iterators with a vector:</p>

    <pre>
      <code>{`
#include &lt;iostream&gt;
#include &lt;vector&gt;

int main() {
    std::vector&lt;int&gt; numbers = {1, 2, 3, 4, 5};

    // Using an iterator to traverse the vector
    std::vector&lt;int&gt;::iterator it;
    for (it = numbers.begin(); it != numbers.end(); ++it) {
        std::cout &lt;&lt; *it &lt;&lt; " "; // Dereferencing the iterator to access the element
    }
    std::cout &lt;&lt; std::endl;

    return 0;
}
       `} </code>
    </pre><br />

    <h2>Conclusion:</h2>

    <p>
      Iterators are a fundamental part of C++ and the STL, providing a consistent way to traverse and manipulate elements in various container types. Their design allows for greater abstraction and code reuse, making C++ a powerful language for generic programming. Understanding how to use iterators effectively is essential for working with the STL and writing efficient C++ code.
    </p>
  </div>
)}

{selectedChapter === 'chapter40' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Algorithms (Sorting, Searching, etc.)</h1>

    <p>
      In C++, <strong>algorithms</strong> are a key part of the Standard Template Library (STL), providing a set of
      functions for performing common operations like sorting, searching, and manipulating data. These algorithms are
      designed to work with STL containers such as vectors, lists, sets, and maps, using iterators to access and modify
      elements.
    </p><br />
    
    <p style={{paddingBottom:"6px"}}>Here’s an overview of commonly used algorithms, focusing on <strong>sorting</strong>, <strong>searching</strong>, and other key operations.</p> 


    <h2>1. <strong>Sorting Algorithms</strong></h2>

    <p style={{paddingBottom:"6px"}}>Sorting is one of the most frequently used operations in programming. The STL provides the <code>sort()</code> function to sort the elements of a container in increasing or decreasing order.</p>

    <h3 style={{paddingBottom:"6px"}}><code>sort()</code> Function</h3>
    <ul>
      <li><strong>Description</strong>: Sorts the elements in a range (from the beginning to the end) in ascending order by default. You can also provide a custom comparator to sort in descending order or using custom rules.</li><br />
      <li><strong>Time Complexity</strong>: <code>O(n log n)</code> (where <code>n</code> is the number of elements in the container).</li><br />
      <li><strong>Use Case</strong>: Sorting data in ascending or descending order.</li>
    </ul><br />

    <pre>
      <code>
{`#include <iostream>
#include <vector>
#include <algorithm> // For sort()

int main() {
    std::vector<int> numbers = {4, 2, 5, 1, 3};

    // Sort in ascending order
    std::sort(numbers.begin(), numbers.end());

    // Sort in descending order
    std::sort(numbers.begin(), numbers.end(), std::greater<int>());

    for (int num : numbers) {
        std::cout << num << " "; // Output: 5 4 3 2 1
    }

    return 0;
}`}
      </code>
    </pre><br />

    <h3 style={{paddingBottom:"6px"}}>Other Sorting Algorithms:</h3>
    <ul>
      <li><strong>stable_sort()</strong>: Preserves the relative order of equal elements while sorting.</li><br />
      <li><strong>partial_sort()</strong>: Partially sorts a range, so that a subset of elements are sorted.</li>
    </ul><br />
 

    <h2>2. <strong>Searching Algorithms</strong></h2>

    <p style={{paddingBottom:"6px"}}>The STL provides several algorithms for searching elements within containers. These algorithms include both linear and binary search techniques.</p>

    <h3 style={{paddingBottom:"6px"}}><code>find()</code> Function</h3>
    <ul>
      <li><strong>Description</strong>: Performs a linear search in the container, returning an iterator to the first element that matches the given value. If the element is not found, it returns <code>end()</code>.</li><br />
      <li><strong>Time Complexity</strong>: <code>O(n)</code> (linear search).</li><br />
      <li><strong>Use Case</strong>: Searching for a specific value in unsorted data.</li>
    </ul><br />

    <pre>
      <code>
{`#include <iostream>
#include <vector>
#include <algorithm> // For find()

int main() {
    std::vector<int> numbers = {1, 2, 3, 4, 5};

    // Find the element '3'
    auto it = std::find(numbers.begin(), numbers.end(), 3);

    if (it != numbers.end()) {
        std::cout << "Element found: " << *it << std::endl;
    } else {
        std::cout << "Element not found." << std::endl;
    }

    return 0;
}`}
      </code>
    </pre><br />

    <h3 style={{paddingBottom:"6px"}}><code>binary_search()</code> Function</h3>
    <ul>
      <li><strong>Description</strong>: Performs a binary search on a sorted container. It returns <code>true</code> if the element is found, and <code>false</code> otherwise.</li><br />
      <li><strong>Time Complexity</strong>: <code>O(log n)</code> (binary search).</li><br />
      <li><strong>Use Case</strong>: Searching in sorted data for faster performance.</li>
    </ul><br />

    <pre>
      <code>
{`#include <iostream>
#include <vector>
#include <algorithm> // For sort() and binary_search()

int main() {
    std::vector<int> numbers = {1, 2, 3, 4, 5};

    // Check if '3' is present in the sorted vector
    bool found = std::binary_search(numbers.begin(), numbers.end(), 3);

    std::cout << (found ? "Element found." : "Element not found.") << std::endl;

    return 0;
}`}
      </code>
    </pre><br />

    <h3 style={{paddingBottom:"6px"}}>Other Searching Algorithms:</h3>
    <ul>
      <li><strong>find_if()</strong>: Finds the first element that satisfies a predicate.</li><br />
      <li><strong>lower_bound()</strong>: Finds the first position where an element can be inserted without violating the order.</li><br />
      <li><strong>upper_bound()</strong>: Finds the first position where an element can be inserted after the last occurrence of an equivalent element.</li>
    </ul><br />


    <h2>3. <strong>Min/Max and Other Utility Algorithms</strong></h2>

    <p style={{paddingBottom:"6px"}}>In addition to sorting and searching, the STL provides several other useful algorithms for working with containers.</p>

    <h3><code>min_element()</code> and <code>max_element()</code></h3>
    <ul><br />
      <li><strong>Description</strong>: Finds the smallest (<code>min_element()</code>) or largest (<code>max_element()</code>) element in a range.</li><br />
      <li><strong>Time Complexity</strong>: <code>O(n)</code>.</li><br />
      <li><strong>Use Case</strong>: Finding the minimum or maximum value in a collection.</li>
    </ul><br />

    <pre>
      <code>
{`#include <iostream>
#include <vector>
#include <algorithm> // For min_element() and max_element()

int main() {
    std::vector<int> numbers = {4, 2, 5, 1, 3};

    // Find the minimum and maximum elements
    auto minIt = std::min_element(numbers.begin(), numbers.end());
    auto maxIt = std::max_element(numbers.begin(), numbers.end());

    std::cout << "Min: " << *minIt << ", Max: " << *maxIt << std::endl;

    return 0;
}`}
      </code>
    </pre><br />

    <h3 style={{paddingBottom:"6px"}}><code>count()</code></h3>
    <ul>
      <li><strong>Description</strong>: Counts the number of occurrences of a given value in a range.</li><br />
      <li><strong>Time Complexity</strong>: <code>O(n)</code>.</li>
    </ul><br />

    <pre>
      <code>
{`#include <iostream>
#include <vector>
#include <algorithm> // For count()

int main() {
    std::vector<int> numbers = {1, 2, 2, 3, 4, 2};

    // Count occurrences of '2'
    int countOfTwos = std::count(numbers.begin(), numbers.end(), 2);

    std::cout << "Count of 2: " << countOfTwos << std::endl;

    return 0;
}`}
      </code>
    </pre><br />

    <h3 style={{paddingBottom:"6px"}}><code>reverse()</code></h3>
    <ul>
      <li><strong>Description</strong>: Reverses the elements in a range.</li><br />
      <li><strong>Time Complexity</strong>: <code>O(n)</code>.</li>
    </ul><br />

    <pre>
      <code>
{`#include <iostream>
#include <vector>
#include <algorithm> // For reverse()

int main() {
    std::vector<int> numbers = {1, 2, 3, 4, 5};

    // Reverse the vector
    std::reverse(numbers.begin(), numbers.end());

    for (int num : numbers) {
        std::cout << num << " "; // Output: 5 4 3 2 1
    }

    return 0;
}`}
      </code>
    </pre>

   <br />

    <h3>Conclusion</h3>
    <p>
      The STL in C++ offers a wide range of algorithms that simplify common operations such as <strong>sorting</strong>, <strong>searching</strong>, and <strong>manipulating</strong> data. By leveraging these algorithms, developers can write cleaner, more efficient code without having to implement these functionalities from scratch. Whether it’s finding an element, sorting a container, or counting occurrences, STL algorithms provide a robust set of tools for everyday programming tasks.
    </p>
  </div>
)}



{selectedChapter === 'chapter41' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Functors</h1>

    <p>
      In C++, <strong>functors</strong> (or <strong>function objects</strong>) are objects that can be used as if
      they were functions. A functor is any object that implements the <code>operator()</code>, which allows it to
      be invoked like a function. This concept is particularly useful when working with the Standard Template
      Library (STL) algorithms that expect a callable object (such as <code>sort</code>, <code>for_each</code>, etc.).
    </p><br />

    <h2>Why Use Functors?</h2>
    <p>Functors provide more flexibility compared to regular functions because:</p>
    <ol>
      <li>They can hold state.</li><br />
      <li>They can be more easily reused and passed as parameters.</li><br />
      <li>You can overload other operators and member functions in functors, adding more functionality.</li>
    </ol><br />

    <h2>How to Create a Functor</h2>
    <p>
      To create a functor, you define a class or struct that overloads the <code>operator()</code> method. Here’s an
      example:
    </p><br />

    <pre>
      <code>
        {`#include <iostream>
#include <vector>
#include <algorithm>

// Define a functor (function object)
class MultiplyBy {
    int factor; // State

public:
    // Constructor to initialize the factor
    MultiplyBy(int factor) : factor(factor) {}

    // Overloading operator() to make this object callable
    int operator()(int value) const {
        return value * factor;
    }
};

int main() {
    std::vector<int> numbers = {1, 2, 3, 4, 5};

    // Creating a functor object that multiplies by 2
    MultiplyBy multiplyByTwo(2);

    // Using the functor with the for_each algorithm
    std::for_each(numbers.begin(), numbers.end(), [&](int& num) {
        num = multiplyByTwo(num);  // Applying the functor
    });

    // Print the modified vector
    for (int num : numbers) {
        std::cout << num << " ";  // Output: 2 4 6 8 10
    }

    return 0;
}`}
      </code>
    </pre><br />

    <h2>Explanation:</h2>
    <ul>
      <li>The functor <code>MultiplyBy</code> holds an internal state (<code>factor</code>).</li><br />
      <li>
        The <code>operator()</code> is overloaded to perform the multiplication whenever the object is called like a
        function.
      </li><br />
      <li>
        In the <code>main</code> function, the functor is used in conjunction with <code>std::for_each</code>, which
        applies the functor to each element in the vector.
      </li><br />
    </ul>

    <h2 style={{paddingBottom:"6px"}}>Benefits of Functors:</h2>
    <ol>
      <li>
        <strong>State</strong>: Unlike regular functions, functors can maintain state. In the example above, the{" "}
        <code>MultiplyBy</code> functor stores the value of <code>factor</code> and applies it whenever called.
      </li><br />
      <li>
        <strong>Reusability</strong>: Functors can be easily reused in different parts of your program with different
        internal states.
      </li><br />
      <li>
        <strong>Flexibility</strong>: You can create highly flexible and configurable behavior by defining complex
        logic inside functors.
      </li>
    </ol><br />

    <h2>Functors vs Lambdas</h2>
    <p>
      In C++11 and later, <strong>lambda expressions</strong> often serve a similar role to functors. Lambdas provide
      a more concise syntax for small, simple operations, while functors are better for more complex operations or
      when state needs to be maintained over multiple calls.
    </p><br />

    <p>Here’s an equivalent example using a lambda:</p>

    <pre>
      <code>
        {`#include <iostream>
#include <vector>
#include <algorithm>

int main() {
    std::vector<int> numbers = {1, 2, 3, 4, 5};
    int factor = 2;

    // Using a lambda to multiply each element by 2
    std::for_each(numbers.begin(), numbers.end(), [factor](int& num) {
        num *= factor;
    });

    // Print the modified vector
    for (int num : numbers) {
        std::cout << num << " ";  // Output: 2 4 6 8 10
    }

    return 0;
}`}
      </code>
    </pre><br />

    <h2 style={{paddingBottom:"6px"}}>Summary:</h2>
    <ul>
      <li>
        <strong>Functors</strong> are objects that behave like functions by overloading the <code>operator()</code>.
      </li><br />
      <li>
        They are useful when you need function-like behavior with additional flexibility, such as maintaining state.
      </li><br />
      <li>
        Functors provide a powerful mechanism in C++ for working with STL algorithms, where more complex behaviors are
        needed than what is achievable with simple function pointers.
      </li>
    </ul><br />
  </div>
)}


{selectedChapter === 'chapter42' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>File Input and Output (ifstream, ofstream)</h1>

    <p>
      In C++, <strong>file input and output (I/O)</strong> operations are handled using the 
      <code>&lt;fstream&gt;</code> library, which provides mechanisms for reading from and writing to files. 
      This library includes three primary classes:
    </p><br />

    <ul>
      <li><strong>ifstream</strong>: Used for reading data from files (input stream).</li><br />
      <li><strong>ofstream</strong>: Used for writing data to files (output stream).</li><br />
      <li><strong>fstream</strong>: Used for both reading and writing data (input/output stream).</li>
    </ul><br />

    <h3>Steps to Handle File I/O in C++</h3>
    <ol>
      <li>Include the <code>&lt;fstream&gt;</code> header:
        <pre>
          <code>#include &lt;fstream&gt;</code>
        </pre>
      </li><br />
      <li>Create an object of one of the file stream classes (<code>ifstream</code>, <code>ofstream</code>, or <code>fstream</code>).</li><br />
      <li>Open a file using the <code>open()</code> method or directly during object creation.</li><br />
      <li>Perform file operations like reading, writing, or appending data.</li><br />
      <li>Close the file using the <code>close()</code> method when you are done.</li>
    </ol>
<br />
    <h3>Writing to a File (<code>ofstream</code>)</h3>
    <p>
      To write data to a file, you can use the <code>ofstream</code> class. If the file does not exist, it will be created. If it already exists, its content will be overwritten (unless you open it in append mode).
    </p>

    <h4>Example:</h4>
    <pre>
      <code>
{`#include <iostream>
#include <fstream>

int main() {
    std::ofstream outfile("example.txt");
    
    if (!outfile) {
        std::cerr << "Error: Could not open the file for writing." << std::endl;
        return 1;
    }

    outfile << "Hello, World!" << std::endl;
    outfile << "This is a file output example in C++." << std::endl;

    outfile.close();
    std::cout << "Data has been written to the file." << std::endl;
    return 0;
}`}
      </code>
    </pre>

    <p><strong>Explanation</strong>: This program opens a file named <code>example.txt</code> and writes two lines of text into it. If the file doesn’t exist, it will be created.</p>

  <br />

    <h3>Reading from a File (<code>ifstream</code>)</h3>
    <p>To read data from a file, use the <code>ifstream</code> class. The file must already exist, or the attempt to open it will fail.</p>

    <h4>Example:</h4>
    <pre>
      <code>
{`#include <iostream>
#include <fstream>
#include <string>

int main() {
    std::ifstream infile("example.txt");

    if (!infile) {
        std::cerr << "Error: Could not open the file for reading." << std::endl;
        return 1;
    }

    std::string line;
    while (std::getline(infile, line)) {
        std::cout << line << std::endl;
    }

    infile.close();
    return 0;
}`}
      </code>
    </pre>

    <p><strong>Explanation</strong>: This program opens an existing file named <code>example.txt</code> and reads its content line by line, printing each line to the console.</p>

   <br />

    <h3>Reading and Writing with <code>fstream</code></h3>
    <p>The <code>fstream</code> class allows for both reading and writing in a single file stream. You can specify whether the file is opened for reading, writing, or both.</p>

    <h4>Example:</h4>
    <pre>
      <code>
{`#include <iostream>
#include <fstream>

int main() {
    std::fstream file("example.txt", std::ios::in | std::ios::out | std::ios::app);

    if (!file) {
        std::cerr << "Error: Could not open the file." << std::endl;
        return 1;
    }

    file << "Appending new line to the file." << std::endl;
    file.seekg(0);

    std::string line;
    while (std::getline(file, line)) {
        std::cout << line << std::endl;
    }

    file.close();
    return 0;
}`}
      </code>
    </pre>

    <p><strong>Explanation</strong>: This program opens the file in both read and write mode. It appends a new line to the file, then reads and displays the entire content.</p>

<br />

    <h3>File Modes</h3>
    <p>When opening a file, you can specify the mode of operation using flags:</p>
    <ul>
      <li><code>std::ios::in</code>: Open for reading.</li><br />
      <li><code>std::ios::out</code>: Open for writing.</li><br />
      <li><code>std::ios::app</code>: Append to the end of the file.</li><br />
      <li><code>std::ios::binary</code>: Open in binary mode.</li><br />
      <li><code>std::ios::trunc</code>: If the file already exists, its content is truncated before opening.</li>
    </ul><br />

    <h4>Example:</h4>
    <pre>
      <code>{`std::ofstream file("example.txt", std::ios::out | std::ios::app); // Open for writing, append mode`}</code>
    </pre>

    <br />

    <h3>Checking File Status</h3>
    <p>You can check the status of file operations using various member functions:</p>
    <ul>
      <li><code>file.is_open()</code>: Checks if the file is successfully opened.</li><br />
      <li><code>file.good()</code>: Checks if no errors have occurred during I/O operations.</li><br />
      <li><code>file.eof()</code>: Checks if the end of the file has been reached.</li>
    </ul>

   <br />

    <h3>Conclusion</h3>
    <p>
      File I/O in C++ is simple yet powerful, providing mechanisms to read from and write to files using streams. By leveraging classes like 
      <code>ifstream</code>, <code>ofstream</code>, and <code>fstream</code>, you can handle files efficiently while benefiting from the flexibility of different file modes and error-checking mechanisms.
    </p>
  </div>
)}
{selectedChapter === 'chapter43' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Reading and Writing Text and Binary Files</h1>

    <p>
      In C++, reading and writing files can be done in two primary modes: <strong>text mode</strong> and <strong>binary mode</strong>. Each mode has specific use cases depending on the type of data you need to process.
    </p><br />

    <h3>1. Text Files</h3>
    <p>
      Text files store data as plain, human-readable characters. They are typically used for reading and writing textual data such as configuration files, logs, or documents.
    </p><br />

    <h4>Reading and Writing Text Files</h4>
    <p>
      In text mode, C++ uses the <code>ifstream</code> and <code>ofstream</code> classes to read and write text files, respectively.
    </p><br />

    <h5>Example of Writing to a Text File:</h5>
    <pre>
      <code>
{`
#include <iostream>
#include <fstream>

int main() {
    // Open a file for writing in text mode
    std::ofstream outFile("textfile.txt");

    // Check if the file was successfully opened
    if (!outFile) {
        std::cerr << "Error: Could not open the file for writing." << std::endl;
        return 1;
    }

    // Write data to the file
    outFile << "This is a line of text." << std::endl;
    outFile << "C++ file I/O is simple." << std::endl;

    // Close the file
    outFile.close();

    return 0;
}
`}
      </code>
    </pre><br />

    <h5>Example of Reading from a Text File:</h5>
    <pre>
      <code>
{`
#include <iostream>
#include <fstream>
#include <string>

int main() {
    // Open a file for reading in text mode
    std::ifstream inFile("textfile.txt");

    // Check if the file was successfully opened
    if (!inFile) {
        std::cerr << "Error: Could not open the file for reading." << std::endl;
        return 1;
    }

    // Read data from the file line by line
    std::string line;
    while (std::getline(inFile, line)) {
        std::cout << line << std::endl;
    }

    // Close the file
    inFile.close();

    return 0;
}
`}
      </code>
    </pre><br />

    <h3>2. Binary Files</h3>
    <p>
      Binary files store data in a binary format (i.e., as a sequence of bytes), making them more efficient for storing non-text data like images, audio files, or custom data structures. Data written to binary files is not human-readable.
    </p><br />

    <h4>Reading and Writing Binary Files</h4>
    <p>
      To work with binary files, you use the <code>ifstream</code> and <code>ofstream</code> classes in <strong>binary mode</strong>, specifying the <code>std::ios::binary</code> flag when opening the file.
    </p><br />

    <h5>Example of Writing to a Binary File:</h5>
    <pre>
      <code>
{`
#include <iostream>
#include <fstream>

int main() {
    // Open a file for writing in binary mode
    std::ofstream outFile("binaryfile.bin", std::ios::binary);

    // Check if the file was successfully opened
    if (!outFile) {
        std::cerr << "Error: Could not open the file for writing." << std::endl;
        return 1;
    }

    // Example data to write
    int numbers[] = {1, 2, 3, 4, 5};

    // Write the array to the file as binary data
    outFile.write(reinterpret_cast<char*>(numbers), sizeof(numbers));

    // Close the file
    outFile.close();

    return 0;
}
`}
      </code>
    </pre><br />

    <h5>Example of Reading from a Binary File:</h5>
    <pre>
      <code>
{`
#include <iostream>
#include <fstream>

int main() {
    // Open a file for reading in binary mode
    std::ifstream inFile("binaryfile.bin", std::ios::binary);

    // Check if the file was successfully opened
    if (!inFile) {
        std::cerr << "Error: Could not open the file for reading." << std::endl;
        return 1;
    }

    // Array to hold the data
    int numbers[5];

    // Read the data from the file into the array
    inFile.read(reinterpret_cast<char*>(numbers), sizeof(numbers));

    // Close the file
    inFile.close();

    // Output the data
    for (int num : numbers) {
        std::cout << num << " ";
    }
    std::cout << std::endl;

    return 0;
}
`}
      </code>
    </pre><br />

    <h4 style={{paddingBottom:"6px"}}>Key Differences Between Text and Binary Modes:</h4>
    <ul>
      <li><strong>Text Mode</strong>: The data is processed line by line and may involve character translations (e.g., newline characters are converted differently across platforms).</li><br />
      <li><strong>Binary Mode</strong>: The data is processed exactly as stored, byte by byte, without any translations or conversions.</li>
    </ul><br />

    <h4>Opening Files with Different Modes:</h4>
    <p>
      To specify different file modes in C++, use the <code>std::ios</code> flags:
    </p>
    <ul>
      <li><code>std::ios::in</code>: Open for reading.</li><br />
      <li><code>std::ios::out</code>: Open for writing.</li><br />
      <li><code>std::ios::binary</code>: Open in binary mode.</li><br />
      <li><code>std::ios::app</code>: Open in append mode.</li><br />
      <li><code>std::ios::trunc</code>: Truncate the file if it exists.</li>
    </ul><br />

    <p><strong>Example:</strong></p>
    <pre>
      <code>
{`
std::ofstream file("example.txt", std::ios::out | std::ios::binary);
`}
      </code>
    </pre><br />

    <h4>Conclusion:</h4>
    <p>
      C++ provides flexible mechanisms for reading and writing both text and binary files. Text files are ideal for human-readable data, while binary files are more suitable for raw data and structures that need to be stored efficiently without any conversion.
    </p>
  </div>
)}

{selectedChapter === 'chapter44' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>File Streams and Error Handling</h1>

    <p>
      In C++, file streams provide a way to read from and write to files using the input/output stream classes defined in the <code>&lt;fstream&gt;</code> header. These classes facilitate file operations while handling various types of file errors that may arise during file manipulation.
    </p><br />

    <h2>1. File Streams</h2>
    <p>
      File streams in C++ are implemented through three primary classes:
    </p>
    <ul>
      <li><strong><code>ifstream</code></strong> (Input File Stream): Used to read data from files.</li><br />
      <li><strong><code>ofstream</code></strong> (Output File Stream): Used to write data to files.</li><br />
      <li><strong><code>fstream</code></strong> (File Stream): Used for both reading from and writing to files.</li>
    </ul><br />

    <h3>Example of Using File Streams</h3>
    <p>To use file streams, you typically follow these steps:</p>
    <ol>
      <li><strong>Include the <code>&lt;fstream&gt;</code> header:</strong>
        <pre><code>#include &lt;fstream&gt;</code></pre>
      </li><br />
      <li><strong>Create a file stream object:</strong>
        <pre><code>
std::ifstream infile("input.txt"); // for reading
std::ofstream outfile("output.txt"); // for writing
        </code></pre>
      </li><br />
      <li><strong>Open a file:</strong> The file is opened automatically upon creating an <code>ifstream</code> or <code>ofstream</code> object with the file name. You can also use the <code>open()</code> method explicitly.</li><br />
      <li><strong>Perform file operations:</strong> Read from or write to the file using the stream operators (<code>&lt;&lt;</code> for <code>ofstream</code> and <code>&gt;&gt;</code> for <code>ifstream</code>).</li><br />
      <li><strong>Close the file:</strong> Use the <code>close()</code> method to release the file resources when done.</li>
    </ol><br />

    <h2>2. Error Handling</h2>
    <p>
      Error handling is crucial when working with file streams, as various issues can occur, such as the file not being found, lacking permissions, or running out of disk space. C++ provides several mechanisms to check for errors in file operations.
    </p><br />

    <h3>Common Error Handling Methods</h3>
    <p>After attempting to open a file or performing I/O operations, you can check the stream's state using various member functions:</p>
    <ul>
      <li><strong><code>is_open()</code></strong>: Checks if the file is successfully opened.</li><br />
      <li><strong><code>good()</code></strong>: Checks if the stream is in a good state (no errors).</li><br />
      <li><strong><code>eof()</code></strong>: Checks if the end of the file has been reached.</li><br />
      <li><strong><code>fail()</code></strong>: Checks if a logical error occurred on the stream.</li><br />
      <li><strong><code>bad()</code></strong>: Checks if a serious error occurred, such as a loss of integrity.</li>
    </ul><br />

    <h3>Example of Error Handling with File Streams</h3>
    <p>Here’s an example demonstrating error handling while working with file streams:</p>
    <pre><code>{`
    #include &lt;iostream&gt;
#include &lt;fstream&gt;

int main() {
    // Open a file for writing
    std::ofstream outfile("output.txt");

    // Check if the file was opened successfully
    if (!outfile.is_open()) {
        std::cerr &lt;&lt; "Error: Could not open the file for writing." &lt;&lt; std::endl;
        return 1; // Exit with an error code
    }

    // Write data to the file
    outfile &lt;&lt; "Hello, World!" &lt;&lt; std::endl;

    // Check for any write errors
    if (outfile.fail()) {
        std::cerr &lt;&lt; "Error: Failed to write to the file." &lt;&lt; std::endl;
        outfile.close(); // Close the file if there's an error
        return 1; // Exit with an error code
    }

    // Close the file
    outfile.close();

    // Open a file for reading
    std::ifstream infile("output.txt");

    // Check if the file was opened successfully
    if (!infile.is_open()) {
        std::cerr &lt;&lt; "Error: Could not open the file for reading." &lt;&lt; std::endl;
        return 1; // Exit with an error code
    }

    // Read data from the file
    std::string line;
    while (std::getline(infile, line)) {
        std::cout &lt;&lt; line &lt;&lt; std::endl; // Output the line to the console
    }

    // Check for any read errors
    if (infile.bad()) {
        std::cerr &lt;&lt; "Error: Failed to read from the file." &lt;&lt; std::endl;
    }

    // Close the file
    infile.close();

    return 0; // Successful completion
}
     `} </code></pre><br />

    <h2>3. Conclusion</h2>
    <p>
      File streams in C++ offer a flexible way to handle file input and output operations. Proper error handling ensures that your program can respond appropriately to various file-related issues, improving robustness and user experience. By utilizing methods to check the stream state, you can effectively manage errors that may occur during file operations.
    </p>
  </div>
)}



{selectedChapter === 'chapter45' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Static vs. Dynamic Memory</h1>
    <p>
      Memory management in C++ is a crucial aspect of programming, as it directly impacts performance and resource utilization. In C++, memory can be categorized into two main types: <strong>static memory</strong> and <strong>dynamic memory</strong>.
    </p><br />

    <h3>1. Static Memory Allocation</h3>

    <p>
      Static memory allocation occurs when memory is allocated at compile time. The size and location of the variables are determined before the program runs, and the allocated memory remains fixed throughout the program's execution.
    </p><br />

    <h4>Characteristics:</h4>
    <ul>
      <li><strong>Lifetime:</strong> The memory remains allocated for the entire duration of the program and is automatically deallocated when the program exits.</li><br />
      <li><strong>Scope:</strong> The scope is determined by the location of the variable declaration; it can be local (inside a function) or global (outside any function).</li><br />
      <li><strong>Speed:</strong> Static memory allocation is generally faster than dynamic allocation since it does not involve runtime overhead.</li><br />
      <li><strong>Size Limitation:</strong> The size of statically allocated memory must be known at compile time and cannot change during execution.</li>
    </ul><br />

    <h4>Example:</h4>
    <pre>
      <code>{`
        #include &lt;iostream&gt;

        int main() {{
            int staticArray[5]; // Static allocation of an array of size 5

            // Initializing the static array
            for (int i = 0; i &lt; 5; ++i) {{
                staticArray[i] = i + 1;
            }}

            // Output the elements of the static array
            for (int i = 0; i &lt; 5; ++i) {{
                std::cout &lt;&lt; staticArray[i] &lt;&lt; " ";
            }}
            std::cout &lt;&lt; std::endl;

            return 0;
        }}
      `}</code>
    </pre><br />

    <h3>2. Dynamic Memory Allocation</h3>

    <p>
      Dynamic memory allocation occurs at runtime using operators such as <code>new</code> and <code>delete</code>. This allows for the allocation of memory as needed, and the size can be determined during execution.
    </p><br />

    <h4 style={{paddingBottom:"6px"}}>Characteristics:</h4>
    <ul>
      <li><strong>Lifetime:</strong> The allocated memory remains available until explicitly deallocated using <code>delete</code>. If not deallocated, it can lead to memory leaks.</li><br />
      <li><strong>Flexibility:</strong> The size of dynamically allocated memory can be determined at runtime, allowing for more flexible data structures (like linked lists, trees, etc.).</li><br />
      <li><strong>Overhead:</strong> Dynamic memory allocation typically has some runtime overhead due to the need to manage the memory (allocation, deallocation).</li><br />
      <li><strong>Fragmentation:</strong> Over time, dynamic memory allocation can lead to fragmentation, as memory can be allocated and deallocated in varying sizes.</li>
    </ul><br />

    <h4>Example:</h4>
    <pre>
      <code>{`
        #include &lt;iostream&gt;

        int main() {{
            int* dynamicArray = new int[5]; // Dynamic allocation of an array of size 5

            // Initializing the dynamic array
            for (int i = 0; i &lt; 5; ++i) {{
                dynamicArray[i] = i + 1;
            }}

            // Output the elements of the dynamic array
            for (int i = 0; i &lt; 5; ++i) {{
                std::cout &lt;&lt; dynamicArray[i] &lt;&lt; " ";
            }}
            std::cout &lt;&lt; std::endl;

            delete[] dynamicArray; // Deallocate the dynamic array

            return 0;
        }}
       `} </code>
    </pre><br />

    <h3>Key Differences</h3>
    <table>
      <thead>
        <tr>
          <th>Feature</th>
          <th>Static Memory</th>
          <th>Dynamic Memory</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><strong>Allocation Time</strong></td>
          <td>Compile time</td>
          <td>Runtime</td>
        </tr>
        <tr>
          <td><strong>Lifetime</strong></td>
          <td>Until the program ends</td>
          <td>Until explicitly deallocated</td>
        </tr>
        <tr>
          <td><strong>Flexibility</strong></td>
          <td>Fixed size</td>
          <td>Variable size</td>
        </tr>
        <tr>
          <td><strong>Speed</strong></td>
          <td>Generally faster</td>
          <td>Slower due to management overhead</td>
        </tr>
        <tr>
          <td><strong>Memory Management</strong></td>
          <td>Automatic (no need for manual deallocation)</td>
          <td>Manual (requires <code>new</code> and <code>delete</code>)</td>
        </tr>
        <tr>
          <td><strong>Scope</strong></td>
          <td>Determined by declaration location</td>
          <td>Can be used anywhere if pointers are managed correctly</td>
        </tr>
      </tbody>
    </table><br />

    <h3>Conclusion</h3>
    <p>
      Understanding the differences between static and dynamic memory allocation in C++ is essential for effective memory management in programming. Static memory is useful for fixed-size data, while dynamic memory offers the flexibility needed for data structures that can change in size during runtime. Properly managing memory, especially dynamic memory, is crucial to avoid memory leaks and ensure optimal program performance.
    </p>
  </div>
)}


{selectedChapter === 'chapter46' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Memory Leaks and Debugging</h1>
   
    <p>
      Memory management is a critical aspect of C++ programming, where developers have the responsibility to allocate and deallocate memory manually. Failure to properly manage memory can lead to memory leaks, which can significantly affect application performance and reliability.
    </p><br />

    <h3>1. Memory Leaks</h3>

    <p>
      A <strong>memory leak</strong> occurs when a program allocates memory dynamically using <code>new</code> (or similar allocation methods) but fails to release it using <code>delete</code> (or the appropriate deallocation function). Over time, memory leaks can consume a significant portion of the available memory, leading to performance degradation and eventually causing the program to crash due to memory exhaustion.
    </p><br />

    <h4 style={{paddingBottom:"6px"}}>Characteristics of Memory Leaks:</h4>
    <ul>
      <li><strong>Invisible Loss</strong>: The memory is allocated but becomes inaccessible because the pointer to it is lost, making it impossible to free.</li><br />
      <li><strong>Cumulative Effect</strong>: If memory leaks occur repeatedly during program execution, the cumulative effect can lead to increased memory usage, resulting in slower performance or application failure.</li><br />
      <li><strong>Difficult to Detect</strong>: Memory leaks can be subtle and may not manifest until much later in the program's execution, making them hard to debug.</li>
    </ul><br />

    <h4>Example of a Memory Leak:</h4>
    <pre>
      <code>{`
#include &lt;iostream&gt;

void createMemoryLeak() {{
    int* leak = new int[10]; // Dynamically allocated array
    // No corresponding delete[] call, leading to a memory leak
}

int main() {{
    for (int i = 0; i &lt; 100; ++i) {{
        createMemoryLeak(); // Each call leaks memory
    }}
    return 0; // Memory not released
}
      `}</code>
    </pre><br />

    <h3>2. Debugging Memory Leaks</h3>

    <p>
      Debugging memory leaks in C++ requires a combination of techniques and tools. Here are some methods to identify and resolve memory leaks:
    </p>

    <h4 style={{paddingBottom:"6px"}}>a. Manual Code Review</h4>
    <ul>
      <li>Inspecting the code for every <code>new</code> allocation to ensure a corresponding <code>delete</code> is present.</li><br />
      <li>Keeping track of all dynamic memory allocations to avoid losing pointers.</li>
    </ul><br />

    <h4>b. Use of Smart Pointers</h4>
    <p>
      Smart pointers (like <code>std::unique_ptr</code>, <code>std::shared_ptr</code>, and <code>std::weak_ptr</code>) automatically manage memory, significantly reducing the risk of memory leaks by ensuring that memory is released when no longer needed.
    </p><br />

    <h4 style={{paddingBottom:"6px"}}>c. Debugging Tools</h4>
    <ul>
      <li><strong>Valgrind</strong>: A popular tool for memory debugging that can detect memory leaks, show memory usage, and identify improper memory access.</li><br />
      <li><strong>AddressSanitizer</strong>: A built-in tool in many compilers (like GCC and Clang) that helps detect memory corruption and leaks during runtime.</li>
    </ul><br />

    <h4>Example of Using Smart Pointers to Prevent Memory Leaks:</h4>
    <pre>
      <code>{`
#include &lt;iostream&gt;
#include &lt;memory&gt;

void createSmartPointer() {{
    std::unique_ptr&lt;int[]&gt; smartArray(new int[10]); // Automatically managed
    // No manual delete required; memory is freed when smartArray goes out of scope
}

int main() {{
    for (int i = 0; i &lt; 100; ++i) {{
        createSmartPointer(); // No memory leak
    }}
    return 0; // Memory is automatically released
}
       `} </code>
    </pre><br />

    <h3>3. Conclusion</h3>
    <p>
      Memory leaks pose a significant challenge in C++ programming, as they can lead to resource exhaustion and degraded performance. By practicing diligent memory management techniques, such as using smart pointers and leveraging debugging tools, developers can effectively mitigate memory leaks and ensure robust application performance. Debugging memory leaks requires a proactive approach to memory allocation and deallocation, making it an essential skill for any C++ programmer.
    </p>
  </div>
)}

{selectedChapter === 'chapter47' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Resource Acquisition Is Initialization (RAII)</h1>

    <p>
      **Resource Acquisition Is Initialization (RAII)** is a programming idiom widely used in C++ to manage resource allocation and deallocation effectively. The concept revolves around tying the lifecycle of a resource (like memory, file handles, sockets, etc.) to the lifespan of an object. In other words, resources are acquired during the object's construction and released during its destruction. This approach helps ensure that resources are properly cleaned up, preventing leaks and ensuring exception safety.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Key Features of RAII</h2>

    <ol>
      <li>
        <strong>Automatic Resource Management:</strong>
        <p>
          Resources are allocated when an object is created (in the constructor) and released when the object goes out of scope (in the destructor). This automatic management reduces the chances of resource leaks and makes code easier to maintain.
        </p>
      </li><br />
      <li>
        <strong>Exception Safety:</strong>
        <p>
          Since destructors are called automatically when an object goes out of scope, RAII ensures that resources are cleaned up even if an exception occurs. This makes it easier to write robust code that handles errors gracefully.
        </p>
      </li><br />
      <li>
        <strong>Ownership Semantics:</strong>
        <p>
          Objects that manage resources explicitly convey ownership. When the object is destroyed, it automatically releases its resources, avoiding the need for manual cleanup.
        </p>
      </li>
    </ol><br />

    <h2>Example of RAII</h2>

    <p>Here’s a simple example demonstrating RAII with a class that manages dynamic memory allocation:</p>

    <pre>
      <code>{`
        #include &lt;iostream&gt;

        class Resource {{
        private:
            int* data; // Pointer to dynamically allocated memory

        public:
            // Constructor
            Resource(size_t size) {{
                data = new int[size]; // Resource acquisition
                std::cout &lt;&lt; "Resource acquired." &lt;&lt; std::endl;
            }}

            // Destructor
            ~Resource() {{
                delete[] data; // Resource release
                std::cout &lt;&lt; "Resource released." &lt;&lt; std::endl;
            }}

            // Other member functions
            void doSomething() {{
                std::cout &lt;&lt; "Doing something with the resource." &lt;&lt; std::endl;
            }}
        }};

        int main() {{
            {{
                Resource res(10); // Resource is acquired here
                res.doSomething(); // Use the resource
                // Resource will be released automatically when 'res' goes out of scope
            }} // Destructor is called here

            return 0;
        }}
       `} </code>
    </pre><br />

    <h2>Explanation of the Example</h2>

    <p>
      In this example, the <code>Resource</code> class manages a dynamically allocated array. The constructor allocates memory, while the destructor releases it. When an instance of <code>Resource</code> is created in the <code>main</code> function, memory is allocated. When the instance goes out of scope (at the end of the block), the destructor is automatically called, releasing the memory. This guarantees that the resource is properly managed, preventing memory leaks.
    </p><br />

    <h2>Conclusion</h2>

    <p>
      RAII is a powerful idiom in C++ that simplifies resource management, enhances exception safety, and makes code more maintainable. By encapsulating resource management within classes, RAII ensures that resources are acquired and released automatically, reducing the burden on the programmer and improving code reliability.
    </p>
  </div>
)}


{selectedChapter === 'chapter48' && (
    <div className={style.chaptercontent}>
        <h1 className={style.heading}>Introduction to Threads</h1>

        <p>
            <strong>Threads</strong> are a fundamental concept in concurrent programming, allowing multiple sequences of instructions to execute simultaneously within a single program. In C++, threads enable developers to create applications that can perform multiple tasks at once, improving performance and responsiveness.
        </p><br />

        <h3>What is a Thread?</h3>

        <p>
            A <strong>thread</strong> is the smallest unit of processing that can be scheduled by an operating system. It represents a single sequence of instructions that can run independently of other threads. Threads within the same process share the same memory space, which allows for efficient communication but also introduces challenges such as data consistency and synchronization.
        </p><br />

        <h3 style={{paddingBottom:"6px"}}>Why Use Threads?</h3>
        <ul>
            <li>
                <strong>Improved Performance:</strong> Threads can help take advantage of multi-core processors, allowing for parallel execution of tasks. This can significantly improve the performance of CPU-bound applications.
            </li><br />
            <li>
                <strong>Responsiveness:</strong> In applications with user interfaces, threads can keep the UI responsive while performing time-consuming operations in the background.
            </li><br />
            <li>
                <strong>Resource Sharing:</strong> Threads within the same process share memory and resources, which makes communication between them easier compared to processes, which have separate memory spaces.
            </li>
        </ul><br />

        <h3 style={{paddingBottom:"6px"}}>Key Concepts</h3>

        <h4>1. Thread Creation</h4>
        <p>
            In C++, threads can be created using the <code>&lt;thread&gt;</code> header. You can create a thread by instantiating a <code>std::thread</code> object and passing a function or callable object to it.
        </p>
        <pre>
            <code>{`
#include &lt;iostream&gt;
#include &lt;thread&gt;

void task() {
    std::cout &lt;&lt; "Thread is running!" &lt;&lt; std::endl;
}

int main() {
    std::thread t(task); // Create a thread
    t.join(); // Wait for the thread to finish
    return 0;
}
 `} </code>
        </pre><br />

        <h4>2. Joining Threads</h4>
        <p>
            The <code>join()</code> method is used to wait for a thread to complete its execution. This is important to ensure that the main thread does not exit before the child threads have finished running.
        </p><br />

        <h4>3. Detaching Threads</h4>
        <p>
            A thread can also be detached using the <code>detach()</code> method, allowing it to run independently. Once detached, you cannot join the thread or check its status.
        </p><br />

        <h4>4. Thread Safety</h4>
        <p>
            Since multiple threads can access shared data, ensuring thread safety is crucial. This can be achieved using synchronization mechanisms like mutexes, condition variables, and locks.
        </p><br />

        <h4>5. Synchronization</h4>
        <p>
            Synchronization techniques are used to control the access of multiple threads to shared resources, preventing data races and ensuring consistency.
        </p><br />
        <pre>
            <code>{`
#include &lt;iostream&gt;
#include &lt;thread&gt;
#include &lt;mutex&gt;

std::mutex mtx; // Mutex for critical section

void safePrint(int id) {
    mtx.lock(); // Lock the mutex
    std::cout &lt;&lt; "Thread " &lt;&lt; id &lt;&lt; " is running." &lt;&lt; std::endl;
    mtx.unlock(); // Unlock the mutex
}

int main() {
    std::thread t1(safePrint, 1);
    std::thread t2(safePrint, 2);
    t1.join();
    t2.join();
    return 0;
}
             `} </code>
        </pre><br />

        <h3>Conclusion</h3>
        <p>
            Threads are a powerful feature in C++ that allow for concurrent programming and can significantly enhance the performance and responsiveness of applications. Understanding how to create, manage, and synchronize threads is essential for developing robust and efficient multi-threaded applications. By using threads effectively, developers can build applications that leverage the full capabilities of modern multi-core processors.
        </p>
    </div>
)}


{selectedChapter === 'chapter49' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Creating and Managing Threads</h1>
    <p>
      Threads are essential for developing concurrent applications, allowing multiple tasks to run simultaneously within a single program. In C++, the Standard Library provides robust support for creating and managing threads, primarily through the <code>&lt;thread&gt;</code> header.
    </p><br />

    <h3>1. Creating Threads</h3>

    <p>To create a thread in C++, you typically follow these steps:</p>
    <ul>
      <li><strong>Include the &lt;thread&gt; header</strong>: This header provides the necessary classes and functions to work with threads.</li><br />
      <li><strong>Define a Function</strong>: This function contains the code that will execute in the new thread.</li><br />
      <li><strong>Instantiate a <code>std::thread</code> object</strong>: Pass the function (and any required arguments) to the constructor of the <code>std::thread</code> class.</li>
    </ul><br />

    <h4>Example of Creating a Thread:</h4>
    <pre>
      <code>{`
#include &lt;iostream&gt;
#include &lt;thread&gt;

void task() {{
    std::cout &lt;&lt; "Thread is running!" &lt;&lt; std::endl;
}

int main() {{
    std::thread t(task); // Create a thread
    t.join(); // Wait for the thread to finish
    return 0;
}}
       `} </code>
    </pre>
    
    <p>
      In this example, a thread is created to execute the <code>task</code> function. The <code>join()</code> method is called to ensure the main thread waits for the created thread to finish before exiting.
    </p><br />

    <h3>2. Managing Threads</h3>

    <p>Managing threads involves various operations to ensure their lifecycle and interaction. Here are the key concepts:</p>
    <ul>
      <li><strong>Joining Threads</strong>: When you call the <code>join()</code> method on a thread object, the calling thread (usually the main thread) waits for the thread to finish execution. This is essential to avoid terminating the main program while child threads are still running.</li><br />
      <li><strong>Detaching Threads</strong>: If you don’t want to wait for a thread to finish, you can call the <code>detach()</code> method. This allows the thread to run independently. However, once a thread is detached, you cannot join it or check its status.</li><br />
      <li><strong>Thread Safety</strong>: When multiple threads access shared data, you must ensure that data integrity is maintained. This can be achieved using synchronization mechanisms like mutexes, locks, and condition variables.</li><br />
      <li><strong>Thread Lifetime</strong>: A thread’s lifetime is determined by the scope of the <code>std::thread</code> object. When the thread object goes out of scope, if the thread has not been joined or detached, the program will terminate with a runtime error.</li>
    </ul><br />

    <h4>Example of Joining and Detaching Threads:</h4>
    <pre>
      <code>{`
#include &lt;iostream&gt;
#include &lt;thread&gt;
#include &lt;chrono&gt;

void printMessage(int id) {{
    std::this_thread::sleep_for(std::chrono::seconds(1)); // Simulate work
    std::cout &lt;&lt; "Thread " &lt;&lt; id &lt;&lt; " finished." &lt;&lt; std::endl;
}

int main() {{
    std::thread t1(printMessage, 1);
    std::thread t2(printMessage, 2);

    t1.join(); // Wait for t1 to finish
    t2.detach(); // t2 will run independently

    // t2 is now detached; we cannot join it again.
    std::cout &lt;&lt; "Main thread continues..." &lt;&lt; std::endl;

    return 0;
}}
      `}</code>
    </pre><br />

    <h3>3. Conclusion</h3>

    <p>
      Creating and managing threads in C++ is a powerful way to develop responsive and efficient applications. The ability to execute multiple tasks concurrently can significantly improve performance, especially in CPU-bound and I/O-bound programs. However, with this power comes the responsibility to manage thread lifecycles and ensure thread safety, making understanding these concepts crucial for any C++ developer.
    </p>

  </div>
)}


{selectedChapter === 'chapter50' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Mutexes and Locks</h1>

    <p>
      In concurrent programming, managing access to shared resources is crucial to prevent data races and ensure data integrity. C++ provides several mechanisms for synchronization, including mutexes (mutual exclusion objects) and locks.
    </p><br />

    <h3>1. Mutexes</h3>

    <p>
      A <strong>mutex</strong> (short for mutual exclusion) is a synchronization primitive that allows multiple threads to safely access shared resources. A mutex can be in one of two states: locked or unlocked. When a thread locks a mutex, other threads that attempt to lock it will be blocked until the mutex is unlocked.
    </p><br />

    <h4 style={{paddingBottom:"6px"}}>Key Characteristics of Mutexes:</h4>
    <ul>
      <li><strong>Exclusivity:</strong> Only one thread can hold a mutex at a time, ensuring exclusive access to the resource.</li><br />
      <li><strong>Blocking:</strong> If a thread tries to lock a mutex that is already locked, it will be blocked until the mutex is unlocked.</li><br />
      <li><strong>Deadlock Prevention:</strong> Careful design is required to prevent deadlocks, where two or more threads are blocked indefinitely, each waiting for the other to release a mutex.</li>
    </ul><br />

    <h4>Example of Using a Mutex:</h4>
    <pre>
      <code>{`
#include &lt;iostream&gt;
#include &lt;thread&gt;
#include &lt;mutex&gt;

std::mutex mtx; // Declare a mutex

void safePrint(int id) {
    mtx.lock(); // Lock the mutex
    std::cout &lt;&lt; "Thread " &lt;&lt; id &lt;&lt; " is accessing the resource." &lt;&lt; std::endl;
    mtx.unlock(); // Unlock the mutex
}

int main() {
    std::thread t1(safePrint, 1);
    std::thread t2(safePrint, 2);

    t1.join(); // Wait for t1 to finish
    t2.join(); // Wait for t2 to finish

    return 0;
}
       `} </code>
    </pre>
    <p>
      In this example, the <code>safePrint</code> function locks a mutex before accessing shared resources, ensuring that only one thread can execute the critical section at a time.
    </p><br />

    <h3>2. Locks</h3>

    <p>
      Locks are higher-level abstractions that provide a more convenient and safer interface for working with mutexes. The C++ Standard Library provides several lock types, such as <code>std::unique_lock</code> and <code>std::lock_guard</code>.
    </p><br />

    <h4 style={{paddingBottom:"6px"}}>Key Benefits of Using Locks:</h4>
    <ul>
      <li><strong>Automatic Lock Management:</strong> Locks can automatically manage the locking and unlocking of mutexes, ensuring that a mutex is released even if an exception occurs or the function exits prematurely.</li><br />
      <li><strong>RAII (Resource Acquisition Is Initialization):</strong> Locks are typically implemented using RAII, which ties the lifespan of the lock to the scope of the object.</li>
    </ul><br />

    <h4>Example of Using <code>std::lock_guard</code>:</h4>
    <pre>
      <code>{`
#include &lt;iostream&gt;
#include &lt;thread&gt;
#include &lt;mutex&gt;

std::mutex mtx; // Declare a mutex

void safePrint(int id) {
    std::lock_guard&lt;std::mutex&gt; lock(mtx); // Automatically locks the mutex
    std::cout &lt;&lt; "Thread " &lt;&lt; id &lt;&lt; " is accessing the resource." &lt;&lt; std::endl;
    // The mutex will be automatically unlocked when 'lock' goes out of scope
}

int main() {
    std::thread t1(safePrint, 1);
    std::thread t2(safePrint, 2);

    t1.join(); // Wait for t1 to finish
    t2.join(); // Wait for t2 to finish

    return 0;
}
       `} </code>
    </pre>
    <p>
      In this example, <code>std::lock_guard</code> automatically locks the mutex upon creation and unlocks it when it goes out of scope, simplifying the management of the mutex and reducing the risk of deadlocks or resource leaks.
    </p><br />

    <h3>Conclusion</h3>
    <p>
      Mutexes and locks are essential tools for managing concurrency in C++. By using these synchronization mechanisms, developers can safely share resources among multiple threads, ensuring data integrity and preventing race conditions. Understanding how to effectively use mutexes and locks is crucial for building robust and reliable multi-threaded applications.
    </p>
  </div>
)}

{selectedChapter === 'chapter51' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Condition Variables</h1>

    <p>
      Condition variables are a powerful synchronization primitive in C++ that allows threads to communicate about the state of shared resources. They are primarily used to block a thread until a particular condition is met, enabling threads to wait for certain conditions to occur before proceeding with execution. Here’s a detailed definition of condition variables in C++:
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Condition Variables in C++</h2>

    <h3>Overview</h3>
    <p>
      A <strong>condition variable</strong> is used in conjunction with a mutex (mutual exclusion) to allow threads to wait for certain conditions to be true. It helps in synchronizing the execution of threads that need to wait for a specific condition before proceeding. This is especially useful in producer-consumer scenarios, where one or more threads produce data that other threads consume.
    </p><br />

    <h3 style={{paddingBottom:"6px"}}>Key Concepts</h3>
    <ul>
      <li>
        <strong>Waiting on a Condition Variable:</strong> A thread can wait on a condition variable. When it does, it releases the associated mutex and goes into a waiting state until another thread notifies it that the condition it is waiting for has occurred.
      </li><br />
      <li>
        <strong>Notifying Threads:</strong> Other threads can notify the waiting threads that the condition has changed using <code>notify_one()</code> or <code>notify_all()</code> methods. 
        <ul>
          <li><code>notify_one()</code>: Wakes up one thread waiting on the condition variable.</li><br />
          <li><code>notify_all()</code>: Wakes up all threads waiting on the condition variable.</li>
        </ul>
      </li><br />
      <li>
        <strong>Mutex Association:</strong> Condition variables must always be used with a mutex to protect the condition being waited on. This ensures that the condition check and state modification are performed atomically.
      </li>
    </ul><br />

    <h3>Example of Using Condition Variables</h3>
    <p>
      Here’s a simple example demonstrating how to use condition variables in a producer-consumer scenario:
    </p>

    <pre>
      <code>{`
#include &lt;iostream&gt;
#include &lt;thread&gt;
#include &lt;mutex&gt;
#include &lt;condition_variable&gt;
#include &lt;queue&gt;

std::mutex mtx;                          // Mutex for critical section
std::condition_variable cv;               // Condition variable
std::queue<int> dataQueue;               // Shared queue
const int maxQueueSize = 10;             // Maximum queue size

// Producer function
void producer() {
    for (int i = 0; i &lt; 20; ++i) {
        std::unique_lock<std::mutex> lock(mtx);
        cv.wait(lock, [] { return dataQueue.size() &lt; maxQueueSize; }); // Wait until queue has space

        dataQueue.push(i);                // Produce an item
        std::cout &lt;&lt; "Produced: " &lt;&lt; i &lt;&lt; std::endl;

        lock.unlock();
        cv.notify_one();                  // Notify one waiting consumer
    }
}

// Consumer function
void consumer() {
    for (int i = 0; i &lt; 20; ++i) {
        std::unique_lock<std::mutex> lock(mtx);
        cv.wait(lock, [] { return !dataQueue.empty(); }); // Wait until there is data to consume

        int data = dataQueue.front();     // Consume an item
        dataQueue.pop();
        std::cout &lt;&lt; "Consumed: " &lt;&lt; data &lt;&lt; std::endl;

        lock.unlock();
        cv.notify_one();                  // Notify one waiting producer
    }
}

int main() {
    std::thread prodThread(producer);
    std::thread consThread(consumer);

    prodThread.join();
    consThread.join();

    return 0;
}
      `}</code>
    </pre>

    <h3>Explanation of the Example</h3>
    <ul>
      <li>
        <strong>Producer Function:</strong> The producer adds items to the shared queue. It waits on the condition variable until there is space in the queue (i.e., the queue size is less than the maximum allowed).
      </li><br />
      <li>
        <strong>Consumer Function:</strong> The consumer removes items from the queue. It waits on the condition variable until there is at least one item to consume in the queue.
      </li><br />
      <li>
        <strong>Mutex and Condition Variable:</strong> Both the producer and consumer functions use a <code>std::unique_lock</code> to manage the mutex and call the <code>wait()</code> method on the condition variable. This allows the thread to block until the specified condition is met while automatically releasing the mutex during the wait.
      </li><br />
      <li>
        <strong>Notifications:</strong> After producing or consuming an item, the respective thread notifies the other (using <code>notify_one()</code>) that it has changed the state of the shared resource.
      </li>
    </ul><br />

    <h3>Conclusion</h3>
    <p>
      Condition variables are essential for managing thread synchronization in C++. They allow threads to wait for specific conditions to be met without busy-waiting, making them a more efficient alternative to simple mutex locks in certain scenarios. Understanding how to effectively use condition variables is crucial for building robust multi-threaded applications.
    </p>
  </div>
)}

{selectedChapter === 'chapter52' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Thread Safety and Data Races</h1>


    <h3>Thread Safety</h3>
    <p>
      <strong>Thread safety</strong> is a property of a piece of code that guarantees safe execution by multiple threads at the same time. When a function or class is thread-safe, it ensures that shared data is accessed and modified correctly, without causing inconsistencies or unexpected behavior.
    </p>

    <p>In C++, achieving thread safety typically involves:</p>
    <ol>
      <li>
        <strong>Synchronization Mechanisms</strong>: Using constructs like mutexes, locks, condition variables, and atomic operations to control access to shared resources. These mechanisms prevent race conditions and ensure that only one thread can modify shared data at any given time.
      </li><br />
      <li>
        <strong>Immutable Data</strong>: Designing data structures that do not change state after they are created. Immutable objects can be safely shared among threads without additional synchronization.
      </li><br />
      <li>
        <strong>Thread-local Storage</strong>: Utilizing thread-local storage to keep data unique to each thread, thereby avoiding the need for synchronization when threads do not share certain data.
      </li>
    </ol><br />

    <h4 style={{paddingBottom:"6px"}}>Key Characteristics of Thread Safety:</h4>
    <ul>
      <li><strong>Consistency</strong>: Thread-safe code ensures that shared resources maintain a consistent state across all threads.</li><br />
      <li><strong>Isolation</strong>: Proper synchronization guarantees that one thread’s operations do not interfere with another’s.</li><br />
      <li><strong>Performance</strong>: While synchronization ensures safety, excessive locking can lead to performance bottlenecks. Thus, finding the right balance between safety and efficiency is crucial.</li>
    </ul><br />

    <h4>Example of Thread Safety</h4>
    <p>Here’s an example of a thread-safe counter using a mutex:</p>
    <pre>
      <code>
        {`#include <iostream>
#include <thread>
#include <mutex>

class ThreadSafeCounter {
private:
    int count;
    std::mutex mtx; // Mutex for protecting the shared counter

public:
    ThreadSafeCounter() : count(0) {}

    void increment() {
        std::lock_guard<std::mutex> lock(mtx); // Automatically locks the mutex
        ++count; // Increment the counter
    }

    int getCount() {
        std::lock_guard<std::mutex> lock(mtx); // Automatically locks the mutex
        return count; // Return the current count
    }
};

void incrementCounter(ThreadSafeCounter &counter) {
    for (int i = 0; i < 1000; ++i) {
        counter.increment();
    }
}

int main() {
    ThreadSafeCounter counter;
    std::thread t1(incrementCounter, std::ref(counter));
    std::thread t2(incrementCounter, std::ref(counter));

    t1.join();
    t2.join();

    std::cout << "Final count: " << counter.getCount() << std::endl;
    return 0;`}
      </code>
    </pre><br />

    <h3>Data Races</h3>
    <p>
      A <strong>data race</strong> occurs in a multi-threaded program when two or more threads access shared data concurrently, and at least one of the accesses is a write operation. This can lead to unpredictable behavior, as the outcome of the operations depends on the timing of thread execution.
    </p><br />

    <h4 style={{paddingBottom:"6px"}}>Conditions for Data Races:</h4>
    <ol>
      <li><strong>Concurrent Access</strong>: Multiple threads access the same variable or data structure simultaneously.</li><br />
      <li><strong>At Least One Write</strong>: At least one of the accesses is a write operation that modifies the data.</li><br />
      <li><strong>No Synchronization</strong>: There is no proper synchronization mechanism (like mutexes or locks) in place to control access to the shared data.</li>
    </ol><br />

    <h4 style={{paddingBottom:"6px"}}>Consequences of Data Races:</h4>
    <ul>
      <li><strong>Undefined Behavior</strong>: Data races can lead to inconsistent or unexpected results, including crashes or corrupted data.</li><br />
      <li><strong>Difficult to Debug</strong>: Since the outcome of operations can vary with different runs, data races are often hard to reproduce and debug.</li>
    </ul><br />

    <h4>Example of Data Races</h4>
    <p>Here’s an example that illustrates a data race:</p>
    <pre>
      <code>
        {`#include <iostream>
#include <thread>

int sharedCounter = 0; // Shared resource

void incrementCounter() {
    for (int i = 0; i < 1000; ++i) {
        ++sharedCounter; // Increment the shared counter
    }
}

int main() {
    std::thread t1(incrementCounter);
    std::thread t2(incrementCounter);

    t1.join();
    t2.join();

    std::cout << "Final count: " << sharedCounter << std::endl; // May not be 2000
    return 0;`}
      </code>
    </pre>
    <p>
      In this example, both threads modify <code>sharedCounter</code> without any synchronization, leading to a data race. The final output may not be what you expect, as the increment operations can overlap.
    </p><br />

    <h3>Conclusion</h3>
    <p>
      Understanding thread safety and data races is crucial for developing reliable multi-threaded applications in C++. Employing proper synchronization mechanisms helps prevent data races and ensures that shared resources are accessed safely, maintaining the integrity of the program. As you work with concurrency, always consider the potential for data races and take steps to mitigate their risks.
    </p>
  </div>
)}
{selectedChapter === 'chapter53' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>C++ 11/14/17 Features (auto, range-based for, nullptr, etc.)</h1>

    <p style={{paddingBottom:"6px"}}>Here's a comprehensive overview of features introduced in C++11, C++14, and C++17, including <strong>auto</strong>, <strong>range-based for</strong>, <strong>nullptr</strong>, and more.</p>

    <h2>C++11 Features</h2>
    <p>C++11 introduced several features aimed at improving performance, readability, and usability. Here are some of the most significant features:</p>

    <ol>
      <li>
        <strong>Auto Keyword:</strong>
        <p>Automatically deduces the type of a variable from its initializer.</p>
        <pre><code>{`auto x = 42;      // x is an int
auto y = 3.14;    // y is a double
auto z = "Hello"; // z is a const char*`}</code></pre>
      </li><br />

      <li>
        <strong>Range-based For Loop:</strong>
        <p>Simplifies iteration over collections (like arrays and containers).</p>
        <pre><code>{`std::vector&lt;int&gt; vec = {1, 2, 3, 4, 5};
for (auto i : vec) {
    std::cout &lt;&lt; i &lt;&lt; " "; // Output: 1 2 3 4 5
        }`}</code></pre>
      </li><br />

      <li>
        <strong>nullptr:</strong>
        <p>A type-safe null pointer constant that replaces the old <code>NULL</code> macro.</p>
        <pre><code>{`int* ptr = nullptr; // ptr is a null pointer`}</code></pre>
      </li><br />

      <li>
        <strong>Lambda Expressions:</strong>
        <p>Allows defining anonymous functions directly within the code.</p>
        <pre><code>{`auto add = [](int a, int b) { return a + b; };
std::cout &lt;&lt; add(3, 4); // Output: 7`}</code></pre>
      </li><br />

      <li>
        <strong>Smart Pointers:</strong>
        <p>Introduced <code>{`std::unique_ptr`}</code> and <code>{`std::shared_ptr`}</code> for better memory management.</p>
        <pre><code>{`std::unique_ptr&lt;int&gt; p1(new int(5));
std::shared_ptr&lt;int&gt; p2 = std::make_shared&lt;int&gt;(10);`}</code></pre>
      </li><br />

      <li>
        <strong>Move Semantics:</strong>
        <p>Optimizes resource management by allowing the resources of temporary objects to be moved instead of copied.</p>
        <pre><code>{`std::vector&lt;int&gt; v1 = {1, 2, 3};
std::vector&lt;int&gt; v2 = std::move(v1); // Move resources from v1 to v2`}</code></pre>
      </li><br />

      <li>
        <strong>Static Assertions:</strong>
        <p>Compile-time assertions that check conditions.</p>
        <pre><code>{`static_assert(sizeof(int) == 4, "Integers are not 4 bytes!");`}</code></pre>
      </li><br />

      <li>
        <strong>Thread Support:</strong>
        <p>Introduced the <code>&lt;thread&gt;</code> library for creating and managing threads.</p>
        <pre><code>{`std::thread t([] { std::cout &lt;&lt; "Hello from thread!" &lt;&lt; std::endl; });
t.join();`}</code></pre>
      </li>
    </ol><br />

    <h2>C++14 Features</h2>
    <p>C++14 built upon the features of C++11, adding several enhancements:</p>

    <ol>
      <li>
        <strong>Generic Lambdas:</strong>
        <p>Lambda expressions can use <code>auto</code> in their parameter lists, allowing for type inference.</p>
        <pre><code>{`auto genericLambda = [](auto x, auto y) { return x + y; };`}</code></pre>
      </li><br />

      <li>
        <strong>Return Type Deduction:</strong>
        <p>Functions can automatically deduce their return type using the <code>auto</code> keyword.</p>
        <pre><code>{`auto func() -&gt; int {
    return 42;
}`}</code></pre>
      </li><br />

      <li>
        <strong>Binary Literals:</strong>
        <p>Allows the definition of integers using binary notation.</p>
        <pre><code>{`int binaryValue = 0b1010; // 10 in decimal`}</code></pre>
      </li><br />

      <li>
        <strong>Digit Separators:</strong>
        <p>The apostrophe (<code>'</code>) can be used as a digit separator for better readability of numeric literals.</p>
        <pre><code>{`int million = 1'000'000; // Readable representation of one million`}</code></pre>
      </li><br />

      <li>
        <strong>Variable Templates:</strong>
        <p>Allows the definition of templates that can be instantiated with non-type template parameters.</p>
        <pre>
          <code>{`template &lt;typename T&gt;
constexpr T pi = T(3.14159);
`}</code></pre>
      </li>
    </ol><br />

    <h2>C++17 Features</h2>
    <p>C++17 introduced additional features that further enhance the language:</p>

    <ol>
      <li>
        <strong>If and Switch with Initializers:</strong>
        <p>Allows variable initialization in the <code>if</code> and <code>switch</code> statements.</p>
        <pre>
          <code>{`if (int x = getValue(); x &gt; 0) {
    std::cout &lt;&lt; "Positive: " &lt;&lt; x &lt;&lt; std::endl;
    }
`}</code></pre>
      </li><br />

      <li>
        <strong>Structured Bindings:</strong>
        <p>A way to unpack tuple-like objects into individual variables.</p>
        <pre><code>{`std::tuple&lt;int, double, std::string&gt; person = {25, 5.9, "John"};
auto [age, height, name] = person;`}</code></pre>
      </li><br />

      <li>
        <strong>std::optional:</strong>
        <p>Represents an optional value that may or may not be present.</p>
        <pre><code>{`std::optional&lt;int&gt; maybeValue;
maybeValue = 10; // now it has a value`}</code></pre>
      </li><br />

      <li>
        <strong>std::variant:</strong>
        <p>A type-safe union that can hold one of several types.</p>
        <pre><code>{`std::variant&lt;int, float, std::string&gt; var;
var = "Hello"; // var holds a string`}</code></pre>
      </li><br />

      <li>
        <strong>std::any:</strong>
        <p>A type-safe container for single values of any type.</p>
        <pre><code>{`std::any value = 42;
value = std::string("Hello");`}</code></pre>
      </li><br />

      <li>
        <strong>Inline Variables:</strong>
        <p>Allows the definition of variables in header files without violating the One Definition Rule (ODR).</p>
        <pre><code>{`inline int sharedValue = 0;`}</code></pre>
      </li><br />

      <li>
        <strong>Filesystem Library:</strong>
        <p>Provides facilities for performing operations on file systems and paths.</p>
        <pre>
          <code>{`#include &lt;filesystem&gt;
namespace fs = std::filesystem;
fs::path p = "example.txt";
if (fs::exists(p)) {
    std::cout &lt;&lt; p &lt;&lt; " exists." &lt;&lt; std::endl; 
    }
`}</code></pre>
      </li><br />

      <li>
        <strong>Consteval and Constexpr if:</strong>
        <p>Allows <code>if</code> statements to be evaluated at compile time and <code>consteval</code> functions that must be evaluated at compile time.</p>
        <pre><code>{`constexpr auto max = [](auto a, auto b) { return (a &gt; b) ? a : b; };`}</code></pre>
      </li>
    </ol><br />

    <h2>Conclusion</h2>
    <p>C++11, C++14, and C++17 introduced a variety of features that enhance the language's expressiveness, safety, and performance. Understanding these features allows developers to write more efficient, readable, and maintainable code in modern C++.</p>
  </div>
)}
{selectedChapter === 'chapter54' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Move Semantics</h1>

    <p>
      Move semantics is a feature introduced in C++11 that optimizes resource management by allowing the transfer of resources from one object to another rather than copying them. This feature is particularly useful for managing dynamic memory and other resources like file handles or network connections, leading to more efficient and performant C++ code.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Key Concepts of Move Semantics</h2>

    <ol>
      <li>
        <strong>Rvalue References:</strong>
        <p>
          Move semantics relies on rvalue references, which are denoted by <code>&amp;&amp;</code>. An rvalue reference can bind to a temporary object (an rvalue) and enables the move operation.
        </p>
        <p>Example:</p>
        <pre><code>{`int&& x = 10; // x is an rvalue reference to the temporary value 10`}</code></pre>
      </li><br />

      <li>
        <strong>Move Constructor:</strong>
        <p>
          A move constructor allows a class to transfer resources from a temporary object to a new object. It "steals" the resources instead of copying them, which is typically more efficient.
        </p>
        <p>Example:</p>
        <pre><code>{`class MyClass {
public:
    MyClass(MyClass&& other) noexcept {
        // Transfer ownership of resources from other
        this->data = other.data; // Move resource
        other.data = nullptr; // Leave other in a valid state
    }
private:
    int* data; // Pointer to some resource
};`}</code></pre>
      </li><br />

      <li>
        <strong>Move Assignment Operator:</strong>
        <p>
          Similar to the move constructor, the move assignment operator transfers resources from one object to another.
        </p>
        <p>Example:</p>
        <pre><code>{`MyClass& operator=(MyClass&& other) noexcept {
    if (this != &other) {
        delete[] data; // Clean up existing resource
        this->data = other.data; // Move resource
        other.data = nullptr; // Leave other in a valid state
    }
    return *this;
}`}</code></pre>
      </li><br />

      <li>
        <strong>Using <code>std::move</code>:</strong>
        <p>
          To enable move semantics, you use the <code>std::move</code> function from the <code>&lt;utility&gt;</code> header. This function casts its argument to an rvalue reference, allowing the move constructor or move assignment operator to be called.
        </p>
        <p>Example:</p>
        <pre><code>{`MyClass obj1;
MyClass obj2 = std::move(obj1); // Move obj1 into obj2`}</code></pre>
      </li>
    </ol><br />

    <h2 style={{ paddingBottom: "6px" }}>Benefits of Move Semantics</h2>

    <ul>
      <li>
        <strong>Performance Improvements:</strong> Move semantics can significantly reduce the overhead of copying large objects or complex data structures, as it typically involves just pointer manipulation instead of deep copying.
      </li><br />
      <li>
        <strong>Resource Management:</strong> It simplifies resource management by allowing temporary objects to be moved rather than copied, which can reduce the risk of memory leaks and dangling pointers.
      </li>
    </ul><br />

    <h2 style={{ paddingBottom: "6px" }}>When to Use Move Semantics</h2>

    <ul>
      <li>
        <strong>In Classes Managing Resources:</strong> Any class that manages resources, such as dynamic memory, file handles, or network sockets, can benefit from implementing move semantics.
      </li><br />
      <li>
        <strong>In Containers:</strong> Standard library containers (like <code>std::vector</code>, <code>std::string</code>) use move semantics internally to improve efficiency when resizing or transferring ownership of contained objects.
      </li>
    </ul><br />

    <h2>Example</h2>

    <p>Here's a complete example demonstrating move semantics in a simple class:</p>

    <pre><code>{`#include <iostream>
#include <utility>

class MyVector {
public:
    MyVector(size_t size) : size(size), data(new int[size]) {}

    // Move Constructor
    MyVector(MyVector&& other) noexcept : size(other.size), data(other.data) {
        other.data = nullptr; // Leave other in a valid state
        other.size = 0;
    }

    // Move Assignment Operator
    MyVector& operator=(MyVector&& other) noexcept {
        if (this != &other) {
            delete[] data; // Clean up existing resource
            data = other.data; // Move resource
            size = other.size;
            other.data = nullptr; // Leave other in a valid state
            other.size = 0;
        }
        return *this;
    }

    ~MyVector() {
        delete[] data; // Clean up resources
    }

    void print() const {
        for (size_t i = 0; i < size; ++i) {
            std::cout << data[i] << " ";
        }
        std::cout << std::endl;
    }

private:
    size_t size;
    int* data;
};

int main() {
    MyVector vec1(5); // Create a vector of size 5
    MyVector vec2 = std::move(vec1); // Move vec1 into vec2

    vec2.print(); // Should work
    // vec1.print(); // This would be unsafe since vec1 is in a moved-from state

    return 0;
}`}</code></pre>

    <p>
      In this example, the <code>MyVector</code> class demonstrates both the move constructor and move assignment operator, allowing efficient resource management and transfer.
    </p>
  </div>
)}

{selectedChapter === 'chapter55' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Copy and Move Constructors</h1>

    <p>
      In C++, <strong>copy constructors</strong> and <strong>move constructors</strong> are special constructors used to create new objects from existing ones. They play crucial roles in resource management and optimization, especially when dealing with dynamic memory or other resources.
    </p><br />

    <h2>Copy Constructor</h2>

    <p>
      A <strong>copy constructor</strong> initializes a new object as a copy of an existing object. It is called when an object is passed by value, returned from a function, or explicitly copied. The copy constructor typically performs a deep copy of the object's resources to ensure that the new object has its own copies of any dynamic memory or resources.
    </p><br />

    <h3>Syntax:</h3>
    <pre>
      <code>{`ClassName(const ClassName& other);`}</code>
    </pre><br />

    <h3>Example:</h3>
    <pre>
      <code>{`class MyClass {
public:
    MyClass(int value) : data(new int(value)) {} // Constructor

    // Copy Constructor
    MyClass(const MyClass& other) {
        data = new int(*other.data); // Deep copy of data
    }

    ~MyClass() {
        delete data; // Clean up
    }

private:
    int* data; // Pointer to dynamically allocated memory
};`}</code>
    </pre><br />

    <h2>Move Constructor</h2>

    <p>
      A <strong>move constructor</strong> transfers resources from a temporary object (an rvalue) to a new object. It is called when an object is initialized using an rvalue or when using <code>std::move()</code>. The move constructor typically "steals" the resources from the source object, setting the source object's pointers to <code>nullptr</code> and leaving it in a valid but unspecified state. This is more efficient than copying, especially for large objects, as it avoids unnecessary memory allocations and deep copies.
    </p><br />

    <h3>Syntax:</h3>
    <pre>
      <code>{`ClassName(ClassName&& other) noexcept;`}</code>
    </pre><br />

    <h3>Example:</h3>
    <pre>
      <code>{`class MyClass {
public:
    MyClass(int value) : data(new int(value)) {} // Constructor

    // Move Constructor
    MyClass(MyClass&& other) noexcept : data(other.data) {
        other.data = nullptr; // Leave other in a valid state
    }

    ~MyClass() {
        delete data; // Clean up
    }

private:
    int* data; // Pointer to dynamically allocated memory
};`}</code>
    </pre><br />

    <h2>Summary of Differences</h2>
    <table>
      <thead>
        <tr>
          <th>Feature</th>
          <th>Copy Constructor</th>
          <th>Move Constructor</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Purpose</td>
          <td>Create a new object as a copy of an existing object</td>
          <td>Transfer resources from a temporary object</td>
        </tr>
        <tr>
          <td>Call Context</td>
          <td>Invoked when passing by value, returning by value, or copying</td>
          <td>Invoked when an object is initialized using an rvalue or <code>std::move()</code></td>
        </tr>
        <tr>
          <td>Resource Handling</td>
          <td>Typically performs a deep copy</td>
          <td>Transfers ownership (moves resources)</td>
        </tr>
        <tr>
          <td>Performance</td>
          <td>Can be slower due to deep copying</td>
          <td>Usually faster due to pointer manipulation</td>
        </tr>
      </tbody>
    </table>

    <br />

    <h2 style={{paddingBottom:"6px"}}>When to Use Each</h2>
    <ul>
      <li><strong>Use a Copy Constructor:</strong> When you need a duplicate of an object, such as when passing objects to functions or returning them.</li><br />
      <li><strong>Use a Move Constructor:</strong> When you want to optimize resource transfer for temporary objects or large data structures, such as in return values or when using <code>std::move()</code>.</li>
    </ul><br />

    <p>
      By properly implementing both copy and move constructors, you can manage resources effectively while maximizing performance in your C++ programs.
    </p>
  </div>
)}

{selectedChapter === 'chapter56' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Namespace and Scope Resolution</h1>

    <p>
      In C++, <strong>namespaces</strong> and <strong>scope resolution</strong> are important concepts that help organize code and manage the visibility of identifiers (such as variables, functions, and classes).
    </p><br />

    <h2>Namespace</h2>
    <p>
      A <strong>namespace</strong> is a declarative region that allows you to group related identifiers to avoid naming conflicts. This is particularly useful in larger projects or when integrating multiple libraries, where the same name might be used in different contexts.
    </p><br />

    <h3 style={{paddingBottom:"6px"}}>Key Points:</h3>
    <ul>
      <li>
        <strong>Declaration</strong>: You declare a namespace using the <code>namespace</code> keyword.
      </li><br />
      <li>
        <strong>Usage</strong>: You can define identifiers (functions, variables, classes) within a namespace, and they will be encapsulated within that namespace.
      </li><br />
      <li>
        <strong>Accessing Names</strong>: To access an identifier in a namespace, you can use the scope resolution operator <code>::</code>. For example, if you have a function <code>foo()</code> inside a namespace <code>myNamespace</code>, you would call it as <code>myNamespace::foo()</code>.
      </li>
    </ul><br />

    <h3>Example:</h3>
    <pre>
      <code>
        {`namespace myNamespace {
    int value = 42;

    void display() {
        std::cout << "Value: " << value << std::endl;
    }
}

int main() {
    myNamespace::display(); // Calls the function from myNamespace
    return 0;
}`}
      </code>
    </pre><br />

    <h2>Scope Resolution</h2>
    <p>
      <strong>Scope resolution</strong> refers to the way C++ resolves names to their corresponding entities based on their scope. The scope of a variable or function defines where it can be accessed or modified. The scope resolution operator <code>::</code> helps specify which identifier to use, especially when there are multiple declarations with the same name.
    </p><br />

    <h3 style={{paddingBottom:"6px"}}>Key Points:</h3>
    <ul>
      <li>
        <strong>Global Scope</strong>: Identifiers declared outside of any function or class have global scope and can be accessed from anywhere in the file.
      </li><br />
      <li>
        <strong>Class Scope</strong>: Members of a class have a class scope. You can access them using the class name and the scope resolution operator.
      </li><br />
      <li>
        <strong>Namespace Scope</strong>: Identifiers declared in a namespace are accessed using the namespace name and the scope resolution operator.
      </li><br />
      <li>
        <strong>Base Class Scope</strong>: In the context of inheritance, you can use the scope resolution operator to access base class members.
      </li>
    </ul><br />

    <h3>Example:</h3>
    <pre>
      <code>
        {`#include <iostream>

int value = 100; // Global variable

class MyClass {
public:
    int value = 200; // Class member

    void display() {
        std::cout << "Class value: " << value << std::endl; // Access class member
        std::cout << "Global value: " << ::value << std::endl; // Access global variable
    }
};

int main() {
    MyClass obj;
    obj.display(); // Calls display method
    return 0;
}`}
      </code>
    </pre><br />

    <h2 style={{paddingBottom:"6px"}}>Summary</h2>
    <ul>
      <li>
        <strong>Namespaces</strong> help prevent name clashes by grouping identifiers.
      </li><br />
      <li>
        <strong>Scope resolution</strong> determines which identifier is being referred to in a given context, using the <code>::</code> operator to specify the desired scope.
      </li>
    </ul><br />

    <p>
      These concepts are fundamental in C++ for managing complexity and maintaining clear code organization.
    </p>
  </div>
)}

{selectedChapter === 'chapter57' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Preprocessor Directives</h1>

    <p>
      In C++, <strong>preprocessor directives</strong> are instructions that are processed by the preprocessor before the actual compilation of the code begins. They provide a way to include files, define macros, and control conditional compilation, among other tasks. These directives are not part of the C++ language itself but are essential for the preprocessing stage.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Key Features of Preprocessor Directives</h2>
    <ul>
      <li><strong>No Semicolon</strong>: Preprocessor directives do not require a semicolon at the end.</li><br />
      <li><strong>Case Sensitivity</strong>: They are case-sensitive; for example, <code>#include</code> is different from <code>#Include</code>.</li><br />
      <li><strong>Start with <code>#</code></strong>: All preprocessor directives begin with the <code>#</code> symbol.</li>
    </ul><br />

    <h2 style={{paddingBottom:"6px"}}>Common Preprocessor Directives</h2>
    <ul>
      <li>
        <strong><code>#include</code></strong>
        <p>
          This directive is used to include the contents of a file into another file. It can be used to include standard libraries or user-defined header files.
        </p>
        <p>
          <strong>Syntax:</strong>
          <br />
          <code>
            {`#include <header_file> // For system headers
#include "header_file" // For user-defined headers`}
          </code>
        </p>
      </li><br />

      <li>
        <strong><code>#define</code></strong>
        <p>
          Used to define macros, which are pieces of code that can be reused throughout the program. It can also define constants.
        </p>
        <p>
          <strong>Syntax:</strong>
          <br />
          <code>#define MACRO_NAME value</code>
        </p>
        <p>
          <strong>Example:</strong>
          <br />
          <code>#define PI 3.14159</code>
        </p>
      </li><br />

      <li>
        <strong><code>#ifdef</code> / <code>#ifndef</code> / <code>#endif</code></strong>
        <p>
          These directives are used for conditional compilation. They allow you to compile code only if a certain macro is defined or not defined.
        </p>
        <p>
          <strong>Example:</strong>
          <br />
          <code>
            {`#ifdef MACRO_NAME
// Code to include if MACRO_NAME is defined
#endif`}
          </code>
        </p>
      </li><br />

      <li>
        <strong><code>#if</code>, <code>#else</code>, <code>#elif</code></strong>
        <p>
          Used for more complex conditional compilation scenarios.
        </p>
        <p>
          <strong>Example:</strong>
          <br />
          <code>
            {`#if VERSION >= 2
// Code for version 2 or higher
#else
// Code for versions lower than 2
#endif`}
          </code>
        </p>
      </li><br />

      <li>
        <strong><code>#undef</code></strong>
        <p>
          Used to undefine a macro that has been previously defined.
        </p>
        <p>
          <strong>Syntax:</strong>
          <br />
          <code>#undef MACRO_NAME</code>
        </p>
      </li><br />

      <li>
        <strong><code>#line</code></strong>
        <p>
          This directive is used to change the current line number and filename for error messages and debugging.
        </p>
        <p>
          <strong>Example:</strong>
          <br />
          <code>#line 100 "myfile.cpp"</code>
        </p>
      </li><br />

      <li>
        <strong><code>#error</code></strong>
        <p>
          Triggers a compilation error with a specified message. This is useful for debugging or ensuring certain conditions are met before compilation.
        </p>
        <p>
          <strong>Example:</strong>
          <br />
          <code>#error "An error has occurred"</code>
        </p>
      </li><br />

      <li>
        <strong><code>#pragma</code></strong>
        <p>
          This directive provides additional information to the compiler and is often used to control compiler-specific features or optimizations. Its effect can vary between different compilers.
        </p>
        <p>
          <strong>Example:</strong>
          <br />
          <code>#pragma once</code> <em>(Prevents multiple inclusions of a header file)</em>
        </p>
      </li>
    </ul><br />

    <h2>Example of Preprocessor Directives</h2>
    <p>Here's a simple example demonstrating some of these directives:</p>
    <pre>
      <code>
        {`#include <iostream> // Include standard library

#define PI 3.14159 // Define a macro for PI

int main() {
    std::cout << "Value of PI: " << PI << std::endl;

    #ifdef PI // Conditional compilation
    std::cout << "PI is defined." << std::endl;
    #else
    std::cout << "PI is not defined." << std::endl;
    #endif

    return 0;
}`}
      </code>
    </pre><br />

    <h2>Summary</h2>
    <p>
      Preprocessor directives in C++ play a crucial role in managing code structure and conditional compilation. They enable code reusability, improve maintainability, and facilitate platform-specific programming. Understanding these directives is essential for effective C++ programming, especially in larger projects.
    </p>
  </div>
)}

{selectedChapter === 'chapter58' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Introduction to Design Patterns</h1>
    <p>
      Design patterns are proven solutions to common software design problems. They represent best practices that can be reused in various scenarios to improve code maintainability, flexibility, and scalability. In C++, design patterns help developers build robust and efficient applications while adhering to established conventions.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Key Concepts of Design Patterns</h2>
    <ol>
      <li><strong>Reusability</strong>: Design patterns allow developers to reuse solutions for common problems, reducing development time and effort.</li><br />
      <li><strong>Efficiency</strong>: They promote efficient code organization, which can lead to improved performance and easier optimization.</li><br />
      <li><strong>Maintainability</strong>: By following design patterns, code becomes easier to understand, test, and modify, enhancing overall maintainability.</li><br />
      <li><strong>Interoperability</strong>: Patterns facilitate communication between different components of an application, making it easier to integrate new features or modify existing ones.</li>
    </ol><br />

    <h2 style={{paddingBottom:"6px"}}>Categories of Design Patterns</h2>
    <ol>
      <li><strong>Creational Patterns</strong>: These patterns deal with object creation mechanisms, aiming to create objects in a manner suitable for the situation. Examples include:
        <ul>
          <li><strong>Singleton</strong>: Ensures a class has only one instance and provides a global point of access to it.</li><br />

          <li><strong>Factory Method</strong>: Provides an interface for creating objects but allows subclasses to alter the type of objects that will be created.</li><br />
        </ul>
      </li><br />
      <li><strong>Structural Patterns</strong>: These patterns focus on the composition of classes and objects, ensuring that if one part of a system changes, the entire system doesn’t need to change. Examples include:
        <ul>
          <li><strong>Adapter</strong>: Allows incompatible interfaces to work together by wrapping an existing class with a new interface.</li><br />
          <li><strong>Decorator</strong>: Adds new behavior to objects dynamically by placing them inside special wrapper objects that contain the behavior.</li>
        </ul>
      </li><br />
      <li><strong>Behavioral Patterns</strong>: These patterns are concerned with algorithms and the assignment of responsibilities between objects. Examples include:
        <ul>
          <li><strong>Observer</strong>: Defines a one-to-many dependency between objects so that when one object changes state, all its dependents are notified and updated automatically.</li><br />
          <li><strong>Strategy</strong>: Enables selecting an algorithm's behavior at runtime by encapsulating different algorithms within classes and allowing the client to choose which one to use.</li>
        </ul>
      </li>
    </ol><br />

    <h2>Example of a Design Pattern: Singleton</h2>
    <p>Here’s a simple implementation of the <strong>Singleton</strong> design pattern in C++:</p>

    <pre>
      <code>{`
        #include &lt;iostream&gt;

        class Singleton {
        private:
            static Singleton* instance;

            // Private constructor to prevent instantiation
            Singleton() {}

        public:
            // Method to access the single instance
            static Singleton* getInstance() {
                if (!instance) {
                    instance = new Singleton();
                }
                return instance;
            }

            void showMessage() {
                std::cout &lt;&lt; "Hello from Singleton!" &lt;&lt; std::endl;
            }
        };

        // Initialize the static member
        Singleton* Singleton::instance = nullptr;

        int main() {
            // Access the singleton instance and show a message
            Singleton::getInstance()->showMessage();
            return 0;
        }
`}</code>
    </pre><br />

    <h2>Conclusion</h2>
    <p>
      Understanding and implementing design patterns in C++ can significantly enhance your software design skills. By leveraging these patterns, you can create systems that are easier to manage and extend, ultimately leading to higher-quality software. Design patterns not only provide a common language for developers but also foster collaboration and communication across teams, making them an invaluable tool in the software development process.
    </p>
  </div>
)}


{selectedChapter === 'chapter59' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Common Patterns (Singleton, Factory, Observer, etc.)</h1>

    <p>
      Here’s an overview of some common design patterns in C++, including <strong>Singleton</strong>, <strong>Factory</strong>, and <strong>Observer</strong> patterns, along with brief descriptions and examples for each:
    </p><br />

    <h2>1. Singleton Pattern</h2>
    <p>
      The <strong>Singleton</strong> pattern ensures that a class has only one instance and provides a global point of access to it. This is particularly useful when exactly one object is needed to coordinate actions across the system.
    </p><br />
    <h3 style={{paddingBottom:"6px"}}>Key Features:</h3>
    <ul>
      <li>Private constructor to prevent instantiation from outside.</li><br />
      <li>Static method to provide access to the instance.</li>
    </ul><br />

    <h3>Example:</h3>
    <pre>
      <code>
        {`
#include <iostream>

class Singleton {
private:
    static Singleton* instance;

    // Private constructor
    Singleton() {}

public:
    // Method to access the single instance
    static Singleton* getInstance() {
        if (!instance) {
            instance = new Singleton();
        }
        return instance;
    }

    void showMessage() {
        std::cout << "Hello from Singleton!" << std::endl;
    }
};

// Initialize the static member
Singleton* Singleton::instance = nullptr;

int main() {
    Singleton::getInstance()->showMessage();
    return 0;
}
        `}
      </code>
    </pre><br />

    <h2>2. Factory Method Pattern</h2>
    <p>
      The <strong>Factory Method</strong> pattern defines an interface for creating an object but allows subclasses to alter the type of objects that will be created. This promotes loose coupling by eliminating the need to instantiate concrete classes directly.
    </p>
    <h3 style={{paddingBottom:"6px"}}>Key Features:</h3>
    <ul>
      <li>A method for creating objects, which subclasses can override.</li><br />
      <li>Promotes flexibility and extensibility.</li>
    </ul><br />

    <h3>Example:</h3>
    <pre>
      <code>
        {`
#include <iostream>

// Product interface
class Product {
public:
    virtual void use() = 0; // Pure virtual function
};

// Concrete product
class ConcreteProductA : public Product {
public:
    void use() override {
        std::cout << "Using ConcreteProductA" << std::endl;
    }
};

// Concrete product
class ConcreteProductB : public Product {
public:
    void use() override {
        std::cout << "Using ConcreteProductB" << std::endl;
    }
};

// Creator
class Creator {
public:
    virtual Product* factoryMethod() = 0; // Factory method
};

// Concrete creator
class ConcreteCreatorA : public Creator {
public:
    Product* factoryMethod() override {
        return new ConcreteProductA();
    }
};

// Concrete creator
class ConcreteCreatorB : public Creator {
public:
    Product* factoryMethod() override {
        return new ConcreteProductB();
    }
};

int main() {
    Creator* creator = new ConcreteCreatorA();
    Product* product = creator->factoryMethod();
    product->use();

    delete product;
    delete creator;

    return 0;
}
        `}
      </code>
    </pre><br />

    <h2>3. Observer Pattern</h2>
    <p>
      The <strong>Observer</strong> pattern defines a one-to-many dependency between objects so that when one object (the subject) changes state, all its dependents (observers) are notified and updated automatically. This is useful for implementing event-driven systems.
    </p><br />
    <h3 style={{paddingBottom:"6px"}}>Key Features:</h3>
    <ul>
      <li>Allows subscribers (observers) to register and unregister themselves.</li><br />
      <li>Notifies all registered observers when a change occurs.</li>
    </ul><br />

    <h3>Example:</h3>
    <pre>
      <code>
        {`
#include <iostream>
#include <vector>
#include <algorithm> // For std::remove

// Observer interface
class Observer {
public:
    virtual void update(int value) = 0;
};

// Subject class
class Subject {
private:
    std::vector<Observer*> observers;
    int state;

public:
    void attach(Observer* observer) {
        observers.push_back(observer);
    }

    void detach(Observer* observer) {
        observers.erase(std::remove(observers.begin(), observers.end(), observer), observers.end());
    }

    void setState(int value) {
        state = value;
        notifyAllObservers();
    }

    void notifyAllObservers() {
        for (Observer* observer : observers) {
            observer->update(state);
        }
    }
};

// Concrete observer
class ConcreteObserver : public Observer {
private:
    std::string name;

public:
    ConcreteObserver(std::string name) : name(name) {}

    void update(int value) override {
        std::cout << "Observer " << name << " updated with value: " << value << std::endl;
    }
};

int main() {
    Subject subject;
    ConcreteObserver observer1("Observer1");
    ConcreteObserver observer2("Observer2");

    subject.attach(&observer1);
    subject.attach(&observer2);

    subject.setState(42); // Notifies all observers

    return 0;
}
        `}
      </code>
    </pre><br />

    <h2>4. Additional Patterns</h2>
    <p>
      While the <strong>Singleton</strong>, <strong>Factory</strong>, and <strong>Observer</strong> patterns are among the most commonly used, several other patterns are also widely adopted in C++:
    </p>
    <ul>
      <li><strong>Decorator Pattern</strong>: Allows behavior to be added to individual objects, either statically or dynamically, without affecting the behavior of other objects from the same class.</li><br />
      <li><strong>Adapter Pattern</strong>: Allows incompatible interfaces to work together by wrapping an existing class with a new interface.</li><br />
      <li><strong>Strategy Pattern</strong>: Enables selecting an algorithm's behavior at runtime by encapsulating different algorithms within classes and allowing the client to choose which one to use.</li>
    </ul><br />

    <h2>Conclusion</h2>
    <p>
      Understanding these common design patterns helps C++ developers create flexible, maintainable, and reusable code. Design patterns not only solve specific design problems but also promote best practices, allowing for clearer communication and collaboration among developers. By leveraging design patterns, you can build robust applications that can adapt to changing requirements with ease.
    </p>
  </div>
)}

{selectedChapter === 'chapter60' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Coding Standards</h1>
    <p>
      Coding standards in C++ are a set of guidelines and best practices that developers follow to ensure consistency, readability, maintainability, and reliability in their code. These standards help facilitate collaboration among developers, making it easier to understand and work with the codebase over time. Below are key aspects of coding standards in C++:
    </p><br />

    <h3>1. Naming Conventions</h3>
    <ul>
      <li>
        <strong>Variables and Functions:</strong> Use <code>camelCase</code> for variable names and function names. For example: <code>int numberOfStudents;</code>, <code>void calculateTotal();</code>.
      </li><br />
      <li>
        <strong>Classes:</strong> Use <code>PascalCase</code> for class names. For example: <code>class StudentDetails {};</code>.
      </li><br />
      <li>
        <strong>Constants:</strong> Use <code>UPPER_SNAKE_CASE</code> for constant values. For example: <code>const int MAX_SIZE = 100;</code>.
      </li>
    </ul><br />

    <h3>2. Code Structure</h3>
    <ul>
      <li>
        <strong>Indentation:</strong> Use consistent indentation (usually 2 or 4 spaces) for better readability.
      </li><br />
      <li>
        <strong>Line Length:</strong> Limit lines to a reasonable length (commonly 80 or 120 characters) to avoid horizontal scrolling.
      </li><br />
      <li>
        <strong>Braces:</strong> Place opening braces on the same line as the statement (K&R style). For example:
        <pre>
          <code>{`
            if (condition) {{
              // code
            }}
           `} </code>
        </pre>
      </li>
    </ul><br />

    <h3 style={{paddingBottom:"6px"}}>3. Comments</h3>
    <ul>
      <li>Use comments to explain the purpose and functionality of code, especially complex sections.</li>
      <li>
        <strong>Single-line Comments:</strong> Use <code>//</code> for brief explanations.
      </li><br />
      <li>
        <strong>Multi-line Comments:</strong> Use <code>/* ... */</code> for more extensive documentation.
      </li><br />
      <li>Maintain comments to ensure they are up-to-date and relevant.</li>
    </ul><br />

    <h3 style={{paddingBottom:"6px"}}>4. Error Handling</h3>
    <ul>
      <li>Use exceptions for error handling instead of error codes to manage unexpected situations.</li><br />
      <li>Define custom exception classes when necessary.</li>
    </ul><br />

    <h3 style={{paddingBottom:"6px"}}>5. Memory Management</h3>
    <ul>
      <li>Prefer smart pointers (<code>std::unique_ptr</code>, <code>std::shared_ptr</code>) over raw pointers to manage dynamic memory and avoid memory leaks.</li><br />
      <li>Always release resources properly when they are no longer needed.</li>
    </ul><br />

    <h3 style={{paddingBottom:"6px"}}>6. Use of STL (Standard Template Library)</h3>
    <ul>
      <li>Favor STL containers (like <code>std::vector</code>, <code>std::map</code>, etc.) over raw arrays for better safety and functionality.</li><br />
      <li>Use algorithms from the STL for common operations (like sorting, searching) to promote code reusability.</li>
    </ul><br />

    <h3 style={{paddingBottom:"6px"}}>7. Function Design</h3>
    <ul>
      <li>Functions should be short and focused on a single task (Single Responsibility Principle).</li><br />
      <li>Use meaningful function names that clearly describe their purpose.</li><br />
      <li>Avoid side effects; functions should have clear input/output without altering global state.</li>
    </ul><br />

    <h3 style={{paddingBottom:"6px"}}>8. Code Reviews and Collaboration</h3>
    <ul>
      <li>Encourage regular code reviews to ensure adherence to coding standards and improve code quality.</li><br />
      <li>Use version control systems (like Git) to manage code changes and collaborate effectively.</li>
    </ul><br />

    <h3 style={{paddingBottom:"6px"}}> 9. Documentation</h3>
    <ul>
      <li>Maintain thorough documentation for the codebase, including design documents and usage examples.</li><br />
      <li>Use tools like Doxygen to generate documentation from comments in the code.</li>
    </ul><br />

    <h3 style={{paddingBottom:"6px"}}>10. Testing</h3>
    <ul>
      <li>Write unit tests to verify that individual components work as intended.</li><br />
      <li>Use testing frameworks like Google Test or Catch2 to facilitate testing.</li>
    </ul><br />

    <h2>Conclusion</h2>
    <p>
      Following coding standards in C++ is crucial for creating clean, maintainable, and efficient code. It enhances collaboration among developers, reduces bugs, and facilitates easier onboarding for new team members. Adopting a set of coding standards tailored to your project's needs can significantly improve the overall quality of your software.
    </p>
  </div>
)}


{selectedChapter === 'chapter61' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Code Optimization Techniques</h1>


    <p>
      Code optimization in C++ involves improving the efficiency and performance of C++ programs without altering their functionality. It can significantly enhance execution speed, reduce memory consumption, and improve overall application performance. Here are some key code optimization techniques in C++:
    </p><br />

    <h2>1. Algorithm Optimization</h2>
    <ul>
      <li>
        <strong>Choose the Right Algorithm</strong>: Select algorithms with optimal time and space complexity for the task. For example, using quicksort instead of bubble sort for sorting operations can lead to significant performance improvements.
      </li><br />
      <li>
        <strong>Data Structures</strong>: Use appropriate data structures that offer efficient access and manipulation. For instance, using <code>std::unordered_map</code> for fast key-value access rather than <code>std::map</code>.
      </li>
    </ul><br />

    <h2>2. Memory Management</h2>
    <ul>
      <li>
        <strong>Use Smart Pointers</strong>: Utilize smart pointers like <code>std::unique_ptr</code> and <code>std::shared_ptr</code> to manage dynamic memory automatically and reduce memory leaks.
      </li><br />
      <li>
        <strong>Memory Pooling</strong>: Implement memory pooling to minimize the overhead of frequent allocations and deallocations, especially in performance-critical applications.
      </li><br />
      <li>
        <strong>Avoid Memory Fragmentation</strong>: Reuse memory and minimize dynamic memory allocation to reduce fragmentation.
      </li>
    </ul><br />

    <h2>3. Inline Functions</h2>
    <p>
      Use the <code>inline</code> keyword for small, frequently called functions to reduce function call overhead. This can result in performance gains by reducing the function call stack and allowing for further optimization by the compiler.
    </p><br />

    <h2>4. Loop Optimization</h2>
    <ul>
      <li>
        <strong>Minimize Loop Overhead</strong>: Move invariant calculations outside of loops to prevent repeated computation.
      </li><br />
      <li>
        <strong>Use Efficient Loop Constructs</strong>: Prefer <code>for</code> loops for indexed access and <code>range-based for</code> loops for better readability and potentially improved performance.
      </li>
    </ul><br />

    <h2>5. Compiler Optimization</h2>
    <ul>
      <li>
        <strong>Optimization Flags</strong>: Utilize compiler optimization flags (e.g., <code>-O2</code>, <code>-O3</code> for GCC) to enable various optimization techniques during the compilation process.
      </li><br />
      <li>
        <strong>Profile-Guided Optimization</strong>: Use profiling to identify bottlenecks and optimize those parts of the code accordingly.
      </li>
    </ul><br />

    <h2>6. Const and Reference Usage</h2>
    <ul>
      <li>
        Use <code>const</code> for variables and parameters that do not change to enable compiler optimizations.
      </li><br />
      <li>
        Pass large objects by reference instead of by value to avoid unnecessary copying, which can be costly in terms of performance.
      </li>
    </ul><br />

    <h2>7. Avoid Unnecessary Copies</h2>
    <ul>
      <li>
        Use move semantics and the <code>std::move()</code> function to transfer ownership of resources without copying them, particularly with large objects.
      </li><br />
      <li>
        Implement the copy-and-swap idiom for classes that manage resources to simplify copy semantics and provide strong exception safety.
      </li>
    </ul><br />

    <h2>8. Reduce Function Calls</h2>
    <p>
      In performance-critical code, consider minimizing the number of function calls, especially for small functions, as function calls can introduce overhead.
    </p><br />

    <h2>9. Precomputation</h2>
    <p>
      Precompute values that are used frequently, especially in situations where the same calculation is performed repeatedly (e.g., using lookup tables).
    </p><br />

    <h2>10. Parallelism and Concurrency</h2>
    <p>
      Utilize C++11 and later features such as threads, <code>std::async</code>, and thread pools to distribute workloads and improve performance on multi-core processors.
    </p><br />

    <h2>Conclusion</h2>
    <p>
      Optimizing code in C++ involves a combination of good design practices, efficient algorithms, and thoughtful use of language features. By applying these techniques, developers can create applications that not only perform better but are also more scalable and maintainable. It's important to profile and measure performance changes after optimization to ensure that they provide the intended benefits without compromising code clarity or maintainability.
    </p>
  </div>
)}



{selectedChapter === 'chapter62' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Debugging and Testing</h1>
    <p>
      <strong>Debugging</strong> and <strong>testing</strong> are two crucial processes in the software development lifecycle, especially in C++. They help ensure that applications function correctly and efficiently while identifying and resolving issues before deployment. Here’s an overview of both concepts in the context of C++ programming:
    </p><br />

    <h3>Debugging</h3>

    <p style={{paddingBottom:"6px"}}>
      Debugging is the process of identifying, isolating, and fixing problems (bugs) in a software application. It involves running the code in a controlled environment and using various tools and techniques to observe its behavior.
    </p>

    <h4 style={{paddingBottom:"6px"}}>Key Aspects of Debugging in C++:</h4>

    <ol>
      <li>
        <strong style={{paddingBottom:"6px"}}>Common Types of Bugs:</strong>
        <ul>
          <li><strong>Syntax Errors:</strong> Mistakes that violate language rules, such as missing semicolons or parentheses.</li><br />
          <li><strong>Logical Errors:</strong> Flaws in the program's logic that cause unexpected behavior or incorrect results.</li><br />
          <li><strong>Runtime Errors:</strong> Errors occurring while the program runs, like division by zero or accessing invalid memory.</li>
        </ul>
      </li><br />
      <li>
        <strong style={{paddingBottom:"6px"}}>Debugging Techniques:</strong>
        <ul>
          <li><strong>Print Statements:</strong> Inserting <code>std::cout</code> statements to output variable values and program flow to identify issues.</li><br />
          <li><strong>Debugger Tools:</strong> Using IDEs like Visual Studio, Code::Blocks, or command-line tools like GDB (GNU Debugger) to step through code, set breakpoints, and inspect variables.</li><br />
          <li><strong>Static Analysis:</strong> Employing tools that analyze code without execution to catch potential issues, such as memory leaks.</li>
        </ul>
      </li><br />
      <li>
        <strong style={{paddingBottom:"6px"}}>Best Practices:</strong>
        <ul>
          <li>Start debugging with the simplest test cases and gradually increase complexity.</li><br />
          <li>Isolate the section of code causing the issue.</li><br />
          <li>Review changes made since the last known good state to identify potential bug sources.</li>
        </ul>
      </li>
    </ol><br />

    <h3>Testing</h3>

    <p style={{paddingBottom:"6px"}}>
      Testing evaluates a software application to determine whether it meets specified requirements and performs as intended. It aims to ensure the quality and reliability of the software.
    </p>

    <h4 style={{paddingBottom:"6px"}}>Key Aspects of Testing in C++:</h4>

    <ol>
      <li>
        <strong style={{paddingBottom:"6px"}}>Types of Testing:</strong>
        <ul>
          <li><strong>Unit Testing:</strong> Testing individual components or functions in isolation, using frameworks like Google Test or Catch2.</li><br />

          <li><strong>Integration Testing:</strong> Testing the interaction between different components to ensure they work together.</li><br />
          <li><strong>System Testing:</strong> Testing the complete integrated software to verify compliance with requirements.</li><br />
          <li><strong>Regression Testing:</strong> Re-running tests after changes to ensure previously working functionality remains intact.</li>
        </ul>
      </li><br />
      <li>
        <strong style={{paddingBottom:"6px"}} >Testing Techniques:</strong>
        <ul>
          <li><strong>Test-Driven Development (TDD):</strong> Writing tests before the corresponding code to ensure design meets test cases from the start.</li><br />
          <li><strong>Mocking and Stubbing:</strong> Creating mock objects or stubs for external dependencies to isolate components for testing.</li><br />
          <li><strong>Automated Testing:</strong> Writing scripts to run tests automatically, facilitating extensive testing without manual intervention.</li>
        </ul>
      </li><br />
      <li>
        <strong style={{paddingBottom:"6px"}}>Best Practices:</strong>
        <ul>
          <li>Write clear and descriptive test cases indicating purpose and expected outcomes.</li><br />
          <li>Keep tests independent so that the failure of one does not affect others.</li><br />
          <li>Regularly update and maintain tests to ensure relevance as the codebase evolves.</li>
        </ul>
      </li>
    </ol><br />

    <h3>Conclusion</h3>

    <p>
      Debugging and testing are essential processes in C++ development that ensure software quality, reliability, and maintainability. By effectively debugging code and implementing a robust testing strategy, developers can reduce the likelihood of defects and deliver more reliable applications. Combining these practices fosters a better understanding of the codebase, ultimately leading to higher-quality software products.
    </p>
  </div>
)}


                </div>
            </div>
        </div>

    );

}


export default Cplusplusdata;


